import React, { Component } from 'react';
import {
  Row,
  Col,
  Input,
  Card,
  Carousel,
  Comment,
  List,
  Button,
  Form,
} from 'antd';
import {
  SendOutlined,
  LeftOutlined,
  EnvironmentFilled,
  CalendarFilled,
  LikeFilled,
  LikeOutlined,
  MessageFilled,
} from '@ant-design/icons';
import '../topNav.css';
import styles from './eventDetails.module.css';
import APIManager from '../../../APIManager';
import { connect } from 'react-redux';
import { Spin, Image, message } from 'antd';
import NewsComp from '../Services/news_comp';
import './event_details.css';
import moment from 'moment';
import { fileUrl } from '../../../Shared/imageUrlPath';
import cardDefaultPic from '../../../Components/asset/card.svg';
import userDefaultPic from '../../../Components/asset/user.svg';
import { NavLink } from 'react-router-dom';
import VideoSection from '../../videoSection/VideoSection';
import { Helmet } from 'react-helmet';

const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    className="event-comment-list"
    dataSource={comments}
    header={`${comments.length > 1 ? 'Comments' : 'Comment'} (${
      comments.length
    }) `}
    itemLayout="horizontal"
    renderItem={(item) => (
      <div>
        <Comment
          author={item.author}
          avatar={
            <img
              src={
                item.avator.includes('static')
                  ? cardDefaultPic
                  : fileUrl.fileUrlPath + item.avator
              }
              alt="imag"
            />
          }
          content={item.content}
          datetime={item.datetime}
        />
      </div>
    )}
  />
);

const Editor = ({ submitting, onSubmit, formRef }) => (
  <>
    <Form
      onFinish={onSubmit}
      ref={formRef}
      style={{ display: 'flex', marginTop: '40px' }}
    >
      <Form.Item name="comment" style={{ width: '100%' }}>
        <TextArea
          rows={1}
          className="comment-textarea"
          placeholder="comment here"
          autoSize
        />
      </Form.Item>
      <div className="comment-btn-div">
        <Form.Item style={{ display: 'inline-block' }}>
          <Button
            htmlType="submit"
            className="comment-btn"
            loading={submitting}
            icon={
              <SendOutlined
                style={{
                  color: '#ffffff',
                  fontSize: '24px',
                  top: '-4px',
                  position: 'relative',
                }}
                rotate={-45}
              />
            }
          ></Button>
        </Form.Item>
      </div>
    </Form>
  </>
);

class EventDetail extends Component {
  formRef = React.createRef();
  state = {
    loading: true,
    like: '',
    submitting: false,
    likesLength: '',
    showMoreComments: false,
    loadCommentName: false,
    allProductsData: null,
    isIntersted: '',
    comments: [],
  };

  componentDidMount() {
    var ls = require('local-storage');
    var eventId = ls.get('eventId');
    APIManager.getAllServices();
    APIManager.jobList();
    APIManager.getAllProducts()
      .then((resp) => {
        if (resp.data.isSuccess) {
          this.setState({
            allProductsData: resp.data.data,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        message.error('Something went wrong');
        this.setState({
          isLoading: false,
        });
      });
    var ls = require('local-storage');

    const { id } = this.props.match.params;
    APIManager.getoneEvent(id).then((response) => {
      const like = response.data.data.hasLiked;
      this.setState({ isIntersted: response.data.isInterested });
      const peopleWhoCommented = response.data.data.comments;
      const comments = [];
      peopleWhoCommented &&
        peopleWhoCommented.forEach((element) => {
          const data = {
            author: element.name,
            avator: element.profileImage
              ? element.profileImage
              : userDefaultPic,
            content: element.comment,
            datetime: moment(element.createdAt).fromNow(),
          };
          comments.push(data);
        });
      this.setState({
        loading: false,
        comments: comments,
        like: like,
        likesLength: response.data.data.likes.length,
        isInterested: response.data.isInterested,
      });
    });
  }
  saveAsIntersted = () => {
    var ls = require('local-storage');
    const { id } = this.props.match.params;
    const token = ls.get('token');
    const param = {
      interestId: id,
      category: 'event',
    };
    this.setState({
      isloading: true,
    });
    if (token) {
      APIManager.createInterested(param)
        .then((response) => {
          if (response.data.isSuccess) {
            this.setState({
              isloading: false,
              isInterested: response.data.message,
            });
          }
          APIManager.getoneEvent();
        })
        .catch((error) => console.log(error));
    } else {
      this.props.history.push('/login');
    }
  };
  handleChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };
  EventListingRender = () => {
    this.props.history.goBack();
  };
  renderJobs = () => {
    this.props.history.push({
      pathname: '/all_jobs',
    });
  };
  renderProducts = () => {
    this.props.history.push({
      pathname: '/products',
    });
  };
  renderServices = () => {
    this.props.history.push({
      pathname: '/services',
    });
  };
  viewMoreComments = () => {
    var size = 3;
    var comment = this.state.comments.slice(0, size);
  };

  handleLike = () => {
    var ls = require('local-storage');
    const { id } = this.props.match.params;
    const token = ls.get('token');
    if (token) {
      APIManager.likeEvent(id).then((resp) => {
        if (resp.data.isSuccess) {
          this.setState({
            like: resp.data.data.hasLiked,
            likesLength: resp.data.data.likes.length,
          });
          APIManager.getoneEvent(id);
        }
      });
    } else {
      this.props.history.push('/login');
    }
  };
  handleSubmit = (values) => {
    var ls = require('local-storage');
    const token = ls.get('token');
    const { id } = this.props.match.params;
    const eventId = id;
    if (token) {
      APIManager.commentEvent(id, values).then((resp) => {
        const comments = [];
        resp.data.data.comments.forEach((element) => {
          const data = {
            author: element.name,
            avator: element.profileImage,
            content: element.comment,
            datetime: moment(element.createdAt).fromNow(),
          };
          comments.push(data);
        });
        if (!values.comment) {
          return;
        }
        this.setState({
          submitting: true,
        });
        this.formRef.current.resetFields();
        setTimeout(() => {
          this.setState({
            submitting: false,
            comments: comments,
          });
        });
      }, 1000);
    } else {
      this.props.history.push('/login');
    }
  };

  render() {
    const propsData = this?.props?.oneeventdata;
    const name = propsData?.data?.name;
    const companyId = propsData?.data?.companyData?._id;
    const companyName = propsData?.data?.companyData?.comp_info?.comp_name;
    const businessName = propsData?.data?.companyData?.comp_info?.bus_name;
    const myEntity = propsData?.myEntity;
    const location = propsData?.data?.location;
    const fromDate = propsData?.data?.from;
    const toDate = propsData?.data?.to;
    const imageUrl = propsData?.data?.companyData?.logo?.name;
    const description = propsData?.data?.description;
    const { submitting, comments } = this.state;
    const peoplewhoLikedEventd = propsData?.data?.likes;
    const loading = this.props.isloading;
    const allServiceData = this?.props?.servicedata?.data;
    const allJobs = this?.props?.jobdata?.data;
    const recentJobs = allJobs?.slice(0, 3);
    const RecentServices = allServiceData?.slice(0, 3);
    const initialComments = this?.state?.comments?.slice(0, 3);
    const recentProducts = this?.state?.allProductsData?.slice(0, 3);
    const imagePath = this?.props?.oneeventdata?.data?.image;

    var ls = require('local-storage');
    // var picture = ls.get('displayPicture');
    const userType = this.props.user ? this.props.user.data.user.user_type : '';
    let likesMessage = [];
    let likesotherUsers = '';

    const likeByYou =
      peoplewhoLikedEventd?.length > 0 && this?.props?.user?.data
        ? peoplewhoLikedEventd?.find(
            (x) => x.refId === this.props?.user?.data?.data?.id
          )
        : '';
    if (likeByYou) {
      likesMessage.push('You');
    }

    for (let i = 0; i < peoplewhoLikedEventd?.length; i++) {
      if (likesMessage.length === 3) {
        likesotherUsers =
          peoplewhoLikedEventd.length - 3 > 0
            ? ` and ${peoplewhoLikedEventd.length - 3} others`
            : '';
        break;
      }
      if (this.props && this.props.user && this.props.user.data) {
        if (peoplewhoLikedEventd[i].refId !== this.props.user.data.data.id) {
          likesMessage.push(peoplewhoLikedEventd[i].name);
        }
      } else {
        likesMessage.push(peoplewhoLikedEventd[i].name);
      }
    }

    const token = ls.get('token');
    const dateFrom = fromDate ? moment(fromDate).format('DD MMM YY') : '';
    const dateTo = this.props?.oneeventdata?.data?.to
      ? moment(this.props?.oneeventdata?.data?.to).format('DD MMM YY')
      : '';
    return (
      <>
        <Helmet>
          <title>{`${name}-${dateFrom}-${dateTo}-${companyName}-Sendbiz`}</title>
          <meta
            property="og:title"
            content={`${name}-${dateFrom}-${dateTo}-${companyName}-Sendbiz`}
          />
          <meta
            name="twitter:card"
            content={`${name}-${dateFrom}-${dateTo}-${companyName}-Sendbiz`}
          />

          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </Helmet>
        {loading && (
          <div className={'d-flex justify-content-center mt-5'}>
            <Spin />
          </div>
        )}
        {!loading && (
          <>
            <div className={`detail-outer-div ${styles.mainContainer}`}>
              <div style={{ paddingBottom: '16px' }}>
                <LeftOutlined
                  className="back-icon"
                  onClick={this.EventListingRender}
                />
                <span className="back-btn" onClick={this.EventListingRender}>
                  Go back
                </span>
              </div>
              <Row gutter={32}>
                <Col
                  sm={8}
                  xs={24}
                  order={2}
                  className={styles.recentJobService}
                >
                  {/* card 1 */}
                  <div className="bg-white p-3 s-detail-left-card">
                    <Row className="custom_row">
                      <Col
                        span={12}
                        className="side-navbar-heading-company-1 pl-0"
                      >
                        Recent Jobs
                      </Col>
                      <Col
                        span={12}
                        className="p-0 side-navbar-heading-company-2 custom-view-all"
                        onClick={this.renderJobs}
                      >
                        View All&nbsp;&nbsp;
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      </Col>
                    </Row>
                    <ul className="icons-listing p-0">
                      {recentJobs &&
                        recentJobs?.map((data) => (
                          <li>
                            <Card
                              bordered={false}
                              className="w-100 p-0 sidebar-card"
                            >
                              <Row>
                                <Col span={7}>
                                  <img
                                    src={
                                      data.companyDetail
                                        ? data.companyDetail?.map(
                                            (data) =>
                                              fileUrl.fileUrlPath +
                                              data.logo.name
                                          )
                                        : cardDefaultPic
                                    }
                                    style={{
                                      height: '100px',
                                      width: '100px',
                                      borderRadius: '10px',
                                      objectFit: 'cover',
                                    }}
                                    className="feature_jobs_company"
                                    alt="feature jobs"
                                  />
                                </Col>
                                <Col span={1}></Col>
                                <Col span={16}>
                                  <p
                                    style={{ cursor: 'pointer' }}
                                    className="partner-text mb-0"
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: `/apply-for/${data._id}`,
                                        state: { jobId: data._id },
                                      });
                                    }}
                                  >
                                    <span
                                      className="partner-text-span-1"
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      {data &&
                                        data.title &&
                                        data?.title?.charAt(0)?.toUpperCase() +
                                          data?.title?.slice(1)}
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                      paddingLeft: '15px',
                                    }}
                                  >
                                    <span>
                                      <NavLink
                                        to={`/comp-id${data?.companyDetail?.[0]?._id}`}
                                        onClick={(e) => e.stopPropagation()}
                                        // target="_blank"
                                        className="job-card-4 comp-hover visit_cand_personal_11"
                                        style={{
                                          textDecoration: 'underline',
                                          fontSize: 'initial',
                                          cursor: 'pointer',
                                          marginLeft: '0px',
                                        }}
                                      >
                                        {data?.companyDetail?.[0]?.comp_info?.bus_name
                                          ?.charAt(0)
                                          ?.toUpperCase() +
                                          data?.companyDetail?.[0]?.comp_info?.bus_name?.slice(
                                            1
                                          )}
                                      </NavLink>
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                      paddingLeft: '15px',
                                    }}
                                  >
                                    <span className="partner-text-span-2">
                                      {moment(data.createdAt).format(
                                        'DD MMM YYYY'
                                      )}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                            </Card>
                          </li>
                        ))}
                    </ul>
                  </div>
                  {/* card 2 */}
                  <div className="bg-white p-3 mt-4 s-detail-left-card">
                    <Row className="custom_row">
                      <Col
                        span={12}
                        className="side-navbar-heading-company-1 pl-0"
                      >
                        Recent Services
                      </Col>
                      <Col
                        span={12}
                        className="side-navbar-heading-company-2 custom-view-all"
                        onClick={this.renderServices}
                      >
                        View All&nbsp;&nbsp;
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      </Col>
                    </Row>
                    {RecentServices &&
                      RecentServices?.map((data) => (
                        <ul className="icons-listing p-0">
                          <li>
                            <Card
                              bordered={false}
                              className="w-100 p-0 sidebar-card"
                            >
                              <Row>
                                <Col span={7}>
                                  <img
                                    src={
                                      data.media.length !== 0
                                        ? fileUrl.fileUrlPath +
                                          data.media[0].fileName
                                        : cardDefaultPic
                                    }
                                    style={{
                                      height: '100px',
                                      width: '100px',
                                      borderRadius: '10px',
                                      objectFit: 'cover',
                                    }}
                                    className="feature_jobs_company"
                                    alt="feature jobs"
                                  />
                                </Col>
                                <Col span={1}></Col>
                                <Col span={16}>
                                  <p
                                    className="partner-text mb-0"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      var ls = require('local-storage');
                                      ls.set('serviceId', data._id);
                                      this.props.history.push({
                                        pathname: `/services/detail/${data._id}`,
                                        id: data._id,
                                      });
                                    }}
                                  >
                                    <span
                                      className="partner-text-span-1"
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      {data?.name?.charAt(0)?.toUpperCase() +
                                        data?.name?.slice(1)}
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                      paddingLeft: '15px',
                                    }}
                                  >
                                    <span>
                                      <NavLink
                                        to={`/comp-id${data?.companyData?._id}`}
                                        onClick={(e) => e.stopPropagation()}
                                        // target="_blank"
                                        className="job-card-4 comp-hover visit_cand_personal_11"
                                        style={{
                                          textDecoration: 'underline',
                                          fontSize: 'initial',
                                          cursor: 'pointer',
                                          marginLeft: '0px',
                                        }}
                                      >
                                        {data?.companyData?.comp_info?.bus_name
                                          ?.charAt(0)
                                          ?.toUpperCase() +
                                          data?.companyData?.comp_info?.bus_name?.slice(
                                            1
                                          )}
                                      </NavLink>
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                      paddingLeft: '15px',
                                    }}
                                  >
                                    <span className="partner-text-span-2">
                                      {moment(data.createdAt).format(
                                        'DD MMM YYYY'
                                      )}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                            </Card>
                          </li>
                        </ul>
                      ))}
                  </div>
                  {/* card 3 */}
                  <div className="bg-white p-3 mt-4 s-detail-left-card">
                    <Row className="custom_row">
                      <Col
                        span={12}
                        className="side-navbar-heading-company-1 pl-0"
                      >
                        Recent Products
                      </Col>
                      <Col
                        span={12}
                        className="side-navbar-heading-company-2 custom-view-all"
                        onClick={this.renderProducts}
                      >
                        View All&nbsp;&nbsp;
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      </Col>
                    </Row>
                    <ul className="icons-listing p-0">
                      {recentProducts &&
                        recentProducts?.map((data) => (
                          <li>
                            <Card
                              bordered={false}
                              className="w-100 p-0 sidebar-card"
                            >
                              <Row>
                                <Col span={7}>
                                  <img
                                    src={
                                      data.media.length > 0 &&
                                      data.media[0].fileType.includes(
                                        'image'
                                      ) &&
                                      data.media.length !== 0
                                        ? fileUrl.fileUrlPath +
                                          data.media[0].fileName
                                        : cardDefaultPic
                                    }
                                    style={{
                                      height: '100px',
                                      width: '100px',
                                      borderRadius: '10px',
                                      objectFit: 'cover',
                                    }}
                                    className="feature_jobs_company"
                                    alt="feature jobs"
                                  />
                                </Col>
                                <Col span={1}></Col>
                                <Col span={16}>
                                  <p
                                    className="partner-text mb-0"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      this.props.history.push({
                                        pathname: `/products/detail/${data._id}`,
                                        product_obj: data,
                                      })
                                    }
                                  >
                                    <span
                                      className="partner-text-span-1"
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      {data?.name?.charAt(0)?.toUpperCase() +
                                        data?.name?.slice(1)}
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                      paddingLeft: '15px',
                                    }}
                                  >
                                    <span>
                                      <NavLink
                                        to={`/comp-id${data?.companyData?._id}`}
                                        onClick={(e) => e.stopPropagation()}
                                        // target="_blank"
                                        className="job-card-4 comp-hover visit_cand_personal_11"
                                        style={{
                                          textDecoration: 'underline',
                                          fontSize: 'initial',
                                          cursor: 'pointer',
                                          marginLeft: '0px',
                                        }}
                                      >
                                        {data?.companyData?.comp_info?.bus_name
                                          ?.charAt(0)
                                          ?.toUpperCase() +
                                          data?.companyData?.comp_info?.bus_name?.slice(
                                            1
                                          )}
                                      </NavLink>
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                      paddingLeft: '15px',
                                    }}
                                  >
                                    <span className="partner-text-span-2">
                                      {moment(data.createdAt).format(
                                        'DD MMM YYYY'
                                      )}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                            </Card>
                          </li>
                        ))}
                    </ul>
                  </div>
                  {/* </Card> */}
                </Col>
                <Col md={16} xs={24} order={1}>
                  <Card className="s-detail-r-card" bordered={false}>
                    <Row>
                      <Col>
                        <NavLink
                          to={`/comp-id${companyId}`}
                          onClick={(e) => e.stopPropagation()}
                          // target="_blank"
                        >
                          <Row>
                            <Col>
                              <img
                                style={{ height: '370px', objectFit: 'cover' }}
                                src={
                                  imageUrl
                                    ? fileUrl.fileUrlPath + imageUrl
                                    : userDefaultPic
                                }
                                alt="event"
                                className="event-d-head-image img-responsive"
                              />
                            </Col>
                            <Col
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <h4 className="event-detail-name">
                                {businessName?.charAt(0)?.toUpperCase() +
                                  businessName?.slice(1)}
                              </h4>
                            </Col>
                          </Row>
                        </NavLink>
                      </Col>
                    </Row>
                    <Row style={{ margin: '30px 0 20px' }}>
                      <Col span={24}>
                        <h4 style={{ fontSize: '28px', marginBottom: '15px' }}>
                          {name?.charAt(0)?.toUpperCase() + name?.slice(1)}
                        </h4>
                        <Row>
                          <Col sm={14} xs={24}>
                            <EnvironmentFilled className="service-icons" />
                            <span
                              className={`event-detail-date`}
                              style={{ fontSize: '18px' }}
                            >
                              {location}
                            </span>
                          </Col>
                          <Col sm={10} xs={24} className={styles.DateOfEvents}>
                            <CalendarFilled className="service-icons" />
                            <span
                              className="event-detail-date"
                              style={{ fontSize: '18px' }}
                            >
                              {moment(fromDate).format('DD MMM YY') +
                                ' - ' +
                                moment(toDate).format('DD MMM YY')}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Carousel
                      afterChange={this.onChange}
                      className="event-carousel"
                      autoplay
                      arrows
                    >
                      {imagePath &&
                        imagePath
                          ?.filter((data) => data.fileType.includes('image'))
                          ?.map((data) => (
                            <div className={'d-flex justify-content-center'}>
                              <Image
                                src={
                                  data.fileName
                                    ? fileUrl.fileUrlPath + data.fileName
                                    : cardDefaultPic
                                }
                                alt="event"
                                className="event-carousel-img"
                                width={data ? '100%' : '80%'}
                                height={350}
                              ></Image>
                            </div>
                          ))}
                    </Carousel>
                    <br />
                    <br />
                    {imagePath &&
                      imagePath.filter((data) =>
                        data.fileType.includes('video')
                      ).length > 0 && (
                        <VideoSection
                          media={imagePath.filter((data) =>
                            data.fileType.includes('video')
                          )}
                        />
                      )}
                    <p
                      className="r-card-text"
                      style={{
                        whiteSpace: 'pre-wrap',
                        marginTop: '30px',
                      }}
                    >
                      {description}
                    </p>
                    <Row
                      style={{
                        marginTop: '30px',
                        paddingBottom: '20px',
                        borderBottom: 'solid 2px #D8D8D830',
                      }}
                    >
                      <Col
                        sm={12}
                        xs={24}
                        className={styles.likedSection}
                        style={{
                          display: 'flex',
                          textAlign: 'right',
                          alignItems: 'center',
                        }}
                      >
                        <div onClick={this.handleLike}>
                          {this.state.like ? (
                            <LikeFilled
                              style={{ cursor: 'pointer' }}
                              className="service-icons"
                            />
                          ) : (
                            <LikeOutlined
                              style={{ cursor: 'pointer' }}
                              className="service-icons"
                            />
                          )}
                        </div>
                        <span className="event-detail-date">
                          {peoplewhoLikedEventd
                            ? (likesMessage.length === 2
                                ? likesMessage.join(' and ')
                                : peoplewhoLikedEventd.length === 3
                                ? `${likesMessage[0]}, ${likesMessage[1]} and ${likesMessage[2]}`
                                : likesMessage.join(', ')) + likesotherUsers
                            : ''}
                        </span>
                      </Col>
                      <Col
                        sm={12}
                        xs={24}
                        className={'d-flex'}
                        style={{ textAlign: 'right', alignItems: 'center' }}
                      >
                        <MessageFilled
                          className="service-icons"
                          style={{ color: 'black' }}
                        />
                        <span className="event-detail-date">
                          {this.state.comments.length} Comments
                        </span>
                        <div style={{ marginLeft: '50px' }}>
                          {!myEntity && (
                            <div
                              className={'intrested-button'}
                              style={{ marginLeft: '5%' }}
                            >
                              <button
                                style={{
                                  height: '100%',
                                  borderRadius: '5px',
                                  width: '100%',
                                  fontWeight: 'bold',
                                }}
                                className="intrested-button"
                                onClick={this.saveAsIntersted}
                              >
                                {this.state.isloading && 'saving...'}

                                {!this.state.isloading &&
                                  (this.state.isInterested
                                    ? 'Not Interested'
                                    : 'Interested')}
                              </button>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {comments.length > 0 && comments.length > 3 ? (
                          this.state.showMoreComments ? (
                            <CommentList comments={this.state.comments} />
                          ) : (
                            <CommentList comments={initialComments} />
                          )
                        ) : (
                          <CommentList comments={comments} />
                        )}
                        <div className={'d-flex justify-content-end'}>
                          {this.state.comments.length > 3 && (
                            <Button
                              onClick={() => {
                                this.setState({
                                  showMoreComments:
                                    !this.state.showMoreComments,
                                  loadCommentName: !this.state.loadCommentName,
                                });
                              }}
                            >
                              {this.state.loadCommentName ? (
                                <p>view less</p>
                              ) : (
                                <p>View more</p>
                              )}
                            </Button>
                          )}
                        </div>
                        <Editor
                          onSubmit={this.handleSubmit}
                          submitting={submitting}
                          formRef={this.formRef}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </div>
            <NewsComp />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    oneeventdata: state.eventReducer.oneeventdata,
    isloading: state.eventReducer.isloading,
    servicedata: state.ServiceReducer.servicedata,
    jobdata: state.jobManagementReducer.jobdata,
    user: state.AuthReducer.user,
  };
};
export default connect(mapStateToProps)(EventDetail);
