// import '../topNav.css';
import { Row, Col } from 'antd';
import Header from '../header/header.js';
import Footer from './footer';
import './Landing.css';
import './footer.css';
import styles from './footer.module.css';
const AboutUs = () => {
  return (
    <Row className="responsive-div">
      <Col>
      <Header />
      <Row className="home-outer-div">
        <Col xs={24} className={styles.aboutusContainer}>
          <Col className={styles.aboutusInnerContainer}>
          <Col xs={24} className={styles.aboutusTitle}>
            You One stop solution for HR roles
          </Col>
          <Col xs={24} className={styles.aboutusTitle1}>
            Sen<span style={{color: '#b02318'}}>db</span>iz connects your SME <br/> to job seekers and buyers
          </Col>
          <Col xs={24}>
           <img
          className={styles.aboutusImage}
          src={process.env.PUBLIC_URL + '/aboutus-bg.png'}
          alt="img"
        />
          </Col>
          </Col>
        </Col>
        <Col xs={24} className={styles.aboutusContainer}>
          <Col xs={1} md={2}></Col>
          <Col xs={22} md={20} className={styles.aboutusRedContainer}>
            <span className={styles.aboutusRedTab}> <b> Sendbiz has been a part of over 50 million Companies </b> </span>
          </Col>
          <Col xs={1} md={2}></Col>
        </Col>
        <Row gutter={50}>
          <Col xs={1}></Col>
          <Col xs={23} md={11}>
            <div className={styles.aboutBox}>
              <img
                className="box-icon"
                src={process.env.PUBLIC_URL + '/growth-icon.png'}
                alt="img"
              />
              <div className="box-heading">Ecosystem for Growth</div>
              <p className="box-para">
                SendBiz is a marketplace to list your company, build your brand,
                and achieve the growth you deserve.
                <br />
                Make your company profile with our in-built feature of images,
                videos, and blog.
                <br />• List your products/services, collaborators, jobs, news
                <br />• Gain the reviews from your target customers Today,
                visibility is a major asset in the development of companies, in
                recruitment and sales. With SendBiz you can make your company
                famous all over the world
              </p>
            </div>
          </Col>
          <Col xs={23} md={11} style={{marginBottom: '30px'}}>
            <Col className={styles.aboutBox}>
              <img
                className="box-icon join-icon"
                src={process.env.PUBLIC_URL + '/join.png'}
                alt="img"
              />
              <div className="box-heading">How to join us now</div>
              <p className="box-para">
                You can join and avail all the benefits of SendBiz in 3 small
                steps
                <br />• Purchase our subscription package
                <br />• Make your SendBiz profile
                <br />• List all the possible information Our mission is the
                growth of VSEs / SMEs. We have designed a platform which will
                take your business from local to global. Jobs at Sendbiz offer
                many opportunities in a simpler, more pleasant and more
                productive manner. Sendbiz’s e-comm section helps to grow and
                promote products that are good for the planet
              </p>
            </Col>
          </Col>
          <Col xs={1}></Col>
        </Row>
      </Row>
      <Footer />
      </Col>
    </Row>
  );
};

export default AboutUs;
