import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import styles from './companyBilling.module.css';
import {
  Layout,
  Col,
  Row,
  Card,
  Input,
  Select,
  Button,
  Spin,
  message,
} from 'antd';
import { SearchOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import AddUser from '../createprofile/create-user.js';
import RightModal from '../job/rightModal.js';
import APIManager from '../../APIManager/index';
import { connect } from 'react-redux';
import ChangePassWord from './changePassword/index';
import moment from 'moment';
import Pie from './sponsoredPieChart';
import CompanyMenuDropDown from './CompanyMenuDropDown';

const { Option } = Select;
class companyBillingOverview extends Component {
  constructor(props) {
    super(props);
    this.buttonpressElement = React.createRef();
  }
  state = {
    applymodal: false,
    isPremium: '',
    sortBy: '',
    serchQuery: '',
    isloading: false,
    isMonthlyPremium: false,
    changePassWordModel: false,
    current_page: null,
    totalPages: '',
    billingData: {},
    premium: '-',
    premiumExpDate: '',
    isSwitchFreemium: false,
    userCount: 0,
    upcomingInv: '',
  };
  componentDidMount() {
    APIManager.getSubUserList().then((resp) => {
      this.setState({ userCount: resp.data.totalCount });
    });
    APIManager.billingOverview().then((response) => {
      this.setState({ billingData: response.data });
    });
    this.setState({ isloading: true });
    APIManager.getAllOrders(this.state.sortBy, this.state.serchQuery)
      .then((response) => {
        if (response.data.isSuccess) {
          this.setState({
            isloading: false,
            total: response.data.totalCount,
            totalPages: response.data.totalPages,
            current_page: response.data.currentPage,
            upcomingInv: response.data.upcomingInv,
          });
        }
      })
      .catch((error) => message.info('Something went wrong.'));
    APIManager.companyInfo().then((response) => {
      if (response.data.isSuccess) {
        this.setState({
          isPremium: response.data.data.data.isPremium,
          isMonthlyPremium: response.data.data.data.isMonthlyPremium,
          premium: response.data.data.data.isPremium
            ? 'Premium Plan'
            : 'Freemium Plan',
          isSwitchFreemium: response.data.data.data.isSwitchFreemium,
          premiumExpDate: response.data.data.data.premiumExpiresAt,
          country: response.data.data.data.contact_info.sub_address,
        });
      }
    });
  }

  makeHttpRequestWithPage = async (pageNumber) => {
    this.setState({
      isloading: true,
    });

    APIManager.getAllOrders(
      this.state.sortBy,
      this.state.serchQuery,
      pageNumber
    )
      .then((response) => {
        if (response.data.isSuccess) {
          this.setState({
            isloading: false,
            total: response.data.totalCount,
            current_page: response.data.currentPage,
          });
        }
      })
      .catch((error) => {
        this.setState({ isloading: false });
        message.info('Something went wrong.');
      });
  };

  componentDidUpdate(prevState, prevprops) {
    if (
      prevprops.sortBy !== this.state.sortBy ||
      prevprops.serchQuery !== this.state.serchQuery
    ) {
      this.setState({ isloading: true });
      APIManager.getAllOrders(this.state.sortBy, this.state.serchQuery).then(
        (response) => {
          if (response.data.isSuccess) {
            this.setState({
              isloading: false,
              total: response.data.totalCount,
              current_page: response.data.currentPage,
            });
          }
        }
      );
    }
  }
  refreshPage = () => {
    APIManager.getSubUserList().then((resp) => {
      this.setState({ userCount: resp.data.totalCount });
    });
  };
  handleChange = (value) => {
    this.setState({ sortBy: value });
  };
  handleSubmit = () => {
    this.buttonpressElement.current.clickSubmitClick();
  };
  handleClick = () => {
    this.setState({ applymodal: true });
  };
  closeModal = () => {
    document.body.style.overflow = 'auto';
    this.setState({ applymodal: false });
  };
  openPackagePlan = () => {
    this.props.history.push({
      pathname: '/package-plan',
      state: {
        userCount: this.state.userCount,
      },
    });
  };
  getInvoice = (id) => {
    fetch(`${process.env.REACT_APP_URL}/payments/get-invoice?orderId=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Sendbiz.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  render() {
    let renderPageNumbers;

    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / 10); i++) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers?.map((number) => {
        return (
          <span
            key={number}
            onClick={() => this.makeHttpRequestWithPage(number)}
            className={
              this.state.current_page === number
                ? 'page-number-btn'
                : 'pagination-container'
            }
          >
            {number}
          </span>
        );
      });
    }

    let d = this.props.data;
    let data = [];
    if (d !== null) {
      data = d;
    }
    const paidOrders = this.props.orderdata ? this.props.orderdata.data : '';
    var ls = require('local-storage');
    var companyName = ls.get('company_name');

    return (
      <>
        {/*=============dropdown menu ==================*/}
        <Row>
          <Col sm={0} xs={24}>
            <CompanyMenuDropDown name={'Billing Overview'} />
          </Col>
        </Row>
        {this.state.isloading && (
          <div
            className={
              'd-flex justify-content-center flex-direction-row align-item-center'
            }
          >
            <Spin />
          </div>
        )}
        <Layout>
          {this.state.changePassWordModel && (
            <ChangePassWord
              show={this.state.changePassWordModel}
              onHide={() => this.setState({ changePassWordModel: false })}
            />
          )}
          {this.state.applymodal ? (
            <RightModal
              isClose={this.closeModal}
              onSubmit={this.handleSubmit}
              className={'create-user-modal'}
              component={
                <AddUser
                  ref={this.buttonpressElement}
                  isClose={this.closeModal}
                  refreshPage={this.refreshPage}
                />
              }
              title={'Add User'}
              isOpen={this.state.applymodal}
            />
          ) : null}
          <Layout
            className={`main-margin-top-for-edit-profile ${styles.mainContainer}`}
          >
            <Row className="custom_row">
              <Col sm={12} xs={0} className="p-0">
                <h6 className="candidate_heading">Billing Overview</h6>
              </Col>
              <Col sm={12} xs={0} className="text-right p-0"></Col>
            </Row>
            <div className="site-card-wrapper">
              <Row gutter={16}>
                {/*================ manage user card ===================*/}
                <Col sm={8} xs={11} className="biiling-card-main-cls">
                  <Card hoverable>
                    <div style={{ display: 'flex' }}>
                      <img
                        src={process.env.PUBLIC_URL + '/users-red.svg'}
                        alt="img"
                      />
                      <div style={{ paddingLeft: 15 }}>
                        <p
                          className={`site-card-wrapper-1 ${styles.headingText}`}
                          style={{ margin: 0 }}
                        >
                          USER(s)
                        </p>
                        <p
                          className={`site-card-wrapper-2 ${styles.headingText}`}
                          style={{ margin: 0 }}
                        >
                          {data.totalCount}
                        </p>
                      </div>
                    </div>
                    <Button
                      onClick={() => {
                        window.location.href = '/manage-user';
                        this.refreshPage();
                      }}
                      className={`billing-card-button-1 ${styles.headingText}`}
                    >
                      Manage User(s)
                    </Button>
                  </Card>
                </Col>
                {/*=================== Monthly payment card ======================*/}
                <Col sm={8} xs={13} className="biiling-card-main-cls">
                  <Card hoverable>
                    <div style={{ display: 'flex' }}>
                      <img src={process.env.PUBLIC_URL + '/doller.svg'} alt='doller'/>
                      <div style={{ paddingLeft: 15 }}>
                        <p
                          className={`site-card-wrapper-1 ${styles.headingText}`}
                          style={{ margin: 0 }}
                        >
                          {this.state.isPremium && !this.state.isMonthlyPremium
                            ? 'Yearly Payment'
                            : 'Monthly Payment'}
                        </p>
                        <p
                          className="site-card-wrapper-2"
                          style={{ margin: 0 }}
                        >
                          $
                          {this.state.isPremium
                            ? this.state.isPremium &&
                              !this.state.isMonthlyPremium
                              ? data.totalCount * 120
                              : data.totalCount * 15
                            : 0}
                        </p>
                      </div>
                    </div>
                    <Button
                      className={`billing-card-button-1 ${styles.headingText}`}
                      onClick={this.handleClick}
                    >
                      Add User(s)
                    </Button>
                  </Card>
                </Col>
                {/*================= Current Subscription card =====================*/}
                <Col
                  sm={8}
                  xs={24}
                  className={`biiling-card-main-cls ${styles.billingMT}`}
                >
                  <Card hoverable>
                    <div style={{ display: 'flex' }}>
                      <img src={process.env.PUBLIC_URL + '/premium_plan.svg'} alt='premium_plan'/>
                      <div style={{ paddingLeft: 15 }}>
                        <p
                          className={`site-card-wrapper-1 ${styles.headingText}`}
                          style={{ margin: 0, fontSize: '1.1vw' }}
                        >
                          Current Subscription
                        </p>
                        <p
                          className="site-card-wrapper-2"
                          style={{ margin: 0 }}
                        >
                          {this.state.premium}
                        </p>
                        <p
                          className="billing_overview_card_list-1-inner-para-2"
                          style={{ margin: '0px', fontSize: '0.8rem' }}
                        >
                          {this.state.isSwitchFreemium &&
                          new Date(this.state.premiumExpDate) > new Date()
                            ? moment(this.state.premiumExpDate).format(
                                'YYYY/MM/DD'
                              )
                            : ''}
                        </p>
                      </div>
                    </div>
                    <Button
                      className={`billing-card-button-1 ${styles.headingText}`}
                      onClick={this.openPackagePlan}
                      style={{
                        fontSize: '1vw',
                        marginTop:
                          this.state.isSwitchFreemium &&
                          new Date(this.state.premiumExpDate) > new Date()
                            ? '0px'
                            : '17px',
                      }}
                    >
                      Manage Subscriptions
                    </Button>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col
                  sm={10}
                  xs={24}
                  className="billing_overview_card_list-1"
                  onClick={() =>
                    this.props.history.push({
                      pathname: '/company/billing/current-billing',
                    })
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <Row>
                    <p
                      className={`candidate_heading ${styles.headingText}`}
                      style={{ fontSize: '1.7vw' }}
                    >
                      Billing
                    </p>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Row className="billing-overview-detail">
                        <Col sm={18} xs={16}>
                          <h6 className={styles.headingText}>User(s)</h6>
                        </Col>
                        <Col sm={6} xs={8}>
                          <h6 className={styles.headingText}>Amount</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={18} xs={16}>
                          <p
                            className={`site-card-wrapper-1 ${styles.headingText} ${styles.billingMarginBtm}`}
                            style={{ margin: 0 }}
                          >
                            {data.totalCount}
                            {' * '} $
                            {this.state.isPremium
                              ? this.state.isPremium &&
                                !this.state.isMonthlyPremium
                                ? '120'
                                : '15'
                              : '0'}{' '}
                            per User
                          </p>
                        </Col>
                        <Col sm={6} xs={8}>
                          <p
                            className={`site-card-wrapper-1 ${styles.headingText}`}
                            style={{ margin: 0 }}
                          >
                            <h6
                              style={{ fontSize: '1.3vw' }}
                              className={styles.headingText}
                            >
                              ${' '}
                              {this.state.isPremium
                                ? this.state.isPremium &&
                                  !this.state.isMonthlyPremium
                                  ? data.totalCount * 120
                                  : data.totalCount * 15
                                : 0}
                            </h6>
                          </p>
                        </Col>
                      </Row>
                      {this.state.isPremium &&
                      this.state?.upcomingInv !== '' ? (
                        <Row>
                          <Col span={18}>
                            <p
                              className={`site-card-wrapper-1 ${styles.headingText}`}
                              style={{ marginTop: '0px' }}
                            >
                              Discount
                            </p>
                          </Col>
                          <Col span={6}>
                            <p
                              className={`site-card-wrapper-1 ${styles.headingText}`}
                              style={{ margin: 0 }}
                            >
                              <h6
                                style={{ fontSize: '1.3vw' }}
                                className={styles.headingText}
                              >
                                &nbsp;&nbsp;&nbsp;
                                {this.state?.upcomingInv?.discount
                                  ? this.state?.upcomingInv?.discount + '% '
                                  : '0% '}
                              </h6>
                            </p>
                          </Col>
                        </Row>
                      ) : (
                        ''
                      )}
                      {this.state?.upcomingInv !== '' && (
                        <Row>
                          <Col
                            sm={18}
                            xs={16}
                            className={styles.nextBillingAmount}
                          >
                            <p
                              className={`site-card-wrapper-1 ${styles.headingText}`}
                            >
                              Next Billing Amount
                            </p>
                          </Col>
                          <Col sm={6} xs={8}>
                            <h6
                              className={`site-card-wrapper-1 ${styles.headingText} ${styles.nextBillingAmountText2} `}
                              style={{ fontSize: '1.3vw' }}
                            >
                              ${' '}
                              {this.state.isPremium
                                ? this.state.isPremium &&
                                  !this.state.isMonthlyPremium
                                  ? (
                                      data.totalCount * 120 -
                                      (this.state?.upcomingInv?.discount
                                        ? this.state?.upcomingInv?.discount /
                                          100
                                        : 0) *
                                        data.totalCount *
                                        120
                                    ).toFixed(2)
                                  : (
                                      data.totalCount * 15 -
                                      (this.state?.upcomingInv?.discount
                                        ? this.state?.upcomingInv?.discount /
                                          100
                                        : 0) *
                                        data.totalCount *
                                        15
                                    ).toFixed(2)
                                : 0}
                            </h6>
                          </Col>
                        </Row>
                      )}
                      {this.state?.upcomingInv !== '' &&
                        (this.state?.upcomingInv.proration?.remainingCost ? (
                          <Row>
                            <Col span={18}>
                              <p
                                className={`site-card-wrapper-1 ${styles.headingText}`}
                                style={{ margin: '0px' }}
                              >
                                Proration Amount
                              </p>
                            </Col>
                            <Col span={6}>
                              <h6
                                className={`site-card-wrapper-1 ${styles.headingText}`}
                                style={{ fontSize: '1.3vw' }}
                              >
                                ${' '}
                                {this.state.isPremium
                                  ? (
                                      this.state?.upcomingInv?.amount?.toFixed(
                                        2
                                      ) -
                                      (!this.state.isMonthlyPremium
                                        ? data.totalCount * 120 -
                                          (this.state?.upcomingInv?.discount
                                            ? this.state?.upcomingInv
                                                ?.discount / 100
                                            : 0) *
                                            data.totalCount *
                                            120
                                        : data.totalCount * 15 -
                                          (this.state?.upcomingInv?.discount
                                            ? this.state?.upcomingInv
                                                ?.discount / 100
                                            : 0) *
                                            data.totalCount *
                                            15)
                                    ).toFixed(2)
                                  : '0'}
                              </h6>
                            </Col>
                          </Row>
                        ) : (
                          ''
                        ))}
                    </Col>
                  </Row>
                  {this.state?.isPremium &&
                    this.state?.upcomingInv !== '' &&
                    this.state?.upcomingInv?.tax !== 0 && (
                      <Row>
                        <Col sm={18} xs={16}>
                          <p
                            className={`site-card-wrapper-1 ${styles.headingText}`}
                            style={{ margin: '0px' }}
                          >
                            Tax
                          </p>
                        </Col>
                        <Col
                          sm={6}
                          xs={8}
                          className={`site-card-wrapper-1 ${styles.headingText} ${styles.nextBillingAmountText2} `}
                        >
                          {' '}
                          ${' '}
                          {this.state?.upcomingInv?.tax
                            ? this.state?.upcomingInv?.tax?.toFixed(2)
                            : 0}{' '}
                        </Col>
                      </Row>
                    )}
                  <Row
                    className={`billing_amount_overview ${styles.headingText}`}
                    style={{
                      position: 'absolute',
                      bottom: '30px',
                      fontSize: '1.5vw',
                      width: '90%',
                    }}
                  >
                    <Col span={16}>Next Billing Total:</Col>
                    <Col span={8} className={styles.nextBilling}>
                      ${' '}
                      {this.state.isPremium
                        ? this.state?.upcomingInv?.totalAmount?.toFixed(2)
                        : '0'}
                    </Col>
                  </Row>
                  {this.state.isPremium && this.state?.upcomingInv !== '' ? (
                    <Row
                      className={`site-card-wrapper-1 ${styles.headingText}`}
                      style={{
                        position: 'absolute',
                        bottom: '-5px',
                        width: '90%',
                      }}
                    >
                      <Col span={16}>
                        <p style={{ fontSize: '0.8em' }}>Next Billing Date:</p>
                      </Col>
                      <Col
                        style={{ display: 'flex', justifyContent: 'center' }}
                        span={8}
                      >
                        <p style={{ fontSize: '0.8em', marginLeft: '15px' }}>
                          {' '}
                          {moment(this.state?.upcomingInv?.createdAt).format(
                            'YYYY/MM/DD'
                          )}
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                </Col>
                <Col sm={1} xs={0}></Col>
                {/*======================sponsered section=================*/}
                <Col
                  sm={13}
                  xs={24}
                  className="billing_overview_card_list-1"
                  onClick={() =>
                    this.props.history.push({
                      pathname: '/company/billing/sponsered-list',
                    })
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <Row>
                    <p
                      className={`candidate_heading ${styles.headingText}`}
                      style={{ fontSize: '1.5vw' }}
                    >
                      Sponsored
                    </p>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {/* In this condition if the billingStats does not contain
                      any data then we use billing format data to show pie chart */}
                      {this.state.billingData?.data?.billingStats &&
                      this.state.billingData?.data?.billingStats.length > 1
                        ? this.state.billingData?.data?.billingStats
                            ?.filter((data) => data.name !== 'subuser')
                            ?.map((value) => {
                              return (
                                <Row style={{ margin: '0.5vw 0 0 0' }}>
                                  <Col
                                    span={12}
                                    style={{
                                      fontWeight: 'bolder',
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {value.name}:
                                  </Col>
                                  <Col
                                    span={12}
                                    style={{
                                      fontWeight: 'bolder',
                                      opacity: '0.6',
                                    }}
                                  >
                                    {value.t_c} Posts ${value.budget}
                                  </Col>
                                </Row>
                              );
                            })
                        : this.state.billingData?.data?.billingFormat
                        ? this.state.billingData?.data?.billingFormat
                            ?.filter((data) => data.name !== 'subuser')
                            ?.map((value) => {
                              return (
                                <Row style={{ margin: '0.5vw 0 0 0' }}>
                                  <Col
                                    span={12}
                                    style={{
                                      fontWeight: 'bolder',
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {value.name}:
                                  </Col>
                                  <Col
                                    span={12}
                                    style={{
                                      fontWeight: 'bolder',
                                      opacity: '0.6',
                                    }}
                                  >
                                    {value.t_c} Posts ${value.budget}
                                  </Col>
                                </Row>
                              );
                            })
                        : ''}
                    </Col>
                    <Col span={21} style={{ marginLeft: '-3vw' }}>
                      <Pie />
                    </Col>
                  </Row>
                  <Row className={`billing_amount_overview`}>
                    <Col
                      sm={12}
                      xs={12}
                      style={{ fontSize: '1.5vw', margin: '1vw 0 0 0' }}
                      className={styles.headingText}
                    >
                      {' '}
                      Total Amount:
                    </Col>
                    <Col
                      className={`${styles.headingText} ${styles.spoceredSectionTotalAmount}`}
                      sm={12}
                      xs={8}
                    >
                      {'$ ' +
                        (this.state.billingData?.data?.totalBilling
                          ? this.state.billingData?.data?.totalBilling
                          : '0')}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <Row style={{ paddingTop: 30 }}>
              <p className="billing_overview_invoice_1">Invoices</p>
              <Col sm={12} xs={20} className="billing_overview_input_and_sort">
                <Input
                  size="large"
                  placeholder="Search By Invoice Id"
                  className="billing-searchbox"
                  prefix={<SearchOutlined />}
                  onChange={(e) =>
                    this.setState({ serchQuery: e.target.value })
                  }
                />
                <Select
                  defaultValue="Sort"
                  className="billing-sort"
                  style={{ marginLeft: '12px' }}
                  onChange={this.handleChange}
                >
                  <Option value="-createdAt">Latest</Option>
                </Select>
              </Col>
              <Col sm={6} xs={0} />
            </Row>
            <div>
              {paidOrders &&
                paidOrders?.map((data) => {
                  return (
                    <>
                      <Row className="billing_overview_card_list-1">
                        <Col sm={6} xs={12}>
                          <p className="billing_overview_card_list-1-inner-para-3">
                            Id:{data.oid}
                          </p>
                        </Col>
                        <Col sm={10} xs={0}></Col>
                        <Col sm={4} xs={7} style={{ display: 'flex' }}>
                          <div className="">
                            <img
                              src={
                                process.env.PUBLIC_URL + '/Subtraction_276.svg'
                              }
                              alt='subtraction_276'
                            />
                          </div>

                          <a
                            style={{ cursor: 'pointer' }}
                            className="billing_overview_card_list-1-inner-para"
                            href={data?.invoiceLink}
                          >
                            {' '}
                            Print Invoice
                          </a>
                        </Col>
                        <Col sm={4} xs={5} style={{ textAlign: 'right' }}>
                          <p className="billing_overview_card_list-1-inner-para-2">
                            {moment(data.createdAt).format('YYYY/MM/DD')}
                          </p>
                        </Col>
                        <Row
                          className={`billing_amount_overview ${styles.headingText}`}
                        >
                          ${data.tax ? data.price + data.tax : data.price}
                        </Row>
                        <Row className="billing-overview-detail">
                          <Col span={6} xs={12}>
                            <h6>{companyName}</h6>
                            <p>Company</p>
                          </Col>
                          <Col span={6} xs={0}></Col>
                          <Col span={4} xs={0}></Col>
                          <Col span={4} xs={12}>
                            <h6>
                              {this.state.country?.split(',').at(-1) || ''}
                            </h6>
                            <p>Billing Country</p>
                          </Col>
                        </Row>
                      </Row>
                    </>
                  );
                })}
            </div>
            {paidOrders.length > 0 && (
              <div className={'d-flex justify-content-center pt-4'}>
                <span
                  className={
                    this.state.current_page === 1 ? 'pagination-side-btn' : ''
                  }
                  onClick={() =>
                    this.makeHttpRequestWithPage(this.state.current_page - 1)
                  }
                >
                  {' '}
                  <LeftOutlined />{' '}
                </span>
                <div>{renderPageNumbers}</div>
                <span
                  className={
                    this.state.current_page === this.state.totalPages
                      ? 'pagination-side-btn'
                      : ''
                  }
                  onClick={() =>
                    this.state.current_page !== this.state.totalPages &&
                    this.makeHttpRequestWithPage(this.state.current_page + 1)
                  }
                >
                  <RightOutlined />
                </span>
              </div>
            )}
          </Layout>
        </Layout>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.billingOverViewReducer.sub_data,
    isloading: state.billingOverViewReducer.isloading,
    orderdata: state.OrderReducer.orderdata,
  };
};
export default connect(mapStateToProps)(companyBillingOverview);
