import React, { useEffect, useRef, useState } from 'react';
import APIManager from '../../../APIManager';
import { Input, Select, AutoComplete } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import '../../screensOfTopNav/topNav.css';

const { Option } = Select;

export default function SelectSector(props) {
  const [sectOptions, setSectorOptions] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageNumber, setpageNumber] = useState(null);
  useEffect(() => {
    sectorList(1, search);
  }, [props]);

  const handleSectorChange = (value) => {
    setSearch(value);
    props.handleSectorChange(value);
  };

  const handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      if (sectOptions?.length > 0 && sectOptions[0]?.value) {
        handleSectorChange(sectOptions[0]?.value);
      }
    }
  };

  const handleSectorSearch = (value) => {
    // console.log('testing sector Search', value);

    sectorList(1, value);
    setSectorOptions([]);
  };

  const onScroll = (event) => {
    var target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = pageNumber + 1;
      sectorList(page, search);
    }
  };

  const sectorList = async(pageNumber = 1, search = '') => {
    setpageNumber(pageNumber);
    let options = [];
    await APIManager.resourceSuggestionList(pageNumber, search?.toLowerCase())
      .then((resp) => {
        resp.data.data?.map((data) => {
          options.push({
            label:
              data?.title
                ? data?.title
                : '',
            value: data?.title ? data?.title : '',
            key: data._id,
          });
        });
        if (options?.length > 0) {
          setSectorOptions(options);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <props.FormItem name="search">
      <AutoComplete
        maxTagCount={1}
        name={props.name}
        filterOption={false}
        onSelect={handleSectorChange}
        onKeyDown={handlePressEnter}
        style={{ float: 'right' }}
        onChange={handleSectorChange}
        onPopupScroll={onScroll}
        value={search}
        className="header-input-fields"
        options={sectOptions}
      >
        <Input
          value={search}
          prefix={
            <SearchOutlined className={'landing-icons-2 header-input-fields'} />
          }
          placeholder="Search"
        />
      </AutoComplete>
    </props.FormItem>
  );
}
