import React, { useEffect, useState } from 'react';
import './company.css';
import styles from './company_profile.module.css';
import { Spin, Row, message, Tooltip, Col } from 'antd';
import { fileUrl } from '../../Shared/imageUrlPath';
import APIManager from '../../APIManager';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cardDefaultPic from '../asset/card.svg';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import NoDataFound from '../../PageNotFound/NoData.js';

const CompanyJobs = (props) => {
  const [jobData, setJobData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [totalCount, setTotalCount] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  useEffect(() => {
    var ls = require('local-storage');
    const id = ls.get('compId');
    getJobs(id, '1');
  }, []);
  const getJobs = (companyId, pageNumber) => {
    setisLoading(true);
    APIManager.getJobsofCompany(companyId, pageNumber)
      .then((resp) => {
        if (resp.data.isSuccess) {
          setJobData(resp.data.data);
          setisLoading(false);
          setCurrentPage(resp.data.currentPage);
          setTotalCount(resp.data.totalCount);
          setTotalPages(resp.data.totalPages);
          setisLoading(false);
        }
      })
      .catch((error) => {
        setisLoading(false);
        message.info('Something went wrong.');
      });
  };
  let renderPageNumbers;
  const pageNumbers = [];
  var ls = require('local-storage');
  const id = ls.get('compId');
  if (totalCount !== null) {
    for (let i = 1; i <= Math.ceil(totalCount / 10); i++) {
      pageNumbers.push(i);
    }
    renderPageNumbers = pageNumbers?.map((number) => {
      return (
        <span
          key={number}
          onClick={() => getJobs(id, number)}
          className={
            currentPage === number ? 'page-number-btn' : 'pagination-container'
          }
        >
          {number}
        </span>
      );
    });
  }

  return (
    <>
      {isLoading && (
        <div className={'d-flex justify-content-center'}>
          <Spin />
        </div>
      )}
      <h6 className="about-company w-100">
        {jobData.length >= 1 ? `${jobData.length} Jobs listed` : ''}
      </h6>
      <div className="col-sm-12 p-0 text-left">
        {jobData &&
          jobData?.map((data, index) => (
            <div
              className="flex-container"
              key={index}
              onClick={() => props.history.push(`/apply-for/${data._id}`)}
            >
              <Row
                className={`jobListingclscard-1 job-card-2 mt-3 ${styles.companyJobTabPadding}`}
                gutter={15}
              >
                <Col sm={4} xs={7}>
                  <img
                    className={styles.Image}
                    alt="example"
                    src={
                      data.job_logo
                        ? fileUrl.fileUrlPath + data.job_logo
                        : data?.companyDetail?.[0]?.logo?.name
                        ? fileUrl.fileUrlPath +
                          data?.companyDetail?.[0]?.logo?.name
                        : cardDefaultPic
                    }
                  />
                </Col>
                <Col sm={19} xs={17}>
                  <Row>
                    <Col sm={16} xs={24}>
                      {data.title.length > 30 ? (
                        <Tooltip
                          className={`job-card-3 ${styles.companyJobsCardTitle}`}
                          title={data.title}
                        >
                          <span style={{ textTransform: 'capitalize' }}>
                            {data.title.substr(0, 29)}...
                          </span>
                        </Tooltip>
                      ) : (
                        <span
                          className={`job-card-3 ${styles.companyJobsCardTitle}`}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {data.title}
                        </span>
                      )}
                    </Col>
                    <Col sm={8} className={styles.jobType}>
                      <div
                        className={`job-card-7 ${styles.permanentMarginTop}  ${styles.sponceredTextSize}`}
                      >
                        {data?.job_type?.toUpperCase()}
                      </div>
                      <div className={`job-card-8 ${styles.sponceredTextSize}`}>
                        {console.log("data",data)}
                        {data?.isSponsored ? 'SPONSERED' : ''}
                      </div>
                    </Col>
                  </Row>
                  <Row className="job-card-6">
                    <Col sm={24} xs={24} className={styles.companyJobsCardText}>
                      <img
                        src={process.env.PUBLIC_URL + '/location-red.png'}
                        alt="img"
                      />
                      &nbsp;&nbsp;
                      {data?.companyDetail?.[0]?.contact_info?.sub_address}
                    </Col>
                    <Col sm={10} xs={24} className={styles.companyJobsCardText}>
                      <img
                        src={process.env.PUBLIC_URL + '/clock-red.png'}
                        alt="img"
                      />
                      &nbsp;&nbsp;
                      {data.daysBeforePosted !== 0
                        ? `${data.daysBeforePosted} days ago`
                        : 'today'}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          ))}
        {/*============= pagination section ===================*/}
        {jobData.length > 0 ? (
          <div className={'d-flex justify-content-center pt-4'}>
            <span
              className={currentPage === 1 ? 'pagination-side-btn' : ''}
              onClick={() => getJobs(currentPage - 1)}
            >
              {' '}
              <LeftOutlined />{' '}
            </span>
            <div>{renderPageNumbers}</div>
            <span
              className={
                currentPage === totalPages ? 'pagination-side-btn' : ''
              }
              onClick={() =>
                currentPage !== totalPages && getJobs(currentPage + 1)
              }
            >
              <RightOutlined />
            </span>
          </div>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.companyInfoReducer.data,
  };
};
export default withRouter(connect(mapStateToProps)(CompanyJobs));
