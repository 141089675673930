import React, { Component } from 'react';
import './login.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import APIManager from '../../APIManager/index';
import { message, Spin } from 'antd';
import { connect } from 'react-redux';
import LoginCover from '../../Components/asset/login_cover.png';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isloading: false
        }
    }
    goSignup() {
        this.props.history.push('/signup');
    }
    handleResetPassword = (values) => {
        const { token } = this.props.match.params;
        this.setState({ isloading: true })
        APIManager.resetPassword(values, token).then((response) => {
            this.setState({ isloading: false });
            message.info("Your password is changed.");
            this.props.history.push("/login");
        })
            .catch((error) => {
                this.setState({ isloading: false })
                message.info("Something went wrong.")
            })
    }
    render() {
        return (
            <>

                <Formik
                    initialValues={{ password: "", confirmPassword: "" }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            setSubmitting(false);
                            this.handleResetPassword(values);
                        }, 500);
                    }}
                    validationSchema={Yup.object().shape({
                        password: Yup.string().required('password is required.').min(6, "too short"),
                        confirmPassword: Yup.string()
                            .required('confirm password is required.')
                            .min(6, 'too short')
                            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                    })}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleSubmit,
                        } = props;
                        return (
                            <>
                                <div className="row custom_row">
                                    <div className="col-sm-6 login-left text-left px-left-login px-top-login">
                                        <div className="go-back ">
                                            <img
                                                className="cursor"
                                                onClick={() => this.props.history.push('/')}
                                                src={process.env.PUBLIC_URL + '/go-back-1.png'}
                                            />
                                        </div>
                                        {
                                            this.state.isloading &&
                                            <div className={'d-flex justify-content-center'}>
                                                <Spin />
                                            </div>
                                        }
                                        {/*<p className="go-back"><i class="fa fa-angle-left" aria-hidden="true"></i>&nbsp;&nbsp;Go back</p>*/}
                                        <ul className="sigunp-logo-with-name">
                                            <li>
                                                <img
                                                    className="w-100"
                                                    src={process.env.PUBLIC_URL + '/db_login.png'}
                                                    alt="logo"
                                                />
                                            </li>
                                            <li style={{ paddingLeft: 10 }}>
                                                <h5
                                                    style={{
                                                        fontWeight: 'bolder',
                                                        fontFamily: 'Gilroy Bold',
                                                        fontSize: 26,
                                                        paddingTop: 8,
                                                    }}
                                                >
                                                    SENDBIZ
                      </h5>
                                            </li>
                                        </ul>
                                        <h6
                                            style={{
                                                fontSize: 26,
                                                fontWeight: 'bolder',
                                                marginTop: 30,
                                                fontFamily: 'Gilroy Bold',
                                            }}
                                            className="welcome_msg"
                                        >
                                            Welcome back !
                  </h6>

                                        <p style={{ marginTop: 30, fontFamily: 'Gilroy medium' }}>
                                            You can reset your password
                                    </p>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group login-form-group">
                                                <label htmlFor="exampleInputEmail1">Password</label>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    className="input-font form-control login-form-control"
                                                    onChange={handleChange}
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"

                                                />
                                                {errors.password && (
                                                    <div style={{ color: 'red', fontSize: '12px' }}>
                                                        {errors.password}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group login-form-group">
                                                <label htmlFor="exampleInputEmail1">Confirm Password</label>
                                                <input
                                                    type="password"
                                                    name="confirmPassword"
                                                    className="input-font form-control login-form-control"
                                                    onChange={handleChange}
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"

                                                />
                                                {errors.confirmPassword && (
                                                    <div style={{ color: 'red', fontSize: '12px' }}>
                                                        {errors.confirmPassword}
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className="form-group"
                                                style={{ marginBottom: '40px' }}
                                            >
                                            </div>
                                            <div className="cent_button">
                                                <button
                                                    type="submit"
                                                    className="btn btn-dark login-button-font"
                                                >
                                                    Next
                      </button>
                                            </div>
                                        </form>
                                        <h6 className="login-tag">
                                            Don't have an account? Create your account,{' '}
                                            <small
                                                className="cursor red-link"
                                                onClick={this.goSignup.bind(this)}
                                                style={{ color: '#B02318' }}
                                            >
                                                Signup
                    </small>{' '}
                    now.
                  </h6>
                                    </div>
                                    <div className={'login-container'}>
                                        {/* <img
                    className="login_div_img_1"
                    src={process.env.PUBLIC_URL + '/campaign_creators@2x.png'}
                    alt="campaign"
                  /> */}
                                        <img className="login_div_img_1" src={LoginCover} />
                                    </div>
                                </div>
                            </>
                        );
                    }}
                </Formik>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.AuthReducer.user,
        isloading: state.AuthReducer.isloading,
    };
};
export default connect(mapStateToProps)(ResetPassword);
