import React, { Component, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Badge,
  Switch,
  Form,
  notification,
  message,
  Modal
} from 'antd';
import PaymentModalCard from '../paymentCard/selectPayment';
import Header from '../header/header.js';
import Footer from './footer';
import './Landing.css';
import './footer.css';
import styles from './plan_cards.module.css';
import { DownOutlined, CheckCircleFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import APIManager from '../../APIManager';
import { withRouter, useHistory, useParams } from 'react-router-dom';
// import { Modal } from 'react-bootstrap';
const Option = { Select };

const PlanCards = (props) => {
  const [isPremium, setIsPremium] = useState('');
  const [userCount, setUserCount] = useState(
    props?.userCount ? props?.userCount : 0
  );
  const history = useHistory();
  //changed input value
  console.log(history);
  const [isSwitchToFreemium, setIsSwitchToFreemium] = useState(true);
  useEffect(() => {
    APIManager.companyInfo().then((response) => {
      if (response.data.isSuccess) {
        // ls.set('userCount',response.data.data.data.UserCount)
        setIsPremium(response.data.data.data.isPremium);
        setUserCount(response.data.data.data.UserCount);
        setIsSwitchToFreemium(response.data.data.data.isSwitchFreemium);
      }
    });
  }, [isSwitchToFreemium]);
  useEffect(() => {
    const params = {
      userCount: userCount,
      isMonthly: isMonthlyPlan,
    };
    APIManager.taxInfo(params).then((response) => {
      if (response?.data) {
        setTaxData(response?.data?.data[0]);
      }
    });
    const success = history?.location?.search?.split('=')?.[1];
    if(success === 'true'){
      message.success('Premium plan successfully purchased!')
    }
    if(success === 'false'){
      message.error('Purchase failed!,Something went wrong please try again later.')
    }
  }, []);
  var ls = require('local-storage');
  const [PaymentModal, showPaymentModal] = useState(false);
  const isMonthly = props.user ? !props.user.data.data.isMonthlyPremium : true;
  const [isMonthlyPlan, setisMonthlyPlan] = useState(true);

  const [switchPlanModal, setSwitchPlanModal] = useState(false);
  const [taxData, setTaxData] = useState({});
  // const isSwitchToFreemium = props?.user?.data?.data?.isSwitchFreemium || true;

  ls.set('setisMonthlyPlan', isMonthlyPlan);
  // console.log(isMonthlyPlan, 'isMonthlyPlan-=-=-=');
  const switchPlan = (checked) => {
    // console.log(checked, 'checked');
    setisMonthlyPlan(!isMonthlyPlan);

    var ls = require('local-storage');
    window.localStorage.setItem('setisMonthlyPlan', isMonthlyPlan);
    // ls.set('setisMonthlyPlan', isMonthlyPlan);
    if (checked) {
    }
  };

  var ls = require('local-storage');
  const token = ls.get('token');
  const renderPlanSection = () => {
    if (token) {
      showPaymentModal(true);
    } else {
      props.history.push('/login');
    }
  };
  const renderPlanSectionFree = () => {
      props.history.push('/signup');
  };

  const proceedToFreemium = () => {
    APIManager.switchToFreemium()
      .then((response) => {
        setIsSwitchToFreemium(true);
        message.success(
          'Your Plan will switch to freemium after current plan expires'
        );
      })
      .catch((err) => {
        message.error('Something went wrong please try again');
      })
      .finally(() => {
        setSwitchPlanModal(false);
      });
  };

  return (
    <>
      {PaymentModal ? (
        <PaymentModalCard
          show={PaymentModal}
          onHide={() => showPaymentModal(false)}
          isMonthlyPlan={isMonthlyPlan}
          userCount={userCount}
          taxData={taxData}
        />
      ) : (
        ''
      )}
      <Modal
        title="Switch Plan to Freemium"
        open={switchPlanModal}
        onCancel={() => setSwitchPlanModal(false)}
        className="switch-plan-modal"
        onOk={proceedToFreemium}
      >    
          <p>Do you really want to switch to freemium Account !</p>
          <p>
            Your plan will switch to freemium after your current subscription
            expires !
          </p>
      </Modal>
      <Row className={styles.planCardsParents}>
        <Col sm={4} xs={0}></Col>

        <Col sm={7} xs={24} className={styles.planCard1}>
          {!isPremium && (
            <img
              className="current-img"
              src={process.env.PUBLIC_URL + '/current-plan.png'}
            />
          )}
          <div className="plan-heading mt-5">FREEMIUM PLAN</div>
          <div className="plan-free">FREE</div>
          <div className="plan-points">
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Creation of the free company page{' '}
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited Media{' '}
              </span>
            </div>

            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited No. of posts{' '}
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited no. of resumes{' '}
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited no. of responses
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited interest on products
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited interest for services
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited no. of users
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                5 Job Offers/Products/Services
              </span>
            </div>
            {/* <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                5 Products or Services
              </span>
            </div> */}
            {!token ? (
              <div style={{ marginTop: '120px' }}>
                <Button
                  className={`buy-now  ${styles.buyNowButton}`}
                  onClick={renderPlanSectionFree}
                  disabled={isPremium}
                >
                  Start for FREE
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </Col>
        <Col sm={7} xs={24} className={styles.planCard2}>
          <div className="current-img" />
          {isPremium && (
            <img
              className="current-img"
              src={process.env.PUBLIC_URL + '/current-plan.png'}
            />
          )}
          <div className="plan-heading mt-5">ESSENTIAL PLAN</div>
          {isMonthlyPlan ? (
            <div className="plan-free">
              <span className="plan-dollar">$</span>
              <b>15</b>
              <span className="plan-month">/MO.</span>
            </div>
          ) : (
            <div className="plan-free">
              <span className="plan-dollar">$</span>
              <b>120</b>
              <span className="plan-month">/Yr.</span>
            </div>
          )}
          <div className="plan-points" style={{ marginTop: '30px' }}>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Creation of the free company page{' '}
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited Media{' '}
              </span>
            </div>

            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited No. of posts{' '}
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited no. of resumes{' '}
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited no. of responses
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited interest on products
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited interest for services
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited no. of users
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited Job Offers
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">
                Unlimited Products or Services
              </span>
            </div>
            <div className="unlimited-div">
              <CheckCircleFilled className="caters-icons plan-icons" />
              <span className="caters-subtext-2 plan-items">1 User</span>
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            {isPremium ? (
              <>
                {!isSwitchToFreemium ? (
                  <Button
                    className={`buy-now  ${styles.buyNowButton}`}
                    onClick={() => setSwitchPlanModal(true)}
                  >
                    Switch to Freemium
                  </Button>
                ) : (
                  <Button
                    className={`buy-now  ${styles.buyNowButton}`}
                    onClick={renderPlanSection}
                    disabled={isPremium}
                  >
                    Buy Now
                  </Button>
                )}
              </>
            ) : (
              <Button
                className={`buy-now  ${styles.buyNowButton}`}
                onClick={renderPlanSection}
                disabled={isPremium}
              >
                Buy Now
              </Button>
            )}

            <div className={`plan-toggle ${styles.planToggle}`}>
              Monthly&nbsp;&nbsp;
              <Switch
                defaultValue={isMonthlyPlan}
                onChange={switchPlan}
                disabled={isPremium}
              />
              &nbsp;&nbsp;Yearly
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user,
    isloading: state.AuthReducer.isloading,
  };
};
export default withRouter(connect(mapStateToProps)(PlanCards));
