import React, { Component } from 'react';
import { Row, Col, Spin, Card } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { ImageUrl } from '../../../Shared/imageUrlPath';
import userDefaultPic from '../../asset/user.svg'
import NoData from '../../../PageNotFound/NoData';


class JobCards extends Component {
  showCandidateInfo = () => {
    //  this.props.history.push('./Applied-company_info');
  };
  render() {
    const appliedJobData = this.props.data ? this.props.data.data : '';
    const loading = this.props.isloading ? this.props.isloading : false;
    const { searchQuery } = this.props;
    return (
      <>
        <div className={'d-flex justify-content-center'}>
          {loading && <Spin />}
        </div>
        {(appliedJobData.length === 0 || appliedJobData === null) && loading === false ? <div><NoData /></div> : appliedJobData &&
          appliedJobData?.map((data, index) => (
            <>
              {/*<Row
                  className={
                    'col-sm-12 jobListingclscard-1 resume-card-2 mt-3 p-0'
                  }
                  onClick={() =>
                    this.props.history.push({
                      pathname: '/applied-job-detail',
                      state: { jobId: data._id },
                    })
                  }
                >
                  <div
                    className="col-sm-2 resume-custom-col-2 text-center"
                    style={{ padding: '20px 30px' }}
                  >
                    <Image
                      className="job-card-image-1"
                      src={process.env.PUBLIC_URL + '/Rectangle@2x.png'}
                    />
                  </div>
                  <div className="col-sm-10 resume-custom-col-10 resume-col-10">
                    <Row>
                      <div className="col-sm-9 col-xs-9 p-0">
                        <p className="resume-card-4">
                          {' '}
                          {data.candidate.current_company}&nbsp;&nbsp;
                          <div className="resume-card-3">
                            <img src={process.env.PUBLIC_URL + '/user.png'} />
                            <span>03</span>
                          </div>
                        </p>
                        <p className="job-card-4">
                          {data.candidate.current_position}
                        </p>
                        <p className="job-card-5">
                          <img
                            src={process.env.PUBLIC_URL + '/location-red.png'}
                          />
                          &nbsp;&nbsp;{data.candidate.location}
                        </p>
                      </div>
                    </Row>
                    <ul className="job-card-6">
                      <li>
                        <p>
                          <img
                            src={process.env.PUBLIC_URL + '/Group 363-red.png'}
                          />
                          &nbsp;&nbsp;Less than 3 months
                        </p>
                      </li>
                      <li>
                        <p>
                          <img
                            src={process.env.PUBLIC_URL + '/clock-red.png'}
                          />
                          &nbsp;&nbsp;
                          {moment(data.applied_at).format('DD MMM YY')}
                        </p>
                      </li>
                    </ul>
                  </div>
                </Row>*/}
              <Card className="services-card" key={index}>
                <Row onClick={() =>
                  this.props.history.push({
                    pathname: '/applied-job-detail',
                    state: { jobId: data._id },
                  })
                }>
                  <Col span={4}>
                    <img
                      alt="example"
                      src={
                        data.job.job_logo
                          ? ImageUrl.imageUrlPath + data.job.job_logo
                          : userDefaultPic
                      }
                      style={{ width: '100%', height: '120px', borderRadius : '10px', objectFit : 'cover' }}
                    />
                  </Col>
                  <Col span={20} style={{ paddingLeft: '20px' }} className="service-detail-row">
                    <Row justify="space-between">
                      <Col span={17}>
                        <div className="service-name" style={{ paddingBottom: "3px" }}>
                          {' '}
                          <Row>
                            <Col span={8}>
                              <div style={{fontSize : '1.5rem'}}>
                                {data.company && data.company.comp_info.comp_name ? data.company.comp_info.comp_name : ''}&nbsp;&nbsp;
                              </div>
                            </Col>
                            <Col span={14}>
                              <div className="resume-card-3">
                                <img src={process.env.PUBLIC_URL + '/user.png'} />&nbsp;
                                <span>{data.count ? data.count : ''}</span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <p className="job-card-4" style={{textTransform: 'capitalize'}}>
                          {data.job.title}
                        </p>
                      </Col>
                      <Col span={7} className="icon-col">

                      </Col>
                    </Row>
                    <Row>
                      <Col span={18}>
                        <span>
                          <img
                            src={
                              process.env.PUBLIC_URL + '/location-red.png'
                            }
                            style={{ width: 14 }}
                          />
                        </span>
                        <span
                          className="service-s-desc"
                          style={{
                            paddingLeft: '8px',
                            verticalAlign: 'middle',
                          }}
                        >
                          {data.company ? `${data.company.contact_info ? (data.company.contact_info.city).charAt(0).toUpperCase() + (data.company.contact_info.city).slice(1) : ""}, ${data.company.contact_info ? (data.company.contact_info.country).charAt(0).toUpperCase() + (data.company.contact_info.country).slice(1) : ""}` : ''}
                        </span>
                      </Col>
                    </Row>
                    <Row align="bottom" style={{ marginTop: '10px' }}>
                      {/* <Col span={7}>
                          <img
                            src={process.env.PUBLIC_URL + '/clock-red.png'}
                            className="service-icons"
                            style={{ height: '16px', verticalAlign: 'super' }}
                          />
                          <div className="service-location">
                            {moment(data.appliedAt).fromNow()}
                          </div>
                        </Col> */}

                      <Col span={12} style={{marginTop : '8px'}}>
                        <img
                          src={process.env.PUBLIC_URL + '/clock-red.png'}
                          className="service-icons"
                          style={{ height: '16px', verticalAlign: 'super' }}
                        />
                        <div className="service-location">
                          {moment(data.appliedAt).format('DD MMM\'YY')}
                        </div>
                      </Col>
                      <Col span={2}>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </>
          ))}

      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.appliedJobsReducer.data,
    isloading: state.appliedJobsReducer.isloading,
  };
};
export default withRouter(connect(mapStateToProps)(JobCards));
