import React, { useState, useEffect } from 'react';
import SponsoredListCard from './sponsoredListCard';
import APIManager from '../../APIManager';
import { Spin } from 'antd';
import NoDataFound from '../../PageNotFound/NoData';

const SponsoredJobs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    APIManager.getSponsoredJobs()
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);
  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        <div>
          {!data.length ? <NoDataFound /> : data?.map(
            ({
              title,
              sponsoredBy,
              sponsoredOn,
              sponsorExpiresAt,
              totalViewsCount,
              initialBudget,
              remaningBudget,
              job_logo,
              compLogo,
              serviceStatus,
            }) => (
              <SponsoredListCard
                name={title}
                sponsoredBy={sponsoredBy}
                sponsoredOn={sponsoredOn}
                sponsorExpiresAt={sponsorExpiresAt}
                totalViewsCount={totalViewsCount}
                initialBudget={initialBudget}
                remaningBudget={remaningBudget}
                imageUrl={job_logo||compLogo}
                serviceStatus = { serviceStatus }
              />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default SponsoredJobs;
