import APIManager from '../../APIManager/index';
import {notification} from 'antd';

export default function checkPremium(element,history){
    //element can be JOBs,SERVICEs OR PRODUCTs
    
   return APIManager.checkPremiumDetail().then((response) => {
        if (!response.data.data[element]) {
            notification.warning({
                message: 'Please upgrade your plan to premium.',
                description: 'Click here to upgrade your plan or delete/deactivate some jobs',
                duration:2,
                onClick: () => {
                    console.log("running");
                    history.push('/package-plan');
                },
            });
        }
        return response.data.data[element] ?? false;
      });
}