import axios from 'axios';
import { dispatchAction } from '../Redux/Store/index';
import { updateUser } from '../Redux/Actions/AuthAction';
import HELPERS from './helper';
import {
  jobManagementData,
  myjobManagementData,
  appliedCandidatesData,
  JobsAppliedbyCompany,
  getjobDescription,
  getOneAppliedJob,
  getCompanyList,
  resourceManagementData,
} from '../Redux/Actions/JobManagementAction';
import {
  getServiceList,
  getAllServiceList,
  getOneService,
} from '../Redux/Actions/serviceAction';
import { updateCandidateResume } from '../Redux/Actions/candidate_resumeAction';
import { getAppliedJobs } from '../Redux/Actions/AppliedJobsActions';
import { createUser } from '../Redux/Actions/createUserAction';
import {
  companyInfoData,
  getCompanyProfileInfo,
  addSubUserOverView,
} from '../Redux/Actions/companyInfoAction';
import { getSubUser } from '../Redux/Actions/billingOverViewAction';
import { getAppliedOnJobUser } from '../Redux/Actions/applied_on_jobAction';
import {
  updateCandidateInfo,
  changePassword,
  deleteResume,
  getAppliedCandidateJobs,
  getFavouriteJobs,
  addFavouriteJobs,
  deleteFavouriteJobs,
} from '../Redux/Actions/candidateInfoSubmitAction';
import {
  getEventList,
  getEventDetail,
  getcompanyEventList,
} from '../Redux/Actions/eventsAction';
import { companyCoverUpdate } from '../Redux/Actions/companyCoverUpdateAction';
import {
  applyForJob,
  applyJobStatus,
} from '../Redux/Actions/applyForJobAction';
import { updateCandidatureInfo } from '../Redux/Actions/updateCandidatureAction';
import { companyMediaUpload } from '../Redux/Actions/companyMediaUploadAction';
import { companyServicesData } from '../Redux/Actions/companyServicesAction';
import { companyProductsData } from '../Redux/Actions/companyProductsAction';
import { getallNewsList, getonenews } from '../Redux/Actions/newsActions';
import { getstatsData } from '../Redux/Actions/statsAction';
import { getAllChannel } from '../Redux/Actions/mailBoxAction';
import { checkforChannel } from '../Redux/Actions/checkChannel';
import { getAllMessages } from '../Redux/Actions/messagesAction';
import { getAllOrders } from '../Redux/Actions/OrderActions';

var cancel;
class APIManager {
  constructor() {
    var ls = require('local-storage');
    var tok = tok ? ls.get('token') : '';
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        Authorization: tok ? `Bearer ${tok}` : '',
      },
    });
  }

  converToFormData = (obj, rootName, ignoreList) => {
    var formData = new FormData();
    function appendFormData(data, root) {
      if (!ignore(root)) {
        root = root || '';
        if (data instanceof File) {
          formData.append(root, data);
        } else if (Array.isArray(data)) {
          for (var i = 0; i < data.length; i++) {
            appendFormData(data[i], root + '[' + i + ']');
          }
        } else if (typeof data === 'object' && data) {
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              if (root === '') {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], root + '.' + key);
              }
            }
          }
        } else {
          if (data !== null && typeof data !== 'undefined') {
            formData.append(root, data);
          }
        }
      }
    }
    function ignore(root) {
      return (
        Array.isArray(ignoreList) &&
        ignoreList.some(function (x) {
          return x === root;
        })
      );
    }
    appendFormData(obj, rootName);
    return formData;
  };

  jobList = (
    page,
    comp_id,
    job_type,
    experience,
    posted_on,
    searchQuery,
    sector,
    city,
    state,
    country,
    homeSearch,
    searchLocation
  ) => {
    let query_params = '';
    if (searchQuery !== undefined && searchQuery !== '') {
      query_params = `&search=${searchQuery}`;
    }
    if (comp_id !== undefined && comp_id !== '') {
      query_params = `&comp_id=${comp_id}`;
    }
    if (job_type !== undefined && job_type !== '') {
      query_params = `${query_params}&job_type=${job_type}`;
    }
    if (experience !== undefined && experience !== '') {
      query_params = `${query_params}&experience=${experience}`;
    }
    if (posted_on !== undefined && posted_on !== '') {
      query_params = `${query_params}&publish_from[gte]=${posted_on};`;
    }
    if (sector !== undefined) {
      query_params =
        sector.length > 0
          ? `${query_params}&category=${sector}`
          : `${query_params}`;
    }
    if (city !== undefined && city !== '') {
      query_params = `${query_params}&city=${city.trim().toLowerCase()}`;
    }
    if (state !== undefined && state !== '') {
      query_params = `${query_params}&state=${state.trim().toLowerCase()}`;
    }
    if (country !== undefined && country !== '') {
      query_params = `${query_params}&country=${country.trim().toLowerCase()}`;
    }
    if (homeSearch !== undefined && homeSearch !== '') {
      query_params = `${query_params}&homeSearch=${homeSearch}`;
    }
    if (searchLocation !== undefined && searchLocation !== '') {
      query_params = `${query_params}&searchLocation=${searchLocation}`;
    }
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`jobs?limit=20&page=${page ? page : 1}${query_params}`)
        .then((response) => {
          dispatchAction(jobManagementData(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  companyList = async (
    page,
    search,
    city,
    state,
    country,
    sector,
    homeSearch,
    searchLocation
  ) => {
    let query_params = '';
    if (search !== undefined && search !== '') {
      query_params = `&search=${search.trim()}`;
    }
    if (state !== undefined && state !== '') {
      query_params = `${query_params}&state=${state.trim().toLowerCase()}`;
    }
    if (city !== undefined && city !== '') {
      query_params = `${query_params}&city=${city.trim().toLowerCase()}`;
    }
    if (country !== undefined && country !== '') {
      query_params = `${query_params}&country=${country.trim().toLowerCase()}`;
    }
    if (sector !== undefined && sector !== '') {
      query_params = `${query_params}&${sector}`;
    }
    if (homeSearch !== undefined && homeSearch !== '') {
      query_params = `${query_params}&homeSearch=${homeSearch}`;
    }
    if (searchLocation !== undefined && searchLocation !== '') {
      query_params = `${query_params}&searchLocation=${searchLocation}`;
    }
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`company_list?limit=20&page=${page}${query_params}`)
        .then((response) => {
          dispatchAction(getCompanyList(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  serviceList = (page) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`services/company?limit=10&page=${page}`)
        .then((response) => {
          dispatchAction(getServiceList(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  allNewsList = (page) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`news?limit=12&page=${page}`)
        .then((response) => {
          dispatchAction(getallNewsList(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  newsList = (page) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`news/company?limit=10&page=${page}`)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  contactToSendbiz = (data) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`contact-us`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  };

  getAllOrders = async (sort, searchQuery, page) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/orders?sort=${sort}&oid=${searchQuery}&page=${page}&limit=10`)
        .then((response) => {
          resolve(response);
          dispatchAction(getAllOrders(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
  addService = async (params) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`services`, params)
        .then((response) => {
          // dispatchAction(getServiceList(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  addEvent = async (params) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/events`, params)
        .then((response) => {
          // dispatchAction(getServiceList(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  addNews = async (params) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/news`, params)
        .then((response) => {
          // dispatchAction(getServiceList(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  likeEvent = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`events/${id}/likes`)
        .then((response) => {
          // dispatchAction(getServiceList(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  commentEvent = (id, params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`events/${id}/comment/`, params)
        .then((response) => {
          // dispatchAction(getServiceList(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  changeActiveStatus = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`services/${id}/toggle-status`)
        .then((response) => {
          // dispatchAction(getServiceList(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  newsActiveStatus = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`news/${id}/toggle-status`)
        .then((response) => {
          // dispatchAction(getServiceList(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  productActiveStatus = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`products/${id}/toggle-status`)
        .then((response) => {
          // dispatchAction(getServiceList(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  ResourceActiveStatus = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`resources/${id}/toggle-status`)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  deleteJob = (id) => {
    return new Promise((resolve, reject) => {
      const param = { isDeleted: true };
      this.axiosInstance
        .delete('jobs/' + id, param)
        .then((response) => {
          dispatchAction(getjobDescription(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  stopSponsor = (id, type) => {
    return new Promise((resolve, reject) => {
      const param = { type, isSponsored: false };
      this.axiosInstance
        .patch('stopSponsor/' + id, param)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  deleteIntersted = (id, entity) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`interested/${entity}/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  editSubcontractorJob = (id, params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('subcontractors/' + id, params)
        .then((response) => {
          // dispatchAction(getjobDescription(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  editJob = (id, params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('edit_job/' + id, params)
        .then((response) => {
          // dispatchAction(getjobDescription(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  getImagefromPresignedUrl = (key) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`https://sendbizbucket.s3.eu-west-3.amazonaws.com/${key}`)
        .then((response) => {
          // dispatchAction(getjobDescription(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  sortEvents = (sort, searchQuery, pageNumber) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(
          `events/company?search=${searchQuery}&sort=${sort}&limit=10&page=${pageNumber}`
        )
        .then((response) => {
          // dispatchAction(getjobDescription(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  sortProducts = (sort, searchQuery, pageNumber) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(
          `products/company?search=${searchQuery}&sort=${sort}&limit=10&page=${pageNumber}`
        )
        .then((response) => {
          // dispatchAction(getjobDescription(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  sortNews = (sort, searchQuery, pageNumber) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(
          `news/company?search=${searchQuery}&sort=${sort}&limit=10&page=${pageNumber}`
        )
        .then((response) => {
          // dispatchAction(getjobDescription(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  sortServices = (sort, searchQuery, pageNumber) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(
          `services/company?search=${searchQuery}&sort=${sort}&limit=10&page=${pageNumber}`
        )
        .then((response) => {
          // dispatchAction(getjobDescription(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  getJobDescription = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('jobs/' + id)
        .then((response) => {
          dispatchAction(getjobDescription(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  getStaticsData = (type) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`get-view-stats`, { params: type })
        .then((response) => {
          dispatchAction(getstatsData(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  appliedJobs = (search, sort, pageNumber) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(
          `applied_on_user_job/2?search=${search}&sort=${sort}&page=${pageNumber}`
        )
        .then((response) => {
          dispatchAction(getAppliedJobs(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  changeJobActiveStatus = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch(`job_active_status/${id}`)
        .then((response) => {
          // dispatchAction(getAppliedJobs(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  myJobs = (page) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`myjobs?limit=10&page=${page}`)
        .then((response) => {
          dispatchAction(myjobManagementData(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  createInterested = (param) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/interested/${param.category}`, { interestId: param.interestId })
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  addressInterested = (id) => {
    console.log(id, 'id bez rhe hai jo wo');
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch(`interested/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  createMessage = (param) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/mailbox/message`, param)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  resetUnseenCount = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`mailbox/message/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  listChannnel = (page, search) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`mailbox/message?limit=10&page=${page}&search=${search}`)
        .then((response) => {
          resolve(response);
          dispatchAction(getAllChannel(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
  checkChannel = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`mailbox/message/channel/${id}`)
        .then((response) => {
          resolve(response);
          dispatchAction(checkforChannel(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
  changePasswordforCompany = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('/change_password', params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  listChat = (id, page) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`mailbox/message/${id}?page=${page}&limit=10`)
        .then((response) => {
          resolve(response);
          dispatchAction(getAllMessages(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
  getInterstedList = (pageNumber, category) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/interested/${category}?limit=10&page=${pageNumber}`)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  Login = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('login', params)
        .then((response) => {
          dispatchAction(updateUser(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  forgetPassword = (param) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('forgot_password', param)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  resetPassword = (param, token) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch(`reset_password/${token}`, param)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  Signup = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('register', params)
        .then((response) => {
          dispatchAction(createUser(response.data));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getAppliedCandidates = (sortingvalue, searchingvalue, pageNumber) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(
          `applied_on_my_job?applied_by_type=1&sort=${sortingvalue}&search=${searchingvalue}&limit=10&page=${pageNumber}`
        )
        .then((response) => {
          resolve(response);
          dispatchAction(appliedCandidatesData(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };

  getAllServices = (page, price, search, company) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`services?limit=10`)
        .then((response) => {
          resolve(response);
          dispatchAction(getAllServiceList(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
  getAllEvents = (page, date, search) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`events?limit=10&page=${page}&sort=${date}&search=${search}`)
        .then((response) => {
          resolve(response);
          dispatchAction(getEventList(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
  InterstedJobs = (pageNumber) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/interested/my/job?limit=10&page=${pageNumber}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getJobsofCompany = (id, pageNumber) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`jobs?comp_id=${id}&limit=10&page=${pageNumber}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getProductofCompany = (id, pageNumber) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`products?companyId=${id}&limit=10&page=${pageNumber}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getServicesofCompany = (id, pageNumber) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`services?companyId=${id}&limit=10&page=${pageNumber}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getNewsofCompany = (id, pageNumber) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`news?companyId=${id}&limit=10&page=${pageNumber}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getEventsofCompany = (id, pageNumber) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`events?companyId=${id}&limit=10&page=${pageNumber}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  deleteMailBoxChannel = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`mailbox/message/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  preSignedUrl = (data) => {
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + authToken,
      },
    });
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('get-presigned-url', data)
        .then((response) => {
          resolve(response);
          //dispatchAction(getAllServiceList(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };

  getoneEvent = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/events/${id}`)
        .then((response) => {
          resolve(response);
          dispatchAction(getEventDetail(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
  getEventList = (page) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`events/company?limit=10&page=${page}`)
        .then((response) => {
          resolve(response);
          dispatchAction(getcompanyEventList(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
  deleteEvent = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`events/${id}`)
        .then((response) => {
          resolve(response);
          //  dispatchAction(getcompanyEventList(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
  deleteNews = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`news/${id}`)
        .then((response) => {
          resolve(response);
          //  dispatchAction(getcompanyEventList(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
  editEvent = (id, params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch(`events/${id}`, params)
        .then((response) => {
          resolve(response);
          //  dispatchAction(getcompanyEventList(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
  editService = (id, params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch(`services/${id}`, params)
        .then((response) => {
          resolve(response);
          //  dispatchAction(getcompanyEventList(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
  editNews = (id, params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch(`news/${id}`, params)
        .then((response) => {
          resolve(response);
          //  dispatchAction(getcompanyEventList(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };

  getOneService = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`services/${id}`)
        .then((response) => {
          dispatchAction(getOneService(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  getOneNews = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`news/${id}`)
        .then((response) => {
          resolve(response);
          dispatchAction(getonenews(response.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
  resumeJobsAppliedbyCompany = (search, sort, pageNumber) => {
    const sortQuery = sort && sort.length > 0 ? `&sort=${sort}` : '';

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(
          `get_all_applied_jobs?&search=${search}${sortQuery}&limit=10&page=${pageNumber}`
        )
        .then((response) => {
          dispatchAction(JobsAppliedbyCompany(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  resumeGetOneAppliedJob = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('get_one_applied_job/' + id)
        .then((response) => {
          dispatchAction(getOneAppliedJob(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  interestedCandidate = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('interested/get_interested_candidate/' + id)
        .then((response) => {
          dispatchAction(getOneAppliedJob(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  resumeGetOneAppliedJobForCompany = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('applied_on_job/' + id)
        .then((response) => {
          dispatchAction(getOneAppliedJob(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  appliedJobCompany = (jobId, companyId) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`applied_on_user_job/one/${jobId}/${companyId}`)
        .then((response) => {
          dispatchAction(getOneAppliedJob(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  AddSubcontractorJob = (data) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('subcontractors/add_subcontract', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          // reject();
        });
    });
  };

  AddJob = (data) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('add_job', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          // reject();
        });
    });
  };
  EditJob = (id, params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch(`add_job/${id}`, params)
        .then((response) => {
          resolve(response);
          //  dispatchAction(getcompanyEventList(response.data));
        })
        .catch((error) => {
          console.log(error);
          //    reject();
        });
    });
  };
  appliedCandidate = () => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('applied_on_my_job?applied_by_type=1')
        .then((response) => {
          dispatchAction(JobsAppliedbyCompany(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  AddNewCard = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('payments/customers/payment-methods', params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  setAsDefaultCard = (param) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/payments/customers/payment-methods/${param}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  GETALLCard = () => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('payments/customers/payment-methods')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  DeleteOneCard = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`payments/customers/payment-methods/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  submitPaymemt = async (params) => {
    // return await new Promise((resolve, reject) => {
    // this.axiosInstance
    //   .post('payments/add-billing', params)
    //   .then((response) => {
    //     resolve(response);
    //   })
    //   .catch((error) => {
    //     reject(error);
    //   });
    // });
    return HELPERS.secureRequest({
      url: `payments/add-billing`,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };
  CandidateResume = (params) => {
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,

      timeout: 10000,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + authToken,
      },
    });
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('/candidate_upload_resume', params)
        .then((response) => {
          dispatchAction(updateCandidateResume(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  // CandidateUpdateResume = (params, resumeId) => {
  //   const authToken = JSON.parse(localStorage.getItem('token') || false);
  //   this.axiosInstance = axios.create({
  //     baseURL: `${process.env.REACT_APP_URL}/`,

  //     timeout: 10000,
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //       'Access-Control-Allow-Credentials': true,
  //       Authorization: 'Bearer ' + authToken,
  //     },
  //   });
  //   return new Promise((resolve, reject) => {
  //     this.axiosInstance
  //       .patch(`/candidate_update_resume/${resumeId}`, params)
  //       .then((response) => {
  //         dispatchAction(updateCandidateResume(response.data));
  //         resolve(response);
  //       })
  //       .catch(() => {
  //         reject();
  //       });
  //   });
  // };
  CandidateUpdateResume = (params, resumeId) => {
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,

      timeout: 10000,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + authToken,
      },
    });
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch(`/candidate_update_resume/${resumeId}`, params)
        .then((response) => {
          dispatchAction(updateCandidateResume(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  companyInfo = async () => {
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,

      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + authToken,
      },
    });
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get('myprofile')
        .then((response) => {
          dispatchAction(companyInfoData(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  taxInfo = async (params) => {
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,

      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + authToken,
      },
    });
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/payments/getTaxRates', params)
        .then((response) => {
          dispatchAction(companyInfoData(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  candidateInfoSubmit = (params) => {
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,

      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + authToken,
      },
    });
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('update_candidate_profile', params)
        .then((response) => {
          dispatchAction(updateCandidateInfo(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  companyCoverUpdate = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('upload_company_identity', params)
        .then((response) => {
          dispatchAction(companyCoverUpdate(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  applyForJob = (params) => {
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,

      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + authToken,
      },
    });
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('apply_job', params)
        .then((response) => {
          dispatchAction(applyForJob(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  subcontractorJobList = (searchParam, pageNumber, sort) => {
    var ls = require('local-storage');
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    var tok = tok ? ls.get('token') : '';
    const axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        Authorization: authToken ? `Bearer ${authToken}` : '',
        // 'Content-Type': 'multipart/form-data'
      },
    });

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(
          `subcontractors?isDeleted=false&page=${pageNumber}${
            searchParam !== '' && searchParam ? '&search=' + searchParam : ''
          }&sort=${sort}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  applyForSubcontractorJob = (params) => {
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,

      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + authToken,
      },
    });
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('subcontractors/apply_subcontract', params)
        .then((response) => {
          dispatchAction(applyForJob(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  deleteSubcontractorjob = (id) => {
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + authToken,
      },
    });
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`subcontractors/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  getSubcontractorsJobList = (searchParam, pageNumber) => {
    var ls = require('local-storage');
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    var tok = tok ? ls.get('token') : '';
    const axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        Authorization: authToken ? `Bearer ${authToken}` : '',
        // 'Content-Type': 'multipart/form-data'
      },
    });

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(
          `subcontractors/subcontract_received_application/2/?isDeleted=false&page=${pageNumber}${
            searchParam !== '' && searchParam ? '&search=' + searchParam : ''
          }`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getSubcontractorJobApplication = (
    jobId,
    companyId,
    searchParam,
    pageNumber
  ) => {
    var ls = require('local-storage');
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    var tok = tok ? ls.get('token') : '';
    const axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        Authorization: authToken ? `Bearer ${authToken}` : '',
        // 'Content-Type': 'multipart/form-data'
      },
    });

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(
          `applied_on_user_job/one/${jobId}/${companyId}?page=${pageNumber}${
            searchParam !== '' && searchParam ? '&search=' + searchParam : ''
          }`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  candidatureSubmit = (params, q) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch(`update_candidate_candidature${q ? '?q=' + q : ''}`, params)
        .then((response) => {
          dispatchAction(updateCandidatureInfo(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  checkPremiumDetail = () => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('premium-detail')
        .then((response) => {
          //dispatchAction(updateCandidatureInfo(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  companyInfoSubmit = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('edit_company', params)
        .then((response) => {
          dispatchAction(updateCandidateInfo(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  billingOverview = () => {
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + authToken,
      },
    });
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('payments/billing-overview')
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  companyMediaSubmit = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('upload_company_media', params)
        .then((response) => {
          dispatchAction(companyMediaUpload(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  uploadMediaProfile = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('upload_company_media', params)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  GetCompanyServices = () => {
    return new Promise((resolve, reject) => {
      this.axiosInstance.get('services').then((response) => {
        dispatchAction(companyServicesData(response.data));
      });
    });
  };
  changeCandidatePassword = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('change_password', params)
        .then((response) => {
          dispatchAction(changePassword(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  deleteCandidateResume = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('candidate_delete_resume/' + params)
        .then((response) => {
          dispatchAction(deleteResume(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  hideAppliedJob = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('hide_applied_job/' + params)
        .then((response) => {
          dispatchAction(deleteResume(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  deleteService = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`services/${id}`)
        .then((response) => {
          //   dispatchAction(deleteResume(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  appliedCandidateJobs = (pageNumber) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`get_all_applied_jobs?limit=10&page=${pageNumber}`)
        .then((response) => {
          dispatchAction(getAppliedCandidateJobs(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  // companyDetail = (id) => {
  //   return new Promise((resolve, reject) => {
  //     this.axiosInstance
  //       .get(`company_detail?comp_id=${id}`)
  //       .then((response) => {
  //         dispatchAction(getCompanyProfileInfo(response.data));
  //         resolve(response);
  //       })
  //       .catch(() => {
  //         reject();
  //       });
  //   });
  // };

  companyDetail = (id) => {
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,
      timeout: 10000,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + authToken,
      },
    });
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`company_detail?comp_id=${id}`)
        .then((response) => {
          dispatchAction(getCompanyProfileInfo(response.data));
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  favouriteJobs = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('favjobs/')
        .then((response) => {
          dispatchAction(getFavouriteJobs(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  promoteEntities = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('payments/sponsor-entity', params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  submitfavouriteJobs = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('favjobs/', params)
        .then((response) => {
          dispatchAction(addFavouriteJobs(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  cuponVerify = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/payments/cupon-verify', params)
        .then((response) => {
          console.log(response, 'cupon-verify response');
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  delfavouriteJobs = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('favjobs/' + params)
        .then((response) => {
          dispatchAction(deleteFavouriteJobs(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  changeApplyJobStatus = (params, data) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('update_apply_job_status/' + params, data)
        .then((response) => {
          dispatchAction(applyJobStatus(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  createSubUser = (data) => {
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,
      timeout: 10000,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + authToken,
      },
    });
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('add_sub_user', data)
        .then((response) => {
          dispatchAction(addSubUserOverView(response.data));
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  getSubUserList = async (page, sort_by) => {
    if (page === undefined) {
      page = '';
    }
    if (sort_by === undefined) {
      sort_by = '';
    }
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`get_sub_user?limit=20&page=${page}&sort_by=${sort_by}`)
        .then((response) => {
          dispatchAction(getSubUser(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  getSubUserListWithoutConstraint = async () => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`get_sub_user`)
        .then((response) => {
          dispatchAction(getSubUser(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  getAppliedOnJobList = (params) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('applied_on_my_job/' + params)
        .then((response) => {
          dispatchAction(getAppliedOnJobUser(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  getAllProducts = (page) => {
    return HELPERS.secureRequest({
      url: `products?limit=10&page=${page}`,
      method: 'GET',
    });
  };

  getOneProduct = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`products/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  getAllCompanyProducts = (page) => {
    return new Promise((resolve, reject) => {
      HELPERS.secureRequest({
        url: `products/company?limit=10&page=${page}`,
        method: 'GET',
      })
        .then((response) => {
          dispatchAction(companyProductsData(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  patchCompanyProduct = (id, params) => {
    // const data = this.converToFormData(params);
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch(`products/${id}`, params)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  postCompanyProduct = (params) => {
    // const data = this.converToFormData(params);
    return HELPERS.secureRequest({
      url: `products`,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };
  deleteCompanyProduct = (id) => {
    return HELPERS.secureRequest({
      url: `products/${id}`,
      method: 'DELETE',
    });
  };
  uploadProductMedia = (id, data, options) => {
    return HELPERS.secureRequest({
      url: `products/${id}/media/`,
      method: 'POST',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...options,
    });
  };
  deleteProductMedia = (prod_id, media_id) => {
    return HELPERS.secureRequest({
      url: `products/${prod_id}/media/${media_id}`,
      method: 'DELETE',
    });
  };

  // ========================sort and search functions==================
  allProductsSortAndSearch = (
    page,
    searchQuery,
    price,
    company,
    category,
    city,
    state,
    country,
    homeSearch,
    searchLocation
  ) => {
    let query_params = '';
    if (price !== undefined && price !== '') {
      query_params = `${query_params}&sort=${price}`;
    }
    if (city !== undefined && city !== '') {
      query_params = `${query_params}&city=${city.trim().toLowerCase()}`;
    }
    if (state !== undefined && state !== '') {
      query_params = `${query_params}&state=${state.trim().toLowerCase()}`;
    }
    if (country !== undefined && country !== '') {
      query_params = `${query_params}&country=${country.trim().toLowerCase()}`;
    }
    if (homeSearch !== undefined && homeSearch !== '') {
      query_params = `${query_params}&homeSearch=${homeSearch}`;
    }
    if (searchLocation !== undefined && searchLocation !== '') {
      query_params = `${query_params}&searchLocation=${searchLocation}`;
    }
    return HELPERS.secureRequest({
      url: `products?limit=10&page=${page}${searchQuery}${company}${category}${query_params}`,
      method: 'GET',
    });
  };

  companyListSortAndSearch = (page, search) => {
    return HELPERS.secureRequest({
      url: `company_list?limit=20&page=${page}&search=${search}`,
      method: 'GET',
    });
  };

  allServicesSortAndSearch = (
    page,
    category,
    company,
    price,
    search,
    city,
    state,
    country,
    homeSearch,
    searchLocation
  ) => {
    let query_params = '';
    if (city !== undefined && city !== '') {
      query_params = `${query_params}&city=${city.trim().toLowerCase()}`;
    }
    if (state !== undefined && state !== '') {
      query_params = `${query_params}&state=${state.trim().toLowerCase()}`;
    }
    if (country !== undefined && country !== '') {
      query_params = `${query_params}&country=${country.trim().toLowerCase()}`;
    }
    if (homeSearch !== undefined && homeSearch !== '') {
      query_params = `${query_params}&homeSearch=${homeSearch}`;
    }
    if (searchLocation !== undefined && searchLocation !== '') {
      query_params = `${query_params}&searchLocation=${searchLocation}`;
    }
    return HELPERS.secureRequest({
      url: `services?limit=10&page=${page}${category}${company}&sort=${price}${search}${query_params}`,
      method: 'GET',
    });
  };

  allEventsSortAndSearch = (
    page,
    date,
    search,
    dateRange,
    company,
    city,
    state,
    country,
    homeSearch,
    searchLocation
  ) => {
    const stdate = dateRange.split(',');
    var dateFilter = '';
    var dcompany = '';
    if (stdate[0]) {
      dateFilter = `&from[gte]=${stdate[0]}&to[lte]=${stdate[1]}`;
    }
    if (company.length > 0) {
      for (let i = 0; i < company.length; i++) {
        dcompany += `&companyId=${company[i]}`;
      }
    }
    let query_params = '';
    if (city !== undefined && city !== '') {
      query_params = `${query_params}&city=${city.trim().toLowerCase()}`;
    }
    if (state !== undefined && state !== '') {
      query_params = `${query_params}&state=${state.trim().toLowerCase()}`;
    }
    if (country !== undefined && country !== '') {
      query_params = `${query_params}&country=${country.trim().toLowerCase()}`;
    }
    if (homeSearch !== undefined && homeSearch !== '') {
      query_params = `${query_params}&homeSearch=${homeSearch}`;
    }
    if (searchLocation !== undefined && searchLocation !== '') {
      query_params = `${query_params}&searchLocation=${searchLocation}`;
    }
    return HELPERS.secureRequest({
      url: `events?limit=10&page=${page}&sort=${date}&search=${search}${dateFilter}${dcompany}${query_params}`,
      method: 'GET',
    });
  };
  allJobsSearchLanding = (page, search) => {
    return HELPERS.secureRequest({
      url: `jobs?limit=5&page=${page}&search=${search}`,
      method: 'GET',
    });
  };
  allProductsSearchLanding = (page, searchQuery) => {
    return HELPERS.secureRequest({
      url: `products?limit=10&page=${page}&search=${searchQuery}`,
      method: 'GET',
    });
  };
  allServicesSearchLanding = (page, search) => {
    return HELPERS.secureRequest({
      url: `services?limit=10&page=${page}&search=${search}`,
      method: 'GET',
    });
  };
  allEventsSearchLanding = (page, search) => {
    return HELPERS.secureRequest({
      url: `events?limit=10&page=${page}&search=${search}`,
      method: 'GET',
    });
  };

  newsListSearchLanding = (page, search) => {
    return HELPERS.secureRequest({
      url: `news/company?limit=10&page=${page}&search=${search}`,
      method: 'GET',
    });
  };
  subuserSortAndSearch = (sort_by, search, page) => {
    return HELPERS.secureRequest({
      url: `get_sub_user?limit=10&page=${page}&permission=${sort_by}&search=${search}`,
      method: 'GET',
    });
  };
  myJobsSortAndSearch = (page, search, sort) => {
    return HELPERS.secureRequest({
      url: `myjobs?limit=10&page=${page}&search=${search}&sort=${sort}`,
      method: 'GET',
    });
  };
  allNewsListSortAndSearch = (
    page,
    search,
    company,
    city,
    state,
    country,
    homeSearch,
    searchLocation
  ) => {
    let query_params = '';
    if (search !== undefined && search !== '') {
      query_params = `${query_params}&search=${search.trim()}`;
    }
    if (state !== undefined && state !== '') {
      query_params = `${query_params}&state=${state.trim().toLowerCase()}`;
    }
    if (city !== undefined && city !== '') {
      query_params = `${query_params}&city=${city.trim().toLowerCase()}`;
    }
    if (country !== undefined && country !== '') {
      query_params = `${query_params}&country=${country.trim().toLowerCase()}`;
    }
    if (homeSearch !== undefined && homeSearch !== '') {
      query_params = `${query_params}&homeSearch=${homeSearch}`;
    }
    if (searchLocation !== undefined && searchLocation !== '') {
      query_params = `${query_params}&searchLocation=${searchLocation}`;
    }
    return HELPERS.secureRequest({
      url: `news?limit=12&page=${page}${company}${query_params}`,
      method: 'GET',
    });
  };

  jobListSuggestions = (search) => {
    return HELPERS.secureRequest({
      url: `jobs?limit=5&page=${1}&search=${search}`,
      method: 'GET',
    });
  };
  allProductsSuggestions = (search) => {
    return HELPERS.secureRequest({
      url: `products?limit=5&page=${1}&search=${search}`,
      method: 'GET',
    });
  };
  allServicesSuggestions = (search) => {
    return HELPERS.secureRequest({
      url: `services?limit=5&page=${1}&search=${search}`,
      method: 'GET',
    });
  };
  allNewsListSuggestions = (search) => {
    return HELPERS.secureRequest({
      url: `news?limit=5&page=${1}&search=${search}`,
      method: 'GET',
    });
  };
  allEventsSuggestions = (search) => {
    return HELPERS.secureRequest({
      url: `events?limit=5&page=${1}&search=${search}`,
      method: 'GET',
    });
  };

  allJobCategories = (pageNumber, search, limit) => {
    const CancelToken = axios.CancelToken;
    if (cancel) {
      cancel(); // cancel request
    }
    return HELPERS.secureRequest({
      url: `job-title-list?limit=3328&page=${pageNumber}&sort=title&search=${search}`,
      method: 'GET',
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  };
  allSkillCategories = (pageNumber, search, limit) => {
    const CancelToken = axios.CancelToken;
    if (cancel) {
      cancel(); // cancel request
    }
    return HELPERS.secureRequest({
      url: `skills/?limit=1000&page=${pageNumber}&sort=title&search=${search}`,
      method: 'GET',
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  };

  allServiceCategories = (pageNumber, search) => {
    const CancelToken = axios.CancelToken;
    if (cancel) {
      cancel(); // cancel request
    }
    return HELPERS.secureRequest({
      url: `entity-category/service?sort=categoryName&search=${search}&page=${pageNumber}`,
      method: 'GET',
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  };

  allJobSectors = (pageNumber, search) => {
    const CancelToken = axios.CancelToken;
    if (cancel) {
      cancel(); // cancel request
    }
    return HELPERS.secureRequest({
      url: `job-title-list?limit=3328&page=${pageNumber}&sort=title&search=${search}`,
      method: 'GET',
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  };
  companysuggestionList = (pageNumber, search) => {
    return HELPERS.secureRequest({
      url: `sug-company-list?fields=comp_info.comp_name,comp_id,comp_info.bus_name&page=${pageNumber}&search=${search}&sort=-join_date`,
      method: 'GET',
    });
  };

  allProductCategories = (pageNumber, search) => {
    const CancelToken = axios.CancelToken;
    if (cancel) {
      cancel(); // cancel request
    }
    return HELPERS.secureRequest({
      url: `entity-category/product?limit=10&page=${pageNumber}&sort=categoryName&search=${search}`,
      method: 'GET',
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  };

  checkCompanyId = async (email) => {
    // const companyId = parseInt(company_id, 10);
    return await HELPERS.request({
      url: `check_company_id?email=${email}`,
      method: 'GET',
    });
    // return await new Promise((resolve,reject) => {
    //   this.axiosInstance
    //   .get(`check_company_id`,company_id)
    //   .then((response) => {
    //     resolve(response);
    //   })
    //   .catch(() => {
    //     reject();
    //   });
    // })
  };

  deleteSubUser = (id) => {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`delete_sub_user/${id}`)
        .then((response) => {
          //   dispatchAction(deleteResume(response.data));
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };

  editSubUser = (id, data) => {
    const authToken = JSON.parse(localStorage.getItem('token') || false);
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_URL}/`,

      timeout: 10000,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Credentials': true,
        Authorization: 'Bearer ' + authToken,
      },
    });
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('edit_sub_user/' + id, data)
        .then((response) => {
          dispatchAction(addSubUserOverView(response.data));
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  getCurrentBillingData = async () => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/payments/current-billing')
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  getSponsoredProducts = async () => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/products/sponsored-list')
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  getSponsoredServices = async () => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/services/sponsored-list')
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  getSponsoredJobs = async () => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/jobs/sponsored-list/job')
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  getSponsoredSubcontractors = async () => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/jobs/sponsored-list/subcontractor')
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  updateUserProfile = async (data) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('/update-user', data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  offersSentList = async (searchParam, pageNumber) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get(
          `/offer-sent/?isDeleted=false&page=${pageNumber}${
            searchParam !== '' && searchParam ? '&search=' + searchParam : ''
          }`
        )
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  offerDetails = async (jobId, compId) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/offer-sent/${jobId}/${compId}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  appliedCandidateJobsDetailsList = async (jobId) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/applied_on_my_job/${jobId}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  switchToFreemium = async () => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .patch('payments/freemium')
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  createResource = async (params) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .post('resources/createResource', params)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  editResource = async (params) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .post('resources/editResource', params)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  createSkill = async (params) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .post('skills/create-skill', params)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  getMyResources = async (params) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/resources/getMyResource?search=${params?.search}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  getAllResources = async (
    page,
    search,
    city,
    state,
    country,
    sector,
    searchLocation
  ) => {
    let query_params = '';
    if (search !== undefined && search !== '') {
      query_params = `&search=${search.trim()}`;
    }
    if (state !== undefined && state !== '') {
      query_params = `${query_params}&state=${state.trim().toLowerCase()}`;
    }
    if (city !== undefined && city !== '') {
      query_params = `${query_params}&city=${city.trim().toLowerCase()}`;
    }
    if (country !== undefined && country !== '') {
      query_params = `${query_params}&country=${country.trim().toLowerCase()}`;
    }
    if (sector !== undefined && sector !== '') {
      query_params = `${query_params}&${sector}`;
    }
    if (searchLocation !== undefined && searchLocation !== '') {
      query_params = `${query_params}&searchLocation=${searchLocation}`;
    }
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .get(
          `resources/getAllSponsoredResources?limit=20&page=${page}${query_params}`
        )
        .then((res) => {
          dispatchAction(resourceManagementData(res.data));
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };
  deleteResource = async (id) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`resources/delete/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject();
        });
    });
  };
  resourceSuggestionList = (pageNumber, search) => {
    return HELPERS.secureRequest({
      url: `resources/resource-search-list?page=${pageNumber}&search=${search}`,
      method: 'GET',
    });
  };
  createEntityCategory = async (data) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`entity-category/${data.type}/create`, data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
  createJobTitle = async (data) => {
    return await new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`job-title-list/create`, data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };
}
export default new APIManager();
