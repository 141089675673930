import React, { Component, useEffect } from 'react';
import { Row, Col, Checkbox } from 'antd';
import Header from '../header/header.js';
import PlanCards from './plan_cards.js';
import Footer from './footer';
import './Landing.css';
import './footer.css';

function onChange(e) {}
const TermsnCond = (props) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="responsive-div" style={{ backgroundColor: 'white' }}>
      <Header />
      <div className="home-outer-div">
        <h1 className="tnc-head">Terms and conditions</h1>
        <Row>
          <Col span={3}></Col>
          <Col span={18} className="tnc-para">
            <h4 style={{ opacity: 0.9 }}>
              <b>Conditions of use common to all users</b>
            </h4>
            <br />
            These Terms of Use ("TOU"), which we may modify or amend from time
            to time, govern your access to and use of any SENDBIZ digital
            property, including the SENDBIZ Websites, mobile applications , and
            aspects of your interaction with our branded and controlled pages or
            accounts on certain third-party social media sites and applications
            (to which third-party terms may also apply) (collectively, the
            "SENDBIZ Sites"), as well as any use by you of the interactive
            business search and other related services we provide to visitors
            via the SENDBIZ Sites ("Visitor Service"), and/or access to the
            business listing or profile management and reporting we provide on
            the SENDBIZ Sites for business owners/operators or their authorized
            agents ("SENDBIZ for Business", and together with the Visitor
            Service(s), the "Service"). This TOU is a legally binding agreement
            between you, personally and, if applicable, on behalf of the entity
            for which you use the SENDBIZ Sites or the Services (collectively,
            "you" or "your"), and Sendbiz Inc. which is the provider of the
            SENDBIZ Sites and the Services ("Sendbiz" or "Sendbiz Inc.", "we",
            "us", "our"), so please read it carefully.
            <br />
            <br />
            These TOU govern your access to and use of the SENDBIZ
            Sites/Services as of 12/05/2023 ("Effective Date").
            <br />
            <br />
            <b style={{ opacity: 0.7 }}>
              BY ACCESSING OR USING ANY PART OF THE SENDBIZ SITES/SERVICES, YOU
              ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND
              BY THESE TERMS AND CONDITIONS OF USE, WHICH CONTAIN A WAIVER OF
              JURY TRIAL AND CLASS ACTION RIGHTS, AS WELL AS LIMITATIONS OF
              LIABILITY. IF YOU DO NOT AGREE TO BE SO BOUND, YOU MAY NOT ACCESS
              OR USE THE SENDBIZ SITES/SERVICES. UNLESS OTHERWISE EXPRESSLY
              STATED, SENDBIZ INC. DISCLAIMS ALL WARRANTIES, WHETHER PROVIDED BY
              SENDBIZ INC. OR ITS SUBSIDIARIES, OR BY ITS RESPECTIVE EMPLOYEES
              AND AGENTS.
            </b>
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>1. About the Services and the SENDBIZ Sites</b>
            </h4>
            <br />
            1.1 The SENDBIZ Sites and Search Results. The SENDBIZ Sites and
            Services allow users to, among other things, link, search for, find
            and compare companies, as well as the products, services, employment
            and subcontracting opportunities, events and news offered by these
            companies. The SENDBIZ Sites may display company listings,
            advertisements or other sponsored information. When sponsored
            information is mixed with general, unpaid search results, the
            sponsored results are identified so that visitors can distinguish
            between the two categories of information. Sponsored listings or
            other advertisements may also be shown in priority to unpaid
            listings.
            <br />
            <br />
            1. 2 Access to External Sites The Site contains links to other
            websites owned and/or operated by third party vendors and other
            third parties (the "External Sites"). You acknowledge that SendBiz
            disclaims all liability to such third parties or with regard to the
            availability of the External Sites and the elements contained on or
            accessible via such Sites. If you have any questions regarding these
            links or the documents appearing on an External Site, we advise you
            to contact the administrator or webmaster of the Site concerned.
            <br />
            <br />
            SendBiz may also provide you with links to certain Third Party Sites
            that offer products , services and/or subcontactors that you may use
            or benefit from or purchase. We may stop offering this Sendbiz of
            Third Party Sites or services at any time. If you choose to use such
            third-party Sites or services, you enter into an Agreement with such
            third party at your own risk. When you enter into an Agreement with
            such parties, you provide your data directly to them, and your
            rights and obligations are determined by their privacy policies and
            terms of use. We are not responsible for any content or services
            provided by such third parties and are not liable for any events
            that may occur when you use such third parties. We are also not
            responsible for any payments that such third parties may charge you.
            You will be informed on the Site that these Sites and services are
            offered by third parties, and not by SendBiz
            <br />
            <br />
            SendBiz acts solely as a technical intermediary. In addition, you
            agree to fully defend and indemnify SendBiz in the event of any
            claim resulting from your use of the third-party service or product.
            <br />
            <br />
            Certain pages of the Site may incorporate content and information
            provided by third party APIs, which are subject to additional terms
            and conditions imposed by such third parties. The Site may, for
            example, incorporate Google Maps features and content that are
            subject to current versions: (1) additional terms of use for Google
            Maps/Google Earth (available here:
            https://maps.google.fr/help/terms_maps/); and (2) the Google Privacy
            Policy (available here: https://policies.google.com/privacy?hl=fr).
            Information published on Company Pages may originate from the
            companies concerned or from third parties.
            <br />
            <br />
            Note: While this Agreement describes SendBiz's services in a general
            manner, differences in availability may exist and you should refer
            to the Site applicable to your location for the services that are
            available to you. While this Agreement describes, for example,
            correspondence services, such services are not offered in the United
            States.
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>2. Use of our Site</b>
            </h4>
            <br />
            The use of any automated system or software, whether operated by a
            third party or otherwise, to extract data from the Site ("screen
            scraping" or "scanning", for example) is prohibited. SendBiz
            reserves the right to take such measures as it deems necessary,
            including taking legal action without notice, in the event of
            unauthorized use of the Site. If you wish to use the Site other than
            for commercial purposes or as a Job Seeker or Employer, or if you
            wish to purchase services and products present on SendBiz, you must
            obtain prior written authorization from SendBiz, or have accepted
            SendBiz's online terms of use. Contact us for more information. We
            reserve the right at any time (without any obligation) to delete
            user accounts and repossess user names or URLs for any reason
            whatsoever.
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>3. User Content</b>
            </h4>
            <br />
            Certain areas of the Site, such as message boards and discussion
            forums or Job Posting pages, may allow users to post content such as
            Job Lists, CVs, information, text, images, audio and video files,
            messages and other content (content sent, posted, displayed or
            otherwise made available by a user on or through the Site, as well
            as content sent to SendBiz by a user, to the extent that it is
            indicated that such content may be posted on the Site, will be
            referred to herein as "User Content"). The purpose of this
            functionality is to help users to obtain information about
            companies, careers and their products and services, to inform users
            about Job Offers, products and services and to provide information
            about candidates and potential buyers and to facilitate any other
            communication. User Content is provided by third party contributors,
            many of whom use pseudonyms. User Content may be inaccurate,
            incomplete, misleading or deceptive.
            <br />
            <br />
            SendBiz does not endorse any user content, including any opinions,
            advice, information or statements constituting it, and declines all
            liability in this regard. Certain elements of the SendBiz search
            results pages may appear to have been created by SendBiz, but are in
            reality a reproduction of user-generated search requests. By
            accessing the Site, you acknowledge that you may be confronted with
            content (including User Content) that you consider harmful,
            offensive, threatening, indecent or reprehensible, in particular
            explicit content and other potentially offensive content, and you
            acknowledge that SendBiz does not accept any liability for such
            content.
            <br />
            <br />
            <br />
            You may not post any User Content that is unlawful, fraudulent,
            discriminatory, threatening, abusive, harassing, defamatory,
            libelous, obscene, obscene or otherwise objectionable, that contains
            sexual, ethnic, racial or other offensive material, or that is
            otherwise discriminatory or that contains no explicit or implicit
            endorsement of any kind. You also may not post any User Content that
            contains proprietary or confidential information, trade secrets,
            advertising (except as permitted by this Agreement), solicitations,
            chain letters or pyramid schemes, investment proposals or other
            types of unsolicited commercial communications (unless otherwise
            expressly permitted in writing), or that encourages or provokes
            spam, flooding or other practices.
            <br />
            <br />
            You may not post any User Content that is unlawful, fraudulent,
            discriminatory, threatening, abusive, harassing, defamatory,
            libelous, obscene, obscene or otherwise objectionable, that contains
            sexual, ethnic, racial or other offensive material, or that is
            otherwise discriminatory or that contains no explicit or implicit
            endorsement of any kind. You also may not post any User Content that
            contains proprietary or confidential information, trade secrets,
            advertising (except as permitted by this Agreement), solicitations,
            chain letters or pyramid schemes, investment proposals or other
            types of unsolicited commercial communications (unless otherwise
            expressly permitted in writing), or that encourages or provokes
            spam, flooding or other practices.
            <br />
            <br />
            Although it is under no obligation to do so, SendBiz may monitor any
            User Content and reserves the right to delete, at its sole
            discretion, all or part of any User Content that violates the above
            rules, particularly if such content does not concern the part of the
            Site on which it is published, which SendBiz, at its sole
            discretion, considers inappropriate. If you consider that any User
            Content violates the terms of this Agreement or our policies, please
            contact SendBiz or notify us via Chat as soon as possible so that we
            may consider its removal. You acknowledge and agree that SendBiz is
            under no obligation to delete any User Content, and that it is at
            SendBiz's sole discretion to determine whether any content (i)
            violates SendBiz's policy, (ii) should be posted or deleted and
            (iii) should be excluded from any posting on SendBiz. You agree that
            SendBiz disclaims all liability to you or others for editorial
            decisions. You acknowledge and agree that SendBiz is an interactive
            computer service and is under no obligation to filter User Content,
            nor to include any User Content (including, without limitation, Job
            Offers, news, products or services) in its search results or other
            lists, and may exclude or delete any User Content for any reason
            whatsoever.
            <br />
            <br />
            Although it is under no obligation to do so, SendBiz may monitor any
            User Content and reserves the right to delete, at its sole
            discretion, all or part of any User Content that violates the above
            rules, particularly if such content does not concern the part of the
            Site on which it is published, which SendBiz, at its sole
            discretion, considers inappropriate. If you consider that any User
            Content violates the terms of this Agreement or our policies, please
            contact SendBiz or notify us via Chat as soon as possible so that we
            may consider its removal. You acknowledge and agree that SendBiz is
            under no obligation to delete any User Content, and that it is at
            SendBiz's sole discretion to determine whether any content (i)
            violates SendBiz's policy, (ii) should be posted or deleted and
            (iii) should be excluded from any posting on SendBiz. You agree that
            SendBiz disclaims all liability to you or others for editorial
            decisions. You acknowledge and agree that SendBiz is an interactive
            computer service and is under no obligation to filter User Content,
            nor to include any User Content (including, without limitation, Job
            Offers, news, products or services) in its search results or other
            lists, and may exclude or delete any User Content for any reason
            whatsoever.
            <br />
            <br />
            Without prejudice to the generality of the foregoing, SendBiz
            reserves the right (without any obligation) to remove any job offer,
            product, news or service that directly or indirectly discriminates
            against Job Seekers, a company, product or service. Direct
            discrimination means, for example, that a job offer may explicitly
            state that only Job Seekers meeting a certain criterion are
            accepted, thus excluding all others on the basis of, for example,
            gender, race, age or disability. Indirect discrimination means, for
            example, that a Job List implicitly excludes certain categories of
            Job Seekers by setting requirements that they cannot meet. You
            understand and agree that it is your responsibility not to publish
            Job Lists that directly or indirectly discriminate against Job
            Seekers or otherwise violate applicable law.
            <br />
            <br />
            <br />
            Note: Section 230 of the Communications Decency Act provides as
            follows:
            <br />
            (1) With respect to treatment as a publisher or spokesperson No
            provider or user of an interactive computer service is treated as a
            publisher or spokesperson for any information provided by another
            information content provider.
            <br />
            (2) Concerning civil liability
            <br />
            No supplier or user of an interactive computer service shall be
            liable for
            <br />
            (A) any action taken voluntarily, in good faith, to restrict access
            to or availability of Content that the Provider or User deems
            obscene, indecent, biased, distasteful, excessively violent,
            offensive or otherwise objectionable, whether or not such Content is
            constitutionally protected; or
            <br />
            (B) any action performed to enable or provide technical means for
            informational content providers or others to restrict access to the
            content described in paragraph (1).
            <br />
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>4. Authorization for User Content and Remarks</b>
            </h4>
            <br />
            To the extent permitted by law, you grant SendBiz a non-exclusive,
            worldwide, permanent (only revocable under the conditions set forth
            below), fully paid, royalty-free, transferable and sub-licensable
            right (via multiple sub-licensees) to create, use, sell and
            sub-license, reproduce, distribute, implement, display, create
            derivative content or exploit in any other form whatsoever the User
            Content that you post or send, in order to publish it on the SendBiz
            Website or the Website of its Publishers, to keep the SendBiz
            Website up to date and to promote SendBiz without restriction. You
            understand that no form of compensation will be owed or paid to you
            with respect to the exercise of SendBiz's rights under the license
            granted by this section. To the extent that compensation may be
            contemplated under applicable law as a result of or in connection
            with the exercise of such rights by SendBiz, you hereby waive any
            rights to such compensation.
            <br />
            <br />
            You further grant SendBiz, its subsidiaries and sub-licensees the
            authorization to use your name, username and/or trademarks and logos
            in connection with (i) such User Content, (ii) the publication of
            SendBiz marketing content containing or incorporating such User
            Content, or (iii) actions carried out by SendBiz to promote or
            advertise such content (such as Job Lists), including the use of
            keywords in third party search engines. Notwithstanding the
            foregoing and to the extent that you send photographs and videos to
            SendBiz in order to associate them with another particular SendBiz
            service, SendBiz will not publish such photographs in the absence of
            a link with said service.
            <br />
            <br />
            <br />
            You declare and certify that : (i) the User Content that you submit,
            post, display or otherwise make available on or through the Site is
            owned by you, or that you have the right to grant the licenses set
            forth in this Section; (ii) such User Content and the submission,
            publication, display or availability of such User Content on or
            through the Site does not violate any applicable law or the privacy,
            publicity, copyright, contractual, intellectual property or other
            rights of any person or entity; and (iii) such submission,
            publication, display or availability of such User Content on or
            through the Site does not constitute a breach of any contract
            between you and any third party. You agree to pay all royalties,
            fees, damages and any other amounts due to any person or entity as a
            result of such User Content. You agree to indemnify, defend and hold
            harmless SendBiz and its subsidiaries from and against any and all
            claims relating to such User Content. If you wish to request the
            revocation of the authorization you grant under this section with
            respect to such User Content, please send a registered letter to the
            above-mentioned postal address, accompanied by a copy of your
            passport or national identity card (for identity verification
            purposes), requesting the deletion of such User Content. Your
            registered letter of request must include (a) the signature of the
            holder of the rights concerned by said User Content or of a person
            authorized to act on his or her behalf; (b) the identification of
            the User Content for which the authorization is to be removed, and
            information reasonably sufficient to enable SendBiz to locate and
            delete said User Content from the Site; (c) your name, address,
            telephone number and email address ; (d) a statement that you have a
            good faith belief that you are the right holder or are authorized by
            the right holder to revoke the authorization with respect to the
            Designated User Content; and (e) a statement that the information in
            the request is accurate and, under penalty of perjury, that you are
            the right holder or are authorized to act on behalf of the right
            holder with respect to such User Content. You may be charged for
            responding to such a request and implementing it; contact SendBiz
            for more information.
            <br />
            <br />
            <br />
            You may at any time and at your sole discretion send SendBiz remarks
            and content relating to the operation and performance of the Site,
            highlighting for example any errors or improvements, modifications
            and bug fixes to be implemented (hereinafter the "Remarks"). If, as
            part of your evaluation or otherwise, you submit Remarks, you hereby
            grant SendBiz a non-exclusive, worldwide, permanent, irrevocable,
            royalty-free, transferable (via multiple sub-licensees),
            non-exclusive right (including but not limited to multiple
            sub-licensees) to create, use, sell, sub-license, reproduce,
            distribute, implement, display, create derivative works from, or
            otherwise exploit such Remarks for any purpose whatsoever, without
            limitation. You agree that SendBiz may transmit all or part of the
            Remarks to any third party in any form, and may sublicense all or
            part of the Remarks to any third party in any form, without
            limitation. By submitting Remarks, you agree that your submission is
            free, unsolicited and without any restriction and does not imply any
            fiduciary, confidentiality or other obligation on the part of
            SendBiz, and that we are free to use such Remarks without any
            consideration to you and to transmit such Remarks to any third
            party, whether or not confidential. You also acknowledge that by
            agreeing to receive any type of Remark, SendBiz does not waive any
            of its rights to exploit similar or related ideas previously known
            to SendBiz, developed by its employees or obtained from sources
            other thank you.
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>5. Rules for Use of our Site</b>
            </h4>
            <br />
            You undertake not to access (or seek to access) the Site in any
            manner other than through the interface provided by SendBiz, unless
            you have been specifically authorized to do so under a separate
            Agreement entered into in writing with SendBiz. You undertake not to
            engage in any activity that may hinder or disrupt the operation of
            the Site (or the servers and networks connected to the Site). Unless
            you have been specifically authorized to do so under a separate
            Agreement concluded in writing with SendBiz, you undertake not to
            browse, scan, reproduce, duplicate, copy, sell, exchange or resell
            the content of the Site for any reason whatsoever. The use or
            provision of false or fictitious names or contact information in
            connection with the Site constitutes grounds for the immediate
            revocation of your SendBiz account and your right to use the Site.
            You accept full responsibility (and agree that SendBiz cannot be
            held liable to you or to any third party) in the event of a breach
            of your obligations under this Agreement and for any consequences
            (including any prejudice or damage suffered by SendBiz) of such a
            breach.
            <br />
            <br />
            <br />
            You undertake not to transmit to SendBiz or publish malicious code
            on or via the Site (as User Content or in any other form
            whatsoever), and not to use or hijack the Site for your own
            commercial purposes. "Malicious Code" means any software (sometimes
            referred to as a "virus", "worm", "Trojan horse", "time bomb", "time
            limiter", "software blocking device", "trap", "access code",
            "cancelbots" or "backdoor software") that : (a) is designed to
            damage, disrupt, deactivate, compromise, alter, interfere with,
            intercept or misappropriate any data, storage medium, program,
            system, equipment or communications tool in any way, based on any
            event, including (i) exceeding a certain number of copies, (ii)
            exceeding a certain number of users, (iii) exceeding a certain time,
            (iv) changing to a certain date or other value, or (v) using certain
            features; or (b) allows an unauthorized person to cause such
            results; or (c) allows an unauthorized person to access another
            person's data without that person's knowledge and permission.
            Without limiting the foregoing, and for illustrative purposes only,
            Users are not permitted to :
            <br />
            <br />
            <br />
            - Send or allow the sending of unwanted commercial emails ("junk
            mail"). This type of activity includes, but is not limited to
            <br />
            <ul>
              <li>
                Sending messages that contravene the CAN-SPAM Act or any other
                anti-spam laws in force;
              </li>
              <li>
                Impersonating or impersonating another person or their email
                address, or creating false accounts in order to send Spam;
              </li>
              <li>the extraction of data belonging to SendBiz;</li>
              <li>
                the sale, exchange or transmission to third parties of a
                person's contact information without that person's knowledge and
                continued consent to the disclosure of such data; and
              </li>
              <li>
                any use of SendBiz CV contacts contrary to SendBiz's policy as
                determined by SendBiz, including, for example, those with a low
                response rate from contacts.
              </li>
            </ul>
            <br />- Send, post, distribute or disseminate any content that is
            illegal, defamatory, offensive, abusive, fraudulent, criminal,
            obscene or otherwise objectionable, or offer to do so. SendBiz may
            block the transmission of such content.
            <br />
            <br />- Deliberately spread viruses, worms, errors, Trojan horses,
            corrupted files, hoaxes or any other form of malicious code or
            element of a destructive or deceptive nature.
            <br />
            - Implement or transmit pyramid selling systems or similar programs.
            <br />
            - Transmit potentially harmful content to minors.
            <br />
            - Impersonate any person (by using their email address or by any
            other means) or otherwise conceal your identity or the source of an
            email.
            <br />- Transmit content that is the intellectual property of a
            person or proprietary or confidential information belonging to that
            person without the permission of the owner or licensor. Users who
            repeatedly publish content that is the intellectual property of
            another will be banned from SendBiz.
            <br />
            - Infringing the rights of others (such as the right to privacy or
            the right to disseminate information).
            <br />
            - Promote or encourage illegal activities.
            <br />
            - Harm the comfort of use of the Site by other SendBiz users.
            <br />- Create multiple user accounts that violate this Agreement in
            one way or another or create user accounts automatically, by
            trickery or under fraudulent pretences.
            <br />
            - Sell, trade, resell, otherwise exploit for unauthorized commercial
            purposes a SendBiz account, or transfer it.
            <br />
            - Modify, adapt, translate or reverse engineer any element of the
            Site.
            <br />
            - Remove indications of copyright, trademarks and other indications
            of exclusive rights appearing on the Site.
            <br />
            - Modify the format or framing of any element of a page of the
            Website without the express written authorization of SendBiz.
            <br />- Contact other SendBiz users to tell them about Multi-level
            Marketing Programs (MLM), employment opportunities requiring prior
            payment or any other subject that SendBiz deems harmful to its
            users.
            <br />
            - Create multiple SendBiz accounts without authorization.
            <br />
            - Create multiple SendBiz accounts that do not carry your company
            name in an attempt to gain greater visibility in the open publishing
            system.
            <br />
            - Circumvent feature limitations or suspensions.
            <br />
            - Communicate incorrect information.
            <br />
            - Explore or capture data (data scraping) from the SendBiz database.
            <br />
            - Explore, capture or reproduce any SendBiz content for competitive
            purposes.
            <br />- Use advertising blockers or other similar software in
            connection with your use of the Site, if and to the extent that such
            software interferes with or prevents the posting of Job Offers.
            <br />
            <br />
            <br />
            SendBiz reserves the right to use any user content (including the
            content of messages or documents sent via the Site, or to the Site
            or to SendBiz) to prevent potentially illegal activities or any
            other activity deemed harmful to other users. SendBiz reserves the
            right to use various methods to detect and block abnormal
            activities, and to screen user content to prevent harmful acts such
            as junk mail or fraud. However, these detection methods are not
            perfect and may produce false positives. If you use the Site, you
            agree that SendBiz may apply the above-mentioned rules or attempt to
            prevent spam, fraud or any other irregular activity. These measures
            may result in the temporary or permanent suspension of your account,
            your access to the SendBiz mail relay or your use of any other
            function of the Site, with or without notice. SendBiz may not be
            held liable for such suspension or its consequences.
            <br />
            <br />
            <br />
            SendBiz reserves the right to ignore any message containing an
            attachment with the extension .ade, .adp, .bat, .chm, .cmd, .com,
            .cpl, .exe, .hta, .ins, .isp, .jar, .jse, lib, .lnk, .mde, .msc,
            .msp, .mst, .pif, .scr, .sct, .shb, .sys, .vb, .vbe, .vbs, .vxd,
            .wsc, .wsf, .wsh or .zip, without invoking any specific reason or
            for any reason whatsoever. SendBiz further reserves the right to
            investigate whether you are committing a scam, sending spam to
            SendBiz or its users, or otherwise engaging in fraudulent activities
            on SendBiz by various means, such as testing your functionality by
            creating profiles and names that belong to SendBiz. SendBiz reserves
            the right to transmit information gathered through these
            investigations to the police or another third party if SendBiz
            believes in good faith that you are using the Site in violation of
            these Terms and Conditions.
            <br />
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>6. SendBiz registration and contact</b>
            </h4>
            <br />
            Certain sections of the Site, including those allowing you to create
            a SendBiz account, require you to register or provide an email
            address. If you register, you agree to (a) provide true, accurate,
            current and complete information about yourself as required by our
            registration form (including your email address) and (b) assume full
            responsibility for the confidentiality and use of your login and
            password, and not to transfer or resell your use of or access to the
            Site to any third party. Please note that any individual to whom you
            have entrusted your username and password and who can answer
            verification questions regarding your SendBiz account may have
            access to information relating to your SendBiz account. We reserve
            the right (without any obligation) to delete user profiles or
            retrieve identifiers or URLs at any time.
            <br />
            <br />
            <br />
            You agree that SendBiz may, in the context of the services it
            provides you with, communicate with you or contact you on behalf of
            a third party, via your SendBiz account or by other means, in
            particular by email, by telephone (including mobile telephones) or
            by post, including by using contact details obtained from you or
            from third party sources. By providing SendBiz with your mobile
            telephone number, you expressly consent to being contacted via this
            number. In this case, these communications are an integral part of
            the services to which you have subscribed. To withdraw your consent
            at any time, reply STOP to the SMS or contact SendBiz customer
            service. You may also withdraw your consent by terminating the
            contract by closing your SendBiz account. By activating the two-step
            verification, you confirm that you are the owner of the telephone
            number you have provided.
            <br />
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>7. Disclaimer of Warranty</b>
            </h4>
            <br />
            SendBiz disclaims all liability for the accuracy, content,
            completeness, legality, reliability, functionality and availability
            of the information or documents appearing in SendBiz's search
            results, accessible via links appearing in such results (including
            Job Lists) or otherwise made available on the Site by SendBiz or by
            third parties (including User Content), whether use is paid or free
            of charge. SendBiz disclaims any liability for the accuracy,
            content, completeness or reliability of the information provided by
            SendBiz solely for information purposes, in particular SendBiz
            Analytics data (estimated number of applications, organic traffic,
            etc.) and information concerning salary. You acknowledge that you
            receive said information free of charge from SendBiz. SendBiz
            declines all liability in the event of deletion, failure to store,
            error or delay in delivery concerning information, emails,
            attachments or documents (including user content). SendBiz also
            disclaims all liability for any damage resulting from downloading or
            access to any information or document on the Site or on other sites,
            or resulting from Internet services accessible through the Site.
            SendBiz may not be held liable to you or to any third party on the
            basis of your inappropriate or abusive use of, or dependence on, the
            Site. In addition, SendBiz may not be held liable to you or a third
            party on the basis of your inappropriate or abusive use of, or
            dependence on, the site or service of any third party that you
            access from a link on the SendBiz Site.
            <br />
            <br />
            <br />
            SendBiz also disclaims all liability in the event of technical
            malfunction of the Site, including but not limited to any failure of
            the network or telephone service, computer systems, servers or
            suppliers, computer or mobile telephone equipment or software,
            failure to send an email (including attachments), failure to connect
            to an account or any other services provided by SendBiz, as a result
            of technical problems or traffic congestion on the Internet or at
            any third party website (including, without limitation, application
            management software, TTYs), or any combination thereof, including
            damage to your or any other person's computer, mobile device or
            other hardware or software, in connection with or resulting from the
            use or downloading of any content in connection with the Site or
            Services. SendBiz shall not be liable for any loss or damage to any
            content or for any personal injury or death that may result from the
            use by any person of the Site, the Services, User Content or
            third-party applications, websites, software or content published on
            or via the Site or transmitted to users, or from any interaction
            between users of the Site or the Services, whether online or
            offline.
            <br />
            <br />
            <br />
            THE SITE, AND ALL DOCUMENTS, INFORMATION, ADVICE, JOB LISTINGS,
            CAREER GUIDES, USER CONTENT, PRODUCTS, FEATURES AND SERVICES
            AVAILABLE ON OR THROUGH THE SITE ARE PROVIDED "AS IS" WITHOUT
            WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE EXTENT
            PROVIDED FOR BY LAW, SendBiz, ITS SUBSIDIARIES AND THIRD PARTY
            LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
            STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND OF TITLE.
            SendBiz, its affiliates and their third party licensors disclaim all
            warranties relating to the security, reliability, timeliness and
            performance of the Site, AS WELL AS ALL DOCUMENTS, INFORMATION,
            ADVICE, JOB LISTINGS, USER CONTENT, FEATURES, PRODUCTS AND SERVICES
            AVAILABLE ON OR THROUGH THE SITE. SendBiz, its subsidiaries and
            their third party licensors disclaim all warranties with regard to
            the goods or services received via the site, presented on the site
            or received via the links made available by SendBiz.
            <br />
            <br />
            <br />
            YOU UNDERSTAND AND AGREE THAT ANY MATERIAL OR INFORMATION DOWNLOADED
            OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE IS DONE AT YOUR
            OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR
            ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM
            THE DOWNLOADING OR OTHERWISE OBTAINING SUCH MATERIAL OR INFORMATION.
            <br />
            <br />
            <br />
            IN NO EVENT SHALL SendBiz, ITS SUBSIDIARIES OR THIRD PARTY LICENSORS
            BE LIABLE TO ANY USER ON THE BASIS OF Inappropriate OR ABUSE USE OF
            THE SITE BY SUCH USER OR ITS DEPENDENCE ON THE SITE. THE APPLICATION
            OF THIS DISCLAIMER OF LIABILITY WILL PROTECT THEM AGAINST
            COMPENSATION FOR INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
            EXEMPLARY OR PUNITIVE DAMAGES, WHETHER THE CLAIMS ARE BASED ON A
            WARRANTY OR A CONTRACT, ANY TORT (INCLUDING NEGLIGENCE), INDEMNITY
            OR OTHERWISE, EVEN IF SendBiz, ITS SUBSIDIARIES OR THIRD PARTY
            LICENSORS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND
            INTERESTS, AND WITHOUT ANY FAILURE TO MEET THE ESSENTIAL OBJECTIVE
            OF ANY OTHER REMEDY. THIS DISCLAIMER OF LIABILITY APPLIES WHETHER
            THE DAMAGES ARE DUE TO THE USER'S USE OR MISUSE OF THE SITE AND
            RELIANCE ON THE SITE, RELIANCE ON OR DAMAGE RESULTING FROM
            INFORMATION PUBLISHED ON THE SITE, THE USER'S INABILITY TO USE THE
            SITE, OR THE INTERRUPTION, SUSPENSION OR DELETION OF THE SITE
            (INCLUDING IF SUCH DAMAGES ARE CAUSED BY THIRD PARTIES). THIS
            DISCLAIMER ALSO APPLIES TO DAMAGES RELATING TO OTHER GOODS OR
            SERVICES RECEIVED VIA THE SITE, PRESENTED ON THE SITE OR RECEIVED
            VIA A LINK MADE AVAILABLE ON THE SITE. THIS DISCLAIMER OF LIABILITY
            ALSO APPLIES, WITHOUT LIMITATION, TO PROCUREMENT COSTS ASSOCIATED
            WITH SUBSTITUTE GOODS AND SERVICES, LOST PROFITS OR LOST DATA. THIS
            DISCLAIMER ALSO APPLIES TO THE PERFORMANCE OR LACK OF PERFORMANCE OF
            THE SITE OR ANY INFORMATION OR PRODUCTS CONTAINED ON THE SITE,
            ACCESSIBLE VIA A LINK ON THE SITE OR OTHERWISE ASSOCIATED WITH THE
            SITE. FINALLY, THIS DISCLAIMER OF LIABILITY ALSO APPLIES
            NOTWITHSTANDING ANY FAILURE TO ACHIEVE THE ESSENTIAL PURPOSE OF ANY
            LIMITED REMEDY, TO THE EXTENT PERMITTED BY LAW. TO THE MAXIMUM
            EXTENT PERMITTED BY LAW, THE MAXIMUM AGGREGATE LIABILITY OF SENDBIZ,
            ITS AFFILIATES AND THEIR LICENSORS FOR ALL DAMAGES, LOSSES AND
            REMEDIES, WHETHER BASED ON A CONTRACT, DISLIGHT (INCLUDING
            NEGLIGENCE) OR ANY OTHER, WILL BE LIMITED TO THE SUM THAT YOU HAVE
            PAID TO SendBiz TO USE THE SITE.
            <br />
            Without limiting the scope of the foregoing, under no circumstances
            shall SendBiz, its subsidiaries or their third party licensors be
            held liable for delays or reduced performance resulting directly or
            indirectly from natural disasters, forces or causes beyond their
            control, including but not limited to breakdowns and failures
            affecting the Internet connection, computer equipment,
            telecommunications equipment or other equipment; power outages;
            strikes; labor disputes; riots; insurrections; civil unrest; labour
            or element shortages; fires; floods; storms; explosions; acts of
            God; wars; government actions; local or foreign court injunctions;
            failure of third parties to perform; or decreases or variations in
            heat, lighting or air conditioning.
            <br />
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>8. Sponsorship</b>
            </h4>
            <br />
            Sendbiz proposes that you can sponsor, that is to say, when a user
            searches for your ad, it will appear at the top of the list
            according to certain search filters (name, location). Each sponsored
            element is sponsored according to a period (maximum 3 months) that
            you have chosen, the pricing is fixed and corresponds to each time
            the page is seen a fee of $0.2 will be charged. At the end of the
            campaign (period that you will have indicated) the total amount of
            views will be debited from your credit card. Once a sponsorship
            campaign is started it cannot be stopped.
            <br />
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>9. Subcontractor - Supplier</b>
            </h4>
            <br />
            Sendbiz.com is a medium that offers the Platform and Services for
            Subcontractors/suppliers to promote their businesses. General
            contractors and/or buyers from various industries around the globe
            can meet with European subcontractor and/or suppliers and exchange
            information and conclude contracts primarily with each other and in
            relation to offering and purchasing of contractor services and
            products.
            <br />
            <br />
            9.1 Acceptance of the Terms
            <br />
            Sendbiz Service is offered on a condition of unqualified acceptance
            of all the Terms and Conditions set forth herein as well as our
            Privacy Policy which is incorporated by reference into this
            Agreement. These Terms constitute a binding legal agreement between
            you and SENDBIZ regarding your use and access of the Sendbiz
            service. By using or accessing the Service, you agree to have read
            these Terms, policies and notices referenced herein and agree to
            fully comply with them. If you do agree with these Terms, you must
            not use the Service. By accepting these Terms of Use, you also
            accept that SENDBIZ is not a party to any communication, transaction
            or any other arrangement between users. Whilst SENDBIZ facilitates
            communication, interaction and/or transactions between users,
            including buyers and /or subcontractors, SENDBIZ is not an agent,
            representative, broker or partner of any user.
            <br />
            <br />
            9.2 Scope of Services.
            <br />
            Subject to the terms and conditions set forth herein, SENDBIZ agrees
            to provide the subcontractor with access to the Platform and
            Services through which the subcontractor may offer certain products
            or services for purchase by other users (customers or buyers) of the
            Service.
            <br />
            <br />
            9.3 Transactions with Buyers/Customers
            <br />
            To access SENDBIZ services, users will need to sign up and register
            for an account by providing personal or company information
            including names, logos, contact details, website or physical address
            and other information we may require for the purposes of accessing
            or using the service. When you create an account with SENDBIZ, you
            consent to receive updates, information or newsletter from us..
            Maintaining security and confidentiality of your account is your own
            responsibility and we are not responsible for any unlawful or
            unauthorized access to your account or any loss or damage in
            connection thereto. You must notify us promptly if you think your
            login information has been compromised or no longer confidential.
            You shall not impersonate any user or unlawfully access anyone’s
            account.
            <br />
            <br />
            9.4 Access and registration
            <br />
            Registration. Buyers must register with the Service in order to be
            able to see and communicate with listed subcontractors and use the
            Sendbiz system, access or purchase the offers, services or products
            made available by subcontractors. Offers: Through the Platform and
            the Services, Subcontractor may list, offer or otherwise provide
            services and products. Any arrangement, communication or transaction
            between the Subcontractor and a buyer/customer is exclusively
            between the Subcontractor and the buyer/customer in question. The
            SENDBIZ does not endorse, approve or recommend the products or
            services of subcontractors. Further, the SENDBIZ reserves the right
            and discretion to list, advertise or otherwise deal with the
            services and products of any other subcontractor, whether or not
            they are competitive or similar to those provided by the
            subcontractor. Subcontractor responsibilities. SENDBIZ takes no
            responsibility whatsoever for information or content supplied
            through the Platform or Services that is made available by third
            parties including Buyers. It is the subcontractor's responsibility
            to make sure that information obtained or accessed herein is
            suitable for them. Subcontractor shall be solely responsible for any
            obligation to pay fees or other payment to any third parties,
            including employees, agents or suppliers of subcontractor.
            Subcontractors shall have sole responsibility to ensure that their
            employees or agents comply with these Terms including having
            appropriate corporate authority to act on their behalf under these
            Terms and Conditions. Subcontractor shall also be responsible for
            addressing their customer's enquiries. Relationship with Buyers or
            Customers. If a buyer permits a subcontractor to access buyer's
            personal information for use with the Service, subcontractor and
            buyer shall be solely liable for any disclosure, use or misuse of
            such information resulting from such access for the resolution of
            any dispute arising therefrom. Sendbiz may, in the ordinary course
            of business, charge the buyer fees for access, ordering, tracking
            and managing purchase of subcontractor products or services.
            <br />
            <br />
            9.5 Ownership and Intellectual Property Rights
            <br />
            All content posted on the Service by subcontractor including without
            limitation text, information, data, photographs, audio, video,
            posts, comments, scripts, graphics, software and other interactive
            content created, generated, shared or otherwise supplied by
            subcontractor through or via the Services (User content) shall be
            the property of respective subcontractor and subcontractor shall be
            solely responsible for such content. Business information provided
            by subcontractors shall be visible for other register users of the
            Service. Subcontractor agrees that other users of the Service
            including but not limited to Buyers or Sendbiz’s customers may
            access and use such information as permitted and regulated by
            Sendbiz. Subcontractor also agrees that the access and use of such
            information is subject to our Privacy Policy. License to User
            Content. Notwithstanding the provisions of clause 10,4 Offers above,
            subcontractor hereby grants to SENDBIZ a worldwide, non-exclusive,
            transferable, irrevocable and royalty-free license to use, utilize,
            distribute, print, copy, display, modify, transmit or otherwise make
            use of user content for the purpose of developing and providing the
            Service, promoting and advertising the subcontractor’s products and
            services or otherwise in relation to SENDBIZ ’s services
            <h4 style={{ opacity: 0.9 }}>
              <b>10. Applicable law and dispute resolution</b>
            </h4>
            <br />
            This Agreement shall be governed by the laws of Delaware, United
            States, regardless of the subject matter of the dispute, including,
            without limitation, the validity, interpretation and performance of
            this Agreement.
            <br />
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>11. Severability and Restatement of Provisions</b>
            </h4>
            <br />
            Each provision of this Agreement is self-contained. If any provision
            of this Agreement becomes unenforceable or illegal, such provision
            shall be modified only to the extent necessary to keep this
            Agreement in effect after such modification. The unenforceability or
            invalidity of any provision shall not affect any other provision of
            this Agreement, which shall remain in full force and effect, and
            shall be construed and enforced as if such provision had never been
            included or had been modified as set forth above, as the case may
            be.
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>12. Use of the Site by Minors</b>
            </h4>
            <br />
            Use of this Site is not intended for persons under 16 years of age.
            However, if local legislation requires you to be older in order for
            SendBiz to legally provide you with the services of the Site, the
            age thus prescribed shall be the applicable minimum age. In all
            jurisdictions outside the European Union, if you are under 18 years
            of age or have not reached the age of majority in your jurisdiction,
            you must use SendBiz under the supervision of a parent, legal
            guardian or responsible adult. SendBiz adopts a series of measures
            to guarantee the exclusion of persons who have not reached the
            minimum age and to comply with any other age-related requirements.
            If you do not meet the age requirements, please send an email to
            privacy-dept @ SendBiz.io (remember to delete the spaces for sending
            an email).
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>13. Third-Party Intellectual Property Policy</b>
            </h4>
            <br />
            13.1 Copyright Policy Sendbiz, Inc. respects the copyright of others
            and we expect our users of the SENDBIZ Sites and Service to do the
            same. We have adopted a policy towards alleged copyright
            infringement in accordance with the Digital Millennium Copyright Act
            ("DMCA"). We reserve the right to remove, suspend, terminate access,
            or take other appropriate actions against users or third parties
            that we believe, in good faith, to be repeat offenders of our policy
            against infringing copyrighted element ( content, company name,
            picture, video, … ). It is important to note that Sendbiz, Inc. does
            not determine copyright ownership in the event of a dispute. We will
            remove content, as required by law, from our site if we receive a
            complete and valid takedown notice. The party whose element has been
            removed then has the ability to respond with a counter notice. If
            and when we receive a valid counter notification, we will forward it
            to the person who submitted the original takedown request. If the
            dispute continues, it will be up to the two parties to settle the
            issue. 13.2 To File a Copyright Complaint (DMCA) If you believe that
            any element on or accessible through the SENDBIZ Sites or Service
            infringes your copyright, pursuant to section 512(c)(3) of the DMCA
            , please send us a written notice requesting takedown per the DMCA,
            containing the following necessary information to our Designated
            Agent, listed below, or to dmcanotices@Sendbiz.io: Identification of
            the copyrighted work(s) or element(s) claimed to be infringed;
            Identification of the element that is claimed to be infringing, or
            to be the subject of allegedly infringing activity and that is to be
            removed, and information reasonably sufficient to permit us to
            locate and verify the element; Your contact information sufficient
            for us to identify you, including: name, and address, telephone
            number and/or email address. If you are not the owner of the
            copyrighted element that has been allegedly infringed, please
            describe your relationship to the copyright owner; Include the
            following statement:"I have a good faith belief that the use of the
            element in the manner complained of is not authorized by the
            copyright owner, its agent, or the law."; Include the following
            statement:"The information in this notice is accurate, and under
            penalty of perjury, I am authorized to act on behalf of the owner of
            an exclusive right that is allegedly infringed."; and A physical or
            electronic signature of a person authorized to act on behalf of the
            owner of an exclusive right that is allegedly infringed. After
            receiving a properly-submitted DMCA notice sent to our Designated
            Agent, it is our policy to: Remove or disable access to the
            allegedly infringing work or element; Notify the content provider,
            member, or user who posted it that we have removed or disabled
            access to the work or element and that they can file a
            counter-notice if they believe they have the right to use or post
            the element; and In the case of repeat infringers, remove the
            allegedly infringing element and terminate such content provider's
            access to the service. Please note that, if we take action on your
            request, our notification to the content provider, member or user
            who posted the allegedly-infringing element includes a complete copy
            of your Copyright Complaint (which may include your name, address,
            phone and email address). If you do not wish your contact
            information to be provided, you may want to consider using an agent
            to report the complaint on your behalf. 13.3 Copyright Complaint
            (DMCA) Counter-Notification Policy If you believe your elements
            which was removed is either not infringing or you believe you have
            the right from the copyright owner, the copyright owner's agent or
            pursuant to the law to post such element, you must submit a
            counter-notice containing the following information to the
            Designated Agent listed below: Identification of the element that
            was mistakenly removed or disabled from access, in writing; A
            description that reasonably identifies the element that has been
            removed, or to which access has been disabled, and the location of
            the element prior to its removal; Your name, address, telephone
            number and email address; Include the statement: "I swear under
            penalty of perjury that I have a good faith belief that the element
            was removed or disabled from access as a result of mistake or
            misidentification of the element to be removed or disabled.";
            Include the statement: "I consent to the jurisdiction of the Federal
            District Court for the judicial district in which my address is
            located or any judicial district in which the service provider may
            be found, and I will accept service of process from the person who
            provided the notification under Section 512(c)(1)(C) or an agent of
            such person."; and A physical or electronic signature of the content
            provider, member or user. If we receive a valid
            counter-notification, we may send a copy of such notice to the
            original complaining party informing them that we may restore the
            removed element or cease disabling it in 10 to 14 business days.
            Unless the copyright owner files an action seeking a court order
            against the content provider, member or user, the removed element
            may be replaced, or access to it restored, in 10 to 14 business
            days, or more, after receipt of the counter-notice. 13.4 Copyright
            Repeat Infringers When appropriate, we will terminate a repeat
            infringer's access to its accounts. If you believe that a user is a
            repeat infringer, please follow the instructions above to contact us
            and provide information sufficient for us to verify that the user is
            a repeat infringer. 13.5 Designated Agent Please contact Sendbiz,
            Inc.’s Designated Agent for DMCA copyright complaints at: Copyright
            Manager – Designated Agent for DMCA Notices Sendbiz, Inc. 26
            O'FARRELL STREET #500 SAN FRANCISCO CA 94108
            Email: dmcanotices@Sendbiz.io 13.6 Trademark Policy. A trademark is
            a word, slogan, logo, symbol or design that can be used to
            distinguish the products or services offered by one party from those
            offered by others. Sendbiz, Inc. also respects the trademark rights
            of third parties and we expect our users of the SENDBIZ Sites and
            Service to do the same. We reserve the right to remove, suspend,
            terminate access, or take other appropriate actions against users or
            third parties that we believe, in good faith, to be repeat offenders
            of our policy against infringing trademarked element. 13.7 To File a
            Trademark Complaint To report a claim of trademark infringement,
            please provide the following items to dmcanotices@Sendbiz.io: Your
            complete contact information, including: full name, telephone
            number, address and email. If you are not the owner of the trademark
            that has allegedly been infringed, please describe your relationship
            to the trademark owner; The specific word, symbol, etc. to which you
            claim trademark rights; The basis of your trademark rights,
            including, if applicable, the application or registration number;
            The country in which you claim trademark rights; The products and/or
            services covered by your claimed trademark rights; Identification of
            the element that is claimed to be infringing, including information
            regarding the location of the allegedly infringing elements that you
            seek to have removed, with sufficient detail so that we are capable
            of finding such element. The easiest way to do this is to provide a
            URL which leads directly to the allegedly infringing content; A
            statement detailing why the use of the trademark(s) at issue in the
            allegedly infringing elements creates consumer confusion as to
            source, origin, affiliation or endorsement; A statement representing
            that the trademark(s) at issue was registered and/or in use prior to
            the alleged infringement; The following statement: "I represent that
            the information in this notification is true and correct and that I
            am authorized to act on behalf of the trademark owner."; and Your
            electronic or physical signature. If we receive all of the above
            requested information, we will process your claim and consider
            whether to remove the allegedly infringing element. If we remove the
            content, we will issue a warning to the party that posted the
            content alerting them the element was removed due to a claim of
            trademark infringement. We may provide them with your contact
            information, including your name and email address. If they believe
            the content was improperly removed, they will be encouraged to
            contact you directly to resolve the issue. If you do not wish that
            your contact information be provided, you might want to consider
            using an agent to report the Complaint on your behalf. In some
            cases, the removed element may be restored and you will be notified.
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>14. Products in beta version</b>
            </h4>
            <br />
            SendBiz may offer products and features in beta versions on our
            Site. Products or functionalities offered in beta or test versions
            are labeled "beta". These beta products or features may be available
            in limited quantities and only in certain locations and for a
            limited time. SendBiz may discontinue or cancel all or part of a
            product or feature in a beta version at any time and without notice.
            ANY BETA PRODUCT OR FEATURE IS BEING TESTED, IS PROVIDED ON AN "AS
            IS" AND "AS AVAILABLE" BASIS, AND IS LIKELY TO BE DEFECTIVE. THE
            PRIMARY PURPOSE OF THIS BETA IS TO GATHER FEEDBACK ON PRODUCT
            PERFORMANCE. YOU ARE ADVISED TO BACK UP YOUR IMPORTANT DATA, TO
            EXERCISE CAUTION AND NOT TO RELY IN ANY WAY ON THE PROPER
            FUNCTIONING OR PERFORMANCE OF THE BETA PRODUCT OR FUNCTIONALITY OR
            ASSOCIATED CONTENT. If you have been invited to test a beta product
            or feature, you agree that, except as otherwise expressly stated
            herein or in SendBiz's written agreement, any beta product or
            feature and any documents provided with a beta product or feature
            constitute confidential information held by SendBiz. You must only
            allow authorized users, who have legitimately obtained access to the
            beta product or functionality, to access the beta product or
            functionality or any associated documents. You agree not to
            transfer, copy, disclose, transmit or otherwise make available such
            confidential information in any form whatsoever to any third party
            without SendBiz's prior written consent.
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>15. Questions or complaints</b>
            </h4>
            <br />
            If you have any questions or complaints about the Site, consult our
            SendBiz Support (https://sendbiz.helpscoutdocs.com/). By using our
            SendBiz Support, you agree to access the Helpscout Third Party Site
            and to receive cookies from this Site, as provided in its cookie
            policy. Please note that communications within Helpscout will not
            necessarily be secure. Therefore, we recommend that you do not
            provide sensitive information (credit card data, etc.) when you
            contact us by email.
            <br />
            <br />
            <br />
            <h4 style={{ opacity: 0.9 }}>
              <b>16. Miscellaneous</b>
            </h4>
            <br />
            This Agreement constitutes the entire agreement between the parties
            with respect to the subject matter hereof, including all provisions
            applicable to the parties, and supersedes any prior or
            contemporaneous agreements or understandings, written or oral,
            dealing with the same subject matter. Any waiver of any of the
            provisions of this Agreement shall be valid only if it is provided
            in writing and signed by SendBiz. This Agreement and all amendments
            and additional agreements agreed between SendBiz and you in
            connection with the Site constitute the entire Agreement between
            SendBiz and you with respect to the Site. If any provision of this
            Agreement is declared invalid by a court of competent jurisdiction,
            the invalidity of such provision shall not affect the validity of
            the other provisions of this Agreement, which shall remain in full
            force and effect. You agree that this Agreement may only be modified
            or replaced by mutual written agreement signed by an authorized
            representative of each party. In other words, exchanges by email or
            telephone between you and a SendBiz employee do not constitute an
            amendment to this Agreement. By accessing or using the Site, you
            consent to the content of this Agreement and agree to comply with
            all applicable laws and regulations, including U.S. export and
            re-export control laws and regulations, copyright laws and any other
            laws relating to intellectual property. You represent, warrant and
            covenant that you (a) are not located or reside in, or are not a
            national of, a country subject to a U.S. embargo or other
            restrictions, or a country designated by the U.S. as a "terrorism
            supporting" country; and (b) are not on the U.S. Government's list
            of restricted end-users. The elements on the Site are subject to
            "Restricted Rights". Any use, reproduction or availability by the
            Government is subject to certain restrictions set forth in
            applicable laws and regulations. Use of documents by the government
            constitutes recognition by the government of SendBiz's exclusive
            rights to such documents. You understand and acknowledge that
            SendBiz, its subsidiaries or their licensors own all right, title
            and interest in and to the Site and all intellectual property rights
            associated with the Site. SendBiz reserves all rights not expressly
            set forth herein. You are not authorized to modify the copyright
            notices, notes relating to exclusive rights and attribution of
            trademarks and service marks, patented product markings and any
            other indication of ownership appearing on the documents accessible
            via the Site, with the exception of your User Content. The use of
            the documents or descriptions, derivative use of the Site or the
            documents contained therein, and the use of data mining, robots or
            other data gathering and extraction tools is strictly prohibited.
            You may not frame any portion of the Site or the elements contained
            therein. In the event of an insoluble conflict between these SendBiz
            General Terms and Conditions of Use and the Terms and Conditions of
            Use for the SendBiz Advertising Program, the SendBiz CV Program,
            Seen by SendBiz, SendBiz Sponsored Ads, the Terms and Conditions of
            Use for Publishers or other terms and conditions applicable to you,
            the Terms and Conditions of Use of the SendBiz Advertising Program,
            the SendBiz CV Program, Seen by SendBiz, SendBiz Sponsored Ads, the
            Terms and Conditions of Use for Publishers or other terms applicable
            to you shall be governed by the provisions that they define
            respectively, or otherwise by those of SendBiz's general terms and
            conditions of use. Any waiver of any of the provisions of this
            Agreement shall be valid only if it is provided in writing and
            signed by SendBiz. This Agreement and all amendments and additional
            agreements agreed between SendBiz and you in relation to the Site
            constitute the entire Agreement between SendBiz and you in relation
            to the Site. If any provision of this Agreement is declared invalid
            by a court of competent jurisdiction, the invalidity of such
            provision shall not affect the validity of the other provisions of
            this Agreement, which shall remain in full force and effect. You
            agree that this Agreement may only be modified or replaced by mutual
            written agreement signed by an authorized representative of each
            party. In other words, exchanges by email or telephone between you
            and a SendBiz employee do not constitute an amendment to this
            Agreement. Any notification addressed to SendBiz must be sent to the
            SendBiz company concerned, indicated in the first paragraph of this
            Agreement, by registered or priority mail, by air or by express
            courier. It shall be deemed to have been effectively delivered once
            received and must specify the Program to which it relates (e.g.,
            SendBiz Advertising Program, SendBiz CV Program or SendBiz Publisher
            Program). Notifications may be sent to you by email to the email
            address indicated in your account settings or by posting a message
            on your account interface, and shall be deemed to have been received
            once sent (for emails) or no later than fifteen (15) days after they
            are posted (for messages sent on the interface of your account
            associated with the SendBiz Advertising Program). You may not assign
            or transfer your rights or obligations hereunder without SendBiz's
            prior written authorization, and in the absence of such
            authorization, any attempt to do so shall be null and void. SendBiz
            may freely assign or delegate its rights and obligations hereunder
            without having to inform you. In addition, SendBiz may assign any
            Agreement entered into with you to any entity related to SendBiz,
            provided that it informs you thereof. You and SendBiz are not legal
            partners or agents, but independent contractors.
            <br />
            <br />
            <br />
            <br />
          </Col>
          <Col span={3}></Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default TermsnCond;
