import {
  Card,
  Row,
  Col,
  Tag,
  Spin,
  Tooltip,
  Modal,
  Typography,
  Collapse,
} from 'antd';
import { useLocation } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import AppliedJobStatus from '../../layout/applied-job-status';
import cardDefaultPic from '../../../Components/asset/card.svg';
import APIManager from '../../../APIManager';
import moment from 'moment';
import { fileUrl } from '../../../Shared/imageUrlPath';
import mailIcon from '../../asset/mail.svg';
import { CURRENCY } from '../../constants/currency';
import NoDataFound from '../../../PageNotFound/NoData';
import { NavLink } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import styles from './offersReceived.module.css';

const SubcontractorReceiveDetails = (props) => {
  const search = useLocation().search;
  const jobid = new URLSearchParams(search).get('jobid');
  const companyid = new URLSearchParams(search).get('companyid');
  const [data, setData] = useState([]);
  const [jobDetails, setJobDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [selectedCandidate, setSelectedCandidate] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { Paragraph } = Typography;
  const checkChannelExist = (id) => {
    APIManager.checkChannel(id).then((resp) => console.log(''));
  };
  useEffect(() => {
    loadData('', 1);
  }, []);
  const loadData = (searchKeyword = '', pageNumber) => {
    setIsLoading(true);
    APIManager.getSubcontractorJobApplication(
      jobid,
      companyid,
      searchKeyword.toLowerCase(),
      pageNumber
    ).then((resp) => {
      setData(resp.data);
      setJobDetails(resp.data.data[0]);
      setResultsPerPage(resp.data.results);
      setTotalCount(resp.data.totalCount);
      setTotalPages(resp.data.totalPages);
      setCurrentPage(resp.data.currenctPage);
      setIsLoading(false);
    });
  };
  return (
    <div>
      {isLoading ? (
        <div className={'d-flex justify-content-center'}>
          <Spin />
        </div>
      ) : (
        <>
          {!data?.data?.length ? (
            <NoDataFound />
          ) : (
            !isLoading && (
              <>
                <Row style={{ justifyContent: 'center' }}>
                  <Col
                    xs={24}
                    onClick={() => {
                      props.history.goBack();
                    }}
                  >
                    <LeftOutlined className="back-icon" />
                    <span className="back-btn">Go back</span>
                  </Col>
                  <Col xs={24} className={styles.heading}>
                    Applicants :{' '}
                  </Col>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <Card className={styles.card}>
                    <Row gutter={15}>
                      <Col xs={5} sm={4} className={styles.imageCol}>
                        <img
                          alt="example"
                          src={
                            jobDetails.applied_for_job.job_logo
                              ? fileUrl.fileUrlPath +
                                jobDetails.applied_for_job.job_logo
                              : jobDetails.applied_for_job.defaultLogo
                              ? fileUrl.fileUrlPath +
                                jobDetails.applied_for_job.defaultLogo
                              : cardDefaultPic
                          }
                          className={styles.image}
                        />
                      </Col>
                      <Col xs={19} sm={20}>
                        <Col xs={23} sm={24}>
                          <div className="service-name">
                            <NavLink
                              to={`apply-for/${jobDetails?.applied_for_job?.id}`}
                              onClick={(e) => e.stopPropagation()}
                              target="_blank"
                              style={{
                                textDecoration: 'underline',
                                color: '#000',
                              }}
                            >
                              {jobDetails.applied_for_job.title}
                            </NavLink>
                            <Tag
                              color="volcano"
                              className={`s-price-tag ${styles.tag}`}
                            >
                              {`${data?.totalCount} Applicants`}
                            </Tag>
                          </div>
                        </Col>
                        <Col
                          xs={24}
                        >
                          <NavLink
                            to={`comp-id${jobDetails?.addedByCompany?._id}`}
                            onClick={(e) => e.stopPropagation()}
                            // target="_blank"
                            className="job-card-4 comp-hover "
                            style={{
                              textDecoration: 'underline',
                              fontSize: 'initial',
                            }}
                          >
                            {jobDetails?.addedByCompany?.comp_info?.bus_name
                              ? jobDetails?.addedByCompany?.comp_info?.bus_name
                                  ?.charAt(0)
                                  ?.toUpperCase() +
                                jobDetails?.addedByCompany?.comp_info?.bus_name?.slice(
                                  1
                                )
                              : jobDetails?.addedByCompany?.comp_info?.comp_name
                                  ?.charAt(0)
                                  ?.toUpperCase() +
                                jobDetails?.addedByCompany?.comp_info?.comp_name?.slice(
                                  1
                                )}
                          </NavLink>
                        </Col>
                        <Col
                          xs={24}
                          style={{ marginLeft: '10px' }}
                        >
                          <p className="job-card-5">
                            <img
                              src={process.env.PUBLIC_URL + '/location-red.png'}
                            />
                            <span className={styles.sideMargin}>
                              {`${
                                jobDetails?.addedByCompany?.contact_info
                                  ?.city !== ''
                                  ? jobDetails?.addedByCompany?.contact_info
                                      ?.city + ', '
                                  : ''
                              }`}
                              {`${
                                jobDetails?.addedByCompany?.contact_info
                                  ?.state !== ''
                                  ? jobDetails?.addedByCompany?.contact_info
                                      ?.state + ', '
                                  : ''
                              }`}
                              {`${
                                jobDetails?.addedByCompany?.contact_info
                                  ?.country !== ''
                                  ? jobDetails?.addedByCompany?.contact_info
                                      ?.country
                                  : ''
                              }`}
                            </span>
                          </p>
                        </Col>
                        <Col xs={24} style={{ marginLeft: '10px' }}>
                          <span>
                            <img src="./clock-red.png" style={{ width: 14 }} />
                          </span>
                          <span
                            className="service-s-desc"
                            style={{
                              paddingLeft: '8px',
                              verticalAlign: 'middle',
                            }}
                          >
                            {moment(jobDetails.applied_for_job.create).format(
                              'DD MMM YY'
                            )}
                          </span>
                        </Col>
                      </Col>
                    </Row>
                  </Card>
                </Row>
                <Col xs={24} className={styles.heading}>
                  {data.data.length}{' '}
                  {data.data.length > 1 ? 'Candidates' : 'Candidate'}
                </Col>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  {data?.data?.map(
                    ({
                      candidate,
                      applied_at,
                      applied_for_job,
                      status,
                      job_title,
                      appliedByCompany,
                      addedByCompany,
                    }) => (
                      <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Card className={styles.card}>
                        <Row gutter={15}>
                          <Col xs={5} sm={4}>
                            <img
                              alt="abc"
                              src={
                                appliedByCompany?.logo?.name
                                  ? fileUrl.fileUrlPath +
                                    appliedByCompany?.logo?.name
                                  : '/company_logo.png'
                              }
                              className={styles.image}
                            />
                          </Col>
                          <Col xs={15} sm={16}>
                            <Row>
                              <Col xs={23}>
                                <Row>
                                  <Col xs={24} sm={12}>
                                    <h4
                                      style={{
                                        textTransform: 'capitalize',
                                        fontWeight: '700',
                                      }}
                                    >
                                      {candidate.name}
                                    </h4>
                                  </Col>
                                  <Col xs={24} sm={12}>
                                    <span className={styles.cost}>Cost:</span>
                                    <span className={styles.titleValue}>
                                      {' '}
                                      {CURRENCY.filter(
                                        (data) =>
                                          data.name === candidate?.currency
                                      ).length > 0 &&
                                        CURRENCY.filter(
                                          (data) =>
                                            data.name === candidate?.currency
                                        )[0].symbol}
                                    </span>
                                    <span className={styles.titleValue}>
                                      {candidate?.price}
                                    </span>
                                    <span className={styles.titleValue}>
                                      {candidate?.jobPayment || ' '}
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={24} sm={8}>
                                    <span className={styles.title1}>
                                      Availability :{' '}
                                    </span>
                                    <span className={styles.titleValue}>
                                      {candidate?.availability}
                                    </span>
                                  </Col>
                                  <Col xs={24} sm={8}>
                                    <span className={styles.title1}>
                                      Experience :{' '}
                                    </span>
                                    <span className={styles.titleValue}>
                                      {candidate?.experience} Years
                                    </span>
                                  </Col>
                                  <Col xs={24} sm={8}>
                                    <span className={styles.title1}>
                                      Current Position :{' '}
                                    </span>
                                    <span className={styles.titleValue}>
                                      {candidate.current_position}
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    xs={0}
                                    sm={8}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <a
                                      target="_blank"
                                      href={`${fileUrl.fileUrlPath}${candidate.resume}`}
                                      style={{
                                        textDecoration: 'none',
                                      }}
                                    >
                                      <span>
                                        <img
                                          src="./link.svg"
                                          style={{
                                            width: 14,
                                            marginLeft: '-5px',
                                          }}
                                        />
                                      </span>
                                      <span className={styles.title1}>
                                        View Resume PDF
                                      </span>
                                    </a>
                                  </Col>
                                  <Col xs={0} sm={8}>
                                    <span className={styles.title1}>
                                      Applied on:
                                    </span>
                                    <span className={styles.titleValue}>
                                      {moment(applied_at).format('DD MMM YYYY')}
                                    </span>
                                  </Col>
                                  <Col
                                    xs={0}
                                    sm={8}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {candidate.documents !== '' ? (
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${fileUrl.fileUrlPath}${candidate.documents}`}
                                        className={styles.title1}
                                      >
                                        <span>
                                          <img
                                            src="./link.svg"
                                            style={{
                                              width: 14,
                                              marginLeft: '5px',
                                            }}
                                            alt=""
                                          />
                                        </span>
                                        <span className={styles.title1}>
                                          View Document PDF
                                        </span>
                                      </a>
                                    ) : (
                                      <>
                                        <span>
                                          <img
                                            src="./link.svg"
                                            style={{
                                              width: 14,
                                              marginLeft: '5px',
                                            }}
                                            alt=""
                                          />
                                        </span>
                                        <span
                                          className={styles.title1}
                                          onClick={() => {
                                            handleOpen();
                                            setSelectedCandidate(
                                              data?.candidate
                                            );
                                          }}
                                        >
                                          View Document PDF
                                        </span>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={4}>
                            <AppliedJobStatus
                              className={styles.image1}
                              status={status}
                              job_id={jobDetails.id}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} sm={0} className={styles.titleDateCol}>
                            <span className={styles.title1}>Applied on:</span>
                            <span className={styles.titleValue}>
                              {moment(applied_at).format('DD MMM YYYY')}
                            </span>
                          </Col>
                          <Col xs={12} sm={0}></Col>
                          <Col xs={12} sm={0} style={{ cursor: 'pointer' }}>
                            <a
                              target="_blank"
                              href={`${fileUrl.fileUrlPath}${candidate.resume}`}
                              style={{
                                textDecoration: 'none',
                              }}
                            >
                              <span>
                                <img
                                  src="./link.svg"
                                  style={{ width: 14, marginLeft: '-5px' }}
                                />
                              </span>
                              <span className={styles.title1}>
                                View Resume PDF
                              </span>
                            </a>
                          </Col>
                          <Col xs={12} sm={0} style={{ cursor: 'pointer' }}>
                            {candidate.documents !== '' ? (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`${fileUrl.fileUrlPath}${candidate.documents}`}
                                className={styles.title1}
                              >
                                <span>
                                  <img
                                    src="./link.svg"
                                    style={{ width: 14 }}
                                    alt=""
                                  />
                                </span>
                                <span className={styles.title1}>
                                  View Document PDF
                                </span>
                              </a>
                            ) : (
                              <>
                                <span>
                                  <img
                                    src="./link.svg"
                                    style={{ width: 14 }}
                                    alt=""
                                  />
                                </span>
                                <span
                                  className={styles.title1}
                                  onClick={() => {
                                    handleOpen();
                                    setSelectedCandidate(data?.candidate);
                                  }}
                                >
                                  View Document PDF
                                </span>
                              </>
                            )}
                          </Col>
                        </Row>
                        <div
                          className="colapsable"
                          style={{ marginTop: '15px' }}
                        >
                          <Collapse>
                            <Collapse.Panel header="More Info">
                              <Row>
                                <Col xs={6}>
                                  <span className={styles.title}>
                                    Location :{' '}
                                  </span>
                                </Col>
                                <Col xs={18}>
                                  <span className={styles.titleValue1}>
                                    {candidate?.location}
                                  </span>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6}>
                                  <span className={styles.title}>
                                    Information :{' '}
                                  </span>
                                </Col>
                                <Col xs={18}>
                                  <span className={styles.titleValue1}>
                                    {candidate?.information}
                                  </span>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24}>
                                  <p
                                    className="visit_cand_personal_10"
                                    style={{
                                      color: 'black',
                                      marginTop: '11px',
                                      marginBottom: '10px',
                                      fontFamily: 'Gilroy Bold',
                                    }}
                                  >
                                    CONTACT{' '}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6}>
                                  <span className={styles.title}>
                                    Company :{' '}
                                  </span>
                                </Col>
                                <Col xs={18}>
                                  <NavLink
                                    to={`comp-id${jobDetails?.appliedByCompany?._id}`}
                                    onClick={(e) => e.stopPropagation()}
                                    target="_blank"
                                    className="job-card-4 comp-hover"
                                    style={{
                                      textDecoration: 'underline',
                                      fontSize: 'initial',
                                    }}
                                  >
                                    {appliedByCompany?.comp_info?.bus_name
                                      ? appliedByCompany?.comp_info?.bus_name
                                      : appliedByCompany?.comp_info?.comp_name}
                                  </NavLink>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6}>
                                  <span className={styles.title}>
                                    Username :{' '}
                                  </span>
                                </Col>
                                <Col xs={18}>
                                  <span className={styles.titleValue1}>
                                    {appliedByCompany?.comp_info?.first_name}
                                    &nbsp;
                                    {appliedByCompany?.comp_info?.last_name}
                                  </span>
                                </Col>
                                <Col xs={6}>
                                  <span className={styles.title}>
                                    User Job Title :{' '}
                                  </span>
                                </Col>
                                <Col xs={15} sm={16}>
                                  <span className={styles.titleValue1}>
                                    {appliedByCompany?.comp_info?.job_profile}
                                  </span>
                                </Col>
                                <Col xs={0} sm={2}>
                                  <div
                                    // className={'d-flex justify-content-end'}
                                    onClick={() => {
                                      var ls = require('local-storage');
                                      ls.set(
                                        'chatUserId',
                                        data.applied_by_candidate_id
                                      );
                                      checkChannelExist(
                                        data.applied_by_candidate_id
                                      );
                                      props.history.push({
                                        pathname: '/company-mailbox',
                                        userInfo: Object.assign(
                                          appliedByCompany,
                                          {
                                            profile:
                                              appliedByCompany?.logo?.name,
                                          }
                                        ),
                                        canId: appliedByCompany?.comp_id,
                                      });
                                    }}
                                  >
                                    <img
                                      src={mailIcon}
                                      // className="  service-icons"
                                      style={{
                                        cursor: 'pointer',
                                        marginLeft: '7px',
                                        // marginRight: '-44px',
                                      }}
                                      alt=""
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6}>
                                  <span className={styles.title}>Email : </span>
                                </Col>
                                <Col xs={15} sm={16}>
                                  <span className={styles.titleValue1}>
                                    {candidate?.email}
                                  </span>
                                </Col>
                                <Col xs={0} sm={2}>
                                  <a href={`mailto:${candidate?.email}`}>
                                    <img
                                      src="/telegram.png"
                                      // style={{ marginLeft: '30px' }}
                                      alt=""
                                    />
                                  </a>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6}>
                                  <span className={styles.title}>
                                    Phone Number :{' '}
                                  </span>
                                </Col>
                                <Col xs={15} sm={18}>
                                  <span className={styles.titleValue1}>
                                    {appliedByCompany?.contact_info?.phone_no
                                      ? appliedByCompany?.contact_info?.phone_no
                                      : '-'}
                                  </span>
                                </Col>
                              </Row>
                              <Row className={styles.mainCol}>
                                <Col xs={17} />
                                <Col xs={1} sm={0}>
                                  <div
                                    className={'d-flex justify-content-end'}
                                    onClick={() => {
                                      var ls = require('local-storage');
                                      ls.set(
                                        'chatUserId',
                                        data.applied_by_candidate_id
                                      );
                                      checkChannelExist(
                                        data.applied_by_candidate_id
                                      );
                                      props.history.push({
                                        pathname: '/company-mailbox',
                                        userInfo: Object.assign(
                                          appliedByCompany,
                                          {
                                            profile:
                                              appliedByCompany?.logo?.name,
                                          }
                                        ),
                                        canId: appliedByCompany?.comp_id,
                                      });
                                    }}
                                  >
                                    <img
                                      src={mailIcon}
                                      className="  service-icons"
                                      style={{
                                        cursor: 'pointer',
                                        marginBottom: '10px',
                                        marginRight: '-44px',
                                      }}
                                      alt=""
                                    />
                                  </div>
                                </Col>
                                <Col xs={2} sm={0}>
                                  <a href={`mailto:${candidate?.email}`}>
                                    <img
                                      src="/telegram.png"
                                      style={{ marginLeft: '30px' }}
                                      alt=""
                                    />
                                  </a>
                                </Col>
                              </Row>
                            </Collapse.Panel>
                          </Collapse>
                        </div>
                      </Card>
                      </Col>
                    )
                  )}
                </Row>
              </>
            )
          )}

          {
            <Modal
              title="Information"
              className="service-name"
              visible={open}
              onOk={handleClose}
              onCancel={handleClose}
              footer={null}
              maskStyle={{
                backgroundColor: '#00000073',
              }}
            >
              <Paragraph
                className={'input-label-field-custom-type-1 m-0'}
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                <p>No Document Uploaded</p>
              </Paragraph>
            </Modal>
          }
        </>
      )}
      {!isLoading && (
        <Row type="flex" alignItems="center">
          <Col span={10} />
          <Col>
            {data?.data?.length > 0 ? (
              <div style={{ margin: '10px', cursor: 'pointer' }}>
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={10}
                  firstPageText={false}
                  lastPageText={false}
                  hideDisabled={true}
                  totalItemsCount={data?.totalCount}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => {
                    loadData('', pageNumber);
                  }}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SubcontractorReceiveDetails;
