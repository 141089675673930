import React, { Component } from 'react';
import { Row, Col, Switch, Spin, Card, message, Button } from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import './servicescard.css';
import {
  DeleteFilled,
  ClockCircleOutlined,
  EditFilled,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import APIManager from '../../../APIManager/index';
import cardDefaultPic from '../../../Components/asset/card.svg';
import NoData from '../../../PageNotFound/NoData';
import ConfirmPopUp from '../../constants/ConfirmPopup';
import styles from './news.module.css';
import { ImageUrl } from '../../../Shared/imageUrlPath';

const numEachPage = 2;

class NewsCards extends Component {
  state = {
    minValue: 0,
    maxValue: 2,
    id: '',
    deleteNewsId: '',
    isLoading: false,
    data: [],
    totalPages: '',
    confirmDeletePopup: false,
  };
  componentDidMount() {
    this.makeHttpRequestWithPage(1);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.sortValue !== prevProps.sortValue) {
      this.makeHttpRequestWithPage();
    }
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.makeHttpRequestWithPage();
    }
  }

  makeHttpRequestWithPage = async (pageNumber) => {
    const sort = this.props.sortValue;
    const searchQuery = this.props.searchQuery;
    this.setState({
      isloading: true,
    });
    if ((sort && sort !== 'reset') || (searchQuery && searchQuery !== '')) {
      APIManager.sortNews(sort, searchQuery, pageNumber)
        .then((resp) => {
          this.setState({
            data: resp.data.data,
            total: resp.data.totalCount,
            //   per_page: resp.data.results,
            current_page: resp.data.currentPage,
            isloading: false,
            totalPages: resp.data.totalPages,
          });
        })
        .catch((err) => {
          this.setState({ isloading: false });
          message.error('Something went wrong!');
        });
    } else {
      APIManager.newsList(pageNumber)
        .then((resp) => {
          this.setState({
            data: resp.data.data,
            total: resp.data.totalCount,
            current_page: resp.data.currentPage,
            isloading: false,
            totalPages: resp.data.totalPages,
          });
        })
        .catch((err) => {
          this.setState({ isloading: false });
          message.error('Something went wrong!');
        });
    }
  };
  showCandidateInfo = () => {};

  handleChange = (value) => {
    this.setState({
      minValue: (value - 1) * numEachPage,
      maxValue: value * numEachPage,
    });
  };

  renderb = (e, data) => {};
  changeActiveStatus = () => {
    const id = this.state.id;
    APIManager.newsActiveStatus(id);
  };
  deleteNews = () => {
    this.setState({ confirmDeletePopup: false });
    APIManager.deleteNews(this.state.deleteNewsId)
      .then((resp) => {
        if (resp.data.isSuccess) {
          message.info(resp.data.message);
          this.makeHttpRequestWithPage(this.state.current_page);
        }
      })
      .catch((err) => {
        message.error('Something went wrong.');
      });
  };
  render() {
    let renderPageNumbers;
    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / 10); i++) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers?.map((number) => {
        return (
          <span
            key={number}
            onClick={() => this.makeHttpRequestWithPage(number)}
            className={
              this.state.current_page === number
                ? 'page-number-btn'
                : 'pagination-container'
            }
          >
            {number}
          </span>
        );
      });
    }
    return (
      <>
        <div className={'d-flex justify-content-center pt-3'}>
          {this.state.isloading ? <Spin /> : ''}
        </div>
        {this.state.confirmDeletePopup && (
          <ConfirmPopUp
            message="Are you sure you want to delete news"
            handleOk={this.deleteNews}
            closePopUp={() => this.setState({ confirmDeletePopup: false })}
          />
        )}
        {!this.state.isloading && this.state.data.length === 0 ? (
          <NoData />
        ) : (
          <>
            {!this.state.isloading &&
              this.state.data &&
              this.state.data?.map((data) => (
                <Row className={styles.cardRow}>
                  <Card className={styles.card}>
                    <Row gutter={15}>
                      <Col xs={5} sm={4}>
                        <img
                          alt="example"
                          src={
                            data.image.filter((data) =>
                              data.fileType?.includes('image')
                            ).length !== 0 && data.image[0] !== ''
                              ? ImageUrl.imageUrlPath +
                                data.image.filter((data) =>
                                  data.fileType.includes('image')
                                )[0].fileName
                              : data.companyData.logo.name
                              ? ImageUrl.imageUrlPath +
                                data.companyData.logo.name
                              : cardDefaultPic
                          }
                          style={{
                            width: '90%',
                            height: '90%',
                            maxHeight: '90px',
                            borderRadius: '10px',
                            // objectFit: 'cover',
                          }}
                        />
                      </Col>
                      <Col xs={19} sm={20} className="service-detail-row">
                        <Row>
                          <Col xs={17}>
                            <div
                              className="service-name"
                              onClick={() => {
                                var ls = require('local-storage');
                                ls.set('newsId', data._id);
                                this.props.history.push({
                                  pathname: `/news/detail/${data._id}`,
                                  newsId: data._id,
                                });
                              }}
                            >
                              {data?.title?.charAt(0)?.toUpperCase() +
                                data?.title?.slice(1)}
                            </div>
                          </Col>
                          <Col xs={7} className="icon-col">
                            <EditFilled
                              className="service-hidden-icons service-icons"
                              onClick={(e) => this.props.editNews(e, data)}
                            />
                            <DeleteFilled
                              className="service-hidden-icons service-icons"
                              onClick={() =>
                                this.setState({
                                  deleteNewsId: data._id,
                                  confirmDeletePopup: true,
                                })
                              }
                            />
                            <Switch
                              onChange={() => {
                                this.setState({ id: data._id }, () =>
                                  this.changeActiveStatus()
                                );
                              }}
                              defaultChecked={data.isActive}
                            />
                          </Col>
                        </Row>
                        <Row align="bottom" className={styles.bottomRow}>
                          <Col
                            xs={24}
                            sm={15}
                            style={{ display: 'flex', alignSelf: 'self-start' }}
                          >
                            <ClockCircleOutlined className="service-icons" />
                            <div
                              className="service-location"
                              style={{ display: 'block' }}
                            >
                              Created on{' '}
                              {moment(data.createdAt).format('YYYY/MM/DD')}
                            </div>
                          </Col>
                          <Col xs={24} sm={9}>
                            <ClockCircleOutlined className="service-icons" />
                            <div className="service-location">
                              Published on{' '}
                              {moment(data.updatedAt).format('YYYY/MM/DD')}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Row>
              ))}
            <Col xs={24} sm={0} className={styles.buttonCol}>
              <Button
                className={`add-service-btn ${styles.button}`}
                onClick={this.props.addNews}
              >
                Add new News
              </Button>
            </Col>
          </>
        )}
        {!this.state.isloading && this.state.data.length > 0 && (
          <Row className={styles.pagination}>
            <div className={'d-flex justify-content-end pt-2'}>
              <span
                className={
                  this.state.current_page === 1 ? 'pagination-side-btn' : ''
                }
                onClick={() =>
                  this.makeHttpRequestWithPage(this.state.currentPage - 1)
                }
              >
                {' '}
                <LeftOutlined />{' '}
              </span>
              <div>{renderPageNumbers}</div>
              <span
                className={
                  this.state.current_page === this.state.totalPages
                    ? 'pagination-side-btn'
                    : ''
                }
                onClick={() =>
                  this.state.current_page !== this.state.totalPages &&
                  this.makeHttpRequestWithPage(this.state.currentPage + 1)
                }
              >
                <RightOutlined />
              </span>
            </div>
          </Row>
        )}
      </>
    );
  }
}
export default withRouter(NewsCards);
