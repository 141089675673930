import React, { useState, useEffect } from 'react';
import SponsoredListCard from './sponsoredListCard';
import APIManager from '../../APIManager';
import { Spin } from 'antd';
import NoDataFound from '../../PageNotFound/NoData';

const SponsoredServices = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    APIManager.getSponsoredServices()
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);
  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        <div>
          {!data.length ? <NoDataFound /> : data?.map(
            ({
              name,
              sponsoredBy,
              sponsoredOn,
              totalViewsCount,
              initialBudget,
              sponsorExpiresAt,
              remaningBudget,
              media,
              serviceStatus,
            }) => (
              <SponsoredListCard
                name={name}
                sponsoredBy={sponsoredBy}
                sponsoredOn={sponsoredOn}
                totalViewsCount={totalViewsCount}
                initialBudget={initialBudget}
                sponsorExpiresAt={sponsorExpiresAt}
                remaningBudget={remaningBudget}
                imageUrl={media[0]?.fileName}
                serviceStatus = {
                  serviceStatus
                }
              />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default SponsoredServices;
