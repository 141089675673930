import React, { Component } from 'react';
// import '../candidate/profile.css';
import './header.css';
import { Menu, Dropdown, Avatar } from 'antd';
import {
  MessageFilled,
} from '@ant-design/icons';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import HELPERS from '../../APIManager/helper';
// import logo from '../../Components/asset/sb_logo_circle.svg';
import logo from '../../Components/asset/sendbiz_logo.png';
import userDefaultPic from '../../Components/asset/user.svg';
import { withRouter } from 'react-router-dom';
import APIManager from '../../APIManager/index';
import { fileUrl } from '../../Shared/imageUrlPath';
import NavbarDrawer from './NavbarDrawer';

const profileComp = (props) => {
  let user_type = JSON.parse(localStorage.getItem('user_type') || false);
  const user_type2 = JSON.parse(localStorage.getItem('user_type2') || false);
  let type_of_user = HELPERS.isNumber(user_type);
  let type_of_user2 = HELPERS.isNumber(user_type2);
  if (type_of_user === 'number' && type_of_user2 === 'number') {
    user_type = user_type / (user_type2 * 99);
  } else {
    localStorage.clear();
  }
  return (
    <Menu>
      <Menu.Item className="dropdown-text">
        <NavLink
          to={
            user_type === 2 || user_type === 3
              ? '/edit/company/profile'
              : '/profile'
          }
        >
          Profile
        </NavLink>
      </Menu.Item>
      <Menu.Item
        className="dropdown-text"
        onClick={() => {
          localStorage.clear();
          window.location.href = '/';
        }}
      >
        <a>Logout</a>
      </Menu.Item>
    </Menu>
  );
};
const UserDropDown = (props) => {
  const displayPicture = props.displayPicture;
  const logo = props.logo;
  return (
    <>
      {props.loginStatus ? (
        <Dropdown
          overlay={profileComp(props)}
          placement="bottomLeft"
          trigger={['click']}
        >
          <div>
            <Avatar
              style={{ verticalAlign: 'text-top', cursor: 'pointer' }}
              icon={fileUrl.fileUrlPath + props.logo}
              src={
                props.logo ? fileUrl.fileUrlPath + props.logo : userDefaultPic
              }
            />
          </div>
        </Dropdown>
      ) : (
        <Link to="/login" className="navbar-display-none">
          <button className="btn btn-dark login-home-btn">Login/SignUp</button>
        </Link>
      )}
    </>
  );
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profilePic: '',
      loginStatus: false,
    };
  }
  componentDidMount() {
    var ls = require('local-storage');
    this.setState({ loginStatus: ls.get('token') ? true : false });
    HELPERS.getLatLong();
    APIManager.companyInfo().then((response) => {
      if (response.data.isSuccess) {
        this.setState({
          profilePic:
            response.data.data.user.user_type === 2
              ? response.data.data.data.logo.name
              : response.data.data.user.user_type === 3
              ? response.data.data.data.subuser.user_image
              : response.data.data.user.user_type === 1
              ? response.data.data.data.can_detail.profile
              : '',
        });
      }
    });
    APIManager.listChannnel('', '').then((response) => {});
  }
  handleLogout() {
    if (this.props.login === true) {
      localStorage.clear();
      window.location.reload(false);
    } else {
      var ls = require('local-storage');
      ls.clear();
      this.props.history.push('/');
    }
  }
  renderMailBox = () => {
    var ls = require('local-storage');
    const token = ls.get('token');
    if (token) {
      this.props.history.push('/company-mailbox');
    } else {
      this.props.history.push('/login');
    }
  };

  render() {
    var ls = require('local-storage');
    // const loginStatus = ls.get('token') ? true : false;
    const usertype = ls.get('user_type');
    const unseenCount = this.props.channelList
      ? this.props.channelList?.data?.map((data) => data.unseenMessageCount)
      : '';
    const totalunseenCount =
      unseenCount && unseenCount.reduce((a, b) => a + b, 0);

    // const displayPicture = this.props.data
    //   ? this.props.data.data.data.logo.path
    //   : '';
    // ls.set('displayPicture', displayPicture);
    const { pathname } = this.props.history.location;
    return (
      <>
        {/**************** navbar main container *****************/}
        <nav className="navbar navbar-expand-lg navbar-custom">
          {/****************  logo section ************************/}
          <div
            onClick={() => {
              this.props.history.push('/');
            }}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={logo}
              width="120px"
              height="50px"
              className="d-inline-block"
              alt="logo"
            />
            &nbsp; &nbsp;
            {/* <p
              style={{
                display: 'inline-block',
                fontSize: '1.5rem',
                fontWeight: '800',
                marginTop: '10px',
              }}
            >
              SENDBIZ
            </p> */}
          </div>
          {/****************  all routes section *************************/}
          <div className="navcomp">
            <form className="form-inline">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item active navbar-display-none">
                  <NavLink
                    to={'/CompanyList'}
                    className="nav-link"
                    href="#"
                    style={{
                      color:
                        pathname.slice(1, 12) === 'CompanyList'
                          ? '#EE5050'
                          : 'rgba(0, 0, 0, 0.9)',
                    }}
                  >
                    Companies
                  </NavLink>
                </li>
                <li className="nav-item navbar-display-none">
                  <NavLink
                    to={'/all_jobs'}
                    className={'nav-link'}
                    style={{
                      color:
                        pathname.slice(1, 9) === 'all_jobs'
                          ? '#EE5050'
                          : 'rgba(0, 0, 0, 0.9)',
                    }}
                  >
                    Jobs
                  </NavLink>
                </li>
                <li className="nav-item navbar-display-none">
                  <NavLink
                    to={'/products'}
                    className="nav-link"
                    style={{
                      color:
                        pathname.slice(1, 9) === 'products'
                          ? '#EE5050'
                          : 'rgba(0, 0, 0, 0.9)',
                    }}
                  >
                    Products
                  </NavLink>
                </li>
                <li className="nav-item navbar-display-none">
                  <NavLink
                    to={'/services'}
                    className="nav-link"
                    style={{
                      color:
                        pathname.slice(1, 9) === 'services'
                          ? '#EE5050'
                          : 'rgba(0, 0, 0, 0.9)',
                    }}
                  >
                    Services
                  </NavLink>
                </li>
                <li className="nav-item navbar-display-none">
                  <NavLink
                    to={'/news'}
                    className="nav-link"
                    style={{
                      color:
                        pathname.slice(1, 5) === 'news'
                          ? '#EE5050'
                          : 'rgba(0, 0, 0, 0.9)',
                    }}
                  >
                    News
                  </NavLink>
                </li>
                <li className="nav-item navbar-display-none">
                  <NavLink
                    to={'/events'}
                    className="nav-link"
                    style={{
                      color:
                        pathname.slice(1, 7) === 'events'
                          ? '#EE5050'
                          : 'rgba(0, 0, 0, 0.9)',
                    }}
                  >
                    Events
                  </NavLink>
                </li>
                <li className="nav-item navbar-display-none">
                  <MessageFilled
                    onClick={this.renderMailBox}
                    className="top-nav-icons"
                  />
                  <div className={'unseen-count-no'}>
                    {totalunseenCount > 0 ? totalunseenCount : ''}
                  </div>
                </li>
                {/* navbar drawer components */}

                <li
                  className="nav-item navbar-display-none-on-bigscreen"
                  style={{
                    marginRight: '5px',
                    paddingRight: '1px',
                  }}
                >
                  {/* Calling a navbar drawer on small screen size */}
                  <NavbarDrawer loginStatus={this.state.loginStatus} logo={this.state.profilePic && this.state.profilePic} />
                </li>
                <li className="nav-item" style={{ paddingRight: '0px' }}>
                  <UserDropDown
                    loginStatus={this.state.loginStatus}
                    logo={this.state.profilePic && this.state.profilePic}
                  />
                </li>
              </ul>
            </form>
          </div>
        </nav>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user,
    channelList: state.mailBoxReducer.channelList,
    data: state.companyInfoReducer.data,
  };
};
export default withRouter(connect(mapStateToProps)(Header));
