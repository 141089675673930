import { Card, Row, Col, Spin, Collapse } from 'antd';
import { NavLink } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import userDefaultPic from '../../asset/card.svg';
import React, { useEffect, useState } from 'react';
import mailIcon from '../../asset/mail.svg';
import { fileUrl } from '../../../Shared/imageUrlPath';
import moment from 'moment';
import APIManager from '../../../APIManager';
import { CURRENCY } from '../../constants/currency';
import styles from './offers_sent.module.css';
// import styles from './offersReceived.module.css';

const checkChannelExist = (id) => {
  APIManager.checkChannel(id).then((resp) => console.log(''));
};
const Offer_details = (props) => {
  useEffect(() => {
    setOffer(props.location.state.offer);
    APIManager.offerDetails(
      props.location.state.jobId,
      props.location.state.companyId
    )
      .then((res) => {
        setCandidates(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);
  const [loading, setLoading] = useState(true);
  const [offer, setOffer] = useState([]);
  const [candidates, setCandidates] = useState([]);
  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <>
          <Row style={{ justifyContent: 'center' }}>
            <Col
              xs={24}
              onClick={() => {
                props.history.goBack();
              }}
            >
              <LeftOutlined className="back-icon" />
              <span className="back-btn">Go back</span>
            </Col>
            <Col xs={24} className={styles.heading}>
              Applicants :{' '}
            </Col>
            <Card className={styles.card}>
              <Row gutter={15} className={styles.headCol}>
                <Col xs={6} sm={4} className={styles.imageCol}>
                  <img
                    src={
                       offer?.job?.addedByCompany[0]?.logo?.name !== ''
                        ? fileUrl.fileUrlPath +
                          offer?.job?.addedByCompany[0]?.logo?.name
                        : userDefaultPic
                    }
                    alt=""
                    className={styles.image}
                  />
                </Col>
                <Col xs={18} sm={20} className={styles.imageCol}>
                  <Col xs={24} >
                    <p className="resume-card-4">
                      {' '}
                      {offer?.job?.title?.charAt(0)?.toUpperCase() +
                        offer?.job?.title?.slice(1)}
                      <span
                        style={{ textAlign: 'center' }}
                        className={`resume-card-3 ${styles.appliedCount}`}
                      >
                        <img src={process.env.PUBLIC_URL + '/user.png'} />
                        <span className={styles.sideMargin}>{offer?.count}</span>
                      </span>
                    </p>
                  </Col>
                  <Col xs={24} >
                    <NavLink
                      to={`comp-id${offer?.job?.addedByCompany[0]?._id}`}
                      onClick={(e) => e.stopPropagation()}
                      // target="_blank"
                      className="job-card-4 comp-hover visit_cand_personal_11"
                      style={{
                        textDecoration: 'underline',
                        fontSize: 'initial',
                      }}
                    >
                      {offer?.job?.addedByCompany[0]?.comp_info?.bus_name
                        ? offer?.job?.addedByCompany[0]?.comp_info?.bus_name
                            ?.charAt(0)
                            ?.toUpperCase() +
                          offer?.job?.addedByCompany[0]?.comp_info?.bus_name?.slice(
                            1
                          )
                        : offer?.job?.addedByCompany[0]?.comp_info?.comp_name
                            ?.charAt(0)
                            ?.toUpperCase() +
                          offer?.job?.addedByCompany[0]?.comp_info?.comp_name?.slice(
                            1
                          )}
                    </NavLink>
                  </Col>
                  <Col xs={24} >
                    <p className="job-card-5" style={{ padding: '0' }}>
                      <img src={process.env.PUBLIC_URL + '/location-red.png'} />
                      <span className={styles.sideMargin}>
                        {`${
                          offer.job.addedByCompany[0].contact_info.city !== ''
                            ? offer.job.addedByCompany[0].contact_info.city +
                              ', '
                            : ''
                        }`}
                        {`${
                          offer.job.addedByCompany[0].contact_info.state !== ''
                            ? offer.job.addedByCompany[0].contact_info.state +
                              ', '
                            : ''
                        }`}
                        {`${
                          offer.job.addedByCompany[0].contact_info.country !==
                          ''
                            ? offer.job.addedByCompany[0].contact_info.country
                            : ''
                        }`}
                      </span>
                    </p>
                  </Col>
                  <Col xs={24} >
                      <span>
                        <img src={process.env.PUBLIC_URL + '/clock-red.png'} />
                      </span>
                      <span className={styles.date}>
                        {moment(offer.appliedAt ? offer.appliedAt : '').format(
                          'DD MMM YY'
                        )}
                      </span>
                  </Col>
                </Col>
              </Row>
            </Card>
            <Col xs={24} className={styles.heading}>
              {candidates.length}{' '}
              {candidates.length > 1 ? 'Candidates' : 'Candidate'}
            </Col>
            {candidates?.map(
              ({
                candidate,
                applied_at,
                status,
                job_title,
                applied_by_company_id,
                companyData,
                appliedByCompany,
              }) => (
                <Card className={styles.card}>
                  <Row gutter={15}>
                    <Col xs={5} sm={4}>
                      <img
                        alt="abc"
                        src={
                          appliedByCompany?.logo?.name
                            ? `https://sendbizbucket.s3.eu-west-3.amazonaws.com/${appliedByCompany?.logo?.name}`
                            : '/company_logo.png'
                        }
                        className={styles.image}
                      />
                    </Col>
                    <Col xs={14} sm={16}>
                      <Row>
                        <Col xs={24} sm={12}>
                          <h4
                            style={{
                              textTransform: 'capitalize',
                              fontWeight: '700',
                            }}
                          >
                            {candidate.name}
                          </h4>
                        </Col>
                        <Col xs={24} sm={12}>
                          <span className={styles.titleCollapse}>Cost:</span>
                          <span className={styles.price}>
                            {CURRENCY.filter(
                              (data) => data.name === candidate?.currency
                            ).length > 0 &&
                              CURRENCY.filter(
                                (data) => data.name === candidate?.currency
                              )[0].symbol}
                          </span>
                          <span className={styles.price}>
                            {candidate.price}
                          </span>
                          <span className={styles.price}>
                            {candidate.jobPayment || ' '}
                          </span>
                        </Col>
                        <Col xs={24} sm={8}>
                          <span className={styles.titleCollapse}>
                            Availability :{' '}
                          </span>
                          <span className={styles.titleValue}>
                            {candidate.availability}
                          </span>
                        </Col>
                        <Col xs={24} sm={8}>
                          <span className={styles.titleCollapse}>
                            Experience :{' '}
                          </span>
                          <span className={styles.titleValue}>
                            {candidate.experience} Years
                          </span>
                        </Col>
                        <Col xs={24} sm={8}>
                          <span className={styles.titleCollapse}>
                            Current position :{' '}
                          </span>
                          <span className={styles.titleValue}>
                            {candidate.current_position}
                          </span>
                        </Col>
                        <Col xs={0} sm={8} style={{ cursor: 'pointer' }}>
                          <a
                            target="_blank"
                            href={`${fileUrl.fileUrlPath}${candidate.resume}`}
                            style={{
                              textDecoration: 'none',
                            }}
                          >
                            <span>
                              <img
                                src="./link.svg"
                                className={styles.resumeImg}
                              />
                            </span>
                            <span className={styles.resume}>
                              View Resume PDF
                            </span>
                          </a>
                        </Col>
                        <Col xs={0} sm={8}>
                          <span className={styles.titleCollapse}>
                            Applied on:
                          </span>
                          <span className={styles.titleValue}>
                            {moment(applied_at).format('DD MMM YYYY')}
                          </span>
                        </Col>
                        <Col xs={0} sm={8} style={{ cursor: 'pointer' }}>
                          {candidate.documents !== '' ? (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`${fileUrl.fileUrlPath}${candidate.documents}`}
                              style={{
                                textDecoration: 'none',
                                paddingLeft: '0',
                              }}
                            >
                              <span>
                                <img
                                  src="./link.svg"
                                  className={styles.resumeImg}
                                  alt=""
                                />
                              </span>
                              <span className={styles.resume}>
                                View Document PDF
                              </span>
                            </a>
                          ) : (
                            <>
                              <span>
                                <img
                                  src="./link.svg"
                                  style={{ width: 14, marginLeft: '-2px' }}
                                  alt=""
                                />
                              </span>
                              <span
                                style={{
                                  paddingLeft: '8px',
                                  verticalAlign: 'middle',
                                  color: 'red',
                                  fontWeight: 'bold',
                                }}
                              >
                                View Document PDF
                              </span>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={5} sm={4}>
                      <img
                        src={process.env.PUBLIC_URL + '/' + status + '.png'}
                        className={styles.image1}
                        style={{ marginTop: '-7px' }}
                      />
                      <p className="application_status_card w-100">
                        Job status
                      </p>
                    </Col>
                    <Row style={{ marginTop: '5px' }}>
                      <Col
                        xs={12}
                        sm={0}
                        className={styles.titleCollapseApplied}
                      >
                        <span className={styles.titleCollapse}>
                          Applied on:
                        </span>
                        <span className={styles.titleValue}>
                          {moment(applied_at).format('DD MMM YYYY')}
                        </span>
                      </Col>
                      <Col xs={12} sm={0}></Col>
                      <Col xs={2} sm={0}></Col>
                      <Col xs={10} sm={0} style={{ cursor: 'pointer' }}>
                        <a
                          target="_blank"
                          href={`${fileUrl.fileUrlPath}${candidate.resume}`}
                          style={{
                            textDecoration: 'none',
                          }}
                        >
                          <span>
                            <img
                              src="./link.svg"
                              className={styles.resumeImg}
                            />
                          </span>
                          <span className={styles.resume}>View Resume PDF</span>
                        </a>
                      </Col>
                      <Col xs={12} sm={0} style={{ cursor: 'pointer' }}>
                        {candidate.documents !== '' ? (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`${fileUrl.fileUrlPath}${candidate.documents}`}
                            style={{
                              textDecoration: 'none',
                              paddingLeft: '0',
                            }}
                          >
                            <span>
                              <img
                                src="./link.svg"
                                className={styles.resumeImg}
                                alt=""
                              />
                            </span>
                            <span className={styles.resume}>
                              View Document PDF
                            </span>
                          </a>
                        ) : (
                          <>
                            <span>
                              <img
                                src="./link.svg"
                                className={styles.resumeImg}
                                alt=""
                              />
                            </span>
                            <span className={styles.resume}>
                              View Document PDF
                            </span>
                          </>
                        )}
                      </Col>
                    </Row>
                    </Row>

                    <div
                      className="colapsable"
                      style={{ marginTop: '15px'}}
                    >
                      <Collapse>
                        <Collapse.Panel header="More Info">
                          <Row>
                            <Col xs={6}>
                              <span className={styles.titleCollapse}>
                                Sent By :{' '}
                              </span>
                            </Col>
                            <Col xs={17} sm={18}>
                              <span className={styles.titleCollapseValue}>
                                {appliedByCompany?.comp_info?.first_name}&nbsp;
                                {appliedByCompany?.comp_info?.last_name}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <span className={styles.titleCollapse}>
                                Location :{' '}
                              </span>
                            </Col>
                            <Col xs={18} sm={12}>
                              <span className={styles.titleCollapseValue}>
                                {candidate?.location}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <span className={styles.titleCollapse}>
                                Information :{' '}
                              </span>
                            </Col>
                            <Col xs={18}>
                              <span className={styles.titleCollapseValue}>
                                {candidate?.information}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <p
                                className="visit_cand_personal_10"
                                style={{
                                  color: 'black',
                                  marginTop: '11px',
                                  marginBottom: '10px',
                                  fontFamily: 'Gilroy Bold',
                                }}
                              >
                                CONTACT{' '}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <span className={styles.titleCollapse}>
                                Company :
                              </span>
                            </Col>
                            <Col xs={18}>
                           
                              <NavLink
                                to={`comp-id${companyData._id}`}
                                onClick={(e) => e.stopPropagation()}
                                target="_blank"
                                className="job-card-4 comp-hover visit_cand_personal_11"
                                style={{
                                  textDecoration: 'underline',
                                  fontSize: 'initial',
                                }}
                              >
                                
                                {companyData?.comp_info?.bus_name
                                  ? companyData?.comp_info?.bus_name
                                  : companyData?.comp_info?.comp_name}
                              </NavLink>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <span className={styles.titleCollapse}>
                                Username :{' '}
                              </span>
                            </Col>
                            <Col xs={18} sm={18}>
                              <span className={styles.titleCollapseValue}>
                                {companyData?.comp_info?.first_name}&nbsp;
                                {companyData?.comp_info?.last_name}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <span className={styles.titleCollapse}>
                                User Job Title :{' '}
                              </span>
                            </Col>
                            <Col xs={15} md={16}>
                              <span className={styles.titleCollapseValue}>
                                {companyData.comp_info.job_profile}
                              </span>
                            </Col>
                            <Col xs={0} md={2}>
                              <div
                                // className={'d-flex justify-content-end'}
                                onClick={() => {
                                  var ls = require('local-storage');
                                  ls.set('chatUserId', applied_by_company_id);
                                  checkChannelExist(applied_by_company_id);
                                  props.history.push({
                                    pathname: '/company-mailbox',
                                    userInfo: Object.assign(companyData, {
                                      profile:
                                        'candidate/profile/CandidateProfile-61d5a0396a340327b364fa4d-1649162059259',
                                    }),
                                    canId: companyData.comp_id,
                                  });
                                }}
                              >
                                <img
                                  src={mailIcon}
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: '7px'
                                  }}
                                  alt=""
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <span className={styles.titleCollapse}>
                                Email :{' '}
                              </span>
                            </Col>
                            <Col xs={15} md={16}>
                              <span className={styles.titleCollapseValue}>
                                {candidate?.email}
                              </span>
                            </Col>
                            <Col xs={0} md={2}>
                              <a href={`mailto:${candidate?.email}`}>
                                <img
                                  src="/telegram.png"
                                  alt=""
                                />
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6}>
                              <span className={styles.titleCollapse}>
                                Phone Number :{' '}
                              </span>
                            </Col>
                            <Col xs={15} md={18}>
                              <span className={styles.titleCollapseValue}>
                                {companyData?.contact_info?.phone_no ? companyData?.contact_info?.phone_no : '-'}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={17} />
                            <Col xs={1} md={0}>
                              <div
                                className={'d-flex justify-content-end'}
                                onClick={() => {
                                  var ls = require('local-storage');
                                  ls.set('chatUserId', applied_by_company_id);
                                  checkChannelExist(applied_by_company_id);
                                  props.history.push({
                                    pathname: '/company-mailbox',
                                    userInfo: Object.assign(companyData, {
                                      profile:
                                        'candidate/profile/CandidateProfile-61d5a0396a340327b364fa4d-1649162059259',
                                    }),
                                    canId: companyData.comp_id,
                                  });
                                }}
                              >
                                <img
                                  src={mailIcon}
                                  className="  service-icons"
                                  style={{
                                    cursor: 'pointer',
                                    marginBottom: '10px',
                                    marginRight: '-44px',
                                  }}
                                  alt=""
                                />
                              </div>
                            </Col>
                            <Col xs={2} md={0} style={{ marginLeft: '40px' }}>
                              <a href={`mailto:${candidate?.email}`}>
                                <img src="/telegram.png" alt="" />
                              </a>
                            </Col>
                          </Row>
                        </Collapse.Panel>
                      </Collapse>
                    </div>
                </Card>
              )
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default Offer_details;
