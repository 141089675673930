import React, { Component } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  AutoComplete,
  Button,
} from 'antd';
import axios from 'axios';
import { SearchOutlined } from '@ant-design/icons';
import Card from './cardForJob';
import { GoogleApiWrapper } from 'google-maps-react';
import './topNav.css';
import './screenOfTopNav.css';
import styles from './screen14.module.css';
import Footer from '../footer/footer';
import { connect } from 'react-redux';
import { Spin, Form } from 'antd';
import Header from '../header/header';
import { RootUrl } from '../../Shared/imageUrlPath';
import HeaderLocation from '../headerLocation/headerLocation';
import SelectSector from './selectSector';
import CustomCompanyMap from '../app_status/company_map';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const { Option } = Select;

let cancel;
class Job extends Component {
  autoCompleteRef = React.createRef();
  filterRef = React.createRef();

  constructor(props) {
    super(props);
    this.jobTypeElement = React.createRef();
    this.state = {
      searchingValue: '',
      job_type: '',
      posted_on: '',
      experience: '',
      current_location: '',
      map_location: '',
      lat: '',
      long: '',
      options: [],
      sector: [],
      searchLocation: "false",
      firstTimeRender: true,
      lengthSelectedSectors: 0,
      homeSearch: "false",
      user_type: null,
    };
  }

  async componentDidMount() {
    if(this.props.location.search.includes('job_type=subcontractor'))
    this.setState({job_type: 'subcontractor'});
    // set dynamic tags
    // SeoMetaTags({title: 'Job Listing', description: 'Sendbiz | Job Listing'})
    let user_type1 = JSON.parse(localStorage.getItem('user_type') || false);
    const user_type2 = JSON.parse(localStorage.getItem('user_type2') || false);
    // user_type = user_type / (user_type2 * 99);
    this.setState({ user_type: user_type1 / (user_type2 * 99) });
    if (
      this.props.location.state !== null &&
      this.props.location.state !== undefined
    ) {
      this.autoCompleteRef.current.setFieldsValue({
        searchingValue: this.props?.location?.state?.job_name,
        location: this.props?.location?.state?.location,
      });
      this.setState({
        searchingValue: this.props?.location?.state?.job_name,
        current_location: this.props?.location?.state?.location,
      });
      if(this.props.location.state.homeSearch==="true"){
        this.setState({
          homeSearch: "true"
        });
      }
    }
    await this.getLocationName(
      this?.props?.location?.state?.lat,
      this?.props?.location?.state?.long
    );
  }
  handleSectorChange = (value) => {
    this.setState({
      sector: value,
      firstTimeRender: false,
    });
  };
  handleCurrentLocationChange = (value) => {
    const { latitude, longitude } = value;
    this.takeLatLong(latitude, longitude);
  };
  //handle searching value
  handleChange = (value) => {
    if (value.length === 0) {
      return this.setState({ firstTimeRender: true, searchingValue: '' });
    }
    this.setState({
      searchingValue: value,
      firstTimeRender: false,
    });
  };
  handlePressEnter = (event) => {
    this.setState({
      searchingValue: event.target.value,
      firstTimeRender: false,
    });
  };
  handleSearch = (value) => {
    let search = value.toLowerCase();
    this.search(search);
  };
  getLocationName = async (lat, long) => {
    var ls = require('local-storage');
    const localLat = lat ? lat : ls.get('lat');
    const localLong = long ? long : ls.get('long');
    const { google } = this.props;
    if (localLat && localLong) {
      var geocoder;
      geocoder = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(localLat, localLong);
      var country = '';
      var state = '';
      var city = '';
      await geocoder.geocode(
        { latLng: latlng },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              var add = results[0].formatted_address;
              var value = add.split(',');
              const count = value.length;
              country = value[count - 1].replace(/[0-9]/g, '').trim(' ');
              state = value[count - 2].replace(/[0-9]/g, '').trim(' ');
              city = value[count - 3].replace(/[0-9]/g, '').trim(' ');
              this.setState({
                lat: localLat,
                long: localLong,
                current_location: `${city},${state},${country}`,
              });
            } else {
              console.log('address not found');
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        }.bind(this)
      );
    } else {
      this.setState({
        current_location: 'Paris, France',
        lat: '48.8566',
        long: '2.3522',
      });
    }
  };

  search = async (search) => {
    const CancelToken = axios.CancelToken;
    if (search.length === 0) {
      return this.handleChange('');
    }
    if (cancel) {
      cancel(); // cancel request
    }
    const { data } = await axios.get(
      `${
        RootUrl.basePath
      }jobs/with-sponsored?limit=10&page=${1}&search=${search}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      }
    );
    let options = [];
    data.data.forEach((data) => {
      options.push({
        label: data ? data.title.toLowerCase() : '',
        value: data ? data.title : '',
        key: data._id,
      });
    });
    this.setState({ options, firstTimeRender: false });
  };

  handleJobType = (e) => {
    this.setState({
      job_type: e.join(','),
      firstTimeRender: false,
      lengthSelectedSectors: e.length,
    });
    {this.props.location.search.includes('job_type=subcontractor') && e?.map((e)=> e === "subcontractor") &&
    this.props.history.push({
      pathname: '/all_jobs',
    })}
  };
  handleSectorSort = (e) => {
    this.setState({
      sector: e,
    });
  };
  handlePostedOn = (date, dateString) => {
    this.setState({
      posted_on: dateString,
      firstTimeRender: false,
    });
  };
  handleExperience = (e) => {
    this.setState({
      experience: e,
      firstTimeRender: false,
    });
  };
  takecurrentLocation = (value) => {
    this.setState({
      current_location: value,
      map_location: '',
      firstTimeRender: false,
      searchLocation: "true",
    });
  };
  takeLatLong = (lat, long) => {
    this.setState({
      lat: lat,
      long: long,
      firstTimeRender: false,
    });
  };
  handleFilter = async () => {
    this.autoCompleteRef.current.resetFields();
    this.filterRef.current.resetFields();
    this.setState({
      searchingValue: '',
      posted_on: '',
      experience: '',
      sector: '',
      job_type: '',
      // current_location: "",
      firstTimeRender: true,
    });
    await this.getLocationName();
    window.history.replaceState('/all_jobs', null);
  };
  handleMapLocationChange = (contact_data, id) => {
    this.takeLatLong(contact_data?.latitude, contact_data?.longitude);
    this.setState({ map_location: contact_data?.sub_address }, function () {
      this.openCompanyCardOnMap(id);
    });
  };

  openCompanyCardOnMap = (id) => {
    setTimeout(() => {
      const mapCard = document.querySelectorAll(`[title="${id}"]`)?.[0];
      if (mapCard) {
        mapCard.click();
      }
    }, 1000);
  };
  render() {
    let { lengthSelectedSectors } = this.state;
    const isLoading = this.props.isloading ? this.props.isloading : '';
    let job_markers = [];
    var min = 0.999999;
    var max = 1.000001;
    if (this.props.jobdata !== null && this.props.jobdata !== undefined) {
      // this.props.jobdata.data.forEach((x) => x.companyDetail[0]?.contact_info?.latitude = x.companyDetail[0]?.contact_info?.latitude * (Math.random() * (max - min) + min));
      job_markers = this.props.jobdata.data;
      for (let i = 0; i < job_markers.length; i++) {
        if(job_markers[i].companyDetail.length > 0){
        job_markers[i].companyDetail[0].contact_info = {
          ...job_markers[i]?.companyDetail?.[0]?.contact_info,
          latitude:
            job_markers[i]?.companyDetail?.[0]?.contact_info?.latitude *
            (Math.random() * (max - min) + min),
          longitude:
            job_markers[i]?.companyDetail?.[0]?.contact_info?.longitude *
            (Math.random() * (max - min) + min),
        };
      }     
    }
    }

    return (
      <>
        <Helmet>
          <title>
            Senbiz. Promote your employer brand and attract the right talent-
            Sendbiz{' '}
          </title>
          <meta
            property="og:title"
            content="Senbiz. Promote your employer brand and attract the right talent- Sendbiz "
          />
          <meta
            name="twitter:card"
            content="Senbiz. Promote your employer brand and attract the right talent- Sendbiz "
          />

          <meta
            name="description"
            content="Sendbiz is your source for jobs and career, find your next job, contract. whether you are a candidate or a freelancer or a company find the jobs and missions that suit you ."
          />
          <meta
            property="og:description"
            content="Sendbiz is your source for jobs and career, find your next job, contract. whether you are a candidate or a freelancer or a company find the jobs and missions that suit you ."
          />
          <meta
            name="twitter:description"
            content="Sendbiz is your source for jobs and career, find your next job, contract. whether you are a candidate or a freelancer or a company find the jobs and missions that suit you ."
          />
        </Helmet>
        <Header />
        <div>
          {/* Header search bar */}
          <Row className={styles.jobpageHeader}>
            <Col span={24}>
              <div className="job-top-nav-bar pl-0 pr-0">
                <Form ref={this.autoCompleteRef}>
                  <Row className="s-search-bar" justify="space-between">
                    <Col md={9} xs={24}>
                      <Row align="middle">
                        <Col xs={24} md={10}>
                          <Form.Item name="searchingValue">
                            <AutoComplete
                              name="search"
                              options={this.state.options}
                              onSelect={this.handleChange}
                              onChange={this.handleChange}
                              onSearch={this.handleSearch}
                              className="header-input-fields"
                            >
                              <Input
                                className="ls-outline "
                                onPressEnter={this.handlePressEnter}
                                prefix={
                                  <SearchOutlined className="landing-icons-2" />
                                }
                                placeholder="Search for a job title"
                              />
                            </AutoComplete>
                          </Form.Item>
                        </Col>
                        <Col xs={0} md={1}></Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            name="location"
                            className={styles.locationInput}
                          >
                            <HeaderLocation
                              envclass="landing-icons landing-select-icon2"
                              className={`company-joblist-input jobListingcls-1 padding-in-job-search ${styles.locationInput}`}
                              value={this.state?.current_location}
                              takecurrentLocation={this.takecurrentLocation}
                              takeLatLong={this.takeLatLong}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={15} xs={24}>
                      <Form ref={this.filterRef}>
                        <Row gutter={12} justify="end">
                          <Col lg={6} md={10} xs={24}>
                            <Form.Item name="sector">
                              <SelectSector
                                handleSectorChange={this.handleSectorChange}
                                value={this.state.sector}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={5} md={5} xs={24}>
                            {/* need to improve code writen for this job_type select */}
                            <Form.Item name="type">
                            {this.props.location?.search?.includes('job_type=subcontractor') ?  
                            <Select
                                defaultValue = {"subcontractor"}
                                mode="multiple"
                                maxTagCount={lengthSelectedSectors > 1 ? 0 : 1}
                                maxTagTextLength={10}
                                dropdownStyle={{}}
                                placeholder="Type"
                                name="type"
                                style={{ float: 'right' }}
                                className="s-search-select"
                                onChange={this.handleJobType}
                              >
                                {this.state.user_type !== 1 && (
                                  <Option value="subcontractor">
                                    Subcontractor
                                  </Option>
                                )}
                                <Option value="permanent">Permanent</Option>
                                <Option value="temporary">Temporary</Option>
                                <Option value="commission">Commission</Option>
                                <Option value="apprenticeship">
                                  Apprenticeship
                                </Option>
                                <Option value="internship">Internship</Option>
                                <Option value="volunteer">Volunteer</Option>
                                <Option value="full time">Full Time</Option>
                              </Select> 
                              :
                               <Select
                               mode="multiple"
                               maxTagCount={lengthSelectedSectors > 1 ? 0 : 1}
                               maxTagTextLength={10}
                               dropdownStyle={{}}
                               placeholder="Type"
                               name="type"
                               style={{ float: 'right' }}
                               className="s-search-select"
                               onChange={this.handleJobType}
                             >
                               {this.state.user_type !== 1 && (
                                 <Option value="subcontractor">
                                   Subcontractor
                                 </Option>
                               )}
                               <Option value="permanent">Permanent</Option>
                               <Option value="temporary">Temporary</Option>
                               <Option value="commission">Commission</Option>
                               <Option value="apprenticeship">
                                 Apprenticeship
                               </Option>
                               <Option value="internship">Internship</Option>
                               <Option value="volunteer">Volunteer</Option>
                               <Option value="full time">Full Time</Option>
                             </Select>
                             }
                            </Form.Item>
                          </Col>
                          <Col lg={5} md={5} xs={24}>
                            <Form.Item name="posted_on">
                              <DatePicker
                                onChange={this.handlePostedOn}
                                name="posted_on"
                                placeholder="Posted On"
                                className="s-search-select padding-in-job-datepicker"
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={5} md={5} xs={24}>
                            <Form.Item name="experience">
                              <Select
                                placeholder="Experience"
                                style={{ float: 'right' }}
                                className="s-search-select"
                                name="experience"
                                onChange={this.handleExperience}
                              >
                                <Option value="Not Applicable">
                                  Not Applicable
                                </Option>
                                <Option value="Internship">Internship</Option>
                                <Option value="Apprenticeship">
                                  Apprenticeship
                                </Option>
                                <Option value="Entry level">Entry level</Option>
                                <Option value="Associate">Associate</Option>
                                <Option value="Mid-Senior level">
                                  {' '}
                                  Mid-Senior level{' '}
                                </Option>
                                <Option value="Director">Director</Option>
                                <Option value="Executive">Executive</Option>
                                <Option value="Expert">Expert</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col lg={3} md={6} xs={24} className="filter-button">
                            <Button
                              type="text"
                              onClick={this.handleFilter}
                              className={styles.filterButton}
                            >
                              Clear All Filters
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
          {/* second row of this page */}
          <Row className={styles.jobpageSecondRow}>
            {/* jobs cards */}
            <Col md={12} xs={24} className={styles.jobCardSection}>
              {/* loader */}
              {isLoading && (
                <div className="d-flex justify-content-center">
                  <Spin />
                </div>
              )}
              <Card
                searchingValue={this.state?.searchingValue}
                job_type={this.state?.job_type}
                sector={this.state?.sector}
                experience={this.state?.experience}
                posted_on={this.state?.posted_on}
                ref={this.jobTypeElement}
                homeSearch={this.state?.homeSearch}
                searchLocation={this.state?.searchLocation}
                current_location={this.state?.current_location}
                firstTimeRender={this.state?.firstTimeRender}
                comp_id={this.props?.location?.comp_id}
                handleCurrentLocationChange={this.handleCurrentLocationChange}
                changeMapLocation={this.handleMapLocationChange}
              />
            </Col>
            {/* map section */}
            <Col md={12} xs={24} className={styles.mapCol}>
              <CustomCompanyMap
                className={styles.jobpageLocationBox}
                renderfrom="jobs"
                markers={job_markers}
                current_location={this.state?.current_location}
                map_location={this.state?.map_location}
                lat={this.state?.lat}
                long={this.state?.long}
                jobLocation={this.state?.current_location}
              />
            </Col>
          </Row>
          <Footer />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    jobdata: state.jobManagementReducer.jobdata,
    isloading: state.jobManagementReducer.isloading,
  };
};
  export default withRouter(
  GoogleApiWrapper({
    apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
  })(connect(mapStateToProps)(Job))
);
