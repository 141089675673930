import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Row, Col, Collapse } from 'antd';
import CoverImage from '../../asset/card.svg';
import { connect } from 'react-redux';
import moment from 'moment';
import { CURRENCY } from '../../constants/currency';
import { fileUrl } from '../../../Shared/imageUrlPath';
import CompanyIcon from '../../asset/Company-icon.png';
import styles from '../../candidate/visit_profile/visitProfile.module.css';

const Panel = Collapse.Panel;
class CompanyMedia extends Component {
  state = {
    isOpen: false,
    isActive: [],
  };

  changeOpen = (index) => {
    var isActive = this.state.isActive;
    if (isActive.includes(index)) {
      const inn = isActive.indexOf(index);
      isActive.splice(inn, 1);
    } else {
      isActive.push(index);
    }
    this.setState({
      isActive,
    });
  };

  render() {
    const candidateData = this.props.appliedJobData
      ? this.props.appliedJobData.data.candidateData.can_experience
      : '';
    const lastSalary = this.props.appliedJobData
      ? this.props.appliedJobData.data.candidateData.can_salary.lastSalary
      : '';
    const minSalary = this.props.appliedJobData
      ? this.props.appliedJobData.data.candidateData.can_salary.minSalary
      : '';
    const maxSalary = this.props.appliedJobData
      ? this.props.appliedJobData.data.candidateData.can_salary.maxSalary
      : '';
    const reciveFormat = this.props.appliedJobData
      ? this.props.appliedJobData.data.candidateData.can_salary.recieveFormat
      : '';

    return (
      <div style={{ backgroundColor: 'white', paddingBottom: 20 }}>
        {/* Current Company */}
        {candidateData.length > 0 && (
          <Row style={{ paddingTop: 35 }}>
            <Col span={24}>
              <p className="visit_cand_personal_1">Current Position</p>
            </Col>
          </Row>
        )}

        {candidateData &&
          candidateData
            ?.sort((a, b) => (a.from > b.from ? -1 : 1))
            ?.map((data, index) => (
              <>
                {data.isCurrentlyWorking ? (
                  <>
                    <p></p>
                    <Row
                      gutter={{ xs: 24, sm: 24, md: 8, lg: 0 }}
                      className="visit_cand_personal_2"
                      style={{ paddingTop: 10, paddingBottom: 10 }}
                    >
                      <Col span={4} style={{ paddingLeft: 29 }}>
                        <img
                          src={
                            data.experience_image
                              ? fileUrl.fileUrlPath + data.experience_image
                              : CoverImage
                          }
                          alt='coverImg'
                        />
                      </Col>
                      <Col span={20}>
                        <Row>
                          <Col span={24}>
                            <p className="visit_cand_personal_3">
                              {data.title}
                            </p>
                          </Col>
                          <Col span={24}>
                            <p className="visit_cand_personal_4">
                              {`from ${moment(data?.from).format(
                                'MMM YYYY'
                              )} to Present`}
                            </p>
                          </Col>
                          <Col span={24}>
                            <p className="visit_cand_personal_4">
                              {data.company}
                            </p>
                          </Col>
                          <Col span={24}>
                            <p className="visit_cand_personal_5">
                              {data.location}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className={`colapsable ${styles.closableDivs}`}>
                      <Collapse
                        defaultActiveKey={this.state.isOpen}
                        onChange={() => {
                          this.changeOpen(index);
                        }}
                      >
                        <Panel
                          className={`${styles.closableSubDivs} ${styles.closableSubDivsParents}`}
                          header={
                            this.state.isActive.includes(index)
                              ? 'Hide'
                              : 'View More'
                          }
                          key="1"
                        >
                          <Row
                            className={`visit_cand_personal_9 ${styles.closableSubDivs}`}
                          >
                            <Col span={12}>
                              <p className="visit_cand_personal_10">Title</p>
                            </Col>
                            <Col span={12}>
                              <p className="visit_cand_personal_10">
                                Employment type
                              </p>
                            </Col>
                          </Row>
                          <Row className={styles.closableSubDivs}>
                            <Col span={12}>
                              <p
                                className={`visit_cand_personal_11 ${styles.closableDivsTexts}`}
                              >
                                {data.title}
                              </p>
                            </Col>
                            <Col span={12}>
                              <p
                                className={`visit_cand_personal_11 ${styles.closableDivsTexts}`}
                              >
                                {/* {employeeType} */}
                                {data.employmentType}
                              </p>
                            </Col>
                          </Row>
                          <Row
                            className={`visit_cand_personal_9 ${styles.closableSubDivs}`}
                          >
                            <Col span={24}>
                              <p className="visit_cand_personal_10">
                                Description
                              </p>
                            </Col>
                          </Row>
                          <Row className={styles.closableSubDivs}>
                            <Col span={24}>
                              <p
                                className={`visit_cand_personal_11 ${styles.closableDivsTexts}`}
                              >
                                {/* {currentPosition} */}
                                {data.description}
                              </p>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>
            ))}

        {/* Past Experiences */}
        {candidateData.length > 0 && (
          <Row>
            <Col span={24} style={{ marginTop: '30px' }}>
              <p className="visit_cand_personal_6">Professional Experience</p>
            </Col>
          </Row>
        )}
        {candidateData &&
          candidateData
            ?.sort((a, b) => (a.from > b.from ? -1 : 1))
            ?.map((data, index) => (
              <>
                {!data.isCurrentlyWorking ? (
                  <>
                    <p></p>
                    <Row
                      gutter={{ xs: 24, sm: 24, md: 8, lg: 0 }}
                      className="visit_cand_personal_7"
                    >
                      <Col
                        span={4}
                        style={{ paddingLeft: 27, paddingBottom: 25 }}
                      >
                        <img
                          src={
                            data.experience_image
                              ? fileUrl.fileUrlPath + data.experience_image
                              : CompanyIcon
                          }
                          alt='companyIcon'
                          className="visit_cand_personal_8"
                        />
                      </Col>
                      <Col span={18}>
                        <Row>
                          <Col span={24}>
                            <p className="visit_cand_personal_3">
                              {data.title} {data.company}
                            </p>
                          </Col>
                          <Col span={24}>
                            <p className="visit_cand_personal_4">
                              {`from ${moment(data.from).format(
                                'MMM YYYY'
                              )} to ${moment(data.to).format('MMM YYYY')}`}
                            </p>
                          </Col>
                          <Col span={24}>
                            <p className="visit_cand_personal_5">
                              {data.location}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <div className="colapsable">
                      <Collapse
                        defaultActiveKey={this.state.isOpen}
                        onChange={() => this.changeOpen(index)}
                      >
                        <Panel
                          // header={this.state.isOpen ? 'Hide' : 'View More'}
                          header={
                            this.state.isActive.includes(index)
                              ? 'Hide'
                              : 'View More'
                          }
                          key="1"
                        >
                          <Row className="visit_cand_personal_9">
                            <Col span={12}>
                              <p className="visit_cand_personal_10">Title</p>
                            </Col>
                            <Col span={12}>
                              <p className="visit_cand_personal_10">
                                Employment type
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <p className="visit_cand_personal_11">
                                {data.title}
                              </p>
                            </Col>
                            <Col span={12}>
                              <p className="visit_cand_personal_11">
                                {data.employmentType}
                              </p>
                            </Col>
                          </Row>
                          <Row className="visit_cand_personal_9">
                            <Col span={24}>
                              <p className="visit_cand_personal_10">
                                Description
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <p className="visit_cand_personal_11">
                                {data.description}
                              </p>
                            </Col>
                            {/* <Col span={24}><p style={{color:'red',font: "normal normal 400 13px/23px Gilroy semiBold",textAlign:"end",cursor:'pointer'}} onClick={this.changeOpen}>Hide</p></Col> */}
                          </Row>
                        </Panel>
                      </Collapse>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>
            ))}

        <div>
          <Row>
            <Col span={24}>
              <p className="visit_cand_personal_13">Salary</p>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <p className="visit_cand_personal_14">Minimum Salary</p>
            </Col>

            <Col span={5}>
              <p className="visit_cand_personal_14">Maximum Salary</p>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <p className="visit_cand_personal_15">
                {CURRENCY.filter((values) => values.name === minSalary.currency)
                  .length > 0 &&
                  CURRENCY.filter(
                    (values) => values.name === minSalary.currency
                  )[0].symbol}
                &nbsp;&nbsp;
                {`${minSalary.amount} ${reciveFormat}`}
              </p>
            </Col>
            <Col span={5}>
              <p className="visit_cand_personal_15">
                {CURRENCY.filter((values) => values.name === maxSalary.currency)
                  .length > 0 &&
                  CURRENCY.filter(
                    (values) => values.name === maxSalary.currency
                  )[0].symbol}
                &nbsp;&nbsp;
                {`${maxSalary.amount} ${reciveFormat}`}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <p className="visit_cand_personal_14">Last Salary</p>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <p className="visit_cand_personal_15">
                {CURRENCY.filter(
                  (values) => values.name === lastSalary.currency
                ).length > 0 &&
                  CURRENCY.filter(
                    (values) => values.name === lastSalary.currency
                  )[0].symbol}
                &nbsp;&nbsp;
                {`${lastSalary.amount} ${reciveFormat}`}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    appliedJobData: state.jobManagementReducer.appliedJobData,
    isloading: state.jobManagementReducer.isloading,
  };
};
export default connect(mapStateToProps)(CompanyMedia);
