import { message } from 'antd';
// import 'antd/dist/antd.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import APIManager from '../../APIManager';
import companylogo from '../asset/edit-company-logo.png';
import companyCover from '../asset/edit-company-small.svg';
import '../company/company.css';
import styles from './candidature.module.css';

class CompanyHeader extends Component {
  state = {
    form_data_1: '',
    form_data_2: '',
    form_data_3: '',
    company_tabs: [],
    is_clicked: false,
    uploadedImage: companyCover,
    companylogo: companylogo,
    logo: {},
    uploadUrl: '',
    fileName: '',
    uploadImage: '',
    companylogoUpload: '',
    img_type: '',
  };
  uploadFiles = (e) => {
    const file = e;

    this.setState({
      testFile: file,
    });
    let img_type = '';
    if (file.cover) {
      img_type = file.cover.type;
      this.setState({
        img_type: img_type,
      });
    } else if (file.logo) {
      img_type = file.logo.type;
      this.setState({
        img_type: img_type,
      });
    }

    const uploadParams = {
      files: [
        {
          requestType: 'CompanyLogoCover',
          contentType: img_type,
        },
      ],
    };

    APIManager.preSignedUrl(uploadParams).then((resp) => {
      if (resp.status === 200) {
        resp.data?.map((data) => {
          //   console.log(data, 'data');
          return this.setState({
            uploadUrl: data.url,
            fileName: data.fileName,
          });
        });
        this.uploadFile(file);
      }
    });
  };
  uploadFile = (file) => {
    let uploadImage = '';
    if (file.cover) {
      uploadImage = this.state.uploadImage;
    } else if (file.logo) {
      uploadImage = this.state.companylogoUpload;
    }
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: uploadImage,
    })
      .then((resp) => {
        this.uploadCoverLogo(file);
      })
      .catch((err) => console.log(err));
  };

  uploadCoverLogo = (file) => {
    let row_data = {};
    if (file.cover) {
      row_data = {
        candidate_cover_image: this.state.fileName,
        // CandidateCoverData: {
        //   fileName: this.state.fileName,
        //   fileType: this.state.img_type,
        // },
      };
    } else if (file.logo) {
      row_data = {
        companyLogoData: {
          fileName: this.state.fileName,
          fileType: this.state.img_type,
        },
      };
    }
    APIManager.candidateInfoSubmit(row_data)
      .then((response) => {
        if (response.data.isSuccess) {
          message.info('Updated Successfully');
        }
      })
      .catch((err) => message.error('Something went wrong.'));
  };
  toBase64 = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          this.setState({ uploadedImage: reader.result });
        }
      };
      reader.readAsDataURL(file);
    }
  };
  toBase641 = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          this.setState({ companylogo: reader.result });
        }
      };
      reader.readAsDataURL(file);
    }
  };
  onChange = (newFile) => {
    this.toBase64(newFile);
    this.setState({ uploadImage: newFile });
    let data = {};
    data['cover'] = newFile;
    this.uploadFiles(data);
  };
  logoonChange = (newFile) => {
    this.setState({
      companylogoUpload: newFile,
    });
    this.toBase641(newFile);
    let data = {};
    data['logo'] = newFile;
    this.uploadFiles(data);
  };
  componentDidMount() {
    let companylogo = '';
    let uploadedImage = '';
    let ImagePath = 'https://sendbizbucket.s3.eu-west-3.amazonaws.com/';
    if (this.props.infodata !== null && this.props.infodata !== undefined) {
      companylogo = this.props.infodata.data.data.logo;
      if (companylogo && companylogo.name !== '' && companylogo.name !== null) {
        this.setState({ companylogo: companylogo.path });
      }
      uploadedImage =
        this.props.infodata.data.data.can_detail.candidate_cover_image;
      if (uploadedImage) {
        this.setState({ uploadedImage: ImagePath + uploadedImage });
      }
    }
  }

  render() {
    const uploadedImage = this.state.uploadedImage;
    return (
      <>
        <div
          className="site-layout-content edit-company-profile-page-layout"
          style={{ marginTop: 15, marginBottom: 10, width: '100%' }}
        >
          <div className="hover-cover-page">
            {uploadedImage === companyCover ? (
              <img src={uploadedImage} className={styles.bannerImge} alt='banner'/>
            ) : (
              <img src={uploadedImage} className={styles.bannerImge} alt='banner' />
            )}

            <label
              className={`add-company-upload-image edit-company-cover-page ${styles.editImage}`}
            >
              <input
                accept="image/*"
                id="raised-button-file"
                multiple
                type="file"
                onChange={(event) => {
                  this.onChange(event.target.files[0]);
                }}
                name="image"
              />
              <span>
                <img src={process.env.PUBLIC_URL + '/company_edit.svg'}  alt='company_edit'/>
              </span>
            </label>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  infodata: state.companyInfoReducer.data,
  isloading: state.companyInfoReducer.isloading,
  loginloading: state.AuthReducer.isloading,
});

export default connect(mapStateToProps)(CompanyHeader);
