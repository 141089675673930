import React, { Component } from 'react';
import SocialModal from './socialModal.js';
import * as Yup from 'yup';
// import 'antd/dist/antd.css';
import styles from './candidature.module.css';
import { message, Row, Col, Button } from 'antd';
import APIManager from '../../APIManager/index';
import { Formik } from 'formik';
let formButton = null;
let initialValues = {
  Linkedin: '',
  Facebook: '',
  Instagram: '',
  Twitter: '',
  Youtube: '',
  Printerest: '',
};
class CandidatureSocial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salary_active: 'candidature-active',
      social_active: '',
      qualification_active: '',
      exp_active: '',
      contact_active: '',
      tab_name: 'Profile',
      // ==========================================================Qualifications=======================
      allQualification: [],
      degree: '',
      institute: '',
      university: '',
      qulification_from: '',
      qulification_to: '',
      // ==========================================================Salary================================
      last_salary: 0,
      min_salary: 0,
      max_salary: 0,
      recieved_format: '',
      // ===========================================================Experience=============================
      allExperiences: [],
      title: '',
      company: '',
      employment_type: '',
      location: '',
      experience_from: '',
      experience_to: '',
      currently_working: false,
      bio: '',
      // ===========================================================Social================================

      // =========================================================Contact Info============================
      phone_number: '',
      landline_number: '',
      address_line1: '',
      address_line2: '',
      city: '',
      state: '',
      zip_code: '',
      latitude: '',
      longitude: '',
      //===========================================================social Info===============
      social_Links: {},
      socialLinkData: [],
      initialdata: {},
      initialLength: '',
      isOpen: false,
      modalShow: false,
      linktitle: '',
      link: '',
    };
  }
  // componentDidMount(){
  // 	this.getProfileDetail()
  // }
  handleClick = () => {
    formButton.click();
  };
  makeSocialLinkDynamic = (data) => {
    let allSocialLinkData = [];
    if (Object.keys(data).length !== 0) {
      for (let i = 0; i < Object.keys(data).length; i++) {
        allSocialLinkData.push({
          name: Object.keys(data)[i],
          link: data[Object.keys(data)[i]],
        });
        this.setState({ socialLinkData: allSocialLinkData });
      }
    }
  };
  formateData = (data) => {
    let allSocialLinkData = [];
    if (Object.keys(data).length !== 0) {
      for (let i = 0; i < Object.keys(data).length; i++) {
        allSocialLinkData.push({
          name: Object.keys(data)[i],
          link: data[Object.keys(data)[i]],
        });
      }
      this.props.setData(allSocialLinkData);
      this.props.patch();
    }
  };
  setformdata = (data) => {
    let social_Link_data = this.state.socialLinkData;
    let len = social_Link_data.length;
    if (data.tag.length > 0 && data.link.length) {
      social_Link_data.push({
        name: '',
        link: '',
      });

      initialValues[data.tag] = data.link;

      social_Link_data[len].name = data.tag;
      social_Link_data[len].link = data.link;
    }
    this.setState({ modalShow: false, social_Link_data });
  };
  componentWillMount() {
    let data = this.props.data;
    if (data.length > 0) {
      let initialdata = {};
      for (let i = 0; i < data.length; i++) {
        initialdata[data[i].name] = data[i].link;
      }
      this.makeSocialLinkDynamic(initialdata);
      initialValues = initialdata;
    } else {
      this.makeSocialLinkDynamic(initialValues);
    }
  }
  addSocialLink = () => {
    this.setState({ modalShow: true });
    let socialData = this.state.socialLinkData;
    socialData.push({
      name: '',
      link: '',
    });
    // initialValues[data.Title] = data.Link
    this.setState({
      socialLinkData: socialData,
      indexOfModal: socialData.length - 1,
    });
  };

  closeModal = () => {
    if (
      this.state.socialLinkData[this.state.indexOfModal].tag === '' ||
      this.state.socialLinkData[this.state.indexOfModal].link === ''
    ) {
      this.state.socialLinkData.splice(this.state.indexOfModal, 1);
    }
    this.setState({ modalShow: false });
  };

  render() {
    // const [socialLinkData] = this.state.socialLinkData
    const socialNames = [];
    this.state.socialLinkData?.map((social) => socialNames.push(social.name));
    // console.log('socialNames',socialNames)
    // console.log('socialll',this.state.socialLinkData)
    const valObject = {};
    // socialNames.map(name => {
    // 	valObject[name]: Yup.string().trim().required('Street is required.')
    // })
    socialNames.forEach((name) => {
      valObject[name] = Yup.string().trim();
    });
    // console.log('val objj',valObject)
    return (
      <div className={styles.salaryTabsParent}>
        {this.state.modalShow ? (
          <SocialModal
            show={this.state.modalShow}
            onHide={this.closeModal}
            setdata={this.setformdata}
            ref={this.linkElement}
          />
        ) : (
          ''
        )}

        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            let s = Object.keys(values);
            let fs = [];
            s.forEach((n) => {
              let fso = {};
              fso['name'] = n;
              fso['link'] = values[n];
              fs.push(fso);
            });

            const can_social = { can_social: fs };

            APIManager.candidatureSubmit(can_social, 'can_social')
              .then((res) => {
                message.success('Data saved successfully');
                console.log('response', res);
              })
              .catch((error) => {
                message.error('Something went wrong');
                console.log('error', error);
              });
            // setTimeout(() => {
            //   setSubmitting(false);
            //   this.formateData(values);
            // }, 500);
          }}
          validationSchema={Yup.object().shape(valObject)}
          //   validationSchema={Yup.object().shape({
          // 	  linkedin : Yup.string().trim().required('Street is required.')
          //   })}
        >
          {(formikProps) => {
            const {
              values,
              errors,
              handleChange,
              touched,
              handleBlur,
            } = formikProps;
            return (
              <form onSubmit={formikProps.handleSubmit}>
                <Row className={styles.dipNoneOnSmall}>
                  <Col span={24}>
                    <Button
                      style={{ float: 'right', height: '100%' }}
                      type="button"
                      htmlType="submit"
                      className="btn btn-dark bold-family btn-save-font cursor"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm={12}
                    xs={24}
                    className={`form-padding-right ${styles.formPaddingRight}`}
                  >
                    {this.state.socialLinkData.forEach((val, index) => {
                      if (index % 2 === 0) {
                        return (
                          <div className="form-group m-0" key={index}>
                            <label
                              className="input_label_profile"
                              style={{ textTransform: 'capitalize' }}
                            >
                              {val.name}
                            </label>
                            <input
                              type="text"
                              className="input-field-custom-type-1-left"
                              value={values[val.name]}
                              name={val.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder={val.name}
                            />
                            {touched[val.name] && errors[val.name] && (
                              <div
                                className="errormsgs"
                                style={{ color: 'red', fontSize: '12px' }}
                              >
                                {errors[val.name]}
                              </div>
                            )}
                          </div>
                        );
                      }
                    })}
                  </Col>
                  <Col
                    sm={12}
                    xs={24}
                    className={`form-padding-left ${styles.formPaddingLeft1}`}
                  >
                    {this.state.socialLinkData.forEach((val, index) => {
                      if (index % 2 === 1) {
                        return (
                          <div className="form-group m-0 pr-0" key={index}>
                            <label
                              className="input_label_profile"
                              style={{ textTransform: 'capitalize' }}
                            >
                              {val.name}
                            </label>
                            <input
                              type="text"
                              className="input-field-custom-type-1-left"
                              value={values[val.name]}
                              name={val.name}
                              onChange={handleChange}
                              placeholder={val.name}
                              onBlur={handleBlur}
                              style={{ width: '100%' }}
                            />
                            {touched[val.name] && errors[val.name] && (
                              <div
                                className="errormsgs"
                                style={{ color: 'red', fontSize: '12px' }}
                              >
                                {errors[val.name]}
                              </div>
                            )}
                            {/* {touched.linkedin && errors.linkedin && (
												<div className="errormsgs" style={{ color: "red", fontSize: "12px" }}>
													{errors.linkedin}
												</div>
											)} */}
                          </div>
                        );
                      }
                    })}
                  </Col>
                  <div className="custom-file input_label_profile">
                    <label
                      onClick={this.addSocialLink}
                      className="custom-file-label-2"
                      htmlFor="inputGroupFile01"
                    >
                      <i className="fa fa-paperclip"></i>&nbsp;&nbsp; Add
                      another link
                    </label>
                  </div>
                </Row>
                {/*=============display none on big screen=================*/}
                <Row className={styles.dipNoneOnBig}>
                  <Col span={24}>
                    <Button
                      style={{ width: '100%', height: '100%' }}
                      type="button"
                      htmlType="submit"
                      className="btn btn-dark bold-family btn-save-font cursor"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default CandidatureSocial;
