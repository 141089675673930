import React, { Component } from 'react';
import { Row, Col, Spin, Card, message,Button } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import './servicescard.css';
import {
  DeleteFilled,
  EnvironmentFilled,
  EditFilled,
  LikeFilled,
  MessageFilled,
  CalendarFilled,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import APIManager from '../../../APIManager/index';
import cardDefaultPic from '../../../Components/asset/card.svg';
import NoData from '../../../PageNotFound/NoData';
import ConfirmPopUp from '../../constants/ConfirmPopup';
import styles from './events.module.css';
import { ImageUrl } from '../../../Shared/imageUrlPath';
const numEachPage = 2;

class EventCards extends Component {
  state = {
    minValue: 0,
    maxValue: 2,
    data: [],
    resultsPerPage: 10,
    totalData: null,
    totalPages: '',
    deleteEventId: '',
    current_page: null,
    isloading: false,
    confirmDeletePopup: false,
  };
  componentDidMount() {
    //APIManager.getEventList();
    this.makeHttpRequestWithPage(1);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.sortValue !== prevProps.sortValue) {
      this.makeHttpRequestWithPage();
    }
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.makeHttpRequestWithPage();
    }
  }
  DeleteEvent = () => {
    this.setState({ confirmDeletePopup: false });
    const id = this.state.deleteEventId;
    APIManager.deleteEvent(id)
      .then((resp) => {
        if (resp.data.isSuccess) {
          message.info(resp.data.message);
          this.makeHttpRequestWithPage(this.state.current_page);
        }
      })
      .catch((err) => {
        message.error('Something went wrong.');
      });
  };
  makeHttpRequestWithPage = async (pageNumber) => {
    const sort = this.props.sortValue;
    const searchQuery = this.props.searchQuery;
    this.setState({
      isloading: true,
    });
    if ((sort && sort !== 'reset') || (searchQuery && searchQuery !== '')) {
      APIManager.sortEvents(sort, searchQuery,pageNumber)
        .then((resp) => {
          this.setState({
            data: resp.data.data,
            total: resp.data.totalCount,
            //   per_page: resp.data.results,
            current_page: resp.data.currentPage,
            totalPages: resp.data.totalPages,
            isloading: false,
          });
        })
        .catch((err) => {
          this.setState({ isloading: false });
          message.error('Something went wrong!');
        });
    } else {
      APIManager.getEventList(pageNumber)
        .then((resp) => {
          this.setState({
            data: resp.data.data,
            total: resp.data.totalCount,
            //   per_page: resp.data.results,
            current_page: resp.data.currentPage,
            totalPages: resp.data.totalPages,
            isloading: false,
          });
        })
        .catch((err) => {
          this.setState({ isloading: false });
          message.error('Something went wrong!');
        });
    }
  };

  showCandidateInfo = () => {
  };

  handleChange = (value) => {
    this.setState({
      minValue: (value - 1) * numEachPage,
      maxValue: value * numEachPage,
    });
  };

  renderb = (e, data) => {};

  render() {
    let renderPageNumbers;
    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / 10); i++) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers?.map((number) => {
        return (
          <span
            key={number}
            onClick={() => this.makeHttpRequestWithPage(number)}
            className={
              this.state.current_page === number
                ? 'page-number-btn'
                : 'pagination-container'
            }
          >
            {number}
          </span>
        );
      });
    }
    const loading = this.props.isloading;
    return (
      <>
        <div className={'d-flex justify-content-center pt-3'}>
          {loading || this.state.isloading ? <Spin /> : ''}
        </div>
        {this.state.confirmDeletePopup && (
          <ConfirmPopUp
            message="Are you sure you want to delete event"
            handleOk={this.deleteEvent}
            closePopUp={() => this.setState({ confirmDeletePopup: false })}
          />
        )}
        {!this.state.isloading && this.state.data.length === 0 ? (
          <NoData />
        ) : (
          <>
         { !this.state.isloading &&
          this.state.data &&
          this.state.data?.map((data) => (
            <Row className={styles.cardCol}>
            <Card
              className={styles.card}
            >
              <Row gutter={15}>
                <Col xs={5} sm={4}>
                  <img
                    alt="example"
                    src={
                      data.image.filter((data) =>
                        data.fileType?.includes('image')
                      ).length !== 0 && data.image[0] !== ''
                        ? ImageUrl.imageUrlPath +
                          data.image.filter((data) =>
                            data.fileType.includes('image')
                          )[0].fileName
                        : data.companyData.logo.name
                        ? ImageUrl.imageUrlPath +
                          data.companyData.logo.name
                        : cardDefaultPic
                    }
                    className={styles.image}
                  />
                </Col>
                <Col
                  xs={19}
                  sm={20}
                  className="service-detail-row"
                >
                  <Row justify="space-between">
                    <Col xs={20}>
                      <div
                        className="service-name"
                        onClick={() => {
                          var ls = require('local-storage');
                          ls.set('eventId', data._id);
                          this.props.history.push({
                            pathname: `/events/detail/${data._id}`,
                            eventId: data._id,
                          });
                        }}
                      >
                        {data?.name}
                      </div>
                    </Col>
                    <Col span={4} className="icon-col">
                      <EditFilled
                        className="service-hidden-icons service-icons"
                        onClick={(e) => this.props.editEvent(e, data)}
                      />
                      <DeleteFilled
                        className="service-hidden-icons service-icons"
                        onClick={() =>
                          this.setState({
                            deleteEventId: data?._id,
                            confirmDeletePopup: true,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row align="bottom" style={{ marginTop: '10px' }}>
                    <Col
                      xs={24}
                      style={{ display: 'flex', alignSelf: 'self-start' }}
                    >
                      <EnvironmentFilled className="service-icons" />
                      <div
                        className="service-location"
                      >
                        {data?.location}
                      </div>
                    </Col>
                  </Row>
                  <Row align="bottom">
                    <Col
                      xs={12}
                      sm={8}
                    >
                      <LikeFilled className="service-icons" />
                      <div
                        className="service-location"
                      >
                        {data?.likes?.length} Likes
                      </div>
                    </Col>
                    <Col xs={12} sm={6}>
                      <MessageFilled className="service-icons" />
                      <div className="service-location">
                        {data?.comments?.length}
                      </div>
                    </Col>
                    <Col xs={24} sm={10}>
                      <CalendarFilled className="service-icons" />
                      <div className="service-location">
                        {moment(data?.from).format('YYYY/MM/DD')}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            </Row>
          ))}
          <Col xs={24} sm={0} style={{ marginTop: '20px'}} className={styles.buttonCol}>
          <Button
            className={`${styles.button} add-service-btn`}
            onClick={this.props?.addNewEvent}
          >
            Add new Event
          </Button>
        </Col>
        </>
        )}
        {!this.state.isloading && this.state?.data.length > 0 && (
          <div className={'d-flex justify-content-center pt-3'}>
            <span
              className={
                this.state.current_page === 1 ? 'pagination-side-btn' : ''
              }
              onClick={() =>
                this.makeHttpRequestWithPage(this.state.current_page - 1)
              }
            >
              {' '}
              <LeftOutlined />{' '}
            </span>
            <div>{renderPageNumbers}</div>
            <span
              className={
                this.state.current_page === this.state.totalPages
                  ? 'pagination-side-btn'
                  : ''
              }
              onClick={() =>
                this.state.current_page !== this.state.totalPages &&
                this.makeHttpRequestWithPage(this.state.current_page + 1)
              }
            >
              <RightOutlined />
            </span>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    eventList: state.eventReducer.eventList,
    isloading: state.eventReducer.isloading,
  };
};
export default withRouter(connect(mapStateToProps)(EventCards));
