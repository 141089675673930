import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Input } from 'antd';
import APIManager from '../../APIManager/index';
import { message } from 'antd';
const { TextArea } = Input;
let formButton = null;

let initialValues = {
  name: '',
  experience: '',
  current_position: '',
  availability: '',
  price: '',
  uploadUrl: '',
  fileName: '',
  information: '',
  resumeName: '',
  newResumeUpload: true,
};

class CandidateForm extends Component {
  state = {
    resume: '',
    resumeOpt: [],
    resumeList: '',
    initialExp: '',
  };
  componentDidMount() {
    this.initialData();
    this.resumeList();
  }
  resumeList = (pageNumber = 1) => {
    APIManager.companyInfo().then((resp) => {
      this.setState({ resumeOpt: resp.data.data.data.resumes });
    });
  };
  initialData = () => {
    var ls = require('local-storage');
    let data = ls.get('persist:root');
    APIManager.companyInfo()
      .then((res) => {
        this.setState({ initialExp: res.data.data.data.totalExp }, () => {
          if (data !== undefined) {
            data = JSON.parse(data.AuthReducer);
            let actual_data = data.user.data.data;
            const fname = actual_data.can_detail
              ? actual_data.can_detail.firstName
              : '';
            const lname = actual_data.can_detail
              ? actual_data.can_detail.lastName
              : '';
            const currentPosition = actual_data.can_detail
              ? actual_data.can_detail.occupation
              : '';
            if (actual_data.can_experience.length > 0) {
              actual_data.can_experience.forEach((data) => {
                if (data.isCurrentlyWorking === true) {
                  initialValues.current_position = data.title;
                }
              });
            }
            if (fname === '') {
              initialValues.name = lname;
            } else {
              initialValues.name = fname + ' ' + lname;
            }
            if (currentPosition && currentPosition.length > 0) {
              initialValues.current_position = currentPosition;
            }
            initialValues.experience = res.data.data.data.totalExp;
          }
        });
        // setTimeout(() => {

        // },3000)

        console.log('sssssss', res.data.data.data.totalExp);
      })
      .catch(() => {});
  };
  onSubmit = (e) => {
    if (!this.state.resumeList && !this.state.fileName) {
      message.error('Please Upload Resume');
    } else {
      e['applied_for_job'] = this.props.id;
      e['resume'] = this.state.fileName;
      this.props.clickSubmit([e]);
    }
  };
  clickSubmitClick = () => {
    formButton.click();
  };
  handleResumeListChange = (evt) => {
    this.setState({
      resumeList: evt,
      fileName: evt.target.value,
      newResumeUpload: true,
    });
  };
  onChange = (event) => {
    if (event.target.files[0] && event.target.files[0].name) {
      const file = event.target.files[0];
      this.setState({
        contentType: file.type,
        uploadResume: file,
        resumeName: file.name,
      });
      const uploadParams = {
        files: [
          {
            requestType: 'CandidateProfile',
            contentType: this.state.contentType,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            this.setState({ uploadUrl: data.url, fileName: data.fileName });
          });
          this.uploadFile();
        }
      });
    }
  };
  uploadFile = () => {
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.uploadResume,
    })
      .then((resp) => {})
      .catch((err) => console.log(err));
  };
  // }
  handleNewResumeButton = () => {
    let seletBox = document.getElementById('resumeSelect');
    seletBox.value = '';
    this.setState({ newResumeUpload: false, resumeList: '', fileName: '' });
  };
  render() {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            this.onSubmit(values);
          }, 500);
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('name is required.'),
          experience: Yup.string().required('experience is required.'),
          current_position: Yup.string().required(
            'current position is required'
          ),
          availability: Yup.string().required('availability is required'),
          price: Yup.string().required('salary is required'),
          information: Yup.string().required('information is required'),
        })}
      >
        {(formikProps) => {
          const {
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
          } = formikProps;
          return (
            <form onSubmit={formikProps.handleSubmit}>
              <Row className="form-group">
                <label className="input_label_profile required-asteric">
                  Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </label>
                <Input
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  style={{ textTransform: 'capitalize' }}
                  className="input-field-custom-type-1-left input-border"
                  placeholder="Enter name"
                  onBlur={handleBlur}
                />
                {touched.name && errors.name && (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.name}
                  </div>
                )}
              </Row>
              <Row className="form-group">
                <label className="input_label_profile required-asteric">
                  Experience
                </label>
                <Input
                  // type="number"
                  name="experience"
                  value={values.experience}
                  onChange={handleChange}
                  className="input-field-custom-type-1-left input-border"
                  placeholder="Enter experience"
                  onBlur={handleBlur}
                />
                {touched.experience && errors.experience && (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.experience}
                  </div>
                )}
              </Row>
              <Row className="form-group">
                <label className="input_label_profile required-asteric">
                  Current position
                </label>
                <Input
                  name="current_position"
                  value={values.current_position}
                  onChange={handleChange}
                  className="input-field-custom-type-1-left input-border"
                  placeholder="Enter position"
                  onBlur={handleBlur}
                />
                {touched.current_position && errors.current_position && (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.current_position}
                  </div>
                )}
              </Row>
              <Row>
                <Col span={12} className="form-group form-padding-right">
                  <label className="input_label_profile required-asteric">
                    Availability
                  </label>
                  <Input
                    name="availability"
                    value={values.availability}
                    onChange={handleChange}
                    className="input-field-custom-type-1-left input-border"
                    placeholder="Enter availability"
                    onBlur={handleBlur}
                  />
                  {touched.availability && errors.availability && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.availability}
                    </div>
                  )}
                </Col>
                <Col span={12} className="form-group form-padding-left">
                  <label className="input_label_profile required-asteric">
                    Salary
                  </label>
                  <Input
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                    className="input-field-custom-type-1-left input-border"
                    placeholder="Enter salary"
                    onBlur={handleBlur}
                  />
                  {touched.price && errors.price && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.price}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={12} className="form-group">
                  <label className="input_label_profile required-asteric">
                    Resume
                  </label>

                  <select
                    name="resume1"
                    onBlur={handleBlur}
                    onChange={this.handleResumeListChange}
                    placeholder="Select Resume"
                    style={{
                      width: '94%',
                      height: '51px',
                      borderRadius: '5px',
                      border: '1px solid #d5d5d5',
                      backgroundColor: '#fff',
                      outline: 'none',
                    }}
                    id="resumeSelect"
                  >
                    {/* {!this.state.newResumeUpload ? 
										  <option value="" selected disabled>Select resume</option>
										   : <option value="" selected >Upload New Resume</option>} */}
                    <option value="" selected disabled>
                      Select resume
                    </option>
                    {this.state.resumeOpt?.map((data) => (
                      <option value={data.name}>{data.title}</option>
                    ))}
                  </select>
                </Col>
                {!this.state.newResumeUpload ? (
                  <Col span={12} className="form-group">
                    <div style={{ width: '100%', marginTop: 50 }}>
                      <div className="col-sm-8">
                        <label className="add-user-upload-image add-user-upload-image-2">
                          <input
                            accept="pdf/*"
                            id="raised-button-file"
                            type="file"
                            onChange={(event) => {
                              this.onChange(event);
                            }}
                          />
                          {this.state.resumeName ? (
                            <span>
                              <img
                                src={process.env.PUBLIC_URL + '/upload.svg'}
                                alt='upload'
                              />
                              &nbsp;&nbsp;{this.state.resumeName}
                            </span>
                          ) : (
                            <span>
                              <img
                                src={process.env.PUBLIC_URL + '/upload.svg'}
                                alt='upload'
                              />
                              &nbsp;&nbsp;UPLOAD RESUME
                            </span>
                          )}
                        </label>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <Col span={12} className="form-group">
                    <div style={{ width: '100%', marginTop: 50 }}>
                      <div className="col-sm-8">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          OR{' '}
                          <span onClick={this.handleNewResumeButton}>
                            Upload New Resume
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
              <Row className="form-group">
                <label className="input_label_profile required-asteric">
                  Information
                </label>
                <TextArea
                  name="information"
                  value={values.information}
                  onChange={handleChange}
                  className="input-field-custom-type-1-left input-border"
                  placeholder="Enter Information"
                  onBlur={handleBlur}
                ></TextArea>
                {touched.information && errors.information && (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.information}
                  </div>
                )}
              </Row>
              <button
                style={{ opacity: 0 }}
                type={'submit'}
                ref={(e) => {
                  formButton = e;
                }}
              >
                ADD{' '}
              </button>
            </form>
          );
        }}
      </Formik>
    );
  }
}
export default CandidateForm;
