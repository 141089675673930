import React, { Component } from 'react';
import axios from 'axios';
import {
  Row,
  Col,
  Input,
  Select,
  AutoComplete,
  Form,
  Button,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import '../topNav.css';
import styles from './productListing.module.css';
import { withRouter } from 'react-router-dom';
import './ProductListing.css';
import ServiceListingCard from './ProductListingCard';
import CustomCompanyMap from '../../app_status/company_map';
import HeaderLocation from '../../headerLocation/headerLocation';
import { RootUrl } from '../../../Shared/imageUrlPath';
import SelectCompany from '../selectCompany';
import SelectCategory from './selectProductCategory';
import { GoogleApiWrapper } from 'google-maps-react';
import { Helmet } from 'react-helmet';
const { Option } = Select;

let cancel;
class ServiceListing extends Component {
  autoCompleteRef = React.createRef();
  filterRef = React.createRef();
  scrollRef = React.createRef();
  state = {
    searchQuery: '',
    current_location: '',
    lat: '',
    long: '',
    category: [],
    company: '',
    price: '',
    compOptions: [],
    options: [],
    productsData: [],
    homeSearch: "false",
    searchLocation: "false",
    firstTimeRender: true,
  };
  async componentDidMount() {
    if (
      this.props.location.state !== null &&
      this.props.location.state !== undefined
    ) {
      this.autoCompleteRef.current.setFieldsValue({
        search: this.props.location.state.prod_name,
        location: this.props.location.state.location,
      });
      this.setState({
        searchQuery: this.props.location.state.prod_name,
        current_location: this.props.location.state.location,
      });
      if(this.props.location.state.homeSearch==="true"){
        this.setState({
          homeSearch: "true"
        });
      }
    }
    await this.getLocationName(
      this?.props?.location?.state?.lat,
      this?.props?.location?.state?.long
    );
  }
  getLocationName = async (lat, long) => {
    var ls = require('local-storage');
    const localLat = lat ? lat : ls.get('lat');
    const localLong = long ? long : ls.get('long');
    const { google } = this.props;
    if (localLat && localLong) {
      var geocoder;
      geocoder = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(localLat, localLong);
      var country = '';
      var state = '';
      var city = '';
      await geocoder.geocode(
        { latLng: latlng },
        function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              var add = results[0].formatted_address;
              var value = add.split(',');
              const count = value.length;
              country = value[count - 1].replace(/[0-9]/g, '').trim(' ');
              state = value[count - 2].replace(/[0-9]/g, '').trim(' ');
              city = value[count - 3].replace(/[0-9]/g, '').trim(' ');
              this.setState({
                lat: localLat,
                long: localLong,
                current_location: `${city},${state},${country}`,
              });
            } else {
              console.log('address not found');
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        }.bind(this)
      );
    } else {
      this.setState({
        current_location: 'Paris, France',
        lat: '48.8566',
        long: '2.3522',
      });
    }
  };
  handleSelect = (val) => {
    this.setState({ searchQuery: val, firstTimeRender: false });
  };
  handleSearch = (value) => {
    let search = value.toLowerCase();
    let options = [];
    this.search(search);
  };
  handleCurrentLocationChange = (value) => {
    const { latitude, longitude } = value?.companyData?.contact_info;
    this.takeLatLong(latitude, longitude);
  };
  handlePressEnter = (event) => {
    this.setState({
      searchQuery: event.target.value,
      firstTimeRender: false,
    });
  };
  handleFilter = () => {
    this.autoCompleteRef.current.resetFields();
    this.filterRef.current.resetFields();
    this.setState({
      searchQuery: '',
      current_location: '',
      map_location: '',
      lat: '',
      long: '',
      category: [],
      company: '',
      price: '',
      compOptions: [],
      options: [],
      data: [],
      firstTimeRender: true,
    });
    this.getLocationName();
    window.history.replaceState('/products', null);
  };
  search = async (search) => {
    const CancelToken = axios.CancelToken;
    if (cancel) {
      cancel(); // cancel request
    }
    const { data } = await axios.get(
      `${RootUrl.basePath}products?limit=10&page=${1}&search=${search}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      }
    );
    let options = [];
    data?.data?.map((data) => {
      options.push({
        label: data ? data.name : '',
        value: data ? data.name : '',
        key: data._id,
      });
    });
    this.setState({ options });
  };
  ProductDetailRender = (e, data) => {
    this.props.history.push({
      pathname: '/products/detail',
      state: data,
    });
  };
  handlePriceChange = (value) => {
    this.setState({
      price: value,
      firstTimeRender: false,
    });
  };
  setPropsProduct = (data) => {
    this.setState({
      data: data,
    });
  };
  handleCategoryChange = (value) => {
    this.setState({
      category: value,
      firstTimeRender: false,
    });
  };
  handleCompanyChange = (value) => {
    this.setState({
      company: value,
      firstTimeRender: false,
    });
  };
  takecurrentLocation = (value) => {
    this.setState({
      current_location: value,
      map_location: '',
      firstTimeRender: false,
      searchLocation: "true",
    });
  };
  takeLatLong = (lat, long) => {
    this.setState({
      lat: lat,
      long: long,
      firstTimeRender: false,
    });
  };
  handleMapLocationChange = (contact_data, id) => {
    this.takeLatLong(contact_data?.latitude, contact_data?.longitude);
    this.setState({ map_location: contact_data?.sub_address }, function () {
      this.openCompanyCardOnMap(id);
    });
  };
  openCompanyCardOnMap = (id) => {
    setTimeout(() => {
      const mapCard = document.querySelectorAll(`[title="${id}"]`)?.[0];
      if (mapCard) {
        mapCard.click();
      }
    }, 1000);
  };
  render() {
    let markers = [];
    if (this.state.data !== null && this.state.data !== undefined) {
      markers = this.state.data;
    }
    var min = 0.999999;
    var max = 1.000001;
    for (let i = 0; i < markers.length; i++) {
      markers[i].companyData.contact_info = {
        ...markers[i].companyData.contact_info,
        latitude:
          markers[i].companyData?.contact_info?.latitude *
          (Math.random() * (max - min) + min),
        longitude:
          markers[i].companyData?.contact_info?.longitude *
          (Math.random() * (max - min) + min),
      };
    }

    return (
      <div>
        <Helmet>
          <title>
            Senbiz. Promote and sell your products easily on Sendbiz
          </title>
          <meta
            property="og:title"
            content="Senbiz. Promote and sell your products easily on Sendbiz
"
          />
          <meta
            name="twitter:card"
            content="Senbiz. Promote and sell your products easily on Sendbiz
"
          />

          <meta
            name="description"
            content="Find the product you are looking for near your location - Promote all your products and develop your local business and increase your sales ."
          />
          <meta
            property="og:description"
            content="Find the product you are looking for near your location - Promote all your products and develop your local business and increase your sales ."
          />
          <meta
            name="twitter:description"
            content="Find the product you are looking for near your location - Promote all your products and develop your local business and increase your sales ."
          />
        </Helmet>
        {/************* products page searchbar ***************/}
        <Row
          className={`s-search-bar ${styles.productPageSearchBar}`}
          justify="space-between"
        >
          <Col md={10} xs={24}>
            <Form ref={this.autoCompleteRef}>
              <Row gutter={16} align="middle">
                <Col md={14} xs={24}>
                  <Form.Item name="search">
                    <AutoComplete
                      name="search"
                      options={this.state.options}
                      // defaultValue={this.state.searchQuery}
                      onChange={this.handleSelect}
                      onSearch={this.handleSearch}
                      className="header-input-fields"
                      onSelect={this.handleSelect}
                    >
                      <Input
                        //style={{ width: '270px' }}
                        className="ls-outline"
                        onPressEnter={this.handlePressEnter}
                        prefix={<SearchOutlined className="landing-icons-2" />}
                        placeholder="Search"
                      />
                    </AutoComplete>
                  </Form.Item>
                </Col>
                <Col lg={6} md={10} xs={24}>
                  <Form.Item name="location">
                    <HeaderLocation
                      envclass="landing-icons landing-select-icon2"
                      className={`company-joblist-input jobListingcls-1 padding-in-job-search ${styles.locationSectionProductPage}`}
                      value={this.state.current_location}
                      takecurrentLocation={this.takecurrentLocation}
                      takeLatLong={this.takeLatLong}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col md={14} xs={24}>
            <Form ref={this.filterRef}>
              <Row gutter={16} justify="end">
                <Col lg={6} md={8} xs={24}>
                  <Form.Item name="category">
                    <SelectCategory
                      handleCategoryChange={this.handleCategoryChange}
                    />
                  </Form.Item>
                </Col>
                <Col lg={8} md={8} xs={24}>
                  <Form.Item name="location">
                    <SelectCompany
                      handleCompanyChange={this.handleCompanyChange}
                    />
                  </Form.Item>
                </Col>
                <Col lg={5} md={8} xs={24}>
                  <Form.Item name="price">
                    <Select
                      placeholder="Price"
                      style={{ float: 'right' }}
                      className="s-search-select"
                      onChange={this.handlePriceChange}
                    >
                      <Option value="price.amount">Low to High</Option>
                      <Option value="-price.amount">High to Low</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={3} md={6} xs={24} className="filter-button">
                  <Button
                    type="button"
                    onClick={this.handleFilter}
                    className={styles.filterButton}
                  >
                    Clear All Filters
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row className={styles.jobPageMainContainer}>
          <Col md={12} xs={24}>
            <ServiceListingCard
              searchQuery={this.state.searchQuery}
              company={this.state.company}
              category={this.state.category}
              price={this.state.price}
              current_location={this.state.current_location}
              firstTimeRender={this.state.firstTimeRender}
              ProductDetailRender={(e, data) =>
                this.ProductDetailRender(e, data)
              }
              homeSearch={this.state.homeSearch}
              searchLocation={this.state.searchLocation}
              setPropsProduct={(data) => this.setPropsProduct(data)}
              scrollRef={this.scrollRef}
              handleCurrentLocationChange={this.handleCurrentLocationChange}
              changeMapLocation={this.handleMapLocationChange}
            />
          </Col>
          {/************* products page map section ***************/}
          <Col md={12} xs={24} className={styles.productPageMapSection}>
            <CustomCompanyMap
              style={{ heigth: '400px' }}
              renderfrom="products"
              markers={markers}
              current_location={this.state.current_location}
              map_location={this.state.map_location}
              lat={this.state.lat}
              long={this.state.long}
              jobLocation={this.state.current_location}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(
  GoogleApiWrapper({
    apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
  })(ServiceListing)
);