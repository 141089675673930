import React, { Component } from 'react';
import { Row, Col, Spin, Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { fileUrl, ImageUrl } from '../../Shared/imageUrlPath';
import userDefaultPic from '../asset/user.svg';
class JobCards extends Component {

  render() {
    const jobTitle = this.props.appliedJobData
      ? this.props.appliedJobData.data[0].applied_for_job.title
      : '';
    const location = this.props.appliedJobData
      ? `${this.props.appliedJobData.data[0].companyData && this.props.appliedJobData.data[0].companyData.contact_info ? (this.props.appliedJobData.data[0].companyData.contact_info.city).charAt(0).toUpperCase() + (this.props.appliedJobData.data[0].companyData.contact_info.city).slice(1) : ""}, ${this.props.appliedJobData.data[0].companyData && this.props.appliedJobData.data[0].companyData.contact_info ? (this.props.appliedJobData.data[0].companyData.contact_info.country).charAt(0).toUpperCase() + (this.props.appliedJobData.data[0].companyData.contact_info.country).slice(1) : ""}`
      : '';
    const date = this.props.appliedJobData
      ? this.props.appliedJobData.data[0].applied_at : '';
    const companyName = this.props.appliedJobData && this.props.appliedJobData.data[0].companyData
      ? this.props.appliedJobData.data[0].companyData.comp_info.comp_name
      : '';
    const loading = this.props.isloading;
    return (
      <div className={'job-card'}>
        <div className={'d-flex justify-content-center'}>
          {loading && <Spin />}
        </div>

        <Card className="services-card">
          <Row>
            <Col span={3}>
              <img
                src={(this.props.appliedJobData && this.props.appliedJobData.data[0].applied_for_job && this.props.appliedJobData.data[0].applied_for_job.job_logo !== "") ? fileUrl.fileUrlPath + this.props.appliedJobData.data[0].applied_for_job.job_logo : userDefaultPic}
                style={{ width: '100%', height: '120px', borderRadius: "6px" }}
              />
            </Col>
            <Col
              span={21}
              style={{ paddingLeft: '20px' }}
              className="service-detail-row"
            >
              <Row justify="space-between">
                <Col span={17}>
                  <div className="service-name" style={{ paddingBottom: "3px" }}>
                    {' '}
                    {jobTitle.charAt(0).toUpperCase() +
                      jobTitle.slice(1)}
                      &nbsp;&nbsp;
                      <div className="resume-card-3">
                      <img src={process.env.PUBLIC_URL + '/user.png'} />
                        &nbsp;
                        <span>{this.props.appliedJobData ? this.props.appliedJobData.totalCount : ''}</span>
                    </div>
                  </div>
                  <p className="job-card-4 mt-2">
                    {companyName.charAt(0).toUpperCase() +
                      companyName.slice(1)}
                  </p>
                </Col>
                <Col span={7} className="icon-col"></Col>
              </Row>
              <Row>
                <Col span={18}>
                  <span>
                    <img
                      src={process.env.PUBLIC_URL + '/location-red.png'}
                      style={{ width: 14 }}
                    />
                  </span>
                  <span
                    className="service-s-desc"
                    style={{
                      paddingLeft: '8px',
                      marginTop: "5px",
                      verticalAlign: 'middle',
                    }}
                  >
                    {location}
                  </span>
                </Col>
              </Row>
              <Row align="bottom" style={{ marginTop: '10px' }}>
                <Col span={12}>
                  <img
                    src={process.env.PUBLIC_URL + '/clock-red.png'}
                    className="service-icons"
                    style={{ height: '16px', verticalAlign: 'super' }}
                  />
                  <div className="service-location">
                    {moment(date).format('DD MMM YY')}
                  </div>
                </Col>
                <Col span={2}></Col>
              </Row>
            </Col>
          </Row>
        </Card>


        <Row>
          <Col span={24}><p style={{ marginTop: 30, font: "normal normal 400 24px/43px Gilroy Bold" }}>{this.props.appliedJobData ? this.props.appliedJobData.totalCount : ''} Candidates</p></Col>
        </Row>

        {this.props.appliedJobData ? this.props.appliedJobData.data?.map((data) => (<Row className={'col-sm-12 jobListingclscard-1 resume-card-2 mt-3 p-0'}>
          <div className="col-sm-2  all-applied-resume-card-1">
            {(data.candidate.image.length === 0) ? (
              <img src={userDefaultPic} className="w-100 rounded-circle" style={{objectFit : 'cover'}}/>
            ) : (
              <img className="w-100 h-90 rounded-circle" style={{ height: "136px", objectFit : 'cover' }} src={ImageUrl.imageUrlPath + data.candidate.image} />
            )}


          </div>
          <div className="col-sm-10 all-applied-resume-card-7">
            <div className="row">
              <div className="col-sm-9 all-applied-resume-card-10">
                <p className="all-applied-resume-card-2">
                  {data.candidate.name.charAt(0).toUpperCase() +
                    data.candidate.name.slice(1)}
                </p>
                <p className="all-applied-resume-card-3">
                  {data.candidate.email}
                </p>
                <ul className="all-applied-resume-card-8">
                  <li>
                    <a target="_blank" href={fileUrl.fileUrlPath + data.candidate.resume} className="p-0">
                      <p className="all-applied-resume-card-4">
                        <img src={process.env.PUBLIC_URL + '/link.svg'} />
                        &nbsp;&nbsp;View Resume PDF
                      </p>
                    </a>
                  </li>
                  <li>
                    <p className="all-applied-resume-card-5">
                      <span className="all-applied-resume-card-6">
                        Applied on:
                      </span>
                      &nbsp;&nbsp;{moment(data.applied_at).format('DD MMM YY')}
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3">
                <img
                  src={process.env.PUBLIC_URL + '/' + data.status + '.png'}
                  style={{ width: '100%' }}
                />
                <p className="application_status_card w-100">Job status</p>
              </div>
            </div>
          </div>
        </Row>)) : ''}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    appliedJobData: state.jobManagementReducer.appliedJobData,
    isloading: state.jobManagementReducer.isloading,
  };
};
export default withRouter(connect(mapStateToProps)(JobCards));
