import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Input, Spin, notification, Select } from 'antd';
import Subcard from './Subcard';
import { withRouter } from 'react-router-dom';
import './subcontractors.css';
import APIManager from '../../../APIManager';
import Pagination from 'react-js-pagination';
import { SearchOutlined } from '@ant-design/icons';
import styles from './subcontractorList.module.css';
import '../jobCards.css';
import './Subcard.css';
import CompanyMenuDropDown from '../CompanyMenuDropDown';

const { Option } = Select;

const Subcontractors = (props) => {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  useEffect(() => {
    loadData('', 1, '');
  }, []);
  const loadData = (searchKeyword = '', pageNumber, sort) => {
    setIsLoading(true);
    APIManager.subcontractorJobList(
      searchKeyword.toLowerCase(),
      pageNumber,
      sort
    ).then((resp) => {
      setData(resp.data);
      setResultsPerPage(resp.data.results);
      setTotalCount(resp.data.totalCount);
      setTotalPages(resp.data.totalPages);
      setCurrentPage(resp.data.currenctPage);
      setIsLoading(false);
    });
  };
  const checkPremiumAccount = () => {
    APIManager.checkPremiumDetail().then((response) => {
      if (response.data.data.jobs) {
        CreateJob();
      } else {
        notification.warning({
          message: 'Please upgrade your plan to premium.',
          description:
            'Click here to upgrade your plan or delete/activate some jobs.',
          onClick: () => {
            history.push('/company/billing');
          },
        });
      }
    });
  };
  const CreateJob = () => {
    history.push({
      pathname: '/add-subcontractor-job',
      state: {
        editJob: false,
      },
    });
  };
  const handleChange = (e) => {
    setIsLoading(true);
    loadData(e.target.value);
  };
  const handleSectorSort = (e) => {
    console.log('sorting....', e);
    setIsLoading(true);
    loadData('', '', e);
  };
  return (
    <>
      <Row>
        <Col xs={24} md={0} style={{ marginBottom: '-20px' }}>
          <CompanyMenuDropDown name={'Subcontractor'} />
        </Col>
      </Row>
      <Row className={styles.headRow}>
        <Col xs={0} sm={16}>
          <p
            style={{
              fontFamily: 'Gilroy Bold',
              fontSize: 28,
              fontWeight: 'bold',
              fontStyle: 'normal',
            }}
          >
            Manage Subcontractor Openings
          </p>
        </Col>
        <Col xs={0} sm={8} style={{ textAlign: 'end' }}>
          <button
            type="button"
            className="btn btn-dark bold-family btn-save-font cursor"
            style={{ width: 230, height: 50 }}
            onClick={checkPremiumAccount}
          >
            Create Opening
          </button>
        </Col>
      </Row>
      <Row className="s-search-bar-updated" justify="space-between">
        <Col xs={24} sm={14}>
          <Row gutter={12}>
            <Col xs={24} sm={14} className={styles.searchCol}>
              <Input
                className={`company-joblist-input jobListingcls-1 ${styles.searchBar}`}
                prefix={<SearchOutlined />}
                type="text"
                placeholder="Search for keywords"
                onChange={handleChange}
              />
            </Col>
            <Col xs={1} sm={0}></Col>
            <Col xs={11} sm={0}>
              <Col xs={24} sm={0} className={styles.postedCount}>
                {data.totalCount ? `${data.totalCount} POSTED` : `0 POSTED`}
              </Col>
              <Col xs={24} sm={0} className={styles.activeCount}>
                {data.totalCount === undefined
                  ? `ACTIVE JOBS`
                  : data.totalCount > 1
                  ? `${data.totalCount} ACTIVE JOBS`
                  : `${data.totalCount} ACTIVE JOB`}
              </Col>
            </Col>
            <Col xs={11} sm={10} className={styles.sortCol}>
              <Select
                placeholder="Sort"
                className={`s-search-select ${styles.sortBar}`}
                onChange={handleSectorSort}
              >
                <Option value="title">Job Title</Option>
                <Option value="-isActive">Active</Option>
                <Option value="publishedDate">Published Date</Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={0} sm={10}>
          <Row gutter={12} justify="end">
            <Col lg={12} sm={8}>
              <div
                style={{
                  backgroundColor: '#ee5050',
                  width: '100%',
                  height: 34,
                  borderRadius: 6,
                  color: 'white',
                  height: '100%',
                  textAlign: 'center',
                  padding: '10px 25px',
                  fontFamily: 'Gilroy Bold',
                  fontSize: 14,
                }}
              >
                {data.totalCount ? `${data.totalCount} POSTED` : `0 POSTED`}
              </div>
            </Col>
            <Col lg={12} sm={8}>
              <div
                style={{
                  backgroundColor: '#ee5050',
                  width: '100%',
                  height: 34,
                  borderRadius: 6,
                  color: 'white',
                  height: '100%',
                  textAlign: 'center',
                  padding: '10px 25px',
                  fontFamily: 'Gilroy Bold',
                  fontSize: 14,
                }}
              >
                {data.activeTotalCount === undefined
                  ? `ACTIVE JOBS`
                  : data.activeTotalCount > 1
                  ? `${data.activeTotalCount} ACTIVE JOBS`
                  : `${data.activeTotalCount} ACTIVE JOB`}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {isLoading && (
        <Row>
          <Col sm={11} />
          <Col sm={2} justify="center">
            <Spin />
          </Col>
        </Row>
      )}
      {!isLoading && (
        <Row className={styles.cardCol}>
            <Subcard data={data.data} reload={loadData} />
        </Row>
      )}
      {!isLoading && (
          <Row type="flex" alignItems="center">
            <Col xs={24} sm={24} className={styles.pagination}>
              {data?.data?.length > 0 ? (
                <div style={{ margin: '10px', cursor: 'pointer' }}>
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={10}
                    firstPageText={false}
                    lastPageText={false}
                    hideDisabled={true}
                    totalItemsCount={data?.totalCount}
                    pageRangeDisplayed={5}
                    onChange={(pageNumber) => {
                      loadData('', pageNumber);
                    }}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              ) : (
                ''
              )}
            </Col>
            <Col xs={24} sm={0} className={styles.buttonCol}>
              <button
                type="button"
                className="btn btn-dark bold-family btn-save-font cursor"
                style={{ width: '80%', height: 60 }}
                onClick={checkPremiumAccount}
              >
                Create Opening
              </button>
            </Col>
          </Row>
      )}
    </>
  );
};
export default withRouter(Subcontractors);
