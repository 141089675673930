import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import closebtn from '../../asset/cancel-circle.png';
import NumberFormat from 'react-number-format';
import './style.css';
import PromoteData from '../../company/context/context';
import PaymentModel from '../../../Components/paymentCard/selectPayment';
import APIManager from '../../../APIManager/index';
import { PAYMENT_NOTES } from '../../constants/messages';

const options = [
  {
    label: 'Select time period type',
    value: '',
  },
  {
    label: 'Days',
    value: 1,
  },
  {
    label: 'Weeks',
    value: 7,
  },
  {
    label: 'Months',
    value: 30,
  },
];
const PromoteEntity = ({ sponser = false, ...props }) => {
  const [couponText, setCouponText] = useState();
  const [state, setState] = React.useState({
    timePeriod: '',
    budget: '',
    name: props.name,
    entity: props.entity,
    promoteEntity: true,
    textPeriod: '',
    numPeriod: null,
  });
  const [paymentModal, showPaymentModal] = useState(false);
  const handleApplyCoupon = () => {
    const data = {
      cupon: couponText,
    };
    APIManager.cuponVerify(data)
      .then((response) => {
        const amountWithoutCoupon = parseInt(state.budget);
        console.log(amountWithoutCoupon, 'amountWithoutCoupon');
        const offPercent = 100 - response.data.data.percent_off;
        let amountAfterCoupon = (amountWithoutCoupon * offPercent) / 100;
        console.log(amountAfterCoupon, 'amountAfterCoupon');
        setState({ budget: amountAfterCoupon });
        console.log(state.budget, 'budget');
        console.log(state.timePeriod, 'timePeriod');
        // setAmountAfterCoupon(amountAfterCoupon);
      })
      .catch((error) => {
        console.log(error);
      });
    console.log('hey ');
  };
  //changed input value
  const handleChange = (evt) => {
    const value = evt.target.value;
    var timePeriod;
    if (evt.target.name === 'textPeriod') {
      timePeriod = parseInt(state.numPeriod) * parseInt(evt.target.value);
    } else if (evt.target.name === 'numPeriod') {
      timePeriod = parseInt(state.textPeriod) * parseInt(evt.target.value);
    } else {
      timePeriod = parseInt(state.textPeriod) * parseInt(state.numPeriod);
    }
    // const timePeriod = parseInt(state.numPeriod) * parseInt(state.textPeriod);
    setState({
      ...state,
      [evt.target.name]: value,
      timePeriod,
    });
  };

  return (
    <PromoteData.Provider value={state}>
      <div className={'promote-job-container'}>
        {sponser || paymentModal ? (
          <PaymentModel
            data={state}
            show={sponser || paymentModal}
            sponser={sponser}
            onHide={() => {
              showPaymentModal(false);
              props.onHide();
            }}
            onSubmit={() => props.onHide()}
            reloadProducts={props.reloadProducts}
          />
        ) : (
          <Modal
            {...props}
            animation={false}
            className="password-modal"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <div className="modal-header">
              <div
                className="modal-title h4"
                id="contained-modal-title-vcenter"
              >
                Promote Entity
              </div>
              <button type="button" onClick={props.onHide} className="close">
                <span aria-hidden="true">
                  <img src={closebtn} alt="cancel" />
                </span>
                <span className="sr-only">Close</span>
              </button>
            </div>
            <Modal.Body>
              <div style={{ float: 'left', marginRight: '20px' }}>
                {/* <div>
                <label htmlFor="cupon">Coupon</label>
                <input
                  type="text"
                  value={couponText}
                  onChange={(e) => {
                    setCouponText(e.target.value);
                  }}
                />

                <button onClick={handleApplyCoupon}>Apply Coupon</button>
              </div> */}
                <label className="input_label_profile" htmlFor="inputPassword">
                  Time Period
                </label>
                <NumberFormat
                  type="text"
                  className="input-font form-control border profile-form-control mb-0"
                  //onChange={handleChange}
                  placeholder="Enter time period"
                  name={'numPeriod'}
                  onChange={handleChange}
                  maxLength={'2'}
                  // style={{width: '50%'}}
                />
              </div>
              <div>
                <label className="input_label_profile" htmlFor="inputPassword">
                  Time Period Type
                </label>
                <select
                  className="input-font form-control border profile-form-control"
                  class="form-control"
                  placeholder="Select time period type"
                  name={'textPeriod'}
                  onChange={handleChange}
                  value={state.textPeriod}
                  style={{
                    width: '50%',
                    font: 'normal normal 600 16px/24px Gilroy semiBold',
                    color: '#6c757d',
                  }}
                >
                  {options?.map((data) => (
                    <option value={data.value}>{data.label}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="input_label_profile" htmlFor="inputPassword">
                  Total Cost
                </label>
                <NumberFormat
                  type="text"
                  className="input-font form-control border profile-form-control"
                  //onChange={handleChange}
                  placeholder="Enter your budget."
                  name={'budget'}
                  onChange={handleChange}
                  maxLength={'7'}
                />
              </div>
              <div className={'budget-container'}>
                <div className={'payment-container'}>
                  <div style={{ opacity: '0.4' }}>Total Cost</div>
                  <div className={'amount'}>
                    <span className={'dollor-sign'}></span>

                    {state.budget}
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '30px' }}>
                {/* NOTE : Your calculated budget for a day decides the points for
                your item to create the order in main lists. Item with highest
                points will be on top of the list and so on. */}
                NOTE: {PAYMENT_NOTES.SPONSOR}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                // type="submit"
                //   onClick={handlePayment}
                onClick={() => showPaymentModal(true)}
                className="btn btn-dark bold-family btn-save-font cursor"
                disabled={!(state.budget && state.timePeriod)}
              >
                Proceed
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </PromoteData.Provider>
  );
};
export default PromoteEntity;
