import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import styles from './edit_company_profile.module.css';
import userIcon from '../asset/user.svg';
import PopupModal from '../../Components/candidate/passwordmodel';
import './userprofile.css';
import { Row, Col, Input, Form, Select, Spin, Button, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import APIManager from '../../APIManager';

import ProfilePhoto from '../candidate/profile_photo';
const { Option } = Select;

class User_profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: null,
      initialData: null,
      loading: true,
      modalShow: false,
      uploadedImage: userIcon,
      upload_image: '',
      isImageUpload: false,
      upload_image_name: '',
      email: '',
    };
  }
  handleChangePassword = (e) => {
    this.setState({ modalShow: true });
  };
  componentDidMount() {
    APIManager.companyInfo()
      .then((res) => {
        // console.log(res);
        this.setState({
          userType: res.data.data.user.user_type,
          initialData:
            res.data.data.user.user_type === 2
              ? res.data.data.data.comp_info
              : res.data.data.data.subuser,
          loading: false,
          email: res.data.data.user.email,
        });
      })
      .catch((err) => console.log('err', err));
  }
  imageData = (newFile) => {
    const data = {
      ...this.props.userElement.current?.getFieldsValue(),
      user_image: newFile,
    };
    this.props.userElement.current?.setFieldsValue(data);
    this.setState({ upload_image: newFile, isImageUpload: true });
  };

  validateNumber = (e) => {
    if (!Number(e.target.value)) {
      return;
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };
  saveUserData = async (values) => {
    try {
      values.user_image = this.state?.upload_image;
      await APIManager.updateUserProfile(values);
      message.success('User Profile Updated');
    } catch {
      message.error('Error updating user profile.');
    }
  };
  render() {
    let loader = true;
    let uploadedImage = this.state.uploadedImage;

    loader = this.props.loading;

    if (!loader) {
      if (
        this.state.initialData &&
        this.state.initialData.user_image !== null
      ) {
        let url = this.state.initialData.user_image
          ? 'https://sendbizbucket.s3.eu-west-3.amazonaws.com/' +
            this.state.initialData.user_image
          : this.state.uploadedImage;
        uploadedImage = url;
      }
    }

    const numArr = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '0',
    ];

    return (
      <div>
        {this.state.loading ? (
          <Spin />
        ) : (
          <div>
            <Form
              initialValues={this.state.initialData}
              onFinish={this.saveUserData}
              layout="vertical"
            >
              {this.state.modalShow ? (
                <PopupModal
                  show={this.state.modalShow}
                  onHide={() => this.setState({ modalShow: false })}
                  mail={this.state.email}
                />
              ) : null}

              <Row className={styles.userTabHeaderRow}>
                <Col sm={12} xs={24} className="form-padding-right">
                  <Form.Item name="user_image">
                    <ProfilePhoto
                      imageChange={this.imageData}
                      uploadedImage={uploadedImage}
                    />
                  </Form.Item>
                </Col>

                <Col
                  sm={12}
                  xs={24}
                  className={`form-padding-left ${styles.changePassword}`}
                >
                  <div className={'change-password-btn-container2'}>
                    <div
                      className={`change-password-btn`}
                      onClick={() => this.setState({ modalShow: true })}
                    >
                      {' '}
                      <EditOutlined className={'m-1'} />
                      Change Password
                    </div>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginBottom: '10px' }}>
                <Col xs={24}>
                  {this.state.userType === 2 ? (
                    <>
                      <Row className={styles.userTabsContainer}>
                        <Col sm={12} xs={24} className="form-padding-left">
                          <Form.Item
                            name="first_name"
                            label={
                              <label className="input_label_profile">
                                First Name
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'Required Field!',
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input
                              className="s-input-fields"
                              placeholder="Enter First Name"
                              type="text"
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={12} xs={24} className="form-padding-left">
                          <Form.Item
                            name="last_name"
                            label={
                              <label className="input_label_profile">
                                Last Name
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'Required Field!',
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input
                              className="s-input-fields"
                              placeholder="Enter Last Name"
                              type="text"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className={styles.userTabsContainer}>
                        <Col sm={12} xs={24} className="form-padding-left">
                          <Form.Item
                            name="job_profile"
                            label={
                              <label className="input_label_profile">
                                Job Profile
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'Required Field!',
                                whitespace: 'true',
                              },
                            ]}
                          >
                            <Input
                              className="s-input-fields"
                              placeholder="Enter Your Job Profile"
                              type="text"
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={12} xs={24} className="form-padding-left">
                          <Form.Item
                            name="permission"
                            label={
                              <label className="input_label_profile">
                                Permissions
                              </label>
                            }
                          >
                            <Input
                              className="s-input-fields"
                              placeholder="Enter permission"
                              defaultValue="super user"
                              value="super user"
                              type="text"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className={styles.userTabsContainer}>
                        <Col sm={12} xs={24} className="form-padding-left">
                          <Form.Item
                            name="phone"
                            label={
                              <label className="input_label_profile">
                                Phone Number
                              </label>
                            }
                            rules={[
                              {
                                // type:"number",
                                required: true,
                                message: 'Required Field!',
                                whitespace: true,
                                max: 15,
                              },
                            ]}
                          >
                            <Input
                              className="s-input-fields"
                              placeholder="Enter Phone Number"
                              type="tel"
                              onChange={this.validateNumber}
                              maxLength={15}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={12} xs={24} className="form-padding-left">
                          <Form.Item
                            name="email"
                            label={
                              <label className="input_label_profile">
                                Email ID
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'Required Field!',
                                type: 'email',
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input
                              className="s-input-fields"
                              placeholder="Enter Email"
                              type="email"
                              defaultValue={this.state.email}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    /*=================================================*/
                    <>
                      <Row>
                        <Col span={12} className="form-padding-left">
                          <label className="input_label_profile">
                            First Name
                          </label>
                          <Form.Item
                            name="first_name"
                            rules={[
                              {
                                required: true,
                                message: 'Required Field!',
                                whitespace: true,
                              },
                            ]}
                            type="text"
                          >
                            <Input
                              className="s-input-fields"
                              placeholder="Enter First Name"
                              disabled
                              type="text"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="form-padding-left">
                          <label className="input_label_profile">
                            Last Name
                          </label>
                          <Form.Item
                            name="last_name"
                            rules={[
                              {
                                required: true,
                                message: 'Required Field!',
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input
                              className="s-input-fields"
                              placeholder="Enter Last Name"
                              disabled
                              type="text"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} className="form-padding-left">
                          <label className="input_label_profile">
                            Job Profile
                          </label>
                          <Form.Item
                            name={`${
                              this.state.userType === 2
                                ? 'job_profile'
                                : 'title'
                            }`}
                            rules={[
                              {
                                required: true,
                                message: 'Required Field!',
                                whitespace: true,
                              },
                            ]}
                          >
                            <Select
                              name="job_profile"
                              className="input-label-field-custom-select-type-4"
                              placeholder="Select Job Profile"
                              disabled
                            >
                              <Option value="Not Applicable">
                                Not Applicable
                              </Option>
                              <Option value="Internship">Internship</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12} className="form-padding-left">
                          <label className="input_label_profile">
                            Permissions
                          </label>
                          <Form.Item
                            name="permission"
                            rules={[
                              {
                                required: true,
                                message: 'Required Field!',
                                whitespace: true,
                              },
                            ]}
                          >
                            <Input
                              className="s-input-fields"
                              placeholder="Enter permission"
                              disabled
                              type="text"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} className="form-padding-left">
                          <label className="input_label_profile">
                            Phone Number
                          </label>
                          <Form.Item
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: 'Required Field!',
                              },
                            ]}
                          >
                            <Input
                              className="s-input-fields"
                              placeholder="Enter Phone Number"
                              type="number"
                              onKeyDown={(e) =>
                                !numArr.includes(e.key) && e.preventDefault()
                              }
                              maxLength={13}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="form-padding-left">
                          <label className="input_label_profile">
                            Email ID
                          </label>
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: 'Required Field!',
                                type: 'email',
                              },
                            ]}
                          >
                            <Input
                              className="s-input-fields"
                              placeholder="Enter Email"
                              disabled
                              type="email"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
                <Col xs={24} className={styles.buttonCol}>
                  <Button className={styles.button} htmlType="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
    );
  }
}

export default User_profile;
