import React, { Component } from 'react';
import { Row, message, Spin, Tooltip, Col } from 'antd';
import './topNav.css';
import styles from './screen14.module.css';
import { connect } from 'react-redux';
import APIManager from '../../APIManager/index';
import Pagination from 'react-js-pagination';
import { withRouter } from 'react-router-dom';
import NoData from '../../PageNotFound/NoData';
import { fileUrl } from '../../Shared/imageUrlPath';
import cardDefaultPic from '../asset/card.svg';
import { CURRENCY } from '../constants/currency';
import { NavLink } from 'react-router-dom';
import { SendOutlined } from '@ant-design/icons';

class JobCards extends Component {
  myRef = React.createRef();

  state = {
    jobs: [],
    offset: 0,
    tableData: [],
    orgtableData: [],
    perPage: 5,
    currentPage: 0,
    job_type: '',
    loading: false,
  };

  componentDidMount() {
    if (
      !(
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.job_name
      )
    ) {
      this.loadMoreData();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.job_type !== prevProps.job_type) {
      this.loadMoreData();
    }
    if (this.props.experience !== prevProps.experience) {
      this.loadMoreData();
    }
    if (this.props.posted_on !== prevProps.posted_on) {
      this.loadMoreData();
    }
    if (this.props.searchingValue !== prevProps.searchingValue) {
      this.loadMoreData();
    }
    if (this.props.sector !== prevProps.sector) {
      this.loadMoreData();
    }
    if (this.props.current_location !== prevProps.current_location) {
      this.loadMoreData();
    }
  }

  loadMoreData = (pageNumber = 1) => {
    const job_type = this.props.job_type;
    const experience = this.props.experience;
    const posted_on = this.props.posted_on;
    const sector = this.props.sector;
    const searchQuery = this.props.searchingValue;
    const comp_id = this.props.comp_id;
    this.setState({
      loading: true,
    });
    let country = '';
    let state = '';
    let city = '';
    if (this.props.current_location.length > 0) {
      const locationArray = this.props.current_location.split(',');
      if (locationArray.length > 0) {
        country = locationArray[locationArray.length - 1];
        state =
          locationArray.length > 1
            ? locationArray[locationArray.length - 2]
            : '';
        city = locationArray.length > 2 ? locationArray[0] : '';
      }

      this.setState({ loading: true });
      APIManager.jobList(
        pageNumber,
        comp_id,
        job_type,
        experience,
        posted_on,
        searchQuery?.toLowerCase(),
        sector,
        city,
        state,
        country,
        this.props.homeSearch,
        this.props.searchLocation,
      )
        .then((resp) => {
          this.setState({
            jobs: resp.data.data,
            resultsPerPage: resp.data.results,
            totalData: resp.data.totalCount,
            totalPages: resp.data.totalPages,
            currentPage: resp.data.currentPage,
            loading: false,
          });
          if (this.props.searchingValue) {
            this.props.changeMapLocation(
              resp?.data?.data[0]?.companyDetail[0]?.contact_info,
              resp?.data?.data?.[0]?._id
            );
          }
          this?.myRef?.current?.scrollIntoView();
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error(err);
        });
    }
  };

  handleLocationChange = (e, value) => {
    e.stopPropagation();
    this.props.changeMapLocation(
      value?.companyDetail?.[0]?.contact_info,
      value?._id
    );
  };
  render() {
    const SponsoredJobs = this.state.jobs
      .sort((a, b) => a.daysBeforePosted - b.daysBeforePosted)
      .filter((data2) => data2.isSponsored);
    const SortsJobs = this.state.jobs
      .sort((a, b) => a.daysBeforePosted - b.daysBeforePosted)
      .filter((data2) => !data2.isSponsored);
    const SortedJobs = [...SponsoredJobs, ...SortsJobs];

    return (
      <>
        {/********* loader  ***********/}
        {this.state.loading && (
          <div className={'d-flex justify-content-center mt-5'}>
            <Spin />
          </div>
        )}
        {/********* job found  ***********/}
        {!this.state.loading && (
          <h3
            className="card-div-head"
            style={{ paddingTop: '50px', paddingBottom: '5px' }}
          >
            {this.state.totalData
              ? this.state.totalData === 1
                ? this.state.totalData + ' Job Found'
                : this.state.totalData + ' Jobs Found'
              : ''}
          </h3>
        )}
        {/********* main job card container ***********/}
        <div className="custom-container" style={{ padding: 0 }}>
          <div ref={this.myRef}>
            {this.state.jobs.length === 0 && this.state.loading === false ? (
              <NoData
                text={
                  'Please try using Different Location, Filters or keywords for your search.'
                }
              />
            ) : (
              SortedJobs.map((data, index) => (
                <div className="job-card-1" key={index}>
                  <Row
                    className={'jobListingclscard-1 job-card-2 mt-3 p-3'}
                    onClick={() => {
                      const win = window.open(
                        `/apply-for/${data._id}`,
                        '_blank'
                      );
                      win.focus();
                    }}
                  >
                    {/* col1  image section*/}
                    <Col md={6} xs={8} className={styles.imageContainer}>
                      <img
                        className={`job-card-image-1 ${styles.jobpageCardImage}`}
                        alt="logo"
                        src={
                          data.job_logo
                            ? fileUrl.fileUrlPath + data.job_logo
                            : data.companyDetail?.at(0).logo?.name
                            ? fileUrl.fileUrlPath +
                              data.companyDetail?.at(0).logo?.name
                            : cardDefaultPic
                        }
                        style={{
                          width: '100%',
                          height: '110px',
                          borderRadius: '10px',
                          objectFit: 'cover',
                        }}
                      />
                    </Col>
                    {/* col2  all other section*/}
                    <Col md={18} xs={16}>
                      <Row>
                        <Col md={16} xs={20}>
                          <div>
                            {data.title.length > 20 ? (
                              <Tooltip
                                title={data.title}
                                className={`job-card-3 ${styles.jobCradHeading}`}
                                style={{ textTransform: 'capitalize' }}
                              >
                                {data?.title?.substr(0, 20) + '...'}
                                <br />
                              </Tooltip>
                            ) : (
                              <p
                                className={`job-card-3 ${styles.jobCradHeading}`}
                                style={{ textTransform: 'capitalize' }}
                              >
                                {data.title}
                              </p>
                            )}
                            <NavLink
                              to={`comp-id${data.companyDetail[0]._id}`}
                              onClick={(e) => e.stopPropagation()}
                              className={`job-card-4 comp-hover ${styles.jobCradSubHeading}`}
                            >
                              {data.companyDetail[0].comp_info.bus_name}
                            </NavLink>
                          </div>
                        </Col>
                        <Col md={5} xs={0}>
                          <div className="job-card-7 ">{data?.job_type}</div>
                        </Col>
                        {/************** map icons ****************/}
                        <Col md={3} xs={4}>
                          <div
                            className=""
                            style={{
                              display: 'flex',
                              justifyContent: 'end',
                              fontSize: '18px',
                            }}
                            onClick={(e) => this.handleLocationChange(e, data)}
                          >
                            <SendOutlined className="mapIcon" />
                          </div>
                          <span className="sponsered" style={{ marginTop: '10px'}}>
                        {data.isSponsored ? 'SPONSERED' : <span>&nbsp;</span>}
                      </span>
                        </Col>
                      </Row>
                      {/************** small screen job type ****************/}
                      <Row>
                        <Col md={0} xs={12} className={styles.jobType}>
                          <div className="job-card-7 ">{data?.job_type}</div>
                        </Col>
                      </Row>
                      {/************** job card experience section ****************/}
                      <Row>
                        <p
                          className="job-card-5"
                          style={{
                            textTransform: 'capitalize',
                          }}
                        >
                          <Tooltip title={'Experience'}>
                            <img
                              src={
                                process.env.PUBLIC_URL + '/briefcase-red.png'
                              }
                              alt="img"
                            />
                            &nbsp;&nbsp;{data.job_type === "subcontractor"? data?.subcontractor_experience.map((e)=> {return e}).join(", ") : data.experience}
                          </Tooltip>
                        </p>
                      </Row>
                      {/************** job card bottom row ****************/}
                      <Row className="job-card-5">
                        {/************** job card location section ****************/}
                        <Col
                          md={8}
                          xs={24}
                          style={{
                            textTransform: 'capitalize',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <span >
                          <img
                            src={process.env.PUBLIC_URL + '/location-red.png'}
                            alt="img"
                            style={{marginTop: '-4px'}}
                          />
                           </span>
                           <span style={{margin: '4px 0px 0px 5px'}}>
                          {data?.companyDetail[0]?.contact_info?.sub_address }
                           </span>
                        </Col>
                          {/************** subcontractor job card price section ****************/}
                        <Col md={8} xs={24}>
                          {data.job_type === 'subcontractor' && (
                            <div
                              className={'mr-1 pt-1'}
                              style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginTop:'-4px'
                                }}
                              >
                                <span
                                  style={{
                                    color: '#EE5050',
                                    fontSize: '18px',
                                    font: 'normal normal 400 16px/18px Gilroy semiBold',
                                  }}
                                >
                                  {CURRENCY.filter(
                                    (values) =>
                                      values.name === data.rate.currency
                                  ).length > 0 &&
                                    CURRENCY.filter(
                                      (values) =>
                                        values.name === data.rate.currency
                                    )[0].symbol}
                                  &nbsp;
                                  </span>
                                <span>
                                  {data.rate.amount}
                                </span>
                                &nbsp;&nbsp;
                                <span
                                 style={{
                                  opacity: '0.9',
                                  fontSize: '18px',
                                  font: 'normal normal 400 16px/18px Gilroy semiBold',
                                }}
                                >
                                {data.job_payment}
                                </span>
                              </div>
                            </div>
                          )}
                        </Col>
                        {/************** time when job was posted ****************/}
                        <Col md={8} xs={24} className={styles.jobPostedTime}>
                          <div
                            style={{
                              textAlign: 'center',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              marginTop: '-1px'
                            }}
                          >
                            <img
                              src={process.env.PUBLIC_URL + '/clock-red.png'}
                              alt="img"
                              style={{marginTop: '-2px'}}
                            />
                            &nbsp;&nbsp;
                            {data.daysBeforePosted !== 0
                              ? `${data.daysBeforePosted} days ago`
                              : 'today'}
                          </div>
                        </Col>
                      </Row>
                      {/************** No idea what is this ****************/}
                    </Col>
                  </Row>
                </div>
              ))
            )}
            {/* pagination section */}
            {this.state.jobs.length !== 0 ? (
              <div className={'paginate-container'}>
                <Pagination
                  activePage={this.state.currentPage}
                  itemsCountPerPage={20}
                  firstPageText={false}
                  lastPageText={false}
                  totalItemsCount={this.state.totalData}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => {
                    this.loadMoreData(pageNumber);
                  }}
                  hideDisabled={true}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jobdata: state.jobManagementReducer.jobdata,
  };
};
export default withRouter(connect(mapStateToProps)(JobCards));
