import React, {useEffect, useState } from 'react';
import { Select } from 'antd';
import axios from 'axios';
import { RootUrl } from '../../Shared/imageUrlPath';
let cancel;

export default function SelectCompany(props) {
  const [compOptions, setcompOptions] = useState([]);
  const [search, setsearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageNumber, setpageNumber] = useState(null);
  const [length, setLength] = useState('');


  useEffect(() => {
    const search = '';
    companyList(1, search);
  }, [props]);

  const handleCompanyChange = (value) => {
    setLength(value.length);
    props.handleCompanyChange(value);
  };
  const handleCompanySearch = (value) => {
    setcompOptions([]);
    companyList(1, value);
  };
  const handleScroll = (event) => {
    var target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = pageNumber + 1;
      companyList(page, search);
    }
  };

  const companyList = async (pageNumber = 1, search) => {
    setpageNumber(pageNumber);
    const CancelToken = axios.CancelToken;
    if (cancel) {
      cancel();
    }
    const { data } = await axios.get(
      `${RootUrl.basePath}sug-company-list?page=${pageNumber}&search=${search}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      }
    );
    let options = [];
    data?.data?.map((data) => {
      const a = compOptions.find(x => x.key === data._id);
      if (typeof a === 'undefined') {
        options.push({
          label: data.comp_info && data.comp_info.bus_name ? data.comp_info.bus_name : 'No Business Name',
          value: data.comp_id ? data.comp_id : data._id,
          key: data._id,
        });
      }
    });
    if (options.length > 0) {

      const newOptions = options.filter(
        (v, i, a) => a.findIndex((t) => t.key === v.key) === i
      );

      // const uniquevalues = newOptions.filter(
      //   (v, i, a) => compOptions.filter((t) => t.key === v.key) === i
      // );
      setcompOptions([...compOptions, ...newOptions]);
    }
    //  else {
    //   setcompOptions(options);
    // }
    // setcompOptions(options)
  };

  return (
    <Select
      maxTagCount={length>1 ? 0 : 1}
      mode="multiple"
      maxTagTextLength={6}
      placeholder="Company"
      style={{ float: 'right' }}
      onChange={handleCompanyChange}
      onSearch={handleCompanySearch}
      onPopupScroll={handleScroll}
      className="s-search-select"
      options={compOptions}
      filterOption={false}
      showArrow
    ></Select>
  );
}
