import React, { Component } from 'react';
import { Row, Col, Card, message, Spin, Checkbox, Collapse } from 'antd';
import './servicescard.css';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import APIManager from '../../../APIManager/index';
import { withRouter, NavLink } from 'react-router-dom';
import { fileUrl } from '../../../Shared/imageUrlPath';
import userDefaultPic from '../../../Components/asset/user.svg';
import clockIcon from '../../asset/clock.svg';
import moment from 'moment';
import NoData from '../../../PageNotFound/NoData';
import styles from './interested.module.css';

class Services extends Component {
  state = {
    data: [],
    total: '',
    current_page: '',
    isloading: false,
    totalPages: '',
  };
  componentDidMount() {
    this.makeHttpRequestWithPage(1);
  }
  makeHttpRequestWithPage = async (pageNumber) => {
    const category = 'event';
    this.setState({ isloading: true });
    APIManager.getInterstedList(pageNumber, category)
      .then((resp) => {
        if (resp.status === 200) {
          this.setState({
            data: resp.data.data,
            total: resp.data.totalCount,
            current_page: resp.data.currentPage,
            isloading: false,
            totalPages: resp.data.totalPages,
          });
        }
      })
      .catch((err) => {
        this.setState({ isloading: false });
        message.error('Something went wrong!');
      });
  };
  //check channel if exists or not
  checkChannelExist = (id) => {
    APIManager.checkChannel(id).then((resp) => console.log(''));
  };
  addressItem = (id) => {
    APIManager.addressInterested(id)
      .then((resp) => {
        message.info(resp?.data?.message);
        this.makeHttpRequestWithPage(1);
      })
      .catch((err) => {
        message.error('Something Went Wrong');
      });
  };

  render() {
    let renderPageNumbers;
    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / 10); i++) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers?.map((number) => {
        return (
          <span
            key={number}
            onClick={() => this.makeHttpRequestWithPage(number)}
            className={
              this.state.current_page === number
                ? 'page-number-btn'
                : 'pagination-container'
            }
          >
            {number}
          </span>
        );
      });
    }
    return (
      <>
        <div >
          {this.state.isloading ? <Spin /> : ''}
        </div>
        {this.state.data.length === 0 && this.state.isloading === false ? (
          <NoData />
        ) : (
          this.state.data?.map((data) => (
            <Card className={styles.card}>
              <Row gutter={15}>
                <Col xs={5} sm={4}>
                  <img
                    alt="example"
                    src={
                      data.userType === 3 && data.userId.subUserData.user_image
                        ? fileUrl.fileUrlPath +
                          data.userId.subUserData.user_image
                        : data.userType === 2 &&
                          data.userId.companyData.logo.name
                        ? fileUrl.fileUrlPath +
                          data.userId.companyData.logo.name
                        : data.userType === 1 &&
                          data.userId.candidateData.can_detail.profile
                        ? fileUrl.fileUrlPath +
                          data.userId.candidateData.can_detail.profile
                        : userDefaultPic
                    }
                    className={styles.image}
                  />
                </Col>
                <Col xs={19} sm={20} className="service-detail-row">
                  <Row className={styles.nameRow}>
                    <Col xs={20} className={styles.nameCol}>
                      <div
                        className={`service-name product-name ${styles.nameCol}`}
                      >
                        <NavLink
                          to={
                            (data.userType === 3
                              ? `/comp-id${data?.userId?.subUserData?.companyData?._id}`
                              : '') ||
                            (data.userType === 2
                              ? `/comp-id${data?.userId?.companyData?.id}`
                              : '') ||
                            (data.userType === 1
                              ? `/interested-candidate/${data?.userId?.candidateData?._id}`
                              : '')
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          target="_blank"
                          className=" comp-hover "
                          style={{ color: 'black',padding: '0px' }}
                        >
                          {(data.userType === 3
                            ? data?.userId?.subUserData?.first_name +
                              ' ' +
                              data?.userId?.subUserData?.last_name
                            : '') ||
                            (data.userType === 2
                              ? data?.userId?.companyData?.comp_info?.bus_name
                              : '') ||
                            (data.userType === 1
                              ? data?.userId?.candidateData?.can_detail
                                  ?.firstName +
                                ' ' +
                                data?.userId?.candidateData?.can_detail
                                  ?.lastName
                              : '')}
                        </NavLink>
                      </div>
                    </Col>
                    <Col xs={4} className={`icon-col ${styles.checkBox}`}>
                      <Checkbox
                        checked={data?.doneWithConversations}
                        onChange={() => this.addressItem(data?._id)}
                        className="address-checkbox"
                      >
                        Addressed
                      </Checkbox>
                      <div
                        onClick={() => {
                          var ls = require('local-storage');
                          ls.set('chatUserId', data?.userId?._id);
                          this.checkChannelExist(data?.userId?._id);
                          this.props.history.push({
                            pathname: '/company-mailbox',
                            userInfo: data,
                          });
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row align="bottom" className={'product-detail'}>
                    <Col xs={24} sm={19} className={styles.headMargin}>
                      <div className={'person-detail'}>
                        {data?.interestId?.name?.length > 40
                          ? data?.interestId?.name
                              ?.substring(0, 40)
                              ?.toUpperCase() + '...'
                          : data?.interestId?.name?.toUpperCase()}
                      </div>
                    </Col>
                    <Col
                      xs={24}
                      sm={5}
                      className={`${styles.dateCol} ${styles.headMargin}`}
                    >
                      <img src={clockIcon} alt="img" />
                      <span style={{ marginLeft: '0.5rem' }}>
                        {(data.userType === 3
                          ? moment(data?.createdAt).format('YYYY/MM/DD')
                          : '') ||
                          (data.userType === 2
                            ? moment(data?.createdAt).format('YYYY/MM/DD')
                            : '') ||
                          (data.userType === 1
                            ? moment(data?.createdAt).format('YYYY/MM/DD')
                            : '')}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} className={styles.locationCol}>
                      <div>
                        <span>
                          <img
                            src={process.env.PUBLIC_URL + '/location-red.png'}
                            className={styles.locationImg}
                          />
                        </span>
                        <span
                          className={`person-detail mt-1 ${styles.location}`}
                        >
                          {(data.userType === 3
                            ? data?.userId?.subUserData?.companyData
                                ?.contact_info?.sub_address
                            : '') ||
                            (data.userType === 2
                              ? data?.userId?.companyData?.contact_info
                                  ?.sub_address &&
                                data?.userId?.companyData?.contact_info
                                  ?.sub_address
                              : '') ||
                            (data.userType === 1
                              ? data?.userId?.candidateData?.can_detail
                                  ?.currentLocation &&
                                data?.userId?.candidateData?.can_detail
                                  ?.currentLocation
                              : '')}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="colapsable" style={{ marginTop: '15px' }}>
                <Collapse>
                  <Collapse.Panel header="More Info">
                    <Row className={` ${styles.titleMargin}`}>
                      <Col xs={8} sm={4}>
                        <span className={styles.title}>
                          {data.userType === 1 ? 'Candidate:' : 'Company:'}
                        </span>
                      </Col>
                      <Col xs={16} sm={20}>
                        <NavLink
                          to={
                            (data.userType === 3
                              ? `/comp-id${data?.userId?.subUserData?.companyData?._id}`
                              : '') ||
                            (data.userType === 2
                              ? `/comp-id${data?.userId?.companyData?.id}`
                              : '') ||
                            (data.userType === 1
                              ? `/interested-candidate/${data?.userId?.candidateData?._id}`
                              : '')
                          }
                          onClick={(e) => e.stopPropagation()}
                          target="_blank"
                          className={`comp-hover ${styles.titleValueCompanyName}`}
                          style={{
                            textDecoration: 'underline',
                            fontSize: 'initial',
                          }}
                        >
                          {(data.userType === 3
                            ? data?.userId?.subUserData?.first_name
                            : '') ||
                            (data.userType === 2
                              ? data?.userId?.companyData?.comp_info?.bus_name
                              : '') ||
                            (data.userType === 1
                              ? data?.userId?.candidateData?.can_detail
                                  ?.firstName +
                                ' ' +
                                data.userId.candidateData.can_detail.lastName
                              : '')}
                        </NavLink>
                      </Col>
                    </Row>
                    <Row className={` ${styles.titleMargin}`}>
                      <Col xs={9} sm={4}>
                        <span className={styles.title}>Username : </span>
                      </Col>
                      <Col xs={15} sm={10}>
                        <span className={styles.titleValue}>
                          {(data.userType === 3
                            ? data.userId.subUserData.first_name +
                              ' ' +
                              data.userId.subUserData.last_name
                            : '') ||
                            (data.userType === 2
                              ? data.userId.companyData.comp_info.first_name +
                                ' ' +
                                data?.userId?.companyData?.comp_info?.last_name
                              : '') ||
                            (data.userType === 1
                              ? data.userId.candidateData.can_detail.firstName +
                                ' ' +
                                data.userId.candidateData.can_detail.lastName
                              : '')}
                        </span>
                      </Col>
                      <Col xs={10} sm={4}>
                        <span className={styles.title}>
                          {data.userType === 1 ? 'Occupation:' : 'Job Title :'}
                        </span>
                      </Col>
                      <Col xs={14} sm={6}>
                        <span className={styles.titleValue}>
                          {(data.userType === 3
                            ? data?.userId?.subUserData?.title
                            : '') ||
                            (data.userType === 2
                              ? data?.userId?.companyData?.comp_info
                                  ?.job_profile
                              : '') ||
                            (data.userType === 1
                              ? data.userId.candidateData.can_detail.occupation
                              : '')}
                        </span>
                      </Col>
                    </Row>
                    <Row className={styles.titleMargin}>
                      <Col xs={5} sm={4} className={styles.title}>
                        Email :{' '}
                      </Col>
                      <Col xs={19} sm={19} className={styles.titleValue}>
                        {(data.userType === 3
                          ? data?.userId?.subUserData?.email
                          : '') ||
                          (data.userType === 2
                            ? data?.userId?.companyData?.contact_info?.email
                            : '') ||
                          (data.userType === 1
                            ? data?.userId?.candidateData?.can_detail?.email
                            : '')}
                      </Col>
                      <Col xs={0} sm={1} className="icon-col">
                        <div
                          className={styles.mailbox}
                          onClick={() => {
                            var ls = require('local-storage');
                            ls.set('chatUserId', data.userId._id);
                            this.checkChannelExist(data.userId._id);
                            this.props.history.push({
                              pathname: '/company-mailbox',
                              userInfo: data,
                            });
                          }}
                        >
                          <img
                            alt=""
                            src={process.env.PUBLIC_URL + '/mailbox_red.png'}
                            className="  service-icons"
                            style={{ height: 15 }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className={styles.titleMargin}>
                      <Col xs={7} sm={4}>
                        <span className={styles.title}>Contact : </span>{' '}
                      </Col>
                      <Col xs={17} sm={19}>
                        <span className={styles.titleValue}>
                          {(data.userType === 3
                            ? data?.userId?.subUserData?.companyData
                                ?.contact_info?.phone_no
                            : '') ||
                            (data.userType === 2
                              ? data?.userId?.companyData?.contact_info
                                  ?.phone_no
                              : '') ||
                            (data.userType === 1
                              ? data?.userId?.candidateData?.can_contact
                                  ?.phoneNumber
                              : '')}
                        </span>
                      </Col>
                      <Col xs={0} sm={1}>
                        <a
                          href={`mailto:${
                            data.userType === 3
                              ? data?.userId?.subUserData?.email
                              : data.userType === 2
                              ? data?.userId?.companyData?.contact_info?.email
                              : data.userType === 1
                              ? data?.userId?.candidateData?.can_detail?.email
                              : ''
                          }`}
                        >
                          <img
                            src="/telegram.png"
                            style={{ marginLeft: '-11px' }}
                            alt=""
                          />
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={20} sm={0}></Col>
                      <Col xs={2} sm={0}>
                        <div
                          className={styles.mailbox1}
                          onClick={() => {
                            var ls = require('local-storage');
                            ls.set('chatUserId', data.userId._id);
                            this.checkChannelExist(data.userId._id);
                            this.props.history.push({
                              pathname: '/company-mailbox',
                              userInfo: data,
                            });
                          }}
                        >
                          <img
                            alt=""
                            src={process.env.PUBLIC_URL + '/mailbox_red.png'}
                            className="service-icons"
                            style={{ height: 15 }}
                          />
                        </div>
                      </Col>
                      <Col xs={2} sm={0}>
                        <a
                          href={`mailto:${
                            data.userType === 3
                              ? data?.userId?.subUserData?.email
                              : data.userType === 2
                              ? data?.userId?.companyData?.contact_info?.email
                              : data.userType === 1
                              ? data?.userId?.candidateData?.can_detail?.email
                              : ''
                          }`}
                        >
                          <img
                            src="/telegram.png"
                            style={{ marginLeft: '-11px' }}
                            alt=""
                          />
                        </a>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </div>
            </Card>
          ))
        )}
        {this.state.data.length > 0 ? (
          <div className={'d-flex justify-content-end pt-3 pb-3'}>
            <span
              className={
                this.state.current_page === 1 ? 'pagination-side-btn' : ''
              }
              onClick={() =>
                this.makeHttpRequestWithPage(this.state.current_page - 1)
              }
            >
              {' '}
              <LeftOutlined />{' '}
            </span>
            <div>{renderPageNumbers}</div>
            <span
              className={
                this.state.current_page === this.state.totalPages
                  ? 'pagination-side-btn'
                  : ''
              }
              onClick={() =>
                this.state.current_page !== this.state.totalPages &&
                this.makeHttpRequestWithPage(this.state.current_page + 1)
              }
            >
              <RightOutlined />
            </span>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default withRouter(Services);
