import React, { Component } from 'react';
import { Row, Col } from 'antd';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import './footer.css';
import styles from './footer.module.css';

class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className={styles.sliderMainCol} >
        <Slider {...settings} className={styles.sliderMain}>
          <Row  className={styles.sliderMainRow}>
            <Col xs={5} md={3}>
              <img className={styles.image1} src={process.env.PUBLIC_URL + '/search2.png'} 
                   alt='search2'
              />
            </Col>
            <Col xs={1}></Col>
            <Col xs={17} md={19}>
              <Col xs={24}>
                <h2 className={`card-head ${styles.sliderHeading}`}>Promising prospects</h2>
              </Col>
              <Col xs={24}>
                <p className={`card-para ${styles.sliderHeading}`}>
                  Increase your visibility, promote your work environment, your
                  values, your products, your teams, ... it allows you to find
                  you, to join you and to know your know-how.
                </p>
              </Col>
            </Col>
          </Row>
          <Row className={styles.sliderMainRow}>
            <Col  xs={5} md={3}>
              <img className={styles.image1} src={process.env.PUBLIC_URL + '/money-bag.png'}
                   alt='money-bag'
              />
            </Col>
            <Col  xs={1}></Col>
            <Col  xs={17} md={19}>
            <Col xs={24}>
            <h2 className={`card-head ${styles.sliderHeading}`}>Increase your visibility</h2>
            </Col>
            <Col xs={24}>
            <p className={`card-para ${styles.sliderHeading}`}>
                  Acquire more promising prospects, which are transferred to you
                  directly by email and also via the chatbot and a link to your
                  online shop (amazon, your website, ... ).
                </p>
            </Col>
            </Col>
          </Row>
          <Row className={styles.sliderMainRow}>
            <Col  xs={5} md={3}>
            <img className={styles.image1} src={process.env.PUBLIC_URL + '/feature_hired.png'} alt='feature_hired'  />
            </Col>
            <Col  xs={1}></Col>
            <Col  xs={17} md={19}>
            <Col xs={24}>
            <h2 className={`card-head ${styles.sliderHeading}`}>Freely recruit</h2>
                </Col>
                <Col xs={24}>
                <p className={`card-para ${styles.sliderHeading}`}>
                  Recruiting is difficult and expensive, with SendBiz we propose
                  that you can post all job offers Jobs, missions, internships,
                  ...), manage applications easily and in a way accessible to
                  all companies.
                </p>
                </Col>
            </Col>
          </Row>

          <Row className={styles.sliderMainRow}>
            <Col  xs={5} md={3}>
            <img className={styles.image1} src={process.env.PUBLIC_URL + '/share2.png'} alt='share2' />
            </Col>
            <Col  xs={1}></Col>
            <Col  xs={17} md={19}>
            <Col xs={24}>
            <h2 className={`card-head ${styles.sliderHeading}`}>Share your news</h2>
                </Col>
                <Col xs={24}>
                <p className={`card-para ${styles.sliderHeading}`}>
                  Make known all the information concerning your company (new
                  product, presence at an exhibition, job dating,) in order to
                  further improve your visibility and attractivity.
                </p>
                </Col>
            </Col>
          </Row>
          <Row className={styles.sliderMainRow}>
            <Col  xs={5} md={3}>
            <img className={styles.image1} src={process.env.PUBLIC_URL + '/stats.png'} alt='stats'/>
            </Col>
            <Col  xs={1}></Col>
            <Col  xs={17} md={19}>
            <Col xs={24}>
            <h2 className={`card-head ${styles.sliderHeading}`}>Statistics</h2>
                </Col>
                <Col xs={24}>
                <p className={`card-para ${styles.sliderHeading}`}>
                  You have access to some statistics about your job offers, your
                  products or services and applications concerning your job
                  offers and missions.
                </p>
                </Col>
            </Col>
          </Row>
          <Row className={styles.sliderMainRow}>
            <Col  xs={5} md={3}>
            <img className={styles.image1} src={process.env.PUBLIC_URL + '/project.png'} alt='project' />
            </Col>
            <Col  xs={1}></Col>
            <Col  xs={17} md={19}>
            <Col xs={24}>
            <h2 className={`card-head ${styles.sliderHeading}`}>Projects & Advices</h2>
            </Col>
            <Col xs={24}>
                <p className={`card-para ${styles.sliderHeading}`}>
                  You have access to some statistics about your job offers, your
                  products or services and applications concerning your job
                  offers and missions.
                </p>
            </Col>
            </Col>
          </Row>
        </Slider>
      </div>
    );
  }
}
export default SimpleSlider;
