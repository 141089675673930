import React from 'react';
import {
  Row,
  Col,
  Card,
  Tag,
  Tooltip,
} from 'antd';
import { useHistory, withRouter } from 'react-router-dom';
import cardDefaultPic from '../../Components/asset/card.svg';
import styles from './applied_candidates.module.css';
import { fileUrl } from '../../Shared/imageUrlPath';
import moment from 'moment';

const Applied_candidates_new_card = ({
  title,
  job_type,
  count,
  added_by,
  address,
  date,
  image,
  id,
  companyLocation,
}) => {
  const history = useHistory();
  // console.log(job_type);
  return (
    <div className={styles.cardCol}>
    <Card
      className={styles.card}
      style={{ cursor: 'pointer' }}
      onClick={() =>
        history.push({
          pathname: '/AppliedCandidates/details',
          state: {
            title,
            job_type,
            count,
            added_by,
            address,
            date,
            image,
            id,
            companyLocation,
          },
        })
      }
    >
      <Row gutter={15}>
        <Col xs={5} sm={4}>
          <img
            alt="example"
            src={
              image
                ? fileUrl.fileUrlPath + image
                : cardDefaultPic
            }
          className={styles.image}
          />
        </Col>
        <Col xs={19} sm={20}>
          <Row>
            <Col xs={24} sm={24} className={`service-name ${styles.titleCol}`}>
                <span>
                {title.length > 30 ? (
                  <Tooltip title={title}>{title.substr(0, 29) + '...'}</Tooltip>
                ) : (
                  title
                )}
                </span>
                <span className={styles.tagSpan}>
                <Tag
                  color="volcano"
                  className={`s-price-tag ${styles.tag}`}
                >
                  {`${count} Applied`}
                </Tag>
                </span>
            </Col>
          </Row>
          <Row>
          <Col xs={12} sm={0}>
              <span>
                <img src="../clock-red.png" style={{ width: 14 }} />
              </span>
              <span
                className="service-s-desc"
                style={{
                  paddingLeft: '8px',
                  verticalAlign: 'middle',
                }}
              >
                {moment(date).format('YYYY/MM/DD')}
              </span>
            </Col>
            <Col xs={12} sm={0}>
            <Tag
                  color="volcano"
                  className={`s-price-tag ${styles.tag}`}
                >
                  {`${count} Applied`}
                </Tag>
            </Col>
            <Col xs={24} sm={5}>
              <span>
                <img
                  src={process.env.PUBLIC_URL + '/briefcase-red.png'}
                  style={{ width: 14 }}
                />
              </span>
              <span
                className="service-s-desc"
                style={{
                  paddingLeft: '8px',
                  verticalAlign: 'middle',
                }}
              >
                {job_type}
              </span>
              </Col>
              <Col xs={24} sm={19}>
              <span>
                <img src="../user-circle.png" style={{ width: 14 }} />
              </span>
              <span
                style={{
                  color: '#000',
                  opacity: '0.8rem',
                  fontWeight: 'bold',
                  verticalAlign: 'middle',
                  marginLeft: '10px',
                }}
              >
                Added By:
              </span>
              <span
                className="service-s-desc"
                style={{
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  verticalAlign: 'middle',
                  marginLeft: '5px',
                }}
              >
                {added_by}
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={16} className={styles.locationCol}>
              <span>
              <img src="../location-red.png" style={{ width: 14 }} />
                <span className={styles.locationSpan}>
                  {companyLocation}
                  </span>
              </span>
            </Col>
            <Col xs={0} sm={8}>
              <span>
                <img src="../clock-red.png" style={{ width: 14 }} />
                <span
                className={styles.dateCol}
              >
                {moment(date).format('YYYY/MM/DD')}
              </span>
              </span>
              
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
    </div>
  );
};

export default Applied_candidates_new_card;
