import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import Cancel from '../asset/cancel_svg.svg';
import './rightModal.css';

class RightModal extends Component {

  handleOk = () => {
    this.props.onSubmit();
  };
  handleCancel = () => {
    Modal.destroyAll();
    this.props.isClose();
  };

  render() {
    return (
      <div className="right-modal-w100">
        {this.props.isOpen ? (
          <Modal
            title={this.props.title}
            visible={this.props.isOpen}
            onOk={this.handleOk}
            cancelButtonProps={{ style: { display: 'none' } }}
            closeIcon={<img src={Cancel}  alt='cancel'/>}
            onCancel={this.handleCancel}
            className={'right-modal' + this.props.className}
            maskClosable={false}
            footer={[
              <Button
                key="submit"
                type="primary"
                className="right-modal-button right-modal-btn"
                onClick={this.handleOk}
              >
                Submit
              </Button>,
            ]}
          >
            {this.props.component}
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default RightModal;
