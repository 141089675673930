import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Spin } from 'antd';
import APIManager from '../../APIManager';
import Pagination from 'react-js-pagination';
import { SearchOutlined } from '@ant-design/icons';
import NoDataFound from '../../PageNotFound/NoData';
import Applied_candidates_new_card from './applied_candidates_new_card';
import styles from './applied_candidates.module.css';
import CompanyMenuDropDown from './CompanyMenuDropDown';

const Applied_candidates_new = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    loadData('', 1);
  }, []);
  const loadData = (searchKeyword = '', pageNumber) => {
    setIsLoading(true);
    APIManager.getAppliedCandidates(
      '',
      searchKeyword.toLowerCase(),
      pageNumber
    ).then((resp) => {
      // console.log('new resss', resp.data);
      setData(resp.data);
      setIsLoading(false);
    });
  };
  const handleChange = (e) => {
    setIsLoading(true);
    loadData(e.target.value);
  };
  return (
    <>
      <Row>
        <Col xs={24} md={0} style={{ marginBottom: '-20px' }}>
          <CompanyMenuDropDown name={'Applied Candidates'} />
        </Col>
      </Row>
      <div>
        <Row className={styles.headRow}>
          <Col xs={0} sm={24}>
            <p className={styles.upperRow}>Applied Candidates</p>
          </Col>
        </Row>
        <Row className="s-search-bar-updated" justify="space-between">
          <Col xs={24} sm={12} className={styles.searchRow}>
            <Input
              className={`company-joblist-input jobListingcls-1 ${styles.searchInput}`}
              prefix={<SearchOutlined />}
              type="text"
              placeholder="Search for a job"
              onChange={handleChange}
            />
          </Col>
        </Row>

        {isLoading ? (
          <div className={'d-flex justify-content-center'}>
            <Spin />
          </div>
        ) : !data.data.length ? (
          <NoDataFound />
        ) : (
          data.data?.map(({ company, job, count, createdBy }) => {
            return (
              count > 0 && (
                <Col xs={24} sm={24} className={styles.cardCol}>
                  <Applied_candidates_new_card
                    title={job?.title}
                    job_type={job?.job_type}
                    count={count}
                    added_by={createdBy}
                    address={company?.contact_info?.sub_address}
                    date={job?.create}
                    image={job?.job_logo ? job?.job_logo : company?.logo?.name}
                    key={job?.id}
                    id={job?.id}
                    companyLocation={company?.contact_info?.sub_address}
                  />
                </Col>
              )
            );
          })
        )}
        {!isLoading && (
          <Row type="flex" alignItems="center">
            <Col span={10} />
            <Col>
              {data?.data?.length > 0 ? (
                <div style={{ margin: '10px', cursor: 'pointer' }}>
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={10}
                    firstPageText={false}
                    lastPageText={false}
                    hideDisabled={true}
                    totalItemsCount={data?.totalCount}
                    pageRangeDisplayed={5}
                    onChange={(pageNumber) => {
                      loadData('', pageNumber);
                    }}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              ) : (
                ''
              )}
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default Applied_candidates_new;
