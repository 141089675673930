import React, { useState } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Form,
  AutoComplete,
  message,
  Checkbox,
} from 'antd';
import {
  SearchOutlined,
  FileTextFilled,
  CheckCircleFilled,
} from '@ant-design/icons';
import Header from './header/header';
import Footer from './footer/footer';
import { Link } from 'react-router-dom';
import './Landing.css';
import HeaderLocation from './headerLocation/headerLocation';
import APIManager from '../APIManager';
import { RootUrl } from '../Shared/imageUrlPath';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { SENDBIZ_TITLE, SENDBIZ_DESCRITPION } from './../Meta/index';
import sendbizOverViewCover from './asset/sendbizOverviewCover.png';

const Option = { Select };
let cancel;

const Landing = (props) => {
  const [current_location, setLocation] = useState('');
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [tags, setTags] = useState('1');
  const [searchValue, setsearchValue] = useState('');
  const [compOptions, setcompOptions] = useState([]);
  const [agreeCheck, setAgreeCheck] = useState(false);
  const renderSignUpComp = () => {
    props.history.push({
      pathname: '/signup',
      state: {
        person: true,
        signup_by: 'Company',
      },
    });
  };
  const renderSignUpCan = () => {
    props.history.push({
      pathname: '/signup',
      state: {
        person: false,
        signup_by: 'Candidate',
      },
    });
  };
  const takecurrentLocation = (value) => {
    setLocation(value);
  };
  const takeLatLong = (lat, long) => {
    setLat(lat);
    setLong(long);
    // this.setState({
    //     lat:lat,
    //     long: long
    // })
  };
  const handleAutoChange = (value) => {
    setsearchValue(value);
  };
  const handleTagsChange = (value) => {
    setTags(value);
  };
  // const [email, submitEmail] = useState('');
  //Submit emails to company
  // const contactUs = (e) => {
  //   e.preventDefault();
  //   //preventDefault();
  //   const data = {
  //     email: email,
  //   };
  //   if (email) {
  //     APIManager.contactToSendbiz(data)
  //       .then((response) => {
  //         if (response.data.isSuccess) {
  //           message.info(
  //             `Your ${response?.data?.data?.email} is submitted to sendbiz team.`
  //           );
  //           submitEmail('');
  //         }
  //       })
  //       .catch((error) => {
  //         message.error('Please check your email or use another email.');
  //       });
  //   } else {
  //     message.warning('Please enter your email.');
  //   }
  // };
  const handleSearchButton = (values) => {
    if (values.tags === '1' || tags === '1') {
      props.history.push({
        pathname: '/CompanyList',
        state: {
          comp_name: values.search,
          location: current_location,
          homeSearch: 'true',
          lat: lat,
          long: long,
        },
      });
    } else if (values.tags === '2') {
      props.history.push({
        pathname: '/all_jobs',
        state: {
          job_name: values.search,
          location: current_location,
          homeSearch: 'true',
          lat: lat,
          long: long,
        },
      });
    } else if (values.tags === '3') {
      props.history.push({
        pathname: '/products',
        state: {
          prod_name: values.search,
          location: current_location,
          homeSearch: 'true',
          lat: lat,
          long: long,
        },
      });
    } else if (values.tags === '4') {
      props.history.push({
        pathname: '/services',
        state: {
          ser_name: values.search,
          location: current_location,
          homeSearch: 'true',
          lat: lat,
          long: long,
        },
      });
    } else if (values.tags === '5') {
      props.history.push({
        pathname: '/news',
        state: {
          news_name: values.search,
          location: current_location,
          homeSearch: 'true',
        },
      });
    } else if (values.tags === '6') {
      props.history.push({
        pathname: '/events',
        state: {
          event_name: values.search,
          location: current_location,
          homeSearch: 'true',
          lat: lat,
          long: long,
        },
      });
    }
  };

  const handleSearch = async (value) => {
    let options = [];
    const CancelToken = axios.CancelToken;
    if (cancel) {
      cancel(); // cancel request
    }
    if (tags !== '' || current_location !== '') {
      if (tags === '1') {
        const { data } = await axios.get(
          `${
            RootUrl.basePath
          }company_list?limit=20&page=${1}&search=${value.toLowerCase()}`,
          {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
          }
        );
        data?.data?.map((data) => {
          options.push({
            label: data.comp_info ? data.comp_info.bus_name : '',
            value: data.comp_info ? data.comp_info.bus_name : '',
            key: data._id,
          });
        });
        setcompOptions(options);
      } else if (tags === '2') {
        const { data } = await axios.get(
          `${
            RootUrl.basePath
          }jobs?limit=5&page=${1}&search=${value.toLowerCase()}`,
          {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
          }
        );
        data?.data?.map((data) => {
          options.push({
            label: data ? data.title : '',
            value: data.title,
            key: data._id,
          });
        });
        setcompOptions(options);
      } else if (tags === '3') {
        const { data } = await axios.get(
          `${
            RootUrl.basePath
          }products?limit=10&page=${1}&search=${value.toLowerCase()}`,
          {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
          }
        );
        data?.data?.map((data) => {
          options.push({
            label: data ? data.name : '',
            value: data.name,
            key: data._id,
          });
        });
        setcompOptions(options);
        // APIManager.allProductsSearchLanding(1, value.toLowerCase()).then(
        //   (resp) => {
        //     resp.data.data.map((data) => {
        //       options.push({
        //         label: data ? data.name : '',
        //         value: data.name,
        //         key: data._id,
        //       });
        //     });
        //     setcompOptions(options);
        //   }
        // );
      } else if (tags === '4') {
        const { data } = await axios.get(
          `${
            RootUrl.basePath
          }services?limit=10&page=${1}&search=${value.toLowerCase()}`,
          {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
          }
        );
        data?.data?.map((data) => {
          options.push({
            label: data ? data.name : '',
            value: data.name,
            key: data._id,
          });
        });
        setcompOptions(options);
        // APIManager.allServicesSearchLanding(1, value.toLowerCase()).then(
        //   (resp) => {
        //     resp.data.data.map((data) => {
        //       options.push({
        //         label: data ? data.name : '',
        //         value: data.name,
        //         key: data._id,
        //       });
        //     });
        //     setcompOptions(options);
        //   }
        // );
      } else if (tags === '5') {
        const { data } = await axios.get(
          `${
            RootUrl.basePath
          }services?limit=10&page=${1}&search=${value.toLowerCase()}`,
          {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
          }
        );
        data?.data?.map((data) => {
          options.push({
            label: data ? data.name : '',
            value: data.name,
            key: data._id,
          });
        });
        setcompOptions(options);
        // APIManager.newsListSearchLanding(1, value.toLowerCase()).then(
        //   (resp) => {
        //     // resp.data.data.map((data) => {
        //     //     options.push(
        //     //         {
        //     //             label: data ? data.name : "",
        //     //             value: data.name,
        //     //             key: data._id
        //     //         }
        //     //     )
        //     // })
        //     // setcompOptions(options)
        //   }
        // );
      } else if (tags === '6') {
        const { data } = await axios.get(
          `${
            RootUrl.basePath
          }services?limit=10&page=${1}&search=${value.toLowerCase()}`,
          {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
          }
        );
        data?.data?.map((data) => {
          options.push({
            label: data ? data.name : '',
            value: data.name,
            key: data._id,
          });
        });
        setcompOptions(options);
        // APIManager.allEventsSearchLanding(1, value.toLowerCase()).then(
        //   (resp) => {
        //     resp.data.data.map((data) => {
        //       options.push({
        //         label: data ? data.name : '',
        //         value: data.name,
        //         key: data._id,
        //       });
        //     });
        //     setcompOptions(options);
        //   }
        // );
      }
    }
  };
  const contactUs = async (values) => {
    try {
      if (!agreeCheck) {
        return message.error('Please accept the terms to proceed.');
      }
      await APIManager.contactToSendbiz(values);
      message.success('Email sent for contact successfully');
    } catch (error) {
      message.error('Something went wrong');
    }
  };
  const urll = process.env.PUBLIC_URL + '/About_back.png';
  const services_url = process.env.PUBLIC_URL + '/services.png';
  const products_url = process.env.PUBLIC_URL + '/products.png';
  const jobs_url = process.env.PUBLIC_URL + '/jobs.png';
  var ls = require('local-storage');
  const loginToken = ls.get('token');
  return (
    <>
      <div className="responsive-div">
        <Helmet>
          <title>{SENDBIZ_TITLE}</title>
          <meta property="og:title" content={SENDBIZ_TITLE} />
          <meta name="twitter:card" content={SENDBIZ_TITLE} />

          <meta name="description" content={SENDBIZ_DESCRITPION} />
          <meta property="og:description" content={SENDBIZ_DESCRITPION} />
          <meta name="twitter:description" content={SENDBIZ_DESCRITPION} />
        </Helmet>
        {/* commenting for testing purpose */}
        <Header />
        <div className="home-outer-div">
          <div className="welcome-div">
            <div className="welcome-title">
              Welcome to Sen<span style={{ color: '#B02318' }}>db</span>iz
            </div>
            <div className="welcome-subtext">
              <span> A worldwide business directory</span>
            </div>
            <div className="welcome-subtext-2">
            <p> BtoBtoC business networking platform</p>
            </div>
            {/* <Form onFinish={handleSearchButton}>
              <div
                className={
                  'd-flex justify-content-space-around homepage-banner'
                }
              >
                <Col
                  span={7}
                  offset={4}
                  className={'mr-2 homepage-banner-items'}
                >
                  <Form.Item
                    name="search"
                  >
                    <AutoComplete
                      options={compOptions}
                      onSearch={handleSearch}
                      name="search"
                      onChange={handleAutoChange}
                      className="landing-input-fields ls-1 ls-outline homepage-banner-items"
                    >
                      <Input
                        className="ls-outline"
                        prefix={
                          <SearchOutlined className="landing-search-bar-icons" />
                        }
                        placeholder="Search Job title or Key word"
                      />
                    </AutoComplete>
                  </Form.Item>
                </Col>
                <Col span={4} className={'mr-2'}>
                  <FileTextFilled className="landing-icons-1 landing-select-icon" />
                  <Form.Item
                    rules={[{ required: false, message: 'Required field' }]}
                    name="tags"
                  >
                    <Select
                      placeholder="Categories"
                      onChange={handleTagsChange}
                      name="tags"
                      className="landing-input-fields homepage-banner-items"
                      popupClassName="landing-dropdown"
                      defaultValue="1"
                    >
                      <Option value="1">Companies</Option>
                      <Option value="2">Jobs</Option>
                      <Option value="3">Products</Option>
                      <Option value="4">Services</Option>
                      <Option value="5">News</Option>
                      <Option value="6">Events</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3} className={'mr-2'}>
                  <HeaderLocation
                    envclass="landing-search-bar-location-icon landing-select-icon"
                    className="home-location-textbox input-field-custom-type-1-left search-outline-padding homepage-banner-items"
                    takecurrentLocation={takecurrentLocation}
                    takeLatLong={takeLatLong}
                  />
                </Col>
                <Col span={3}>
                  <Button htmlType="submit" className="landing-btn">
                    SEARCH
                  </Button>
                </Col>
              </div>
            </Form> */}
            <div style={{display: 'flex', justifyContent:'center'}}>
              <Button className="welcome-sub-btn" onClick={renderSignUpComp}>
                START FOR FREE
              </Button>
            </div>
          </div>
          <div className="offerings-div">
            <div className="offerings-title">Our offerings</div>
            <Row justify={'space-around'} className="offerings-div-box">
              <Col span={7}>
                <Link
                  to="/all_jobs?job_type=subcontractor"
                  className="landing-link"
                >
                  <div
                    className="offerings-card"
                    style={{ backgroundImage: `url(${products_url})` }}
                  >
                    <div className="o-card-title">Subcontractors</div>
                    <div className="o-card-subtext">
                      Subcontract yourself to range of different companies.
                    </div>
                  </div>
                </Link>
              </Col>
              <Col span={7}>
                <Link to="/all_jobs" className="landing-link">
                  <div
                    className="offerings-card"
                    style={{ backgroundImage: `url(${jobs_url})` }}
                  >
                    <div className="o-card-title">Jobs</div>
                    <div className="o-card-subtext">
                      Apply to as many jobs from different companies.
                    </div>
                  </div>
                </Link>
              </Col>
              <Col span={7}>
                <Link to="/services" className="landing-link">
                  <div
                    className="offerings-card"
                    style={{ backgroundImage: `url(${services_url})` }}
                  >
                    <div className="o-card-title">Services</div>
                    <div className="o-card-subtext">
                      Avail from a bunch of services provided by different
                      companies.
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </div>
          <div className="caters-div caters-card-col1">
            <div className="caters-title">Sendbiz caters</div>

            <div className="caters-cad-outer-div">
              {/* card1  */}
              <div className="caters-card">
                <Row>
                  <Col span={24}>
                    <div className="caters-card-title">For Companies</div>
                    <div className="caters-card-subtext">
                      <span style={{ color: '#B02318' }}> Companies: </span>Get
                      started!
                    </div>
                  </Col>
                  <Col span={22} offset={6} className="for-companies-row1">
                    <div className="unlimited-div">
                      <CheckCircleFilled className="caters-icons" />
                      <span className="caters-subtext-2">Post Jobs</span>
                    </div>
                    <div className="unlimited-div">
                      <CheckCircleFilled className="caters-icons" />
                      <span className="caters-subtext-2">
                        Post Subcontractor Jobs
                      </span>
                    </div>
                    <div className="unlimited-div">
                      <CheckCircleFilled className="caters-icons" />
                      <span className="caters-subtext-2">Post Resources</span>
                    </div>
                    <div className="unlimited-div">
                      <CheckCircleFilled className="caters-icons" />
                      <span className="caters-subtext-2">Post Services</span>
                    </div>
                    <div className="unlimited-div">
                      <CheckCircleFilled className="caters-icons" />
                      <span className="caters-subtext-2">Select Resumes</span>
                    </div>
                  </Col>
                  {!loginToken && (
                    <Col span={12} offset={6}>
                      <Button
                        className="landing-signup-btn"
                        onClick={renderSignUpComp}
                      >
                        SIGN UP
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
              {/* card 2 */}
              <div className="caters-card caters-card-col2">
                <Row>
                  <Col span={24}>
                    <div className="caters-card-title">For People</div>
                    <div className="caters-card-subtext">
                      Create your{' '}
                      <span style={{ color: '#B02318' }}> profile</span> now!
                    </div>
                  </Col>
                  <Col span={22} offset={6} className="for-companies-row2">
                    <div className="unlimited-div Post-your-Resume">
                      <CheckCircleFilled className="caters-icons" />
                      <span className="caters-subtext-2">Post your Resume</span>
                    </div>
                    <div className="unlimited-div">
                      <CheckCircleFilled className="caters-icons" />
                      <span className="caters-subtext-2">Apply Jobs</span>
                    </div>
                    <div className="unlimited-div">
                      <CheckCircleFilled className="caters-icons" />
                      <span className="caters-subtext-2">Apply Missions</span>
                    </div>
                    <div className="unlimited-div">
                      <CheckCircleFilled className="caters-icons" />
                      <span className="caters-subtext-2">Buy Products</span>
                    </div>
                    <Col className="unlimited-div" />
                    <Col className="unlimited-div" />
                  </Col>
                  {!loginToken && (
                    <Col span={12} offset={6}>
                      <Button
                        className="landing-signup-btn"
                        onClick={renderSignUpCan}
                      >
                        SIGN UP
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          </div>

          <div
            className="about-us-div"
            style={{ backgroundImage: `url(${urll})` }}
          >
            <div className="about-title">About Sendbiz</div>
            <div className="about-subtext">
              Sendbiz is a platform that allows micro, small and medium sized
              companies to present information, jobs , products / service and
              events, they offer in an ergonomic way. Worldwide, we give great
              visibility to millions of companies who can promote themselves,
              recruit , sell their products / services.
            </div>
          </div>
          <div className="contact-div">
            <div className="contact-head">Overview</div>
            <div className="contact-subtext">
              <video
                className="videoContainer"
                controls
                poster={sendbizOverViewCover}
              >
                <source
                  src={
                    'https://sendbizbucket.s3.eu-west-3.amazonaws.com/sendbizOverview111.mp4'
                  }
                  type="video/mp4"
                />
                Your browser does not support this video.
              </video>
            </div>
            <div className="contact-div">
              <div className="contact-head">Contact Us</div>
            </div>
            <Form onFinish={contactUs}>
              <Row>
                <Col span={10} offset={7} style={{ paddingLeft: '20px' }}>
                  <Form.Item name="email" type="email">
                    <Input
                      className="landing-input-fields contactus-input"
                      placeholder="Enter Email Id"
                      rules={[
                        {
                          required: true,
                          message: 'Required Field!',
                          type: 'email',
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item name="agreeCheck">
                    <Checkbox onChange={() => setAgreeCheck(!agreeCheck)}>
                      I agree to receive communications regarding Sendbiz
                      informations, products, services, and events. By
                      submitting, you agree to allow Sendbiz to store and
                      process your personal data as described in our Privacy
                      Policy.
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={4} offset={10} style={{ paddingLeft: '30px' }}>
                  <Button
                    className="landing-signup-btn mt-3 contactus-input-btn"
                    htmlType="submit"
                  >
                    CONTACT US
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Landing;
