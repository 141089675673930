import { EntityActionTypes } from '../Actions/entityAction';
const defaultState = {
    jobData: null,
};
const entityReducer = (state = { ...defaultState }, action) => {
    switch (action.type) {
        case EntityActionTypes.GET_COMPANY_JOBS:
            return {
                ...state,
                jobData: action.jobData,
            };
        default:
            return { ...state };
    }
};

export default entityReducer;