import React, { Component } from 'react';
import './signup.css';
import axios from 'axios';
import APIManager from '../../APIManager/index';
import { Formik } from 'formik';
import { ReloadOutlined } from '@ant-design/icons';
import * as EmailValidator from 'email-validator'; // used when validating with a self-implemented approach
import * as Yup from 'yup'; // used when validating with a pre-built solution
import { message, Input, Button, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { Checkbox } from 'antd';
import { withRouter } from 'react-router-dom';

class Candidatesignup extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
    this.handleInput = this.handleInput.bind(this);
    this.checkCaptcha = this.checkCaptcha.bind(this);
    this.state = {
      password1validation: false,
      password2validation: false,
      termsandcondition: false,
      formIsValid: true,
      captcha: '',
    };
  }
  componentDidMount() {
    this.setCaptcha();
  }
  handleInput(event) {
    this.setState({ value: event.target.value });
  }
  checkCaptcha(event) {
    if (event !== this.state.captcha) {
      window.alert('Invalid Captcha!');
      return false;
    } else {
      return true;
    }
  }
  onSubmit = (e, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);
      if (this.checkCaptcha(this.state.value)) {
        this.finalSubmit(e);
      }
    }, 500);
  };
  GenerateCaptcha = () => {
    var chr1 = Math.ceil(Math.random() * 10) + '';
    var chr2 = Math.ceil(Math.random() * 10) + '';
    var chr3 = Math.ceil(Math.random() * 10) + '';

    var str = new Array(4).join().replace(/(.|$)/g, function () {
      return ((Math.random() * 36) | 0)
        .toString(36)
        [Math.random() < 0.5 ? 'toString' : 'toUpperCase']();
    });
    var captchaCode = str + chr1 + ' ' + chr2 + ' ' + chr3;

    return this.removeSpaces(captchaCode);
  };
  removeSpaces = (data) => {
    return data.split(' ').join('');
  };
  setCaptcha = () => {
    this.setState({ captcha: this.GenerateCaptcha() });
  };
  finalSubmit(data) {
    const signupCredentials = {
      email: data.email,
      password: data.password,
      confirm_password: data.confirm_password,
      user_type: this.props.signup_type,
      can_detail: {
        first_name: data.first_name,
        last_name: data.last_name,
      },
    };

    APIManager.Signup(signupCredentials)
      .then((response) => {
        if (response.data.isSuccess) {
          const authToken = response.data.token;
          const userType = response.data.data.user.user_type;
          var ls = require('local-storage');
          // ls.set('token', authToken);
          // ls.set('user_type', userType);
          const candidateEmail = response.data.data.user.email;
          const firstName = response.data.data.candidate.can_detail.first_name;
          const lastName = response.data.data.candidate.can_detail.last_name;
          const fullName = `${firstName} ${lastName}`;
          // ls.set('email', candidateEmail);
          // ls.set('name', fullName);
          message.info('User added successfully');
          this.props.history.push('/login');
        } else {
          message.info(response.data.message);
        }
      })
      .catch((err, response) => {
        message.error('Wrong Credentials or something went wrong.');
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirm_password: '',
          first_name: '',
          last_name: '',
          captcha_value: '',
          password: '',
          confirm_password: '',
          termsandcondition: false,
        }}
        onSubmit={this.onSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required('email is required.'),
          password: Yup.string().required('password is required.'),
          confirm_password: Yup.string()
            .required('confirm password is required.')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
          first_name: Yup.string().trim().required('first name is required'),
          last_name: Yup.string().trim().required('last name is required'),
          //   captcha_value:Yup.string().oneOf([this.state.captcha],'captcha does not match'),
          termsandcondition: Yup.bool().oneOf(
            [true],
            'Accept Terms & Conditions is required'
          ),
        })}
      >
        {(formikProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
          } = formikProps;
          return (
            <>
              <form onSubmit={formikProps.handleSubmit}>
                <div className="form-group mb-signup-1">
                  <label className="you_are_cls" htmlFor="exampleInputFullName">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    className="form-control input-font login-form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="CandidateFirstName"
                    placeholder="Enter first name"
                  />
                  {touched.first_name && errors.first_name && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.first_name}
                    </div>
                  )}
                </div>
                <div className="form-group mb-signup-1">
                  <label className="you_are_cls" htmlFor="exampleInputLastName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    className="form-control input-font login-form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="exampleInputLastName"
                    aria-describedby="fullnameHelp"
                    placeholder="Enter last name"
                    required
                  />
                  {touched.last_name && errors.last_name && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.last_name}
                    </div>
                  )}
                </div>
                <div className="form-group mb-signup-1">
                  <label className="you_are_cls" htmlFor="exampleInputEmail1">
                    Email ID
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control input-font login-form-control"
                    onChange={handleChange}
                    id="exampleInputEmail1"
                    aria-describedby="email1Help"
                    placeholder="Enter Email ID"
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className="form-group mb-signup-1">
                  <label
                    className="you_are_cls"
                    htmlFor="exampleInputPeoplePassword"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    autocomplete="new-password"
                    className="form-control input-font login-form-control"
                    onChange={handleChange}
                    id="exampleInputPeoplePassword"
                    aria-describedby="password1Help"
                    placeholder="Enter your password"
                    minLength="8"
                    onBlur={handleBlur}
                  />
                  {touched.password && errors.password && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.password}
                    </div>
                  )}
                  {/*<span className="help-block"></span>*/}
                </div>

                <div className="form-group mb-signup-1">
                  <label className="you_are_cls" htmlFor="confirmPassword">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirm_password"
                    className="form-control input-font login-form-control"
                    id="confirmPassword"
                    onBlur={handleBlur}
                    placeholder="Re-Enter your password"
                    onChange={handleChange}
                  />
                  {touched.confirm_password && errors.confirm_password && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.confirm_password}
                    </div>
                  )}
                </div>
                <div>
                  <div style={{ display: 'flex', marginTop: '61px' }}>
                    <div
                      style={{
                        width: 250,
                        backgroundColor: 'rgb(241, 243, 246)',
                        borderRadius: '11px',
                        border: 'ridge',
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <i
                        style={{
                          fontSize: 'large',
                          fontFamily: 'cursive',
                          userSelect: 'none',
                          padding: '6px',
                        }}
                      >
                        {this.state.captcha}
                      </i>
                    </div>
                    <div style={{ marginLeft: '2vh' }}>
                      <Tooltip title="Reload">
                        <div
                          style={{
                            border: '1px solid black',
                            borderRadius: '5px',
                            marginTop: '4px',
                          }}
                        >
                          <Button
                            className="button1"
                            // type="primary"
                            shape="circle"
                            style={{ border: 'white' }}
                            onClick={() => this.setCaptcha()}
                          >
                            <ReloadOutlined />
                          </Button>
                        </div>
                      </Tooltip>
                    </div>
                  </div>

                  <Input
                    placeholder="Enter Captcha"
                    name="captcha_value"
                    //   error={captcha_error}
                    //   id={!captcha_error ? 'outlined-input' : 'outlined-error'}
                    //   value={inputValue}
                    onChange={this.handleInput}
                    style={{
                      width: '250px',
                      height: '6vh',
                      borderRadius: '11px',
                      marginTop: '2vh',
                      marginBottom: '25px',
                    }}
                  />
                </div>
                <div
                  className="form-check mb-signup-1"
                  style={{ paddingLeft: '0' }}
                >
                  <Checkbox
                    name="termsandcondition"
                    onBlur={handleBlur}
                    checked={values.termsandcondition}
                    onChange={handleChange}
                    value={values.termsandcondition}
                  >
                    <label
                      className="input_label_profile form-check-label-2"
                      htmlFor="exampleCheck1"
                    >
                      &nbsp;&nbsp;I have read and accepted the terms and
                      conditions published by sendbiz.com. &nbsp;&nbsp;
                      <small
                        className="cursor terms-link"
                        onClick={() =>
                          this.props.history.push('./terms-and-conditions')
                        }
                      >
                        Read all
                      </small>
                    </label>
                  </Checkbox>

                  {touched.termsandcondition && errors.termsandcondition && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.termsandcondition}
                    </div>
                  )}
                </div>

                <div className="cent_button">
                  <button
                    type="submit"
                    className="btn btn-dark login-button-font login-btn"
                  >
                    SIGNUP
                  </button>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    );
  }
}
const mapStateToProps = ({ AuthReducer: { user } }) => ({ user });
export default withRouter(connect(mapStateToProps)(Candidatesignup));
