import React, { useState, useEffect } from 'react';
import SponsoredListCard from './sponsoredListCard';
import APIManager from '../../APIManager';
import { Spin } from 'antd';
import NoDataFound from '../../PageNotFound/NoData';
import styles from './sponsoredProducts.module.css';
const SponsoredProducts = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    APIManager.getSponsoredProducts()
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);
  return (
    <div className={styles.productTab}>
      {loading ? (
        <Spin />
      ) : (
        <div>
          {!data.length ? (
            <NoDataFound />
          ) : (
            data?.map(
              ({
                name,
                sponsoredBy,
                sponsoredOn,
                sponsorExpiresAt,
                totalViewsCount,
                initialBudget,
                remaningBudget,
                media,
                serviceStatus,
              }) => (
                <SponsoredListCard
                  name={name}
                  sponsoredBy={sponsoredBy}
                  sponsoredOn={sponsoredOn}
                  sponsorExpiresAt={sponsorExpiresAt}
                  totalViewsCount={totalViewsCount}
                  initialBudget={initialBudget}
                  remaningBudget={remaningBudget}
                  imageUrl={media[0]?.fileName}
                  serviceStatus={serviceStatus}
                />
              )
            )
          )}
        </div>
      )}
    </div>
  );
};

export default SponsoredProducts;
