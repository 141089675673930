import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import moment from 'moment';
import { Row, Col, Input, Form, DatePicker, Button, message, Divider, Space, Modal } from 'antd';
import './edit_company_profile.css';
import styles from './edit_company_profile.module.css';
import { dispatchAction } from '../../Redux/Store/index';
import { updateUser } from '../../Redux/Actions/AuthAction';
import { Select } from 'antd';
import APIManager from '../../APIManager/index';
import { PlusCircleOutlined } from '@ant-design/icons';
const { TextArea } = Input;
let formButton = null;

class CompanyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyCategory:
        this.props &&
        typeof this.props.initialdata !== 'undefined' &&
        this.props.initialdata.comp_category
          ? this.props.initialdata.comp_category?.map((x) => ({
              label: x ? x.categoryName : '',
              value: x ? x._id : '',
              key: x ? x._id : '',
            }))
          : [],

      founding_year: this.props.initialdata.founding_year,
      categoriesSelected: this.props.initialdata.comp_category,
      loading: false,
      pageNumber: null,
      search: '',
    };
  }
  componentDidMount() {
    this.getCategories();
  }
  onSubmit = (values, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);
      values['comp_category'] = this.state.categoriesSelected;
      values['founding_year'] = this.state.founding_year;
      this.props.setdata(values);
    }, 500);
  };
  handleCategorySearch = (value) => {
    this.setState(
      {
        search: value,
        companyCategory: [],
      },
      this.getCategories(1, value)
    );
  };
  onScroll = (event) => {
    var target = event.target;
    if (
      !this.state.loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = this.state.pageNumber + 1;
      this.getCategories(page, this.state.search);
    }
  };
  getCategories = (pageNumber = 1, search = '') => {
    this.setState({
      pageNumber: pageNumber,
    });
    let options = [];
    APIManager.allServiceCategories(pageNumber, search).then((resp) => {
      if (resp) {
        const opt = resp.data.data;
        opt.forEach((data) => {
          const suggestionExist = this.state.companyCategory.find(
            (x) => x.key === data._id
          );
          if (typeof suggestionExist === 'undefined') {
            options.push({
              label: data ? data.categoryName : '',
              value: data ? data._id : '',
              key: data._id,
            });
          }
        });
        if (options.length > 0) {
          this.setState({
            companyCategory: [...this.state.companyCategory, ...options],
          });
        }
      }
    });
  };
  clickformButton = () => {
    formButton.click();
  };
  handleFoundingChange = (date, dateString) => {
    this.setState({ founding_year: dateString });
  };
  handleSelectChange = (value) => {
    this.setState({ categoriesSelected: value });
  };
  setAddCategory = (value) => {
    this.setState({
      addCategory: value,
    })
  }
  proceedToAddCategory = async(values) => {
    if(!values.category && values?.category?.length === 0){
      message.error('Please add value of category');
    } else {
      await APIManager.createEntityCategory({type:'service',categoryName: values.category});
        this.setAddCategory(!this.state.addCategory);
    }
  }
  saveUserData = async (values) => {
    try {
      let data1 = {};
      console.log('kmkm', values);
      const date = new Date(values?.founding_year);
      values.founding_year = date.getFullYear();
      data1['comp_info'] = values;
      const response = await APIManager.companyInfoSubmit(data1);
      var ls = require('local-storage');
      let old_data = ls.get('persist:root');
      if (old_data !== undefined) {
        old_data = JSON.parse(old_data.AuthReducer);
        old_data.user.data.data = response.data.data;
        let new_data = {};
        new_data = old_data.user;
        dispatchAction(updateUser(new_data));
      }
      message.success('Contact info updated successfully.');
    } catch {
      message.error('Error updating user profile.');
    }
  };

  render() {
    const comp_data = this.props.initialdata;
    let data = {};
    data['company_id'] = comp_data ? comp_data.company_id : '';
    data['comp_name'] = comp_data ? comp_data.comp_name : '';
    data['bus_name'] = comp_data ? comp_data.bus_name : '';
    data['noOf_emp'] = comp_data ? comp_data.noOf_emp : '';
    data['comp_info'] = comp_data ? comp_data.comp_info : '';
    data['comp_category'] = comp_data
      ? comp_data.comp_category?.map((x) => x._id)
      : [];
    data['age'] = comp_data ? comp_data.age : '';
    let founding_year = comp_data ? comp_data.founding_year : '';
    return (
      <>
                      <div style={{display: 'flex',justifyContent: 'center'}}>
    <Modal
        title="Add skills"
        open={this.state.addCategory}
        onCancel={() => this.setAddCategory(!this.state.addCategory)}
        className="add_skill"
        centered
        footer={false}
      >
        <Form
          onFinish={this.proceedToAddCategory}
        >
        <Form.Item
        name="category"
        label="Add Your category"
        >
          <Input className={styles.skillAdd} placeholder="Enter your category" />
        </Form.Item>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Form.Item>
          <Button type="text" onClick={() => this.setAddCategory(false)}>
            Close
          </Button>
          <Button type="primary" htmlType="submit">
            Proceed
          </Button>
          </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
        <Form
          className={styles.mainContainer}
          onFinish={this.saveUserData}
          layout="vertical"
          initialValues={data}
        >
          <Row>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-right ${styles.fromPaddingRight}`}
            >
              <Form.Item
                name="company_id"
                label={
                  <label className="input_label_profile">Company ID</label>
                }
                rules={[
                  {
                    required: true,
                    message: 'Required Field!',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  className={`input-field-custom-type-1-left-disabled ${styles.inputBoxW100}`}
                  disabled
                  style={{ backgroundColor: '#ffffff' }}
                />
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-left ${styles.fromPaddingLeft}`}
            >
              <Form.Item
                name="comp_name"
                label={
                  <label className="input_label_profile">Company Name</label>
                }
                rules={[
                  {
                    required: true,
                    message: 'Required Field!',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  className={`input-field-custom-type-1-left-disabled ${styles.inputBoxW100}`}
                  disabled
                  style={{ backgroundColor: '#ffffff', width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-right ${styles.fromPaddingRight}`}
            >
              <Form.Item
                name="bus_name"
                label={
                  <label className="input_label_profile">Business Name</label>
                }
                rules={[
                  {
                    required: true,
                    message: 'Required Field!',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="bus_name"
                  className={`input-field-custom-type-1-left ${styles.inputBoxW100}`}
                />
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-left ${styles.fromPaddingLeft}`}
            >
              <Form.Item
                name="comp_category"
                label={
                  <label className="input_label_profile">
                    Company Category
                  </label>
                }
              >
                {/* <Select
                  mode="multiple"
                  allowClear
                  maxTagCount={2}
                  removeIcon={
                    <img
                      style={{ width: 14, height: 14 }}
                      src={process.env.PUBLIC_URL + '/cancel.png'}
                      alt=""
                    />
                  }
                  style={{ width: '100%' }}
                  optionLabelProp="label"
                  onPopupScroll={this.onScroll}
                  filterOption={false}
                  placeholder="Please select"
                  className="input-label-field-custom-select-type-2"
                  onSearch={this.handleCategorySearch}
                  onChange={this.handleSelectChange}
                  options={this.state.companyCategory}
                /> */}
                  <Select
                  mode="multiple"
                  allowClear
                  removeIcon={
                    <img
                      style={{ width: 14, height: 14 }}
                      src={process.env.PUBLIC_URL + '/cancel.png'}
                      alt=""
                    />
                  }
                  // defaultValue={editData?.skills?.map((e)=> e._id)}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Space style={{ display: 'flex', justifyContent:'center', width: '100%'}}>
                        <Button type="text" onClick={()=>{this.setAddCategory(!this.state.addCategory)}}>
                        <PlusCircleOutlined style={{fontSize: '20px', opacity: '0.5'}} />
                        </Button>
                      </Space>
                    </>
                  )}
                  maxTagCount={1}
                  style={{ width: '100%' }}
                  filterOption={false}
                  placeholder="Select Category"
                  className="input-label-field-custom-select-type-2"
                  onPopupScroll={this.onScroll}
                  onSearch={this.handleCategorySearch}
                  onChange={this.handleSelectChange}
                  options={this.state.companyCategory}
              > 
              </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              sm={8}
              xs={24}
              className={`form-padding-left-3 ${styles.fromPaddingLeft} ${styles.fromPaddingRight}`}
            >
              <Form.Item
                name="noOf_emp"
                label={
                  <label className="input_label_profile">
                    {' '}
                    No. of Employees
                  </label>
                }
              >
                <Input
                  type="number"
                  className={`input-field-custom-type-1-left ${styles.inputBoxW100}`}
                />
              </Form.Item>
            </Col>
            <Col
              sm={8}
              xs={24}
              className={`form-padding-left-3 form-padding-left-2 ${styles.fromPaddingLeft} ${styles.fromPaddingRight}`}
            >
              <Form.Item
                name="founding_year"
                label={
                  <label className="input_label_profile">Founding Year</label>
                }
              >
                <DatePicker
                  size="large"
                  suffixIcon={
                    <img
                      src={process.env.PUBLIC_URL + '/calendar-date.svg'}
                      alt=""
                    />
                  }
                  className={`w-100 input-field-custom-type-1-left ${styles.inputBoxW100}`}
                  defaultValue={
                    founding_year ? moment(founding_year, 'YYYY') : ''
                  }
                  format={'YYYY'}
                  picker="year"
                />
              </Form.Item>
            </Col>
            <Col
              sm={8}
              xs={24}
              className={`form-padding-left-2 ${styles.fromPaddingLeft}`}
            >
              <Form.Item
                name="age"
                label={<label className="input_label_profile">Avg Age</label>}
              >
                <Input
                  type="number"
                  className="input-field-custom-type-1-left"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="text-area-padding">
              <Form.Item
                name="comp_info"
                label={
                  <label className="input_label_profile">
                    Company Information
                  </label>
                }
                rules={[
                  {
                    required: true,
                    message: 'Required Field!',
                    whitespace: true,
                  },
                ]}
              >
                <TextArea
                  className="input-font form-control profile-form-control"
                  style={{ width: '100%' }}
                  rows="5"
                  required={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} className={styles.buttonCol}>
              <Button className={styles.button} htmlType="submit">
                Save
              </Button>
            </Col>
          </Row>
          <button
            style={{ opacity: 0 }}
            type={'submit'}
            ref={(e) => {
              formButton = e;
            }}
          >
            ghfghfgh
          </button>
        </Form>
      </>
    );
  }
}

export default CompanyInfo;
