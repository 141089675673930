import React, { Component } from 'react';
import PopupModal from './passwordmodel.js';
import ProfileForm from './candidate_profile_form.js';
import userIcon from '../asset/user.svg';
import { connect } from 'react-redux';
import { Spin, Row, Col, Button } from 'antd';
import styles from './profile.module.css';
import APIManager from '../../APIManager';
import ProfilePhoto from './candidate_profile';
import HELPERS from '../../APIManager/helper';
import MenuDropDown from './MenuDropDown.jsx';

class Profile extends Component {
  infoElement = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      formIsValid: false,
      uploadedImage: userIcon,
      upload_image: '',
      upload_image_name: '',
      isImageUpload: false,
      current_location: '',
    };
  }
  componentDidMount() {
    HELPERS.getLatLong();
    this.getcurrentLocation();
    APIManager.companyInfo();
    // this.getLatLong()
  }
  handleProfileSubmit = () => {
    this.infoElement.current.handledClick();
  };
  getcurrentLocation = () => {
    var ls = require('local-storage');
    let lat = ls.get('lat');
    let long = ls.get('long');
    HELPERS.getLocation(lat, long).then((response) => {
      if (response.data.results.length > 0) {
        let location = response?.data?.results[9]?.formatted_address;
        this.setState({ current_location: location });
      }
    });
  };
  handleChangePassword = (e) => {
    this.setState({ modalShow: true });
  };
  imageData = (newFile) => {
    this.setState({ upload_image: newFile, isImageUpload: true });
  };
  render() {
    let { modalShow } = this.state.modalShow;
    let loader = true;
    let uploadedImage = this.state.uploadedImage;
    let data = {};
    loader = this.props.isloading;
    data = this.props.infodata;
    if (!loader) {
      if (data.data.data.can_detail.profile !== '') {
        let url = data.data.data.can_detail.profile;
        uploadedImage =
          'https://sendbizbucket.s3.eu-west-3.amazonaws.com/' + url;
      }
    }
    // }
    return (
      <>
        {/*************  for Dropdown menu *************/}
        <Row>
          <Col span={24}>
            <MenuDropDown name={'Profile'} />
          </Col>
        </Row>
        <div className={styles.profileMainContainer}>
          {loader ? (
            <Spin className="d-flex justify-content-center pt-3" />
          ) : (
            <>
              {this.state.modalShow ? (
                <PopupModal
                  show={this.state.modalShow}
                  onHide={() => this.setState({ modalShow: false })}
                  mail={this.props.infodata.data.user.email}
                />
              ) : null}

              <Row>
                <Col span={12} className={styles.profileHeading}>
                  <h6 className="candidate_heading">Profile</h6>
                </Col>
                <Col sm={12} xs={0} className="text-right">
                  <button
                    type="button"
                    onClick={this.handleProfileSubmit}
                    className="btn btn-dark bold-family btn-save-font cursor"
                  >
                    Save
                  </button>
                </Col>
              </Row>
              <Row
                span={24}
                justify="space-between"
                align="middle"
                className={styles.profileContainer}
              >
                <Col sm={8} xs={24} className={styles.candidateProfile}>
                  <ProfilePhoto
                    imageChange={this.imageData}
                    uploadedImage={uploadedImage}
                  />
                </Col>
                <Col
                  sm={16}
                  xs={24}
                  align="right"
                  className={styles.viewPublicProfile}
                >
                  <ul
                    className={`sigunp-logo-with-name ${styles.changeButtonOnSmallSceen}`}
                    style={{ display: 'inline-flex' }}
                  >
                    <li>
                      <button
                        className="edit-password edit-password-active"
                        onClick={() => this.setState({ modalShow: true })}
                      >
                        Change Password
                      </button>
                    </li>
                  </ul>
                  <div className="custom-file view-profile">
                    <label
                      className="custom-file-label-2"
                      onClick={() =>
                        this.props.history.push({
                          pathname: '/visit-profile',
                        })
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      View public profile
                    </label>
                  </div>
                </Col>
              </Row>
              <ProfileForm
                ref={this.infoElement}
                location={this.state.current_location}
                isImage={this.state.isImageUpload}
                image={this.state.upload_image}
                image_name={this.state.upload_image_name}
                data={data}
              />
              <Row>
                <Col sm={0} xs={24}>
                  <Button
                    style={{ width: '100%', marginTop: '-60px' }}
                    type="button"
                    onClick={this.handleProfileSubmit}
                    className="btn btn-dark bold-family btn-save-font cursor"
                  >
                    Save
                  </Button>
                </Col>
                <Col
                  sm={0}
                  xs={24}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <button
                    className={styles.changePasswordButton}
                    onClick={() => this.setState({ modalShow: true })}
                  >
                    Change Password
                  </button>
                </Col>
              </Row>
            </>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.AuthReducer.user,
  infodata: state.companyInfoReducer.data,
  isloading: state.companyInfoReducer.isloading,
  authloading: state.AuthReducer.isloading,
});

export default connect(mapStateToProps)(Profile);
