import React, { Component } from 'react';
import AllResumeCards from './All_applied_resume_Card';
import APIManager from '../../APIManager/index';
import { connect } from 'react-redux';

class Resume extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount(id) {
    const resumeId = this.props.location.state.resumeId;
    APIManager.resumeGetOneAppliedJobForCompany(resumeId);
  }

  render() {
    const jobTitle = this.props.appliedJobData
      ? this.props.appliedJobData.data[0].applied_for_job.title
      : '';
    return (
      <div className="applied-job-paddingright">
        <div className="row">
          <p className="resume-1">{`Resume : ${jobTitle.charAt(0).toUpperCase() + jobTitle.slice(1)
            }`}</p>
        </div>
        <AllResumeCards />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appliedJobData: state.jobManagementReducer.appliedJobData,
    isloading: state.jobManagementReducer.isloading,
  };
};
export default connect(mapStateToProps)(Resume);
