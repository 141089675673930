import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Col, Row, Button, Image, Carousel, Tooltip } from 'antd';
import VideoSection from '../videoSection/VideoSection';
import './apply_job.css';
import styles from './apply_job.module.css';
import Footer from '../footer/footer';
import ApplyJobModal from './applyJobModal.js';
import APIManager from '../../APIManager/index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Spin, message } from 'antd';
import { fileUrl, ImageUrl } from '../../Shared/imageUrlPath';
import Header from '../header/header';
import cardDefault from '../asset/card.svg';
import coverDefault from '../asset/edit-company.svg';
import NewsComp from '../screensOfTopNav/Services/news_comp';
import { CURRENCY } from '../constants/currency';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

class ApplyJob extends Component {
  state = {
    applymodal: false,
    already_applied: true,
    // id: this.props.location.state.jobId,
    spinner: false,
    isIntersted: '',
    isLoading: false,
    categoryJob: [],
  };
  handleClick = () => {
    var ls = require('local-storage');
    const authToken = ls.get('token');
    if (authToken) {
      const { id } = this.props.match.params;
      localStorage.setItem('JobId', id);
      this.setState({ applymodal: true });
    } else {
      this.props.history.push('/login');
    }
  };
  componentDidMount() {
    let { id } = this.props.match.params;
    this.setState({ isLoading: true });
    APIManager.getJobDescription(id).then((resp) => {
      if (resp.status === 200)
        this.setState({
          isIntersted: resp.data.isInterested,
          isLoading: false,
        });
      console.log('resp', resp);
      if (resp.data.data.job_type === 'subcontractor') {
        this.getCategories(resp.data.data.subcontractor_category);
      }
    });
  }
  componentDidUpdate = () => {
    window.scrollTo(0, 0);
  };
  //   this.setState({
  //     isLoading: true,
  //   });
  //   var ls = require('local-storage');
  //   const authToken = ls.get('token');
  //   if (authToken) {
  //     const { id } = this.props.match.params;
  //     const param = {
  //       interestId: id,
  //     };
  //     const category = 'job';
  //     this.setState({
  //       isLoading: true,
  //     });
  //     console.log("=========>");
  //     APIManager.createInterested(category, param)
  //       .then((resp) => {
  //         if (resp.data.isSuccess) {
  //           this.setState({
  //             isIntersted: resp.data.message,
  //             isLoading: false,
  //           });
  //           if (resp.data.message) {
  //             message.success('Added to interested !');
  //           } else {
  //             message.warn('Removed from interested !');
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         this.setState({ isLoading: false });
  //         message.error('Something went wrong!');
  //       });
  //   } else {
  //     this.props.history.push('/login');
  //   }
  // };
  addFavourite = (id) => {
    let data = {
      job: id,
    };
    APIManager.submitfavouriteJobs(data)
      .then((response) => {
        if (response.data.status === 'success') {
          message.info('Job added favourite');
        }
      })
      .catch((err) => message.error('Something went wrong.'));
  };
  closeModal = () => {
    this.setState({ applymodal: false });
  };

  uploaded = (spinner) => {
    this.setState({ spinner: spinner });
    this.refreshPage();
  };
  refreshPage = () => {
    const { id } = this.props.match.params;
    APIManager.getJobDescription(id);
  };
  saveAsIntersted = () => {
    const { id } = this.props.match.params;
    const param = {
      interestId: id,
      category: 'job',
    };
    var ls = require('local-storage');
    const token = ls.get('token');
    this.setState({
      isLoading: true,
    });
    if (token) {
      APIManager.createInterested(param)
        .then((response) => {
          if (response.data.isSuccess) {
            this.setState({
              isLoading: false,
              isIntersted: response.data.message,
            });
            this.refreshPage();
          }
        })
        .catch((error) => console.log(error));
    } else {
      this.props.history.push('/login');
    }
  };

  getCategories = (categories, pageNumber = 1, search = '') => {
    console.log('Categories', categories);
    let options = [];
    APIManager.allJobCategories(pageNumber, search).then((resp) => {
      if (resp) {
        const opt = resp.data.data;
        options = opt.filter((data) =>
          categories.some((category) => category === data.id)
        );
        this.setState({
          categoryJob: options,
        });
        // console.log("Options", options);
      }
    });
  };

  render() {
    const data = this?.props?.decriptionData?.data;
    const comp_name = data?.companyDetail?.[0]?.comp_info?.comp_name;
    const bus_name = data?.companyDetail[0].comp_info.bus_name;
    const jobPostion = data?.title;
    const description = data?.description;
    const location = data?.location;
    const minSalary = data?.min_salary;
    const maxSalary = data?.max_salary;
    const subcontractor_experience = data?.subcontractor_experience;
    const experience = data?.experience.split(',');
    const RecruitmentProcess = data?.req_process;
    const qualification = data?.qualification;
    const Specialization = data?.specialization;
    const desiredCandidate = data?.desired;
    const phone = data?.companyDetail?.[0].contact_info.phone_no;
    const logo = data?.job_logo;
    const jobtype = data?.job_type;
    const cover = data?.job_cover;
    const sector = data?.sector;
    const compInfo = data?.companyDetail?.[0].comp_info.comp_info;
    const hasApplied = this.props?.decriptionData?.hasApplied;
    const social_link = data?.companyDetail?.[0].social_link;
    const title = data?.title;
    const myJob = this.props?.decriptionData?.myJob;
    const jobPayment = this.props.decriptionData
      ? data?.job_payment
      : 'fixed';
    const rate =  data?.rate?.amount;
    const currency = data?.rate?.currency;
    const compId = data?.companyDetail?.map((data) => data._id);
    const jobMedia = data?.media;
    const ImagePath = ImageUrl.imageUrlPath;

    const coverDefaultMain =
      this.props?.decriptionData?.data?.companyDetail?.at(0)?.coverImage
        ?.name !== ''
        ? ImagePath + data?.companyDetail?.at(0)?.coverImage?.name
        : coverDefault;
    const logoDefaultMain =
      this.props?.decriptionData?.data?.companyDetail?.at(0)?.logo?.name !== ''
        ? ImagePath + data?.companyDetail?.at(0)?.logo?.name
        : cardDefault;

    const job_type = this.props?.decriptionData?.data?.job_type || '';
    let user_type = JSON.parse(localStorage.getItem('user_type') || false);
    const user_type2 = JSON.parse(localStorage.getItem('user_type2') || false);
    user_type = user_type / (user_type2 * 99);
    let showApplyNow = true;
    if (user_type === 1 && hasApplied === true) {
      showApplyNow = false;
    }
    return (
      <div className="responsive-div">
        <Helmet>
          <title>{`${jobPostion}-${comp_name}-${job_type}-${location}-Sendbiz`}</title>
          <meta
            property="og:title"
            content={`${jobPostion}-${comp_name}-${job_type}-${location}-Sendbiz`}
          />
          <meta
            name="twitter:card"
            content={`${jobPostion}-${comp_name}-${job_type}-${location}-Sendbiz`}
          />

          <meta name="description" content={compInfo} />
          <meta property="og:description" content={compInfo} />
          <meta name="twitter:description" content={compInfo} />
        </Helmet>
        {this.state.applymodal ? (
          <ApplyJobModal
            isClose={this.closeModal}
            comp_name={comp_name}
            id={this.props.match.params.id}
            uploaded={this.uploaded}
            title={'Apply Job'}
            refreshPage={this.refreshPage}
            isOpen={this.state.applymodal}
            jobtype={jobtype}
            job_title={title}
            currency={currency}
          />
        ) : null}
        <Header />
        <Row>
          <div className={'d-flex justify-content-center w-100'}>
            {this.state.isLoading && <Spin />}
          </div>
          <img
            className={`apply-job-image-height ${styles.bannerImage}`}
            alt="img"
            src={cover ? ImageUrl.imageUrlPath + cover : coverDefaultMain}
          />
        </Row>
        <Row
          className={`site-layout-background main-left-right ${styles.mainContainer}`}
        >
          <Col md={8} xs={24}>
            <div className={`apply-job-sidebar ${styles.childContainerOne}`}>
              <div className="menu-list">
                <Row>
                  <Col span={8}>
                    <img
                      src={
                        logo ? ImageUrl.imageUrlPath + logo : logoDefaultMain
                      }
                      alt="img"
                      style={{
                        width: '100%',
                        height: '100px',
                        borderRadius: '10px',
                        objectFit: 'cover',
                      }}
                    />
                  </Col>
                  <Col span={16} className="apply-custom-navbar">
                    <h5 className="apply-custom-navbar-h5-1">
                      {jobPostion?.charAt(0)?.toUpperCase() + jobPostion?.slice(1)}
                    </h5>
                    <p className="apply-custom-navbar-p-1">
                      <NavLink
                        to={`/comp-id${compId}`}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        target="_blank"
                        className=" comp-hover "
                        style={{ color: 'black', padding: '0px' }}
                      >
                        {bus_name?.charAt(0)?.toUpperCase() + bus_name?.slice(1)}
                      </NavLink>
                    </p>
                    <p className="apply-custom-navbar-p-2">{jobtype}</p>
                  </Col>
                </Row>
                <Row>
                  {jobtype !== 'subcontractor' ? (
                    <>
                      <p className="apply-job-salary-1">
                        {minSalary && maxSalary
                          ? `$${minSalary} - $ ${maxSalary}`
                          : 'Not Indicated'}
                      </p>
                      <p className="apply-job-salary-2 padding-bottom-apply-job">
                        Monthly Salary
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="apply-job-salary-1">
                        {
                          <Tooltip
                            title={this.state.categoryJob?.map((item) => (
                              <p>{item.title}, </p>
                            ))}
                          >
                            <span>
                              {this.state.categoryJob?.length &&
                                this.state.categoryJob?.[0]?.title}
                            </span>
                            <span>
                              {this.state.categoryJob?.length > 1
                                ? ` + ${this.state.categoryJob?.length - 1}`
                                : ''}
                            </span>
                          </Tooltip>
                        }
                      </p>
                      <p className="apply-job-salary-2 padding-bottom-apply-job">
                        Job Category
                      </p>
                    </>
                  )}
                  <Col
                    style={{ textAlign: 'center' }}
                    span={jobtype === 'subcontractor' ? 8 : 8}
                    className="right-border-apply-job"
                  >
                    <p className="apply-job-salary-1">
                      {jobtype === 'subcontractor'
                        ? `${
                            CURRENCY.filter((data) => data.name === currency)
                              ?.length > 0
                              ? CURRENCY.filter(
                                  (data) => data.name === currency
                                )[0].symbol
                              : ''
                          } ${rate}`
                        : sector}
                    </p>
                    <p className="apply-job-salary-2">
                      {jobtype === 'subcontractor' ? 'Rate' : 'Industry'}
                    </p>
                  </Col>
                  {jobtype === 'subcontractor' && (
                    <Col
                      style={{ textAlign: 'center' }}
                      span={8}
                      className="right-border-apply-job"
                    >
                      <p className="apply-job-salary-1">{jobPayment}</p>
                      <p className="apply-job-salary-2">Job Payment</p>
                    </Col>
                  )}
                  <Col
                    span={jobtype === 'subcontractor' ? 8 : 8}
                    style={{ textAlign: 'center' }}
                    className={
                      jobtype !== 'subcontractor'
                        ? 'right-border-apply-job'
                        : ''
                    }
                  >
                    {jobtype === 'subcontractor' ? (
                      <p className="apply-job-salary-1">
                        <Tooltip
                          title={subcontractor_experience?.map((exp) => (
                            <p>{exp}, </p>
                          ))}
                        >
                          <span>
                            {subcontractor_experience?.length &&
                              subcontractor_experience?.[0]}
                          </span>
                          <span>
                            {subcontractor_experience?.length > 1
                              ? ` + ${subcontractor_experience?.length - 1}`
                              : ''}
                          </span>
                        </Tooltip>
                      </p>
                    ) : (
                      <p className="apply-job-salary-1">
                        <Tooltip
                          title={experience?.map((exp) => (
                            <p>{exp}, </p>
                          ))}
                        >
                          <span>{experience?.length && experience?.[0]}</span>
                          <span>
                            {experience?.length > 1
                              ? ` + ${experience?.length - 1}`
                              : ''}
                          </span>
                        </Tooltip>
                      </p>
                    )}
                    <p className="apply-job-salary-2">Experience</p>
                  </Col>
                  {jobtype !== 'subcontractor' && (
                    <Col span={8} style={{ textAlign: 'center' }}>
                      <p className="apply-job-salary-1">{jobtype}</p>
                      <p className="apply-job-salary-2">Job Type</p>
                    </Col>
                  )}
                </Row>
                <Row
                  className={`padding-top-apply-job ${styles.paddingTopZero}`}
                >
                  <Col span={13} className="padding-bottom-apply-job">
                    <ul className="company-main-ul-1">
                      {social_link?.map((val, index) => (
                        <>
                          {val.tag === 'Facebook' || val.tag === 'facebook' ? (
                            <li
                              style={{ paddingRight: 15 }}
                              onClick={() => window.open(val.link, '_blank')}
                            >
                              <img
                                src={process.env.PUBLIC_URL + '/facebook.png'}
                                alt="img"
                                style={{
                                  fontSize: '30px',
                                  marginRight: '17px',
                                  cursor: 'pointer',
                                }}
                              />
                            </li>
                          ) : null}
                          {val.tag === 'Linkedin' ||
                          val.tag === 'linkedin' ||
                          val.tag === 'LinkedIn' ? (
                            <li
                              style={{ paddingRight: 15 }}
                              onClick={() => window.open(val.link, '_blank')}
                            >
                              <img
                                src={process.env.PUBLIC_URL + '/linkedin.png'}
                                alt="img"
                                style={{
                                  fontSize: '30px',
                                  marginRight: '17px',
                                  cursor: 'pointer',
                                }}
                              />
                            </li>
                          ) : null}
                          {val.tag === 'Twitter' || val.tag === 'twitter' ? (
                            <li
                              style={{ paddingRight: 15 }}
                              onClick={() => window.open(val.link, '_blank')}
                            >
                              <img
                                src={process.env.PUBLIC_URL + '/twitter.png'}
                                alt="img"
                                style={{
                                  fontSize: '30px',
                                  marginRight: '17px',
                                  cursor: 'pointer',
                                }}
                              />
                            </li>
                          ) : null}
                        </>
                      ))}
                    </ul>
                  </Col>
                  <Col span={13}></Col>
                  {(jobtype !== 'subcontractor' && user_type === 2) ||
                  (jobtype === 'subcontractor' && user_type !== 2) ? (
                    ''
                  ) : (
                    <>
                      {!myJob && (
                        <div
                          className="apply-job-btn-cls"
                          style={{ width: '50%' }}
                        >
                          {showApplyNow === false ? (
                            <Button
                              className={`btn btn-dark bold-family btn-save-font cursor ${styles.btnW100}`}
                            >
                              Applied
                            </Button>
                          ) : (
                            <Button
                              style={{ width: '100%', marginRight: '10px' }}
                              className="btn btn-dark bold-family btn-save-font cursor"
                              onClick={this.handleClick}
                            >
                              Apply Now
                            </Button>
                          )}
                        </div>
                      )}
                      {!myJob && (
                        <div style={{ width: '45%', marginLeft: '5%' }}>
                          <button
                            style={{
                              height: '100%',
                              borderRadius: '5px',
                              width: '100%',
                              fontWeight: 'bold',
                            }}
                            className="intrested-button"
                            onClick={this.saveAsIntersted}
                          >
                            {this.state.isLoading && 'saving...'}

                            {!this.state.isLoading &&
                              (this.state.isIntersted
                                ? 'Not Interested'
                                : 'Interested')}
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </Row>
              </div>
            </div>
          </Col>
          <Col md={16} xs={24}>
            <div className={`apply-job-sidebar ${styles.childContainerTwo}`}>
              <h5 className="job-detail-description mt-1">Job Description</h5>
              <p
                className="job-detail-description-para"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {description
                  ? description?.charAt(0)?.toUpperCase() + description?.slice(1)
                  : ''}
              </p>
              <Carousel
                afterChange={this.onChange}
                className="event-carousel mt-5"
                autoplay
                arrows
              >
                {jobMedia &&
                  jobMedia
                    ?.filter((data) => data.fileType.includes('image'))
                    ?.map((data) => (
                      <div>
                        <Image
                          src={
                            data.fileName
                              ? fileUrl.fileUrlPath + data.fileName
                              : cardDefault
                          }
                          alt="event"
                          className="event-carousel-img"
                          width={'100%'}
                          height={350}
                        ></Image>
                      </div>
                    ))}
              </Carousel>
              <br />
              {jobtype !== 'subcontractor' && (
                <h5 className="job-detail-description mt-5">
                  Recruitment Process
                </h5>
              )}
              {jobtype !== 'subcontractor' && (
                <p className="job-detail-description-para">
                  {RecruitmentProcess
                    ? RecruitmentProcess?.charAt(0)?.toUpperCase() +
                      RecruitmentProcess?.slice(1)
                    : ''}
                </p>
              )}
              <h5 className="job-detail-description mt-5">Specialization</h5>
              <p
                className="job-detail-description-para"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {Specialization
                  ? Specialization?.charAt(0)?.toUpperCase() +
                    Specialization?.slice(1)
                  : ''}
              </p>
              {jobMedia &&
                jobMedia?.filter((data) => data?.fileType?.includes('video'))
                  ?.length > 0 && (
                  <VideoSection
                    media={jobMedia?.filter((data) =>
                      data.fileType.includes('video')
                    )}
                  />
                )}
              <h5 className="job-detail-description mt-5">Requirements</h5>
              <p
                className="job-detail-description-para"
                style={{
                  marginBottom: 10,
                  opacity: '0.5',
                  font: 'normal normal 400 16px/19px Gilroy semiBold',
                }}
              ></p>
              <h5 className="job-detail-description mt-5">Qualification</h5>
              <p
                className="job-detail-description-para qualification-para"
                style={{
                  opacity: '0.5',
                  marginTop: '0',
                  font: 'normal normal 400 16px/19px Gilroy semiBold',
                }}
              >
                {qualification}
              </p>
              {jobtype !== 'subcontractor' && (
                <p
                  className="apply-job-salary-2"
                  style={{ marginBottom: '10px', opacity: '1' }}
                >
                  Desired Candidate
                </p>
              )}
              {jobtype !== 'subcontractor' && (
                <p
                  className="apply-job-salary-2 job-detail-description mt-5"
                  style={{
                    opacity: 0.5,
                    font: 'normal normal 400 16px/19px Gilroy semiBold',
                    marginBottom: 10,
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {desiredCandidate
                    ? desiredCandidate?.charAt(0)?.toUpperCase() +
                      desiredCandidate?.slice(1)
                    : ''}
                </p>
              )}
              <h5 className="job-detail-description mt-5">About the company</h5>
              <p
                className="job-detail-description-para"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {compInfo
                  ? compInfo?.charAt(0)?.toUpperCase() + compInfo?.slice(1)
                  : ''}
              </p>
              <ul
                className={`job-detail-description-ul ${styles.locationAndNumber}`}
              >
                <li style={{ paddingRight: 40 }}>
                  <img
                    src={process.env.PUBLIC_URL + '/location.svg'}
                    alt="img"
                  />
                  &nbsp;&nbsp;
                  {/* <span>{address}</span> */}
                  {data?.companyDetail
                    ? data?.companyDetail[0]?.contact_info?.city
                      ? data?.companyDetail[0]?.contact_info?.city + ', '
                      : ''
                    : ''}
                  {data?.companyDetail
                    ? data?.companyDetail[0]?.contact_info?.state
                      ? data.companyDetail[0].contact_info.state + ', '
                      : ''
                    : ''}
                  {data?.companyDetail
                    ? data?.companyDetail[0]?.contact_info?.country
                      ? data.companyDetail[0].contact_info.country
                      : ''
                    : ''}
                </li>
                <li style={{ paddingRight: 40 }}>
                  <img src={process.env.PUBLIC_URL + '/phone.svg'} alt="img" />
                  &nbsp;&nbsp;
                  <span>{phone}</span>
                </li>
              </ul>
              <p
                className="job-detail-link-company-page"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  this.props.history.push({ pathname: `/comp-id${compId}` })
                }
              >
                <img src={process.env.PUBLIC_URL + '/link.svg'} alt="img" />
                &nbsp;&nbsp;
                <span>Visit company profile</span>
              </p>
              {(jobtype !== 'subcontractor' && user_type === 2) ||
              (jobtype === 'subcontractor' && user_type !== 2) ? (
                ''
              ) : (
                <>
                  {!myJob && (
                    <div
                      className={`apply-job-btn-cls ${styles.buttonContainer}`}
                    >
                      {showApplyNow === false ? (
                        <Button
                          className={`btn btn-dark bold-family btn-save-font cursor ${styles.btnW100}`}
                        >
                          Applied
                        </Button>
                      ) : (
                        <Button
                          className={`btn btn-dark bold-family btn-save-font cursor ${styles.btnW100} ${styles.applybtnBigScreen}`}
                          onClick={this.handleClick}
                        >
                          Apply Now
                        </Button>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
        <NewsComp />
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    decriptionData: state.jobManagementReducer.decriptionData,
    isloading: state.jobManagementReducer.isloading,
  };
};
export default withRouter(connect(mapStateToProps)(ApplyJob));
