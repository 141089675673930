import React, { Component } from 'react';
import './signup.css';
import { connect } from 'react-redux';
import Candidatesignup from './candidate_signup.js';
import Companysignup from './company_signup.js';
import sendbiz_logo from '../asset/sendbiz_logo.png';
import { Col, Row } from 'antd';
import { fileUrl } from '../../Shared/imageUrlPath';
class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person: true,
      CompanyName: '',
      CompanyID: '',
      CompanyAddress: '',
      CompanyEmail: '',
      CompanyCity: '',
      CompanyZip: '',
      Password: '',
      button_value: true,
      signup_by: 'Candidate',
      CandidateFirstName: '',
      CandidateLastName: '',
      fields: {},
      errors: {},
      ConfirmPassword: '',
      CandidateEmail: '',
      password1validation: false,
      password2validation: false,
      termsandcondition: false,
      formIsValid: true,
    };
  }
  gotoLogin() {
    this.props.history.push('/');
  }
  componentDidMount() {
    if (
      this.props.location.state !== null &&
      this.props.location.state !== undefined
    ) {
      this.setState({
        person: this.props.location.state.person,
        signup_by: this.props.location.state.signup_by,
        button_value: true,
      });
    }
    var ls = require('local-storage');
    const loginToken = ls.get('token');
    if (loginToken) {
      this.props.history.push('/');
    }
    if (
      this.props?.location?.state !== null &&
      this.props?.location?.state?.claim_company
    ) {
      this.setState({ person: true, signup_by: 'Company', button_value: true });
    }
  }
  onSubmit = (e, { setSubmitting }) => {
    setTimeout(() => {
      setSubmitting(false);
      this.CandidateResumeUpload(e);
    }, 500);
  };

  switchCompany(data) {
    if (data === 'company') {
      this.setState({ person: !this.state.person, signup_by: 'Company', button_value: true });
    } else if (data === 'candidate') {
      this.setState({
        person: false,
        signup_by: 'Candidate',
        button_value: true,
      });
    }
  }
  render() {
    return (
        <Row >
          <Col xs={24} sm={12} style={{display:'flex',justifyContent:'center',marginTop: '20px'}}>
            <Row style={{width: '80%'}}>
            <Col
            xs={24}
              className="go-back"
              onClick={() => {
                this.props.history.push('/');
              }}
            >
              <img
                className="cursor"
                src={process.env.PUBLIC_URL + '/go-back-1.png'}
                alt=""
              />
            </Col>
            <Col xs={24}>
              <img
                src={process.env.PUBLIC_URL + sendbiz_logo}
                alt="logo"
                style={{
                  width: '120px',
                  height: '50px',
                }}
              />
            </Col>
            <Col xs={24}>
            <h6
              style={{
                fontSize: 26,
                fontWeight: 'bolder',
                marginTop: 30,
                fontFamily: 'Gilroy Bold',
              }}
              className="welcome_msg mb-signup-1"
            >
              Welcome !
            </h6>
            </Col>
            <Col xs={24}><p>Enter the following details to create Account</p> </Col>
            <Col xs={24} className="row mb-signup-1 youare">
              <p className="w-100 you_are_cls">You are</p>
              <div className="col-6 pl-0">
                <div className="form-check form-check-inline">
                  {this.state.person ? (
                    <img
                      onClick={this.switchCompany.bind(this, 'company')}
                      style={{ width: '18px', borderRadius: '2px' }}
                      src={process.env.PUBLIC_URL + '/companyCheckRed.png'}
                      alt=""
                    />
                  ) : (
                    <img
                      onClick={this.switchCompany.bind(this, 'company')}
                      style={{ width: '18px' ,borderRadius: '2px'}}
                      src={process.env.PUBLIC_URL + '/companyCheckWhite.png'}
                      alt=""
                    />
                  )}
                    <span style={{marginLeft: '15px', fontFamily:'Gilroy Bold', fontWeight:'800'}}> Company / Freelancer</span>
                </div>
              </div>
              <div className="col-6 ">
                <div className="form-check form-check-inline">
                  {this.state.person ? (
                    <img
                      onClick={this.switchCompany.bind(this, 'candidate')}
                      style={{ width: '18px', borderRadius: '2px' }}
                      src={process.env.PUBLIC_URL + '/companyCheckWhite.png'}
                      alt=""
                    />
                  ) : (
                    <img
                      onClick={this.switchCompany.bind(this, 'candidate')}
                      style={{ width: '18px',borderRadius: '2px' }}
                      src={process.env.PUBLIC_URL + '/companyCheckRed.png'}
                      alt=""
                    />
                  )}
                  <span style={{marginLeft: '15px', fontFamily:'Gilroy Bold', fontWeight:'800'}}> People / Candidate</span>
                </div>
              </div>
            </Col>
            {this.state.person ? (
              <Companysignup signup_type={2} />
            ) : (
              <Candidatesignup signup_type={1} />
            )}
            <Col xs={24}>
                <h6 className="login-tag">
              Already have an account?{' '}
              <small
                className="cursor red-link"
                onClick={() => this.props.history.push('/login')}
                style={{ color: '#B02318' }}
              >
                Login
              </small>{' '}
              to your account.
            </h6>
            </Col>
            </Row>
          </Col>
           <Col xs={0} sm={12} className='login-container'>
           <img
                    className="login_div_img_1"
                    src={fileUrl.fileUrlPath + "login_image_cover.gif"}
                    alt="img"
                  />
          </Col> 
        </Row>
    );
  }
}
const mapStateToProps = ({ AuthReducer: { user } }) => ({ user });
export default connect(mapStateToProps)(Signup);
