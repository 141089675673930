import { useState } from "react"
import { fileUrl } from "../../Shared/imageUrlPath"
import cardDefault from '../asset/card.svg'
const VideoSection = (props) => {
    const [activeVideo,setActiveVideo] = useState(fileUrl.fileUrlPath+props.media[0].fileName)
    return (
        <>
        {props.media && 
        <div>
            <video
            src={activeVideo}
            controls
            alt="event"
            width={'100%'}
            height={350}
            />
        </div>}
        {(props.media && props.media.length>1) && props.media.map((data) => (
            <span>
                <video
                onClick={() => setActiveVideo(fileUrl.fileUrlPath+data.fileName)}
                src={
                    data.fileName
                    ? fileUrl.fileUrlPath + data.fileName
                    : cardDefault
                }
                alt="event"
                width={'20%'}
                height={70}
                />
            </span>
        ))}
    </>
    )
}
export default VideoSection