import React, { useEffect, useState } from 'react';
import './style.css';
import userDefaultPic from '../../asset/user.svg';
import { fileUrl } from '../../../Shared/imageUrlPath';
import APIManager from '../../../APIManager/index';
import { connect } from 'react-redux';
import { Spin, message, Popconfirm } from 'antd';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import { LeftOutlined, RightOutlined, DeleteFilled } from '@ant-design/icons';
import { imageUrlPath } from '../../../Shared/imageUrlPath';
import defaultCard from '../../asset/card.svg';
import NoData from '../../../PageNotFound/NoData';

const UserCard = (props) => {
  // useEffect(() => {
  //   APIManager.listChannnel(props.searchChannel).then((resp) =>
  //   );
  // }, [props.searchChannel]);
  useEffect(() => {
    const search = props.searchChannel;
    const page = '1';
    makeHttpRequestWithPage(page, search);
  }, [props.searchChannel]);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  const [unseenCount, setUnseenCount] = useState(true);
  const [totalPages, setTotalPages] = useState('');
  const makeHttpRequestWithPage = async (pageNumber, search) => {
    setLoading(true);
    APIManager.listChannnel(pageNumber, search).then((resp) => {
      setData(resp.data.data);
      setTotal(resp.data.totalCount);
      setCurrentPage(resp.data.currentPage);
      setTotalPages(resp.data.totalPages);
      setLoading(false);
      // this.setState({
      //   data: resp.data.data,
      //   total: resp.data.totalCount,
      //   //per_page: resp.data.results,
      //   current_page: resp.data.currentPage,
      // });
    });
  };
  // React.useEffect(() => {
  //   loadData(props.searchChannel);
  // }, [props.sortValue, props.searchQuery]);

  //           data: resp.data.data,
  //           total: resp.data.totalCount,
  //           //   per_page: resp.data.results,
  //           current_page: resp.data.currentPage,
  //           isloading: false,
  //         });
  //       })
  //       .catch((err) => {
  //         this.setState({ isloading: false });
  //         message.error('Something went wrong!');
  //       });
  // }

  //check channel if exists or not
  const checkChannelExist = (data) => {
    const id = data.company
      ? data.company.compId
      : '' || data.subUser
      ? data.subUser.compId
      : '' || data.candidate
      ? data.candidate.compId
      : '';
    setLoading(true);
    APIManager.checkChannel(id)
      .then((resp) => {
        if (resp.data.isSuccess) {
          setLoading(false);
          setUnseenCount(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error('Something went wrong..');
      });
  };
  const turncateMessage = (str) => {
    return str.length >= 55 ? str.substring(0, 60) + '...' : str;
  };
  const totalChannels = props.channelList ? props.channelList.data : [];
  let renderPageNumbers;
  const pageNumbers = [];
  if (total !== null) {
    for (let i = 1; i <= Math.ceil(total / 10); i++) {
      pageNumbers.push(i);
    }
    renderPageNumbers = pageNumbers?.map((number) => {
      // let classes = this.state.currentPage === number ? .active : '';
      return (
        <span
          key={number}
          onClick={() => makeHttpRequestWithPage(number, '')}
          className={
            currentPage === number ? 'page-number-btn' : 'pagination-container'
          }
        >
          {number}
        </span>
      );
    });
  }
  const getScreenSize = () => {
    if(window.innerWidth <=1000){
     props.screenChange();
    }
  }
  //handle delete of channel from mailbox
  const handleDeleteChannel = (id) => {
    APIManager.deleteMailBoxChannel(id).then((response) => {
      if (response.data.isSuccess) {
        message.info('Channel is removed.');
        makeHttpRequestWithPage(currentPage, '');
      }
    });
  };
  const ResetUnseenCount = () => {
    var ls = require('local-storage');
    const id = ls.get('channelId');
    APIManager.resetUnseenCount(id).then((response) => {
      setUnseenCount(false);
      // if (response.data.isSuccess) {
      //   refreshPage();
      // }
    });
  };

  return (
    <>
      {isLoading ? (
        <div className={'d-flex justify-content-center'}>
          {' '}
          <Spin />{' '}
        </div>
      ) : (
        ''
      )}
      {data.length > 0
        ? data?.map((data) => {
            let profileImage = userDefaultPic;
            if (typeof data.company !== 'undefined') {
              profileImage =
                data.company.logo.name.length !== 0
                  ? fileUrl.fileUrlPath + data.company.logo.name
                  : userDefaultPic;
            }
            if (typeof data.candidate !== 'undefined') {
              profileImage =
                data.candidate.profile.length !== 0
                  ? fileUrl.fileUrlPath + data.candidate.profile
                  : userDefaultPic;
            }

            if (typeof data.subUser !== 'undefined') {
              profileImage =
                data.subUser.profile !== 0
                  ? fileUrl.fileUrlPath + data.subUser.profile
                  : userDefaultPic;
            }

            return (
              <div
                className={
                  data.unseenMessageCount > 0 && unseenCount
                    ? 'unseen-msg-card m-2'
                    : 'user-card-container m-2'
                }
                onClick={() => {
                  var ls = require('local-storage');
                  ls.set(
                    'chatUserId',
                    data.company
                      ? data.compId
                      : '' || data.subUser
                      ? data.subUser.compId
                      : ''
                  );
                  ls.set('channelId', data.id);
                  //setuserInfo(data);
                  props.getIdfromProps(data);
                  checkChannelExist(data);
                  getScreenSize();
                  props.showChatBox(true);
                  ResetUnseenCount();
                  setUnseenCount(false);
                }}
              >
                <div className={'mb-2'}>
                  <div className={'pic-name-container'}>
                    <div className={'user-pic-container'}>
                      <img
                        src={profileImage}
                        //alt={'user-image'}
                        className={'user-pic'}
                        alt={'image'}
                        style={{ objectFit: 'cover' }}
                      />
                    </div>

                    <div
                      className="w-100 ml-2"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {/* {console.log('sdjgdfjgdjfgjbfdgbfdjg', data)} */}
                      <span className={'user-name'}>
                        {data.company
                          ? data.company.busName
                          : '' || data.subUser
                          ? data.subUser.firstname
                          : '' || data.candidate
                          ? data.candidate.firstname +
                            ' ' +
                            data.candidate.lastname
                          : ''}
                      </span>
                    </div>
                    <div className={'d-flex flex-shrink-0 time-style'}>
                      {moment(data.updatedAt).fromNow()}
                    </div>
                  </div>
                </div>
                <div className={'d-flex justify-content-end'}>
                  <Popconfirm
                    title="Are you sure to delete this channel?"
                    onConfirm={() => handleDeleteChannel(data.id)}
                  >
                    <DeleteFilled style={{ color: '#ee5050' }} />
                  </Popconfirm>
                </div>
                <div className={'displayed-msg-container'}>
                  <div>
                    <span className={'displayed-msg'}>
                      {data.lastMessage.text ? (
                        turncateMessage(data.lastMessage.text)
                      ) : (
                        <img
                          src={defaultCard}
                          style={{ height: '40px', width: '30px' }}
                        />
                      )}
                    </span>
                  </div>
                  {data.unseenMessageCount > 0 && unseenCount ? (
                    <div className={'unseen-count'}>
                      {data.unseenMessageCount}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            );
          })
        : isLoading === false && <NoData />}

      {data.length > 0 && (
        <div className={'d-flex justify-content-center pt-2'}>
          <span
            className={currentPage === 1 ? 'pagination-side-btn' : ''}
            onClick={() => makeHttpRequestWithPage(currentPage - 1, '')}
          >
            {' '}
            <LeftOutlined />{' '}
          </span>
          <div>{renderPageNumbers}</div>
          <span
            className={currentPage === totalPages ? 'pagination-side-btn' : ''}
            onClick={() =>
              currentPage !== totalPages &&
              makeHttpRequestWithPage(currentPage + 1, '')
            }
          >
            <RightOutlined />
          </span>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    channelList: state.mailBoxReducer.channelList,
    isloading: state.mailBoxReducer.isloading,
  };
};
export default connect(mapStateToProps)(UserCard);
