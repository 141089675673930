import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';

function Test(props) {
  const [position, setPosition] = useState({
    lat: props.lat,
    lng: props.long,
  });

  useEffect(()=>{
    if(props.lat && props.long){
      setPosition({lat:props.lat,lng:props.long})
    }

  },[props.lat, props.long])

  const handleClick = (mapProps, map, clickEvent) => {
    // console.log(mapProps, map, clickEvent);
    // console.log(clickEvent.latLng.lat(), clickEvent.latLng.lng());
    setLatLong(clickEvent.latLng.lat(), clickEvent.latLng.lng());
  };

  const setLatLong = (lat, lng) => {
    props.takeLatLong(lat,lng);
  };
  return (
    <>
      <Map
        google={props.google}
        style={{ width: '100%', height: '80%' }}
        zoom={18}
        draggable={true}
        className="cutom-map-for-header"
        onClick={handleClick}
        initialCenter={{
          lat: props.lat,
          lng: props.long,
        }}
      >
        <Marker position={position} />
      </Map>
    </>
  );
}

export default withRouter(
  GoogleApiWrapper({
    apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
  })(Test)
);
