import React, { Component } from 'react';
import QualificationIcon from '../asset/Qualification-icon.png';
// import 'antd/dist/antd.css';
import styles from './candidature.module.css';
import { PlusOutlined } from '@ant-design/icons';
import { message, Row, Col, Input, DatePicker, Form, Button } from 'antd';
import APIManager from '../../APIManager/index';
import moment from 'moment';
import { fileUrl } from '../../Shared/imageUrlPath';
class CandidatureQualification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image_preview: undefined,
      salary_active: 'candidature-active',
      social_active: '',
      qualification_active: '',
      exp_active: '',
      contact_active: '',
      tab_name: 'Profile',
      fileNameLocal: '',
      // ==========================================================Qualifications=======================
      allQualification: [],
      allQualificationLength: 0,
      degree: '',
      institute: '',
      university: '',
      from: '',
      to: '',
      qualification_from: '',
      qualification_image: '',
      qualification_to: '',
      disabled: true,
      edit_qualification: false,
      edit_data_index: '',
    };
  }

  componentDidMount() {
    this.qualFormRef = React.createRef();
    let data = this.props.data;
    let cd = [];
    data.forEach((n) => {
      let fso = {};
      fso['degree'] = n.degree;
      fso['from'] = n.from;
      fso['institute'] = n.institute;
      fso['to'] = n.to;
      fso['university'] = n.university;
      fso['qualification_image'] = n.qualification_image;
      cd.push(fso);
    });
    console.log(data, 'data important');
    this.setState({ allQualification: cd });
  }

  handleFromDate = (date, dateString) => {
    this.setState({ qualification_from: dateString, disabled: false });
  };
  handleToDate = (date, dateString) => {
    this.setState({ qualification_to: dateString });
  };
  handleEditQualification = (index) => {
    let edit_data = this.state.allQualification[index];
    this.setState({
      fileName: this.state.allQualification[index].qualification_image,
    });
    let edit_data_index = index;
    let degree = this.state.degree;
    degree = edit_data.degree;
    let qualification_from = this.state.qualification_from;
    qualification_from = edit_data.from;
    let qualification_to = this.state.qualification_to;
    qualification_to = edit_data.to;
    let qualification_image = this.state.qualification_image;
    qualification_image = edit_data.qualification_image;
    let institute = this.state.university;
    institute = edit_data.institute;
    let university = this.state.university;
    university = edit_data.university;
    this.setState({
      degree,
      qualification_from,
      qualification_to,
      institute,
      university,
      edit_qualification: true,
      qualification_image,
      edit_data_index: edit_data_index,
      fileNameLocal: 'Qalification Logo.png',
    });
    const form = this.qualFormRef.current;
    form.setFieldsValue({
      degree,
      qualification_from,
      qualification_to,
      institute,
      university,
    });

    setTimeout(() => {
      console.log('after 3 sec', this.state.degree);
    }, 5000);
    this.props.handleSave(false);
  };
  handleDeleteQualification = (index) => {
    const existingExp = [...this.state.allQualification];
    const newExp = existingExp.filter((exp, i) => i !== index);
    this.setState({ allQualification: newExp });
    const can_qualification = {
      can_qualification: newExp,
    };
    APIManager.candidatureSubmit(can_qualification, 'can_qualification')
      .then((res) => {
        message.success('Data saved successfully.');
      })
      .catch((error) => {
        message.error('Something went wrong');
        console.log('error', error);
      });
  };
  disabledDate = (current) => {
    let customDate = this.state.qualification_from;
    return current < moment(customDate, 'DD MMMM YYYY');
  };
  handleClick = () => {
    this.addAnotherQualification();
    this.props.setData(this.state.allQualification);
  };
  addEditQualification = (index) => {
    let newQualifiucation = this.state.allQualification;
    newQualifiucation[index].degree = this.state.degree;
    newQualifiucation[index].institute = this.state.institute;
    newQualifiucation[index].university = this.state.university;
    newQualifiucation[index].from = this.state.qualification_from;
    newQualifiucation[index].to = this.state.qualification_to;
    newQualifiucation[index].qualification_image =
      this.state.qualification_image;
    this.setState({ allQualification: newQualifiucation });
    this.setState({
      degree: '',
      institute: '',
      university: '',
      qualification_from: '',
      qualification_to: '',
      qualification_image: '',
      edit_qualification: false,
      fileNameLocal: '',
    });
    const fromRef = this.qualFormRef.current;
    fromRef.resetFields();
    this.props.handleSave(true);
  };
  addAnotherQualification = () => {
    const fromRef = this.qualFormRef.current;
    fromRef
      .validateFields()
      .then((values) => {
        let newQualifiucation = this.state.allQualification;
        newQualifiucation.push({
          degree: this.state?.degree.trim(),
          institute: this.state?.institute.trim(),
          university: this.state?.university.trim(),
          from: this.state?.qualification_from,
          to: this.state?.qualification_to,
          qualification_image: this.state?.qualification_image,
        });
        // fromRef.resetFields()
        if (
          newQualifiucation[newQualifiucation.length - 1].degree === '' ||
          newQualifiucation[newQualifiucation.length - 1].university === '' ||
          newQualifiucation[newQualifiucation.length - 1].institute === '' ||
          newQualifiucation[newQualifiucation.length - 1].qualification_from ===
            '' ||
          newQualifiucation[newQualifiucation.length - 1].qualification_to ===
            ''
        ) {
          newQualifiucation.splice(newQualifiucation.length - 1, 1);
        } else {
          this.setState({
            allQualification: newQualifiucation,
            allQualificationLength: newQualifiucation.length,
            degree: '',
            institute: '',
            university: '',
            qualification_from: '',
            qualification_to: '',
            qualification_image: '',
            fileNameLocal: '',
            file: '',
          });
        }
        fromRef.resetFields();
        this.props.handleSave(true);
      })
      .catch((err) => {
        return console.log('failllll', err);
      });
  };
  onSubmit = (values) => {
    // console.log('checking....', this.qualFormRef.current.getFieldsValues())
    // console.log('this.state.allQualification', values);
    // this.props.setData(values);
    const can_qualification = {
      can_qualification: this.state.allQualification,
    };
    if (this.state.allQualificationLength !== 0) {
      APIManager.candidatureSubmit(can_qualification, 'can_qualification')
        .then((res) => {
          message.success('Data saved successfully.');
          console.log('response', res);
          this.setState({ file: '', fileNameLocal: '', fileUrlPath: '' });
        })
        .catch((error) => {
          message.error('Something went wrong');
          console.log('error', error);
        });
    } else {
      message.error('Please fill all fields');
    }
  };
  addImage = (image) => {
    const file = image;
    this.setState({
      file: file,
      fileNameLocal: image?.name,
    });
    let img_type = file?.type;
    this.setState({
      img_type: img_type,
    });
    const uploadParams = {
      files: [
        {
          requestType: 'CandidateProfile',
          contentType: img_type,
        },
      ],
    };
    APIManager.preSignedUrl(uploadParams).then((resp) => {
      if (resp.status === 200) {
        this.setState({
          uploadUrl: resp?.data[0]?.url,
          fileName: resp?.data[0]?.fileName,
          qualification_image: resp?.data[0]?.fileName,
        });
        this.uploadOnS3(file);
      }
    });
  };

  uploadOnS3 = (file) => {
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: file,
    })
      .then((resp) => {
        this.setState({ qualification_image: this.state.fileName });
      })
      .catch((err) => console.log(err));
  };
  Submit = () => {
    if (this.state.edit_qualification) {
      this.addEditQualification(this.state.edit_data_index);
      this.setState({ file: '', fileName: '', fileUrlPath: '' });
    } else {
      this.addAnotherQualification();
      this.setState({ file: '', fileName: '', fileUrlPath: '' });
    }
    setTimeout(() => {
      this.onSubmit();
    }, 2000);
  };
  render() {
    return (
      <div className={styles.qualificationTab}>
        <Form ref={this.qualFormRef} layout="vertical">
          {/* save button for big screen  */}
          <Row
            style={{ marginBottom: '1.5vw' }}
            className={styles.dipNoneOnSmall}
          >
            <Col span={24}>
              <Button
                onClick={this.Submit}
                style={{ float: 'right', height: '100%' }}
                type="button"
                htmlType="submit"
                className="btn btn-dark bold-family btn-save-font cursor"
              >
                Save/New
              </Button>
            </Col>
          </Row>
          {/* if any digree avilable  */}
          {this.state.allQualification.length !== 0 ? (
            <Row className="">
              {this.state.allQualification?.map((val, index) => {
                if (index % 2 === 0) {
                  return (
                    <Col
                      sm={12}
                      xs={24}
                      key={index}
                      className={`card-bottom-padding-qualification form-padding-right ${styles.firstQfcBox}`}
                    >
                      {/* {console.log(fileUrl, 'fileUrl', val, 'val')} */}
                      <Row className="bg-white qualification-card">
                        <Col span={5}>
                          <img
                            src={
                              val.qualification_image
                                ? fileUrl.fileUrlPath + val.qualification_image
                                : QualificationIcon
                            }
                            alt='qualification'
                            className="qualification-card-icon"
                          />
                        </Col>
                        <Col span={18} className="qualification-card-icon-data">
                          <p className="degree_name_cls_1">{val.degree} </p>
                          <p className="degree_name_cls_2">
                            {moment(val.from).format('YYYY MMMM DD')} -{' '}
                            {moment(val.to).format('YYYY MMMM DD')}
                          </p>
                        </Col>
                        <Col span={1} style={{ cursor: 'pointer' }}>
                          <img
                            onClick={() => {
                              this.setState(
                                { file: '', fileNameLocal: '' },
                                () => {
                                  this.handleEditQualification(index);
                                }
                              );
                            }}
                            src={process.env.PUBLIC_URL + '/edit.png'}
                            alt=""
                          />
                          <img
                            onClick={() =>
                              this.handleDeleteQualification(index)
                            }
                            src={process.env.PUBLIC_URL + '/delete-icon.png'}
                            alt=""
                          />
                        </Col>
                      </Row>
                    </Col>
                  );
                } else {
                  return (
                    <Col
                      sm={12}
                      xs={24}
                      key={index}
                      className={`card-bottom-padding-qualification form-padding-left ${styles.secondQfcBox}`}
                    >
                      <Row className="bg-white qualification-card">
                        <Col span={5}>
                          <img
                            src={
                              val.qualification_image !== ''
                                ? fileUrl.fileUrlPath + val.qualification_image
                                : QualificationIcon
                            }
                            alt='qualification'
                            className="qualification-card-icon"
                          />
                        </Col>
                        <Col span={18} className="qualification-card-icon-data">
                          <p className="degree_name_cls_1">{val.degree} </p>
                          <p className="degree_name_cls_2">
                            {moment(val.from).format('YYYY MMMM DD')} -{' '}
                            {moment(val.to).format('YYYY MMMM DD')}
                          </p>
                        </Col>
                        <Col span={1}>
                          <img
                            onClick={() => this.handleEditQualification(index)}
                            src={process.env.PUBLIC_URL + '/edit.png'}
                            alt=""
                          />
                          <img
                            onClick={() =>
                              this.handleDeleteQualification(index)
                            }
                            src={process.env.PUBLIC_URL + '/delete-icon.png'}
                            alt=""
                          />
                        </Col>
                      </Row>
                    </Col>
                  );
                }
              })}
            </Row>
          ) : null}
          {/* upload image section */}
          <Row>
            <Col span={12}>
              <label className="input_label_profile">Image</label>
              <br />
              <label
                className={`add-user-upload-image add-user-upload-image-2 ${styles.uploadImageSection}`}
              >
                <Form.Item
                  name="experience_image"
                  style={{ height: '0px', visibility: 'hidden' }}
                >
                  <Input
                    accept="image/*"
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={(event) => {
                      this.addImage(event.target.files[0]);
                    }}
                    style={{ height: '0px' }}
                  />
                </Form.Item>
                <PlusOutlined />
                <br />
                <br />
                <br />
                <span className={styles.uploadImage}>
                  &nbsp;&nbsp;UPLOAD IMAGE
                </span>
              </label>
            </Col>
            <Col span={12}>
              {this?.state?.file ? (
                <img
                  src={URL?.createObjectURL(this.state.file)}
                  alt=""
                  className={styles.previewImg}
                />
              ) : this.state.fileName ? (
                <img
                  src={fileUrl.fileUrlPath + this.state.fileName}
                  alt=""
                  className={styles.previewImg}
                />
              ) : null}
            </Col>
          </Row>
          {/* all other section */}
          <Row>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-right ${styles.universityBox}`}
            >
              <Form.Item
                label={<span className="input_label_profile">Degree</span>}
                name="degree"
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  type="text"
                  name="degree"
                  className={`input-field-custom-type-1-left ${styles.universityBoxChild}`}
                  value={this.state.degree}
                  onChange={(e) => this.setState({ degree: e.target.value })}
                  placeholder="Enter degree name"
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24} className="form-padding-right pr-0">
              <Form.Item
                label={<span className="input_label_profile">Institute</span>}
                name="institute"
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  type="text"
                  name="institute"
                  className="input-field-custom-type-1-left"
                  value={this.state.institute}
                  onChange={(e) => this.setState({ institute: e.target.value })}
                  placeholder="Enter institute name"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-right ${styles.universityBox}`}
            >
              <Form.Item
                label={<span className="input_label_profile">University</span>}
                name="university"
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  type="text"
                  className={`input-field-custom-type-1-left ${styles.universityBoxChild}`}
                  value={this.state.university}
                  onChange={(e) =>
                    this.setState({ university: e.target.value })
                  }
                  placeholder="Enter university name"
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={0} className="form-padding-left"></Col>
            <Col
              sm={8}
              xs={24}
              className={`form-padding-right ${styles.calenderFirstBox}`}
            >
              <label className="input_label_profile">From</label>
              <DatePicker
                onChange={this.handleFromDate}
                name="qulification_from"
                size="large"
                suffixIcon={
                  <img src={process.env.PUBLIC_URL + '/calendar-date.svg'} alt='calendar-date' />
                }
                className="w-100 input-field-custom-type-1-left"
                value={
                  this.state.qualification_from
                    ? moment(this.state.qualification_from)
                    : undefined
                }
                format={'YYYY MMMM DD'}
                picker="date"
              />
            </Col>
            <Col
              sm={8}
              xs={24}
              className={`form-padding-left ${styles.calenderSecondBox}`}
            >
              <label className="input_label_profile">To</label>
              <DatePicker
                className="w-100 input-field-custom-type-1-left disabled-bg"
                value={
                  this.state.qualification_to
                    ? moment(this.state.qualification_to)
                    : undefined
                }
                onChange={this.handleToDate}
                disabledDate={this.disabledDate}
                disabled={this.state.disabled}
                suffixIcon={
                  <img src={process.env.PUBLIC_URL + '/calendar-date.svg'} alt='calendar-date' />
                }
                picker="date"
                format={'YYYY MMMM DD'}
              />
            </Col>
          </Row>
          {/* save button for small screen  */}
          <Row
            style={{ marginBottom: '1.5vw', marginTop: '30px' }}
            className={styles.dipNoneOnBig}
          >
            <Col span={24}>
              <Button
                onClick={this.Submit}
                style={{ float: 'right', height: '100%', width: '100%' }}
                type="button"
                htmlType="submit"
                className="btn btn-dark bold-family btn-save-font cursor"
              >
                Save/New
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default CandidatureQualification;
