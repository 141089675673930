import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Spin } from 'antd';
import { LeftOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import Pie from './sponsoredPieChart';
import APIManager from '../../APIManager';
import styles from './companyCurrentBilling.module.css';
import moment from 'moment';
const CompanyCurrentBilling = (props) => {
  useEffect(() => {
    APIManager.billingOverview()
      .then((response) => {
        setBillingOverview(response.data.data);

        setSubUserObject(
          response.data.data.billingStats.find(
            (bill) => bill.name === 'subuser'
          )
        );
        setJobObject(
          response.data.data.billingStats.find((bill) => bill.name === 'job')
        );

        setSubcontractorObject(
          response.data.data.billingStats.find(
            (bill) => bill.name === 'subcontractor' || bill.name === 'job'
          )
        );
      })
      .catch((error) => {
        console.log('error in current billing', error);
      });
    APIManager.getCurrentBillingData()
      .then((response) => {
        setSubUserList(response.data.data.subUserList);
        setSponsoresList(response.data.data.sponsoresList);
        setLoading(false);
      })
      .catch((error) => console.log('current billing error', error));
    APIManager.companyInfo().then((response) => {
      if (response.data.isSuccess) {
        setIsPremium(response.data.data.data.isPremium);
      }
    });
    APIManager.getSubUserListWithoutConstraint().then((resp) => {
      if (resp.data.status === 'success') {
        setAllSubusers(resp.data.data);
      }
    });
  }, []);
  const [loading, setLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [showSponserDetails, setShowSponserDetails] = useState(true);
  const [subUserList, setSubUserList] = useState([]);
  const [sponsoresList, setSponsoresList] = useState([]);
  const [billingoverview, setBillingOverview] = useState([]);
  const [subUserObject, setSubUserObject] = useState({});
  const [allSubUsers, setAllSubusers] = useState();
  const [jobObject, setJobObject] = useState({});
  const [isPremium, setIsPremium] = useState();
  const [subcontractorObject, setSubcontractorObject] = useState({});

  let totalSponsorAmount = billingoverview.totalBilling;

  return (
    <>
      {/*================== header for small screen ===================*/}
      <Row>
        <Col md={0} xs={24} className={styles.bgWhite}>
          <Row>
            <Col span={8}>
              <div
                onClick={() => {
                  props.history.goBack();
                }}
              >
                <LeftOutlined className="back-icon" />
                <span className="back-btn">Go back</span>
              </div>
            </Col>
            <Col span={16}>
              <span className={styles.textDesignBig}>Current Billing</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={styles.mainContainer}>
        <div
          className={styles.displayNone}
          onClick={() => {
            props.history.goBack();
          }}
        >
          <LeftOutlined className="back-icon" />
          <span className="back-btn">Go back</span>
        </div>
        <h3
          style={{ fontWeight: '700', marginTop: '30px' }}
          className={styles.displayNone}
        >
          Current Billing
        </h3>
        {loading ? (
          <Spin />
        ) : (
          <Row>
            <Col md={10} xs={24}>
              <Card>
                <Row>
                  <Col span={18}>
                    <h4 className={styles.textDesignBig}>Users Fee</h4>
                  </Col>
                  <Col span={6}>
                    <h4 className={styles.textDesignBig}>
                      {`$${isPremium ? subUserList?.length * 15 : '0'}`}
                    </h4>
                  </Col>
                </Row>

                {allSubUsers?.length && allSubUsers?.length === 1 ? (
                  <p className={styles.textDesignSmall}>
                    1 user {isPremium ? '@15' : '$0'}
                  </p>
                ) : (
                  <p className={styles.textDesignSmall}>
                    {subUserList?.length} users {isPremium ? '@15' : '$0'} per
                    user
                  </p>
                )}
                {showDetails ? (
                  <p
                    className={styles.textDesignSmall}
                    onClick={() => setShowDetails(!showDetails)}
                    style={{ cursor: 'pointer', display: 'flex', color: 'red' }}
                  >
                    Show Details
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '5px',
                      }}
                    >
                      <DownOutlined />
                    </span>
                  </p>
                ) : (
                  <p
                    onClick={() => setShowDetails(!showDetails)}
                    style={{ cursor: 'pointer', display: 'flex', color: 'red' }}
                  >
                    Hide details
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '5px',
                      }}
                    >
                      <UpOutlined />
                    </span>
                  </p>
                )}
                {!showDetails && (
                  <Row
                    className="current-billing-row"
                    style={{
                      border: '1px solid gray',
                      borderRadius: '5px',
                      padding: '5px',
                      // height: '2',
                      maxHeight: '200px',
                      overflow: 'scroll',
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <h6 style={{ fontWeight: '700' }}>Users List</h6>
                      </Col>
                    </Row>

                    {allSubUsers?.map((subUser, index) => {
                      return (
                        <>
                          <Row>
                            <Col
                              span={10}
                              style={{ textTransform: 'capitalize' }}
                            >{`${index + 1}. ${subUser.first_name} ${
                              subUser.last_name
                            }`}</Col>
                            <Col span={9}>
                              {moment(subUser.createdAt).format('YY MMM DD')}
                            </Col>
                            <Col span={5}>{isPremium ? '$15' : '$0'}</Col>
                          </Row>
                        </>
                      );
                    })}
                  </Row>
                )}
                <Row style={{ marginTop: '20px' }}>
                  <Col span={18}>
                    <h4 className={styles.textDesignBig}>Post Sponsorship</h4>
                  </Col>
                  <Col span={6}>
                    <h4 className={styles.textDesignBig}>
                      ${totalSponsorAmount}
                    </h4>
                  </Col>
                </Row>

                {sponsoresList.length && sponsoresList.length === 1 ? (
                  <p className={styles.textDesignSmall}>1 Post</p>
                ) : (
                  <p className={styles.textDesignSmall}>
                    {sponsoresList?.length} Posts
                  </p>
                )}
                {showSponserDetails ? (
                  <p
                    onClick={() => setShowSponserDetails(!showSponserDetails)}
                    style={{ cursor: 'pointer', display: 'flex', color: 'red' }}
                  >
                    Show Details
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '5px',
                      }}
                    >
                      <DownOutlined />
                    </span>
                  </p>
                ) : (
                  <p
                    onClick={() => setShowSponserDetails(!showSponserDetails)}
                    style={{ cursor: 'pointer', display: 'flex', color: 'red' }}
                  >
                    Hide details
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '5px',
                      }}
                    >
                      <UpOutlined />
                    </span>
                  </p>
                )}
                {!showSponserDetails && (
                  <Row
                    className="current-billing-row"
                    style={{
                      border: '1px solid gray',
                      borderRadius: '5px',
                      padding: '5px',
                      maxHeight: '200px',
                      overflow: 'scroll',
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <h6 style={{ fontWeight: '700' }}>Posts List</h6>
                      </Col>
                    </Row>

                    <ol style={{ width: '100%' }}>
                      {sponsoresList?.map((data, index) => (
                        <li style={{ marginLeft: '-18px' }}>
                          {data?.map((data2) => (
                            <Row>
                              <Col
                                span={10}
                                style={{ textTransform: 'capitalize' }}
                              >{`${data2.title} `}</Col>
                              <Col span={9}>
                                {moment(data2.createdAt).format('YY/MM/DD')}
                              </Col>
                              <Col span={5}>{`$${data2.price}`}</Col>
                            </Row>
                          ))}
                        </li>
                      ))}
                    </ol>
                  </Row>
                )}
                <Row style={{ marginTop: '20px' }} className={styles.bgPink}>
                  <Col span={15}>
                    <h3 className={styles.textDesignBig}>
                      Total Billing Amount:{' '}
                    </h3>
                  </Col>
                  <Col span={9}>
                    <h3 className={styles.textDesignBig}>
                      $
                      {isPremium
                        ? (
                            totalSponsorAmount +
                            subUserList?.length * 15
                          ).toFixed(2)
                        : totalSponsorAmount}
                    </h3>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md={2} xs={0}></Col>
            <Col md={12} xs={24} className={styles.daigramSection}>
              <Row>
                <Col span={24}>
                  <Card>
                    <Row>
                      <Col span={20}>
                        <h4 className={styles.textDesignBig}>Paid Amount</h4>
                      </Col>
                      <Col span={4}>
                        <h4 style={{ fontWeight: '900', color: '#6CEF5E' }}>
                          {0}
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <h4 className={styles.textDesignBig}>
                          Outstanding Amount
                        </h4>
                      </Col>
                      <Col span={4}>
                        <h4 style={{ fontWeight: '900', color: '#EE5050' }}>
                          {isPremium
                            ? totalSponsorAmount + subUserList?.length * 15
                            : totalSponsorAmount}
                        </h4>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col span={24}>
                  <Card>
                    <Pie />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default CompanyCurrentBilling;
