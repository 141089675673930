import React, { useEffect } from 'react';
import { Row, Col, Badge } from 'antd';
import Header from '../header/header.js';
import Footer from './footer';
import './Landing.css';
import './footer.css';
import styles from './subcontractors.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withRouter } from 'react-router-dom';

const Features = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <div className="responsive-div">
        <Header />
        <div className={styles.mainContainer}>
          <Row className={styles.headerBg}>
            <Col span={24} className={styles.headingText}>
              SUBCONTRACTORS
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <img
                className={styles.headerImage}
                src={process.env.PUBLIC_URL + 'subcontractorFooter.jpg'}
                alt="subcontractorFooter"
              />
            </Col>
          </Row>
          <div className={styles.mainBody}>
            <div>
              <Row>
                <Col span={24} className={styles.welcomeText}>
                  Hello.
                  <br />
                  Whether you are a company, a freelancer and whatever the trade
                  you practice: Builders, plumbers, electricians, accountants,
                  IT, digital, human resources consultants and any other trade
                  or resource you need...
                  <br />
                  Simply register to find the person you need, or register to be
                  found.
                  <br />
                  <br />
                  You can't do everything by yourself or you may not have all
                  the resources within your employees <br />
                  That's why Sendbiz is here to help you.
                  <br />
                  <br />
                  We created Sendbiz to help you meet the challenges of running
                  and growing your business through our online service, which
                  connects contractors and companies with outsourced contractors
                  and experts. <br />A cost-effective, fast and efficient way to
                  find qualified people to support your business in its projects
                  and development.
                </Col>
              </Row>
              {/* cards section */}
              <Col className={styles.cardMainRow}>
                <Col className={`${styles.cardBackground} ${styles.cardChild}`}>
                  <h3 className={styles.cardHeadign}>Contractors</h3>
                  <Row style={{ marginTop: '10px' }}>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      Register for free
                    </Col>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      Set up your profile
                    </Col>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      Find quality resources
                    </Col>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      Post jobs
                    </Col>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      Receive applicatons
                    </Col>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      No Commission
                    </Col>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      Contract directly with the Subcontractor
                    </Col>
                  </Row>
                  {/* </div> */}
                </Col>

                <Col className={`${styles.cardBackground} ${styles.cardChild}`}>
                  <h3 className={styles.cardHeadign}>Subcontractors</h3>
                  <Row style={{ marginTop: '10px' }}>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      Register for free{' '}
                    </Col>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      Set up your profile
                    </Col>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      Search and be found by contractors <br /> looking for your
                      skills
                    </Col>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      View job postings
                    </Col>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      Apply for jobs
                    </Col>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      No commission
                    </Col>
                    <Col span={1} align="center">
                      <Badge color="#D46161" className={styles.badge} />
                    </Col>
                    <Col span={23} className={styles.cardText}>
                      Contract directly with the Subcontractor
                    </Col>
                  </Row>
                </Col>
              </Col>
            </div>

            {/* looking for a work section */}
            <div
              className={`follow-up ${styles.cardBackground} ${styles.cardPadding}`}
              style={{ marginTop: 50 }}
            >
              <Row className="follow-up-heading">
                If you are looking for a work
              </Row>
              <hr className="follow-up-line" />
              <Row>
                <Col span={24} className="follow-up-para">
                  it is easy to register.
                  <br />
                  By registering even with the freemium version of Sendbiz, we
                  offer you the possibility to present your skills (services),
                  your experience and your availability in a superb page where
                  you can put photos, videos to illustrate your know-how, as
                  well as the possibility to search for the ideal subcontractor
                  or company for which you want to work.
                </Col>
              </Row>
            </div>
            {/* 3 reason to register  section*/}
            <div
              className={`follow-up ${styles.cardBackground} ${styles.cardPadding}`}
              style={{ marginTop: 50 }}
            >
              <Row className="follow-up-heading">3 reasons to register</Row>
              <hr className="follow-up-line" />
              <Row>
                <Col span={24} className="follow-up-para">
                  1. No hidden fees or commissions
                  <br />
                  2. No contract
                  <br />
                  3. No bidding process
                  <br />
                  <br />
                  Once you sign up as a subcontractor or outsourced resource,
                  you can search and find all the companies and contractors that
                  are looking for you.
                </Col>
              </Row>
            </div>
            {/* Register by a Company section */}
            <div
              className={`follow-up ${styles.cardBackground} ${styles.cardPadding}`}
              style={{ marginTop: 50, marginBottom: 20 }}
            >
              <Row className="follow-up-heading">Register by a Company</Row>
              <hr className="follow-up-line" />
              <Row>
                <Col span={24} className="follow-up-para">
                  With Sendbiz you have a platform where you can easily search
                  for subcontractors to help your company to complete your
                  existing projects and to accept new challenges.
                  <br />
                  <br />
                  We put you in touch with subcontractors in different
                  professions who are looking to support companies like yours
                  with their qualifications and experience. Several companies or
                  freelancers can respond to your search and you can consult
                  their Sendbiz files and contact them directly to meet the
                  resource or resources they have proposed to you without any
                  intervention from Sendbiz or request for money.
                  <br />
                  <br />
                  As your business grows, you face daily challenges, and growing
                  your workforce is one of them.
                  <br />
                  <br />
                  Sendbiz is an economical, fast and efficient way to find the
                  skills you are looking for. Once you register, you can search
                  and find subcontractors that meet your needs. In addition, you
                  will have full access to their contact information.
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="fotter-modif">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default withRouter(Features);
