import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Row, Space } from 'antd';
import { Link } from 'react-router-dom';
import styles from './CompanyMenuDropDown.module.css';
const styleobj = {
  color: '#00000080',
  font: 'normal normal 400 13px/15px Gilroy semiBold',
};
const items = [
  {
    key: '1',
    label: (
      <span style={{ font: 'normal normal 400 14px/16px Gilroy Bold' }}>
        Basic
      </span>
    ),
  },

  {
    key: '2',
    label: (
      <Link style={styleobj} to="/edit/company/profile">
        Profile
      </Link>
    ),
  },
  {
    key: '21',
    label: (
      <Link style={styleobj} to="/company/billing">
        Billing Overview
      </Link>
    ),
  },
  {
    key: '3',
    label: (
      <Link style={styleobj} to="/company-statistics">
        Statistics
      </Link>
    ),
  },
  {
    key: '4',
    label: (
      <span style={{ font: 'normal normal 400 14px/16px Gilroy Bold' }}>
        Recruitment
      </span>
    ),
  },
  {
    key: '5',
    label: (
      <Link style={styleobj} to="/companyjoblist">
        Jobs
      </Link>
    ),
  },
  {
    key: '6',
    label: (
      <Link style={styleobj} to="/AppliedCandidates">
        Applied Candidates
      </Link>
    ),
  },
  {
    key: '41',
    label: (
      <span style={{ font: 'normal normal 400 14px/16px Gilroy Bold' }}>
        Consulting
      </span>
    ),
  },
  {
    key: '7',
    label: (
      <Link style={styleobj} to="/Company_Subcontractors">
        Subcontractors
      </Link>
    ),
  },
  {
    key: '8',
    label: (
      <Link style={styleobj} to="/Company_offers_sent">
        Offer Sent
      </Link>
    ),
  },
  {
    key: '81',
    label: (
      <Link style={styleobj} to="/Company_Subcontractors_received">
        Offer Received
      </Link>
    ),
  },
  {
    key: '82',
    label: (
      <Link style={styleobj} to="/SearchResource">
        Search Resources
      </Link>
    ),
  },
  {
    key: '82',
    label: (
      <Link style={styleobj} to="/MyResources">
        My Resources
      </Link>
    ),
  },
  {
    key: '9',
    label: (
      <span style={{ font: 'normal normal 400 14px/16px Gilroy Bold' }}>
        Business
      </span>
    ),
  },
  {
    key: '91',
    label: (
      <Link style={styleobj} to="/company/services">
        Service
      </Link>
    ),
  },
  {
    key: '92',
    label: (
      <Link style={styleobj} to="/company/products">
        Product
      </Link>
    ),
  },
  {
    key: '93',
    label: (
      <Link style={styleobj} to="/company/news">
        News
      </Link>
    ),
  },
  {
    key: '94',
    label: (
      <Link style={styleobj} to="/company/events">
        Events
      </Link>
    ),
  },
  {
    key: '95',
    label: (
      <Link style={styleobj} to="/company/interested">
        Interested
      </Link>
    ),
  },
  {
    key: '10',
    label: (
      <span style={{ font: 'normal normal 400 14px/16px Gilroy Bold' }}>
        Contact
      </span>
    ),
  },
  {
    key: '101',
    label: (
      <Link style={styleobj} to="/company-mailbox">
        Mailbox
      </Link>
    ),
  },
];
const CompanyMenuDropDown = ({ name }) => (
  <Row className={styles.dropDownMenu}>
    <Col span={24} className={styles.dropDownMenuContainer}>
      <Dropdown
        className={styles.menuItems}
        menu={{
          items,
        }}
        trigger={['click']}
      >
        <a
          onClick={(e) => e.preventDefault()}
          style={{
            color: 'black',
            font: 'normal normal 400 20px/25px Gilroy Bold',
          }}
        >
          <Space>
            {name}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </Col>
  </Row>
);

export default CompanyMenuDropDown;
