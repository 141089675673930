import React, { Component } from 'react';
import { Row, Col } from 'antd';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import Card1 from './applied_candidate_info1';
import AllTabs from '../../layout/tabsComponent';
import PesonalInfo from './personalInfo';
import coverDefault from '../../asset/edit-company.svg';
import CandidateEducation from './candidateEducation';
import CanidateProfessionalInfo from './candidateProfesionalInfo';
import { connect } from 'react-redux';
import { ImageUrl } from '../../../Shared/imageUrlPath';

class Job extends Component {
  render() {
    console.log('Props dddd', this.props);

    const tabs = [
      { tabname: 'PERSONAL INFO', comp: <PesonalInfo /> },
      { tabname: 'PROFESSIONAL INFO', comp: <CanidateProfessionalInfo /> },
      { tabname: 'EDUCATION', comp: <CandidateEducation /> },
    ];
    const status =
      this?.props?.location && this?.props?.location?.state
        ? this?.props?.location?.state?.status
        : '';
    const cover = this?.props?.appliedJobData?.data?.candidateData?.can_detail?.candidate_cover_image
      ? ImageUrl?.imageUrlPath +
        this?.props?.appliedJobData?.data?.candidateData?.can_detail
          ?.candidate_cover_image
      : coverDefault;
    return (
      <div>
        <Header />
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <img
                  src={cover}
                  style={{
                    width: '100%',
                    position: 'relative',
                    height: '400px',
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={2}></Col>
              <Col span={20}>
                <div
                  className={'candidate-info-card-1'}
                  style={{ borderRadius: 10, paddingBottom: 10 }}
                >
                  <Card1 status={status} />
                  <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div
                      style={{
                        backgroundColor: '#F4F6F9',
                        borderTop: '2px solid',
                        borderTopColor: '#e7ebf3',
                      }}
                    >
                      <AllTabs
                        company_tabs={tabs}
                        class={'candidat-profile-info-tab'}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={2}></Col>
            </Row>
          </Col>
        </Row>      
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appliedJobData: state.jobManagementReducer.appliedJobData,
    isloading: state.jobManagementReducer.isloading,
  };
};
export default connect(mapStateToProps)(Job);
