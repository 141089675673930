import React, { useEffect, useState } from 'react';
import './company.css';
import styles from './company_profile.module.css';
import { Spin, Card, Row, Col } from 'antd';
import APIManager from '../../APIManager';
import moment from 'moment';
import cardDefaultPic from '../asset/card.svg';
import { ImageUrl } from '../../Shared/imageUrlPath';
import {
  ClockCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import NoDataFound from '../../PageNotFound/NoData.js';

const CompanyNews = (props) => {
  useEffect(() => {
    getNews('1');
  }, []);
  const [newsData, setNewsData] = useState([]);
  const [totalCount, setTotalCount] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [totalPages, setTotalPages] = useState('');
  const getNews = (pageNumber) => {
    var ls = require('local-storage');
    const compId = ls.get('compId');
    setisLoading(true);
    APIManager.getNewsofCompany(compId, pageNumber).then((response) => {
      if (response.data.isSuccess) {
        setNewsData(response.data.data);
        setCurrentPage(response.data.currentPage);
        setisLoading(false);
        setTotalCount(response.data.totalCount);
        setTotalPages(response.data.totalPages);
      }
    });
  };
  let renderPageNumbers;
  const pageNumbers = [];
  var ls = require('local-storage');
  const id = ls.get('compId');
  if (totalCount !== null) {
    for (let i = 1; i <= Math.ceil(totalCount / 10); i++) {
      pageNumbers.push(i);
    }
    renderPageNumbers = pageNumbers?.map((number) => {
      return (
        <span
          key={number}
          onClick={() => getNews(number)}
          className={
            currentPage === number ? 'page-number-btn' : 'pagination-container'
          }
        >
          {number}
        </span>
      );
    });
  }
  return (
    <>
      {isLoading && (
        <div className={'d-flex justify-content-center'}>
          <Spin />
        </div>
      )}
      {newsData.length >= 1 && (
        <h6 className="about-company w-100">{newsData.length} News listed</h6>
      )}
      {newsData &&
        newsData?.map((data) => {
          let newsImg = '';
          data?.image?.forEach((e) => {
            if (e.fileType.includes('image')) {
              newsImg = e.fileName;
            }
          });
          return (
            <div
              className={`services-card ${styles.newsCards}`}
              onClick={() => props.history.push(`/news/detail/${data._id}`)}
            >
              <Row gutter={15}>
                <Col sm={4} xs={6}>
                  <img
                    alt="example"
                    className={styles.Image}
                    src={
                      data.image.length > 0
                        ? ImageUrl.imageUrlPath + newsImg
                        :  data?.companyData?.logo?.name
                        ? ImageUrl.imageUrlPath + data?.companyData?.logo?.name
                        : cardDefaultPic
                    }
                  />
                </Col>
                <Col sm={20} xs={18} className="service-detail-row">
                  <Row justify="space-between">
                    <Col sm={17} xs={24}>
                      <div className={`service-name ${styles.newsCardTitle}`}>
                        {data.title.charAt(0).toUpperCase() +
                          data.title.slice(1)}
                      </div>
                    </Col>
                  </Row>

                  <Row align="bottom" className={styles.createdOn}>
                    <Col
                      sm={12}
                      xs={24}
                      style={{ display: 'flex', alignSelf: 'self-start' }}
                    >
                      <ClockCircleOutlined
                        className={`service-icons ${styles.newsCardIcons} ${styles.newsCardText}`}
                      />
                      <div
                        className={`service-location ${styles.newsCardText}`}
                        style={{ display: 'block' }}
                      >
                        Created on {moment(data.createdAt).format('YYYY/MM/DD')}
                      </div>
                    </Col>
                    <Col sm={12} xs={24}>
                      <ClockCircleOutlined
                        className={`service-icons ${styles.newsCardIcons} ${styles.newsCardText}`}
                      />
                      <div
                        className={`service-location ${styles.newsCardText}`}
                      >
                        Published on{' '}
                        {moment(data.updatedAt).format('YYYY/MM/DD')}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          );
        })}
      {/* pagination section */}
      {newsData.length > 0 ? (
        <div className={'d-flex justify-content-center pt-4'}>
          <span
            className={currentPage === 1 ? 'pagination-side-btn' : ''}
            onClick={() => getNews(currentPage - 1)}
          >
            {' '}
            <LeftOutlined />{' '}
          </span>
          <div>{renderPageNumbers}</div>
          <span
            className={currentPage === totalPages ? 'pagination-side-btn' : ''}
            onClick={() =>
              currentPage !== totalPages && getNews(currentPage + 1)
            }
          >
            <RightOutlined />
          </span>
        </div>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};
export default withRouter(CompanyNews);
