import React, { Component } from 'react';
import { Row, Col , Spin } from 'antd';
import styles from './visitProfile.module.css';
import { connect } from 'react-redux';
import { ImageUrl } from '../../../Shared/imageUrlPath';
import CoverImage from '../../asset/user.svg';
import { CURRENCY } from '../../constants/currency';
class Card extends Component {
  render() {
    const data = this.props?.data?.data?.data;
    const preData = this.props?.data?.data;
    const candidateName = data?.can_detail?.firstName + ' ' + data?.can_detail?.lastName;
    const occupation = data?.can_detail?.occupation;
    const candidateExperience = preData?.experience?.years ? preData?.experience?.years : '0';
    const candidateLastSalary = data?.can_salary?.lastSalary;
    const phoneNumber = data?.can_contact?.phoneNumber;
    const landLine = data?.can_contact?.landlineNumber;
    const email = data?.can_detail?.email;
    const location = data?.can_contact?.addressTwo;
    const street = data?.can_contact?.addressOne;
    const zipCode = data?.can_contact?.zipCode;
    const Availibility = data?.can_detail?.availability;
    const profilePhoto = data?.can_detail?.profile;
    const social_link = data?.can_social;
    const loading = this.props?.isloading;
    const resume = data?.resumes[0];
    return (
      <div className={'job-card'} style={{ borderRadius: 4, cursor: 'revert' }}>
        <div className="d-flex justify-content-center pt-2">
          {loading && <Spin />}
        </div>
        <Row className="visit_info_card_1" style={{ marginBottom: '20px' }}>
          <Col sm={3} xs={6} style={{display: 'flex',justifyContent: 'center'}}>
            <img
              className={styles.profileIcons}
              src={
                profilePhoto ? ImageUrl.imageUrlPath + profilePhoto : CoverImage
              }
            />
          </Col>
          <Col sm={16} xs={18} className={styles.visitInfoCardcol2}>
            <Row>
              <Col sm={20} xs={24}>
                <p
                  className={`visit_info_card_3 ${styles.profileSectionHeading}`}
                >
                  {candidateName?.charAt(0)?.toUpperCase() +
                    candidateName?.slice(1)}
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                className={`input-label-field-custom-type-1 ${styles.profileSectionSubHeading}`}
              >
                <p
                  className={`visit_info_card_4 ${styles.profileSectionSubHeading}`}
                >
                  {occupation}
                </p>
              </Col>
            </Row>
            <Row style={{ justifyContent: 'space-between' }}>
              {street || location || zipCode ? (
                <Col sm={9} xs={24}>
                  <div className="visit_info_card_5">
                    <img
                      src={process.env.PUBLIC_URL + '/location-red.png'}
                      className={styles.locationPng}
                    />
                    <p className={styles.locationBoxTetx}>
                      {location ? ` ${location}` : ''},
                      {zipCode ? ` ${zipCode}` : ''}
                    </p>
                  </div>
                </Col>
              ) : (
                <Col sm={9} xs={0}></Col>
              )}

              <Col sm={6} xs={24}>
                <div
                  className={`visit_info_card_5 ${styles.phoneNumberSection}`}
                >
                  <p>
                    {phoneNumber && (
                      <div className={styles.mobileIcons}>
                        <i className="fa fa-mobile" aria-hidden="true"></i>
                        &nbsp;&nbsp;{phoneNumber}
                      </div>
                    )}
                    {landLine && (
                      <div>
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        &nbsp;&nbsp;{landLine}
                      </div>
                    )}
                  </p>
                </div>
              </Col>
              <Col sm={8} xs={24}>
                <div className="visit_info_card_5">
                  <p className={styles.emailFontIcons}>
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    &nbsp;&nbsp;{email}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={0} xs={24} style={{ marginTop: '-15px' }}>
                <p
                  className={`job-detail-link-company-page mb-0`}
                  onClick={() => {
                    window.location.href = `${ImageUrl.imageUrlPath}${resume.name}`;
                  }}
                  style={{
                    cursor: 'pointer',
                    fontSize: '12px',
                  }}
                >
                  <img src={process.env.PUBLIC_URL + '/link.svg'} />
                  &nbsp;
                  <span>View Resume PDF</span>
                </p>
              </Col>
            </Row>
            {/**************** for small screen social media link big scrrent display none *********************/}
            <Row className={styles.socialMediaPadding}>
              <Col
                sm={0}
                xs={24}
                className="padding-bottom-apply-job"
                style={{ marginTop: '5px' }}
              >
                <ul className="company-main-ul-1">
                  {social_link &&
                    social_link?.map((val, index) => (
                      <>
                        {val.name === 'Facebook' ||
                        (val.name === 'facebook' && val.link) ? (
                          <li onClick={() => window.open(val.link, '_blank')}>
                            <img
                              src={process.env.PUBLIC_URL + '/facebook.png'}
                              style={{
                                height: '25px',
                                marginRight: '15px',
                                cursor: 'pointer',
                              }}
                            />
                          </li>
                        ) : null}
                        {(val.name === 'Linkedin' ||
                          val.name === 'linkedin' ||
                          val.name === 'LinkedIn') &&
                        val.link ? (
                          <li onClick={() => window.open(val.link, '_blank')}>
                            <img
                              src={process.env.PUBLIC_URL + '/linkedin.png'}
                              style={{
                                height: '25px',
                                marginRight: '15px',
                                cursor: 'pointer',
                              }}
                            />
                          </li>
                        ) : null}
                        {val.name === 'Twitter' ||
                        (val.name === 'twitter' && val.link) ? (
                          <li onClick={() => window.open(val.link, '_blank')}>
                            <img
                              src={process.env.PUBLIC_URL + '/twitter.png'}
                              style={{
                                height: '25px',
                                marginRight: '15px',
                                cursor: 'pointer',
                              }}
                            />
                          </li>
                        ) : null}
                      </>
                    ))}
                </ul>
              </Col>
            </Row>
          </Col>
          <Col sm={4} xs={0}>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Col sm={18} xs={24} className="padding-bottom-apply-job">
                <ul className="company-main-ul-1">
                  {social_link &&
                    social_link?.map((val, index) => (
                      <>
                        {val.name === 'Facebook' ||
                        (val.name === 'facebook' && val.link) ? (
                          <li
                            style={{ paddingRight: 15 }}
                            onClick={() => window.open(val.link, '_blank')}
                          >
                            <img
                              src={process.env.PUBLIC_URL + '/facebook.png'}
                              style={{
                                fontSize: '30px',
                                marginRight: '17px',
                                cursor: 'pointer',
                              }}
                            />
                          </li>
                        ) : null}
                        {(val.name === 'Linkedin' ||
                          val.name === 'linkedin' ||
                          val.name === 'LinkedIn') &&
                        val.link ? (
                          <li
                            style={{ paddingRight: 15 }}
                            onClick={() => window.open(val.link, '_blank')}
                          >
                            <img
                              src={process.env.PUBLIC_URL + '/linkedin.png'}
                              style={{
                                fontSize: '30px',
                                marginRight: '17px',
                                cursor: 'pointer',
                              }}
                            />
                          </li>
                        ) : null}
                        {val.name === 'Twitter' ||
                        (val.name === 'twitter' && val.link) ? (
                          <li
                            style={{ paddingRight: 15 }}
                            onClick={() => window.open(val.link, '_blank')}
                          >
                            <img
                              src={process.env.PUBLIC_URL + '/twitter.png'}
                              style={{
                                fontSize: '30px',
                                marginRight: '17px',
                                cursor: 'pointer',
                              }}
                            />
                          </li>
                        ) : null}
                      </>
                    ))}
                </ul>
              </Col>
            </Row>
            <p
              className={`job-detail-link-company-page mb-0 ${styles.resumeSection}`}
              onClick={() => {
                window.location.href = `${ImageUrl.imageUrlPath}${resume.name}`;
              }}
              style={{
                textAlign: 'right',
                marginRight: '10%',
                cursor: 'pointer',
              }}
            >
              <img src={process.env.PUBLIC_URL + '/link.svg'} />
              &nbsp;&nbsp;
              <span>View Resume PDF</span>
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={12} sm={12} md={12} xs={24}>
            <ul className={`visit_info_card_6 ${styles.visitInfoCard6}`}>
              <li className={`visit_info_card_7 ${styles.visitInfoCard7}`}>
                <Row style={{ height: 25 }}>
                  <Col span={24}>
                    <p>
                      {Availibility?.charAt(0)?.toUpperCase() +
                        Availibility?.slice(1)}
                    </p>
                  </Col>
                </Row>
                <Row style={{ fontSize: '0.6rem' }}>
                  <Col span={24}>
                    <p
                      className={`input-label-field-custom-type-1 ${styles.availabilityDiv}`}
                    >
                      Availability
                    </p>
                  </Col>
                </Row>
              </li>
              {/* col 2 */}
              <li className={`visit_info_card_7 ${styles.visitInfoCard7}`}>
                <Row style={{ paddingLeft: 15, height: 25 }}>
                  <Col span={24}>
                    <p>{candidateExperience} years</p>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: 15, height: 20 }}>
                  <Col span={24}>
                    <p className="input-label-field-custom-type-1">
                      Experience
                    </p>
                  </Col>
                </Row>
              </li>
              {/* col 3 */}
              <li className={`visit_info_card_8 ${styles.visitInfoCard8}`}>
                <Row style={{ paddingLeft: 30, height: 25 }}>
                  <Col span={24}>
                    <p>
                      {CURRENCY.filter(
                        (values) => values.name === candidateLastSalary?.currency
                      ).length > 0 &&
                        CURRENCY.filter(
                          (values) =>
                            values.name === candidateLastSalary?.currency
                        )[0].symbol}
                      &nbsp;&nbsp;
                      {candidateLastSalary?.amount}
                      &nbsp;&nbsp;
                      {data?.can_salary?.recieveFormat}
                    </p>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: 30, height: 20 }}>
                  <Col span={24}>
                    <p className={'input-label-field-custom-type-1'}>Salary</p>
                  </Col>
                </Row>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.companyInfoReducer.data,
    isloading: state.companyInfoReducer.isloading,
  };
};
export default connect(mapStateToProps)(Card);
