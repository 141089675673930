import React, { Component } from 'react';
import { Row, Col, Card, Tag, message, Tooltip } from 'antd';
import Pagination from 'react-js-pagination';
import { EnvironmentFilled, ClockCircleOutlined } from '@ant-design/icons';
import './ServiceListing.css';
import styles from './serviceListingCard.module.css';
import APIManager from '../../../APIManager';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import NoData from '../../../PageNotFound/NoData';
import { fileUrl } from '../../../Shared/imageUrlPath';
import cardDefaultPic from '../../asset/card.svg';
import { CURRENCY } from '../../constants/currency';
import { NavLink } from 'react-router-dom';
import { SendOutlined, UserOutlined } from '@ant-design/icons';

class ServiseListingCard extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      minValue: 0,
      maxValue: 2,
      data: [],
      resultsPerPage: 10,
      totalData: '',
      totalPages: '',
      currentPage: '',
      serviceid: '',
      loading: false,
    };
  }
  componentDidMount() {
    if (
      !(
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.ser_name
      )
    ) {
      this.makeHttpRequestWithPage();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.makeHttpRequestWithPage();
    }
    if (this.props.company_name !== prevProps.company_name) {
      this.makeHttpRequestWithPage();
    }

    if (this.props.category !== prevProps.category) {
      this.makeHttpRequestWithPage();
    }
    if (this.props.price !== prevProps.price) {
      this.makeHttpRequestWithPage();
    }
    if (this.props.current_location?.length > 0 && this.props.current_location !== prevProps.current_location) {
      this.makeHttpRequestWithPage();
    }
  }

  makeHttpRequestWithPage = async (pageNumber = 1) => {
    const search = this.props.searchQuery;
    const company = this.props.company_name;
    const category = this.props.category;
    const price = this.props.price;
    let searchCat = '';
    if (category.length > 0) {
      category?.map((val) => {
        searchCat = searchCat + `&category=${val}`;
      });
    }
    let searchComp = '';
    if (company.length > 0) {
      company?.map((val) => {
        searchComp = searchComp + `&companyId=${val}`;
      });
    }
    this.setState({ loading: true });
    let country = '';
    let state = '';
    let city = '';
    if (this.props.current_location.length > 0) {
      const locationArray = this.props.current_location.split(',');
      if (locationArray.length > 0) {
        country = locationArray[locationArray.length - 1];
        state =
          locationArray.length > 1
            ? locationArray[locationArray.length - 2]
            : '';
        city = locationArray.length > 2 ? locationArray[0] : '';
      }
      APIManager.allServicesSortAndSearch(
        pageNumber,
        searchCat,
        searchComp,
        price ? `${price}` : '',
        search ? `&search=${search}` : '',
        city,
        state,
        country,
        this.props.homeSearch,
        this.props.searchLocation
      )
        .then((resp) => {
          this.props.setPropsService(resp.data.data);
          this.setState({
            data: resp.data.data,
            totalData: resp.data.totalCount,
            resultsPerPage: resp.data.results,
            totalPages: resp.data.totalPages,
            currentPage: resp.data.currentPage,
            loading: false,
          });
          if (this.props.searchQuery) {
            this.props.changeMapLocation(resp?.data?.data[0]);
          }
          this?.myRef?.current?.scrollIntoView();
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          this.setState({ loading: false });
          message.error(err);
        });
    }
  };
  handleLocationChange = (e, value) => {
    e.stopPropagation();
    this.props.changeMapLocation(value);
  };
  renderDetailPage = () => {};
  render() {
    const SponsoredServices = this.state.data
      .filter((data2) => data2.isSponsored);
    const SortsServices = this.state.data
      .filter((data2) => !data2.isSponsored);
    const SortedServices = [...SponsoredServices, ...SortsServices];

    const loading = this.state.loading;
    return (
      <>
        {/************ Loader and error **************/}
        {loading && (
          <div className={'d-flex justify-content-center mt-5'}>
            <Spin />
          </div>
        )}

        {!loading && (
          <h3 className="card-div-head">
            {SortedServices?.length > 0
              ? SortedServices?.length + ' Services found'
              : ''}
          </h3>
        )}
        {/************ Service Listing card **************/}
        <div className="custom-container" style={{ padding: 0 }}>
          <div ref={this.myRef}>
            {this.state.data.length === 0 && !this.state.loading ? (
              <NoData
                text={
                  'Please try using Different Location, Filters or keywords for your search.'
                }
              />
            ) : (
              SortedServices &&
              SortedServices?.map((data) => (
                <>
                  <Card
                    className={`services-card ${styles.serviceCradMainContainer}`}
                    onClick={() => {
                      var ls = require('local-storage');
                      ls.set('serviceId', data._id);

                      {
                        const win = window.open(
                          `/services/detail/${data._id}`,
                          '_blank'
                        );
                        win.focus();
                      }
                    }}
                    key={data.comp_id}
                  >
                    <Row className={styles.mainServiceCardRow}>
                      <Col
                        md={5}
                        xs={6}
                        className={styles.serviceCardImagesContainer}
                      >
                        <img
                          className={styles.serviceCardImages}
                          alt="logo"
                          src={
                            data.media[0]
                              ? data.media[0].fileType !== 'video/mp4'
                                ? fileUrl.fileUrlPath + data.media[0].fileName
                                : cardDefaultPic
                              : cardDefaultPic
                          }
                        />
                      </Col>
                      <Col
                        xs={18}
                        md={19}
                        className={`service-detail-row ${styles.serviceCardDetailsContainer}`}
                      >
                        <Row
                          justify="space-between"
                          align={'middle'}
                          style={{ marginBottom: '5px' }}
                        >
                          <Col span={13}>
                            <div style={{ display: 'flex' }}>
                              <div
                                className="service-name job-card-3"
                                style={{
                                  fontSize: '1.5rem',
                                }}
                              >
                                <Tooltip placement="top" title={`${data.name}`}>
                                  {data.name}
                                </Tooltip>
                              </div>
                              {data.companyData.isFreelancer ? (
                                <div style={{ margin: '1px 0px 0px 10px' }}>
                                  <Tooltip placement="top" title={'Freelancer'}>
                                    <UserOutlined
                                      style={{
                                        fontSize: '20px',
                                        color: '#ed5050',
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </Col>
                          <Col span={8}>
                            <div style={{ display: 'flex' }}>
                              <Tooltip placement="top" title={'Price'}>
                                <Tag
                                  color="volcano"
                                  className="s-price-tag"
                                  style={{ float: 'right' }}
                                >
                                  {CURRENCY?.map((curr) =>
                                    data
                                      ? curr.name === data.price.currency
                                        ? `${curr.symbol} ${data.price.amount}`
                                        : ''
                                      : ''
                                  )}
                                </Tag>
                              </Tooltip>
                              <Tooltip placement="top" title={'Period'}>
                                <Tag
                                  color="volcano"
                                  className="s-price-tag"
                                  style={{ float: 'right' }}
                                >
                                  {data?.period}
                                </Tag>
                              </Tooltip>
                            </div>
                          </Col>
                          <Col span={3}>
                            <div
                              className={styles.mapIcons}
                              onClick={(e) =>
                                this.handleLocationChange(e, data)
                              }
                            >
                              <SendOutlined className="mapIcon" />
                            </div>
                          </Col>
                        </Row>
                        <Row >
                          <Col md={19} xs={18}>
                            <NavLink
                              to={`comp-id${data.companyData._id}`}
                              onClick={(e) => e.stopPropagation()}
                              className="job-card-4 comp-hover"
                            >
                              {data.companyData.comp_info.bus_name}
                            </NavLink>
                          </Col>
                          <Col md={5} xs={6}>
                            <span className={styles.sponsored}>
                              {data.isSponsored ? 'SPONSORED' : ''}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={data.category.length > 1 ? 24 : 6}>
                            <span >
                              <Tag
                                color="volcano"
                                className="s-category-name"
                                style={{ maxWidth: '300px' }}
                              >
                                {data.category[0]
                                  ? data.category[0].categoryName.toUpperCase()
                                  : ''}
                              </Tag>
                              {data.category.length > 1 ? (
                                <Tag
                                  color="volcano"
                                  className="s-category-name"
                                >
                                  {`+${data.category.length - 1}`}
                                </Tag>
                              ) : (
                                ''
                              )}
                            </span>
                          </Col>
                        </Row>

                        <Row align="bottom" style={{ marginTop: '5px' }}>
                          <Col
                            span={13}
                            style={{ display: 'flex', alignSelf: 'self-start' }}
                          >
                            <EnvironmentFilled className="service-icons" />
                            <div
                              className="service-location"
                              style={{
                                display: 'block',
                                textTransform: 'capitalize',
                              }}
                            >
                            {data.location}
                            </div>
                          </Col>
                          <Col
                            span={11}
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <ClockCircleOutlined className="service-icons" />
                            <div className="service-location">
                              {data.duration} ago
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </>
              ))
            )}
            {this.state.data.length !== 0 ? (
              <div className={'paginate-container'}>
                <Pagination
                  activePage={this.state.currentPage}
                  itemsCountPerPage={11}
                  firstPageText={false}
                  lastPageText={false}
                  totalItemsCount={this.state.totalData}
                  pageRangeDisplayed={5}
                  hideDisabled={true}
                  onChange={(pageNumber) => {
                    this.makeHttpRequestWithPage(pageNumber);
                  }}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    servicedata: state.ServiceReducer.servicedata,
    isloading: state.ServiceReducer.isloading,
  };
};
export default withRouter(connect(mapStateToProps)(ServiseListingCard));
