import React, { Component } from 'react';
import { Row, message, Spin, Tooltip, Col } from 'antd';
import './topNav.css';
import './companyList.css';
import { connect } from 'react-redux';
import APIManager from '../../APIManager/index';
import Pagination from 'react-js-pagination';
import { withRouter } from 'react-router-dom';
import cardDefaultPic from '../../Components/asset/card.svg';
import { fileUrl } from '../../Shared/imageUrlPath';
import NoData from '../../PageNotFound/NoData';
import { SendOutlined, UserOutlined, AuditOutlined } from '@ant-design/icons';

class CompanyListCard extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      companies: [],
      resultsPerPage: null,
      totalData: null,
      totalPages: null,
      currentPage: null,
      isLoading: false,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchingValue !== prevProps.searchingValue) {
      this.loadData();
    }
    if (this.props.current_location?.length > 0 && this.props.current_location !== prevProps.current_location) {
      this.loadData();
    }
    if (this.props.sector !== prevProps.sector) {
      this.loadData();
    }
  }
  loadData = (pageNumber = 1) => {
    const search =
      typeof this.props.searchingValue !== 'undefined'
        ? this.props?.searchingValue
        : this.props?.location?.state?.comp_name
        ? this.props?.location?.state?.comp_name
        : '';
    let sector = this.props.sector;
    let searchCat = '';
    if (sector.length > 0) {
      sector?.map((val) => {
        searchCat = searchCat + `&sector=${val}`;
      });
    }
    let country = '';
    let state = '';
    let city = '';

    if (this.props.current_location.length > 0) {
      const locationArray = this.props.current_location.split(',');
      if (locationArray.length > 0) {
        country = locationArray[locationArray.length - 1];
        state =
          locationArray.length > 1
            ? locationArray[locationArray.length - 2]
            : '';
        city = locationArray.length > 2 ? locationArray[0] : '';
      }
    }
    this.setState({ isLoading: true });
    APIManager.companyList(
      pageNumber,
      search.toLowerCase(),
      city,
      state,
      country,
      searchCat,
      this.props.homeSearch,
      this.props.searchLocation
    )
      .then((resp) => {
        this.setState({
          companies: resp.data.data,
          resultsPerPage: resp.data.results,
          totalData: resp.data.totalCount,
          totalPages: resp.data.totalPages,
          currentPage: resp.data.currentPage,
          isLoading: false,
        });
        if (this.props.searchingValue) {
          this.props.changeMapLocation(
            resp?.data?.data[0]?.contact_info,
            resp?.data?.data[0]?._id
          );
        }
        this?.myRef?.current?.scrollIntoView();
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        message.error(err);
      });
  };

  handleLocationChange = (e, value) => {
    e.stopPropagation();
    this.props.changeMapLocation(value?.contact_info, value?._id);
  };

  render;
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        {this.state.isLoading && (
          <div className={'d-flex justify-content-center mt-5'}>
            <Spin />
          </div>
        )}
        {!this.state.isLoading && (
          <h3
            className="card-div-head"
            style={{ paddingTop: '50px', paddingBottom: '0px' }}
          >
            {this.props.current_location.length > 0 ||
            this.props.searchingValue.length > 0
              ? `${
                  this.state.totalData
                    ? this.state.totalData +
                      `${
                        this.state.totalData === 1
                          ? ' Company found'
                          : ' Companies found'
                      }`
                    : ''
                }`
              : ''}
          </h3>
        )}
        <div className="custom-container" style={{ padding: 0 }}>
          <div ref={this.myRef} className="flex-container job-card-1">
            {this.state.companies.length === 0 ? (
              <NoData
                text={
                  'Please try using Different Location, Filters or keywords for your search.'
                }
              />
            ) : (
              this.state.companies?.map((data, index) => (
                <Row
                  className="company-list-container"
                  key={index}
                  onClick={() => {
                    const win = window.open(`/comp-id${data._id}`, '_blank');
                    win.focus();
                  }}
                >
                  <Col md={6} xs={8} className="job-card-image">
                    <img
                      alt="logo"
                      src={
                        data.logo && data.logo.name !== ''
                          ? fileUrl.fileUrlPath + data.logo.name
                          : cardDefaultPic
                      }
                      style={{
                        width: '100%',
                        height: '120px',
                        borderRadius: '10px',
                        objectFit: 'cover',
                      }}
                    />
                  </Col>
                  <Col md={18} xs={16} className="company-card-part2">
                    {/* title section */}
                    <Row
                      className="company-card-part2"
                      style={{ marginBottom: '-10px' }}
                    >
                      <Col
                        xs={19}
                        md={15}
                        className="job-card-3 company-page-jobcard"
                      >
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          {data.comp_info.bus_name
                            ? data.comp_info.bus_name?.charAt(0).toUpperCase() +
                              data.comp_info.bus_name?.slice(1).toUpperCase()
                            : data.comp_info.comp_name?.charAt(0).toUpperCase() +
                              data.comp_info.comp_name?.slice(1).toUpperCase()}
                          {data.isFreelancer ? (
                            <div style={{ margin: '-6px 0px 0px 10px' }}>
                              <Tooltip placement="top" title={'Freelancer'}>
                                <UserOutlined
                                  style={{
                                    fontSize: '20px',
                                    color: '#ed5050',
                                  }}
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            ''
                          )}
                          {!data.isCompanyActivated ? (
                            <div style={{ margin: '-6px 0px 0px 10px' }}>
                              <Tooltip
                                placement="top"
                                title={'Company Not Activated'}
                              >
                                <AuditOutlined
                                  style={{
                                    fontSize: '20px',
                                    color: '#ed5050',
                                  }}
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col xs={0} md={6}>
                        <div className="emp-on-smallscreen2">
                          {data.comp_info.noOf_emp > 0 && (
                            <p className="job-card-7">{`${data.comp_info.noOf_emp} Employees`}</p>
                          )}
                        </div>
                      </Col>
                      <Col xs={5} md={3}>
                        <div
                          className="text-right p-0"
                          style={{
                            display: 'flex',
                            justifyContent: 'end',
                            fontSize: '18px',
                          }}
                          onClick={(e) => this.handleLocationChange(e, data)}
                        >
                          <SendOutlined className="mapIcon" />
                        </div>
                      </Col>
                    </Row>
                    {/* category section */}
                    <Row
                      style={
                        {
                          // border: '2px solid red',
                        }
                      }
                    >
                      <Col span={20}>
                        {data.comp_info.comp_category?.length > 0 && (
                          <p className="job-card-4">
                            {data.comp_info?.comp_category?.length > 1
                              ? `${data.comp_info?.comp_category?.[0]?.categoryName?.toUpperCase()}, + ${
                                  data.comp_info?.comp_category?.length - 1
                                } others`
                              : data.comp_info?.comp_category?.[0]?.categoryName?.toUpperCase()}
                          </p>
                        )}
                      </Col>
                    </Row>
                    {/* location section */}
                    <Row style={{ marginBottom: '-15px' }}>
                      <Col xs={16}>
                        <p
                          className="job-card-location-section"
                          style={{ textTransform: 'capitalize' }}
                        >
                          <img
                            src={process.env.PUBLIC_URL + '/location-red.png'}
                            alt="img"
                          />
                          &nbsp;&nbsp;
                          {data?.contact_info?.sub_address}
                        </p>
                      </Col>
                      {/* sponser section for big screen */}
                      <Col xs={8} className="sponsored">
                        <p className="job-card-8">
                          {data.isSponsored ? 'SPONSORED' : ''}
                        </p>
                      </Col>
                    </Row>
                    {/* number of employee on small screen */}
                    <Row className="emp-on-smallscreen">
                      <Col xs={16}>
                        <div>
                          {data.comp_info.noOf_emp > 0 && (
                            <p className="job-card-7">{`${data.comp_info.noOf_emp} Employees`}</p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    {/* bottom section */}
                    <Row>
                      <Col
                        className="company-card-bottom-section-number-of-emp"
                        xs={24}
                        md={20}
                        style={{
                          marginTop: '8px',
                          border: 'red',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className="right-card-border pr-md-4 pr-1">
                          <p className="mb-0 text-bold"> {data.jobs}</p>
                          <p className="company-card-7 mb-0">Jobs</p>
                        </div>
                        <div className="right-card-border  pr-md-4 pr-1 ml-0">
                          <p className="mb-0 text-bold">{data.products}</p>
                          <p className="company-card-7 mb-0">Products</p>
                        </div>
                        <div className="right-card-border  pr-md-4 pr-1 ml-0">
                          <p className="mb-0 text-bold">{data.services}</p>
                          <p className="company-card-7 mb-0">Services</p>
                        </div>
                        <div>
                          <p className="mb-0 text-bold">{data.comp_info.age}</p>
                          <p className="company-card-7 mb-0">Avg age</p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))
            )}
            {/* for pagination on company page */}
            {this.state.companies.length !== 0 ? (
              <div className={'paginate-container'}>
                <Pagination
                  activePage={this.state.currentPage}
                  itemsCountPerPage={20}
                  firstPageText={false}
                  lastPageText={false}
                  totalItemsCount={this.state.totalData}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => {
                    this.loadData(pageNumber);
                  }}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyList: state.jobManagementReducer.companyList,
    isLoading: state.jobManagementReducer.isLoading,
  };
};
export default withRouter(connect(mapStateToProps)(CompanyListCard));
