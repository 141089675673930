import React, { Component, useEffect, useRef, useState } from 'react';
import styles from './index.module.css';
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Form,
  message,
  Divider,
  Space,
  Modal
} from 'antd';
import { LeftOutlined ,PlusCircleOutlined} from '@ant-design/icons';
import CurrentMapLocation from '../../../Routes/test';
import APIManager from '../../../APIManager';
import { CURRENCY } from '../../constants/currency';
import GoogleSearch from '../../candidate/googlesearch';
// import { Modal } from 'react-bootstrap';
const { TextArea } = Input;
const { Option } = Select;


const CreateResourceForm = ({ editData, editResource, changeResourceState }) => {
  const [location, setLocationField] = useState('');
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [checkToggle, setCheckToggle] = useState('');
  const [contentType, setContentType] = useState('');
  const [uploadResume, setUploadResume] = useState('');
  const [uploadUrl, setUploadUrl] = useState('');
  const [resume, setResume] = useState('');
  const [editJob, setEditJob] = useState('');
  const [addSkill, setAddSkill] = useState(false);
  const [uploadDocument,setUploadDocument] = useState('');
  const [documents, setDocuments] = useState('');
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState('');
  const [pageNumber,setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const takecurrentLocation = (value) => {
    setLocationField(value);
  };
  const takeLatLong = (lat, long) => {
    setLat(lat);
    setLong(long);
  };
  const checkToggleFunc = (checkToggle) => {
    setCheckToggle(checkToggle);
  };
  useEffect(() => {
    APIManager.allSkillCategories(1,'',20).then((resp) => {
      let options = [];
      if (resp) {
        const opt = resp.data.data;
        opt.forEach((data) => {
          const suggestionExist = categories.find(
            (x) => x.key === data._id
          );
          if (typeof suggestionExist === 'undefined') {
            options.push({
              label: data ? data.title : '',
              value: data ? data._id : '',
              key: data._id,
            });
          }
        });
        if (options.length > 0) {
          let arr = [...categories, ...options];
          setCategories(arr);
        }
      }
    });
    },[])
  const onFinish = (values) => {
   let params = {
    location: values.location,
    availability: values.Availabilty,
    email: values.Email,
    experience: values.Experience,
    information: values.Information,
    skills: values.Category,
    title: values.title,
    price: {
      currency: values.price.currency,
      price: values.price.amount
    },
    job_payment: values.job_payment,
    document: documents,
    resume: resume,
    latitude: lat,
    longitude: long
    }
    if(editResource === true){
      params.id = editData._id
      APIManager.editResource(params)
      .then((response) => {
        if (response?.data?.isSuccess) {
          message.info('Resource is edited!');
          changeResourceState();
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
    } else {
    APIManager.createResource(params)
    .then((response) => {
      if (response?.data?.isSuccess) {
        message.info('Resource is created!');
        changeResourceState();
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.message);
    });
  };
}
  const setLocation=(location)=>{
    formRef.current.setFieldsValue({location:location})
    console.log(formRef.current.getFieldsValue())
  }
  const uploadResumeFile = () => {
    fetch(uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: uploadResume,
    })
      .then((resp) => {})
      .catch((err) => console.log(err));
  };
  const uploadDocumentFile = () => {
    fetch(uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: uploadDocument,
    })
      .then((resp) => {})
      .catch((err) => console.log(err));
  };
  const handleCategorySearch = (value) => {
    setSearch(value);
    setCategories([]);
    getCategories(1, value);
  };
  const onScroll = (event) => {
    let target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = pageNumber + 1;
      getCategories(page, search);
    }
  };
  const getCategories = (pageNum = 1, search = '') => {
    setPageNumber(pageNum);
    let options = [];
    let limit = editJob ? 10 : 3328
    APIManager.allSkillCategories(pageNum, search,limit).then((resp) => {
      if (resp) {
        const opt = resp.data.data;
        opt.forEach((data) => {
          const suggestionExist = categories.find(
            (x) => x.key === data._id
          );
          if (typeof suggestionExist === 'undefined') {
            options.push({
              label: data ? data.title : '',
              value: data ? data._id : '',
              key: data._id,
            });
          }
        });
        if (options.length > 0) {
          let arr = [...categories, ...options];
          setCategories(arr);
        }
      }
    });
  };
  const onChangeResume = (file) => {
    if (file) {
        setContentType(file.type);
        setUploadResume(file);

      const uploadParams = {
        files: [
          {
            requestType: 'CandidateProfile',
            contentType: file.type,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            setUploadUrl(data.url);
            setResume(data.fileName);
          });
         uploadResumeFile();
        }
      });
    }
  };
  const onChangeDocument = (file) => {
    if (file) {
        setContentType(file.type);
        setUploadDocument(file);
      const uploadParams = {
        files: [
          {
            requestType: 'CandidateProfile',
            contentType: file.type,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            setUploadUrl(data.url);
            setDocuments(data.fileName);
          });
          uploadDocumentFile();
        }
      });
    }
  };
  const proceedToAddSkill = (values) => {
    let params = {
      title: values.skill
    }
    APIManager.createSkill(params)
    .then((response) => {
        if (response?.data?.isSuccess) {
            message.info('Skill is added!');
            setAddSkill(false);
            getCategories(1,'');
        }
    })
    .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  }
  let initialValues = {
    title: editData?.title ? editData?.title : '',
    Experience: editData?.experience ? editData?.experience : '',
    Category: editData?.skills?.map((e)=> e._id),
    price: { amount: editData?.price?.price,
      currency: editData?.price?.currency,
    },
    location: editData?.location,
    Email: editData?.email ? editData?.email : '',
    Availabilty: editData?.availability ? editData.availability : '',
    job_payment: editData?.job_payment ? editData.job_payment : '',
    Information: editData?.information ? editData?.information : '',
  }
  return (
    <>
    <div style={{display: 'flex',justifyContent: 'center'}}>
    <Modal
        title="Add skills"
        open={addSkill}
        onCancel={() => setAddSkill(!addSkill)}
        className="add_skill"
        centered
        footer={false}
      >
        <Form
          onFinish={proceedToAddSkill}
        >
        <Form.Item
        name="skill"
        label="Add Your Skill"
        >
          <Input className={styles.skillAdd} placeholder="Enter your skill" />
        </Form.Item>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Form.Item>
          <Button type="text" onClick={() => setAddSkill(false)}>
            Close
          </Button>
          <Button type="primary" htmlType="submit">
            Proceed
          </Button>
          </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
      <Row>
        <Col md={24}>
        <Form
          layout="vertical"
          name="product-add-edit-form"
          onFinish={onFinish}
          ref={formRef}
          initialValues = {initialValues}
        >
          <Row>
            <Col xs={24} className={styles.resource}>
              <div className={styles.goBackDiv} onClick={() => changeResourceState()}>
                <LeftOutlined
                  className={styles.goBack}
                />
                <span className={styles.goBack}>Go back</span>
              </div>
            </Col>
            <Col xs={24} className={styles.resource}>
              <span className={styles.addResource}> Add Resource </span>
            </Col>

            <Col xs={0} md={24} className={styles.rightColumn}>
              <Form.Item>
                <Button className={styles.createButton} htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.formFields}>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Title"
                  name="title"
                  className="inputLabel"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Title!',
                    },
                  ]}
                >
                  <Input className={styles.inputTab} placeholder="Title" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Experience"
                  name="Experience"
                  className="inputLabel"
                  rules={[
                    {
                      type: Number,
                      required: true,
                      message: 'Please Enter Experience in Years',
                    },
                  ]}
                >
                  <Input
                    type='number'
                    className={styles.inputTab}
                    placeholder="Enter Experience in Years"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={12}>
              <Form.Item
              label="Skills"
              name="Category"
              className='inputLabel'
              rules={[{ required: true, message: 'Required Field!' }]}
            >
               <Select
                  mode="multiple"
                  allowClear
                  removeIcon={
                    <img
                      style={{ width: 14, height: 14 }}
                      src={process.env.PUBLIC_URL + '/cancel.png'}
                      alt=""
                    />
                  }
                  defaultValue={editData?.skills?.map((e)=> e._id)}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Space style={{ display: 'flex', justifyContent:'center', width: '100%'}}>
                        <Button type="text" onClick={()=>{setAddSkill(!addSkill)}}>
                        <PlusCircleOutlined style={{fontSize: '20px', opacity: '0.5'}} />
                        </Button>
                      </Space>
                    </>
                  )}
                  // maxTagCount={1}
                  style={{ width: '100%' }}
                  filterOption={false}
                  placeholder="Select Skills"
                  className="input-label-field-custom-select-type-2"
                  onPopupScroll={onScroll}
                  onSearch={handleCategorySearch}
                  options={categories}
              > 
              </Select>
            </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={ <label
                    style={{
                      color: '#24262b7a',
                      font: 'normal normal 400 16px/19px Gilroy semiBold',
                    }}
                  >
                    Location
                  </label>}
                  name="location"
                >
                  <Input
                    size="large"
                    className="s-input-fields"
                    placeholder="Location"
                    style={{ display: 'none' }}
                    defaultValue={initialValues.location}
                  />
                  <GoogleSearch
                    className="s-input-fields"
                    placeholder="Enter Location"
                    takecurrentLocation={takecurrentLocation}
                    takeLatLong={takeLatLong}
                    setLocation={setLocation}
                    location={initialValues?.location}
                    //   handleSave={this.handleSaveButton}
                    checkToggleFunc={checkToggleFunc}
                  />
                  {lat && long && checkToggle && (
                    <div
                      className="form-group mb-signup-1"
                      style={{
                        position: 'relative',
                        width: '100%',
                        height: '300px',
                      }}
                    >
                      <CurrentMapLocation
                        lat={lat}
                        long={long}
                        takeLatLong={takeLatLong}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Your contact Email"
                  name="Email"
                  className="inputLabel"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Email',
                    },
                  ]}
                >
                  <Input className={styles.inputTab} placeholder="Enter your contact Email" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Availabilty"
                  name="Availabilty"
                  className="inputLabel"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Availabilty',
                    },
                  ]}
                >
                  <Input
                    className={styles.inputTab}
                    placeholder="Availability"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={12}>
              <Form.Item label="Price" >
                <Input.Group compact>
                  <Form.Item
                    name={['price', 'currency']}
                    style={{ display: 'inline-block' }}
                  >
                    <Select
                      className="currency-select-style"
                      style={{ width: "65px" }}
                      defaultValue={initialValues?.price?.currency}
                    >
                      {CURRENCY?.map((curr) => (
                        <Option
                          value={curr.name}>{curr.symbol}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={['price', 'amount']}
                    style={{ display: 'inline-block' }}
                    rules={[{ required: false}]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Price"
                      type="number"
                      className={"price-input-service"}
                      defaultValue={initialValues?.price?.amount}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Job Payment"
                  name="job_payment"
                  className="inputLabel"
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Job Payment Type',
                    },
                  ]}
                >
                  <Select
                    name="job_payment"
                    className="input-label-field-custom-select-type-4"
                    placeholder="Select job payment"
                  >
                    <Option value="Hourly">Hourly</Option>
                    <Option value="Daily">Daily</Option>
                    <Option value="Fixed">Fixed Price</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={
                    <label
                      style={{
                        color: '#24262b7a',
                        font: 'normal normal 400 16px/19px Gilroy semiBold',
                        marginBottom: '15px',
                        marginTop: '15px',
                      }}
                    >
                      Upload Resume
                    </label>
                  }
                  name="uploadResume"
                  rules={[{ required: false, message: 'Required Field!' }]}
                >
                 <Input
                  accept="pdf/*"
                  id="raised-button-file"
                  type="file"
                  className="input-field-custom-type-1-left input-border"
                  onChange={(event) => {
                    onChangeResume(event.target.files[0]);
                  }}
                />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="uploadDocument"
                  label={
                    <label
                      style={{
                        color: '#24262b7a',
                        font: 'normal normal 400 16px/19px Gilroy semiBold',
                        marginBottom: '15px',
                        marginTop: '15px',
                      }}
                    >
                      Upload Documents
                    </label>
                  }
                  rules={[{ required: false, message: 'Required Field!' }]}
                >
                  <Input
                  accept="pdf/*"
                  id="raised-button-file"
                  type="file"
                  className="input-field-custom-type-1-left input-border"
                  onChange={(event) => {
                    onChangeDocument(event.target.files[0]);
                  }}
                  defaultValue={initialValues.document}
                />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <Form.Item
                  label={
                    <label
                      style={{
                        color: '#24262b7a',
                        font: 'normal normal 400 16px/19px Gilroy semiBold',
                        marginBottom: '15px',
                        marginTop: '15px',
                      }}
                    >
                      Information
                    </label>
                  }
                  name="Information"
                >
                  <TextArea
                    className={styles.inputTabInfo}
                    placeholder="Enter Information"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={0} style={{marginLeft: '20px'}}>
              <Form.Item>
                <Button className={styles.createButton} htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Col>
            </Row>
          </div>
        </Form>
        </Col>
      </Row>
    </>
  );
};
export default CreateResourceForm;
