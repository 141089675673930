import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Modal, Button } from 'antd';
import Cancel from '../asset/cancel_svg.svg';
import CompanyForm from './company_form.js';
import CandidateForm from './candidate_form.js';
import APIManager from '../../APIManager/index';
import { message } from 'antd';
import './rightModal.css';
import SubcontractForm from './subcontract_form';

const ConfirmPopUp = (props) => {
  const { message, closePopUp, handleOk } = props;
  return (
    <>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: '#24262B66',
          backdropFilter: 'blur(6px)',
          position: 'fixed',
          left: '0',
          top: '0',
          zIndex: '100',
        }}
      >
        <div
          style={{
            width: '30%',
            height: '15vw',
            backgroundColor: 'white',
            borderRadius: '10px',
            opacity: '1',
            margin: '10vw 0 0 35vw',
          }}
        >
          <div
            style={{ height: '2vw', paddingLeft: '27vw', paddingTop: '1vw' }}
          >
            <img src="../../cancel-circle.png" onClick={closePopUp} alt='cancel-circle'></img>
          </div>
          <div style={{ margin: '2vw 0 0 3vw', position: 'relative' }}>
            <img src="../delete-icon.png" alt='delete'/>
            <span
              style={{
                fontSize: '1.5vw',
                fontWeight: '900',
                marginLeft: '10px',
              }}
            >
              {message}
            </span>
          </div>
          <div>
            <button
              style={{
                margin: '10% 0 0 10%',
                width: '20%',
                backgroundColor: '#EE5050',
                color: '#ffffff',
                borderRadius: '5px',
                border: 'none',
              }}
              onClick={closePopUp}
            >
              No
            </button>
            <button
              style={{
                margin: '10% 0 0 10%',
                width: '20%',
                backgroundColor: '#ffffff',
                color: '#EE5050',
                borderRadius: '5px',
                border: '2px solid #EE5050',
              }}
              onClick={handleOk}
            >
              yes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

class ApplyJobModal extends Component {
  constructor(props) {
    super(props);
    this.submitElement = React.createRef();
    this.state = {
      confirmPopUp: false,
      showSaveButton: true,
    };
  }
  alterSaveButton = (value) => {
    this.setState({ showSaveButton: value });
  };
  handleFormSubmit = () => {
    this.setState({ confirmPopUp: true });
  };
  handleOk = () => {
    this.setState({ confirmPopUp: false });
    this.submitElement.current.clickSubmitClick();
  };
  handleCancel = () => {
    // document.body.style.overflow = "auto"
    this.props.isClose();
  };
  clickSubmit = (data) => {
    this.props.uploaded(true);
    this.props.isClose();
    let user_type = JSON.parse(localStorage.getItem('user_type') || false);
    const user_type2 = JSON.parse(localStorage.getItem('user_type2') || false);
    user_type = user_type / (user_type2 * 99);

    data.forEach((val) => {
      user_type === 1
        ? APIManager.applyForJob(val)
            .then((response) => {
              if (response.data.isSuccess) {
                this.props.refreshPage();
                this.props.isClose();
                message.info('Apply for job successfully');
                // this.props.isClose()
              }
            })
            .catch((err) => {
              message.error('Something went wrong.');
            })
        : APIManager.applyForSubcontractorJob(val)
            .then((response) => {
              if (response.data.isSuccess) {
                this.props.refreshPage();
                this.props.isClose();
                message.info('Apply for job successfully');
                // this.props.isClose()
              }
            })
            .catch((err) => {
              message.error('Something went wrong.');
            });
    });
    this.props.uploaded(false);
  };

  render() {
    let user_type = JSON.parse(localStorage.getItem('user_type') || false);
    const user_type2 = JSON.parse(localStorage.getItem('user_type2') || false);
    user_type = user_type / (user_type2 * 99);
    return (
      <div>
        {this.props.isOpen ? (
          <Modal
            title={this.props.title}
            visible={this.props.isOpen}
            onOk={this.handleOk}
            destroyOnClose={true}
            cancelButtonProps={{ style: { display: 'none' } }}
            closeIcon={<img src={Cancel} alt='cancel'/>}
            onCancel={this.handleCancel}
            className={'right-modal ' + this.props.className}
            maskClosable={true}
            footer={
              this.state.showSaveButton && (
                <Button
                  key="submit"
                  type="primary"
                  style={{ height: '45px' }}
                  className="btn btn-dark bold-family btn-save-font cursor"
                  onClick={this.handleFormSubmit}
                >
                  Submit
                </Button>
              )
            }
          >
            {this.state.confirmPopUp && (
              <ConfirmPopUp
                message="Are you sure you want to apply for this job"
                closePopUp={() => {
                  this.setState({ confirmPopUp: false });
                }}
                handleOk={this.handleOk}
              />
            )}
            {this.props.jobtype !== 'subcontractor' &&
              (user_type === 1 ? (
                <CandidateForm
                  ref={this.submitElement}
                  id={this.props.id}
                  clickSubmit={this.clickSubmit}
                />
              ) : (
                <CompanyForm
                  ref={this.submitElement}
                  id={this.props.id}
                  comp_name={this.props.comp_name}
                  clickSubmit={this.clickSubmit}
                />
              ))}
            {this.props.jobtype === 'subcontractor' && (
              <SubcontractForm
                ref={this.submitElement}
                title={this.props.job_title}
                comp_name={this.props.comp_name}
                jobtype={this.props.jobtype}
                id={this.props.id}
                clickSubmit={this.clickSubmit}
                currency={this.props.currency}
                handleFormSubmit={this.handleFormSubmit}
                alterSaveButton={this.alterSaveButton}
              />
            )}
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default ApplyJobModal;
