import React, { Component } from 'react';
import { Row } from 'antd';
import ApplicationStatus from './application-status-modal';
import  './editCompanyModal.css';

class AppliedJobStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callAddMedia: false,
      status: this.props.status,
    };
  }
  componentDidMount = () => {
    this.setState({
      callAddMedia: false,
      status: this.props.status,
    });
  };
  handleClick = () => {
    this.setState({ callAddMedia: true });
  };
  closeModal = (status) => {
    if (status) {
      this.setState({ status });
    }
    this.setState({ callAddMedia: false });
  };
  render() {
    return (
      <>
        <Row>
          <img
            src={process.env.PUBLIC_URL + '/' + this.state.status + '.png'}
            className='marginMobile1'
          />
          <p
            className="all-applied-resume-card-9 w-100"
            style={{ fontSize: '1em', cursor: 'pointer' }}
            onClick={this.handleClick}
          >
            next status
          </p>
        </Row>
        {this.state.callAddMedia ? (
          <ApplicationStatus
            isClose={this.closeModal}
            job_id={this.props.job_id}
            status={this.state.status}
            isOpen={this.state.callAddMedia}
          />
        ) : null}
      </>
    );
  }
}
export default AppliedJobStatus;