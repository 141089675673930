import React, { Component, useEffect } from 'react';
import { Row, Col, Select } from 'antd';
import Header from '../header/header.js';
import Footer from './footer';
import './Landing.css';
import './footer.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './solution.module.css';

import { withRouter } from 'react-router-dom';

const Option = { Select };

const Features = (props) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="responsive-div">
      <Header />
      <Row className={styles.container}>
        <Col xs={24}>
          <Col xs={24} className={`${styles.heading} ${styles.marginTp}`}>
            Solutions
          </Col>
        </Col>
        <Row
          className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
        >
          <Col span={24} className={styles.titleValue}>
            Sendbiz offers to very small companies or international groups
            solutions to promote services, products, events, job offers, search
            for subcontractors, news, ... And to get in touch with future
            buyers, suppliers, employees. Sendbiz has been designed to provide
            solutions to all sectors of activity and we are going to offer more
            and more new features to facilitate the networking between companies
          </Col>
        </Row>

        {/* row 1 */}
        <Row gutter={16} style={{ flexDirection: 'row-reverse' }}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.imageTwo}
              src={process.env.PUBLIC_URL + 'solutionFooter1.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Technology / Digital
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                Sendbiz allows all companies offering services and products in
                the field of technology and digital to propose their services
                and products, to find their future employees and also to find or
                propose resources in technical assistance, so you can find
                talent to strengthen your teams and projects and for others find
                missions to employees waiting for project. Share your news and
                also the events in which you participate
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Sell your services/ products
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find your subcontractors/ suppliers
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your events
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.image}
              src={process.env.PUBLIC_URL + 'solution_page_img2.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Consulting
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                Sendbiz offers to all consulting companies (BtoB, BtoC or
                BtoBtoC business consulting) to sell their services, to find
                their future collaborators and also to find or propose resources
                in technical assistance. Expand your customer base. Share your
                news and also the events in which you participate
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Sell your services/ products
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find your subcontractors/ suppliers
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your events
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16} style={{ flexDirection: 'row-reverse' }}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.imageTwo}
              src={process.env.PUBLIC_URL + 'solution_page_img3.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Craft
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                Sendbiz offers to all craftsmen to highlight their know-how,
                skills, experiences and to propose their services and products.
                Expand your customer base. Share your skills and get in touch
                with new customers, suppliers
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Sell your services/ products
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find your subcontractors/ suppliers
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your events
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.image}
              src={process.env.PUBLIC_URL + 'solution_page_img4.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Farmer/Breeder
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                Sendbiz offers to all growers, breeders, farmers to promote and
                sell their products directly to consumers - no more
                intermediaries Develop your sales at the local level and make
                yourself known at the global level.Your know-how, local and
                regional products will be better known and you will be able to
                promote your skills and know-how often very old
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Sell your products
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your events
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16} style={{ flexDirection: 'row-reverse' }}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.imageTwo}
              src={process.env.PUBLIC_URL + 'solution_page_img5.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Automative
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                Today's shoppers do their research online, long before they set
                foot in a dealership. To adapt to this new era, manufacturers
                and dealers need to engage and seduce customers first on the
                web, during and after the purchase.
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Sell your products
                  </Col>
                </Row>

                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruitment
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your event you participate or organise
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.image}
              src={process.env.PUBLIC_URL + 'solution_page_img6.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Communications
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                Combine marketing, sales, commerce, and service on a single
                platform with rich presentations and relevant information to
                connect you with suppliers or customers. You can use Sendbiz to
                reimagine the B2B, B2C customer experience in the field of
                communication.
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Sell your services
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find & process subcontractors
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your event you organise or participate
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16} style={{ flexDirection: 'row-reverse' }}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.imageTwo}
              src={process.env.PUBLIC_URL + 'solution_page_img7.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Consumer Goods
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                Sendbiz brings B2B2C companies a unified platform to personalize
                consumer relationships, achieve business excellence, and work
                efficiently. Consumer goods are products purchased for
                consumption by everyone. Sendbiz allows you to showcase them and
                also sell them, these consumer goods are the end result of
                production and manufacturing. Clothing, food products and
                dishwashers are examples of common consumer goods. ...
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Sell your services/ products
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find your subcontractors/ suppliers
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your events
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.image}
              src={process.env.PUBLIC_URL + 'solution_page_img8.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Education
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                Sendbiz allows you to promote all your training courses and find
                new students. Also indicate your open days, the fairs you
                participate in to increase your enrollment requests
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Sell your services & products (training programmes)
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find your subcontractors
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your event you organise or participate
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16} style={{ flexDirection: 'row-reverse' }}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.imageTwo}
              src={process.env.PUBLIC_URL + 'solution_page_img9.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Energy & Utilities
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                We help companies in the energy and utilities sector to offer
                companies and individuals innovative services and products in
                energy consumption and saving Make your utility business more
                efficient, showcase exceptional customer experiences and
                optimize your resources with Sendbiz.
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Sell your services/ products
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find your subcontractors/ suppliers
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your events
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.image}
              src={process.env.PUBLIC_URL + 'solution_page_img10.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Financial Services
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                The financial services industry relies on customer
                relationships. That's why financial companies can promote their
                products and services with sendbiz to find new customers. You
                can unify your teams with sendbiz to recruit and find your
                subcontractors. Sendbiz, a unique and flexible platform that
                puts the customer at the center of its concerns.
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Sell your services
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find your subcontractors
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your events
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16} style={{ flexDirection: 'row-reverse' }}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.imageTwo}
              src={process.env.PUBLIC_URL + 'solution_page_img11.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Healthcare & Lifescience
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                We can help you build awareness of your specialties and
                accelerate patient acquisition and enrollment. Optimized to meet
                the unique and fast-paced needs of the industry, Sendbiz helps
                pharmaceutical and medical device companies collaborate across
                entire ecosystems, bring new products and services to market
                faster, and recruit your new employees.
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Sell your services/ products
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find your subcontractors/ suppliers
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your events
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.image}
              src={process.env.PUBLIC_URL + 'solution_page_img12.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Manufacturing
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                Offer a personalized customer experience, make your know-how
                known to future customers, find new suppliers and develop
                yourself by finding your future collaborators
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Sell your products
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find your subcontractors/ suppliers
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your events
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16} style={{ flexDirection: 'row-reverse' }}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.imageTwo}
              src={process.env.PUBLIC_URL + 'solution_page_img13.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Media
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                Increase your audience by promoting your services. Build
                audience loyalty. Use powerful, real-time insights and
                actionable data to build audience loyalty and reduce churn. We
                enable audience-driven businesses to access agilely designed
                growth opportunities and leverage powerful data to deliver
                content directly to their customers, accelerating growth and
                increasing retention. Sendbiz gives you the ability to grow your
                customer communities and reward them for their engagement, so
                they become powerful brand advocates. Every business is unique,
                but Sendbiz can help you win the battle for attention. Content
                options are rapidly expanding. So is the way fans consume
                recorded and live content. This solution bridges the gap between
                your customers and your media.
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Sell your services/ media
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find your subcontractors/ suppliers
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your events
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your Contents
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.image}
              src={process.env.PUBLIC_URL + 'solution_page_img14.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Non Profit
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                Make known your actions, your operations in order to find new
                sources of financing and fundraising ,also to find new
                volunteers and collaborators
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Show your services (actions/ operations, product
                    fundraising)
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find your subcontractors/ suppliers
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your events
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16} style={{ flexDirection: 'row-reverse' }}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.imageTwo}
              src={process.env.PUBLIC_URL + 'solution_page_img15.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Public sector
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                Sendbiz is the platform that helps the public sector to
                communicate its missions, services and commitments, giving civil
                servants a view of citizens, stakeholders and partners. We help
                public services to evolve quickly and increase their image with
                the general public and businesses whether it is a large
                administration or a small village administration.
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Show your services
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find your subcontractors
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your future civil servantsRecruit your talents
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your events
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
        {/* row 1 */}
        <Row gutter={16}>
          <Col md={12} xs={24} className={styles.headingCol}>
            <img
              className={styles.image}
              src={process.env.PUBLIC_URL + 'solution_page_img16.png'}
              alt="solutionFooter image"
            ></img>
          </Col>
          <Col md={12} xs={24}>
            <Row
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Col span={24} className="follow-up-heading">
                Retail
              </Col>
              <hr className="follow-up-line" />

              <Col span={24} className={styles.titleValue}>
                Whether you are a small local business or a franchise network
                with Sendbiz create commercial and marketing experiences. Expand
                your network of suppliers, promote your products to attract new
                customers. Communicate on your new products, your promotions
              </Col>

              {/* icons section */}
              <Col span={24}>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_1.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Shell your products
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_2.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Find your subcontractors
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_4.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Recruit your future civil servants
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_5.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Indicate your events
                  </Col>
                </Row>
                <Row
                  align={'middle'}
                  gutter={16}
                  className={styles.iconsParentBox}
                >
                  <Col md={2} xs={3} className={styles.textCenter}>
                    <img
                      className={styles.iconImage}
                      src={process.env.PUBLIC_URL + '/Solution_FAQ_6.png'}
                      alt="icons"
                    />
                  </Col>
                  <Col span={20} className={styles.titleValueIcons}>
                    Share your news & promotions
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* ************* */}
      </Row>
      <Col span={24}>
        <br />
        <br />
        <br />
      </Col>
      <Col span={24}>
        <Footer />
      </Col>
    </div>
  );
};

export default withRouter(Features);
