import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Row } from 'antd';
import { NavLink } from 'react-router-dom';
import './sidebar.css';
import APIManager from '../../APIManager/index';

class SideNav extends Component {
  render() {
    let iscompany = false;
    let isbilling = false;
    let isstat = false;
    let isjobs = false;
    let isappliedCandi = false;
    let isSubcontractor = false;
    let isoffer = false;
    let isSubcontractorReceived = false;
    let isservice = false;
    let isMyResource = false;
    let isSearchResource = false;
    let isproducts = false;
    let isnews = false;
    let isintereted = false;
    let isevents = false;
    let ismailbox = false;
    if (this.props.location.pathname === '/edit/company/profile') {
      iscompany = true;
    } else if (this.props.location.pathname === '/company/billing') {
      isbilling = true;
    } else if (this.props.location.pathname === '/company-statistics') {
      isstat = true;
    } else if (this.props.location.pathname === '/companyjoblist') {
      isjobs = true;
    } else if (this.props.location.pathname === '/AppliedCandidates') {
      isappliedCandi = true;
    } else if (this.props.location.pathname === '/Company_Subcontractors') {
      isSubcontractor = true;
    } else if (this.props.location.pathname === '/Company_offers_sent') {
      isoffer = true;
    } else if (
      this.props.location.pathname === '/Company_Subcontractors_received'
    ) {
      isSubcontractorReceived = true;
    } else if (this.props.location.pathname === '/MyResources') {
      isMyResource = true;
    } else if (this.props.location.pathname === '/SearchResource') {
      isSearchResource = true;
    } else if (this.props.location.pathname === '/company/products') {
      isproducts = true;
    } else if (this.props.location.pathname === '/company/services') {
      isservice = true;
    } else if (this.props.location.pathname === '/company/news') {
      isnews = true;
    } else if (this.props.location.pathname === '/company/interested') {
      isintereted = true;
    } else if (this.props.location.pathname === '/company/events') {
      isevents = true;
    } else if (this.props.location.pathname === '/company-mailbox') {
      ismailbox = true;
    }
    return (
      <Row>
        <div className="nav-side-menu nav-side-height-company">
          <div className="menu-list">
            <h6 className="bold-family profile-head-font">Basic</h6>
            <ul id="menu-content" className="menu-content out">
              <NavLink to={'/edit/company/profile'}>
                {iscompany ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/user-circle.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Profile
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/user-circle-white.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Profile
                  </li>
                )}
              </NavLink>
              {this.props.user_type === 2 ? (
                <NavLink to={'/company/billing'}>
                  {isbilling ? (
                    <li className="sidebar-padding">
                      <img
                        src={process.env.PUBLIC_URL + '/billing-red.svg'}
                        alt="img"
                      />
                      &nbsp;&nbsp;Billing Overview
                    </li>
                  ) : (
                    <li className="sidebar-padding">
                      <img
                        src={process.env.PUBLIC_URL + '/billing.svg'}
                        alt="img"
                      />
                      &nbsp;&nbsp;Billing Overview
                    </li>
                  )}
                </NavLink>
              ) : null}
              <NavLink to={'/company-statistics'}>
                {isstat ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/chart-pie-red.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Statistics
                  </li>
                ) : (
                  <li
                    className="sidebar-padding"
                    onClick={() => {
                      APIManager.getStaticsData();
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + '/chart-pie.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Statistics
                  </li>
                )}
              </NavLink>
            </ul>
            <h6 className="margin-top-for-side-nav-head bold-family profile-head-font">
              Recruitment
            </h6>
            <ul id="menu-content" className="menu-content out">
              <NavLink to={'/companyjoblist'}>
                {isjobs ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/briefcase-red.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Jobs
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/briefcase.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Jobs
                  </li>
                )}
              </NavLink>
              <NavLink to={'/AppliedCandidates'}>
                {isappliedCandi ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/users-red-2.svg'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Applied Candidates
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/users.svg'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Applied Candidates
                  </li>
                )}
              </NavLink>
            </ul>
            <h6 className="margin-top-for-side-nav-head bold-family profile-head-font">
              Consulting
            </h6>
            <ul id="menu-content" className="menu-content out">
              <NavLink to={'/Company_Subcontractors'}>
                {isSubcontractor ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/page-content-red.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Subcontractors
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/page-content.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Subcontractors
                  </li>
                )}
              </NavLink>
              <NavLink to={'/Company_offers_sent'}>
                {isoffer ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/page-check-red.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Offers Sent
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/page-check.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Offers Sent
                  </li>
                )}
              </NavLink>
              <NavLink to={'/Company_Subcontractors_received'}>
                {isSubcontractorReceived ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/page-check-red.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Offers Received
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/page-check.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Offers Received
                  </li>
                )}
              </NavLink>
              <NavLink to={'/SearchResource'}>
                {isSearchResource ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/page-check-red.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Search Resources
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/page-check.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Search Resources
                  </li>
                )}
              </NavLink>
              <NavLink to={'/MyResources'}>
                {isMyResource ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/page-check-red.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;My Resources
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/page-check.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;My Resources
                  </li>
                )}
              </NavLink>
            </ul>
            <h6 className="margin-top-for-side-nav-head bold-family profile-head-font">
              Business
            </h6>
            <ul id="menu-content" className="menu-content out">
              <NavLink to={'/company/services'}>
                {isservice ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/service-red.svg'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Service
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/search.svg'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Service
                  </li>
                )}
              </NavLink>
              <NavLink to={'/company/products'}>
                {isproducts ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/laptop-red.svg'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Product
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/laptop.svg'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Product
                  </li>
                )}
              </NavLink>
              <NavLink to={'/company/news'}>
                {isnews ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/newspaper-red.svg'}
                      alt="img"
                    />
                    &nbsp;&nbsp;News
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/newspaper.svg'}
                      alt="img"
                    />
                    &nbsp;&nbsp;News
                  </li>
                )}
              </NavLink>
              <NavLink to={'/company/events'}>
                {isevents ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/events-red.svg'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Events
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/events.svg'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Events
                  </li>
                )}
              </NavLink>
              <NavLink to={'/company/interested'}>
                {isintereted ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/bookmark-red.svg'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Interested
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/bookmark.svg'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Interested
                  </li>
                )}
              </NavLink>
            </ul>
            <h6 className="margin-top-for-side-nav-head bold-family profile-head-font">
              Contact
            </h6>
            <ul id="menu-content" className="menu-content out">
              <NavLink to={'/company-mailbox'}>
                {ismailbox ? (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/mailbox_red.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Mailbox
                  </li>
                ) : (
                  <li className="sidebar-padding">
                    <img
                      src={process.env.PUBLIC_URL + '/inbox.png'}
                      alt="img"
                    />
                    &nbsp;&nbsp;Mailbox
                  </li>
                )}
              </NavLink>
            </ul>
          </div>
        </div>
      </Row>
    );
  }
}

export default SideNav;
