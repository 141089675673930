import React, { useState } from 'react';
import { Drawer } from 'antd';
import './header.css';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import { PauseOutlined } from '@ant-design/icons';
import { fileUrl } from '../../Shared/imageUrlPath';
import userDefaultPic from '../../Components/asset/user.svg';

const NavbarDrawer = ({ loginStatus,logo }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClose = () => {
    setOpenDrawer(!openDrawer);
  };
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };
  return (
    <>
      <PauseOutlined
        rotate={90}
        style={{ fontSize: '35px' }}
        onClick={showDrawer}
      />
      <Drawer
        placement="right"
        onClose={onClose}
        open={openDrawer}
        width={250}
        closable={false}
      >
        <div className='navbar-container'>
          <p className="navlink-smallscreen-profile">
          <span>
          <img className='image' src={logo ? fileUrl.fileUrlPath + logo : userDefaultPic}
            alt="profile image"
          />
          </span>
          <span>
           Profile</span>
           </p>
        </div>
        <div>
          <NavLink className="navlink-smallscreen" to={'/CompanyList'}>
            Companies
          </NavLink>
        </div>
        <div>
          <NavLink className="navlink-smallscreen" to={'/all_jobs'}>
            Jobs
          </NavLink>
        </div>
        <div>
          <NavLink className="navlink-smallscreen" to={'/products'}>
            Products
          </NavLink>
        </div>
        <div>
          <NavLink className="navlink-smallscreen" to={'/services'}>
            Services
          </NavLink>
        </div>
        <div>
          <NavLink className="navlink-smallscreen" to={'/news'}>
            News
          </NavLink>
        </div>
        <div>
          <NavLink className="navlink-smallscreen" to={'/events'}>
            Events
          </NavLink>
        </div>
        <div>
          {loginStatus && (
            <NavLink to={'/company-mailbox'} className="navlink-smallscreen">
              MailBox
            </NavLink>
          )}
        </div>
        <div>
          {loginStatus ? (
            <p
              className="navlink-smallscreen red-color-smallscreen"
              onClick={handleLogout}
              style={{ marginLeft: '-5px', paddingTop: '7px' }}
            >
              Logout
            </p>
          ) : (
            <NavLink
              className="navlink-smallscreen red-color-smallscreen"
              to={'/login'}
            >
              Login/SignUp
            </NavLink>
          )}
        </div>
      </Drawer>
    </>
  );
};
export default NavbarDrawer;
