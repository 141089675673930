import React, { useState, useEffect } from 'react';
import './style.css';
import styles from './mailbox.module.css';
import { Input, Spin, message, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import UserCard from './userCard';
import ChatBox from './chatBox';
import { connect } from 'react-redux';
import APIManager from '../../../APIManager/index';
import CompanyMenuDropDown from '../CompanyMenuDropDown';

const MailBox = (props) => {
  useEffect(() => {
    APIManager.listChannnel();
  }, []);

  //show user in chatbox
  const [chatBox, showChatBox] = useState(false);
  const [smallScreen, showSmallScreen] = useState(false);
  const [chatuser, setChatUser] = useState('');
  const [otherChats, setOtherChats] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openedotherChats, setopenedotherChats] = useState(false);
  const [searchChannel, setSearchChannel] = useState('');
  const user = props.location.userInfo;
  const canId = props.location.canId;
  //get id when the user click on the card
  const getIdfromProps = (userInfo) => {
    setChatUser(userInfo);
    setOtherChats(true);
    setCurrentId(
      userInfo.company
        ? userInfo.compId
        : '' || userInfo.subUser
        ? userInfo.subUser.compId
        : '' || userInfo.candidate
        ? userInfo.candidate.compId
        : ''
    );
    var ls = require('local-storage');
    ls.set('chatUserId', currentId);
    setopenedotherChats(!openedotherChats);
  };
  const changeScreenSize = () => {
    showSmallScreen(!smallScreen);
  };
  //refresh state for new message
  const refreshPage = () => {
    var ls = require('local-storage');
    const id = ls.get('chatUserId');
    setIsLoading(true);
    APIManager.checkChannel(id)
      .then((resp) => {
        if (resp.data.isSuccess) {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        message.error('Something went wrong.');
        setIsLoading(false);
      });
  };

  //search channel by input field
  const handleSearchChannel = (e) => {
    setSearchChannel(e.target.value);
  };
  //resetUnseenCountof message
  const ResetUnseenCount = () => {
    var ls = require('local-storage');
    const id = ls.get('channelId');
    APIManager.resetUnseenCount(id).then((response) => {
      // if (response.data.isSuccess) {
      //   refreshPage();
      // }
    });
  };

  return (
    <>
      <Row>
        <Col xs={24} md={0} style={{ marginBottom: '20px' }}>
          <CompanyMenuDropDown name={'MailBox'} />
        </Col>
      </Row>
      {isLoading && (
        <div className={'d-flex justify-content-center'}>
          <Spin />
        </div>
      )}
      <div className={`mail-box-container ${styles.mailBoxContainer}`}>
        {!smallScreen && (
          <label
            className={`${styles.mailBoxHeading} ${styles.displayNoneForSmall}`}
          >
            <span className={'mail-box-label'}>Mailbox</span>
          </label>
        )}
        <div className={'d-flex'}>
          {!smallScreen && (
            <div className={'w-100 mr-2 mt-1 '}>
              <div className={styles.mailBoxSearchbox}>
                <Input
                  size="large"
                  className="resume-data-1 company-joblist-input service-input mb-2"
                  placeholder="Search for users"
                  prefix={<SearchOutlined />}
                  onChange={handleSearchChannel}
                />
              </div>
              <div
                className={'mt-5'}
                onClick={() => {
                  ResetUnseenCount();
                }}
              >
                <UserCard
                  user={user}
                  ResetUnseenCount={ResetUnseenCount}
                  showChatBox={showChatBox}
                  showSmallScreen={showSmallScreen}
                  screenChange={changeScreenSize}
                  getIdfromProps={getIdfromProps}
                  searchChannel={searchChannel}
                />
              </div>
            </div>
          )}
          {chatBox || user ? (
            <div className={'w-100'}>
              <ChatBox
                showChatBox={showChatBox}
                otherChats={otherChats}
                openedotherChats={openedotherChats}
                showSmallScreen={showSmallScreen}
                user={user}
                chatuser={chatuser}
                refreshPage={refreshPage}
                canId={canId}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    channelList: state.mailBoxReducer.channelList,
    isloading: state.mailBoxReducer.isloading,
  };
};
export default connect(mapStateToProps)(MailBox);
