import React, { Component } from 'react';
import { Row, Col, Select } from 'antd';
import Card from './applied_job_cards';
import APIManager from '../../../APIManager/index';
import { connect } from 'react-redux';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
const { Option } = Select;
class AppliedJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      sortValue: '',
      totalCount: '',
      totalPages: '',
      currentPage: ""
    };
  }
  componentDidMount() {
    this.getJobs("1");
  }
  getJobs = (pageNumber) => {
    APIManager.appliedJobs(this.state.searchQuery, this.state.sortValue, pageNumber).then((response) => {
      if (response.data.isSuccess) {
        this.setState({
          totalCount: response.data.totalCount,
          totalPages: response.data.totalPages,
          currentPage: response.data.currentPage
        })
      }
    })

  }
  handleChange = (event) => {
    this.setState({ searchQuery: event.target.value });
    APIManager.appliedJobs(event.target.value, this.state.sortValue);
  };
  handleSort = (e) => {
    if (e === 'company') {
      this.setState({
        sortValue: 'company.comp_info.comp_name'
      })
      APIManager.appliedJobs(this.state.searchQuery, 'company.comp_info.comp_name');

    } else if (e === 'applied') {
      this.setState({
        sortValue: 'appliedAt'
      })
      APIManager.appliedJobs(this.state.searchQuery, 'appliedAt');

    }
  }
  render() {
    let renderPageNumbers;
    const pageNumbers = [];
    if (this.state.totalCount !== null) {
      for (let i = 1; i <= Math.ceil(this.state.totalCount / 10); i++) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers?.map((number) => {
        // let classes = this.state.currentPage === number ? .active : '';
        return (
          <span
            key={number}
            onClick={() => this.getJobs(number)}
            className={
              this.state.currentPage === number
                ? 'page-number-btn'
                : 'pagination-container'
            }
          >
            {number}
          </span>
        );
      });
    }
    const jobCount = this.props.data ? this.props.data.data.length : '';
    return (
      <div className="">
        <Row>
          <p className="resume-1">
            {jobCount === 0 ? 'Jobs applied' : `${jobCount} Jobs applied`}
          </p>
          <Col span={12} className="billing_overview_input_and_sort">
            {/* <Input
              size="large"
              className="billing-searchbox"
              placeholder="Search for keywords"
              prefix={<SearchOutlined />}
              onChange={this.handleChange}
              value={this.state.searchQuery}
            /> */}
            <Select
              name="permissions"
              defaultValue=""
              className="billing-sort"
              onChange={this.handleSort}
            >
              <Option value="" disabled>
                Sort by
              </Option>
              <Option value="company">Company</Option>
              <Option value="applied">Applied</Option>
            </Select>
          </Col>
        </Row>
        <Card searchQuery={this.state.searchQuery} />
        {jobCount > 0 ? (
          <div className={'d-flex justify-content-end pt-4'}>
            <span
              className={
                this.state.currentPage === 1 ? 'pagination-side-btn' : ''
              }
              onClick={() => (this.state.currentPage !== 1 ? this.getJobs(this.state.currentPage - 1) : '')}
            >
              {' '}
              <LeftOutlined />{' '}
            </span>
            <div>{renderPageNumbers}</div>
            <span className={
              this.state.totalPages === this.state.currentPage ? 'pagination-side-btn' : ''
            }
              onClick={() => (this.state.totalPages !== this.state.currentPage ? this.getJobs(this.state.currentPage + 1) : '')}>
              <RightOutlined />
            </span>
          </div>
        ) : ''
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.appliedJobsReducer.data,
    isloading: state.appliedJobsReducer.isloading,
  };
};
export default connect(mapStateToProps)(AppliedJobs);
