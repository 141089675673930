import React, { Component } from 'react';
import { Row, Col } from 'antd';
import Header from '../../header/header';
import Footer from '../../footer/footer';
import CardUpperSection from './visit_info_card';
import AllTabs from '../../layout/tabsComponent';
import PesonalInfo from './visit_personal_info';
import CandidateEducation from './visit_education';
import CanidateProfessionalInfo from './visit_cand_personal_info';
import { connect } from 'react-redux';
import CoverImage from '../../asset/edit-company.svg';
import './visit_profile.css';
import styles from './visitProfile.module.css';
import APIManager from '../../../APIManager';
import { fileUrl } from '../../../Shared/imageUrlPath';

class VisitCandidate extends Component {
  constructor(props) {
    super(props);
    this.salaryElement = React.createRef();
    this.socialElement = React.createRef();
    this.qualificationElement = React.createRef();
    this.experienceElement = React.createRef();
    this.contactElement = React.createRef();
    this.state = {
      cover: '',
    };
  }
  componentDidMount = async () => {
    const res = await APIManager.companyInfo()
      this.setState({
        cover: res?.data?.data?.data?.can_detail?.candidate_cover_image,
      });
  }
  render() {
    const tabs = [
      { tabname: 'PERSONAL INFO', comp: <PesonalInfo /> },
      { tabname: 'PROFESSIONAL INFO', comp: <CanidateProfessionalInfo /> },
      { tabname: 'EDUCATION', comp: <CandidateEducation /> },
    ];

    return (
      <div>
        <Header />
        <Row>
          <Col span={24}>
            <Row className={styles.bannerDiv}>
              <Col span={24}>
                <img
                  className={styles.bannerImage}
                  src={
                    this.state.cover
                      ? fileUrl.fileUrlPath + this.state.cover
                      : CoverImage
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={2} xs={0}></Col>
              <Col sm={20} xs={22} style={{ margin: 'auto' }}>
                <div
                  className="candidate-info-card-1"
                  style={{ borderRadius: 10, paddingBottom: 10 }}
                >
                  <CardUpperSection />
                  <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <div className="visit_candidate_1">
                      <AllTabs
                        company_tabs={tabs}
                        class={'candidat-profile-info-tab'}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={2} xs={0}></Col>
            </Row>
          </Col>
        </Row>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.companyInfoReducer.data,
    isloading: state.companyInfoReducer.isloading,
  };
};
export default connect(mapStateToProps)(VisitCandidate);
