import React, { useState, useEffect } from 'react';
import userDefaultPic from '../../asset/user.svg';
import pin from '../../asset/pin.svg';
import './style.css';
import styles from './mailbox.module.css';
import sendButton from '../../asset/send.svg';
import APIManager from '../../../APIManager/index';
import { message, Spin, Image, Row, Col } from 'antd';
import { connect } from 'react-redux';
import {
  PlusCircleOutlined,
  FilePdfOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { Upload } from 'antd';
import { ImageUrl } from '../../../Shared/imageUrlPath';
import refresh from '../../asset/refresh.svg';
import { fileUrl } from '../../../Shared/imageUrlPath';
const ChatBox = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (props.chatuser) {
      sethasmore(true);
      var ls = require('local-storage');
      const id = ls.get('channelId');
      APIManager.listChat(id, '1')
        .then((resp) => {
          if (resp.status === 200) {
            setisLoading(false);
            setData([...resp.data.data]);

            setCurrentPage(resp.data.currentPage);
            setLoadmorebtn(resp.data.hasMore);
          }
        })
        .catch((err) => {
          setisLoading(false);
          message.error('Something went wrong......');
        });
    }
  }, [props.chatuser]);
  const [current_page, setCurrentPage] = useState('');
  const [messgaeInput, setMessageInput] = useState('');
  const [hasmore, sethasmore] = useState(false);
  const [loadmorebtn, setLoadmorebtn] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [contentType, setContentType] = useState('');
  const [fileName, setFileName] = useState([]);
  const [isImageUploaded, setImageUploaded] = useState(false);
  const [textArea, showTextArea] = useState(true);

  let imageUploadCounter = 0;
  const setCounter = () => {
    imageUploadCounter = 0;
  };
  //to send image and file in chat
  const handleUploadFiles = ({ fileList }) => {
    if (fileList.length > 0) {
      showTextArea(false);
    } else {
      showTextArea(true);

      setFileName([]);
    }
    imageUploadCounter += 1;
    const fileType = fileList[0] ? fileList[0].type : '';
    setContentType(fileType);
    if (imageUploadCounter < 2) {
      const fileData = fileList[0] ? fileList[0].originFileObj : '';
      const uploadParams = {
        files: [
          {
            requestType: 'Mailbox',
            contentType: contentType,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          fileName.push(resp.data[0].fileName);
          if (resp.data[0] && fileList[0]) {
            uploadFile(resp.data[0].url, fileData);
          }
        }
      });
    } else {
      showTextArea(true);
      console.log('process will not run');
    }
  };
  const uploadFile = (url, file) => {
    fetch(url, {
      method: 'PUT',
      mode: 'cors',
      body: file,
    })
      .then((resp) => {
        if (resp.status === 200) {
          message.info('uploaded!');
        }
      })
      .catch((err) => message.error('Try again.'));
  };
  const receiverId = props.otherChats
    ? props.chatuser.company
      ? props.chatuser.company.compId
      : '' || props.chatuser.subUser
      ? props.chatuser.subUser.compId
      : '' || props.chatuser.candidate
      ? props.chatuser.candidate.compId
      : ''
    : props.canId
    ? props.canId
    : props.user.userId._id;
  const userType =
    (props.chatuser.company && '2') ||
    (props.chatuser.subUser && '3') ||
    (props.chatuser.candidate && '1');
  const listMessages = async () => {
    if (props.chatuser) {
      sethasmore(true);
      var ls = require('local-storage');
      const id = ls.get('channelId');
      await APIManager.listChat(id, '1')
        .then((resp) => {
          if (resp.status === 200) {
            setisLoading(false);
            setData([...resp.data.data]);

            setCurrentPage(resp.data.currentPage);
            setLoadmorebtn(resp.data.hasMore);
          }
        })
        .catch((err) => {
          setisLoading(false);
          message.error('Something went wrong......');
        });
    }
  };
  var ls = require('local-storage');
  const id = ls.get('channelId');
  const listMoreMessages = async (page) => {
    sethasmore(true);
    setisLoading(true);
    await APIManager.listChat(id, page).then((resp) => {
      if (resp.status === 200) {
        setisLoading(false);
        setData((prevState) => [...prevState, ...resp.data.data]);

        setCurrentPage(resp.data.currentPage);
        setLoadmorebtn(resp.data.hasMore);
      }
    });
  };

  const senderId = props.userInfo ? props.userInfo.data.data.comp_id : '';
  const refreshPage = () => {
    APIManager.checkChannel(receiverId);
  };
  const name = props.otherChats
    ? props.chatuser.company
      ? props.chatuser.company.busName
      : '' || props.chatuser.subUser
      ? props.chatuser.subUser.firstname
      : '' || props.chatuser.candidate
      ? props.chatuser.candidate.firstname +
        ' ' +
        props.chatuser.candidate.lastname
      : ''
    : (props.user.userType === 1
        ? props.user.userId.candidateData.can_detail.firstName
        : '') ||
      (props.user.userType === 2
        ? props.user.userId.companyData.comp_info.bus_name
        : '') ||
      (props.user.userType === 3
        ? props.user.userId.subUserData.first_name
        : '') ||
      (props.user ? props.user.name : '') ||
      (props.user.comp_info ? props.user.comp_info.bus_name : '');

  let profile = props.otherChats
    ? props.chatuser.company
      ? props.chatuser.company.logo.name
      : '' || props.chatuser.subUser
      ? props.chatuser.subUser.profile
      : '' || props.chatuser.candidate
      ? props.chatuser.candidate.profile
      : ''
    : (props.user.userType === 1
        ? props.user.userId.candidateData.can_detail.profile
        : '') ||
      (props.user.userType === 2
        ? props.user.userId.companyData.logo.name
        : '') ||
      (props.user.userType === 3
        ? props.user.userId.subUserData.user_image
        : '') ||
      (props.user ? props.user.profile : '') ||
      (props.user.comp_info ? props.user.logo.name : '');

  //handle change in message input
  const handleChange = (event) => {
    setMessageInput(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const param = {
      receiverId: receiverId,
      recieverType: userType,
      messages: [
        {
          text: messgaeInput,
        },
      ],
    };
    const param2 = {
      receiverId: receiverId,
      recieverType: userType,
      messages: [
        {
          attachments: [
            {
              key: fileName[0],
              type: contentType,
            },
          ],
        },
      ],
    };
    if (messgaeInput || fileName[0])
      if (fileName[0]) {
        setImageUploaded(true);
        await APIManager.createMessage(param2)
          .then((resp) => {
            if (resp.data.isSuccess) {
              listMessages();
              refreshPage();
              setMessageInput('');
              setImageUploaded(false);
              setFileName([]);
              showTextArea(true);
            }
          })
          .catch((error) => message.error('Something went wrong!'));
      } else {
        setImageUploaded(true);
        await APIManager.createMessage(param)
          .then((resp) => {
            if (resp.data.isSuccess) {
              listMessages();
              refreshPage();
              setMessageInput('');
              setImageUploaded(false);
              setFileName([]);
              showTextArea(true);
            }
          })
          .catch((error) => message.error('Something went wrong!'));
      }
  };
  const refreshChat = () => {
    setisLoading(true, 10);
    listMessages();
  };
  const currentChat = props.chatHistory
    ? props.chatHistory?.data?.map((data) => data.messages)
    : '';
  /**************  to close messgae popup *******************/
  const handleCloseEvents = () => {
    props.showChatBox(false);
    props.showSmallScreen(false);
  };

  return (
    <>
      <div
        className={`chat-container ${styles.chatBoxMainConatiner}`}
        onClick={refreshChat}
      >
        {/* refres icons */}
        <div style={{ margin: '10px' }}>
          <span className={'mr-2 plus-btn-container'}>
            {/* <PlusCircleFilled /> */}
            <img src={refresh} alt="img" />
          </span>
          <span className={'new-chat-btn mt-1'}>Refresh</span>
        </div>
      </div>

      <div className={`chat-box-container ${styles.mainContainer}`}>
        <Row className={'chat-box-header p-3 border-bottom'}>
          <Col sm={0} xs={4}>
            {/* close icon */}
            <div className={styles.chatBoxDisplayNone}>
              <CloseOutlined
                onClick={handleCloseEvents}
                className={styles.closeIcons}
              />
            </div>
          </Col>
          <Col
            sm={24}
            xs={16}
            style={{
              alignContent: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div className={'user-pic-container'}>
              <img
                src={profile ? fileUrl.fileUrlPath + profile : userDefaultPic}
                alt={'user'}
                className={'user-pic'}
              />
            </div>
            <div className={'w-100 ml-2'}>
              <span className={'user-name'}>{name ? name : ''}</span>
            </div>
          </Col>
          <Col sm={0} xs={4}>
            <div
              className={`chat-container`}
              onClick={refreshChat}
              style={{ marginTop: '5px' }}
            >
              {/* refres icons */}
              <div>
                <span className={'plus-btn-container'}>
                  <img src={refresh} alt="img" />
                </span>
                <span className={'new-chat-btn mt-1'}>Refresh</span>
              </div>
            </div>
          </Col>
        </Row>

        <div className={'msg-container'}>
          {props.isloading || isLoading ? (
            <div className={'d-flex justify-content-center'}>
              <Spin />{' '}
            </div>
          ) : (
            ''
          )}
          {loadmorebtn || currentChat.length >= 1 ? (
            // <button onClick={() => listMoreMessages(current_page + 1)}>
            //   OK
            // </button>
            <div className={'d-flex justify-content-center'}>
              <PlusCircleOutlined
                onClick={() => listMoreMessages(current_page + 1)}
              />
            </div>
          ) : (
            ''
          )}
          {hasmore
            ? data &&
              data
                ?.slice(0)
                ?.reverse()
                ?.map((data) => (
                  <>
                    {!data?.comp_id?.includes(senderId) ? (
                      <div className={''}>
                        {data.text && (
                          <div
                            className={
                              'd-flex justify-content-start time-stamp'
                            }
                          >
                            <b>
                              {data?.first_name + ' ' + data?.last_name + ' '}
                            </b>{' '}
                            &nbsp;&nbsp;
                            {moment(data.created_ts).fromNow()}
                          </div>
                        )}
                        {/* left side wale */}
                        {data.text && (
                          <pre
                            style={{
                              font: 'normal normal 600 14px/17px Gilroy',
                            }}
                            className={'msg-style-user m-3'}
                          >
                            {data.text}
                          </pre>
                        )}
                        {data.attachments[0] &&
                        data.attachments[0].type === 'application/pdf' ? (
                          <div
                            className={
                              'd-flex msg-style-user justify-content-start'
                            }
                          >
                            <a
                              href={
                                data.attachments[0] &&
                                fileUrl.fileUrlPath + data.attachments[0].key
                              }
                              rel="noreferrer"
                              target="_blank"
                            >
                              <FilePdfOutlined />
                            </a>
                          </div>
                        ) : (
                          ''
                        )}

                        <div>
                          {data.attachments.length > 0 &&
                          data.attachments[0] &&
                          data.attachments[0].type !== 'application/pdf' ? (
                            <div
                              className={
                                'd-flex msg-style-user justify-content-start'
                              }
                            >
                              <Image
                                height={'300'}
                                width={'100%'}
                                src={data.attachments?.map(
                                  (data) => ImageUrl.imageUrlPath + data.key
                                )}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className={'m-2'}>
                        {data.text && (
                          <div
                            className={'d-flex justify-content-end time-stamp'}
                          >
                            {moment(data.created_ts).fromNow()}
                            &nbsp;
                            <b>{data?.first_name + ' ' + data?.last_name}</b>
                          </div>
                        )}
                        {data.text && (
                          <pre
                            style={{
                              font: 'normal normal 600 14px/17px Gilroy',
                            }}
                            className={'msg-style-self m-3'}
                          >
                            {data.text}
                          </pre>
                        )}
                        {data.attachments[0] &&
                        data.attachments[0].type === 'application/pdf' ? (
                          <div
                            className={
                              'd-flex msg-style-self justify-content-end'
                            }
                          >
                            <a
                              href={
                                data.attachments[0] &&
                                fileUrl.fileUrlPath + data.attachments[0].key
                              }
                              rel="noreferrer"
                              target="_blank"
                            >
                              <FilePdfOutlined />
                            </a>
                          </div>
                        ) : (
                          ''
                        )}
                        <div>
                          {data.attachments.length > 0 &&
                          data.attachments[0] &&
                          data.attachments[0].type !== 'application/pdf' ? (
                            <div
                              className={
                                'd-flex msg-style-self justify-content-end'
                              }
                            >
                              <Image
                                height={'300'}
                                width={'100%'}
                                src={data.attachments?.map(
                                  (data) => ImageUrl.imageUrlPath + data.key
                                )}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ))
            : currentChat.length !== 0 &&
              currentChat[0]?.map((data) => (
                <>
                  {!data.user_id.includes(senderId) ? (
                    <div className={'m-2'}>
                      {data.text && (
                        <div
                          className={'d-flex justify-content-start time-stamp'}
                        >
                          <b>{data?.first_name + ' ' + data?.last_name} </b>{' '}
                          &nbsp;
                          {moment(data.created_ts).fromNow()}
                        </div>
                      )}
                      {data.text && (
                        <div
                          style={{ font: 'normal normal 600 14px/17px Gilroy' }}
                          className={'msg-style-user m-2'}
                        >
                          {data.text}
                        </div>
                      )}
                      {data.attachments[0] &&
                      data.attachments[0].type === 'application/pdf' ? (
                        <div
                          className={
                            'd-flex msg-style-user justify-content-start'
                          }
                        >
                          <a
                            href={
                              data.attachments[0] &&
                              fileUrl.fileUrlPath + data.attachments[0].key
                            }
                            rel="noreferrer"
                            target="_blank"
                          >
                            <FilePdfOutlined />
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                      <div>
                        {data.attachments.length > 0 &&
                        data.attachments[0] &&
                        data.attachments[0].type !== 'application/pdf' ? (
                          <div
                            className={
                              'd-flex msg-style-user justify-content-start'
                            }
                          >
                            <Image
                              height={'300'}
                              width={'100%'}
                              src={data.attachments?.map(
                                (data) => ImageUrl.imageUrlPath + data.key
                              )}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className={'m-2'}>
                      <div className={'d-flex justify-content-end time-stamp'}>
                        {moment(data.created_ts).fromNow()}
                        &nbsp;&nbsp;
                        <b> {' ' + data?.first_name + ' ' + data?.last_name}</b>
                      </div>
                      {data.text && (
                        <div
                          style={{ font: 'normal normal 600 14px/17px Gilroy' }}
                          className={'msg-style-self'}
                        >
                          {data.text}
                        </div>
                      )}
                      {data.attachments[0] &&
                      data.attachments[0].type === 'application/pdf' ? (
                        <div
                          className={
                            'd-flex msg-style-self justify-content-end'
                          }
                        >
                          <a
                            href={
                              data.attachments[0] &&
                              fileUrl.fileUrlPath + data.attachments[0].key
                            }
                            rel="noreferrer"
                            target="_blank"
                          >
                            <FilePdfOutlined />
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                      <div>
                        {data.attachments.length > 0 &&
                        data.attachments[0] &&
                        data.attachments[0].type !== 'application/pdf' ? (
                          <div
                            className={
                              'd-flex msg-style-self justify-content-end'
                            }
                          >
                            <Image
                              height={'300'}
                              width={'100%'}
                              src={data.attachments?.map(
                                (data) => ImageUrl.imageUrlPath + data.key
                              )}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  )}
                </>
              ))}
        </div>
        <form onSubmit={handleSubmit}>
          <div className={'msg-creater-container '}>
            <div className={'w-100 mr-2'}>
              {textArea ? (
                <textarea
                  className={'msg-creater-area'}
                  placeholder={'Type a message here'}
                  value={messgaeInput}
                  onChange={handleChange}
                  rows={7}
                />
              ) : (
                ''
              )}
            </div>
            <div className={'d-flex flex-shrink-0 mr-2'}>
              {!isImageUploaded ? (
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  onClick={() => setCounter}
                  onChange={handleUploadFiles}
                  accept={'image/png, image/jpg,image/jpeg,application/pdf'}
                  beforeUpload={() => {
                    return false;
                  }}
                >
                  {textArea ? (
                    <img src={pin} className={'attach-btn'} alt="img" />
                  ) : (
                    ''
                  )}
                </Upload>
              ) : (
                ''
              )}
            </div>
            <div>
              <button type={'submit'} className={'send-btn-container'}>
                <img src={sendButton} className={'send-btn'} alt="img" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    chatHistory: state.checkChannelReducer.chatHistory,
    isloading: state.checkChannelReducer.isloading,
    messages: state.messagesReducer.messages,
    userInfo: state.AuthReducer.user,
  };
};
export default connect(mapStateToProps)(ChatBox);
