import React, { Component } from 'react';

// import 'antd/dist/antd.css';
import { Layout, Col, Row, Spin, message } from 'antd';
import AllTabs from '../layout/tabsComponent.js';
import CompanyInfo from './company_info_component.js';
import CompanySocial from './company_social_component.js';
import UserProfile from './user_profile.js';
import ContactInfo from './company_contact_info_component.js';
import CompanyMedia from './company_media_component.js';
import companyCover from '../asset/edit-company.svg';
import companylogo from '../asset/edit-company-logo.png';
import './company.css';
import styles from './edit_company_profile.module.css';
import { connect } from 'react-redux';
import APIManager from '../../APIManager';
import CompanyHeader from './companyHeader';
import HELPERS from '../../APIManager/helper';
import CompanyMenuDropDown from './CompanyMenuDropDown.jsx';

class EditCompanyProfile extends Component {
  constructor(props) {
    super(props);
    this.infoElement = React.createRef();
    this.contactElement = React.createRef();
    this.socialElement = React.createRef();
    this.userElement = React.createRef();
  }
  state = {
    form_data_1: '',
    form_data_2: '',
    form_data_3: '',
    company_tabs: [],
    is_clicked: false,
    uploadedImage: companyCover,
    companylogo: companylogo,
    logo: {},
    companyData: '',
  };

  uploadCoverLogo = (data) => {
    let formdata = new FormData();
    if (data.cover) {
      formdata.append('companyCover', data.cover, data.cover.name);
    } else {
      formdata.append('companyLogo', data.logo, data.logo.name);
    }
    APIManager.companyCoverUpdate(formdata)
      .then((response) => {
        if (response.data.isSuccess) {
          message.info('Updated Successfully');
        }
      })
      .catch((err) => message.error('Something went wrong.'));
  };
  setformdata_1 = (data) => {
    this.setState({ form_data_1: data });
  };
  setformdata_2 = (data) => {
    this.setState({ form_data_2: data });
  };
  setformdata_3 = (data) => {
    this.setState({ form_data_3: data });
  };
  setformdata_4 = (data) => {
    this.setState({ form_data_4: data });
  };
  toBase64 = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ uploadedImage: reader.result });
      }
    };
    reader.readAsDataURL(file);
  };
  toBase641 = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ companylogo: reader.result });
      }
    };
    reader.readAsDataURL(file);
  };
  onChange = (newFile) => {
    this.toBase64(newFile);
    let data = this.state.logo;
    data['cover'] = newFile;
    this.uploadCoverLogo(data);
  };
  logoonChange = (newFile) => {
    this.toBase641(newFile);
    let data = this.state.logo;
    data['logo'] = newFile;

    this.uploadCoverLogo(data);
  };
  componentWillMount() {
    HELPERS.getLatLong();
    APIManager.companyInfo();
  }

  render() {
    let loader = this.props.loginloading;

    let tabs = [];
    let companylogo = '';
    let coverImage = '';
    let comp_id = '';
    loader = this.props.isloading;
    if (
      this?.props?.infodata?.data?.data !== null &&
      this?.props?.infodata?.data?.data !== undefined
    ) {
      localStorage.setItem('isPremium', this.props.data.data.data.isPremium);
      var comp_data = this.props.infodata.data.data.comp_info;
      comp_id = this.props.infodata.data.data._id;
      const contact_data = this.props.infodata.data.data.contact_info;
      const social_data = this.props.infodata.data.data.social_link;
      const media_data = this.props.infodata.data.data.comp_media;
      companylogo = this.props.infodata.data.data.logo;
      coverImage = this.props.infodata.data.data.coverImage;
      contact_data.first_name = this.props.infodata.data.user.first_name;
      contact_data.last_name = this.props.infodata.data.user.last_name;
      tabs = [
        {
          tabname: 'COMPANY INFO',
          comp: (
            <CompanyInfo
              setdata={this.setformdata_1}
              ref={this.infoElement}
              initialdata={comp_data}
            />
          ),
        },
        {
          tabname: 'SOCIAL',
          comp: (
            <CompanySocial
              setdata={this.setformdata_3}
              ref={this.socialElement}
              initialdata={social_data}
            />
          ),
        },
        {
          tabname: 'CONTACT INFO',
          comp: (
            <ContactInfo
              setdata={this.setformdata_2}
              ref={this.contactElement}
              initialdata={contact_data}
            />
          ),
        },
        { tabname: 'MEDIA', comp: <CompanyMedia initialdata={media_data} /> },
        {
          tabname: 'USER',
          comp: (
            <UserProfile
              setdata={this.setformdata_4}
              userElement={this.userElement}
              initialdata={contact_data}
            />
          ),
        },
      ];
    } else {
      tabs = [];
    }
    return (
      <>
        <Row>
          <Col xs={24} sm={0}>
            <CompanyMenuDropDown name={'Profile'} />
          </Col>
        </Row>
        <Layout className="layout-backgroud">
          {loader ? (
            <Spin />
          ) : (
            <Layout className="main-margin-top-for-edit-profile">
              <Row className={`custom_row ${styles.headerRowForSmallScreen}`}>
                <Col span={12} className="p-0">
                  <h6 className="candidate_heading">Profile</h6>
                </Col>
                <Col span={12} className="text-right p-0">
                  <div className="custom-file view-profile">
                    <label
                      className="custom-file-label-2"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.props.history.push({
                          pathname: `/comp-id${comp_id}`,
                          state: { compId: comp_id },
                        })
                      }
                    >
                      View public profile
                    </label>
                  </div>
                </Col>
              </Row>

              <CompanyHeader
                companylogo={companylogo}
                coverImage={coverImage}
              />
              <Row>
                <Col xs={24} sm={0}>
                  <div
                    className="custom-file view-profile"
                    style={{
                      textAlign: 'center',
                      marginTop: '10px',
                    }}
                  >
                    <label
                      className="custom-file-label-2"
                      style={{ cursor: 'pointer', fontSize: '20px' }}
                      onClick={() =>
                        this.props.history.push({
                          pathname: `/comp-id${comp_id}`,
                          state: { compId: comp_id },
                        })
                      }
                    >
                      View public profile
                    </label>
                  </div>
                </Col>
              </Row>

              <AllTabs
                company_tabs={tabs}
                class={'company_profile_main_tabs'}
              />
            </Layout>
          )}
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.AuthReducer.user,
  infodata: state.companyInfoReducer.data,
  isloading: state.companyInfoReducer.isloading,
  loginloading: state.AuthReducer.isloading,
});

export default connect(mapStateToProps)(EditCompanyProfile);
