import React, { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import Header from '../header/header.js';
import Footer from './footer';
import './Landing.css';
import './footer.css';
import styles from './footer.module.css';
const ChooseUs = (props) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Row className="responsive-div">
      <Col>
        <Header />
        <Row className="home-outer-div">
          <Col xs={24} className={styles.container}>
            <img
              className={styles.image3}
              src={process.env.PUBLIC_URL + '/choose-bg.png'}
              alt='choose-bg'
            />
          </Col>
          <Col xs={24} className={styles.container}>
            <Button
              type="primary"
              size="large"
              className="feature-button-style"
              onClick={() => props.history.push('/login')}
            >
              Try Sendbiz Now
            </Button>
          </Col>
          <Row className={styles.container}>
            <Col xs={24} md={12} className={styles.container}>
              <img
                className={styles.image3}
                src={process.env.PUBLIC_URL + '/choose-bg1.png'}
                alt='choose-bg1'
              />
            </Col>
            <Col xs={24} md={12} className={styles.innerContainer}>
              <Col xs={24} className="choose-heading">
                Your One stop Solution for HR to Sales
              </Col>
              <Row>
                <Col xs={24}>
                  <Col className={styles.title}>
                    Sendbiz allows you to create a company profile, list open
                    vacancies, and grow the revenue by selling product
                    /services.
                  </Col>
                  <Row style={{ paddingTop: '20px' }}>
                    <Col span={1}>
                      <div className="dot-choose"></div>
                    </Col>
                    <Col span={23} className="choose-pink-points">
                      Company Profile
                    </Col>
                    <Col span={1}></Col>
                    <Col span={23} className="choose-points">
                      Create your company's profile page on SendBiz.
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '20px' }}>
                    <Col span={1}>
                      {/* <Badge color="#D46161"/> */}
                      <div className="dot-choose"></div>
                    </Col>
                    <Col span={23} className="choose-pink-points">
                      Brand Awareness
                    </Col>
                    <Col span={1}></Col>
                    <Col span={23} className="choose-points">
                      Use our rich media for brand awareness.
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '20px' }}>
                    <Col span={1}>
                      {/* <Badge color="#D46161"/> */}
                      <div className="dot-choose"></div>
                    </Col>
                    <Col span={23} className="choose-pink-points">
                      Job Offers
                    </Col>
                    <Col span={1}></Col>
                    <Col span={23} className="choose-points">
                      List all of your job offerings.
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '20px' }}>
                    <Col span={1}>
                      {/* <Badge color="#D46161"/> */}
                      <div className="dot-choose"></div>
                    </Col>
                    <Col span={23} className="choose-pink-points">
                      Product/Services
                    </Col>
                    <Col span={1}></Col>
                    <Col span={23} className="choose-points">
                      Promote your product / services and sell.
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '20px' }}>
                    <Col span={1}>
                      <div className="dot-choose"></div>
                    </Col>
                    <Col span={23} className="choose-pink-points">
                      Unlimited Users
                    </Col>
                    <Col span={1}></Col>
                    <Col span={23} className="choose-points">
                      SendBiz Premium Plan offers unlimited users.
                    </Col>
                  </Row>
                </Col>

                <Col span={5}></Col>
              </Row>
            </Col>
          </Row>
          <Row className={styles.container}>
            <Col xs={24} md={12} className={styles.container}>
              <img
                className={styles.image4}
                src={process.env.PUBLIC_URL + '/choose-bg2.png'}
                alt='choose-bg2'
              />
            </Col>
            <Col xs={24} md={12} className={styles.innerContainer3}>
              <Col xs={24} className="choose-heading">
                Exclusively for SME and VSE
              </Col>
              <Row>
                <Col xs={24}>
                  <Col className={styles.title}>
                    With SendBiz you thrive from small business to global
                    company
                  </Col>
                  <Row style={{ paddingTop: '38px' }}>
                    <Col span={1}>
                      <div
                        className="dot-choose"
                        style={{ marginTop: '14px' }}
                      ></div>
                    </Col>
                    <Col span={23} className="points-exc">
                      <span style={{ color: '#EE5050', opacity: '0.8' }}>
                        Develop
                      </span>{' '}
                      local business
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '22px' }}>
                    <Col span={1}>
                      <div
                        className="dot-choose"
                        style={{ marginTop: '14px' }}
                      ></div>
                    </Col>
                    <Col span={23} className="points-exc">
                      <span style={{ color: '#EE5050', opacity: '0.8' }}>
                        Promote
                      </span>{' '}
                      your product/services
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '22px' }}>
                    <Col span={1}>
                      <div
                        className="dot-choose"
                        style={{ marginTop: '14px' }}
                      ></div>
                    </Col>
                    <Col span={23} className="points-exc">
                      <span style={{ color: '#EE5050', opacity: '0.8' }}>
                        Increase
                      </span>{' '}
                      your sales
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '22px' }}>
                    <Col span={1}>
                      <div
                        className="dot-choose"
                        style={{ marginTop: '14px' }}
                      ></div>
                    </Col>
                    <Col span={23} className="points-exc">
                      <span style={{ color: '#EE5050', opacity: '0.8' }}>
                        Make
                      </span>{' '}
                      B2B connections
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '22px' }}>
                    <Col span={1}>
                      <div
                        className="dot-choose"
                        style={{ marginTop: '14px' }}
                      ></div>
                    </Col>
                    <Col span={23} className="points-exc">
                      <span style={{ color: '#EE5050', opacity: '0.8' }}>
                        Grow
                      </span>{' '}
                      your business network
                    </Col>
                  </Row>
                </Col>
                <Col span={5}></Col>
              </Row>
            </Col>
          </Row>
          <Col xs={0} md={6} className={styles.container1} />
          <Col xs={24} md={12} className={styles.container1}>
            <Row className={styles.titleCol}>
              <Col xs={12} className={styles.displayCenter}>
                <h3 className={styles.textValue}>
                  Register for a free account
                </h3>
              </Col>
              <Col xs={12} className={styles.displayCenter}>
                <h3 className={styles.textValue}>
                  Know the benefits of our Premium Plan
                </h3>
              </Col>
            </Row>
          </Col>
          <Col xs={0} md={6} className={styles.container1} />
          <Col xs={24} className={styles.container4}>
            <Button
              type="primary"
              size="large"
              className={styles.buttonStyle}
              onClick={() => props.history.push('/login')}
            >
              SIGNUP
            </Button>
          </Col>
        </Row>
        <div className="fotter-modif">
          <Footer />
        </div>
      </Col>
    </Row>
  );
};

export default withRouter(ChooseUs);
