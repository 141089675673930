import React, { Component } from 'react';
import { Row, Col } from 'antd';
import AllTabs from '../../layout/tabsComponent.js';
import Product from './product_card.js';
import Event from './event_card';
import Services from './services_card.js';
import Jobs from './job_card';
import styles from './interested.module.css';
import CompanyMenuDropDown from '../CompanyMenuDropDown.jsx';
export default class InterestedListWrapper extends Component {
  render() {
    let tabs = [
      { tabname: 'PRODUCTS', comp: <Product /> },
      { tabname: 'SERVICES', comp: <Services /> },
      { tabname: 'Events', comp: <Event /> },
      { tabname: 'Jobs', comp: <Jobs /> },
    ];
    return (
      <>
        <Row>
          <Col xs={24} md={0}>
            <CompanyMenuDropDown name={'Interested'} />
          </Col>
        </Row>
        <div>
          <Row>
            <Col xs={0} sm={24} className={styles.headerCol}>
              <span className={`header-text`}>Interested List</span>
            </Col>
            <Col xs={24} className={styles.header}>
              <AllTabs
                company_tabs={tabs}
                class={`company_profile_main_tabs ${styles.headerCol}`}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
