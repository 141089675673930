import React, { Component } from 'react';
import './login.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import APIManager from '../../APIManager/index';
import { Col, message, Row, Spin } from 'antd';
import { connect } from 'react-redux';
// import LoginCover from '../../Components/asset/login_cover.png';
import sendbiz_logo from '../asset/sendbiz_logo.png';
import { fileUrl } from '../../Shared/imageUrlPath';

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
    };
  }
  goSignup() {
    this.props.history.push('/signup');
  }
  handleforgetPassword = (values) => {
    this.setState({ isloading: true });

    APIManager.forgetPassword(values)
      .then((response) => {
        console.log(response);
        if (response.data.isSuccess) {
          this.setState({ isloading: false });

          message.info('Please check your mail.');
        }
      })
      .catch((error) => {
        message.info('Email Is Not Valid.');
        this.setState({ isloading: false });
      });
  };
  render() {
    return (
      <>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              this.handleforgetPassword(values);
            }, 500);
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required('email is required.'),
          })}
        >
          {(props) => {
            const { values, errors, isSubmitting, handleChange, handleSubmit } =
              props;
            return (
              <>
                <div className="row custom_row">
                  <div className="col-sm-6 login-left text-left px-left-login px-top-login">
                    <div className="go-back ">
                      <img
                        className="cursor"
                        onClick={() => this.props.history.push('/')}
                        src={process.env.PUBLIC_URL + '/go-back-1.png'}
                      />
                    </div>
                    {this.state.isloading && (
                      <div className={'d-flex justify-content-center'}>
                        <Spin />
                      </div>
                    )}
                    <div>
                      <img
                        src={process.env.PUBLIC_URL + sendbiz_logo}
                        alt="logo"
                        style={{
                          width: '120px',
                          height: '50px',
                        }}
                      />
                    </div>
                    <h6
                      style={{
                        fontSize: 26,
                        fontWeight: 'bolder',
                        marginTop: 30,
                        fontFamily: 'Gilroy Bold',
                      }}
                      className="welcome_msg"
                    >
                      Welcome back !
                    </h6>

                    <p style={{ marginTop: 30, fontFamily: 'Gilroy medium' }}>
                      Request to reset your password.
                    </p>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group login-form-group">
                        <label htmlFor="exampleInputEmail1">User name</label>
                        <input
                          type="email"
                          name="email"
                          className="input-font form-control login-form-control"
                          onChange={handleChange}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email ID"
                        />
                        {errors.email && (
                          <div style={{ color: 'red', fontSize: '12px' }}>
                            {errors.email}
                          </div>
                        )}
                      </div>
                      <div
                        className="form-group"
                        style={{ marginBottom: '40px' }}
                      ></div>
                      <div className="cent_button">
                        <button
                          type="submit"
                          className="btn btn-dark login-btn login-button-font"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                    <h6 className="login-tag">
                      Don't have an account? Create your account,{' '}
                      <small
                        className="cursor red-link"
                        onClick={this.goSignup.bind(this)}
                        style={{ color: '#B02318' }}
                      >
                        Signup
                      </small>{' '}
                      now.
                    </h6>
                  </div>
                  <div className='login-container'>
                  <img
                    className="login_div_img_1"
                    src={fileUrl.fileUrlPath + "login_image_cover.gif"}
                    alt="img"
                  />
                </div>
                </div>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user,
    isloading: state.AuthReducer.isloading,
  };
};
export default connect(mapStateToProps)(ForgetPassword);
