import React, { Component } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  Upload,
  Modal,
  DatePicker,
  message,
  Popconfirm,
  Image,
} from 'antd';
import GoogleSearch from '../../candidate/googlesearch';
import moment from 'moment';
import './addService.css';
import { LeftOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import APIManager from '../../../APIManager/index';
import { fileUrl } from '../../../Shared/imageUrlPath';
import CurrentMapLocation from '../../../Routes/test';
import styles from './events.module.css';
const { TextArea } = Input;
let imageUploadCounter = 0;
export const getBlob = async (fileUri) => {
  const resp = await fetch(fileUri);
  const imageBody = await resp.blob();
  return imageBody;
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default class AddEvent extends Component {
  formRef = React.createRef();
  state = {
    constentType: '',
    previewVisible: false,
    previewImage: '',
    uploadUrl: '',
    previewTitle: '',
    fileList: [],
    fileType: '',
    fileName: [],
    fileListToDelete: [],
    uploadImage: '',
    binaryObject: '',
    testFile: '',
    newFileName: '',
    from: '',
    to: '',
    deleteImageArr: [],
    prevImageArray: [],
    // saveButton: true,
    location: 'Asia',
    saveButton: true,
    lat: '',
    long: '',
    checkToggle:false,
  };
  componentDidMount() {
    if (this.props.history.location.state.event_obj) {
      this.formRef.current.setFieldsValue({
        ...this.props.history.location.state.event_obj,
        from_date: moment(this.props.history.location.state.event_obj.from),
        to_date: moment(this.props.history.location.state.event_obj.to),
        desc: this.props.history.location.state.event_obj.description,
      });
      this.setState({
        from: this.props.history.location.state.event_obj.from,
        to: this.props.history.location.state.event_obj.to,
      });
    }
    let arr =
      this.props.location.state.event_obj &&
      this.props.location.state.event_obj.image?.map((data) => ({
        fileName: data,
        isSelected: false,
      }));
    console.log(arr);
    this.setState({
      prevImageArray: arr,
      current_location: '',
      lat: '',
      long: '',
    });
  }
  takecurrentLocation = (value) => {
    this.setState({ current_location: value });
  };
  takeLatLong = (lat, long) => {
    this.setState({
      lat: lat,
      long: long,
    });
  };
  checkToggleFunc = (checkToggle) => {
    this.setState({
      checkToggle: checkToggle,
    });
  }
  onSubmit = (e) => {
    var location = {
      latitude: this.state.lat,
      longitude: this.state.long,
    };
    e['location'] = location;
    e['addressTwo'] = this.state.current_location;
    if (this.state.current_location?.length > 0) {
      const locationArray = this.state.current_location.split(',');
      if (locationArray.length > 0) {
        e['country'] = locationArray[locationArray.length - 1].trim();
        e['state'] =
          locationArray.length > 1
            ? locationArray[locationArray.length - 2].trim()
            : '';
        e['city'] = locationArray.length > 2 ? locationArray[0].trim() : '';
      }
    }
    this.props.setData(e);
  };
  handleCancel = () => this.setState({ previewVisible: false });
  setCounter = () => (imageUploadCounter = 0);
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  uploadFiles = (e) => {
    const file = e.target.files[0];
    this.setState({
      testFile: file,
    });
    const uploadParams = {
      files: [
        {
          requestType: 'CompanyEventImage',
          contentType: 'image/png',
        },
      ],
    };
    APIManager.preSignedUrl(uploadParams).then((resp) => {
      if (resp.status === 200) {
        resp.data?.map((data) => {
          return this.setState({
            uploadUrl: data.url,
            fileName: data.fileName,
          });
        });
        this.uploadFile();
      }
    });
  };
  handleUploadFiles = ({ fileList }) => {
    imageUploadCounter += 1;
    if (imageUploadCounter < 2) {
      this.setState({ fileList });
      fileList?.map((data) => {
        return this.setState({
          contentType: data.type,
          uploadImage: data.originFileObj,
        });
      });
      const uploadParams = {
        files: [
          {
            requestType: 'CompanyEventImage',
            contentType: this.state.contentType,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data?.map((data) => {
            this.state.fileName.push({
              fileName: data.fileName,
              fileType: this.state.contentType,
            });
            this.state.fileListToDelete.push({
              fileName: data.fileName,
              fileUid: fileList[fileList.length - 1].uid,
            });
            return this.setState({ uploadUrl: data.url });
          });
          this.uploadFile();
        }
      });
    } else {
      console.log('process will not run...');
    }
  };
  removeFiles = (e) => {
    const id = e.uid;
    const indexId = this.state.fileList.findIndex((x) => x.uid === id);
    this.state.fileList.splice(indexId, 1);
    const imageIndexId = this.state.fileListToDelete.findIndex(
      (x) => x.fileUid === id
    );
    this.state.fileListToDelete.splice(imageIndexId, 1);
    this.setState({
      fileList: this.state.fileList,
      fileName: this.state.fileListToDelete?.map((x) => x.fileName),
    });
  };
  uploadFile = () => {
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.uploadImage,
    })
      .then((resp) => {})
      .catch((err) => console.log(err));
  };
  showCandidateInfo = () => {
  };

  editEvent = () => {
    const id = this.props.location.event_obj._id;
    APIManager.editEvent(id);
  };
  renderEventList = () => {
    this.props.history.push('/company/events');
  };
  handleChange = (changed_values, all_values) => {
  };
  changeFrom = (date, dateString) => {
    this.setState({
      from: dateString,
    });
  };
  changeTo = (date, dateString) => {
    this.setState({
      to: dateString,
    });
  };
  handleDate = (current) => {
    const d = new Date(this.state.from);
    return current < moment(d, 'YYYY/MM/DD');
  };
  finishForm = (val) => {
    const data = {
      name: val.name,
      location: val.location,
      description: val.desc,
      from: val.from_date,
      to: val.to_date,
      image: this.state.fileName,
      latitude:this.state.lat,
      longitude:this.state.long,
    };
    const addForm = this.props.location.state.addForm;
    if (addForm) {
      APIManager.addEvent(data)
        .then((resp) => {
          if (resp.data.isSuccess) {
            message.info(resp.data.message);
            this.props.history.push('/company/events');
          }
        })
        .catch((err) => {
          if (moment(this.state.from) > moment(this.state.to)) {
            message.error(
              'Event ending date must be greater than starting date!'
            );
          } else {
            message.error('Something went wrong.');
          }
        });
    }
    const id = this.props?.location?.state?.event_obj?._id;
    const prevImageArray = this.props.location.state.event_obj
      ? this.props.location.state.event_obj.image
      : [];
    this.state.fileName?.map((data) =>
      this.setState({
        newFileName: data,
      })
    );
    const EditImageArray = [...prevImageArray, ...this.state.fileName];
    if (this.state.deleteImageArr.length === 0) {
      const editedData = {
        name: val.name,
        location: val.location,
        description: val.desc,
        from: val.from_date,
        to: val.to_date,
        image: EditImageArray,
        latitude: this.state.lat,
        longitude:this.state.long,
      };
      if (id) {
        APIManager.editEvent(id, editedData)
          .then((resp) => {
            if (resp.data.isSuccess) {
              message.info(resp.data.message);
              this.props.history.push('/company/events');
            }
          })
          .catch((err) => {
            if (moment(this.state.from) > moment(this.state.to)) {
              message.error(
                'Event ending date must be greater than starting date!'
              );
            } else {
              message.error('Something went wrong.');
            }
          });
      }
    } else {
      this.deleteMedia();
    }
  };
  deleteMedia = () => {
    const id = this.props.location.state.event_obj._id;
    const prevImageArray =
      this.state.prevImageArray && this.state.prevImageArray;

    this.state.fileName?.map((data) =>
      this.setState({
        newFileName: data,
      })
    );
    const deletedArr = this.state.deleteImageArr;
    if (deletedArr) {
      var arr3 = [];
      let arr = [...prevImageArray];
      for (let i = 0; i < arr.length; i++) {
        let d = deletedArr.find((x) => x === arr[i].fileName);
        if (typeof d === 'undefined') {
          arr3.push(arr[i]);
        }
      }
    }
    const data = arr3?.map((data) => data.fileName);
    const editdata = {
      image: data,
    };
    if (id) {
      APIManager.editEvent(id, editdata)
        .then((resp) => {
          if (resp.data.isSuccess) {
            message.info(resp.data.message);
            this.props.history.push('/company/events');
          }
        })
        .catch((err) => message.error('Something went wrong.'));
    }
  };
  toggleState = (id) => {
    let arr =
      this.state.prevImageArray &&
      this.state.prevImageArray?.map((data) => {
        if (id === data.fileName) {
          data.isSelected = !data.isSelected;
        }
        return { ...data };
      });
    this.setState({
      prevImageArr: arr,
    });
  };
  setLocation = (location) => {
    this.formRef.current.setFieldsValue({ location: location });
  };
  disabledDate = (current) => {
    let customDate = moment();
    return current < moment(customDate, 'DD MMMM YYYY');
  };
  handleSaveButton = (value) => {
    this.setState({ saveButton: value });
  };
  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const location = this.props.history.location.state.event_obj
      ? this.props.history.location.state.event_obj.location
      : '';
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <div>
        <div className={styles.eventTop}>
          <LeftOutlined
            className="back-icon"
            onClick={() => this.props.history.goBack()}
          />
          <span className="back-btn" onClick={this.renderEventList}>
            Go back
          </span>
        </div>
        <Form
          layout="vertical"
          ref={this.formRef}
          name="service-add-edit-form"
          onValuesChange={this.handleChange}
          onFinish={this.finishForm}
          initialValues={{
            location: '',
          }}
        >
          <Row className="services-header" justify="space-between">
            <Col xs={24} sm={12} className={styles.eventTop}>
              <span className="header-text">
                {this.props.location.state.event_obj ? 'Edit' : 'Add'} Event
              </span>
            </Col>
            <Col xs={0} sm={12}>
              {this.state.saveButton && (
                <Button className="add-service-btn" htmlType="submit">
                  Save
                </Button>
              )}
            </Col>
          </Row>
          <Row gutter={24} className={`add-service-form ${styles.mainRow}`}>
            <Col xs={24} className={styles.uploadImageCol}>
            <Col xs={24}>
              <Form.Item
                label="Image"
              >
                  <Row gutter={[16, 24]}>
                    {this.props.location.state.addForm ? (
                      ''
                    ) : (
                      <>
                        {this.state.prevImageArray &&
                          this.state.prevImageArray
                            ?.filter((data) => data.fileName !== null)
                            ?.map((data) => (
                                <Col className={styles.imageContainer}>
                                  <div className={styles.deleteIcon}>
                                    <Popconfirm
                                      title="Are you sure to delete this file?"
                                      onConfirm={() =>
                                        this.toggleState(data.fileName)
                                      }
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <DeleteOutlined
                                       style={{ color: 'red' }}
                                        onClick={() =>
                                          this.state.deleteImageArr.push(
                                            data.fileName
                                          )
                                        }
                                      />
                                    </Popconfirm>
                                  </div>
                                  <div
                                    className={
                                      data.isSelected ? 'service-img' : ''
                                    }
                                  >
                                    {data.fileName.fileType.includes(
                                      'image'
                                    ) ? (
                                      <Image
                                        src={
                                          fileUrl.fileUrlPath +
                                          data.fileName.fileName
                                        }
                                        style={{
                                          height: '100px',
                                          width: '100px',
                                        }}
                                      />
                                    ) : (
                                      <video
                                        src={
                                          fileUrl.fileUrlPath +
                                          data.fileName.fileName
                                        }
                                        style={{
                                          height: '100px',
                                          width: '100px',
                                        }}
                                      />
                                    )}
                                  </div>
                                </Col>
                            ))}
                      </>
                    )}
                  </Row>
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    multiple={true}
                    fileList={fileList}
                    onClick={this.setCounter}
                    onPreview={this.handlePreview}
                    accept="image/png,image/gif,image/jpeg,image/jpg,video/*"
                    onChange={this.handleUploadFiles}
                    onRemove={(e) => this.removeFiles(e)}
                    beforeUpload={() => {
                      return false;
                    }}
                  >
                    {fileList.length >= 5 ||
                    this.state.prevImageArray?.length +
                      fileList?.length -
                      this.state.deleteImageArr?.length >=
                      5
                      ? null
                      : uploadButton}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                  >
                    <img
                      alt="example"
                      style={{
                        width: '100%',
                        objectFit: 'contain',
                        height: '200px',
                      }}
                      src={previewImage}
                    />
                  </Modal>
              </Form.Item>
            </Col>
            <Row>
            <Col xs={24} sm={12} className={styles.nameRow}>
              <Form.Item
                name="name"
                label="Name"
                className={styles.input}
                rules={[{ required: true, message: 'Required Field!' }]}
              >
                <Input
                  placeholder="Enter Event Name"
                  size='middle'
                  className="s-input-fields"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={11} className={styles.nameRow}>
              <Form.Item name="location" label="Location" className={styles.input}>
                <Input
                  size='middle'
                  className={`s-input-fields ${styles.location}`}
                  placeholder="Location"
                  name="location"
                  defaultValue="Asias"
                  style={{ display: 'none' }}
                />
                <GoogleSearch
                  className={`input-field-custom-type-1-left ${styles.location}`}
                  name="location"
                  placeholder="Enter Location"
                  location={location}
                  takecurrentLocation={this.takecurrentLocation}
                  takeLatLong={this.takeLatLong}
                  setLocation={this.setLocation}
                  handleSave={this.handleSaveButton}
                  checkToggleFunc={this.checkToggleFunc}
                />
                   {
                  this.state.lat && this.state.long && this.state.checkToggle &&
                  <div  className="form-group mb-signup-1" style={{position: 'relative',width: '100%',height: '300px'}} >
                    <CurrentMapLocation 
                    lat = {this.state.lat}
                    long = {this.state.long} 
                    takeLatLong={this.takeLatLong}
                    />
                  </div>
                  }
              </Form.Item>
            </Col>
            </Row>
            <Row>
            <Col xs={24} sm={12} className={styles.nameRow}>
              <Form.Item
                name="from_date"
                label="From Date"
                className={styles.input}
                rules={[{ required: true, message: 'Required Field!' }]}
              >
                <DatePicker
                  className="s-input-fields"
                  onChange={this.changeFrom}
                  disabledDate={this.disabledDate}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={11} className={styles.nameRow}>
              <Form.Item
                name="to_date"
                label="To Date"
                className={styles.input}
                rules={[{ required: true, message: 'Required Field!' }]}
              >
                <DatePicker
                  className="s-input-fields"
                  disabled={!this.state.from}
                  disabledDate={this.handleDate}
                  onChange={this.changeTo}
                />
              </Form.Item>
            </Col>
            </Row>
            <Col xs={24}>
              <Form.Item
                name="desc"
                label="Event Description"
              >
                <TextArea
                  className="short-desc-input s-input-fieldDesc"
                  bordered={false}
                  placeholder="Enter Description"
                  rows={4}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={0} className={styles.buttonCol}>
              {this.state.saveButton && (
                <Button className={`${styles.button} add-service-btn`} htmlType="submit">
                  Save
                </Button>
              )}
            </Col>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
