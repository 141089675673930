import React, { Component } from 'react';
import styles from './application_status.module.css';
class ApplicationStatus extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const status = this.props.status;
    return (
      <div className="row p-0">
        <img
          src={process.env.PUBLIC_URL + '/' + status + '.png'}
          className={styles.image}
        />
        <p className="application_status_card w-100">Job status</p>
      </div>
    );
  }
}

export default ApplicationStatus;
