import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Spin } from 'antd';
import APIManager from '../../../APIManager';
import Pagination from 'react-js-pagination';
import { SearchOutlined } from '@ant-design/icons';
import ReceiveCard from './ReceiveCard';
import NoDataFound from '../../../PageNotFound/NoData';
import styles from './offersReceived.module.css';
import CompanyMenuDropDown from '../CompanyMenuDropDown';

const SubcontractorsReceived = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  useEffect(() => {
    loadData('', 1);
  }, []);
  const loadData = (searchKeyword = '', pageNumber) => {
    setIsLoading(true);
    APIManager.getSubcontractorsJobList(
      searchKeyword.toLowerCase(),
      pageNumber
    ).then((resp) => {
      setData(resp.data);
      setResultsPerPage(resp.data.results);
      setTotalCount(resp.data.totalCount);
      setTotalPages(resp.data.totalPages);
      setCurrentPage(resp.data.currenctPage);
      setIsLoading(false);
    });
  };
  const handleChange = (e) => {
    setIsLoading(true);
    loadData(e.target.value);
  };
  return (
    <>
      <Row>
        <Col xs={24} md={0} style={{ marginBottom: '-15px' }}>
          <CompanyMenuDropDown name={'Offers Receive'} />
        </Col>
      </Row>
      <div>
        <Row style={{ marginTop: '6%' }}>
          <Col xs={0} sm={24}>
            <p
              style={{
                fontFamily: 'Gilroy Bold',
                fontSize: 28,
                fontWeight: 'bold',
                fontStyle: 'normal',
              }}
            >
              Offers Received
            </p>
          </Col>
          <Col xs={24} sm={10} className={styles.searchCol}>
            <Input
              className={styles.searchBar}
              prefix={<SearchOutlined />}
              type="text"
              placeholder="Search for a job"
              onChange={handleChange}
            />
          </Col>
          <Col xs={0} sm={14}></Col>
        </Row>

        {isLoading ? (
          <div className={'d-flex justify-content-center'}>
            <Spin />
          </div>
        ) : !data.data.length ? (
          <NoDataFound />
        ) : (
          data?.data?.map(({ _id, count, appliedAt, job, company }) => (
            <Col className={styles.cardCol}>
              <ReceiveCard
                id={_id}
                count={count}
                appliedAt={appliedAt}
                job={job}
                company={company}
              />
            </Col>
          ))
        )}
        {!isLoading && (
          <Row type="flex" alignItems="center">
            <Col span={10} />
            <Col>
              {data?.data?.length > 0 ? (
                <div style={{ margin: '10px', cursor: 'pointer' }}>
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={10}
                    firstPageText={false}
                    lastPageText={false}
                    hideDisabled={true}
                    totalItemsCount={data?.totalCount}
                    pageRangeDisplayed={5}
                    onChange={(pageNumber) => {
                      loadData('', pageNumber);
                    }}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              ) : (
                ''
              )}
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default SubcontractorsReceived;
