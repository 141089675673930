import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'antd';
import CompanyListCard from './cardForCompany';
import Header from '../header/header';
import { GoogleApiWrapper } from 'google-maps-react';
import './topNav.css';
import './companyList.css';
import styles from './index.module.css';
import Footer from '../footer/footer';
import { connect } from 'react-redux';
import CustomCompanyMap from '../app_status/company_map';
import HeaderLocation from '../headerLocation/headerLocation';
import SelectSector from './searchSelector';
import SelectCategory from './Services/selectServiceCategory';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class Companies extends Component {
  autoCompleteRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      searchingValue: '',
      current_location: '',
      map_location: '',
      lat: '',
      long: '',
      options: [],
      homeSearch: "false",
      searchLocation: "false",
      firstTimeRender: true,
      sector: [],
    };
  }
  async componentDidMount() {
    if (
      this.props.location.state !== null &&
      this.props.location.state !== undefined
    ) {
      this.autoCompleteRef.current.setFieldsValue({
        search: this.props.location.state.comp_name,
        location: this.props.location.state.location,
      });
      this.setState({
        searchingValue: this.props.location.state.comp_name,
        current_location: this.props.location.state.location,
      });
      if(this.props.location.state.homeSearch==="true"){
        this.setState({
          homeSearch: "true"
        });
      }
    }
    await this.getLocationName(
      this?.props?.location?.state?.lat,
      this?.props?.location?.state?.long
    );
  }
  getLocationName = async (lat, long) => {
    var ls = require('local-storage');
    const localLat = lat ? lat : ls.get('lat');
    const localLong = long ? long : ls.get('long');
    const { google } = this.props;
    if (localLat && localLong) {
      var geocoder;
      geocoder = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(localLat, localLong);
      var country = '';
      var state = '';
      var city = '';
      await geocoder.geocode(
        { latLng: latlng },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              var add = results[0].formatted_address;
              var value = add.split(',');
              const count = value.length;
              country = value[count - 1].replace(/[0-9]/g, '').trim(' ');
              state = value[count - 2].replace(/[0-9]/g, '').trim(' ');
              city = value[count - 3].replace(/[0-9]/g, '').trim(' ');
              this.setState({
                lat: localLat,
                long: localLong,
                current_location: `${city},${state},${country}`,
              });
            } else {
              console.log('address not found');
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        }.bind(this)
      );
    } else {
      this.setState({
        current_location: 'Paris, France',
        lat: '48.8566',
        long: '2.3522',
      });
    }
  };
  handleCategoryChange = (value) => {
    this.setState({
      sector: value,
      firstTimeRender: false,
    });
  };
  takecurrentLocation = (value) => {
    this.setState({
      current_location: value,
      firstTimeRender: false,
      map_location: '',
      searchLocation: "true",
    });
  };
  handlePressEnter = (event) => {
    this.setState({
      searchingValue: event.target.value,
    });
  };
  takeLatLong = (lat, long) => {
    this.setState({
      lat: lat,
      long: long,
      firstTimeRender: false,
    });
  };
  handleSearch = (value) => {
    let search = value.toLowerCase();
    this.search(search);
  };
  handleFilter = async () => {
    await this.getLocationName();
    this.autoCompleteRef.current.resetFields();
    this.setState({
      searchingValue: '',
      current_location: '',
      options: [],
      firstTimeRender: true,
    });
    window.history.replaceState('/companyList', null);
  };
  handleChange = (value) => {
    this.setState({
      searchingValue: value,
      firstTimeRender: false,
    });
  };
  handleCurrentLocationChange = (value) => {
    const { latitude, longitude } = value?.contact_info;
    this.takeLatLong(latitude, longitude);
  };
  handleMapLocationChange = (contact_data, id) => {
    this.takeLatLong(contact_data?.latitude, contact_data?.longitude);
    this.setState({ map_location: contact_data?.sub_address }, function () {
      this.openCompanyCardOnMap(id);
    });
  };
  openCompanyCardOnMap = (id) => {
    setTimeout(() => {
      const mapCard = document.querySelectorAll(`[title="${id}"]`)?.[0];
      if (mapCard) {
        mapCard.click();
      }
    }, 1000);
  };
  render() {
    let markers = [];
    if (
      this.props.companyList !== null &&
      this.props.companyList !== undefined
    ) {
      this.props.companyList.data.forEach((val) => {
        markers = markers.concat(val);
      });
      var min = 0.999999;
      var max = 1.000001;
      for (let i = 0; i < markers.length; i++) {
        markers[i].contact_info = {
          ...markers[i].contact_info,
          latitude:
            markers[i].contact_info?.latitude *
            (Math.random() * (max - min) + min),
          longitude:
            markers[i].contact_info?.longitude *
            (Math.random() * (max - min) + min),
        };
      }
    }
    var ls = require('local-storage');
    ls.set('current_location', this.state.current_location);
    return (
      <div>
        <Helmet>
          <title>
            Discover around a location all the best companies - Sendbiz
          </title>
          <meta
            property="og:title"
            content="Discover around a location all the best companies - Sendbiz"
          />
          <meta
            name="twitter:card"
            content="Discover around a location all the best companies - Sendbiz"
          />
          <meta
            name="description"
            content="Sendbiz. Promote your company, products, services and attract the right talent"
          />
          <meta
            property="og:description"
            content="Sendbiz. Promote your company, products, services and attract the right talent"
          />
          <meta
            name="twitter:description"
            content="Sendbiz. Promote your company, products, services and attract the right talent"
          />
        </Helmet>
        <Header />
        <Row className={styles.searchbar}>
          <Col xs={24} md={17}>
            <Form ref={this.autoCompleteRef}>
              <Row align="middle" className={styles.searchRowPart1}>
                <Col xs={24} md={8}>
                  <SelectSector
                    FormItem={Form.Item}
                    handleSectorChange={this.handleChange}
                    value={this.state.options}
                    name={'search'}
                  />
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item name="location" className={styles.locationInput}>
                    <HeaderLocation
                      envclass="landing-icons landing-select-icon2"
                      value={this.state.current_location}
                      className="company-joblist-input jobListingcls-1 padding-in-job-search company-page-location-inputbox"
                      takecurrentLocation={this.takecurrentLocation}
                      takeLatLong={this.takeLatLong}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6} className={styles.searchCategory}>
                  <Form.Item name="sector">
                    <SelectCategory
                      handleCategoryChange={this.handleCategoryChange}
                      value={this.state.sector}
                      name={'Category'}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col xs={24} md={7}>
            <Row className={styles.btnParent}>
              <Col xs={24} md={10}>
                <Button
                  type="text"
                  onClick={this.handleFilter}
                  className={styles.filterButton}
                >
                  Clear All Filters
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="company-list-bottom-row">
          <Col xs={24} md={12} className="">
            <CompanyListCard
              searchingValue={this.state.searchingValue}
              current_location={this.state.current_location}
              sector={this.state.sector}
              homeSearch={this.state.homeSearch}
              searchLocation={this.state.searchLocation}
              firstTimeRender={this.state.firstTimeRender}
              handleCurrentLocationChange={this.handleCurrentLocationChange}
              changeMapLocation={this.handleMapLocationChange}
            />
          </Col>
          <Col
            xs={24}
            md={12}
            style={{ marginTop: '26px' }}
            className="company-list-map"
          >
            <CustomCompanyMap
              renderfrom="company"
              markers={markers}
              current_location={this.state.current_location}
              map_location={this.state.map_location}
              lat={this.state.lat}
              long={this.state.long}
              jobLocation={this.state.current_location}
            />
          </Col>
        </Row>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyList: state.jobManagementReducer.companyList,
    isloading: state.jobManagementReducer.isloading,
  };
};
// export default ;
export default withRouter(
  GoogleApiWrapper({
    apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
  })(connect(mapStateToProps)(Companies))
);
