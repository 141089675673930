import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Spin, Row, Col, Card , Tooltip } from 'antd';
import APIManager from '../../APIManager';
import moment from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { fileUrl } from '../../Shared/imageUrlPath';
import defaultCard from '../asset/card.svg';
import NoData from '../../PageNotFound/NoData';
import MenuDropDown from './MenuDropDown.jsx';
import styles from './favouritejobs.module.css';

class FavouriteJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isloading: false,
      total: '',
      total_pages: '',
      current_page: '',
    };
  }
  componentDidMount() {
    this.setState({ isloading: true });
    APIManager.InterstedJobs(1).then((response) => {
      if (response.status === 200) {
        this.setState({
          data: response.data.data,
          isloading: false,
          total: response.data.totalCount,
          total_pages: response.data.totalPages,
          current_page: parseInt(response.data.currentPage),
        });
      }
    });
  }
  deleteFavJob = (id) => {
    APIManager.delfavouriteJobs(id)
      .then((response) => {
        if (response.data.status === 'success') {
          message.info('Job deleted favourite');
          APIManager.favouriteJobs();
          // window.location.reload();
        }
      })
      .catch((err) => message.error('Something went wrong.'));
  };

  makeHttpRequestWithPage = async (pageNumber) => {
    this.setState({
      isloading: true,
    });
    APIManager.InterstedJobs(pageNumber)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            data: response.data.data,
            isloading: false,
            total: response.data.totalCount,
            total_pages: response.data.totalPages,
            current_page: parseInt(response.data.currentPage),
          });
        }
      })
      .catch((err) => {
        this.setState({ isloading: false });
        message.error('Something went wrong!');
      });
  };

  render() {
    let renderPageNumbers;

    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / 10); i++) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers?.map((number) => {
        // let classes = this.state.currentPage === number ? .active : '';
        return (
          <span
            key={number}
            onClick={() => this.makeHttpRequestWithPage(number)}
            className={
              this.state.current_page == number
                ? 'page-number-btn'
                : 'pagination-container'
            }
          >
            {number}
          </span>
        );
      });
    }
    const { data } = this.state;
    return (
      <>
        <Row>
          <Col sm={0} xs={24}>
            <MenuDropDown name={'Favourite Jobs'} />
          </Col>
        </Row>
        <Row className={styles.parentContainer}>
          <h5 className={`candidate_heading ${styles.candidateHeading}`}>
            Favourite Jobs
          </h5>
          {this.state.isloading ? (
            <Spin className={'d-flex justify-content-center pt-3'} />
          ) : data.length === 0 && this.state.isloading === false ? (
            <NoData />
          ) : (
            data &&
            data?.map((data) => {
              return (
                <Card className={`services-card w-100 ${styles.serviceCard}`}>
                  <Row>
                    <Col sm={4} xs={6}>
                      <img
                        alt="example"
                        src={
                          data.interestId.job_logo
                            ? fileUrl.fileUrlPath + data.interestId.job_logo
                            : data.interestId.companyDetail[0].logo.name
                            ? fileUrl.fileUrlPath +
                              data.interestId.companyDetail[0].logo.name
                            : defaultCard
                        }
                        style={{
                          width: '100px',
                          height: '100px',
                          objectFit: 'cover',
                          borderRadius: '10px',
                        }}
                      />
                    </Col>
                    <Col sm={18} xs={16} className="service-detail-row">
                      <Row justify="space-between">
                        <Col span={17}>
                          {data.interestId.title.length > 40 ? (
                            <Tooltip title={data.interestId.title}>
                              <div
                                className="service-name"
                                style={{
                                  fontSize: '1.6rem',
                                  marginBottom: '3px',
                                }}
                                onClick={() =>
                                  this.props.history.push(
                                    `/apply-for/${data.interestId._id}`
                                  )
                                }
                              >
                                {data.interestId.title.substr(0, 39)}...
                              </div>
                            </Tooltip>
                          ) : (
                            <div
                              className="service-name"
                              style={{
                                fontSize: '1.6rem',
                                marginBottom: '3px',
                              }}
                              onClick={() =>
                                this.props.history.push(
                                  `/apply-for/${data.interestId._id}`
                                )
                              }
                            >
                              {data.interestId.title}
                            </div>
                          )}
                          <p
                            className="favourite-para-1 m-0"
                            style={{ textTransform: 'uppercase' }}
                          >
                            {data.interestId.companyDetail[0].comp_info.bus_name
                              ? data.interestId.companyDetail[0].comp_info
                                  .bus_name
                              : data.interestId.companyDetail[0].comp_info
                                  .comp_name}
                          </p>
                        </Col>
                        <Col span={7} className="icon-col"></Col>
                      </Row>
                      <Row>
                        <Col sm={22} xs={24} className={styles.locationSection}>
                          <Row>
                            <Col sm={1} xs={2}>
                              <img
                                src={
                                  process.env.PUBLIC_URL + '/location-red.png'
                                }
                                style={{
                                  width: 14,
                                  marginTop: '17px',
                                }}
                              />
                            </Col>
                            <Col
                              span={21}
                              className={`service-s-desc ${styles.locationSection}`}
                              style={{
                                verticalAlign: 'middle',
                              }}
                            >
                              {data.interestId.location}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row align="bottom" style={{ marginTop: '10px' }}>
                        <Col
                          span={9}
                          style={{
                            display: 'flex',
                            alignSelf: 'self-start',
                          }}
                        >
                          {/* <EnvironmentFilled className="service-icons" /> */}
                          <span>
                            <img
                              src={process.env.PUBLIC_URL + '/clock-red.png'}
                              className="service-icons"
                              style={{
                                height: '16px',
                                verticalAlign: 'super',
                              }}
                            />
                          </span>
                          <div
                            className="service-location"
                            style={{ display: 'block' }}
                          >
                            {moment(data.updatedAt).fromNow()}
                            {/* {moment(val.job.publish_from).format(
                          'd MMM YY'
                        )} */}
                          </div>
                        </Col>
                        <Col span={12}>
                          {/* <ClockCircleFilled className="service-icons" /> */}
                          <img
                            src={process.env.PUBLIC_URL + '/clock-red.png'}
                            className="service-icons"
                            style={{
                              height: '16px',
                              verticalAlign: 'super',
                            }}
                          />
                          <div className="service-location">
                            {/* {moment(val.job.publish_to).format(
                        'DD MMM YY'
                      )} */}
                            {moment(data.createdAt).format('YYYY/MM/DD')}
                          </div>
                        </Col>
                        <Col
                          span={2}
                          style={{ textAlign: 'right' }}
                          className="sponsered"
                        ></Col>
                      </Row>
                    </Col>
                    <Col sm={2} xs={2}>
                      <img
                        // onClick={() => this.deleteFavJob(val.job._id)}
                        src={process.env.PUBLIC_URL + '/bookmark-red.svg'}
                        className="favouritejobs-card-5"
                        alt="like"
                      />
                    </Col>
                  </Row>
                </Card>
              );
            })
          )}
        </Row>
        {/*================= pagination section ===================*/}
        <Row>
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {data.length > 0 ? (
              <div className={styles.paginationContainer}>
                <span
                  className={
                    this.state.current_page == 1 ? 'pagination-side-btn' : ''
                  }
                  onClick={() =>
                    this.state.current_page != 1
                      ? this.makeHttpRequestWithPage(
                          this.state.current_page - 1
                        )
                      : ''
                  }
                >
                  {' '}
                  <LeftOutlined />{' '}
                </span>
                <div>{renderPageNumbers}</div>
                <span
                  className={
                    this.state.current_page == this.state.total_pages
                      ? 'pagination-side-btn'
                      : ''
                  }
                  onClick={() =>
                    this.state.current_page != this.state.total_pages
                      ? this.makeHttpRequestWithPage(
                          this.state.current_page + 1
                        )
                      : ''
                  }
                >
                  <RightOutlined />
                </span>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.candidateInfoSubmitReducer.fav_data,
  isloading: state.candidateInfoSubmitReducer.isLoading,
});
export default connect(mapStateToProps)(FavouriteJobs);
