import React, { useState } from 'react';
import { Row, Col, Image, Tooltip, Switch, Collapse } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { CURRENCY } from '../../constants/currency';
import moment from 'moment';
import { fileUrl } from '../../../Shared/imageUrlPath';
import styles from './index.module.css';
import ConfirmPopUp from '../../constants/ConfirmPopup';
import mailIcon from '../../asset/mail.svg';
import APIManager from '../../../APIManager';

const ResourceCards = ({ data, editResource, ...props }) => {
  const [deleteResourceId, setDeleteResourceId] = useState('');
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const deleteResource = (id) => {
    APIManager.deleteResource(id).then((resp) => {
      window.location.reload();
    });
    setConfirmDeletePopup(false);
  };
  const changeActiveStatus = async (jobSwitchId) => {
    const id = jobSwitchId;
    APIManager.ResourceActiveStatus(id);
  };
  return (
    <div className={styles.mainDiv}>
      <div className={styles.innerMainDiv}>
        {confirmDeletePopup && (
          <ConfirmPopUp
            message="Are you sure you want to delete resource"
            handleOk={() => deleteResource(deleteResourceId)}
            closePopUp={() => setConfirmDeletePopup(false)}
          />
        )}
        <Row gutter={15}>
          <Col xs={5} sm={4}>
            <img
              className={styles.image}
              src={
                data.companyData[0]?.logo?.name
                  ? fileUrl.fileUrlPath + data.companyData[0].logo.name
                  : '/company_logo.png'
              }
              preview={false}
            />
          </Col>
          <Col xs={19} sm={20}>
            <Row>
              <Col xs={22} md={16}>
                <h4 className={styles.headingName}>{data?.title}</h4>
              </Col>
              <Col xs={0} md={6} className={styles.editCol}>
                <Tooltip title="Edit Resource">
                  <EditFilled
                    className={styles.editFields}
                    onClick={(e) => editResource(data)}
                  />
                </Tooltip>
                <Tooltip title="Delete Service">
                  <DeleteFilled
                    className={styles.editFields}
                    onClick={() => {
                      setDeleteResourceId(data._id);
                      setConfirmDeletePopup(true);
                    }}
                  />
                </Tooltip>
              </Col>
              <Col xs={2}>
                <Switch
                  defaultChecked={data.isActive}
                  onChange={() => {
                    changeActiveStatus(data._id);
                  }}
                />
              </Col>
            </Row>
            <Row className={styles.innerRows}>
              <Col xs={12} md={8}>
                <span className={styles.titleNames}>Price: </span>
                <span className={styles.titleData}>
                  {CURRENCY.map((curr) =>
                    data
                      ? curr.name === data.price.currency
                        ? `${curr.symbol} ${data.price.price}`
                        : ''
                      : ''
                  )}
                </span>
              </Col>
              <Col xs={12} md={8}>
                <span className={styles.titleNames}>Availability: </span>
                <span className={styles.titleData}>{data?.availability}</span>
              </Col>
              <Col xs={24} md={8}>
                <span className={styles.titleNames}>Experience: </span>
                <span className={styles.titleData}>
                  {data?.experience + ' years'}
                </span>
              </Col>
            </Row>
            <Row className={styles.innerRows1}>
              <Col xs={24}>
                <span className={styles.titleNames}>Skills:</span>
                <span className={styles.titleData}>
                  {data?.skills
                    ?.map((e) => {
                      return e?.title?.toUpperCase();
                    })
                    .join(', ')}
                </span>
              </Col>
            </Row>
            <Row className={styles.innerRows1}>
              <Col xs={0} md={8}>
                <a
                  target="_blank"
                  href={`${fileUrl.fileUrlPath}${data?.resume}`}
                  style={{
                    textDecoration: 'none',
                    paddingLeft: '0',
                  }}
                >
                  <span className={styles.titleNames}>View Resume PDF</span>
                </a>
              </Col>
              <Col xs={0} md={8}>
                <span className={styles.titleNames}>Applied on:</span>
                <span className={styles.titleData}>
                  {moment(data.createdAt).format('DD MMM YY')}
                </span>
              </Col>
              <Col xs={0} md={8}>
                <a
                  target="_blank"
                  href={`${fileUrl.fileUrlPath}${data?.document}`}
                  style={{
                    textDecoration: 'none',
                    paddingLeft: '0',
                  }}
                >
                  <span className={styles.titleNames}>View Document PDF </span>
                </a>
              </Col>
            </Row>
          </Col>
          <Row className={styles.innerRows1}>
            <Col xs={10} md={0} className={styles.appliedBg}>
              <span className={styles.titleNames}>Applied on:</span>
              <span className={styles.titleData}>
                {moment(data.createdAt).format('DD MMM YY')}
              </span>
            </Col>
            <Col xs={14} md={0}></Col>
            <Col xs={12} md={0}>
              <a
                target="_blank"
                href={`${fileUrl.fileUrlPath}${data?.resume}`}
                style={{
                  textDecoration: 'none',
                  paddingLeft: '0',
                }}
              >
                <span className={styles.titleNames}>View Resume PDF</span>
              </a>
            </Col>
            <Col xs={12} md={0}>
              <a
                target="_blank"
                href={`${fileUrl.fileUrlPath}${data?.document}`}
                style={{
                  textDecoration: 'none',
                  paddingLeft: '0',
                }}
              >
                <span className={styles.titleNames}>View Document PDF </span>
              </a>
            </Col>
          </Row>
          <Col xs={24} style={{ marginTop: '20px' }} className={'colapsable'}>
            <Collapse>
              <Collapse.Panel header="More Info">
                <Row className={styles.rowCollapse}>
                  <Col xs={6} className={styles.collapseHeaders}>
                    Location :
                  </Col>
                  <Col xs={18} className={styles.collapseValue}>
                    {data?.location}
                  </Col>
                  <Col xs={6} className={styles.collapseHeaders}>
                    Information :
                  </Col>
                  <Col xs={18} className={styles.collapseValue}>
                    {data?.information}
                  </Col>
                  <Col xs={6} className={styles.collapseHeaders}>
                    Skills :
                  </Col>
                  <Col xs={18} className={styles.collapseValue}>
                    {data?.skills
                      ?.map((e) => {
                        return e?.title?.toUpperCase();
                      })
                      .join(', ')}
                  </Col>
                  <Col xs={24} className={styles.collapeseContact}>
                    CONTACT
                  </Col>
                  <Col xs={6} className={styles.collapseHeaders}>
                    Company:
                  </Col>
                  <Col xs={18} className={styles.collapseValueCompany}>
                    {data?.companyData[0]?.comp_info?.bus_name}
                  </Col>
                  <Col xs={6} className={styles.collapseHeaders}>
                    Username:
                  </Col>
                  <Col xs={18} className={styles.collapseValue}>
                    {data?.companyData[0]?.comp_info?.first_name +
                      ' ' +
                      data?.companyData[0]?.comp_info?.last_name}
                  </Col>
                  <Col xs={6} className={styles.collapseHeaders}>
                    User Job Title:
                  </Col>
                  <Col xs={16} className={styles.collapseValue}>
                    {data?.companyData?.[0]?.comp_info?.job_profile}
                  </Col>
                  <Col xs={2} className={styles.collapseValue}>
                    <div
                      // className={'d-flex justify-content-end'}
                      onClick={() => {
                        props.history.push({
                          pathname: '/company-mailbox',
                          userInfo: Object.assign(data.companyData),
                          canId: data.companyData.comp_id,
                        });
                      }}
                    >
                      <img
                        src={mailIcon}
                        style={{ cursor: 'pointer', marginLeft: '7px' }}
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col xs={6} className={styles.collapseHeaders}>
                    Email:
                  </Col>
                  <Col xs={16} className={styles.collapseValue}>
                    {data?.email}
                  </Col>
                  <Col xs={2} className={styles.collapseValue}>
                    <a href={`mailto:${data?.email}`}>
                      <img src="/telegram.png" alt="" />
                    </a>
                  </Col>
                  <Col xs={6} className={styles.collapseHeaders}>
                    Phone Number:
                  </Col>
                  <Col xs={18} className={styles.collapseValue}>
                    {data?.companyData[0]?.contact_info?.phone_no ? data?.companyData[0]?.contact_info?.phone_no : '-'}
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ResourceCards;
