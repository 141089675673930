import React, { Component } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Form,
  Upload,
  Modal,
  message,
  Spin,
  Popconfirm,
  Image,
} from 'antd';
import './addService.css';
import { LeftOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import APIManager from '../../../APIManager/index';
import { fileUrl } from '../../../Shared/imageUrlPath';
import styles from './news.module.css';

const { Option } = Select;
const { TextArea } = Input;

let imageUploadCounter = 0;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default class AddNews extends Component {
  formRef = React.createRef();
  state = {
    contentType: '',
    isLoading: false,
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    uploadUrl: '',
    fileList: [],
    fileType: '',
    fileName: [],
    fileListToDelete: [],
    uploadImage: '',
    testFile: '',
    newFileName: '',
    deleteImageArr: [],
    prevImageArr: [],
  };

  componentDidMount() {
    if (this.props.history.location.state.news_obj) {
      this.formRef.current.setFieldsValue({
        ...this.props.history.location.state.news_obj,
      });
    }
    let arr =
      this.props.location.state.news_obj &&
      this.props.location.state.news_obj.image?.map((data) => ({
        fileName: data,
        isSelected: false,
      }));

    this.setState({
      prevImageArr: arr,
    });
  }
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  setCounter = () => (imageUploadCounter = 0);

  handleUploadFiles = ({ fileList }) => {
    imageUploadCounter += 1;
    if (imageUploadCounter < 2) {
      this.setState({ fileList });

      fileList?.map((data) => {
        return this.setState({
          contentType: data.type,
          uploadImage: data.originFileObj,
        });
      });
      const uploadParams = {
        files: [
          {
            requestType: 'CompanyNewsImage',
            contentType: this.state.contentType,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          // this.state.fileName.push(resp.data.fileName)
          resp.data.forEach((data) => {
            console.log('data imageeeee', data);
            this.state.fileName.push({
              fileName: data.fileName,
              fileType: this.state.contentType,
            });
            this.state.fileListToDelete.push({
              fileName: data.fileName,
              fileUid: fileList[fileList.length - 1].uid,
            });
            this.setState({ uploadUrl: data.url });
          });
          // this.uploadFile();
          this.uploadFile();
        }
      });
    } else {
      console.log('process will not run');
    }
  };
  uploadFile = () => {
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.uploadImage,
    })
      .then((resp) => {})
      .catch((err) => console.log(err));
  };

  renderNewsList = () => {
    this.props.history.goBack();
  };
  handleChange = (changed_values, all_values) => {};
  deleteMedia = () => {
    const id = this.props.location.state.news_obj._id;
    const prevImageArray = this.state.prevImageArr && this.state.prevImageArr;
    this.state.fileName?.map((data) =>
      this.setState({
        newFileName: data,
      })
    );
    const deletedArr = this.state.deleteImageArr;
    let EditImageArray = [];
    if (deletedArr.length !== 0) {
      var arr3 = [];
      let arr = [...prevImageArray];
      for (let i = 0; i < arr.length; i++) {
        let d = deletedArr.find((x) => x === arr[i].fileName);
        if (typeof d === 'undefined') {
          arr3.push(arr[i]);
        }
      }
    } else {
      EditImageArray = [...prevImageArray, ...this.state.newFileName];
    }
    const data = arr3?.map((data) => data.fileName);
    const editdata = {
      image: data,
    };

    if (id) {
      APIManager.editNews(id, editdata)
        .then((resp) => {
          if (resp.data.isSuccess) {
            message.info(resp.data.message);
            this.props.history.push('/company/news');
          }
        })
        .catch((err) => message.error('Something went wrong.'));
    }
  };
  finishForm = (val) => {
    const data = {
      title: val.title,
      description: val.description,
      image: this.state.fileName,
    };
    this.setState({
      isLoading: true,
    });
    const addForm = this.props.location.state.addForm;
    if (addForm) {
      APIManager.addNews(data)
        .then((resp) => {
          if (resp.data.isSuccess) {
            this.setState({
              isLoading: false,
            });
            this.props.history.push('/company/news');
            message.info(resp.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isLoading: false,
          });
        });
    }
    const id = this.props.location.state.news_obj._id;
    const prevImageArray = this.props.location.state.news_obj
      ? this.props.location.state.news_obj.image
      : [];

    this.state.fileName?.map((data) =>
      this.setState({
        newFileName: data,
      })
    );
    const EditImageArray = [...prevImageArray, ...this.state.fileName];
    if (this.state.deleteImageArr.length === 0) {
      const editData = {
        title: val.title,
        description: val.description,
        image: EditImageArray,
      };
      if (id) {
        APIManager.editNews(id, editData)
          .then((resp) => {
            if (resp.data.isSuccess) {
              this.setState({ isLoading: false });
              message.info(resp.data.message);
              this.props.history.push('/company/news');
            }
          })
          .catch((err) => {
            this.setState({
              isLoading: false,
            });
            message.error('Something went wrong.');
          });
      }
    } else {
      this.deleteMedia();
    }
  };
  toggleState = (id) => {
    let arr =
      this.state.prevImageArr &&
      this.state.prevImageArr?.map((data) => {
        if (id === data.fileName) {
          data.isSelected = !data.isSelected;
        }
        return { ...data };
      });
    this.setState({
      prevImageArr: arr,
    });
  };
  removeFiles = (e) => {
    const id = e.uid;
    const indexId = this.state.fileList.findIndex((x) => x.uid === id);
    this.state.fileList.splice(indexId, 1);
    const imageIndexId = this.state.fileListToDelete.findIndex(
      (x) => x.fileUid === id
    );
    this.state.fileListToDelete.splice(imageIndexId, 1);
    this.setState({
      fileList: this.state.fileList,
      fileName: this.state.fileListToDelete?.map((x) => x.fileName),
    });
  };
  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const prevImageArray = this.props.location.state.news_obj
      ? this.props.location.state.news_obj.image
      : '';
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8, width: 200 }}>Upload</div>
      </div>
    );
    return (
      <div className="applied-job-paddingright ">
        <div className={'d-flex justify-content-center'}>
          {this.state.isLoading && <Spin />}
        </div>
        <div className={styles.addNews}>
          <LeftOutlined className="back-icon" onClick={this.renderNewsList} />
          <span className="back-btn" onClick={this.renderNewsList}>
            Go back
          </span>
        </div>
        <Form
          layout="vertical"
          ref={this.formRef}
          name="service-add-edit-form"
          onValuesChange={this.handleChange}
          onFinish={this.finishForm}
        >
          <Row className="services-header" justify="space-between">
            <Col xs={24} sm={12} className={styles.addNews}>
              <span className="header-text">
                {this.props.location.state.news_obj ? 'Edit' : 'Add'} News
              </span>
            </Col>
            <Col xs={0} sm={12}>
              <Button className="add-service-btn" htmlType="submit">
                Save
              </Button>
            </Col>
          </Row>
          <Col className={styles.mainCol}>
            <Row className={styles.mainRow}>
              <Col xs={24}>
                <Form.Item name="image" label="Image">
                  <div>
                    <Row gutter={[16, 24]}>
                      {this.props.location.state.addForm ? (
                        ''
                      ) : (
                        <>
                          {this.state.prevImageArr &&
                            this.state.prevImageArr
                              ?.filter((data) => data !== '')
                              ?.map((data) => (
                                <div className={styles.imageContainer}>
                                  <Col>
                                    <div
                                      key={data._id}
                                      className={styles.deleteIcon}
                                    >
                                      <Popconfirm
                                        title="Are you sure to delete this file?"
                                        onConfirm={() =>
                                          this.toggleState(data.fileName)
                                        }
                                        //onCancel={cancel}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <DeleteOutlined
                                          style={{ color: 'red' }}
                                          onClick={() =>
                                            this.state.deleteImageArr.push(
                                              data.fileName
                                            )
                                          }
                                        />
                                      </Popconfirm>
                                    </div>
                                    <div
                                      className={
                                        data.isSelected ? 'service-img' : ''
                                      }
                                    >
                                      {data.fileName.fileType.includes(
                                        'image'
                                      ) ? (
                                        <Image
                                          src={
                                            fileUrl.fileUrlPath +
                                            data.fileName.fileName
                                          }
                                          style={{
                                            height: '100px',
                                            width: '100px',
                                          }}
                                        ></Image>
                                      ) : (
                                        <video
                                          src={
                                            fileUrl.fileUrlPath +
                                            data.fileName.fileName
                                          }
                                          style={{
                                            height: '100px',
                                            width: '100px',
                                          }}
                                        />
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              ))}
                        </>
                      )}
                    </Row>

                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={fileList}
                      onClick={this.setCounter}
                      onPreview={this.handlePreview}
                      onChange={this.handleUploadFiles}
                      onRemove={(e) => this.removeFiles(e)}
                      beforeUpload={() => {
                        return false;
                      }}
                    >
                      {fileList.length >= 5 ||
                      this.state.prevImageArr?.length +
                        fileList?.length -
                        this.state.deleteImageArr?.length >=
                        5
                        ? null
                        : uploadButton}
                    </Upload>

                    <Modal
                      visible={previewVisible}
                      title={previewTitle}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <img
                        alt="example"
                        style={{
                          width: '100%',
                          objectFit: 'contain',
                          height: '200px',
                        }}
                        src={previewImage}
                      />
                    </Modal>
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="title"
                  label="News Title"
                  rules={[
                    {
                      required: true,
                      message: 'Required Field!',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter News Title"
                    size="large"
                    className="s-input-fields"
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: 'Required Field!',
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea
                    className="short-desc-input s-input-fieldDesc"
                    bordered={false}
                    placeholder="Enter Description Of Your News Here"
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={0} className={styles.mainCol}>
            <Button
              className={`add-service-btn ${styles.button}`}
              htmlType="submit"
            >
              Save
            </Button>
          </Col>
        </Form>
      </div>
    );
  }
}
