import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { Row, Col, Input, Button, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CreateResourceForm from './CreateResource';
import ResourceCard from './resourceCards';
import APIManager from '../../../APIManager';
import CompanyMenuDropDown from '../CompanyMenuDropDown';
import NoData from '../../../PageNotFound/NoData';

const MyResourcesListing = () => {
  const [createResource, setCreateResource] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState('');
  const [activeCount, setActiveCount] = useState('');
  const [user_type, setUserType] = useState('');
  const [editData, setEditData] = useState({});
  const [editResource, setEditResource] = useState(false);
  const [search, setSearch] = useState('');

  const handleClick = () => {
    setCreateResource(!createResource);
  };
  const handleEditChange = (data) => {
    setEditResource(true);
    setEditData(data);
    setCreateResource(true);
  };
  const getResourceList = () => {

    APIManager.getMyResources({search: search})
      .then((response) => {
        if (response?.data?.isSuccess) {
          setData(response?.data?.myResources);
          setTotalCount(response?.data?.totalCount);
          setActiveCount(response?.data?.activeCount);
          setUserType(response?.data?.user_type);
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  };
  useEffect(() => {
    getResourceList();
  }, [createResource,search]);
  return (
    <>
      <Row>
        <Col xs={24} md={0} style={{ marginBottom: '20px' }}>
          <CompanyMenuDropDown name={'My Resources'} />
        </Col>
      </Row>
      {createResource && (
        <CreateResourceForm
          editData={editData}
          editResource={editResource}
          changeResourceState={handleClick}
        />
      )}
      {!createResource && (
        <div>
          <Row gutter={24} className={styles.upperRow}>
            <Col xs={0} md={16} className={styles.resource}>
              Manage Resource
            </Col>
            <Col xs={0} md={8} className={styles.rightColumn}>
              <Button className={styles.createButton} onClick={handleClick}>
                Create Resource
              </Button>
            </Col>
          </Row>
          <Row gutter={24} className={styles.secondRow}>
            <Col xs={24} md={12} className={styles.resource}>
              <Row gutter={12}>
                <Col xs={24} md={16} className={styles.searchFields}>
                  <Input
                    className={`company-joblist-input jobListingcls-1 ${styles.search}`}
                    prefix={<SearchOutlined />}
                    type="text"
                    placeholder="Search for keywords"
                    onChange={(e)=> setSearch(e.target.value)}
                  />
                </Col>
                <Col xs={24} md={0}>
                  <Col xs={20}>
                    <div className={styles.marginLeft}>{totalCount} POSTED</div>
                  </Col>
                  <Col xs={20}>
                    <div className={styles.marginLeft1}>
                      {activeCount} ACTIVE RESOURCE
                    </div>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col xs={0} md={12} className={styles.rightColumn}>
              <div className={styles.countButton}>{totalCount} POSTED</div>
              <div className={styles.countButton}>
                {activeCount} ACTIVE RESOURCE
              </div>
            </Col>
          </Row>
          <Row className={styles.searchFields}>
            {data.length === 0 ?  <NoData /> : data?.map((data) => (
              <ResourceCard
                data={data}
                user_type={user_type}
                editResource={handleEditChange}
              />
            ))}
          </Row>
          <Row type="flex" alignItems="center">
            <Col xs={24} sm={0} className={styles.buttonCol}>
              <Button className={styles.createButton} onClick={handleClick}>
                Create Resource
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
export default MyResourcesListing;
