import React, { Component } from 'react';
import {
  Row,
  Col,
  Switch,
  Spin,
  Card,
  message,
  Tooltip
} from 'antd';
import { connect } from 'react-redux';
import checkPremiumDetails from '../checkPremiumDetail';
import { withRouter } from 'react-router-dom';
import './servicescard.css';
import { CURRENCY } from '../../constants/currency';
import {
  DeleteFilled,
  NotificationFilled,
  EnvironmentFilled,
  DollarCircleFilled,
  ClockCircleFilled,
  EditFilled,
  StopOutlined,
} from '@ant-design/icons';
import { ImageUrl } from '../../../Shared/imageUrlPath';
import APIManager from '../../../APIManager';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import cardDefaultPic from '../../../Components/asset/card.svg';
import PromoteEntity from '../../../Components/company/promoteEntity/index';
import NoData from '../../../PageNotFound/NoData';
import ConfirmPopUp from '../../constants/ConfirmPopup';
import styles from './service.module.css';
const numEachPage = 2;
var user_type;

class ServiceCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minValue: 0,
      maxValue: 2,
      data: [],
      checked:[],
      resultsPerPage: 10,
      totalData: null,
      totalPages: null,
      current_page: null,
      deleteServiceId: '',
      id: '',
      isloading: false,
      promoteEntity: false,
      promoteServiceId: '',
      confirmDeletePopup: false,
      stopSponsorJobId: '',
      confirmStopSponsorPopup: false,

    };
  }
  componentDidMount() {
    user_type = JSON.parse(localStorage.getItem('user_type') || false);
    const user_type2 = JSON.parse(localStorage.getItem('user_type2') || false);
    user_type = user_type / (user_type2 * 99);
    this.makeHttpRequestWithPage(1);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.sortValue !== prevProps.sortValue) {
      this.makeHttpRequestWithPage();
    }
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.makeHttpRequestWithPage();
    }
  }
  makeHttpRequestWithPage = async (pageNumber) => {
    const sort = this.props.sortValue;
    const searchQuery = this.props.searchQuery;
    this.setState({
      isloading: true,
    });
    if ((sort && sort !== 'reset') || (searchQuery && searchQuery !== '')) {
      APIManager.sortServices(sort, searchQuery,pageNumber)
        .then((resp) => {
          this.setState({
            data: resp.data.data,
            total: resp.data.totalCount,
            //   per_page: resp.data.results,
            current_page: resp.data.currentPage,
            isloading: false,
            totalPages: resp.data.totalPages,
          });
        })
        .catch((err) => {
          this.setState({ isloading: false });
          message.error('Something went wrong!');
        });
    } else {
      APIManager.serviceList(pageNumber)
        .then((resp) => {
          this.setState({
            data: resp.data.data,
            total: resp.data.totalCount,
            //per_page: resp.data.results,
            current_page: resp.data.currentPage,
            isloading: false,
            totalPages: resp.data.totalPages,
            checked: resp?.data?.data?.map( item => item?.isActivated) ?? []
          });
        })
        .catch((err) => {
          this.setState({ isloading: false });
          message.error('Something went wrong!');
        });
    }
  };
  showCandidateInfo = () => {
    //  this.props.history.push('./Applied-company_info');
  };
  stopSponsor = () => {
    const id = this.state.stopSponsorJobId;
    //const { id } = this.props.match.params;
    APIManager.stopSponsor(id,'service').then((resp) => {
      if (resp.data.isSuccess) {
        message.success('Job Sponsor is stopped');
        this.makeHttpRequestWithPage();
      //  window.location.reload();
        APIManager.myJobs();
      }
    });
  }
  handleChange = (value) => {
    this.setState({
      minValue: (value - 1) * numEachPage,
      maxValue: value * numEachPage,
    });
  };
  changeActiveStatus = async (currState,index,checked) => {
    if(!currState){

      const flag = await checkPremiumDetails("services");
      if(!flag){
        return;
      }
    }
    const id = this.state.id;
    this.setState({ isSpin: true,checked: [...this.state.checked?.map( (_,i) => i===index?checked: _)] });
    APIManager.changeActiveStatus(id);
  };
  renderb = (e, data) => {};
  deleteService = () => {
    const id = this.state.deleteServiceId;
    this.setState({ confirmDeletePopup: false });
    APIManager.deleteService(id)
      .then((resp) => {
        if (resp.data.isSuccess) {
          message.info(resp.data.message);
          this.makeHttpRequestWithPage(this.state.current_page);
        }
      })
      .catch((err) => {
        message.error('Something went wrong.');
      });
  };
  confirmStopSponsor = () => {
    this.stopSponsor();
    this.setState({ confirmStopSponsorPopup: false });
  }
  dontDelete = () => {
    //Dont Delete Because it is sponsored job which has remaining Budget
    message.info("You can't delete a sponsored service");
  };

  render() {
    let renderPageNumbers;

    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / 10); i++) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers?.map((number) => {
        // let classes = this.state.currentPage === number ? .active : '';
        return (
          <span
            key={number}
            onClick={() => this.makeHttpRequestWithPage(number)}
            className={
              this.state.current_page === number
                ? 'page-number-btn'
                : 'pagination-container'
            }
          >
            {number}
          </span>
        );
      });
    }

    const loading = this.props.isloading;
    const reloadServices = () => {
      APIManager.serviceList('1')
        .then((resp) => {
          this.setState({
            data: resp.data.data,
            total: resp.data.totalCount,
            //per_page: resp.data.results,
            current_page: resp.data.currentPage,
            isloading: false,
          });
        })
        .catch((err) => {
          this.setState({ isloading: false });
          message.error('Something went wrong!');
        });
    };
  
    return (
      <div className={styles.mainContainer}>
        {this.state.promoteEntity && (
          <PromoteEntity
            show={this.state.promoteEntity}
            sponser={true}
            onHide={() => this.setState({ promoteEntity: false })}
            name={'service'}
            entity={this.state.promoteServiceId}
            reloadProducts={reloadServices}
          />
        )}
          {this.state.confirmStopSponsorPopup && (
          <ConfirmPopUp
            message="Are you sure you want to Stop Service Sponsorisation"
            handleOk={this.confirmStopSponsor}
            closePopUp={() => this.setState({ confirmDeletePopup: false })}
          />
        )}
        {this.state.confirmDeletePopup && (
          <ConfirmPopUp
            message="Are you sure you want to delete service"
            handleOk={this.deleteService}
            closePopUp={() => this.setState({ confirmDeletePopup: false })}
          />
        )}
        {loading || this.state.isloading ? (
          <div className={'d-flex justify-content-center pt-2'}>
            <Spin />
          </div>
        ) : (
          ''
        )}
        {this.state.data.length === 0 && this.state.isloading === false ? (
          <NoData />
        ) : (
          this.state.data &&
            this.state.data?.map((data,i) => (
         <div style={{marginTop: '20px'}}>
            <Card
              className={styles.card}
              key={data._id}
              // onClick={(e)=>this.renderb(e,data)}
            >
              <Row gutter={15}>
                <Col xs={5} md={4}>
                  <img
                    alt="example"
                    src={
                      data.media.filter((data) =>
                        data.fileType?.includes('image')
                      ).length !== 0
                        ? 'https://sendbizbucket.s3.eu-west-3.amazonaws.com/' +
                          data.media.filter((data) =>
                            data.fileType.includes('image')
                          )[0].fileName
                        : data?.companyData?.logo?.name ? ImageUrl.imageUrlPath + data?.companyData?.logo?.name: cardDefaultPic
                    }
                    className={styles.image}
                  />
                </Col>
                <Col
                  xs={19}
                  md={20}
                  className="service-detail-row"
                >
                  <Row justify="space-between">
                    <Col xs={17} md={17}>
                      <div
                        className="service-name"
                        onClick={() => {
                          var ls = require('local-storage');
                          ls.set('serviceId', data._id);
                          this.props.history.push({
                            pathname: `/services/detail/${data._id}`,
                            serviceId: data._id,
                          });
                        }}
                      >
                        {data.name}
                      </div>
                    </Col>
                    <Col xs={7} md={7} className="icon-col">
                    <Tooltip title="Edit Service">
                      <EditFilled
                        className="service-hidden-icons service-icons"
                        onClick={(e) => this.props.editService(e, data)}
                      />
                    </Tooltip>
                      {user_type === 2 && !data?.isSponsored && (
                         <Tooltip title="Sponsor Service">
                        <NotificationFilled
                          className="service-hidden-icons service-icons"
                          onClick={() =>
                            this.setState({
                              promoteEntity: true,
                              promoteServiceId: data._id,
                            })
                          }
                        />
                        </Tooltip>
                      )}
                      {user_type === 2 && data.isSponsored && (
                       <Tooltip title="Stop Sponsorisation">
                       <StopOutlined
                          onClick={() => {
                               this.setState({
                                  stopSponsorJobId: data._id,
                                  confirmStopSponsorPopup: true,
                                })
                          }}
                          className="service-hidden-icons service-icons"
                        />
                        </Tooltip>
                      )}
                        <Tooltip title="Delete Service">
                      <DeleteFilled
                        className="service-hidden-icons service-icons"
                        onClick={() => {
                             this.setState({
                                deleteServiceId: data._id,
                                confirmDeletePopup: true,
                              })
                        }}
                      />
                      </Tooltip>
                      <Switch
                        defaultChecked={data.isActivated}
                        checked ={this.state.checked[i]}
                        onChange={(checked) => {
                          this.setState({ id: data._id }, () =>
                            this.changeActiveStatus(this.state.checked[i],i,checked)
                          );
                        }}
                      />
                    </Col>
                    <Col xs={20} md={21}>
                      <div className="service-s-desc">
                        {data.shortDescription}
                      </div>
                    </Col>
                    <Col xs={4} md={3}>
                      <div className="sponsered" style={{ textAlign: 'right' }}>
                        {data.isSponsored ? 'SPONSORED' : ''}
                      </div>
                    </Col>
                  {/* </Row>
                  <Row align="bottom" style={{ marginTop: '10px' }}> */}
                  <Col xs={12} md={0}>
                      <ClockCircleFilled className="service-icons" />
                      <div className="service-location">{data.period}</div>
                    </Col>
                    <Col xs={12} md={6}>
                      <DollarCircleFilled className="service-icons" />

                      <div className="service-location">
                        {CURRENCY.filter(
                          (values) => values.name === data.price.currency
                        ).length > 0 &&
                          CURRENCY.filter(
                            (values) => values.name === data.price.currency
                          )[0].symbol}
                        &nbsp; {data.price.amount}
                      </div>
                      {/* <span>{data.price.currency}</span> */}
                    </Col>
                    <Col
                      xs={24}
                      md={9}
                      style={{ display: 'flex', alignSelf: 'self-start' }}
                    >
                      <EnvironmentFilled className="service-icons" />
                      <div
                        className="service-location"
                        style={{ display: 'block' }}
                      >
                        {data.location}
                      </div>
                    </Col>
                    <Col xs={0} md={9}>
                      <ClockCircleFilled className="service-icons" />
                      <div className="service-location">{data.period}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            </div>
          ))
        )}
        {this.state.data.length > 0 && (
          <div className={'d-flex justify-content-center pt-4'}>
            <span
              className={
                this.state.current_page === 1 ? 'pagination-side-btn' : ''
              }
              onClick={() =>
                this.makeHttpRequestWithPage(this.state.current_page - 1)
              }
            >
              {' '}
              <LeftOutlined />{' '}
            </span>
            <div>{renderPageNumbers}</div>
            <span
              className={
                this.state.current_page === this.state.totalPages
                  ? 'pagination-side-btn'
                  : ''
              }
              onClick={() =>
                this.state.current_page !== this.state.totalPage &&
                this.makeHttpRequestWithPage(this.state.current_page + 1)
              }
            >
              <RightOutlined />
            </span>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    servicelistdata: state.ServiceReducer.servicelistdata,
    isloading: state.ServiceReducer.isloading,
  };
};
export default withRouter(connect(mapStateToProps)(ServiceCards));
