import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  Card,
  Tag,
  Button,
  Carousel,
  Image,
} from 'antd';
import { CURRENCY } from '../../constants/currency';
import {
  LeftOutlined,
  MoneyCollectFilled,
  ClockCircleFilled,
} from '@ant-design/icons';
import '../topNav.css';
import './ProductDetail.css';
import styles from './productDetails.module.css';
import APIManager from '../../../APIManager';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import NewsComp from '../Services/news_comp';
import userDefaultPic from '../../../Components/asset/user.svg';
import { fileUrl } from '../../../Shared/imageUrlPath';
import VideoSection from '../../videoSection/VideoSection';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

const { Search } = Input;
const { Option } = Select;

const ProductDetail = (props) => {
  const [loading, setLoading] = useState(true);
  const [productData, setproductData] = useState([]);
  const [interstedProduct, AddedtoIntersted] = useState(false);
  const [myPrdouct, setMyProduct] = useState(false);
  useEffect(() => {
    const { id } = props.match.params;
    const productId = props.match.params;
    setLoading(true);
    APIManager.getOneProduct(id)
      .then((response) => {
        if (response.data.isSuccess) {
          setproductData(response.data.data);
          setLoading(false);
          setMyProduct(response.data.myEntity);
          AddedtoIntersted(response.data.isInterested);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    });
  const saveAsIntersted = () => {
    const { id } = props.match.params;
    const param = {
      interestId: id,
      category: 'product',
    };
    setLoading(true);
    var ls = require('local-storage');
    const token = ls.get('token');
    if (token) {
      APIManager.createInterested(param)
        .then((response) => {
          if (response.data.isSuccess) {
            setLoading(false);
            AddedtoIntersted(response.data.message);
            //APIManager.getOneProduct(serviceId);
          }
        })
        .catch((error) => console.log(error));
    } else {
      props.history.push('/login');
    }
  };

  const ProductListingRender = () => {
    props.history.goBack();
  };
  const data = productData;
  var ls = require('local-storage');
  const token = ls.get('token');
  console.log('dataaaaa', data);
  return (
    <>
      <Helmet>
        <title>{`${data?.name}-${data?.companyData?.comp_info?.comp_name}-Sendbiz`}</title>
        <meta
          property="og:title"
          content={`${data?.name}-${data?.companyData?.comp_info?.comp_name}-Sendbiz`}
        />
        <meta
          name="twitter:card"
          content={`${data?.name}-${data?.companyData?.comp_info?.comp_name}-Sendbiz`}
        />

        <meta name="description" content={data?.fullDescription} />
        <meta property="og:description" content={data?.fullDescription} />
        <meta name="twitter:description" content={data?.fullDescription} />
      </Helmet>
      {loading && (
        <div className={'d-flex justify-content-center'}>
          <Spin />
        </div>
      )}
      <div className={`detail-outer-div ${styles.mainContainer}`}>
        <div style={{ paddingBottom: '16px' }}>
          <LeftOutlined className="back-icon" onClick={ProductListingRender} />
          <span className="back-btn" onClick={ProductListingRender}>
            Go back
          </span>
        </div>
        <Row gutter={32}>
          <Col md={8} xs={24} className={styles.card1}>
            <Card bordered={false} className="s-detail-left-card">
              <h4
                className="s-detail-name"
                style={{
                  marginTop: '0',
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {data.name}
              </h4>
              <p className="s-detail-comp-name">
              <NavLink
                        to={`/comp-id${data?.companyData?._id}`}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        target="_blank"
                        className=" comp-hover "
                        style={{ color: 'black', padding: '0px' }}
                      >
                {data.companyData && data.companyData.comp_info.bus_name}
                      </NavLink>
              </p>
              <p
                className="s-detail-short-desc"
                style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
              >
                {data.shortDescription
                  ? data.shortDescription.charAt(0).toUpperCase() +
                    data.shortDescription.slice(1)
                  : ''}
              </p>
              {data.category &&
                data.category?.map((cat) => (
                  <Tag
                    color="volcano"
                    style={{ maxWidth: '100%' }}
                    className="s-price-tag"
                  >
                    {cat.categoryName}
                  </Tag>
                ))}
              <p
                className="s-detail-val"
                style={{ fontSize: '24px', margin: '10px 0 5px' }}
              >
                {data.price?.currency &&
                  CURRENCY.filter(
                    (values) => values.name === data.price.currency
                  )[0]?.symbol}{' '}
                {data.price && data.price.amount}
              </p>
              <p className="s-detail-attr" style={{ margin: '0 0 15px' }}>
                Price
              </p>

              <div>
                <Row gutter={16} style={{ margin: '40px 0 28px' }}>
                  <Col
                    span={8}
                    style={{
                      borderRight: 'solid 1px #6c7a8f20',
                      wordWrap: 'break-word',
                    }}
                  >
                    <p className="s-detail-val">{data.availability}</p>
                    <p className="s-detail-attr">Availability</p>
                  </Col>
                  <Col
                    span={8}
                    style={{
                      borderRight: 'solid 1px #6c7a8f20',
                      textAlign: 'center',
                      wordWrap: 'break-word',
                    }}
                  >
                    <p className="s-detail-val">{data.reference}</p>
                    <p className="s-detail-attr">Reference</p>
                  </Col>
                  <Col
                    span={8}
                    style={{ textAlign: 'center', wordWrap: 'break-word' }}
                  >
                    <p className="s-detail-val">
                      {data.inStock ? 'Yes' : 'No'}
                    </p>
                    <p className="s-detail-attr">In stock</p>
                  </Col>
                </Row>
              </div>
              {data.companyData &&
                data.companyData.social_link?.map((val, index) => (
                  <>
                    {val.tag === 'Facebook' ? (
                      <a href={val.link}>
                        <img
                          src={process.env.PUBLIC_URL + '/facebook.png'}
                          alt=""
                          style={{
                            fontSize: '30px',
                            marginRight: '17px',
                            cursor: 'pointer',
                          }}
                        />
                      </a>
                    ) : null}
                    {val.tag === 'LinkedIn' ? (
                      <a href={val.link}>
                        <img
                          src={process.env.PUBLIC_URL + '/linkedin.png'}
                          alt=""
                          style={{
                            fontSize: '30px',
                            marginRight: '17px',
                            cursor: 'pointer',
                          }}
                        />
                      </a>
                    ) : null}
                    {val.tag === 'Twitter' ? (
                      <a href={val.link}>
                        <img
                          src={process.env.PUBLIC_URL + '/twitter.png'}
                          alt=""
                          style={{
                            fontSize: '30px',
                            marginRight: '17px',
                            cursor: 'pointer',
                          }}
                        />
                      </a>
                    ) : null}
                  </>
                ))}
              <Row style={{ marginTop: '27px' }}>
                <Col span={11}>
                  {token && (
                    <a href={`${data.buyUrl}`} target="_blank">
                      <Button className="shop-btn">Shop this Product</Button>
                    </a>
                  )}
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  {!myPrdouct && (
                    <button
                      className={`intrested-button ${styles.notIntrestedBtn}`}
                      onClick={saveAsIntersted}
                    >
                      {loading && 'saving...'}
                      {!loading &&
                        (interstedProduct ? 'Not Interested' : 'Interested')}
                    </button>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={16} xs={24}>
            <Card className={`s-detail-r-card`} bordered={false}>
              <p className="s-detail-comp-name">
                {data.companyData && data.companyData.comp_info.bus_name}
              </p>
              <Carousel
                //afterChange={this.onChange}
                className="event-carousel m-2"
                autoplay
                arrows={true}
              >
                {data.media &&
                  data.media
                    ?.filter((data) => data.fileType.includes('image'))
                    ?.map((data) => (
                      <div className={'d-flex justify-content-center'}>
                        <Image
                          src={
                            data.fileName
                              ? fileUrl.fileUrlPath + data.fileName
                              : userDefaultPic
                          }
                          alt="event"
                          className="event-carousel-img"
                          // width={'100%'}
                          // height={350}
                          style={{ objectFit: 'cover' }}
                        />
                      </div>
                    ))}
              </Carousel>

              <br />
              <h4 className="r-card-head">About the product</h4>
              {data.media &&
                data.media.filter((data) => data.fileType?.includes('video'))
                  .length > 0 && (
                  <VideoSection
                    media={data.media.filter((data) =>
                      data.fileType?.includes('video')
                    )}
                  />
                )}
              <p
                className="r-card-text"
                style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
              >
                {data.fullDescription
                  ? data.fullDescription.charAt(0).toUpperCase() +
                    data.fullDescription.slice(1)
                  : ''}
              </p>
              <h4
                className="r-card-head"
                style={{ paddingTop: '20px', marginBottom: '0' }}
              >
                Product Information
              </h4>
              <Row>
                <Col md={12} xs={24}>
                  <p
                    className={`s-detail-attr ${styles.font18}`}
                    style={{ marginTop: '10px' }}
                  >
                    Delivery Time
                  </p>
                  <ClockCircleFilled
                    className={`p-detail-icons ${styles.font18}`}
                  />
                  <span
                    style={{ wordWrap: 'break-word' }}
                    className={`s-detail-val ${styles.font18}`}
                  >
                    {data.deliveryTime}
                  </span>
                </Col>
                {/* card 2 */}
                <Col md={12} xs={24}>
                  <p
                    className={`s-detail-attr ${styles.font18}`}
                    style={{ marginTop: '10px' }}
                  >
                    Pricing Plan
                  </p>
                  <MoneyCollectFilled
                    className={`p-detail-icons ${styles.font18}`}
                  />
                  <span
                    style={{ wordWrap: 'break-word' }}
                    className={`s-detail-val ${styles.font18}`}
                  >
                    {data.pricingPlan}
                  </span>
                </Col>
              </Row>
              {/*====================commenting this button because this button is already available==================*/}
              {/* <div style={{ marginTop: '27px', width: '200px' }}>
                {token && (
                  <a href={`${data.buyUrl}`} target="_blank">
                    <Button className="shop-btn">Shop this Product</Button>
                  </a>
                )}
              </div> */}
            </Card>
          </Col>
        </Row>
      </div>
      <Row className={styles.card2Padding}>
        <Col span={24}>
          <NewsComp />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user,
  };
};
export default connect(mapStateToProps)(ProductDetail);
