import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import QualificationIcon from '../../asset/Qualification-icon.png';
import { fileUrl } from '../../../Shared/imageUrlPath';
import styles from './visitProfile.module.css';
import moment from 'moment';

class CompanyMedia extends Component {
  render() {
    const educationData = this.props?.data?.data?.data?.can_qualification;
    return (
      <div style={{ backgroundColor: 'white' }}>
        <Row style={{ paddingTop: 35 }}>
          <Col span={24}>
            <p className="visit_education-1">Education Qualification</p>
          </Col>
        </Row>
        {educationData &&
          educationData
            ?.sort((a, b) => (a.from > b.from ? -1 : 1))
            ?.map((data) => (
              <>
                <Row>
                  <Col sm={4} xs={8} className={styles.educationImage}>
                    <img
                      src={
                        data?.qualification_image
                          ? fileUrl.fileUrlPath + data?.qualification_image
                          : QualificationIcon
                      }
                      alt='qualificationIcon'
                      className="visit_education-2"
                    />
                  </Col>
                  <Col sm={18} xs={16} className={styles.educationRow2}>
                    <Row>
                      <Col span={24}>
                        <p
                          className={`visit_education-3 ${styles.educationHeading}`}
                        >
                          {data?.institute}
                        </p>
                      </Col>
                      <Col span={24}>
                        <p
                          className={`visit_education-4 ${styles.educationSubHeading}`}
                        >
                          {data?.degree}
                        </p>
                      </Col>
                      <Col span={24}>
                        <p
                          className={`visit_education-4 ${styles.educationSubHeading}`}
                        >
                          {data?.university}
                        </p>
                      </Col>
                      <Col span={24}>
                        <p
                          className={`visit_education-4 ${styles.educationSubHeading}`}
                        >
                          {moment(data?.from).format('YYYY')} to{' '}
                          {moment(data?.to).format('YYYY')}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            ))}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.companyInfoReducer.data,
    isloading: state.companyInfoReducer.isloading,
  };
};
export default connect(mapStateToProps)(CompanyMedia);
