import React, { useEffect, useState } from 'react';
import APIManager from '../../APIManager';
import { Select } from 'antd';

export default function SelectSector(props) {
  const [sectOptions, setSectorOptions] = useState([]);
  
  const [search, setsearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageNumber, setpageNumber] = useState(null);
  const [lengthSelectedSectors, setLengthSectors] = useState(null);
  useEffect(() => {
    const search = '';
    sectorList(1, search);
  }, [props]);

  useEffect(() => {
    sectorList(pageNumber, search);
  }, [sectOptions]);

  const handleSectorChange = (value) => {
    setLengthSectors(value.length);
    props.handleSectorChange(value);
  };
  const handleSectorSearch = (value) => {
    setSectorOptions([]);
    setsearch(value);
    // sectorList(1,value)
  };
  const onScroll = (event) => {
    var target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = pageNumber + 1;
      sectorList(page, search);
    }
  };
  const sectorList = (pageNumber = 1, search) => {
    setpageNumber(pageNumber);
    let options = [];
    APIManager.allJobSectors(pageNumber, search.toLowerCase())
      .then((resp) => {
        resp?.data?.data?.map((data) => {
          const suggestionExist = sectOptions?.find((x) => x?.key === data?._id);
          if (typeof suggestionExist === 'undefined') {
            options.push({
              label: data ? data?.title : '',
              value: data ? data?.id : '',
              key: data?._id,
            });
          }
        });
        if (options.length > 0) {
          setSectorOptions([...sectOptions, ...options]);
        }
        // else{
        //   setSectorOptions(options)
        // }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Select
      mode="multiple"
      maxTagCount={lengthSelectedSectors > 1 ? 0 : 1}
      maxTagTextLength={10}
      placeholder="Career Category"
      name={props.name}
      filterOption={false}
      onChange={handleSectorChange}
      style={{ float: 'right' }}
      onSearch={handleSectorSearch}
      onPopupScroll={onScroll}
      className="s-search-select"
      options={sectOptions}
      onBlur={() => {
        handleSectorSearch('');
      }}
    ></Select>
  );
}
