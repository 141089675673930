export const ImageUrl = {
  imageUrlPath: 'https://sendbizbucket.s3.eu-west-3.amazonaws.com/',
};

export const MapKey = {
  key: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
};
export const fileUrl = {
  fileUrlPath: 'https://sendbizbucket.s3.eu-west-3.amazonaws.com/',
};
export const RootUrl = {
  basePath: `${process.env.REACT_APP_URL}/`,
};
