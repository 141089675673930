import React, { Component } from 'react';
import { Row, Col, Input, Select, Button, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ServicesCard from './service_card';
import APIManager from '../../../APIManager/index';
import { connect } from 'react-redux';
import './serviceslist.css';
import styles from './service.module.css';
import CompanyMenuDropDown from '../CompanyMenuDropDown';

const { Option } = Select;
let user_type = JSON.parse(localStorage.getItem('user_type') || false);
const user_type2 = JSON.parse(localStorage.getItem('user_type2') || false);
user_type = user_type / (user_type2 * 99);

class ServicesListWrapper extends Component {
  state = {
    searchQuery: '',
    addForm: true,
    sortValue: '',
  };
  componentDidMount() {
    APIManager.serviceList();
  }
  handleSort = (value) => {
    this.setState({
      sortValue: value,
    });
  };
  handleChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };
  renderAddService = (e, obj, type) => {
    if (type === 'edit') {
      this.props.history.push({
        pathname: '/company/edit-service',
        state: { service_obj: obj },
      });
    } else {
      APIManager.checkPremiumDetail().then((response) => {
        if (response.data.data.services) {
          this.props.history.push({
            pathname: '/company/add-service',
            state: { addForm: this.state.addForm },
          });
        } else {
          notification.warning({
            message: 'Please upgrade your plan to premium.',
            description:
              'Click here to upgrade your plan or delete/deactivate some jobs',
            onClick: () => {
              this.props.history.push('/package-plan');
            },
          });
        }
      });
    }

    // console.log(obj);
  };

  render() {
    const servicesCount = this.props.data ? this.props.data.data.length : '';

    return (
      <>
        <Row>
          <Col xs={24} md={0} style={{ marginBottom: '-20px' }}>
            <CompanyMenuDropDown name={'Manage Services'} />
          </Col>
        </Row>
        <div className="applied-job-paddingright">
          <Row
            className={`services-header ${styles.mainRow}`}
            justify="space-between"
          >
            <Col xs={0} md={12}>
              <span className="header-text">Manage Services</span>
            </Col>
            <Col xs={0} md={12}>
              <Button
                className={styles.button}
                onClick={this.renderAddService}
              >
                Add new Service
              </Button>
            </Col>
          </Row>
          <Row className={styles.searchRow}>
            <Col xs={24} md={8} className={styles.searchCol}>
              <Input
                size="large"
                className={`resume-data-1 company-joblist-input service-input ${styles.search}`}
                placeholder="Search for a Service"
                prefix={<SearchOutlined />}
                onChange={this.handleChange}
                value={this.state.searchQuery}
              />
            </Col>
            <Col xs={14} md={0}></Col>
            <Col xs={10} md={4} className={styles.sortCol}>
              <Select
                name="permissions"
                className="sort-select"
                style={{ float: 'right' }}
                placeholder="Sort By"
                onChange={this.handleSort}
              >
                <Option value="price.amount">Low to High</Option>
                <Option value="-price.amount">High to Low</Option>
                <Option value="reset">Reset</Option>
              </Select>
            </Col>
          </Row>
          <Row className={styles.cardCol}>
            <ServicesCard
              searchQuery={this.state.searchQuery}
              editService={(e, obj) => this.renderAddService(e, obj, 'edit')}
              sortValue={this.state.sortValue}
            />
          </Row>
          <Row>
          <Col xs={24} md={0} className={styles.buttonCol}>
              <Button
                className={styles.button}
                onClick={this.renderAddService}
              >
                Add new Service
              </Button>
            </Col>
            </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.ServiceReducer.data,
    isloading: state.ServiceReducer.isloading,
  };
};
export default connect(mapStateToProps)(ServicesListWrapper);
