import React, { useEffect, useState } from 'react';
import { Pie, measureTextWidth } from '@ant-design/charts';
import APIManager from '../../APIManager';

const DemoPie = () => {
  const [value, setValue] = useState([
    {
      budget: '.',
      name: 'loading',
      count: '.',
    },
  ]);

  const data = value
    ?.filter((data) => data.name !== 'subuser')
    ?.map((data) => ({
      budget: Number(data.budget),
      name: data.name[0].toUpperCase() + data.name.substring(1),
    }));

  var config = {
    appendPadding: 10,
    data: data,
    angleField: 'budget',
    colorField: `name`,
    radius: 1,
    innerRadius: 0.64,
    height: 150,
    color: ['#EE5050', '#F38383', '#F6A6A6', '#FACACA'],
    label: {
      type: 'inner',
      offset: '-30%',
      content: function content(_ref) {
        var percent = _ref.percent;
        return '';
      },
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [{ type: 'element-active' }],
  };
  useEffect(() => {
    APIManager.billingOverview().then((response) => {
      if (response.data.data.billingStats.length > 1)
        setValue(response.data.data.billingStats);
      else setValue(response.data.data.billingFormat);
      // console.log(response.data.data.billingStats)
    });
  }, []);
  return <Pie {...config} />;
};

export default DemoPie;
