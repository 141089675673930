import React, { useEffect, useState } from 'react';
import { Row, Spin, Col, Image, message, Collapse } from 'antd';
import Pagination from 'react-js-pagination';
import styles from './index.module.css';
import '../../screensOfTopNav/topNav.css';
import { withRouter } from 'react-router-dom';
import { fileUrl } from '../../../Shared/imageUrlPath';
import NoData from '../../../PageNotFound/NoData';
import { SendOutlined } from '@ant-design/icons';
import mailIcon from '../../asset/mail.svg';
import APIManager from '../../../APIManager';
import { CURRENCY } from '../../constants/currency';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

const SearchResourceCard = ({
  searchingValue,
  current_location,
  skills,
  firstTimeRender,
  searchLocation,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState('');
  const [resources, setResources] = useState([]);
  const [currentPage, setCurrentPage] = useState('');

  useEffect(() => {
    if (current_location?.length > 0) {
      loadData(1);
    }
  }, [searchingValue, current_location, skills]);

  const loadData = async (pageNumber = 1) => {
    const search = searchingValue;
    let searchCat = '';
    if (skills.length > 0) {
      skills?.map((val) => {
        searchCat = searchCat + `&skills=${val}`;
      });
    }
    let country = '';
    let state = '';
    let city = '';
    if (current_location.length > 0) {
      const locationArray = current_location.split(',');
      if (locationArray.length > 0) {
        country = locationArray[locationArray.length - 1];
        state =
          locationArray.length > 1
            ? locationArray[locationArray.length - 2]
            : '';
        city = locationArray.length > 2 ? locationArray[0] : '';
      }
    }
    await APIManager.getAllResources(
      pageNumber,
      search?.toLowerCase(),
      city,
      state,
      country,
      searchCat,
      searchLocation
    )
      .then((res) => {
        if (res) {
          if (res.data.isSuccess) {
            setResources(res?.data?.data);
            setTotalData(res?.data?.totalCount);
            setIsLoading(true);
          }
        }
      })
      .catch((error) => {
        message.error('Something Went Wrong, Please login again');
        setIsLoading(true);
      });
  };
  const handleLocationChange = (e, value) => {
    e.stopPropagation();
    props.changeMapLocation(value, value?._id);
  };
  return (
    <>
      {!isLoading && (
        <Row className={'d-flex justify-content-center mt-5'}>
          <Spin />
        </Row>
      )}
      {isLoading && (
        <Row className={styles.searchCardTop}>
          <Row>
            <p className={`card-div-head ${styles.numberOfCard}`}>
              {resources.length > 0
                ? resources.length +
                  `${
                    resources.length === 1
                      ? ' Resource found'
                      : ' Resources found'
                  }`
                : ''}
            </p>
          </Row>
          {resources.length === 0 && isLoading ? (
            <NoData
              text={
                'Please try using Different Location, Filters or keywords for your search.'
              }
            />
          ) : (
            resources?.map((data, index) => (
              <Row gutter={20} className={styles.searchCardRow}>
                <Col md={4} xs={7}>
                  <img
                    className={styles.image}
                    src={
                      data.companyData.logo.name
                        ? fileUrl.fileUrlPath + data.companyData.logo.name
                        : '/company_logo.png'
                    }
                    preview={false}
                  />
                </Col>
                <Col md={20} xs={17}>
                  <Row>
                    <Col span={22}>
                      <Col span={24} className={styles.searchTitle}>
                        {data?.title}
                      </Col>
                      <Row className={styles.innerRows}>
                        <Col md={8} xs={12}>
                          <span className={styles.titleNames}>Price: </span>
                          <span className={styles.titleData}>
                            {CURRENCY?.map((curr) =>
                              data
                                ? curr.name === data.price.currency
                                  ? `${curr.symbol} ${data.price.price}`
                                  : ''
                                : ''
                            )}
                          </span>
                        </Col>
                        <Col md={8} xs={12}>
                          <span className={styles.titleNames}>
                            Availability:{' '}
                          </span>
                          <span className={styles.titleData}>
                            {data?.availability}
                          </span>
                        </Col>
                        <Col md={8} xs={20}>
                          <span className={styles.titleNames}>
                            Experience:{' '}
                          </span>
                          <span className={styles.titleData}>
                            {data?.experience + ' years'}
                          </span>
                        </Col>
                      </Row>
                      <Row className={styles.innerRows1}>
                        <Col span={22}>
                          <span className={styles.titleNames}>Skills: </span>
                          <span className={styles.titleData}>
                            {data?.skills
                              ?.map((e) => {
                                return e?.title?.toUpperCase();
                              })
                              .join(', ')}
                          </span>
                        </Col>
                      </Row>
                      <Row className={styles.innerRows1}>
                        <Col md={0} xs={24}>
                          <span className={styles.titleNames}>Applied on:</span>
                          <span className={styles.titleData}>
                            {moment(data.createdAt).format('DD MMM YY')}
                          </span>
                        </Col>
                        <Col md={8} xs={24}>
                          <a
                            target="_blank"
                            href={`${fileUrl.fileUrlPath}${data?.resume}`}
                            style={{
                              textDecoration: 'none',
                              paddingLeft: '0',
                            }}
                          >
                            <span className={styles.titleNames}>
                              View Resume PDF
                            </span>
                          </a>
                        </Col>
                        <Col md={8} xs={0}>
                          <span className={styles.titleNames}>Applied on:</span>
                          <span className={styles.titleData}>
                            {moment(data.createdAt).format('DD MMM YY')}
                          </span>
                        </Col>
                        <Col md={8} xs={24}>
                          <a
                            target="_blank"
                            href={`${fileUrl.fileUrlPath}${data?.document}`}
                            style={{
                              textDecoration: 'none',
                              paddingLeft: '0',
                            }}
                          >
                            <span className={styles.titleNames}>
                              View Document PDF{' '}
                            </span>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      span={2}
                      className={styles.arrowCol}
                      onClick={(e) => handleLocationChange(e, data)}
                    >
                      <SendOutlined className={styles.arrow} />
                    </Col>
                  </Row>
                </Col>
                {/*=============collapese details ====================*/}
                <Col
                  span={24}
                  style={{ marginTop: '20px' }}
                  className={'colapsable'}
                >
                  <Collapse>
                    <Collapse.Panel header="More Info">
                      <Row className={styles.rowCollapse}>
                        <Col md={6} xs={8} className={styles.collapseHeaders}>
                          Location:
                        </Col>
                        <Col md={18} xs={16} className={styles.collapseValue}>
                          {data?.location}
                        </Col>
                        <Col md={6} xs={8} className={styles.collapseHeaders}>
                          Information:
                        </Col>
                        <Col md={18} xs={16} className={styles.collapseValue}>
                          {data?.information}
                        </Col>
                        <Col xs={24} className={styles.collapeseContact}>
                          CONTACT
                        </Col>
                        <Col sm={6} xs={8} className={styles.collapseHeaders}>
                          Company:
                        </Col>
                        <Col sm={18} xs={16} className={styles.collapseValueCompany}>
                          <NavLink
                            to={`comp-id${data.companyData._id}`}
                            onClick={(e) => e.stopPropagation()}
                            className={styles.companyText}
                          >
                            {data.companyData.comp_info.bus_name}
                          </NavLink>
                        </Col>
                        <Col sm={6} xs={8} className={styles.collapseHeaders}>
                          Username:
                        </Col>
                        <Col sm={18} xs={16} className={styles.collapseValue}>
                          {data.companyData.comp_info.first_name +
                            ' ' +
                            data.companyData.comp_info.last_name}
                        </Col>
                        <Col sm={6} xs={8} className={styles.collapseHeaders}>
                          User Job Title:
                        </Col>
                        <Col sm={16} xs={14} className={styles.collapseValue}>
                        {data?.companyData?.comp_info?.job_profile}
                        </Col>
                        <Col xs={2} className={styles.collapseValue}>
                          <div
                            onClick={() => {
                              props.history.push({
                                pathname: '/company-mailbox',
                                userInfo: Object.assign(data?.companyData),
                                canId: data?.companyData?.comp_id,
                              });
                            }}
                          >
                            <img
                              src={mailIcon}
                              style={{ cursor: 'pointer', marginLeft: '2px' }}
                              alt=""
                            />
                          </div>
                        </Col>
                        <Col sm={6} xs={8} className={styles.collapseHeaders}>
                          Email:
                        </Col>
                        <Col sm={16} xs={14} className={styles.collapseValue}>
                          {data?.email}
                        </Col>
                        <Col xs={2} className={styles.collapseValue}>
                          <a
                            className={styles.emailIcon}
                            href={`mailto:${data?.email}`}
                          >
                            <img src="/telegram.png" alt="" />
                          </a>
                        </Col>
                        <Col sm={6} xs={8} className={styles.collapseHeaders}>
                          Phone Number:
                        </Col>
                        <Col sm={18} xs={16} className={styles.collapseValue}>
                          {data?.companyData?.contact_info?.phone_no ? data?.companyData?.contact_info?.phone_no : '-'}
                        </Col>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </Col>
              </Row>
            ))
          )}
        </Row>
      )}
      {/* for pagination on company page */}
      {resources.length !== 0 ? (
        <Row className={'paginate-container'}>
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={20}
            firstPageText={false}
            lastPageText={false}
            totalItemsCount={totalData}
            pageRangeDisplayed={5}
            onChange={(pageNumber) => {
              loadData(pageNumber);
            }}
            itemClass="page-item"
            linkClass="page-link"
          />
        </Row>
      ) : (
        ''
      )}
    </>
  );
};

export default withRouter(SearchResourceCard);
