import React, { useState } from 'react';
import { Row, Col, Input, Select, Button, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ProductCard from './product_card';
import APIManager from '../../../APIManager/index';
import './serviceslist.css';
import styles from './product.module.css';
import CompanyMenuDropDown from '../CompanyMenuDropDown';

const { Option } = Select;

const ProductsListWrapper = (props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [sortValue, setSortValue] = useState('');
  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const checkPremiumAccount = () => {
    APIManager.checkPremiumDetail().then((response) => {
      if (response.data.data.products) {
        renderAddProduct();
      } else {
        notification.warning({
          message: 'Please upgrade your plan to premium.',
          description:
            'Click here to upgrade your plan or delete/deactivate some jobs',
          onClick: () => {
            props.history.push('/company/billing');
          },
        });
      }
    });
  };
  const renderAddProduct = (e, obj, type) => {
    if (type === 'edit') {
      props.history.push({
        pathname: '/company/edit-product',
        product_obj: obj,
        state: { addForm: false },
      });
    } else {
      props.history.push({
        pathname: '/company/add-product',
        state: { addForm: true },
      });
    }
  };

  const productDetailRender = (e, data) => {
    props.history.push({
      pathname: '/products/detail',
      product_obj: data,
    });
  };

  return (
    <>
      <Row>
        <Col xs={24} md={0} style={{ marginBottom: '10px' }}>
          <CompanyMenuDropDown name={'Manage Products'} />
        </Col>
      </Row>
      <div>
        <Row className="services-header" justify="space-between">
          <Col xs={0} md={12}>
            <span className="header-text">Manage Products</span>
          </Col>
          <Col xs={0} md={12}>
            <Button className="add-service-btn" onClick={checkPremiumAccount}>
              Add new Product
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={8} className={styles.searchRow}>
            <Input
              size="large"
              className={`resume-data-1 company-joblist-input service-input ${styles.searchBar}`}
              placeholder="Search for a Product"
              prefix={<SearchOutlined />}
              onChange={handleChange}
              value={searchQuery}
            />
          </Col>
          <Col xs={17} md={0}></Col>
          <Col xs={6} md={4} className={styles.sort}>
            <Select
              name="permissions"
              className="sort-select"
              style={{ float: 'right' }}
              placeholder="Sort By"
              onChange={(e) => setSortValue(e)}
            >
              <Option value="price.amount">Low to High</Option>
              <Option value="-price.amount">High to Low</Option>
              <Option value="reset">Reset</Option>
            </Select>
          </Col>
          <Col xs={1} md={0}></Col>
        </Row>
        <ProductCard
          searchQuery={searchQuery}
          editProduct={(e, obj) => renderAddProduct(e, obj, 'edit')}
          productDetailRender={(e, data) => productDetailRender(e, data)}
          sortValue={sortValue}
        />
        <Col xs={24} md={0} className={styles.buttonCol}>
            <Button className={`add-service-btn ${styles.button}`} onClick={checkPremiumAccount}>
              Add new Product
            </Button>
          </Col>
      </div>
    </>
  );
};
export default ProductsListWrapper;
