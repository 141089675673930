import { ReloadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

function ClaimCompanyModal({ show, closeCb, detail,...restProps }) {
  const [captcha,setCaptcha]=useState(generateCaptcha())
  const [text,setText]=useState('')
  
  const handleSubmit = (values) => {
    if(text === captcha){
      restProps.history.push('/signup', {claim_company:true,comp_detail:detail })
    }
    else{
      alert("Invalid Captcha")
    }
  };

  function generateCaptcha () {
    var chr1 = Math.ceil(Math.random() * 10) + '';
    var chr2 = Math.ceil(Math.random() * 10) + '';
    var chr3 = Math.ceil(Math.random() * 10) + '';

    var str = new Array(4).join().replace(/(.|$)/g, function () {
      return ((Math.random() * 36) | 0)
        .toString(36)
        [Math.random() < 0.5 ? 'toString' : 'toUpperCase']();
    });
    var captchaCode = str + chr1 + ' ' + chr2 + ' ' + chr3;

    return removeSpaces(captchaCode);
  };
  function removeSpaces(data) {
    return data.split(' ').join('');
  };

  const resetCapctha = () => {
    setCaptcha(generateCaptcha() );
  };
  return (
    <Modal show={show} onHide={closeCb} className="claim-modal">
      <Modal.Header closeButton>
        <Modal.Title>Claim your Company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
       
          onFinish={handleSubmit}
          initialValues={{
            name: detail?.comp_info?.comp_name,
            city: detail?.contact_info?.city,
            country: detail?.contact_info?.country,
            state: detail?.contact_info?.state,
            zip_code: detail?.contact_info?.zip_code,
          }}
        >
          <Row gutter={10}>
            <Col xs={24}>
              <Form.Item name="name" >
                <Input disabled placeholder='Company Name'/>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item name="city">
                <Input disabled  placeholder='City'/>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item name="country">
                <Input disabled placeholder='Country' />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item name="state">
                <Input disabled placeholder='State' />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item name="zip_code">
                <Input disabled placeholder='Zip Code' />
              </Form.Item>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
                  <div style={{ display: 'flex', }}>
                    <div
                      style={{
                        width: 250,
                        backgroundColor: 'rgb(241, 243, 246)',
                        borderRadius: '11px',
                        border: 'ridge',
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <i
                        style={{
                          fontSize: 'large',
                          fontFamily: 'cursive',
                          userSelect: 'none',
                          padding: '6px',
                        }}
                      >
                      {/* {console.log("ccccc",this.state.captcha)} */}
                        {captcha}
                      </i>
                    </div>
                    <div style={{ marginLeft: '2vh' }}>
                      <Tooltip title="Reload">
                        <div
                          style={{
                            border: '1px solid black',
                            borderRadius: '5px',
                            marginTop: '4px',
                          }}
                        >
                          <Button
                            className="button1"
                            // type="primary"
                            shape="circle"
                            style={{ border: 'white' }}
                            onClick={() => resetCapctha()}
                          >
                            <ReloadOutlined />
                          </Button>
                        </div>
                      </Tooltip>
                    </div>
                  </div>

                  <Input
                    placeholder="Enter Captcha"
                    value={text}
                    //   error={captcha_error}
                    //   id={!captcha_error ? 'outlined-input' : 'outlined-error'}
                    //   value={inputValue}
                    onChange={(e)=>setText(e.target.value)}
                    style={{
                      width: '250px',
                      height: '6vh',
                      borderRadius: '11px',
                      marginTop: '2vh',
                      marginBottom: '25px',
                    }}
                  />
                </Row>
          <Row className="d-flex justify-content-center">
            <button className="company-active">Claim your Company</button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default withRouter(ClaimCompanyModal);
