import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import styles from './visitProfile.module.css';
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';

class CompanyMedia extends Component {
  state = {
    callAddMedia: false,
  };
  handleClick = () => {
    this.setState({ callAddMedia: true });
  };
  closeModal = () => {
    this.setState({ callAddMedia: false });
  };
  render() {
    const data = this.props?.data?.data?.data;
    const jobCategory = data?.can_detail?.jobCategory;
    const skills = data?.can_detail?.skills;
    const bio = data?.can_detail?.description;
    const hobbies = data?.can_detail?.hobbies;
    const desiredLocation = data?.can_detail?.desiredLocation;
    const canSocial = data?.can_social;

    return (
      <div>
        <div style={{ backgroundColor: 'white' }}>
          {/***************  job Category ****************/}
          <Row className="p-info-head" style={{ marginTop: '0' }}>
            <Col span={24} style={{ paddingTop: 35 }}>
              <p className="personal-info-1">Job Category</p>
            </Col>
          </Row>
          <Row>
            {jobCategory &&
              jobCategory?.map((data) => (
                <Col sm={7} xs={8} style={{ marginBottom: 12 }}>
                  <div className="personal-info-2">{data?.title}</div>
                </Col>
              ))}
          </Row>
          <Row className="p-info-head">
            <Col span={24}>
              <p className="personal-info-3 ">Skills</p>
            </Col>
          </Row>
          {/***************  skills ****************/}
          <Row gutter={15}>
            {skills &&
              skills?.map((data) => (
                <Col
                  style={{ marginBottom: 12 }}
                  span={data.length < 10 ? 7 : 8}
                >
                  <div
                    className={`personal-info-2 personal-info-skill ${styles.skillsData}`}
                  >
                    {data}
                  </div>
                </Col>
              ))}
          </Row>
          {/********************  Bio Section **********************/}
          <Row>
            <Col span={24} className="p-info-head">
              <p className="personal-info-3">Bio</p>
            </Col>
          </Row>
          <Row>
            <Col sm={16} xs={24}>
              <p className="personal-info-4">
                {bio?.charAt(0)?.toUpperCase() + bio?.slice(1)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="p-info-head">
              <p className="personal-info-3">Hobbies</p>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <p className="personal-info-4 personal-info-skill">{hobbies}</p>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="p-info-head">
              <p className="personal-info-3">Desired Locations</p>
            </Col>
          </Row>
          <Row gutter={12}>
            {desiredLocation &&
              desiredLocation?.map((data) => (
                <Col
                  sm={data.length < 10 ? 5 : 8}
                  xs={data.length < 10 ? 8 : 6}
                  style={{ marginBottom: 12 }}
                >
                  <div
                    className={`personal-info-2 personal-info-skill ${styles.desiredLocationData}`}
                  >
                    {/* {console.log(data, 'okkkkkkkkkkk')} */}
                    {data}
                  </div>
                </Col>
              ))}
          </Row>
          {/***************  social media section ***********************/}
          <Row className="p-info-head">
            <Col span={24}>
              <p className="personal-info-3">Social</p>
            </Col>
          </Row>
          <Row className={styles.socialMedia}>
            {canSocial &&
              canSocial?.map((data) => {
                switch (data.name) {
                  case 'facebook':
                    return (
                      <Col>
                        <a
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FacebookOutlined />
                        </a>
                      </Col>
                    );
                  case 'instagram':
                    return (
                      <Col>
                        <a
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <InstagramOutlined />
                        </a>
                      </Col>
                    );
                  case 'twitter':
                    return (
                      <Col>
                        <a
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TwitterOutlined />
                        </a>
                      </Col>
                    );
                  case 'youtube':
                    return (
                      <Col>
                        <a
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <YoutubeOutlined />
                        </a>
                      </Col>
                    );
                  case 'linkedin':
                    return (
                      <Col>
                        <a
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedinOutlined />
                        </a>
                      </Col>
                    );
                  case 'printerest':
                    return (
                      <Col>
                        <a
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            width="30px"
                            height="20px"
                            src="https://icones.pro/wp-content/uploads/2021/07/icone-pinterest-rouge.png"
                            alt="pinterest logo"
                          />
                        </a>
                      </Col>
                    );
                  default:
                    return '';
                }
              })}
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.companyInfoReducer.data,
    isloading: state.companyInfoReducer.isloading,
  };
};
export default connect(mapStateToProps)(CompanyMedia);
