import React, { Component } from 'react';
import JobCards from './job_cards';
import styles from './companyJobs.module.css';
import { Row, Col, Input, notification, Select } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import JobPromote from './jobPromoteModal';
import DeletePopup from './jobDeleteCardPopup';
import './company.css';
// import 'antd/dist/antd.css';
import APIManager from '../../APIManager/index';
import { connect } from 'react-redux';
import { reloadComponent } from '../../Redux/Actions/JobManagementAction';
import CompanyMenuDropDown from './CompanyMenuDropDown';

const { Option } = Select;

class CompanyJobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPromoteModal: false,
      openJobDeletePopup: false,
      searchingValue: '',
      current: 1,
      totalResults: '',
      reload: false,
      sort: '',
    };
  }

  componentDidMount() {
    this.setzero();
  }
  // componentDidMount() {
  //   APIManager.myJobs(this.state.current).then((resp) => {
  //     this.setState({
  //       totalResults: resp.data.totalData,
  //     });
  //   });
  // }

  handlePromoteModal = () => {
    let val = this.state.openPromoteModal;
    this.setState({ openPromoteModal: !val });
  };
  handleDeleteModal = () => {
    let val = this.state.openJobDeletePopup;
    this.setState({ openJobDeletePopup: !val });
  };
  checkPremiumAccount = () => {
    APIManager.checkPremiumDetail().then((response) => {
      if (response.data.data.jobs) {
        this.CreateJob();
      } else {
        notification.warning({
          message: 'Please upgrade your plan to premium.',
          description:
            'Click here to upgrade your plan or delete/deactivate some jobs',
          onClick: () => {
            this.props.history.push('/company/billing');
          },
        });
      }
    });
  };
  CreateJob = () => {
    this.props.history.push({
      pathname: '/add-job',
      state: {
        editJob: false,
      },
    });
  };

  renderAddService = (e, obj) => {
    this.props.history.push({
      pathname: '/edit-job',
      state: {
        job_obj: obj,
        editJob: true,
      },
    });
  };

  onChange = (page) => {
    this.setState({
      current: page,
    });
  };

  handleSectorSort = (e) => {
    this.setState({
      sort: e,
    });
  };

  //handle search query
  handleChange = (event) => {
    this.setState({ searchingValue: event.target.value });
  };
  setzero = () => {
    this.setState({
      reload: true,
    });

    if (this.state.reload) {
      APIManager.myJobs();
      // myjobManagementData();
      reloadComponent();
    } else {
      APIManager.myJobs();
    }
  };
  render() {
    // console.log(this.props.myjobData, 'this.props.myjobData');
    const jobPosted = this.props.myjobData
      ? this.props.myjobData.totalCount
      : '';
    const activeJobCount = this.props.myjobData
      ? this.props.myjobData.activeTotalCount
      : '';
    return (
      <>
        <Row>
          <Col xs={24} md={0} style={{ marginBottom: '-20px' }}>
            <CompanyMenuDropDown name={'Jobs'} />
          </Col>
        </Row>
        <div>
          {this.state.openPromoteModal ? (
            <JobPromote
              isOpen={this.state.openPromoteModal}
              close={this.handlePromoteModal}
            />
          ) : null}
          {this.state.openJobDeletePopup ? (
            <div className="deleteModal">
              <DeletePopup
                isOpen={this.state.openJobDeletePopup}
                close={this.handleDeleteModal}
              />
            </div>
          ) : null}
          <Row className={styles.headRow}>
            <Col xs={0} sm={12} className={styles.headCol1}>
              Manage Jobs
            </Col>
            <Col xs={0} sm={12} className={styles.headCol2}>
              <button
                type="button"
                className="btn btn-dark bold-family btn-save-font cursor"
                style={{ width: 192, height: 50 }}
                onClick={this.checkPremiumAccount}
              >
                Create Job
              </button>
            </Col>
          </Row>
          <Row
            className={`s-search-bar-updated ${styles.searchRow}`}
            justify="space-between"
          >
            <Col xs={24} sm={13}>
              <Row>
                <Col xs={24} sm={13} className={styles.searchRowInner}>
                  <Input
                    style={{ height: 40, width: '100%' }}
                    className={`company-joblist-input jobListingcls-1 ${styles.searchInput}`}
                    prefix={<SearchOutlined />}
                    type="text"
                    placeholder="Search for keywords"
                    value={this.state.searchingValue}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col xs={12} sm={0}>
                  <Col className={styles.jobElement}>
                    {jobPosted > 1
                      ? `${jobPosted} JOBS POSTED`
                      : `${jobPosted} JOB POSTED`}
                  </Col>
                  <Col className={styles.activeElement}>
                    {activeJobCount > 1
                      ? `${activeJobCount} ACTIVE JOBS`
                      : `${activeJobCount} ACTIVE JOB`}
                  </Col>
                </Col>
                <Col xs={12} sm={10} className={styles.sortRowInner}>
                  <Select
                    placeholder="Sort"
                    style={{ width: '80%', float: 'right' }}
                    className="s-search-select"
                    onChange={this.handleSectorSort}
                  >
                    <Option value="title">Job Title</Option>
                    <Option value="-isActive">Active</Option>
                    <Option value="create">Published Date</Option>
                    {/* <Option value="isActive">Inactive</Option> */}
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col xs={0} sm={11}>
              <Row gutter={10} justify="end">
                <Col lg={12} sm={8}>
                  <div
                    style={{
                      backgroundColor: '#ee5050',
                      width: '100%',
                      borderRadius: 6,
                      color: 'white',
                      height: '100%',
                      textAlign: 'center',
                      padding: '10px 25px',
                      fontFamily: 'Gilroy Bold',
                      fontSize: 14,
                    }}
                  >
                    {jobPosted > 1
                      ? `${jobPosted} JOBS POSTED`
                      : `${jobPosted} JOB POSTED`}
                  </div>
                </Col>
                <Col lg={12} sm={8}>
                  <div
                    style={{
                      backgroundColor: '#ee5050',
                      width: '100%',
                      borderRadius: 6,
                      color: 'white',
                      height: '100%',
                      textAlign: 'center',
                      padding: '10px 25px',
                      fontFamily: 'Gilroy Bold',
                      fontSize: 14,
                    }}
                  >
                    {activeJobCount > 1
                      ? `${activeJobCount} ACTIVE JOBS`
                      : `${activeJobCount} ACTIVE JOB`}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <JobCards
            handlePromote={this.handlePromoteModal}
            handleDelete={this.handleDeleteModal}
            searchingValue={this.state.searchingValue}
            editService={(e, obj) => this.renderAddService(e, obj)}
            setzero={this.setzero}
            sortingValue={this.state.sort}
          />
          <Col xs={24} sm={0} className={styles.buttonCol}>
            <button
              type="button"
              className="btn btn-dark bold-family btn-save-font cursor"
              style={{ width: '80%', height: '60' }}
              onClick={this.checkPremiumAccount}
            >
              Create Job
            </button>
          </Col>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myjobData: state.jobManagementReducer.myjobData,
    isloading: state.jobManagementReducer.isloading,
  };
};
export default connect(mapStateToProps)(CompanyJobList);
