import { LeftOutlined } from '@ant-design/icons';
import React from 'react';
import AllTabs from '../layout/tabsComponent';
import SponsoredJobS from './sponsoredJobs';
import SponsoredProducts from './sponsoredProducts';
import SponsoredServices from './sponsoredServices';
import SponsoredSubcontractors from './sponsoredSubcontractors';
import styles from './sponsoredProducts.module.css';
import { Col, Row } from 'antd';
const SponsoredList = (props) => {
  const tabs = [
    {
      tabname: 'PRODUCTS',
      comp: <SponsoredProducts />,
    },
    {
      tabname: 'SERVICES',
      comp: <SponsoredServices />,
    },
    {
      tabname: 'JOBS',
      comp: <SponsoredJobS />,
    },
    {
      tabname: 'SUBCONTRACTORS',
      comp: <SponsoredSubcontractors />,
    },
  ];
  return (
    <div>
      {/*============== for small screen header ================*/}
      <Row>
        <Col xs={24} md={0} className={styles.header}>
          <Row>
            <Col span={8}>
              <div
                onClick={() => {
                  props.history.goBack();
                }}
              >
                <LeftOutlined className="back-icon" />
                <span className="back-btn">Go back</span>
              </div>
            </Col>
            <Col className={styles.fontTextBig} span={10}>
              Sponsored List
            </Col>
          </Row>
        </Col>
      </Row>

      <div
        className={styles.displayNone}
        onClick={() => {
          props.history.goBack();
        }}
      >
        <LeftOutlined className="back-icon" />
        <span className="back-btn">Go back</span>
      </div>
      <h3
        style={{ fontWeight: '700', marginTop: '30px' }}
        className={styles.displayNone}
      >
        Sponsored List
      </h3>
      <AllTabs company_tabs={tabs} class={'company_profile_main_tabs'} />
    </div>
  );
};

export default SponsoredList;
