import React, { Component } from 'react';
import { Row, Col, Spin } from 'antd';
import APIManager from '../../../APIManager';
import { connect } from 'react-redux';
import { ImageUrl } from '../../../Shared/imageUrlPath';
import { CURRENCY } from '../../constants/currency';
import { withRouter } from 'react-router-dom';
import user from '../../asset/user.svg';
import './applied_candidate.css';
class JobCards extends Component {
  state = {
    status: '',
  };
  componentDidMount() {
    //   const candidateId = this.props.id;
    const { id } = this.props.match.params;
    if (this.props.status !== null && this.props.status !== undefined) {
      this.setState({
        status: this.props.status,
      });
    }
    if(this.props.match.path==="/Applied-candidate-info/:id")
    APIManager.resumeGetOneAppliedJob(id);
    else
    APIManager.interestedCandidate(id);
  }
  render() {
    console.log("hello",this.props);
    const candidateName = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_detail?.firstName+" "+this?.props?.appliedJobData?.data?.candidateData?.can_detail?.lastName
      : '';
    const candidatePosition = this?.props?.appliedJobData
      ? this.props?.appliedJobData?.data?.candidateData?.can_detail?.occupation
      : '';
    const candidateExperience = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.experience?.years
      : '0';
    const candidateSalary = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_salary?.lastSalary
      : '';
    const candidateLastSalaryFormat = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_salary?.recieveFormat
      : '';
    const location = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_contact
        ? `${this?.props?.appliedJobData?.data?.candidateData?.can_contact?.city}${
            this?.props?.appliedJobData?.data?.candidateData?.can_contact?.state
              ? ', ' +
                this?.props?.appliedJobData?.data?.candidateData?.can_contact?.state
              : ''
          }${
            this?.props?.appliedJobData?.data?.candidateData?.can_contact?.country
              ? ', ' +
                this?.props?.appliedJobData?.data?.candidateData?.can_contact?.country
              : ''
          } `
        : 'No Location'
      : '';

    const street = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_contact?.addressOne
      : '';

    const street2 = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_contact?.addressTwo
      : '';

    const zipCode = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_contact?.zipCode
      : '';

    const Availibility = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_detail?.availability
      : '';
    const profilePhoto = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_detail?.profile
      : '';
    const phoneNumber = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_contact?.phoneNumber
      : '';
    const landLine = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_contact?.landlineNumber
      : '';
    const email = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_detail?.email
      : '';
    const id = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?._id
      : '';
    const social = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_social
      : '';
    // const resume = '';
    const resume = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidate?.resume
      : '';
    const loading = this?.props?.isloading;
  
    return (
      <div
        className={'job-card'}
        style={{ borderRadius: 4,cursor: 'revert'  }}
        // style={{ cursor: 'revert' }}
      >
        <div className={'d-flex justify-content-center pt-2'}>
          {loading && <Spin />}
        </div>
        <Row className="visit_info_card_1">
          <Col span={3} style={{ height: 120 }}>
            <div className="visit_info_card_2">
              <img
                src={profilePhoto ? ImageUrl.imageUrlPath + profilePhoto : user}
                style={{ objectFit: 'cover', height: '100px' }}
              />
            </div>
          </Col>
          <Col span={16}>
            <Row className="w-100">
              <Col span={20} style={{ height: 40 }}>
                <p
                  className="visit_info_card_3"
                  style={{ fontSize: '1.8rem', textTransform: 'capitalize' }}
                >
                  {' '}
                  {candidateName?.charAt(0).toUpperCase() +
                    candidateName?.slice(1)}
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{ height: 40 }}
                className="input-label-field-custom-type-1"
              >
                <p className="visit_info_card_4" style={{ fontSize: '1.2rem' }}>
                  {' '}
                  {candidatePosition?.charAt(0).toUpperCase(0) +
                    candidatePosition?.slice(1)}
                </p>
              </Col>
            </Row>
            <Row style={{ justifyContent: 'space-between' }}>
              {location ? (
                <Col span={9}>
                  <div className="visit_info_card_5">
                    <img src={process.env.PUBLIC_URL + '/location-red.png'} />
                    {console.log(process.env.PUBLIC_URL + '/location-red.png',"i am super")}
                    <p>
                      {street ? street : street2 ? street2 : ''},
                      {zipCode ? ` ${zipCode}` : ''}
                    </p>
                  </div>
                </Col>
              ) : (
                <Col span={9}></Col>
              )}

              <Col span={6}>
                <div className="visit_info_card_5">
                  <p>
                    <ul style={{ listStyleType: 'none' }}>
                      {phoneNumber && (
                        <li>
                          <i className="fa fa-mobile" aria-hidden="true"></i>
                          &nbsp;&nbsp;{phoneNumber}
                        </li>
                      )}
                      {landLine && (
                        <li>
                          <i className="fa fa-phone" aria-hidden="true"></i>
                          &nbsp;&nbsp;{landLine}
                        </li>
                      )}
                    </ul>
                  </p>
                </div>
              </Col>
              <Col span={8}>
                <div className="visit_info_card_5">
                  {email && (
                    <p>
                      <i className="fa fa-paper-plane" aria-hidden="true"></i>
                      &nbsp;&nbsp;{email}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          {/* <Col span={2}></Col> */}
          <Col span={4}>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Col span={18} className="padding-bottom-apply-job">
                <ul className="company-main-ul-1">
                  {social &&
                    social?.map((val, index) => (
                      <>
                        {val?.name === 'Facebook' ||
                        (val?.name === 'facebook' && val?.link) ? (
                          <li
                            style={{ paddingRight: 15 }}
                            onClick={() => window.open(val?.link, '_blank')}
                          >
                            <img
                              src={process.env.PUBLIC_URL + '/facebook.png'}
                              style={{
                                fontSize: '30px',
                                marginRight: '17px',
                                cursor: 'pointer',
                              }}
                            />
                          </li>
                        ) : null}
                        {(val?.name === 'Linkedin' ||
                          val?.name === 'linkedin' ||
                          val?.name === 'LinkedIn') &&
                        val?.link ? (
                          <li
                            style={{ paddingRight: 15 }}
                            onClick={() => window.open(val?.link, '_blank')}
                          >
                            <img
                              src={process.env.PUBLIC_URL + '/linkedin.png'}
                              style={{
                                fontSize: '30px',
                                marginRight: '17px',
                                cursor: 'pointer',
                              }}
                            />
                          </li>
                        ) : null}
                        {val?.name === 'Twitter' ||
                        (val?.name === 'twitter' && val?.link) ? (
                          <li
                            style={{ paddingRight: 15 }}
                            onClick={() => window.open(val?.link, '_blank')}
                          >
                            <img
                              src={process.env.PUBLIC_URL + '/twitter.png'}
                              style={{
                                fontSize: '30px',
                                marginRight: '17px',
                                cursor: 'pointer',
                              }}
                            />
                          </li>
                        ) : null}
                      </>
                    ))}
                </ul>
              </Col>
              <Col span={22} style={{ marginTop: '10%' }}>
                <div style={{ textAlign: 'center' }}>
                  <p
                    className="job-detail-link-company-page"
                    onClick={() => {
                      window.open(
                        `${ImageUrl?.imageUrlPath}${resume}`,
                        '_blank'
                      );
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <img src={process.env.PUBLIC_URL + '/link.svg'} />
                    &nbsp;&nbsp;
                    <span>View Resume PDF</span>
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '5px' }}>
          <Col lg={12} sm={12} md={12} style={{ height: 60 }}>
            <ul className="visit_info_card_6">
              <li className="visit_info_card_7">
                <Row style={{ height: 25 }}>
                  <Col span={24}>
                    <p>
                      {Availibility?.charAt(0).toUpperCase() +
                        Availibility?.slice(1)}
                    </p>
                  </Col>
                </Row>
                <Row style={{ height: 20, fontSize: '0.6rem' }}>
                  <Col span={24}>
                    <p className="input-label-field-custom-type-1">
                      Availability
                    </p>
                  </Col>
                </Row>
              </li>
              <li className="visit_info_card_7">
                <Row style={{ paddingLeft: 15, height: 25 }}>
                  <Col span={24}>
                    <p>{candidateExperience} years</p>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: 15, height: 20 }}>
                  <Col span={24}>
                    <p className="input-label-field-custom-type-1">
                      Experience
                    </p>
                  </Col>
                </Row>
              </li>
              <li className="visit_info_card_8">
                <Row style={{ paddingLeft: 30, height: 25 }}>
                  <Col span={24}>
                    <p>
                      {' '}
                      {CURRENCY?.filter(
                        (values) => values?.name === candidateSalary?.currency
                      ).length > 0 &&
                        CURRENCY?.filter(
                          (values) => values?.name === candidateSalary?.currency
                        )[0]?.symbol}
                      &nbsp;&nbsp;
                      {candidateSalary?.amount}
                      &nbsp;&nbsp;
                      {candidateLastSalaryFormat}
                    </p>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: 30, height: 20 }}>
                  <Col span={24}>
                    <p className={'input-label-field-custom-type-1'}>Salary</p>
                  </Col>
                </Row>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appliedJobData: state.jobManagementReducer.appliedJobData,
    isloading: state.jobManagementReducer.isloading,
  };
};
export default withRouter(connect(mapStateToProps)(JobCards));
