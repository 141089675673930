import React, { useEffect } from 'react';
import Header from '../../header/header';
import { GoogleApiWrapper } from 'google-maps-react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Button } from 'antd';
import { useSelector } from 'react-redux';
import styles from './index.module.css';
import { useState } from 'react';
import CustomCompanyMap from '../../app_status/company_map';
import HeaderLocation from '../../headerLocation/headerLocation';
import SelectSector from './searchResourceSelector';
import SelectCategory from './selectSkillCategory';
import SearchResourceCards from './SearchResourceCards';
import Footer from '../../footer/footer';
import CompanyMenuDropDown from '../CompanyMenuDropDown';

const SearchResources = (props) => {
  let ls = require('local-storage');
  const [searchingValue, setSearchingValue] = useState('');
  const [firstTimeRender, setFirstTimeRender] = useState(true);
  const [options, setOptions] = useState([]);
  const [current_location, setCurrentLocation] = useState('');
  const [map_location, setMapLocation] = useState('');
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [resourceData, setResourceData] = useState({});
  const [skills, setSkills] = useState([]);
  const [searchLocation,setSearchLocation] = useState('false');
  const Data = useSelector((state) => state);

  const autoCompleteRef = React.createRef();
  useEffect(async () => {
    if (Data?.jobManagementReducer?.resourceData) {
      setResourceData(Data?.jobManagementReducer?.resourceData);
    }
  }, [Data]);
  useEffect(async () => {
    await getLocationName();
  }, []);
  const handleChange = (value) => {
    setSearchingValue(value);
    setFirstTimeRender(false);
  };
  const takecurrentLocation = (value) => {
    setCurrentLocation(value);
    setMapLocation('');
    setSearchLocation('true');
    setFirstTimeRender(false);
  };
  const takeLatLong = (lat, long) => {
    setLat(lat);
    setLong(long);
    setFirstTimeRender(false);
  };
  const handleCategoryChange = (value) => {
    setSkills(value);
    setFirstTimeRender(false);
  };
  const handleFilter = async () => {
    autoCompleteRef.current.resetFields();
    setSearchingValue('');
    setCurrentLocation('');
    setOptions([]);
    setFirstTimeRender(true);
    window.history.replaceState('/SearchResource', null);
  };
  const handleCurrentLocationChange = (value) => {
    const { latitude, longitude } = value?.contact_info;
    takeLatLong(latitude, longitude);
  };
  const handleMapLocationChange = (value, id) => {
    takeLatLong(value?.latitude, value?.longitude);
    setMapLocation(value?.location);
    openCompanyCardOnMap(id);
  };
  const openCompanyCardOnMap = (id) => {
    setTimeout(() => {
      const mapCard = document.querySelectorAll(`[title="${id}"]`)?.[0];
      if (mapCard) {
        mapCard.click();
      }
    }, 1000);
  };

  let markers = [];
  if (resourceData !== null && resourceData !== undefined) {
    resourceData?.data?.forEach((val) => {
      markers = markers.concat(val);
    });
  }
  const getLocationName = async (lat, long) => {
    const localLat = lat ? lat : ls.get('lat');
    const localLong = long ? long : ls.get('long');
    const { google } = props;
    if (localLat && localLong) {
      var geocoder;
      geocoder = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(localLat, localLong);
      var country = '';
      var state = '';
      var city = '';
      await geocoder.geocode(
        { latLng: latlng },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              var add = results[0].formatted_address;
              var value = add.split(',');
              const count = value.length;
              country = value[count - 1].replace(/[0-9]/g, '').trim(' ');
              state = value[count - 2].replace(/[0-9]/g, '').trim(' ');
              city = value[count - 3].replace(/[0-9]/g, '').trim(' ');
              setLat(localLat);
              setLong(localLong);
              setCurrentLocation(`${city},${state},${country}`);
            } else {
              console.log('address not found');
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        }.bind(this)
      );
    } else {
      setLat('48.8566');
      setLong('2.3522');
      setCurrentLocation('Paris, France');
    }
  };
  

  return (
    <>
      <Header />
      <Row>
        <Col xs={24} md={0}>
          <CompanyMenuDropDown name={'Search Resource'} />
        </Col>
      </Row>
      <Row className={styles.searchBar}>
        <Col md={20} xs={24}>
          <Form ref={autoCompleteRef}>
            <Row gutter={16} align="middle">
              <Col lg={8} md={10} xs={24}>
                <SelectSector
                  FormItem={Form.Item}
                  handleSectorChange={handleChange}
                  value={options}
                  name={'search'}
                />
              </Col>
              <Col lg={6} md={10} xs={24}>
                <Form.Item name="location">
                  <HeaderLocation
                    envclass="landing-icons landing-select-icon2"
                    value={current_location}
                    className={`company-joblist-input jobListingcls-1 padding-in-job-search ${styles.locationBox}`}
                    takecurrentLocation={takecurrentLocation}
                    takeLatLong={takeLatLong}
                  />
                </Form.Item>
              </Col>
              <Col lg={1} md={10} xs={0}></Col>
              <Col lg={6} md={10} xs={24}>
                <Form.Item name="sector">
                  <SelectCategory
                    handleCategoryChange={handleCategoryChange}
                    value={skills}
                    name={'Category'}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col md={4} xs={24} className={styles.filterButtonCol}>
          <Button
            className={styles.filterButton}
            type="text"
            onClick={handleFilter}
          >
            Clear All Filters
          </Button>
        </Col>
      </Row>
      <Row className={styles.mainBodyContainer}>
        <Col md={12} xs={24} className={styles.resourceCards}>
          <SearchResourceCards
            searchingValue={searchingValue}
            current_location={current_location}
            skills={skills}
            firstTimeRender={firstTimeRender}
            searchLocation={searchLocation}
            handleCurrentLocationChange={handleCurrentLocationChange}
            changeMapLocation={handleMapLocationChange}
          />
        </Col>
        <Col md={12} xs={24} className={styles.map}>
          <CustomCompanyMap
            renderfrom="resource"
            markers={markers}
            current_location={current_location}
            map_location={map_location}
            lat={lat}
            long={long}
            jobLocation={current_location}
          />
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default withRouter(
  GoogleApiWrapper({
    apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
  })(SearchResources)
);
