import React, { Component } from 'react';
import './signup.css';
import { Formik } from 'formik';
import { ReloadOutlined } from '@ant-design/icons';
import * as Yup from 'yup'; // used when validating with a pre-built solution
import { message, Input, Button, Tooltip, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Checkbox } from 'antd';
import APIManager from '../../APIManager/index';
import { withRouter } from 'react-router-dom';
import GoogleSearch from '../candidate/googlesearch';
import CurrentMapLocation from '../../Routes/test';

Yup.addMethod(Yup.string, 'customValidator', function () {
  return null;
});

class Companysignup extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
    this.formRef = React.createRef();
    this.handleInput = this.handleInput.bind(this);
    this.checkCaptcha = this.checkCaptcha.bind(this);
    this.state = {
      password1validation: false,
      password2validation: false,
      termsandcondition: false,
      formIsValid: true,
      ExistId: false,
      companyId: '',
      current_location: '',
      lat: '',
      long: '',
      initialData: null,
    };
  }
  componentDidMount() {
    this.setCaptcha();
    if (
      this.props?.location?.state !== null &&
      this.props?.location?.state?.comp_detail
    ) {
      this.setState({
        initialData: this.props?.location?.state?.comp_detail,
      });
      this.formRef?.current?.setFieldValue(
        'comp_name',
        this.props?.location?.state?.comp_detail?.comp_info?.comp_name
      );
      this.formRef?.current?.setFieldValue(
        'zip_code',
        this.props?.location?.state?.comp_detail?.contact_info?.zip_code
      );
      this.formRef?.current?.setFieldValue(
        'city',
        this.props?.location?.state?.comp_detail?.contact_info?.sub_address
      );
      this.formRef?.current?.setFieldValue(
        'company_id',
        this.props?.location?.state?.comp_detail?._id
      );
      this.takecurrentLocation(
        this.props?.location?.state?.comp_detail?.contact_info?.sub_address
      );
      if (
        this.props?.location?.state?.comp_detail?.contact_info?.latitude &&
        this.props?.location?.state?.comp_detail?.contact_info?.longitude
      ) {
        this.takeLatLong(
          this.props?.location?.state?.comp_detail?.contact_info?.latitude,
          this.props?.location?.state?.comp_detail?.contact_info?.longitude
        );
      }
    }
  }
  onSubmit = (e, { setSubmitting }) => {
    if (!this.state.ExistId && this.state.email) {
      setTimeout(() => {
        setSubmitting(false);
        if (this.checkCaptcha(this.state.value)) {
          this.finalSubmit(e);
        }
      }, 500);
    } else {
      message.error('Please enter a valid email ID');
    }
  };
  handleInput(event) {
    // console.log("value",event.target.value);
    this.setState({ value: event.target.value });
  }
  checkCaptcha(event) {
    // console.log(event + ' ' + this.state.captcha);
    if (event !== this.state.captcha) {
      window.alert('Invalid Captcha!');
      return false;
    }
    return true;
  }
  GenerateCaptcha = () => {
    var chr1 = Math.ceil(Math.random() * 10) + '';
    var chr2 = Math.ceil(Math.random() * 10) + '';
    var chr3 = Math.ceil(Math.random() * 10) + '';

    var str = new Array(4).join().replace(/(.|$)/g, function () {
      return ((Math.random() * 36) | 0)
        .toString(36)
        [Math.random() < 0.5 ? 'toString' : 'toUpperCase']();
    });
    var captchaCode = str + chr1 + ' ' + chr2 + ' ' + chr3;

    return this.removeSpaces(captchaCode);
  };
  removeSpaces = (data) => {
    return data.split(' ').join('');
  };
  setCaptcha = () => {
    this.setState({ captcha: this.GenerateCaptcha() });
  };
  customHandleChange = (event) => {
    let searchValue = event.target.value;
    APIManager.checkCompanyId(searchValue).then((response) => {
      if (response && response.data.isSuccess) {
        this.setState({
          ExistId: false,
          email: event.target.value,
        });
      } else {
        this.setState({
          ExistId: true,
        });
      }
    });
  };

   finalSubmit = async (data) => {
    try{
    data['user_type'] = this.props.signup_type;
    data['email'] = this.state.email;
    data['comp_detail'] = { company_name: data['comp_name'] };
    data['latitude'] = this.state.lat;
    data['longitude'] = this.state.long;
    data['sub_address'] = this.state.current_location;
    if (this.props?.location?.state?.comp_detail?._id) {
      data['_id'] = this.props?.location?.state?.comp_detail?._id;
    }
    if (this.state?.current_location?.length > 0) {
      const locationArray = this.state?.current_location?.split(',')?.reverse();
      if (locationArray.length > 0) {
        data['country'] = locationArray[0];
        data['state'] = locationArray.length > 1 ? locationArray[1] : '';
        data['city'] = locationArray.length > 2 ? locationArray[2] : '';
        data['address'] = locationArray.length > 3 ? locationArray[3] : '';
      }
    }
    const response = await APIManager.Signup(data)
    if(response){
      message.success('User added successfully!');
      this.props.history.push('/login');
    }
  } catch (error) {
    message.error(error.response.data.message);
  }
  }

  takecurrentLocation = (value) => {
    this.setState({ current_location: value });
  };
  takeLatLong = (lat, long) => {
    this.setState({
      lat: lat,
      long: long,
    });
  };
  checkToggleFunc = (checkToggle) => {
    this.setState({
      checkToggle: !this.state.checkToggle,
    });
  };
  render() {
    return (
      <Formik
        innerRef={this.formRef}
        initialValues={{
          email: '',
          password: '',
          confirm_password: '',
          address: '',
          city: '',
          zip_code: '',
          comp_name: '',
          termsandcondition: false,
          isFreelancer: false,
          company_id: '',
          first_name: '',
          last_name: '',
          jobTitle: '',
          checkToggle: false,
        }}
        onSubmit={this.onSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string().email(),
          password: Yup.string().required('password is required.'),
          confirm_password: Yup.string()
            .required('confirm password is required.')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
          // city: Yup.string().trim().required('city name is required'),
          zip_code: Yup.string().trim().required('zip code is required'),
          // address: Yup.string().trim().required('address is required'),
          // company_name: Yup.object().required('Company name is required'),
          comp_name: Yup.string().trim().required('Company name is required'),
          first_name: Yup.string().trim().required('First name is required'),
          last_name: Yup.string().trim().required('Last name is required'),
          jobTitle: Yup.string().trim().required('Job title is required'),
          company_id: Yup.string().trim(),
          termsandcondition: Yup.bool().oneOf(
            [true],
            'Accept Terms & Conditions is required'
          ),
        })}
      >
        {(formikProps) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
          } = formikProps;
          return (
            <>
              <form onSubmit={formikProps.handleSubmit}>
                <Row gutter={12}>
                  <Col xs={24} style={{ marginTop: '10px' }}>
                    <label
                      className="you_are_cls"
                      htmlFor="exampleInputCompanyName"
                    >
                      Name Of Company
                    </label>
                    <input
                      type="text"
                      name="comp_name"
                      onChange={handleChange}
                      value={values.comp_name}
                      className="form-control input-font login-form-control"
                      id="exampleInputCompanyName"
                      aria-describedby="nameHelp"
                      placeholder="Enter company name"
                      onBlur={handleBlur}
                    />
                    {touched.comp_name && errors.comp_name && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.comp_name}
                      </div>
                    )}
                  </Col>
                  <Col xs={24} md={12} style={{ marginTop: '10px' }}>
                  <label className="you_are_cls" htmlFor="exampleInputEmail">
                    Email ID
                  </label>
                  <input
                    type="email"
                    name="email"
                    onChange={this.customHandleChange}
                    className="form-control input-font login-form-control"
                    id="exampleInputEmail"
                    aria-describedby="emailHelp"
                    onBlur={handleBlur}
                    placeholder="Enter Email ID"
                  />
                  {touched.email && errors.email && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.email}
                    </div>
                  )}
                   {this.state.ExistId && (
                      <p style={{ color: 'red', fontSize: '0.8rem' }}>
                        This email id already exist.
                      </p>
                    )}
                </Col>
                  <Col xs={24} md={12} style={{ marginTop: '10px' }}>
                    <label className="you_are_cls" htmlFor="exampleInputID">
                      Official Company ID
                    </label>
                    <input
                      type="text"
                      name="company_id"
                      onChange={handleChange}
                      className="form-control input-font login-form-control"
                      id="exampleInputID"
                      aria-describedby="compIdHelp"
                      placeholder="Enter company ID"
                      // onBlur={handleBlur}
                    />
                    {touched.company_id && errors.company_id && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.company_id}
                      </div>
                    )}
                  </Col>
                  <Col xs={24} md={12} style={{ marginTop: '10px' }}>
                    <label className="you_are_cls" htmlFor="exampleInputCity">
                      Address
                    </label>
                    <GoogleSearch
                      className="form-control input-font login-form-control"
                      location={values.city}
                      takecurrentLocation={this.takecurrentLocation}
                      takeLatLong={this.takeLatLong}
                      checkToggleFunc={this.checkToggleFunc}
                    />
                    {touched.city && errors.city && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.city}
                      </div>
                    )}
                  </Col>
                  <Col xs={24} md={12} style={{ marginTop: '10px' }}>
                    <label className="you_are_cls" htmlFor="exampleInputCode">
                      Zip Code
                    </label>
                    <input
                      className="form-control input-font login-form-control"
                      name="zip_code"
                      onChange={handleChange}
                      value={values?.zip_code}
                      id="exampleInputCode"
                      placeholder="Enter zip Code"
                      onBlur={handleBlur}
                    />
                    {touched.zip_code && errors.zip_code && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.zip_code}
                      </div>
                    )}
                  </Col>
                  {this.state.lat &&
                     this.state.long &&
                     this.state.checkToggle && (
                      <Col
                        style={{
                        marginTop: '10px',
                        position: 'relative',
                        width: '98%',
                        height: '300px',
                      }}
                      >
                      <CurrentMapLocation
                        lat={this.state.lat}
                        long={this.state.long}
                        takeLatLong={this.takeLatLong}
                      />
                    </Col>
                  )}
                  <Col
                    xs={24}
                    className="form-group mb-signup-1"
                    style={{ display: 'flex' , marginTop: '10px' }}
                  >
                    <Checkbox
                      name="isFreelancer"
                      checked={values.isFreelancer}
                      onChange={handleChange}
                      value={values.isFreelancer}
                      onBlur={handleBlur}
                    >
                    <label
                      className="you_are_cls"
                      htmlFor="exampleInputFreelancer"
                      style={{ marginTop: '10px' }}
                    >
                      Freelancer
                    </label>
                  </Checkbox>
                </Col>
                <Col xs={24} style={{ marginTop: '10px' }} className="form-group mb-signup-1">
                  <label
                    className="you_are_cls"
                    htmlFor="exampleInputFirstName"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    onChange={handleChange}
                    className="form-control input-font login-form-control"
                    id="exampleInputFirstName"
                    aria-describedby="firstNameHelp"
                    onBlur={handleBlur}
                    placeholder="Enter First Name"
                  />
                  {touched.first_name && errors.first_name && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.first_name}
                    </div>
                  )}
                </Col>
                <Col xs={24} style={{ marginTop: '10px' }} className="form-group mb-signup-1">
                  <label className="you_are_cls" htmlFor="exampleInputLastName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    onChange={handleChange}
                    className="form-control input-font login-form-control"
                    id="exampleInputLastName"
                    aria-describedby="lastNameHelp"
                    onBlur={handleBlur}
                    placeholder="Enter Last Name"
                  />
                  {touched.last_name && errors.last_name && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.last_name}
                    </div>
                  )}
                </Col>
                <Col xs={24} style={{ marginTop: '10px' }} className="form-group mb-signup-1">
                  <label className="you_are_cls" htmlFor="exampleInputJobTitle">
                    Job Title
                  </label>
                  <input
                    type="text"
                    name="jobTitle"
                    onChange={handleChange}
                    className="form-control input-font login-form-control"
                    id="exampleInputJobTitle"
                    aria-describedby="jobTitleHelp"
                    onBlur={handleBlur}
                    placeholder="Enter Job Title"
                  />
                  {touched.jobTitle && errors.jobTitle && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.jobTitle}
                    </div>
                  )}
                </Col>
                <Col xs={24} style={{ marginTop: '10px' }} className="form-group mb-signup-1">
                  <label
                    className="you_are_cls"
                    htmlFor="exampleInputPeoplePassword"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    autocomplete="new-password"
                    className="form-control input-font login-form-control"
                    onChange={handleChange}
                    id="exampleInputPeoplePassword"
                    aria-describedby="password1Help"
                    placeholder="Enter your password"
                    minLength="8"
                    onBlur={handleBlur}
                  />
                  {touched.password && errors.password && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.password}
                    </div>
                  )}
                  {/*<span className="help-block"></span>*/}
                </Col>
                <Col xs={24} style={{ marginTop: '10px' }} className="form-group mb-signup-1">
                  <label className="you_are_cls" htmlFor="confirmPassword">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirm_password"
                    className="form-control input-font login-form-control"
                    id="confirmPassword"
                    placeholder="Re-Enter your password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.confirm_password && errors.confirm_password && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.confirm_password}
                    </div>
                  )}
                </Col>
                <Col xs={24}  style={{ marginTop: '10px' }}>
                  <Col xs={24} style={{ display: 'flex', marginTop: '61px' }}>
                    <div
                      style={{
                        width: 250,
                        backgroundColor: 'rgb(241, 243, 246)',
                        borderRadius: '11px',
                        border: 'ridge',
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <i
                        style={{
                          fontSize: 'large',
                          fontFamily: 'cursive',
                          userSelect: 'none',
                          padding: '6px',
                        }}
                      >
                        {/* {console.log("ccccc",this.state.captcha)} */}
                        {this.state.captcha}
                      </i>
                    </div>
                    <div style={{ marginLeft: '2vh' }}>
                      <Tooltip title="Reload">
                        <div
                          style={{
                            border: '1px solid black',
                            borderRadius: '5px',
                            marginTop: '4px',
                          }}
                        >
                          <Button
                            className="button1"
                            // type="primary"
                            shape="circle"
                            style={{ border: 'white' }}
                            onClick={() => this.setCaptcha()}
                          >
                            <ReloadOutlined />
                          </Button>
                        </div>
                      </Tooltip>
                    </div>
                  </Col>

                  <Input
                    placeholder="Enter Captcha"
                    onChange={this.handleInput}
                    style={{
                      width: '250px',
                      height: '6vh',
                      borderRadius: '11px',
                      marginTop: '2vh',
                      marginBottom: '25px',
                    }}
                  />
                </Col>
                <Col 
                  xs={24}
                  className="form-check mb-signup-1"
                  style={{ paddingLeft: '0', marginTop: '10px' }}
                >
                  <Checkbox
                    name="termsandcondition"
                    checked={values.termsandcondition}
                    onChange={handleChange}
                    value={values.termsandcondition}
                    onBlur={handleBlur}
                  >
                    <label
                      className="input_label_profile form-check-label-2"
                      htmlFor="exampleCheck1"
                    >
                      &nbsp;&nbsp;I have read and accepted the terms and
                      conditions published by sendbiz.com. &nbsp;&nbsp;
                      <small
                        className="cursor terms-link"
                        onClick={() =>
                          this.props.history.push('./terms-and-conditions')
                        }
                      >
                        Read all
                      </small>
                    </label>
                  </Checkbox>
                  {touched.termsandcondition && errors.termsandcondition && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.termsandcondition}
                    </div>
                  )}
                </Col>
                <Col xs={24} className="cent_button">
                  <button
                    type="submit"
                    className="btn btn-dark login-btn login-button-font"
                  >
                    SIGNUP
                  </button>
                </Col>
                </Row>
              </form>
            </>
          );
        }}
      </Formik>
    );
  }
}
const mapStateToProps = ({ AuthReducer: { user } }) => ({ user });
export default withRouter(connect(mapStateToProps)(Companysignup));
