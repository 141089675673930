import React, { useEffect, useState } from 'react';
import { DeleteFilled } from '@ant-design/icons';
import './style.css';
import styles from './savedCard.module.css';
import APIManager from '../../APIManager/index';
import { Col, Container, Row } from 'react-bootstrap';
import Footer from '../footer/footer.js';
import { Popconfirm, message, Select, Radio } from 'antd';
import NewCardModal from './index';
const Option = Select.Option;
const SavedCards = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentId, setPaymentId] = useState('');
  const [newCardModal, showNewCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState('');
  const [isChecked, setisChecked] = useState('');
  const [test, setTest] = useState('');
  const [value, setValue] = React.useState(1);
  const [isNewCardAdded, setNewCardAdded] = useState(false);
  useEffect(() => {
    APIManager.GETALLCard().then((response) => {
      if (response.data.isSuccess) {
        setPaymentMethods(response.data.data);
      }
    });
  }, []);

  const handleSelectCards = (value) => {
    setSelectedCard(value);
  };

  //getallcards
  const getMyCards = () => {
    APIManager.GETALLCard().then((response) => {
      if (response.data.isSuccess) {
        setPaymentMethods(response.data.data);
      }
    });
  };

  //to delete credit carc
  const DeleteCard = (id) => {
    APIManager.DeleteOneCard(id).then((resp) => {
      if (resp.data.isSuccess) {
        message.info('Card is deleted.');
        getMyCards();
      }
    });
  };
  const handleSelected = (e, id) => {
    setTest(e.target.value);
    setDefaultCard(id);
  };
  const isCardAdded = () => {
    getMyCards();
    showNewCard(false);
  };

  //To set default card
  const setDefaultCard = (id) => {
    APIManager.setAsDefaultCard(id).then((resp) => {
      if (resp.data.isSuccess) {
        getMyCards();
      }
    });
  };

  return (
    <>
      {newCardModal && (
        <NewCardModal
          show={newCardModal}
          isCardAdded={isCardAdded}
          onHide={() => showNewCard(false)}
        />
      )}
      <div className={'mt-5'}>
        {/***************  add card section *******************/}
        <div>
          <div className={styles.savedCardRow}>
            <p className={styles.savedCradsHeading}>Saved Card</p>
            <div>
              <button
                onClick={() => showNewCard(true)}
                className={`new-card-btn ${styles.savedCradsButton}`}
              >
                Add New Card
              </button>
            </div>
          </div>
        </div>
        {/***************  all  cards *******************/}
        <div className={styles.allCardParents}>
          <Row>
            {paymentMethods &&
              paymentMethods?.map((data, id) => {
                return (
                  <Col sm={5} xs={24} className={'mt-5'}>
                    <div
                      className={`credit-card-container ${styles.creditCardContainer}`}
                    >
                      <div className={'card-brand-container'}>
                        {data.brand.toUpperCase()}
                      </div>
                      {/* All  card info */}
                      <div className={'w-100 m-4'}>
                        {/* row 1 */}
                        <div className={'dlt-container'}>
                          <div>
                            <div className={'card-num-label'}>CARD NUMBER</div>
                            <div
                              className={`card-num ${styles.cardText}`}
                            >{`**** **** **** ${data.card}`}</div>
                          </div>
                          <div
                            onClick={() => setPaymentId(data.id)}
                            className={'delete-btn-container ml-5'}
                          >
                            <Popconfirm
                              title="Are you sure to delete this card?"
                              onConfirm={() => DeleteCard(data.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <DeleteFilled />
                            </Popconfirm>
                          </div>
                        </div>
                        {/* row 2 */}
                        <div
                          className={`d-flex flex-direction-row ${styles.row2}`}
                        >
                          <div>
                            <div className={'card-num-label mr-5 mt-4'}>
                              VALID THRU
                            </div>
                            <div
                              className={`card-num ${styles.cardText}`}
                            >{`${data.expMonth}/${data.expYear}`}</div>
                          </div>
                          <div className={'ml-4 mt-4'}>
                            <div className={'card-num-label'}>CVV</div>
                            <div className={`card-num ${styles.cardText}`}>
                              ***
                            </div>
                          </div>
                        </div>
                        {/* row 3 */}
                        <div
                          className={`d-flex flex-direction-row ${styles.row3}`}
                        >
                          <div>
                            <div className={'card-num-label mr-5 mt-4'}>
                              Name
                            </div>
                            <div className={`card-num ${styles.cardText}`}>
                              {data?.name ? data?.name?.toUpperCase() : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* save card section */}
                    <div className={'d-flex mt-4'}>
                      <div className={'mt-1'}>
                        <input
                          type="radio"
                          name="Radio"
                          value={id}
                          checked={data.defaultCard ? true : false}
                          className={'save-radio-btn'}
                          // checked={test === id}
                          onChange={(e) => handleSelected(e, data.id)}
                        />
                      </div>
                      <div className={'ml-2 mt-1'}>
                        Save this card for future purpose.
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
    </>
  );
};
export default SavedCards;
