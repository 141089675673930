import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';

const socialTags = [
  'Twitter',
  'twitter',
  'Linkedin',
  'linkedin',
  'linkedIn',
  'LinkedIn',
  'Facebook',
  'facebook',
];

class CompanyMedia extends Component {
  state = {
    callAddMedia: false,
  };
  handleClick = () => {
    this.setState({ callAddMedia: true });
  };
  closeModal = () => {
    this.setState({ callAddMedia: false });
  };
  render() {
    const jobCategory = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_detail
          ?.jobCategory
      : '';
    const skills = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_detail?.skills
      : '';
    const bio = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_detail
          ?.description
      : '';
    const hobbies = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_detail?.hobbies
      : '';
    const desiredLocation = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_detail
          ?.desiredLocation
      : '';
    const canSocial = this?.props?.appliedJobData
      ? this?.props?.appliedJobData?.data?.candidateData?.can_social
      : '';
    // const name = this?.props?.appliedJobData
    // ? this?.props?.appliedJobData?.data?.candidateData?.

    return (
      <div>
        <div style={{ backgroundColor: 'white' }}>
          <Row className="p-info-head" style={{ marginTop: '0' }}>
            <Col span={24} style={{ paddingTop: 35 }}>
              <p className="personal-info-1">Job Category</p>
            </Col>
          </Row>
          <Row>
            {jobCategory &&
              jobCategory?.map((data) => (
                <Col span={7} style={{ marginBottom: 12 }}>
                  <div className="personal-info-2">{data?.title}</div>
                </Col>
              ))}
            {console.log('title', jobCategory)}
          </Row>
          <Row className="p-info-head">
            <Col span={24}>
              <p className="personal-info-3 ">Skills</p>
            </Col>
          </Row>
          <Row>
            {skills &&
              skills?.map((data) => (
                <Col
                  style={{ marginBottom: 12 }}
                  span={'Technology of ins'.length < 10 ? 5 : 8}
                >
                  <div className="personal-info-2 personal-info-skill">
                    {data}
                  </div>
                </Col>
              ))}
          </Row>
          <Row>
            <Col span={24} className="p-info-head">
              <p className="personal-info-3">Bio</p>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <p className="personal-info-4">
                {bio?.charAt(0).toUpperCase() + bio?.slice(1)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="p-info-head">
              <p className="personal-info-3">Hobbies</p>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <p className="personal-info-4 personal-info-skill">{hobbies}</p>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="p-info-head">
              <p className="personal-info-3">Desired Locations</p>
            </Col>
          </Row>
          <Row>
            {desiredLocation &&
              desiredLocation?.map((data) => (
                <Col
                  span={'Gurgaon'.length < 10 ? 5 : 8}
                  style={{ marginBottom: 12 }}
                >
                  <div className="personal-info-2 personal-info-skill">
                    {data}
                  </div>
                </Col>
              ))}
          </Row>
          <Row className="p-info-head" style={{ marginBottom: '0' }}>
            <Col span={24}>
              <p className="personal-info-3">Social</p>
            </Col>
          </Row>
          {console.log('social', canSocial)}
          {canSocial &&
            canSocial?.map((data) => (
              <>
                {data.link ? (
                  <div>
                    <Row>
                      <Col span={24}>
                        {!socialTags.includes(data?.name) ? (
                          <p className="personal-info-5">{data?.name}</p>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {!socialTags.includes(data?.name) ? (
                          <a
                            href={data.link}
                            rel="noreferrer"
                            target="_blank"
                            className="personal-info-6"
                          >
                            {data?.link}
                          </a>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ''
                )}
              </>
            ))}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    appliedJobData: state.jobManagementReducer.appliedJobData,
    isloading: state.jobManagementReducer.isloading,
  };
};
export default connect(mapStateToProps)(CompanyMedia);
