import React, { Component } from 'react';
import ResumeModal from './resumeModal.js';
import { connect } from 'react-redux';
import { message, Spin, Row, Col, Input, Select } from 'antd';
import APIManager from '../../APIManager';
import moment from 'moment';
import userIcon from '../asset/rectangle@3x.jpg';
import { SearchOutlined } from '@ant-design/icons';
import NoDataFound from '../../PageNotFound/NoData.js';
import MenuDropDown from './MenuDropDown.jsx';
import styles from './resume.module.css';
const { Option } = Select;

class Resume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: '',
      modalShow: false,
      uploadedImage: userIcon,
      name: '',
      description: '',
      resumeId: '',
      resumeName: '',
      searchingValue: '',
      resumes: [],
      resumesCopy: [],
    };
  }

  componentDidMount() {
    APIManager.companyInfo().then((res) => {
      this.setState({
        resumes: res.data.data.data.resumes,
        resumesCopy: res.data.data.data.resumes,
      });
    });
  }

  deleteResume = (id) => {
    APIManager.deleteCandidateResume(id)
      .then((response) => {
        if (response.data.status === 'success') {
          message.info('Resume deleted successfully');
          window.location.reload();
        }
      })
      .catch((err) => message.error('Something went wrong.'));
  };

  handleClose = () => {
    this.setState({
      modalShow: false,
      name: '',
      description: '',
      resumeId: '',
      resumeName: '',
    });
  };
  reloadPage = () => {
    APIManager.companyInfo();
  };
  handleUpdate = (name, description, id, resumeName) => {
    this.setState({
      modalShow: true,
      name: name,
      description: description,
      resumeId: id,
      resumeName: resumeName,
    });
  };
  handleShow = (e) => {
    this.setState({ showMessage: true });
  };
  handleChange = (event) => {
    const value = event.target.value.toLowerCase();
    const newResume = this.state.resumes.filter((item) => {
      if (item.title.toLowerCase().includes(value)) {
        return item;
      }
    });
    this.setState({ resumesCopy: newResume, searchingValue: value });
  };
  handleSectorSort = (e) => {
    if (this.state.searchingValue) {
      if (e === 'A-Z') {
        const newArray = this.state.resumesCopy.sort(function (a, b) {
          var textA = a.title.trim().toLowerCase();
          var textB = b.title.trim().toLowerCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        this.setState({ resumesCopy: newArray });
      } else {
        const newArray = this.state.resumesCopy.sort(function (a, b) {
          var textA = a.title.trim().toLowerCase();
          var textB = b.title.trim().toLowerCase();
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });

        this.setState({ resumesCopy: newArray });
      }
    } else {
      if (e === 'A-Z') {
        const newArray = this.state.resumes.sort(function (a, b) {
          var textA = a.title.trim().toLowerCase();
          var textB = b.title.trim().toLowerCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        this.setState({ resumes: newArray });
      } else {
        const newArray = this.state.resumes.sort(function (a, b) {
          var textA = a.title.trim().toLowerCase();
          var textB = b.title.trim().toLowerCase();
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });

        this.setState({ resumes: newArray });
      }
    }
  };
  render() {
    const { showMessage } = this.state;
    let loader = this.props.isloading;
    let uploadedImage = this.state.uploadedImage;
    let resumes = [];
    if (!loader) {
      if (this.props.data.data.data.can_detail.profile !== '') {
        let url = 'https://sendbizbucket.s3.eu-west-3.amazonaws.com/';
        uploadedImage = url + this.props.data.data.data.can_detail.profile;
      }
      if (this.state.searchingValue) {
        resumes = this.state.resumesCopy;
      } else resumes = this.props.data.data.data.resumes;
    }
    // console.log('State', this.state);

    return (
      <>
        {/* dropdown menu */}
        <Row className={styles.dropDownMenu}>
          <Col span={24}>
            <MenuDropDown name={'Resume(s)'} />
          </Col>
        </Row>
        <div className={styles.resumeParent}>
          {this.state.modalShow ? (
            <ResumeModal
              show={this.state.modalShow}
              name={this.state.name}
              description={this.state.description}
              resumeId={this.state.resumeId}
              resumeName={this.state.resumeName}
              onHide={this.handleClose}
              reloadPage={this.reloadPage}
            />
          ) : null}

          <div>
            <Row className={styles.headerRow}>
              <Col sm={12} xs={16}>
                <p className={`candidate_heading ${styles.resumeHeading}`}>
                  {resumes.length > 1
                    ? `${resumes.length} Resume(s)`
                    : 'Resume'}
                </p>
              </Col>
              {/* View public profile for small screen */}
              <Col
                sm={0}
                xs={8}
                className={`justify-content-end align-items-center ${styles.viewProfileSmallScreen}`}
              >
                <label
                  className="custom-file-label-2"
                  onClick={() =>
                    this.props.history.push({
                      pathname: '/visit-profile',
                    })
                  }
                  style={{ cursor: 'pointer' }}
                >
                  View public profile
                </label>
              </Col>
              <Col sm={12} xs={0} className="text-right">
                <button
                  type="button"
                  onClick={() => this.setState({ modalShow: true })}
                  className="btn btn-dark bold-family btn-save-font cursor"
                >
                  Add New
                </button>
              </Col>
            </Row>
            <Row className="s-search-bar-updated" justify="space-between">
              <Col sm={14} xs={24}>
                <Row gutter={12}>
                  <Col sm={14} xs={24}>
                    <Input
                      style={{ height: 40, width: '100%' }}
                      className={'company-joblist-input jobListingcls-1'}
                      prefix={<SearchOutlined />}
                      type="text"
                      placeholder="Search for keywords"
                      value={this.state.searchingValue}
                      onChange={this.handleChange}
                    />
                  </Col>
                  <Col
                    span={10}
                    lg={6}
                    sm={10}
                    xs={8}
                    gutter={2}
                    className={styles.sortingBox}
                  >
                    <Select
                      placeholder="Sort"
                      style={{ float: 'right' }}
                      className="s-search-select"
                      onChange={this.handleSectorSort}
                    >
                      <Option value="A-Z">A-Z</Option>
                      <Option value="Z-A">Z-A</Option>
                    </Select>
                  </Col>
                </Row>
              </Col>
              {/* View public profile for big screen */}
              <Col
                sm={10}
                xs={0}
                className={`justify-content-end align-items-center ${styles.viewProfileBigScreen} `}
              >
                <label
                  className="custom-file-label-2"
                  onClick={() =>
                    this.props.history.push({
                      pathname: '/visit-profile',
                    })
                  }
                  style={{ cursor: 'pointer' }}
                >
                  View public profile
                </label>
              </Col>
            </Row>
            {loader ? (
              <Spin className="d-flex justify-content-center pt-3" />
            ) : (
              <>
                {resumes.length === 0 ? (
                  <>
                    <NoDataFound></NoDataFound>
                  </>
                ) : (
                  <>
                    {/*================ all resume cards ==================*/}
                    {resumes?.map((val, index) => (
                      <Row
                        className={`mb-3 bg-white custom-row services-card ${styles.cardParent}`}
                        key={index}
                      >
                        <Col
                          sm={4}
                          xs={8}
                          className="resume-photo-padding candidate-resume-card-1"
                        >
                          <img
                            src={uploadedImage}
                            className={`resume_profile_image resume-photo-radius ${styles.resumeImage}`}
                            alt="profile image"
                            style={{ objectFit: 'cover' }}
                          />
                        </Col>
                        <Col
                          sm={13}
                          xs={15}
                          className="candidate-resume-card-2"
                        >
                          <h3
                            className={`resume-heading ${styles.resumeHeading}`}
                            style={{ textTransform: 'capitalize' }}
                          >
                            {val.title}
                          </h3>
                          <p
                            className={`resume-para ${styles.resumeDescription}`}
                          >
                            {val.description}
                          </p>
                        </Col>
                        <Col
                          sm={7}
                          xs={24}
                          className={`candidate-resume-card-3 ${styles.updatedResumeRow}`}
                        >
                          <p
                            className={`resume-last-days ${styles.lastUpdateText}`}
                          >
                            Last updated on{' '}
                            {moment(val.uploadedAt).format('DD MMM YY')}
                          </p>
                          <ul
                            className={`resume-ul right-resume-ul ${styles.resumeIcons}`}
                          >
                            <li className="text-center">
                              <a
                                className="p-0"
                                href={
                                  'https://sendbizbucket.s3.eu-west-3.amazonaws.com/' +
                                  val.name
                                }
                              >
                                <img
                                  style={{ width: '35%' }}
                                  src={
                                    process.env.PUBLIC_URL + '/group-18@3x.png'
                                  }
                                />
                              </a>
                            </li>
                            <li
                              className="resume-color text-center"
                              onClick={() =>
                                this.handleUpdate(
                                  val.title,
                                  val.description,
                                  val._id,
                                  val.name
                                )
                              }
                            >
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                              ></i>
                            </li>
                            <li
                              className="resume-color text-center"
                              onClick={() => this.deleteResume(val._id)}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </>
            )}
            <Row>
              <Col sm={0} xs={24} className="text-right">
                <button
                  style={{ width: '100%' }}
                  type="button"
                  onClick={() => this.setState({ modalShow: true })}
                  className="btn btn-dark bold-family btn-save-font cursor"
                >
                  Add New
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.companyInfoReducer.data,
  isloading: state.companyInfoReducer.isloading,
});

export default connect(mapStateToProps)(Resume);
