import React, { Component, useEffect, useState } from 'react';
import APIManager from '../../../APIManager';
import { Row, Col, Input, Pagination, Select } from 'antd';
const { Option } = Select;
export default function SelectCategory(props){
  const[cateOptions,setcatOptions] = useState([]);
  const [search,setsearch] = useState("");
  const [loading, setLoading] = useState(false)
  const [pageNumber, setpageNumber] = useState(null)
  const [lengthSelectedSectors, setLengthSectors] = useState(null);

  useEffect(()=>{
    const search = ""
    categoryList(1,search);
  },[props])

  useEffect (() => {
    categoryList(pageNumber,search)
  }, [cateOptions])

  const handleCategoryChange = (value) =>{
    setLengthSectors(value.length);
  	props.handleCategoryChange(value)
  }
  const handleCategorySearch = (value) =>{
    setsearch(value)
    setcatOptions([])
    categoryList(1,value)
  }
  const onScroll = (event) =>{
    var target = event.target
    if (!loading && target.scrollTop + target.offsetHeight === target.scrollHeight){
      const page = pageNumber + 1
      categoryList(page,search)
      
    }
  }
  
  const categoryList = (pageNumber=1,search) =>{
      setpageNumber(pageNumber)
      let options = [];
      APIManager.allProductCategories(pageNumber,search.toLowerCase())
      .then(
        (resp) =>{
          resp.data.data?.map((data) => {
            const suggestionExist = cateOptions.find(x => x.key === data._id);
                if (typeof suggestionExist === 'undefined') {
            options.push({
              label: data ? data.categoryName : '',
              value: data ? data._id : '',
              key: data._id,
            });
          }
          });
          if(options.length > 0){
            setcatOptions([...cateOptions,...options])
          }
          // else{
          //   setcatOptions(options)
          // }
        })
      .catch((err) =>
        console.log(err)
      );
   }
  return(

  		<Select
          mode="multiple"
          // maxTagCount= {1}
          maxTagCount= {lengthSelectedSectors>1 ? 1 : 1}
          maxTagTextLength={5}
          placeholder="Category"
          name={props.name}
          filterOption={false}
          onChange={handleCategoryChange}
          style={{ float: 'right' }}
          onSearch={handleCategorySearch}
          onPopupScroll={onScroll}
          className="s-search-select"
          options={cateOptions}
          onBlur = {()=> {handleCategorySearch('')}}
        >
        </Select>
  	)
}