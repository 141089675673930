import styles from './confirmPopup.module.css';

const ConfirmPopUp = (props) => {
  const { message, closePopUp, handleOk } = props;
  return (
    <>
      <div className={styles.parentBox}>
        <div className={styles.childBox}>
          <div className={styles.closeUpIcon}>
            <img src="../../cancel-circle.png" onClick={closePopUp}></img>
          </div>
          <div style={{ margin: '2vw 0 0 3vw', position: 'relative' }}>
            <img src="../../delete-icon.png" />
            <span className={styles.popupText}>{message}</span>
          </div>
          <div>
            <button
              style={{
                margin: '10% 0 0 10%',
                width: '20%',
                backgroundColor: '#EE5050',
                color: '#ffffff',
                borderRadius: '5px',
                border: 'none',
              }}
              onClick={closePopUp}
            >
              No
            </button>
            <button
              style={{
                margin: '10% 0 0 10%',
                width: '20%',
                backgroundColor: '#ffffff',
                color: '#EE5050',
                borderRadius: '5px',
                border: '2px solid #EE5050',
              }}
              onClick={handleOk}
            >
              yes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmPopUp;
