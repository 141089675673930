import React, { useEffect, useState } from 'react';

import './company.css';
import styles from './company_profile.module.css';
import { Spin, Row, Col, message, Tag } from 'antd';
import APIManager from '../../APIManager';

import { fileUrl } from '../../Shared/imageUrlPath.js';
import cardDefaultPic from '../asset/card.svg';
import {
  EnvironmentFilled,
  DollarCircleFilled,
  ClockCircleFilled,
  LeftOutlined,
  RightOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import NoDataFound from '../../PageNotFound/NoData.js';

const CompanyServices = (props) => {
  useEffect(() => {
    getServices('1');
  }, []);
  const [serviceData, setServiceData] = useState([]);
  const [totalCount, setTotalCount] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [totalPages, setTotalPages] = useState('');
  const getServices = (pageNumber) => {
    var ls = require('local-storage');
    const compId = ls.get('compId');
    setisLoading(true);
    APIManager.getServicesofCompany(compId, pageNumber)
      .then((response) => {
        if (response.data.isSuccess) {
          setCurrentPage(response.data.currentPage);
          setisLoading(false);
          setTotalCount(response.data.totalCount);
          setServiceData(response.data.data);
          setTotalPages(response.data.totalPages);
        }
      })
      .catch((error) => {
        message.info('Something went wrong.');
        isLoading(false);
      });
  };
  let renderPageNumbers;
  const pageNumbers = [];

  if (totalCount !== null) {
    for (let i = 1; i <= Math.ceil(totalCount / 10); i++) {
      pageNumbers.push(i);
    }
    renderPageNumbers = pageNumbers?.map((number) => {
      return (
        <span
          key={number}
          onClick={() => getServices(number)}
          className={
            currentPage === number ? 'page-number-btn' : 'pagination-container'
          }
        >
          {number}
        </span>
      );
    });
  }
  return (
    <>
      {isLoading && (
        <div className={'d-flex justify-content-center'}>
          <Spin />
        </div>
      )}
      {serviceData.length >= 1 && (
        <h6 className="about-company w-100">
          {serviceData.length} Services listed
        </h6>
      )}
      {serviceData &&
        serviceData?.map((data) => {
          //   console.log('nknknnk', data.media);
          let serviceImg = '';
          data?.media?.forEach((e) => {
            if (e.fileType.includes('image')) {
              serviceImg = e.fileName;
            }
          });
          return (
            <div
              className={`services-card ${styles.serviceCards}`}
              onClick={() => props.history.push(`/services/detail/${data._id}`)}
            >
              <Row gutter={15}>
                <Col sm={4} xs={7}>
                  <img
                    alt="example"
                    className={styles.Image}
                    src={
                      data.media.length > 0
                        ? fileUrl.fileUrlPath + serviceImg
                        : data?.companyData?.logo?.name
                        ? fileUrl.fileUrlPath + data?.companyData?.logo?.name
                        : cardDefaultPic
                    }
                  />
                </Col>
                <Col sm={20} xs={17} className="service-detail-row">
                  <Row justify="space-between">
                    <Col sm={17} xs={24}>
                      <div
                        className={`service-name ${styles.serviceName}`}
                      >
                        {data.name}
                      </div>
                    </Col>
                    <Col sm={7} xs={24}>
                      <div
                        className={`sponsered ${styles.serviceCardsSponcered}`}
                      >
                        {data.isSponsored ? 'SPONSORED' : ''}
                      </div>
                    </Col>

                    <Col sm={7} xs={0} className="icon-col"></Col>
                  </Row>
                  <Row>
                    <Col sm={18} xs={24}>
                      <span style={{ marginRight: '15px' }}>
                        {data?.category?.map((cat) => (
                          <Tag color="volcano" className="s-category-name">
                            {cat.categoryName.toUpperCase()}
                          </Tag>
                        ))}
                      </span>
                    </Col>
                  </Row>
                  <Row align="bottom" gutter={10}>
                    <Col
                      sm={7}
                      xs={24}
                      className={styles.companyServiceLocation}
                      style={{ display: 'flex', alignSelf: 'self-start' }}
                    >
                      <EnvironmentFilled className="service-icons" />
                      <div
                        className="service-location"
                        style={{ display: 'block' }}
                      >
                        {data.location}
                      </div>
                    </Col>
                    <Col
                      sm={8}
                      xs={24}
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <ClockCircleFilled className="service-icons" />
                      <div className="service-location">{data.period}</div>
                    </Col>
                    <Col sm={4} xs={12}>
                      <DollarCircleFilled className="service-icons" />
                      <div className="service-location">
                        {data.price.amount}
                      </div>
                    </Col>
                    <Col
                      sm={5}
                      xs={12}
                      style={{
                        display: 'flex',
                        alignSelf: 'self-start',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <ClockCircleOutlined className="service-icons" />
                      <div
                        className="service-location"
                        style={{ display: 'block' }}
                      >
                        {data.deliveryTime}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          );
        })}
      {/*================ pagination section ====================*/}
      {serviceData.length > 0 ? (
        <div className={'d-flex justify-content-center pt-4'}>
          <span
            className={currentPage === 1 ? 'pagination-side-btn' : ''}
            onClick={() => getServices(currentPage - 1)}
          >
            {' '}
            <LeftOutlined />{' '}
          </span>
          <div>{renderPageNumbers}</div>
          <span
            className={currentPage === totalPages ? 'pagination-side-btn' : ''}
            onClick={() =>
              currentPage !== totalPages && getServices(currentPage - 1)
            }
          >
            <RightOutlined />
          </span>
        </div>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default withRouter(CompanyServices);
