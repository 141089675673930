import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import styles from './create-user.module.css';
import { Alert } from 'antd';
import { Formik } from 'formik';
import { Row, Col, Avatar } from 'antd';
import {
  EyeFilled,
  EyeInvisibleFilled,
} from '@ant-design/icons';
// import UserIcon from '../asset/rectangle@3x.jpg';
import { message } from 'antd';
import APIManager from '../../APIManager/index';
import * as Yup from 'yup';
import UserIcon from '../../Components/asset/user.svg';
import './create-user.css';
import { ImageUrl } from '../../Shared/imageUrlPath';
import noWhiteSpace from '../constants/noWhiteSpace';
let formButton = null;

const initialValues = {
  image: UserIcon,
  uploadedImage: UserIcon,
  job_title: '',
  first_name: '',
  last_name: '',
  email_id: '',
  permissions: '',
  password: '',
};
class AddUser extends Component {
  formRef = React.createRef();

  state = {
    uploadedImage: UserIcon,
    isImage: false,
    image: '',
    alert: false,
    alertMessage: '',
    password: true,
    // initialValues: initialValues
  };

  componentDidMount() {
    if (this.props.sub_user_object !== '') {
      this.setState({
        uploadedImage:
          this.props.sub_user_object &&
          this.props.sub_user_object.user_image.length > 0
            ? ImageUrl.imageUrlPath + this.props.sub_user_object.user_image
            : UserIcon,
        image:
          this.props.sub_user_object &&
          this.props.sub_user_object.user_image.length > 0
            ? ImageUrl.imageUrlPath + this.props.sub_user_object.user_image
            : UserIcon,
      });
    }
  }

  toBase64 = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          this.setState({ uploadedImage: reader.result });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  onChange = (newFileList) => {
    if (newFileList) {
      this.setState({ isImage: true, image: newFileList });
      this.toBase64(newFileList);
    }
  };
  clickSubmitClick = () => {
    formButton.click();
  };
  createUser = (data) => {
    let formdata = new FormData();
    formdata.append('first_name', data.first_name);
    formdata.append('last_name', data.last_name);
    formdata.append('email', data.email_id);
    formdata.append('title', data.job_title);
    formdata.append('permission', data.permissions);
    formdata.append('password', data.password);
    if (this.state.isImage) {
      formdata.append('subUserImage', this.state.image, this.state.image.name);
    }

    if (
      this.props.sub_user_object === '' ||
      this.props.sub_user_object === undefined
    ) {
      APIManager.createSubUser(formdata)
        .then((response) => {
          if (response.data.status === 'success') {
            this.props.isClose();
            this.props.refreshPage();
            window.location.reload(false);
            message.info('Data saved successfully');
          }
        })
        .catch((error) =>
          this.setState({
            alert: true,
            alertMessage: error?.response?.data?.message,
          })
        );
    } else {
      APIManager.editSubUser(this.props.sub_user_object._id, formdata)
        .then((response) => {
          if (response.data.status === 'success') {
            this.props.isClose();
            this.props.refreshPage();
            message.info('Data saved successfully');
          }
        })
        .catch((error) =>
          this.setState({
            alert: true,
            alertMessage: error.response.data.message,
          })
        );
    }
  };
  handleChangePermissions = (value) => {
    initialValues.permissions = value;
  };
  showPass = () => {
    this.setState((prev) => ({ password: !prev.password }));
  };
  forgetPassword = () => {
    var ls = require('local-storage');
    const loggedInUserMail = ls.get('company_email');
    const value = { email: this.props.sub_user_object.email, loggedInUserMail };

    APIManager.forgetPassword(value)
      .then((response) => {
        if (response.data.isSuccess) {
          message.info('Please check your mail.');
        }
      })
      .catch((error) => {});
  };
  render() {
    console.log('Props', this.props);
    return (
      <Formik
        initialValues={{
          job_title:
            this.props.sub_user_object && this.props.sub_user_object.title
              ? this.props.sub_user_object.title
              : '',
          first_name:
            this.props.sub_user_object && this.props.sub_user_object.first_name
              ? this.props.sub_user_object.first_name
              : '',
          last_name:
            this.props.sub_user_object && this.props.sub_user_object.last_name
              ? this.props.sub_user_object.last_name
              : '',
          email_id:
            this.props.sub_user_object && this.props.sub_user_object.email
              ? this.props.sub_user_object.email
              : '',
          permissions:
            this.props.sub_user_object && this.props.sub_user_object.permission
              ? this.props.sub_user_object.permission
                  ?.split(' ')
                  ?.map((x) => x.toUpperCase()[0] + x.slice(1))
                  ?.join(' ')
              : '',
          password:
            this.props.sub_user_object && this.props.sub_user_object.password
              ? this.props.sub_user_object.password
              : '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            this.createUser(values);
          }, 500);
        }}
        validationSchema={Yup.object().shape({
          job_title: Yup.string().trim().required('job title is required.'),
          first_name: Yup.string().trim().required('first name is required.'),
          last_name: Yup.string().trim().required('last name is required.'),
          email_id: Yup.string().trim().email().required('email is required.'),
          password: Yup.string()
            .required('password is required')
            .min(6, 'password length must be 6 digit.'),
        })}
      >
        {(formikProps) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
          } = formikProps;
          return (
            <form onSubmit={formikProps.handleSubmit}>
              <Row style={{ paddingBottom: 25 }}>
                <div style={{ width: '100%', height: 85 }}>
                  <p className="input-label-field-custom-type-1">Image</p>
                  <Avatar
                    size={92}
                    icon={<img src={this.state.uploadedImage} alt='upload'/>}
                  />
                  &nbsp;&nbsp;
                  <label
                    className="add-user-upload-image"
                    style={{ cursor: 'pointer' }}
                  >
                    <input
                      accept="image/*"
                      id="raised-button-file"
                      multiple
                      type="file"
                      onChange={(event) => {
                        this.onChange(event.target.files[0]);
                      }}
                      name="image"
                    />
                    <span>
                      <img src={process.env.PUBLIC_URL + '/upload.svg'} alt='upload'/>
                      &nbsp;&nbsp;UPLOAD IMAGE
                    </span>
                  </label>
                </div>
              </Row>
              <Row style={{ marginTop: 40 }}>
                <label className="input_label_profile">Job Title</label>
                <input
                  name="job_title"
                  value={values.job_title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={noWhiteSpace}
                  className={`input-field-custom-type-1-left input-border ${styles.inputBox}`}
                  placeholder="Enter Job Title"
                />
                {touched.job_title && errors.job_title && (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {errors.job_title}
                  </div>
                )}
              </Row>
              <Row>
                <Col
                  sm={12}
                  xs={24}
                  className={`form-padding-right ${styles.paddingRyt}`}
                >
                  <label className="input_label_profile">First Name</label>
                  <input
                    name="first_name"
                    // value={values.first_name}
                    value={values.first_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={noWhiteSpace}
                    className={`input-field-custom-type-1-left input-border ${styles.inputBox}`}
                    placeholder="Enter First Name"
                  />
                  {touched.first_name && errors.first_name && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.first_name}
                    </div>
                  )}
                </Col>
                <Col
                  sm={12}
                  xs={24}
                  className={`form-padding-left ${styles.paddingLft}`}
                >
                  <label className="input_label_profile">Last Name</label>
                  <input
                    name="last_name"
                    value={values.last_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={noWhiteSpace}
                    className={`input-field-custom-type-1-left input-border ${styles.inputBox}`}
                    placeholder="Enter Last Name"
                  />
                  {touched.last_name && errors.last_name && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.last_name}
                    </div>
                  )}
                </Col>
                <Col
                  sm={12}
                  xs={24}
                  className={`form-padding-right ${styles.paddingRyt}`}
                >
                  <label className="input_label_profile">Email id</label>
                  <input
                    name="email_id"
                    value={values.email_id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={noWhiteSpace}
                    className={`input-field-custom-type-1-left input-border ${styles.inputBox}`}
                    placeholder="Enter Email ID"
                  />
                  {touched.email_id && errors.email_id && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.email_id}
                    </div>
                  )}
                </Col>
                <Col
                  sm={12}
                  xs={24}
                  className={`form-padding-left ${styles.paddingLft}`}
                >
                  <label className="input_label_profile">Permissions</label>
                  <select
                    name="permissions"
                    defaultValue={values.permissions}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Select User"
                    className={`input-field-custom-type-1-left input-border ${styles.inputBox} ${styles.selectInputBox}`}
                  >
                    <option className={styles.selectInputBoxOptions} value="">
                      Select User
                    </option>
                    <option
                      className={styles.selectInputBoxOptions}
                      value="Super User"
                    >
                      Super User
                    </option>
                    <option
                      className={styles.selectInputBoxOptions}
                      value="User"
                    >
                      User
                    </option>
                  </select>
                  {touched.permissions && errors.permissions && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      {errors.permissions}
                    </div>
                  )}
                </Col>
                <Col
                  sm={12}
                  xs={24}
                  className={`form-padding-right ${styles.paddingRyt}`}
                >
                  <label className="input_label_profile">Password</label>
                  <div style={{ position: 'relative' }}>
                    {this.state.password ? (
                      <EyeFilled className="eye-btn" onClick={this.showPass} />
                    ) : (
                      <EyeInvisibleFilled
                        className="eye-btn"
                        onClick={this.showPass}
                      />
                    )}
                    <input
                      name="password"
                      value={values.password}
                      type={this.state.password ? 'password' : 'text'}
                      onChange={handleChange}
                      onKeyDown={noWhiteSpace}
                      className={`input-field-custom-type-1-left input-border ${styles.inputBox}`}
                      placeholder="Enter Password"
                    />
                    {touched.password && errors.password && (
                      <div style={{ color: 'red', fontSize: '12px' }}>
                        {errors.password}
                      </div>
                    )}
                  </div>
                  {this.props.sub_user_object?.id && (
                    <div
                      className="form-group"
                      style={{ marginTop: '20px', marginBottom: '7px' }}
                    >
                      <label
                        onClick={this.forgetPassword}
                        className="form-check-label form-check-label-3 cursor"
                      >
                        Forgot Password.
                      </label>
                    </div>
                  )}
                </Col>
              </Row>
              {this.state.alert ? (
                <Alert
                  description={this.state.alertMessage}
                  type="error"
                  style={{ padding: '0', margin: '5px' }}
                  closable
                  onClose={() => {
                    this.setState({ alert: false, alertMessage: '' });
                  }}
                />
              ) : (
                ''
              )}

              <button
                style={{ opacity: 0, display: 'none' }}
                type={'submit'}
                ref={(e) => {
                  formButton = e;
                }}
              >
                ADD{' '}
              </button>
            </form>
          );
        }}
      </Formik>
    );
  }
}
export default AddUser;
