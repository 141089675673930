import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import styles from './candidature.module.css';
import { Select, Input, Form, Button } from 'antd';
import { message, Row, Col } from 'antd';
import APIManager from '../../APIManager/index';
import { CURRENCY } from '../constants/currency';
const { Option } = Select;

class CandidatureSalary extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      salary_active: 'candidature-active',
      social_active: '',
      qualification_active: '',
      exp_active: '',
      contact_active: '',
      tab_name: 'Profile',
      // ==========================================================Qualifications=======================
      allQualification: [],
      degree: '',
      institute: '',
      university: '',
      qulification_from: '',
      qulification_to: '',
      // ==========================================================Salary================================
      last_salary: 0,
      min_salary: 0,
      max_salary: 0,
      recieved_format: '',
      // ===========================================================Experience=============================
      allExperiences: [],
      title: '',
      company: '',
      employment_type: '',
      location: '',
      experience_from: '',
      experience_to: '',
      currently_working: false,
      bio: '',
      // ===========================================================Social================================
      linkedin: '',
      facebook: '',
      instagram: '',
      twitter: '',
      pinterest: '',
      youtube: '',
      indexOfModal: '',
      // =========================================================Contact Info============================
      phone_number: '',
      landline_number: '',
      address_line1: '',
      address_line2: '',
      city: '',
      state: '',
      zip_code: '',
      latitude: '',
      longitude: '',
      //===========================================================social Info===============
      social_Links: {},
      socialLinkData: [],
      initialLength: '',
      isOpen: false,
      modalShow: false,
      linktitle: '',
      link: '',
    };
  }
  handleClick = () => {
    this.formRef.current.submit();
  };
  onSubmit = (values) => {
    console.log('checking....', values);
    // this.props.setData(values);
    const can_salary = { can_salary: values };
    APIManager.candidatureSubmit(can_salary, 'can_salary')
      .then((res) => {
        console.log('response', res);
        message.success('Data saved successfully');
      })
      .catch((error) => {
        message.error('Something went wrong');
        console.log('error', error);
      });
  };

  render() {
    const data = this.props.data;
    return (
      <div className={styles.salaryTabsParent}>
        <Form
          initialValues={{
            lastSalary: data.lastSalary ? data.lastSalary : '',
            recieveFormat: data.recieveFormat ? data.recieveFormat : '',
            minSalary: data.minSalary ? data.minSalary : '',
            maxSalary: data.maxSalary ? data.maxSalary : '',
          }}
          ref={this.formRef}
          onFinish={this.onSubmit}
          style={{ position: 'relative' }}
        >
          {/* save button from big screen */}
          <Row className={styles.dipNoneOnSmall}>
            <Col span={24}>
              <Button
                style={{ float: 'right', height: '100%' }}
                type="button"
                htmlType="submit"
                className="btn btn-dark bold-family btn-save-font cursor"
              >
                Save
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12} xs={24} className="form-padding-right">
              <label className="input_label_profile">Last Salary</label>
              <Form.Item>
                <Input.Group compact style={{ width: '100%' }}>
                  <Form.Item
                    name={['lastSalary', 'currency']}
                    style={{ display: 'inline-block' }}
                  >
                    <Select
                      defaultValue="US Dollar"
                      className="currency-select"
                      style={{ width: '90px' }}
                    >
                      {CURRENCY?.map((curr) => (
                        <Option value={curr.name}>{curr.symbol}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={['lastSalary', 'amount']}
                    rules={[{ required: true, message: 'Required field' }]}
                  >
                    <Input
                      style={{ width: '20vw' }}
                      className={`s-input-fields ${styles.enterPriceBox}`}
                      placeholder="Enter Price"
                      type="number"
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-left ${styles.formPaddingLeft}`}
            >
              <label className="input_label_profile">Recieve Format</label>
              <Form.Item
                rules={[{ required: false, message: 'Required field' }]}
                name="recieveFormat"
              >
                <Select
                  className="input-selector-custom-type-1 input-field-custom-type-1-left"
                  placeholder="Select format"
                  optionLabelProp="label"
                  name="recieveFormat"
                >
                  <Option value="Hourly" label="Hourly">
                    Hourly
                  </Option>
                  <Option value="Daily" label="Daily">
                    Daily
                  </Option>
                  <Option value="Weekly" label="Weekly">
                    Weekly
                  </Option>
                  <Option value="Monthly" label="Monthly">
                    Monthly
                  </Option>
                  <Option value="Annualy" label="Annualy">
                    Annualy
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12} xs={24} className="form-padding-right">
              <label className="input_label_profile">Min Salary</label>
              <Form.Item>
                <Input.Group compact style={{ width: '100%' }}>
                  <Form.Item
                    name={['minSalary', 'currency']}
                    style={{ display: 'inline-block' }}
                  >
                    <Select
                      defaultValue="US Dollar"
                      className="currency-select"
                      style={{ width: '90px' }}
                    >
                      {CURRENCY?.map((curr) => (
                        <Option value={curr.name}>{curr.symbol}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={['minSalary', 'amount']}
                    rules={[{ required: true, message: 'Required field' }]}
                  >
                    <Input
                      className={`s-input-fields ${styles.enterPriceBox}`}
                      placeholder="Enter Price"
                      style={{ width: '20vw' }}
                      type="number"
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-left ${styles.formPaddingLeft}`}
            >
              <label className="input_label_profile">Max Salary</label>
              <Form.Item>
                <Input.Group compact style={{ width: '100%' }}>
                  <Form.Item
                    name={['maxSalary', 'currency']}
                    style={{ display: 'inline-block' }}
                  >
                    <Select
                      defaultValue="US Dollar"
                      className="currency-select"
                      style={{ width: '90px' }}
                    >
                      {CURRENCY?.map((curr) => (
                        <Option value={curr.name}>{curr.symbol}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={['maxSalary', 'amount']}
                    rules={[{ required: true, message: 'Required field' }]}
                  >
                    <Input
                      className={`s-input-fields ${styles.maxSalary}`}
                      placeholder="Enter Price"
                      style={{ width: '23vw' }}
                      type="number"
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          {/* save button from big screen */}
          <Row className={styles.dipNoneOnBig}>
            <Col span={24}>
              <Button
                style={{ float: 'right', height: '100%', width: '100%' }}
                type="button"
                htmlType="submit"
                className="btn btn-dark bold-family btn-save-font cursor"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default CandidatureSalary;
