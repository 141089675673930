import React, { Component } from 'react';
import { Row, Spin } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import './resume.css';
import userDefaultPic from '../asset/card.svg';
import NoData from '../../PageNotFound/NoData';
import { fileUrl } from '../../Shared/imageUrlPath';
import APIManager from '../../APIManager';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

class JobCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      total: '',
      currentPage: '',
      totalPages: '',
      data: [],
    };
  }
  componentDidMount() {
    this.getJob('1');
  }
  getJob = (pageNumber) => {
    let sortingValue = '';
    if (this.props.sortingValue) {
      sortingValue = this.props.sortingValue;
    }
    this.setState({ isloading: true });
    APIManager.resumeJobsAppliedbyCompany('', sortingValue, pageNumber).then(
      (response) => {
        this.setState({
          isloading: false,
          total: response.data.totalCount,
          currentPage: response.data.currentPage,
          totalPages: response.data.totalPages,
          data: response.data.data,
        });
      }
    );
  };
  render() {
    const appliedJobsData = this.props.appliedCandidateData
      ? this.props.appliedCandidateData.data
      : '';
    const loading = this.props.isloading ? this.props.isloading : '';
    const { searchingValue } = this.props;
    let renderPageNumbers;

    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / 10); i++) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers?.map((number) => {
        // let classes = this.state.currentPage === number ? .active : '';
        return (
          <span
            key={number}
            onClick={() => this.getJob(number)}
            className={
              this.state.currentPage === number
                ? 'page-number-btn'
                : 'pagination-container'
            }
          >
            {number}
          </span>
        );
      });
    }
    return (
      <>
        <div className={'d-flex justify-content-center w-100'}>
          {this.state.isloading && <Spin />}
        </div>
        {(appliedJobsData.length === 0 || appliedJobsData === null) &&
        this.state.isloading === false ? (
          <div className={'d-flex justify-content-center w-100'}>
            <NoData />
          </div>
        ) : (
          appliedJobsData &&
          appliedJobsData?.map((data) => (
            <div
              className="resume-card-1"
              onClick={() =>
                this.props.history.push({
                  pathname: '/All_applied_resume',
                  state: { resumeId: data._id },
                })
              }
            >
              <Row
                className={
                  'col-sm-12 jobListingclscard-1 resume-card-2 mt-3 p-0'
                }
              >
                <div
                  className="col-sm-2 resume-custom-col-2 text-center"
                  style={{ padding: '20px 30px' }}
                >
                  {/*<div style={{backgroundColor:"red",marginTop:11,width:"80%",marginLeft:11,height:"80%",borderRadius:10}}>*/}
                  <img
                    src={
                      data.job && data.job.job_logo !== ''
                        ? fileUrl.fileUrlPath + data.job.job_logo
                        : userDefaultPic
                    }
                    style={{
                      width: '100%',
                      height: '110px',
                      borderRadius: '10px',
                      objectFit: 'cover',
                    }}
                    alt="img"
                  />
                  {/*</div>*/}
                </div>
                <div className="col-sm-10 resume-custom-col-10 resume-col-10">
                  <Row>
                    <div
                      style={{ cursor: 'pointer' }}
                      className="col-sm-9 col-xs-9 p-0"
                    >
                      <p className="resume-card-4">
                        {' '}
                        {data.job.title.charAt(0).toUpperCase() +
                          data.job.title.slice(1)}
                        &nbsp;&nbsp;
                        <div
                          style={{ textAlign: 'center' }}
                          className="resume-card-3"
                        >
                          <img
                            src={process.env.PUBLIC_URL + '/user.png'}
                            alt="img"
                          />
                          &nbsp;&nbsp;<span>{data.count}</span>
                        </div>
                      </p>
                      <p className="job-card-4" style={{ marginTop: '3px' }}>
                        {data.company.comp_info.comp_name
                          ? data.company.comp_info.comp_name
                              .charAt(0)
                              .toUpperCase() +
                            data.company.comp_info.comp_name.slice(1)
                          : ''}
                      </p>
                      <p className="job-card-5" style={{ padding: '0' }}>
                        <img
                          src={process.env.PUBLIC_URL + '/location-red.png'}
                          alt="img"
                        />
                        &nbsp;&nbsp;
                        {data.company.contact_info
                          ? data.company.contact_info.city
                              .charAt(0)
                              .toUpperCase() +
                            data.company.contact_info.city.slice(1)
                          : ''}
                        ,{' '}
                        {data.company.contact_info
                          ? data.company.contact_info.country
                              .charAt(0)
                              .toUpperCase() +
                            data.company.contact_info.country.slice(1)
                          : ''}
                      </p>
                    </div>
                  </Row>
                  <ul className="job-card-6" style={{ paddingTop: '20px' }}>
                    {/* {data.resume !== '' && data.resume !== null ? ( */}
                    {/* <li>
                        <p>
                          <img
                            src={
                              process.env.PUBLIC_URL + '/page-check-red.png'
                            }
                          />
                              &nbsp;&nbsp;{(data.candidate.name).charAt(0).toUpperCase() + (data.candidate.name).slice(1)}
                        </p>
                      </li> */}
                    {/* ) : null} */}

                    {/* <li>
                        <p>
                          <img
                            src={
                              process.env.PUBLIC_URL + '/Group 363-red.png'
                            }
                          />
                            &nbsp;&nbsp;{moment(data.applied_for_job ? data.updatedAt : "").fromNow('DD MMM YY')}
                        </p>
                      </li> */}
                    <li>
                      <p>
                        <img
                          src={process.env.PUBLIC_URL + '/clock-red.png'}
                          alt="img"
                        />
                        &nbsp;&nbsp;{' '}
                        {moment(data.appliedAt ? data.appliedAt : '').format(
                          'DD MMM YY'
                        )}
                      </p>
                    </li>
                  </ul>
                </div>
              </Row>
            </div>
          ))
        )}

        {appliedJobsData.length > 0 ? (
          <div className={'d-flex justify-content-end pt-4 w-100'}>
            <span
              className={
                this.state.currentPage === 1 ? 'pagination-side-btn' : ''
              }
              onClick={() =>
                this.state.currentPage !== 1
                  ? this.getJob(this.state.currentPage - 1)
                  : ''
              }
            >
              {' '}
              <LeftOutlined />{' '}
            </span>
            <div>{renderPageNumbers}</div>
            <span
              className={
                this.state.currentPage === this.state.totalPages
                  ? 'pagination-side-btn'
                  : ''
              }
              onClick={() =>
                this.state.currentPage !== this.state.totalPages
                  ? this.getJob(this.state.currentPage + 1)
                  : ''
              }
            >
              <RightOutlined />
            </span>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appliedCandidateData: state.jobManagementReducer.appliedCandidateData,
    isloading: state.jobManagementReducer.isloading,
  };
};
export default connect(mapStateToProps)(JobCards);
