import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { MapKey } from '../../Shared/imageUrlPath';

class CustomMap extends Component {
  render() {
    //var ls = require('local-storage');
    let lat = this.props.lat; //ls.get('lat')
    let lng = this.props.long; //ls.get('long')
    return (
      <>
        <div className="form-group m-0">
          <label className="input_label_profile" htmlFor="exampleInputBio1">
            Map
          </label>
          <Map
            google={this.props.google}
            style={{ height: '200px' }}
            initialCenter={{
              lat: lat,
              lng: lng,
            }}
            center={{
              lat: lat,
              lng: lng,
            }}
          >
            <Marker
              onClick={this.onMarkerClick}
              name={''}
              icon={{
                url: '/marker_pro.png',
              }}
              position={{ lat: lat, lng: lng }}
            />
            {/*<InfoWindow marker={this.state.activeMarker} 
						visible={this.state.showingInfoWindow}
						onClose={this.onClose}
					>
						<div>
							<h4>{this.state.selectedPlace.name}</h4>
						</div>
					</InfoWindow>*/}
          </Map>
        </div>
      </>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: MapKey.key,
})(CustomMap);
// export default CandidatureContact;
