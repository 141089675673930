import React, { useEffect, useState } from 'react';
import { Spin, Alert, Input, message, Row, Col } from 'antd';
import { Modal } from 'react-bootstrap';
import closebtn from '../asset/cancel-circle.png';
import APIManager from '../../APIManager/index';
import PromoteData from '../company/context/context';
import './style.css';
import { withRouter, useHistory} from 'react-router-dom';
import { PAYMENT_NOTES } from '../constants/messages';

const options = [
  {
    label: 'Select time period type',
    value: '',
  },
  {
    label: 'Days',
    value: 1,
  },
  {
    label: 'Weeks',
    value: 7,
  },
  {
    label: 'Months',
    value: 30,
  },
];

const SelectPaymentModal = ({ sponser = false, ...props }) => {
  const { Search } = Input;
  const { userCount } = props;
  const { taxData } = props;
  const [state, setState] = React.useState({
    timePeriod: '',
    textPeriod: '',
    numPeriod: null,
    name: '',
    entity: '',
    promoteEntity: true,
    ...props.data,
    budget: 0,
  });
  const [newCard, setNewCard] = useState(false);
  const [selectedCard, showSelectedCard] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [maxLen, setMaxLen] = useState(0);
  const [selectedCardData, setSelectedCardData] = useState([]);
  // const [creditCard, selectCreditCard] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [stateValue, setStateValue] = useState('');
  const [captcha, captchaState] = useState('');
  const [captchVal, setCaptchaValue] = useState('');
  const [captcha_error, setCaptchaError] = useState(false);
  
  const [amountAftercouponSubs, setAmountAfterCouponSubs] = useState(
    Number(
      props?.data?.promoteEntity
      ? props.data.budget
      : props.isMonthlyPlan
      ? 15 * userCount
      : 120 * userCount
      )
      );
      const [amountAfterCouponPromoteState, setAmountAfterCouponState] =
      useState(0);
      const [alert, showAlert] = useState(false);
      const [alertMsg, setAlertMsg] = useState('');
      const [cuponText, setCuponText] = useState('');
      const [couponError, setCouponError] = useState(false);
      const [beforeAmount, setBeforeAmount] = useState();
      const [lengthErr, setLengthErr] = useState(true);
      const value = React.useContext(PromoteData);
      const history = useHistory();
      //changed input value
      const handleChange = (evt) => {
        let temp = 0;
        const value = evt.target.value;
    if (evt.target.name === 'textPeriod') {
      if (evt.target.value === '1') {
        setInputValue(1);
        setMaxLen(90);
        temp = 90;
      } else if (evt.target.value === '7') {
        setInputValue(7);
        setMaxLen(12);
        temp = 12;
      } else {
        setInputValue(30);
        setMaxLen(3);
        temp = 3;
      }
      if (stateValue - 0 < temp) {
        showAlert(false);
        setLengthErr(true);
      } else {
        if (temp === 90) {
          setAlertMsg('Enter value between 1 to 90 Days');
        } else if (temp === 12) {
          setAlertMsg('Enter value between 1 to 12 Weeks');
        } else {
          setAlertMsg('Enter value between 1 to 3 Months');
        }
        setLoading(false);
        showAlert(true);
        setLengthErr(false);
      }
    } else {
      setStateValue(evt.target.value);
      if (evt.target.value > maxLen) {
        if (inputValue === 1) {
          setAlertMsg('Enter value between 1 to 90 Days');
        } else if (inputValue === 7) {
          setAlertMsg('Enter value between 1 to 12 Weeks');
        } else {
          setAlertMsg('Enter value between 1 to 3 Months');
        }
        setLoading(false);
        showAlert(true);
        setLengthErr(false);
      } else {
        setLoading(false);
        showAlert(false);
        setLengthErr(true);
      }
    }
    var timePeriod;
    if (evt.target.name === 'textPeriod') {
      timePeriod = parseInt(state.numPeriod) * parseInt(evt.target.value);
    } else if (evt.target.name === 'numPeriod') {
      timePeriod = parseInt(state.textPeriod) * parseInt(evt.target.value);
    } else {
      timePeriod = parseInt(state.textPeriod) * parseInt(state.numPeriod);
    }

    setState({
      ...state,
      [evt.target.name]: value,
      timePeriod,
    });
  };

  const handleCuponVerify = () => {
    let product = props.isMonthlyPlan ? 'PREMIUM MONTHLY PLAN' : 'PREMIUM YEARLY PLAN';
    const data = {
      cupon: cuponText,
      whichProduct: props?.data ? 'sponsored' : product,
    };
    setCouponError(false);
    APIManager.cuponVerify(data)
      .then((response) => {
        if (response.data.isValid) {
          const amountWithoutCouponSubs = Number(
            props?.data?.promoteEntity
              ? props.data.budget
              : props.isMonthlyPlan
              ? 15 * userCount
              : 120 * userCount
          );
          const offPercent = 100 - response.data.data.percent_off;
          let amountAfterCouponSubs =
            (amountWithoutCouponSubs * offPercent) / 100;
          setAmountAfterCouponState(beforeAmount - amountAfterCouponSubs);
          setAmountAfterCouponSubs(amountAfterCouponSubs);
        } else {
          setCouponError(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePayment = async () => {
    try{
      var ls = require('local-storage');

      const isMonthly = ls.get('setisMonthlyPlan');
      const params = {
        // paymentMethod: paymentId,
        isMonthly: isMonthly,
        cupon: cuponText,
        userCount: userCount,
        totalAmount: beforeAmount,
        isReccuring: true,
      };
  
      setLoading(true);
      if (props.data) {
        const data = {
          budget: Number(
            amountAfterCouponPromoteState > 0
              ? amountAfterCouponPromoteState
              : beforeAmount
          ),
          period: parseInt(sponser ? state.timePeriod : props.data.timePeriod),
          name: props.data.name,
          // paymentMethod: paymentId,
          entity: props.data.entity,
          cupon: cuponText,
        };
  
        if (data.budget === 0) data.budget = 1;
  
        if (!lengthErr) {
          setAlertMsg('Enter all Fields ');
          showAlert(true);
          setLoading(false);
          setStateValue('');
        } else {
          APIManager.promoteEntities(data)
            .then((response) => {
              if (response.data.isSuccess) {
                setLoading(false);
                showAlert(true);
                setAlertMsg('Entity is promoted.');
                props.reloadProducts();
                props.onSubmit();
              }
            })
            .catch((error) => {
              setLoading(false);
              showAlert(true);
              setAlertMsg(error?.response?.data?.message);
            });
        }
      } else  {
      const response = await APIManager.submitPaymemt(params)
         console.log(history);
         if(response){
           window.open(response.data.data.session_url, "_self");
         }
      }
      setLoading(false);
    } catch (err) {
      message.error(err.response.data.message);
    }
    // const paymentId = creditCard.id;
  };

  useEffect(() => {
    const amountWithoutCouponSubs = Number(
      props?.data?.promoteEntity
        ? props.data.budget
        : props.isMonthlyPlan
        ? 15 * userCount
        : 120 * userCount
    );
    setBeforeAmount(amountWithoutCouponSubs);
  });

  return (
    <div>
      {/* {newCard && (
        <AddNewCardModal show={newCard} onHide={() => setNewCard(false)} />
      )} */}
      <Modal
        {...props}
        animation={false}
        className="password-modal"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="modal-header">
          <div className="modal-title h4" id="contained-modal-title-vcenter">
            Payment
          </div>
          <button type="button" onClick={props.onHide} className="close">
            <span aria-hidden="true">
              <img src={closebtn} alt="cancel" />
            </span>
            <span className="sr-only">Close</span>
          </button>
        </div>
        <Modal.Body className="show-grid" style={{ overflow: 'scroll' }}>
          {alert && (
            <Alert
              message={alertMsg}
              type="warning"
              closable
              onClose={() => {
                showAlert(false);
                setAlertMsg('');
              }}
            />
          )}
          {isLoading && (
            <div className={'d-flex justify-content-center'}>
              {' '}
              <Spin />
            </div>
          )}
          {sponser ? (
            <Row>
              <Col xs={22} style={{ float: 'left', marginRight: '20px' }}>
                <label className="input_label_profile" htmlFor="inputPassword">
                  Time Period
                </label>
                <Input
                  type="number"
                  className="nput-font form-control border"
                  placeholder="Enter time period"
                  style={{
                    font: 'normal normal 600 16px/24px Gilroy semiBold',
                    color: '#6c757d',
                  }}
                  name={'numPeriod'}
                  min={1}
                  max={maxLen}
                  value={stateValue}
                  size="large"
                  onChange={handleChange}
                  maxLength={'2'}
                />
              </Col>
              <Col xs={22}>
                <label className="input_label_profile" htmlFor="inputPassword">
                  Time Period Type
                </label>
                <select
                  className="input-font form-control border profile-form-control"
                  class="form-control"
                  placeholder="Select time period type"
                  name={'textPeriod'}
                  onChange={handleChange}
                  value={state.textPeriod}
                  style={{
                    // width: '50%',
                    font: 'normal normal 600 16px/24px Gilroy semiBold',
                    color: '#6c757d',
                  }}
                >
                  {options?.map((data) => (
                    <option value={data.value}>{data.label}</option>
                  ))}
                </select>
              </Col>
            </Row>
          ) : (
            <div className={'payment-container'} style={{ marginTop: '20px' }}>
              <div style={{ opacity: '0.4' }}>Total Payment</div>
              <div className={'new-payment-amount'}>
                <span className={'dollor-sign'}>$</span>

                {amountAfterCouponPromoteState !== 0 || taxData
                  ? (
                      amountAftercouponSubs *
                      ((taxData?.tax_rate?.percentage
                        ? taxData?.tax_rate?.percentage / 100
                        : 0) +
                        1)
                    ).toFixed(2)
                  : amountAftercouponSubs.toFixed(2)}
              </div>
            </div>
          )}

          <br />
          <div style={{position:"absolute", bottom:'10px'}}>
            {/* <div className={'coupon-card'}>Coupon Code</div> */}
            <div className={'saved-card-container'}>
              <Input
                placeholder="Enter Coupon Code"
                allowClear
                size="large"
                onChange={(e) => {
                  setCuponText(e.target.value);
                }}
                bordered={false}
              />
              <button
                type="button"
                onClick={handleCuponVerify}
                className="new-coupon-button cursor"
              >
                Apply Coupon
              </button>
            </div>
            {couponError ? (
              <p style={{ color: 'red' }}> *Please enter valid coupon code</p>
            ) : (
              ''
            )}
            <div style={{ marginTop: '60px' }}>
            NOTE:
            {sponser ? PAYMENT_NOTES.SPONSOR : PAYMENT_NOTES.BUY}
          </div>
          </div>

          {/* <div className={'select-card-container'}>
            <div className={'select-card'}>Select card</div>
            <div className={'new-card'} onClick={() => setNewCard(true)}>
              + Add new card
            </div>
          </div> */}

          {/* {!creditCard && (
            <div onClick={getAllCard} className={'saved-card-container mt-2'}>
              <div className={'saved-card'}>Select Saved Card</div>
              <div>
                <DownOutlined onClick={getAllCard} />
              </div>
            </div>
          )}
          {creditCard && (
            <div onClick={getAllCard} className={'saved-card-container mt-2'}>
              <div className={'saved-card'}>
                **** **** **** {creditCard.card}
              </div>
              <div className={'d-flex'}>
                <div className={'card-type mr-1'}>
                  {creditCard.brand.toUpperCase()}
                </div>
                <DownOutlined onClick={getAllCard} />
              </div>
            </div>
          )} */}
          {/* {selectedCard && (
            <div className={'card-container'}>
              <div className={'close-btn'}>
                {isLoading === false && (
                  <CloseCircleFilled onClick={() => showSelectedCard(false)} />
                )}
              </div>
              {selectedCardData &&
              selectedCardData.length === 0 &&
              isLoading === false
                ? 'No card found'
                : selectedCardData?.map((data) => {
                    return (
                      <div
                        className={'card-detail m-2'}
                        onClick={() => {
                          selectCreditCard(data);
                          showSelectedCard(false);
                        }}
                      >
                        <div className={'card-number'}>
                          **** **** **** {data.card}
                        </div>
                        <div className={'card-type'}>
                          {data.brand.toUpperCase()}
                        </div>
                      </div>
                    );
                  })}
            </div>
          )}
        */}
          {!sponser && (
            <div className={'amount-container'}>
              <div className={'before-amount'}>
                <div className={'amount-name'}>Total Amount</div>
                <div className={'amount'}>
                  <span className={'dollor-sign'}>$</span> {beforeAmount}
                </div>
              </div>
              <div className={'before-amount'}>
                <div className={'amount-name'}>Discount</div>
                <div>
                  -<span className={'dollor-sign'}>$</span>{' '}
                  {amountAfterCouponPromoteState}
                </div>
              </div>
              <div className={'before-amount'}>
                <div className={'amount-name'}>
                  {taxData ? taxData?.tax_rate?.display_name : 'Tax'} (%
                  {taxData ? taxData?.tax_rate?.percentage : '0'})
                </div>
                <div>
                  +<span className={'dollor-sign'}>$</span>{' '}
                  {amountAfterCouponPromoteState !== 0 || taxData
                    ? (
                        amountAftercouponSubs *
                        (taxData?.tax_rate?.percentage
                          ? taxData?.tax_rate?.percentage / 100
                          : 0)
                      ).toFixed(2)
                    : '0'}
                </div>
              </div>
              <div className={'before-amount'}>
                <div className={'amount-name'}>Final Amount</div>
                <div className="dollor-width-margin">
                  <span className={'dollor-sign'}>$</span>{' '}
                  {amountAfterCouponPromoteState !== 0 || taxData
                    ? (
                        amountAftercouponSubs *
                        ((taxData?.tax_rate?.percentage
                          ? taxData?.tax_rate?.percentage / 100
                          : 0) +
                          1)
                      ).toFixed(2)
                    : amountAftercouponSubs.toFixed(2)}
                </div>
              </div>
            </div>
          )}
          <br></br>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              handlePayment();
            }}
            className="btn btn-dark bold-family btn-save-font cursor"
          >
            Proceed
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default withRouter(SelectPaymentModal);
