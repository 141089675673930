import React, { Component } from 'react';

import { message } from 'antd';
import { GoogleApiWrapper } from 'google-maps-react';
import { EnvironmentFilled } from '@ant-design/icons';

class HeaderLocation extends Component {
  state = {
    lat: '',
    long: '',
  };
  componentDidMount() {
    this.handleCurrentLocation();
  }
  onchangeLocation = (e) => {
    this.props.takecurrentLocation(e.target.value);
  };
  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.props.takecurrentLocation(e.target.value);
    }
  };
  handleCurrentLocation() {
    try {
      const { google } = this.props;
      const maps = google.maps;
      var options = {
        // types: ['(country)'],/
      };
      var input = document.getElementById('pac_input'); //this.pac_input.current;
      var autocomplete = new maps.places.Autocomplete(input, options);
      const place = autocomplete.getPlace();

      autocomplete.addListener('place_changed', () => {
        this.setState({
          lat: autocomplete.getPlace().geometry
            ? autocomplete.getPlace().geometry.location.lat()
            : '',
          long: autocomplete.getPlace().geometry
            ? autocomplete.getPlace().geometry.location.lng()
            : '',
        });
        // this.props.takeautocompleteRef(autocomplete)
        if (autocomplete.getPlace().geometry) {
          this.props.takeLatLong(
            autocomplete.getPlace().geometry.location.lat(),
            autocomplete.getPlace().geometry.location.lng()
          );
        }
      });
      try {
        if (!place.geometry || !place.geometry.location) {
          return;
        }
      } catch (error) {
        return;
      }
    } catch (error) {
      message.error('Check your internet');
    }
  }

  render() {
    return (
      <>
        <EnvironmentFilled className={this.props.envclass} />
        <input
          type="search"
          className={this.props.className}
          // value={this.props.value}
          style={{ outline: 'none' }}
          name="current_location"
          id="pac_input"
          defaultValue={this.props.value}
          // ref={this.pac_input}
          // onChange={this.onchangeLocation}
          onKeyDown={this.handleKeyDown}
          onBlur={this.onchangeLocation}
          placeholder="Location"
        />
        {/*</Form.Item>*/}
      </>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
})(HeaderLocation);
// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyCq_buWKq5SczlpLmaxxpgQD7zZTNGGXL4'
// })(GoogleSearch);
