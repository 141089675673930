import React, { Component, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  Card,
  Tag,
  Spin,
  message,
  Tooltip,
} from 'antd';
import {
  EnvironmentFilled,
  ClockCircleOutlined,
} from '@ant-design/icons';
import styles from './productListing.module.css';
import './ProductListing.css';
import APIManager from '../../../APIManager';
import Pagination from 'react-js-pagination';
import cardDefaultPic from '../../asset/card.svg';
import NoData from '../../../PageNotFound/NoData';
import { withRouter } from 'react-router-dom';
import { fileUrl } from '../../../Shared/imageUrlPath';
import { CURRENCY } from '../../constants/currency';
import { NavLink } from 'react-router-dom';
import { SendOutlined, UserOutlined } from '@ant-design/icons';

const ProductListingCard = (props) => {
  const [loading, setLoading] = useState(true);
  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setcurrentPage] = useState(0);
  const [resultsPerPage, setresultsPerPage] = useState(0);
  const [totalData, settotalData] = useState(0);
  const myRef = props.scrollRef;
  const handleChange = (value) => {
    loadData(value);
  };

  useEffect(() => {
    if(props.current_location?.length > 0){
      loadData();
    }
  }, [
    props.city_name,
    props.searchQuery,
    props.company,
    props.price,
    props.category,
    props.price,
    props.current_location,
  ]);

  const loadData = (
    pageNumber = 1,
    { city_name } = props,
    { searchQuery } = props,
    { company } = props,
    { price } = props,
    { category } = props,
    { current_location } = props
  ) => {
    setLoading(true);
    let searchCat = '';
    if (category.length > 0) {
      category?.map((val) => {
        searchCat = searchCat + `&category=${val}`;
      });
    }
    let searchComp = '';
    if (company.length > 0) {
      company?.map((val) => {
        searchComp = searchComp + `&companyId=${val}`;
      });
    }
    let country = '';
    let state = '';
    let city = '';
    if (props.current_location.length > 0) {
      const locationArray = props.current_location.split(',');
      if (locationArray.length > 0) {
        country = locationArray[locationArray.length - 1];
        state =
          locationArray.length > 1
            ? locationArray[locationArray.length - 2]
            : '';
        city = locationArray.length > 2 ? locationArray[0] : '';
      }

      APIManager.allProductsSortAndSearch(
        pageNumber,
        searchQuery ? `&search=${searchQuery.toLowerCase()}` : '',
        price ? `${price}` : '',
        searchComp,
        searchCat,
        city,
        state,
        country,
        props.homeSearch,
        props.searchLocation,
      )
        .then((resp) => {
          setAllProducts(resp.data.data);
          props.setPropsProduct(resp.data.data);
          setLoading(false);
          setcurrentPage(resp.data.currentPage);
          setresultsPerPage(resp.data.results);
          settotalData(resp.data.totalCount);
          if (searchQuery) {
            props.changeMapLocation(
              resp?.data?.data[0]?.companyData?.contact_info,
              resp?.data?.data[0]?._id
            );
          }
          myRef?.current?.scrollIntoView();
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  const searchedData = allProducts.filter(
    (data) => data.name.toLowerCase() && data.isActivated
  );
  const SponsoredProducts = searchedData.filter((data2) => data2.isSponsored);
  const SortsProducts = searchedData.filter((data2) => !data2.isSponsored);
  const SortedProducts = [...SponsoredProducts, ...SortsProducts];
  let handleLocationChange = (e, value) => {
    e.stopPropagation();
    props.changeMapLocation(value?.companyData?.contact_info, value?._id);
  };
  return (
    <>
      {/************* Loader and error section ***************/}
      {loading && (
        <div className={'d-flex justify-content-center mt-5'}>
          <Spin />
        </div>
      )}
      {!loading && (
        <h3 className="card-div-head">
          {searchedData.length > 0
            ? searchedData.length +
              `${
                searchedData.length === 1 ? ' Product found' : ' Products found'
              }`
            : ''}
        </h3>
      )}
      {/************* all cards ***************/}
      <div
        className={`custom-container ${styles.mainContainerOfProductPage}`}
        style={{ padding: 0 }}
      >
        <div ref={myRef}>
          {searchedData.length === 0 && loading === false ? (
            <NoData
              text={
                'Please try using Different Location, Filters or keywords for your search.'
              }
            />
          ) : (
            SortedProducts?.map((data) => (
              <Card
                className={styles.productPageServiceCards}
                onClick={() => {
                  const win = window.open(
                    `/products/detail/${data._id}`,
                    '_blank'
                  );
                  win.focus();
                }}
                key={data.id}
              >
                <Row className={styles.productsCartParentCOntainer}>
                  <Col xs={6} className={styles.productLogoParentContainer}>
                    <img
                      alt="logo"
                      src={
                        data.media[0]
                          ? data.media[0].fileType !== 'video/mp4'
                            ? fileUrl.fileUrlPath + data.media[0].fileName
                            : cardDefaultPic
                          : cardDefaultPic
                      }
                      className={`product-logo ${styles.productLogo}`}
                    />
                  </Col>
                  <Col xs={17} className="service-detail-row">
                    <Row justify="space-between" align={'middle'}>
                      <Col span={16}>
                        <div style={{ display: 'flex' }}>
                          <div
                            className="product-name job-card-3"
                            style={{ fontSize: '1.5rem', lineHeight: '2rem' }}
                          >
                            {data.name}
                          </div>
                          {data.companyData.isFreelancer ? (
                            <div style={{ margin: '1px 0px 0px 10px' }}>
                              <Tooltip placement="top" title={'Freelancer'}>
                                <UserOutlined
                                  style={{ fontSize: '20px', color: '#ed5050' }}
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </Col>
                      <Col span={4}>
                        <Tag
                          color="volcano"
                          className="s-price-tag"
                          style={{ float: 'right' }}
                        >
                          {CURRENCY?.map((curr) =>
                            data
                              ? curr.name === data.price.currency
                                ? `${curr.symbol} ${data.price.amount}`
                                : ''
                              : ''
                          )}
                        </Tag>
                      </Col>
                      <Col span={3}>
                        <div
                          className={styles.mapIcons}
                          onClick={(e) => handleLocationChange(e, data)}
                        >
                          <SendOutlined className="mapIcon" />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={19}>
                        <NavLink
                          to={`comp-id${data.companyData._id}`}
                          onClick={(e) => e.stopPropagation()}
                          className="job-card-4 comp-hover"
                        >
                          {data.companyData
                            ? data.companyData.comp_info.bus_name
                            : ''}
                        </NavLink>
                      </Col>
                      <Col span={4}>
                        <div>
                          <p className="job-card-8 mb-0">
                            {data.isSponsored ? 'SPONSORED' : ''}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col
                        lg={data.category.length > 1 ? 24 : 6}
                        xs={24}
                        md={24}
                      >
                        <span style={{ marginRight: '15px' }}>
                          <Tag
                            color="volcano"
                            className="s-price-tag"
                            style={{ maxWidth: '300px' }}
                          >
                            {data && data.category && data.category[0]
                              ? data.category[0].categoryName
                              : ''}
                          </Tag>
                          {data.category.length > 1 ? (
                            <Tag
                              color="volcano"
                              className="s-price-tag"
                              style={{ maxWidth: '100%' }}
                            >
                              {data && data.category && data.category[0]
                                ? `+${data.category.length - 1}`
                                : ''}
                            </Tag>
                          ) : (
                            ''
                          )}
                        </span>
                      </Col>
                    </Row>

                    <Row
                      align="bottom"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '10px',
                      }}
                    >
                      <Col
                        span={13}
                        style={{ display: 'flex', alignSelf: 'self-start' }}
                      >
                        <EnvironmentFilled className="service-icons" />
                        <div
                          className="product-location"
                          style={{
                            display: 'block',
                            textTransform: 'capitalize',
                          }}
                        >
                          {data.companyData && data.companyData.contact_info
                            ? data.companyData.contact_info.city + ', '
                            : ''}
                          {data?.companyData?.contact_info?.state
                            ? data.companyData.contact_info.state
                            : ''}
                          {data.companyData &&
                          data.companyData.contact_info &&
                          data.companyData.contact_info.country.length > 0
                            ? ', ' + data.companyData.contact_info.country
                            : ''}
                        </div>
                      </Col>
                      <Col
                        span={10}
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        <ClockCircleOutlined className="service-icons" />
                        <div className="product-location">
                          {data.deliveryTime} Days ago
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            ))
          )}
          {/************* pagination section ***************/}
          <div className={'paginate-container'}>
            {searchedData.length > 0 ? (
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={10}
                firstPageText={false}
                lastPageText={false}
                totalItemsCount={totalData}
                pageRangeDisplayed={5}
                hideDisabled={true}
                onChange={(pageNumber) => {
                  loadData(pageNumber);
                }}
                itemClass="page-item"
                linkClass="page-link"
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(ProductListingCard);
