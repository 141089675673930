import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  Tag,
  Button,
  Carousel,
  Image,
} from 'antd';
import {
  FacebookFilled,
  LinkedinFilled,
  TwitterCircleFilled,
  HistoryOutlined,
  EnvironmentFilled,
  MoneyCollectFilled,
  ClockCircleFilled,
  HourglassFilled,
} from '@ant-design/icons';
import '../topNav.css';
import './ServiceDetail.css';
import styles from './serviceDetails.module.css';
import APIManager from '../../../APIManager';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import NewsComp from './news_comp';
import logo from '../../../Components/asset/card.svg';
import { CURRENCY } from '../../constants/currency';
import VideoSection from '../../videoSection/VideoSection';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

class ServiceDetail extends Component {
  state = {
    loading: true,
    isIntersted: '',
    isLoading: false,
    check_id: '',
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ isLoading: true });
    APIManager.getOneService(id).then((resp) => {
      if (resp.status === 200)
        this.setState({
          isIntersted: resp.data.isInterested,
          isLoading: false,
        });
    });
    APIManager.companyInfo().then((resp) => {
      if (resp.status === 200) {
        this.setState({ check_id: resp.data.data.data.comp_id });
      }
    });
    if (this.props.location.service_obj) this.setState({ loading: false });
  }
  componentDidUpdate = () => {
    window.scrollTo(0, 0);
  };
  handleChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  saveAsIntersted = () => {
    const { id } = this.props.match.params;
    const param = {
      interestId: id,
      category: 'service',
    };
    var ls = require('local-storage');
    const token = ls.get('token');
    this.setState({
      isloading: true,
    });
    if (token) {
      APIManager.createInterested(param)
        .then((response) => {
          if (response.data.isSuccess) {
            this.setState({
              isloading: false,
              isIntersted: response.data.message,
            });
            APIManager.getOneService(id);
          }
        })
        .catch((error) => console.log(error));
    } else {
      this.props.history.push('/login');
    }
  };
  checkChannelExist = (id) => {
    APIManager.checkChannel(id).then((resp) => {});
  };

  render() {
    const name = this.props.oneservicedata
      ? this.props.oneservicedata.data.name
      : '';
    const companyName = this.props.oneservicedata
      ? this.props.oneservicedata.data.companyData.comp_info.comp_name
      : '';
    const businessName = this.props.oneservicedata
      ? this.props.oneservicedata.data.companyData.comp_info.bus_name
      : '';
    const shortDescription = this.props.oneservicedata
      ? this.props.oneservicedata.data.shortDescription
      : '';
    const fullDescription = this.props.oneservicedata
      ? this.props.oneservicedata.data.fullDescription
      : '';
    const category = this.props.oneservicedata
      ? this.props.oneservicedata.data.category
      : '';
    const price = this.props.oneservicedata
      ? this.props.oneservicedata.data.price.amount
      : '';
    const currency = this.props.oneservicedata
      ? this.props.oneservicedata.data.price.currency
      : '';
    const duration = this.props.oneservicedata
      ? this.props.oneservicedata.data.duration
      : '';
    const period = this.props.oneservicedata
      ? this.props.oneservicedata.data.period
      : '';
    const location = this.props.oneservicedata
      ? this.props.oneservicedata.data.location
      : '';
    const pricingPlan = this.props.oneservicedata
      ? this.props.oneservicedata.data.pricingPlan
      : '';
    const experience = this.props.oneservicedata
      ? this.props.oneservicedata.data.experience
      : '';
    const deliveryTime = this.props.oneservicedata
      ? this.props.oneservicedata.data.deliveryTime
      : '';
    const socialLinks = this.props.oneservicedata
      ? this.props.oneservicedata.data.socialLinks
      : [];
    const loading = this.props.isloading;
    const imagePath = this.props.oneservicedata
      ? this.props.oneservicedata.data.media
      : [];
    const myEntity = this.props.oneservicedata
      ? this.props.oneservicedata.myEntity
      : '';
    const compId = this.props.oneservicedata
      ? this.props.oneservicedata.data.companyData.comp_id
      : '';
    const companyData = this.props.oneservicedata
      ? this.props.oneservicedata.data.companyData
      : '';
    const brochure = this.props.oneservicedata
      ? this.props.oneservicedata.data.brochurePath
      : '';
    const busName = this.props.oneservicedata
      ? this.props.oneservicedata.data.companyData.comp_info.bus_name
      : '';
    var ls = require('local-storage');
    const token = ls.get('token');
    const userType = this.props.user ? this.props.user.data.user.user_type : '';
    console.log('data', this.props);
    return (
      <>
        <Helmet>
          <title>{`${name}-${companyName}-Sendbiz`}</title>
          <meta
            property="og:title"
            content={`${name}-${companyName}-Sendbiz`}
          />
          <meta
            name="twitter:card"
            content={`${name}-${companyName}-Sendbiz`}
          />

          <meta name="description" content={fullDescription} />
          <meta property="og:description" content={fullDescription} />
          <meta name="twitter:description" content={fullDescription} />
        </Helmet>
        {this.state.isloading ? (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        ) : (
          ''
        )}
        <div className={`detail-outer-div ${styles.mainContainer}`}>
          <Row gutter={32}>
            <Col md={8} xs={24}>
              <Card
                bordered={false}
                className={`s-detail-left-card ${styles.headerCard}`}
              >
                <Carousel
                  afterChange={this.onChange}
                  className="event-carousel"
                  autoplay
                  arrows={true}
                >
                  {imagePath &&
                    imagePath
                      ?.filter((data) => data.fileType?.includes('image'))
                      ?.map((data) => (
                        <div className={'d-flex justify-content-center'}>
                          <Image
                            src={data.filePath ? data.filePath : logo}
                            alt="event"
                            className="event-carousel-img"
                            width={'100%'}
                            objectFit="cover"
                          ></Image>
                        </div>
                      ))}
                </Carousel>
                <h4 className="s-detail-name">
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </h4>
                <p className="s-detail-comp-name">
                  <NavLink
                    to={`/comp-id${this.props?.oneservicedata?.data?.companyData?._id}`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    target="_blank"
                    className=" comp-hover "
                    style={{ color: 'black', padding: '0px' }}
                  >
                    {businessName}
                  </NavLink>
                </p>
                <p style={{ overflow: 'auto' }} className="s-detail-short-desc">
                  {shortDescription}
                </p>
                {category &&
                  category?.map((cat) => (
                    <Tag color="volcano" className="s-price-tag">
                      {cat.categoryName}
                    </Tag>
                  ))}
                <div>
                  {/* row 1 card 1 */}
                  <Row gutter={{ sm: 16, xs: 5 }} className={styles.card1Row1}>
                    <Col
                      span={8}
                      style={{ borderRight: 'solid 1px #6c7a8f20' }}
                    >
                      <p className="s-detail-val">{duration}</p>
                      <p className="s-detail-attr">Duration</p>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        borderRight: 'solid 1px #6c7a8f20',
                        textAlign: 'center',
                      }}
                    >
                      <p className="s-detail-val">
                        {
                          CURRENCY.filter(
                            (values) => values.name === currency
                          )[0]?.symbol
                        }
                        {price}
                      </p>
                      <p className="s-detail-attr">Price</p>
                    </Col>
                    <Col span={8} style={{ textAlign: 'center' }}>
                      <p className="s-detail-val">{period}</p>
                      <p className="s-detail-attr">Period</p>
                    </Col>
                  </Row>
                </div>
                {socialLinks &&
                  socialLinks?.map((data) => (
                    <div>
                      <a href={data.link}>
                        <FacebookFilled
                          style={{
                            fontSize: '30px',
                            marginRight: '17px',
                            cursor: 'pointer',
                          }}
                        />
                      </a>
                      <LinkedinFilled
                        style={{
                          fontSize: '30px',
                          marginRight: '17px',
                          cursor: 'pointer',
                        }}
                      />
                      <TwitterCircleFilled
                        style={{
                          fontSize: '30px',
                          marginRight: '17px',
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                  ))}
                {/* All buttons */}
                <Row>
                  <Col md={12} xs={24}>
                    {this.state.check_id !== compId &&
                    userType !== 1 &&
                    token ? (
                      <Button
                        className={styles.sendMsgBtn}
                        onClick={() => {
                          var ls = require('local-storage');
                          ls.set('chatUserId', compId);
                          this.checkChannelExist(compId);
                          this.props.history.push({
                            pathname: '/company-mailbox',
                            userInfo: companyData,
                            canId: compId,
                          });
                        }}
                      >
                        Send a Message
                      </Button>
                    ) : (
                      ''
                    )}
                  </Col>

                  <Col md={12} xs={24}>
                    {!myEntity && (
                      <div>
                        <button
                          className={styles.intrestedBtn}
                          onClick={this.saveAsIntersted}
                        >
                          {this.state.isloading && 'saving...'}

                          {!this.state.isloading &&
                            (this.state.isIntersted
                              ? 'Not Interested'
                              : 'Interested')}
                        </button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
            {/* card 2 */}
            <Col md={16} xs={24}>
              <Card className="s-detail-r-card" bordered={false}>
                <h4 className="r-card-head">About the service</h4>
                <p
                  className={`r-card-text ${styles.description}`}
                  style={{
                    overflowWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {fullDescription}
                </p>

                <h4 className="r-card-head" style={{ padding: '20px 0 0' }}>
                  Service Information
                </h4>
                {imagePath.filter((data) => data.fileType?.includes('video'))
                  .length > 0 && (
                  <VideoSection
                    media={
                      imagePath &&
                      imagePath.filter((data) =>
                        data.fileType?.includes('video')
                      )
                    }
                  />
                )}
                <Row gutter={16}>
                  {location && (
                    <Col md={9} xs={24}>
                      <p className="s-detail-attr">Location of Intervetion</p>
                      <EnvironmentFilled className="p-detail-icons" />
                      <span className="s-detail-val">{location}</span>
                    </Col>
                  )}
                  <Col md={8} xs={24}>
                    <p className="s-detail-attr">Duration</p>
                    <HistoryOutlined className="p-detail-icons" />{' '}
                    <span className="s-detail-val">{duration}</span>
                  </Col>
                  <Col md={7} xs={24}>
                    <p className="s-detail-attr">Pricing Plan</p>
                    <MoneyCollectFilled className="p-detail-icons" />
                    <span className="s-detail-val">{pricingPlan}</span>
                  </Col>
                  <Col md={9} xs={24}>
                    <p className="s-detail-attr">Delivery Time</p>
                    <ClockCircleFilled className="p-detail-icons" />
                    <span className="s-detail-val">{deliveryTime}</span>
                  </Col>
                  <Col md={8} xs={24}>
                    <p className="s-detail-attr">Experience</p>
                    <HourglassFilled className="p-detail-icons" />
                    <span className="s-detail-val">{experience}</span>
                  </Col>
                  <Col md={7} xs={24}>
                    {brochure?.[0] &&
                      brochure?.map((data) => {
                        return (
                          <a
                            style={{ textDecoration: 'none' }}
                            target="_blank"
                            className="p-0"
                            href={data}
                          >
                            <p
                              style={{ fontSize: '15px', fontWeight: 'bold' }}
                              className="all-applied-resume-card-4 pt-5"
                            >
                              <img src={process.env.PUBLIC_URL + '/link.svg'} />
                              &nbsp;&nbsp;View Brochure
                            </p>
                          </a>
                        );
                      })}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
        <NewsComp />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    oneservicedata: state.ServiceReducer.oneservicedata,
    isloading: state.ServiceReducer.isloading,
    user: state.AuthReducer.user,
  };
};
export default connect(mapStateToProps)(ServiceDetail);
