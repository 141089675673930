import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import {
  Col,
  Row,
  Input,
  Select,
  Button,
  Spin,
  Popconfirm,
  message,
} from 'antd';
import {
  SearchOutlined,
  LeftOutlined,
  RightOutlined,
  DeleteFilled,
  EditFilled,
} from '@ant-design/icons';
import RightModal from '../job/rightModal.js';
import AddUser from '../createprofile/create-user.js';
import APIManager from '../../APIManager/index';
import { connect } from 'react-redux';
import { ImageUrl } from '../../Shared/imageUrlPath';

import userDefaultpic from '../../Components/asset/user.svg';
import './jobCards.css';
import styles from './companyBilling.module.css';
import NoData from '../../PageNotFound/NoData';

const { Option } = Select;
class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.buttonpressElement = React.createRef();
  }
  state = {
    applymodal: false,
    sub_users: [],
    resultsPerPage: '',
    totalData: '',
    totalPages: '',
    currentPage: '',
    sortBy: '',
    serchQuery: '',
    data: [],
    isloading: false,
    sub_user_object: '',
    deleteEdit: -1,
  };
  componentDidMount() {
    this.loadMoreData('1');
  }
  componentDidUpdate(prevState, prevprops) {
    if (
      prevprops.sortBy !== this.state.sortBy ||
      prevprops.serchQuery !== this.state.serchQuery
    ) {
      this.loadMoreData('1');
    }
  }

  loadMoreData = (pageNumber) => {
    let sort_by = this.state.sortBy;
    let search = this.state.serchQuery;
    this.setState({ isloading: true });
    APIManager.subuserSortAndSearch(sort_by, search, pageNumber).then(
      (resp) => {
        if (resp.data.status === 'success') {
          this.setState({
            resultsPerPage: resp.data.results,
            totalData: resp.data.totalCount,
            totalPages: resp.data.totalPages,
            currentPage: resp.data.currentPage,
            data: resp.data.data,
            isloading: false,
          });
        }
      }
    );
  };
  handleSubmit = () => {
    this.buttonpressElement.current.clickSubmitClick();
  };
  handleClick = () => {
    this.setState({
      applymodal: true,
      sub_user_object: '',
    });
  };
  handleSortChange = (value) => {
    this.setState({ sortBy: value });
  };

  closeModal = () => {
    document.body.style.overflow = 'auto';
    this.setState({ applymodal: false });
  };

  deleteSubUser = () => {
    const id = this.state.deleteSubUserId;
    APIManager.deleteSubUser(id)
      .then((resp) => {
        if (resp.data.isSuccess) {
          message.info(resp.data.message);
          this.loadMoreData(this.state.current_page);
        }
      })
      .catch((err) => {
        message.error('Something went wrong.');
      });
  };

  render() {
    let renderPageNumbers;

    const pageNumbers = [];
    if (this.state.totalData !== null) {
      for (let i = 1; i <= Math.ceil(this.state.totalData / 10); i++) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers?.map((number) => {
        return (
          <span
            key={number}
            onClick={() => this.loadMoreData(number)}
            className={
              this.state.currentPage === number
                ? 'page-number-btn'
                : 'pagination-container'
            }
          >
            {number}
          </span>
        );
      });
    }
    return (
      <>
        {/*============== for small screen only===============*/}
        <Row className={styles.manageUserHeader}>
          <Col
            sm={0}
            xs={9}
            style={{ paddingBottom: '16px', paddingLeft: '5%' }}
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <LeftOutlined className="back-icon" />
            <span className="back-btn">Go back</span>
          </Col>
          <Col sm={0} xs={15}>
            <p className="billing_overview_invoice_1">Manage Users</p>
          </Col>
        </Row>
        <div className={styles.manageUserParent}>
          {this.state.isloading && (
            <div className={'d-flex justify-content-center'}>
              <Spin />
            </div>
          )}
          {this.state.applymodal ? (
            <RightModal
              isClose={this.closeModal}
              onSubmit={this.handleSubmit}
              className={'create-user-modal'}
              component={
                <AddUser
                  ref={this.buttonpressElement}
                  isClose={this.closeModal}
                  refreshPage={this.loadMoreData}
                  sub_user_object={this.state.sub_user_object}
                />
              }
              title={this.state.sub_user_object ? 'Edit User ' : 'Add User'}
              isOpen={this.state.applymodal}
            />
          ) : null}
          <div
            className={styles.displayNoneSM}
            style={{ paddingBottom: '16px' }}
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <LeftOutlined className="back-icon" />
            <span className="back-btn">Go back</span>
          </div>

          <Row>
            <p className={`billing_overview_invoice_1 ${styles.displayNoneSM}`}>
              Manage Users
            </p>
            <Col
              sm={12}
              xs={0}
              className={`p-manage-search billing_overview_input_and_sort ${styles.displayNoneSM}`}
            >
              <Input
                size="large"
                placeholder="Search for keywords"
                onChange={(e) => this.setState({ serchQuery: e.target.value })}
                className="billing-searchbox"
                prefix={<SearchOutlined />}
              />
              <Select
                placeholder="Sort by"
                className="billing-sort"
                style={{ marginLeft: '12px' }}
                onChange={this.handleSortChange}
              >
                <Option value="super user">Super User</Option>
                <Option value="user">User</Option>
              </Select>
            </Col>
            {/*================= search box for small screens ==============*/}
            <Col xs={24} sm={0}>
              <Row className="p-manage-search billing_overview_input_and_sort">
                <Col span={24}>
                  <Input
                    size="large"
                    placeholder="Search for keywords"
                    onChange={(e) =>
                      this.setState({ serchQuery: e.target.value })
                    }
                    className="billing-searchbox"
                    prefix={<SearchOutlined />}
                  />
                </Col>
                <Select
                  placeholder="Sort by"
                  className={`billing-sort ${styles.sortBox}`}
                  onChange={this.handleSortChange}
                >
                  <Option value="super user">Super User</Option>
                  <Option value="user">User</Option>
                </Select>
              </Row>
            </Col>

            <Col sm={12} xs={0} align="end">
              <Button
                className="btn btn-dark bold-family btn-save-font b-height"
                onClick={this.handleClick}
              >
                Add Users
              </Button>
            </Col>
          </Row>
          <div style={{ height: '800px', overflow: 'scroll' }}>
            {this?.state?.isloading === false &&
            this?.state?.data?.length === 0 ? (
              <NoData />
            ) : (
              this.state.data &&
              this.state.data?.map((val, index) => (
                <div
                  className={'manage-user-card'}
                  onMouseEnter={() => this.setState({ deleteEdit: index })}
                  onMouseLeave={() => this.setState({ deleteEdit: -1 })}
                >
                  <Row
                    className={`resume-card-2 m-user-card  ${styles.manageUserCard}`}
                    key={index}
                  >
                    <Col
                      sm={3}
                      xs={6}
                      className={`all-applied-resume-card-1 ${styles.profileImageParent}`}
                    >
                      {val.user_image === '' ? (
                        <img
                          src={userDefaultpic}
                          className={`rounded-circle ${styles.profileImage}`}
                          alt="img"
                        />
                      ) : (
                        <img
                          className={`rounded-circle ${styles.profileImage}`}
                          src={ImageUrl.imageUrlPath + val.user_image}
                          alt="img"
                        />
                      )}
                    </Col>
                    <Col sm={2} xs={0}></Col>
                    <Col sm={19} xs={18} className="all-applied-resume-card-7">
                      <div className="row">
                        <div className="col-sm-9 all-applied-resume-card-10">
                          <p className="all-applied-resume-card-2">
                            {(val.first_name !== ''
                              ? val.first_name[0].toUpperCase() +
                                val.first_name.substr(1) +
                                ' '
                              : '') +
                              (val.last_name !== ''
                                ? val.last_name.substr(0, 1).toUpperCase() +
                                  val.last_name.substr(1)
                                : '')}

                            <span style={{ color: '#EE5050' }}>
                              &nbsp;&bull;&nbsp;{val.title}&nbsp;&bull;
                            </span>
                          </p>

                          <p
                            className={`all-applied-resume-card-3 ${styles.emailText}`}
                          >
                            {val.email}
                          </p>
                          <ul className="all-applied-resume-card-8">
                            <li>
                              <p className="user-background-manage all-applied-resume-card-4 m-0">
                                {val.permission}
                              </p>
                            </li>
                            <li></li>
                          </ul>
                        </div>

                        {index !== 0 && (
                          <div className="col-sm-3">
                            {this.state.deleteEdit === index && (
                              <EditFilled
                                className="manage-user-icons"
                                style={{ display: 'inline-block' }}
                                onClick={(e) => {
                                  this.setState({
                                    applymodal: true,
                                    sub_user_object: val,
                                  });
                                }}
                              />
                            )}
                            {this.state.deleteEdit === index && (
                              <Popconfirm
                                style={{ display: 'inline' }}
                                title="Are you sure to delete this sub user?"
                                onConfirm={() => {
                                  this.setState(
                                    { deleteSubUserId: val._id },
                                    this.deleteSubUser
                                  );
                                }}
                                onCancel={this.cancelDelete}
                                okText="Yes"
                                cancelText="No"
                              >
                                <DeleteFilled className="manage-user-icons" />
                              </Popconfirm>
                            )}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              ))
            )}
          </div>
          {/*================= Button for small screen================*/}
          <Row>
            <Col sm={0} xs={24} className={styles.btnMt}>
              <Button
                className="btn btn-dark bold-family btn-save-font b-height w-100"
                onClick={this.handleClick}
              >
                Add Users
              </Button>
            </Col>
          </Row>
          {/*================ pagination section===================*/}
          {this.state.data.length > 0 && (
            <div className={'d-flex justify-content-center pt-4'}>
              <span
                className={
                  this.state.currentPage === 1 ? 'pagination-side-btn' : ''
                }
                onClick={() => this.loadMoreData(this.state.currentPage - 1)}
              >
                {' '}
                <LeftOutlined />{' '}
              </span>
              <div>{renderPageNumbers}</div>
              <span
                className={
                  this.state.currentPage === this.state.totalPages
                    ? 'pagination-side-btn'
                    : ''
                }
                onClick={() =>
                  this.state.currentPage !== this.state.totalPages &&
                  this.loadMoreData(this.state.currentPage + 1)
                }
              >
                <RightOutlined />
              </span>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.billingOverViewReducer.sub_data,
    isloading: state.billingOverViewReducer.isloading,
  };
};
export default connect(mapStateToProps)(ManageUsers);
