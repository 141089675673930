import React, { useEffect } from 'react';
import { Row, Col, Button, Badge } from 'antd';
import Header from '../header/header.js';
import Footer from './footer';
import './Landing.css';
import './footer.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SimpleSlider from './slider';
import { withRouter } from 'react-router-dom';
import styles from './footer.module.css';

const Features = (props) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Row className="responsive-div">
      <Col>
        <Header />
        <Row className="home-outer-div">
          <Col xs={24} className={styles.headingCol}>
            <Col xs={24} className={styles.heading}>
              Companies
            </Col>
          </Col>
          <Col xs={24} className={styles.headingCol}>
            <img
              className={styles.image}
              src={process.env.PUBLIC_URL + 'companiesFooter.jpg'}
              alt='companiesFooter'
            />
          </Col>
          <Col xs={24} className={styles.sliderCol}>
            <SimpleSlider />
          </Col>
          <Row className={styles.container}>
            <Col className={styles.innerContainer}>
              <Row className={styles.title}>IMPROVE YOUR BUSINESS TO GROW</Row>
              <hr className="follow-up-line" />
              <Row>
                <Col xs={20} className={styles.titleValue}>
                  Whether you are a microenterprise, a freelancer, or a larger
                  company, Sendbiz's mission is to help you, to accompany you in
                  your development by allowing you to make yourself known, to
                  belly up and to recruit easily and at the lowest possible
                  cost.
                </Col>
              </Row>
            </Col>
            <Row gutter={16} className={styles.titleCol}>
              <Col xs={8} className={styles.displayCenter}>
                <h3 className={styles.textValue}>
                  Create your
                  <br /> free account
                </h3>
              </Col>
              <Col xs={8} className={styles.displayCenter}>
                <h3 className={styles.textValue}>
                  Create your
                  <br /> company page
                </h3>
              </Col>
              <Col xs={8} className={styles.displayCenter}>
                <h3 className={styles.textValue}>
                  Post your jobs,
                  <br /> services, products,
                  <br /> events,…
                </h3>
              </Col>
            </Row>
            <hr className="feature-hr" />
            <Row className={styles.container} style={{ marginTop: 50 }}>
              <Col className={styles.innerContainer}>
                <Row className={styles.title}>
                  Promote your company and your <br /> expertise as well as your
                  jobs
                </Row>
                <hr className="follow-up-line" />
                <Row>
                  <Col xs={20} className={styles.titleValue}>
                    Finding clients, candidates, resources shouldn't be that
                    hard. The Sendbiz platform allows you to search for your
                    candidates, find clients and be seen
                  </Col>
                </Row>
              </Col>

              <Col xs={24} className={styles.container1}>
                <Row className={styles.innerContainer}>
                  <Col xs={2} md={3} className={styles.paddingAll}></Col>
                  <Col xs={10} md={9} className={styles.paddingAll}>
                    <span>
                      <Badge color="#D46161" style={{ paddingTop: '6px' }} />
                    </span>
                    <span className={`para-fblock ${styles.marginLeft}`}>
                      Get more visibility{' '}
                    </span>
                  </Col>
                  <Col xs={2} md={3} className={styles.paddingAll}></Col>
                  <Col xs={10} md={9} className={styles.paddingAll}>
                    <span>
                      <Badge color="#D46161" style={{ paddingTop: '6px' }} />
                    </span>
                    <span className={`para-fblock ${styles.marginLeft}`}>
                      Find applicants quality{' '}
                    </span>
                  </Col>
                  <Col xs={2} md={3} className={styles.paddingAll}></Col>
                  <Col xs={10} md={9} className={styles.paddingAll}>
                    <span>
                      <Badge color="#D46161" style={{ paddingTop: '6px' }} />
                    </span>
                    <span className={`para-fblock ${styles.marginLeft}`}>
                      Send your services /Products{' '}
                    </span>
                  </Col>
                  <Col xs={2} md={3} className={styles.paddingAll}></Col>
                  <Col xs={10} md={9} className={styles.paddingAll}>
                    <span>
                      <Badge color="#D46161" style={{ paddingTop: '6px' }} />
                    </span>
                    <span className={`para-fblock ${styles.marginLeft}`}>
                      Find resources you need{' '}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Col className={styles.container1}>
              <Col xs={24} className={styles.title}>
                Want to be seen faster?
                <br /> Sponsor your company.
              </Col>
            </Col>
            <Col xs={24} className={styles.container1}>
              <Button
                type="primary"
                size="large"
                className="feature-button-style"
                onClick={() => props.history.push('/login')}
              >
                Try Sendbiz Now
              </Button>
            </Col>
          </Row>
        </Row>
        <Footer />
      </Col>
    </Row>
  );
};
export default withRouter(Features);
