import React, { useEffect, useState } from 'react';
import { message, Spin, Row, Col, Card, Checkbox, Collapse } from 'antd';
import APIManager from '../../../APIManager/index';
import cardDefaultImage from '../../asset/card.svg';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import NoDataFound from '../../../PageNotFound/NoData';
import { fileUrl } from '../../../Shared/imageUrlPath';
import { NavLink, useHistory } from 'react-router-dom';
import styles from './interested.module.css';

const JobCards = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [isloading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState('');
  const [total, setTotal] = useState('');
  const [jobId, setJobId] = useState('');
  useEffect(() => {
    makeHttpRequestWithPage(1);
  }, []);
  const deleteService = () => {
    const id = jobId;
    APIManager.deleteIntersted(id, 'job')
      .then((resp) => {
        if (resp.data.isSuccess) {
          message.info(resp.data.message);
          makeHttpRequestWithPage(currentPage);
        }
      })
      .catch((err) => {
        message.error('Something went wrong.');
      });
  };
  const makeHttpRequestWithPage = async (pageNumber) => {
    const category = 'job';
    setLoading(true);
    APIManager.getInterstedList(pageNumber, category)
      .then((resp) => {
        if (resp.status === 200) {
          setData(resp.data.data);
          setTotal(resp.data.totalCount);
          setCurrentPage(resp.data.currentPage);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error('Something went wrong!');
      });
  };
  const checkChannelExist = (id) => {
    APIManager.checkChannel(id).then((resp) => {});
  };
  const addressItem = (id) => {
    APIManager.addressInterested(id)
      .then((resp) => {
        message.info(resp?.data?.message);
        makeHttpRequestWithPage(1);
      })
      .catch((err) => {
        message.error('Something Went Wrong');
      });
  };
  return (
    <div>
      {isloading ? (
        <div className={'d-flex justify-content-center'}>
          <Spin />
        </div>
      ) : (
        <>
          {!data.length ? (
            <NoDataFound />
          ) : (
            <>
              {data?.map((data) => {
                return (
                  <Card className={styles.card}>
                    <Row gutter={15}>
                      <Col xs={5} sm={4}>
                        <img
                          alt="example"
                          src={
                            data.userType === 3 &&
                            data?.userId?.subUserData?.user_image
                              ? fileUrl.fileUrlPath +
                                data.userId.subUserData.user_image
                              : data.userType === 2 &&
                                data?.userId?.companyData?.logo.name
                              ? fileUrl.fileUrlPath +
                                data.userId.companyData.logo.name
                              : data.userType === 1 &&
                                data?.userId.candidateData?.can_detail?.profile
                              ? fileUrl.fileUrlPath +
                                data?.userId?.candidateData?.can_detail.profile
                              : cardDefaultImage
                          }
                          className={styles.image}
                        />
                      </Col>
                      <Col
                        xs={19}
                        sm={20}
                        style={{ justifyItems: 'space-between' }}
                        className="service-detail-row"
                      >
                        <Row className={styles.nameRow}>
                          <Col xs={20}>
                            <div
                              className={` product-name ${styles.nameCol}`}
                              style={{ cursor: 'auto' }}
                            >
                              <NavLink
                                to={
                                  (data.userType === 3
                                    ? `/comp-id${data?.userId?.subUserData?.companyData?._id}`
                                    : '') ||
                                  (data.userType === 2
                                    ? `/comp-id${data?.userId?.companyData?.id}`
                                    : '') ||
                                  (data.userType === 1
                                    ? `/interested-candidate/${data?.userId?.candidateData?._id}`
                                    : '')
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                target="_blank"
                                className=" comp-hover "
                                style={{ color: 'black', padding: '0px' }}
                              >
                                {(data.userType === 3
                                  ? data?.userId?.subUserData?.first_name +
                                    ' ' +
                                    data.userId.subUserData.last_name
                                  : '') ||
                                  (data.userType === 2
                                    ? data?.userId?.companyData?.comp_info
                                        .bus_name
                                    : '') ||
                                  (data.userType === 1
                                    ? data?.userId?.candidateData?.can_detail
                                        .firstName +
                                      ' ' +
                                      data.userId.candidateData.can_detail
                                        .lastName
                                    : '')}
                              </NavLink>
                            </div>
                          </Col>
                          <Col xs={4} className={`icon-col ${styles.checkBox}`}>
                            <Checkbox
                              className="address-checkbox"
                              checked={data?.doneWithConversations}
                              onChange={(e) => {
                                addressItem(data._id);
                              }}
                            >
                              Addressed
                            </Checkbox>
                          </Col>
                        </Row>
                        <Row className={`product-detail  ${styles.headMargin}`}>
                          <Col xs={24} sm={19}>
                            <span
                              className={`favourite-para-1`}
                              style={{ color: '#00000080' }}
                            >
                              {data?.interestId?.title?.length > 40
                                ? data?.interestId?.title
                                    ?.substring(0, 40)
                                    ?.toUpperCase() + '...'
                                : data?.interestId?.title?.toUpperCase()}
                            </span>
                          </Col>
                          <Col xs={24} sm={5} className={styles.dateCol}>
                            <img
                              src={process.env.PUBLIC_URL + '/clock-red.png'}
                            />
                            <span style={{ marginLeft: '0.5rem' }}>
                              {moment(data?.createdAt).format('YYYY/MM/DD')}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24} className={styles.headMargin}>
                            <span>
                              <img
                                src={
                                  process.env.PUBLIC_URL + '/location-red.png'
                                }
                                className={styles.locationImg2}
                                alt={'image_1'}
                              />
                            </span>
                            <span className={styles.location2}>
                              {(data.userType === 3
                                ? data?.userId?.subUserData?.companyData
                                    ?.contact_info?.sub_address
                                : '') ||
                                (data.userType === 2
                                  ? data?.userId?.companyData?.contact_info
                                      ?.sub_address &&
                                    data?.userId?.companyData?.contact_info
                                      ?.sub_address
                                  : '') ||
                                (data.userType === 1
                                  ? data?.userId?.candidateData?.can_detail
                                      ?.currentLocation &&
                                    data?.userId?.candidateData?.can_detail
                                      ?.currentLocation
                                  : '')}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="colapsable" style={{ marginTop: '15px' }}>
                      <Collapse>
                        <Collapse.Panel header="More Info">
                          <Row className={styles.titleMargin}>
                            <Col xs={8} sm={4}>
                              <span className={styles.title}>
                                {data.userType === 1
                                  ? 'Candidate:'
                                  : 'Company:'}
                              </span>
                            </Col>
                            <Col xs={16} sm={20}>
                              <NavLink
                                to={
                                  (data.userType === 3
                                    ? `/comp-id${data?.userId?.subUserData?.companyData?._id}`
                                    : '') ||
                                  (data.userType === 2
                                    ? `/comp-id${data?.userId?.companyData?.id}`
                                    : '') ||
                                  (data.userType === 1
                                    ? `/interested-candidate/${data?.userId?.candidateData?._id}`
                                    : '')
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                target="_blank"
                                className={`comp-hover ${styles.titleValueCompanyName}`}
                                style={{
                                  textDecoration: 'underline',
                                  fontSize: 'initial',
                                }}
                              >
                                {(data.userType === 3
                                  ? data?.userId?.subUserData?.first_name
                                  : '') ||
                                  (data.userType === 2
                                    ? data?.userId?.companyData?.comp_info
                                        ?.bus_name
                                    : '') ||
                                  (data.userType === 1
                                    ? data?.userId?.candidateData?.can_detail
                                        ?.firstName +
                                      ' ' +
                                      data.userId.candidateData.can_detail
                                        .lastName
                                    : '')}
                              </NavLink>
                            </Col>
                          </Row>
                          <Row className={styles.titleMargin}>
                            <Col xs={9} sm={4}>
                              <span className={styles.title}>Username : </span>
                            </Col>
                            <Col xs={15} sm={10}>
                              <span className={styles.titleValue}>
                                {(data.userType === 3
                                  ? data.userId.subUserData.first_name +
                                    ' ' +
                                    data.userId.subUserData.last_name
                                  : '') ||
                                  (data.userType === 2
                                    ? data.userId.companyData.comp_info
                                        .first_name +
                                      ' ' +
                                      data?.userId?.companyData?.comp_info
                                        ?.last_name
                                    : '') ||
                                  (data.userType === 1
                                    ? data.userId.candidateData.can_detail
                                        .firstName +
                                      ' ' +
                                      data.userId.candidateData.can_detail
                                        .lastName
                                    : '')}
                              </span>
                            </Col>
                            <Col xs={10} sm={4}>
                              <span className={styles.title}>
                                {data.userType === 1
                                  ? 'Occupation:'
                                  : 'Job Title :'}
                              </span>
                            </Col>
                            <Col xs={14} sm={6}>
                              <span className={styles.titleValue}>
                                {(data.userType === 3
                                  ? data?.userId?.subUserData?.title
                                  : '') ||
                                  (data.userType === 2
                                    ? data?.userId?.companyData?.comp_info
                                        ?.job_profile
                                    : '') ||
                                  (data.userType === 1
                                    ? data.userId.candidateData.can_detail
                                        .occupation
                                    : '')}
                              </span>
                            </Col>
                          </Row>
                          <Row className={styles.titleMargin}>
                            <Col xs={5} sm={4} className={styles.title}>
                              Email :{' '}
                            </Col>
                            <Col xs={19} sm={19} className={styles.titleValue}>
                              {(data.userType === 3
                                ? data?.userId?.subUserData?.email
                                : '') ||
                                (data.userType === 2
                                  ? data?.userId?.companyData?.contact_info
                                      ?.email
                                  : '') ||
                                (data.userType === 1
                                  ? data?.userId?.candidateData?.can_detail
                                      ?.email
                                  : '')}
                            </Col>
                            <Col xs={0} sm={1} className="icon-col">
                              <div
                                className={styles.mailbox}
                                onClick={() => {
                                  var ls = require('local-storage');
                                  ls.set('chatUserId', data.userId._id);
                                  checkChannelExist(data.userId._id);
                                  history?.push({
                                    pathname: '/company-mailbox',
                                    userInfo: data,
                                  });
                                }}
                              >
                                <img
                                  alt=""
                                  src={
                                    process.env.PUBLIC_URL + '/mailbox_red.png'
                                  }
                                  className="  service-icons"
                                  style={{ height: 15 }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className={styles.titleMargin}>
                            <Col xs={7} sm={4}>
                              <span className={styles.title}>Contact : </span>{' '}
                            </Col>
                            <Col xs={17} sm={19}>
                              <span className={styles.titleValue}>
                                {(data.userType === 3
                                  ? data?.userId?.subUserData?.companyData
                                      ?.contact_info?.phone_no
                                  : '') ||
                                  (data.userType === 2
                                    ? data?.userId?.companyData?.contact_info
                                        ?.phone_no
                                    : '') ||
                                  (data.userType === 1
                                    ? data?.userId?.candidateData?.can_contact
                                        .phoneNumber
                                    : '')}
                              </span>
                            </Col>
                            <Col xs={0} sm={1}>
                              <a
                                href={`mailto:${
                                  data.userType === 3
                                    ? data?.userId?.subUserData?.email
                                    : data.userType === 2
                                    ? data?.userId?.companyData?.contact_info
                                        ?.email
                                    : data.userType === 1
                                    ? data?.userId?.candidateData?.can_detail
                                        ?.email
                                    : ''
                                }`}
                              >
                                <img
                                  src="/telegram.png"
                                  style={{ marginLeft: '-11px' }}
                                  alt=""
                                />
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={20} sm={0}></Col>
                            <Col xs={2} sm={0}>
                              <div
                                className={styles.mailbox1}
                                onClick={() => {
                                  var ls = require('local-storage');
                                  ls.set('chatUserId', data.userId._id);
                                  this.checkChannelExist(data.userId._id);
                                  this.props.history.push({
                                    pathname: '/company-mailbox',
                                    userInfo: data,
                                  });
                                }}
                              >
                                <img
                                  alt=""
                                  src={
                                    process.env.PUBLIC_URL + '/mailbox_red.png'
                                  }
                                  className="service-icons"
                                  style={{ height: 15 }}
                                />
                              </div>
                            </Col>
                            <Col xs={2} sm={0}>
                              <a
                                href={`mailto:${
                                  data.userType === 3
                                    ? data?.userId?.subUserData?.email
                                    : data.userType === 2
                                    ? data?.userId?.companyData?.contact_info
                                        ?.email
                                    : data.userType === 1
                                    ? data?.userId?.candidateData?.can_detail
                                        ?.email
                                    : ''
                                }`}
                              >
                                <img
                                  src="/telegram.png"
                                  style={{ marginLeft: '-11px' }}
                                  alt=""
                                />
                              </a>
                            </Col>
                          </Row>
                        </Collapse.Panel>
                      </Collapse>
                    </div>
                  </Card>
                );
              })}
              <div
                className={
                  'paginate-container d-flex justify-content-end pt-3 pb-3'
                }
              >
                {total > 0 && (
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={10}
                    firstPageText={false}
                    lastPageText={false}
                    hideDisabled={true}
                    totalItemsCount={total}
                    // pageRangeDisplayed={5}
                    onChange={(pageNumber) => {
                      makeHttpRequestWithPage(pageNumber);
                    }}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default JobCards;
