import React from 'react';
// import '../topNav.css';
import Header from '../header/header.js';
import PlanCards from '../footer/plan_cards.js';
import Footer from '../footer/footer.js';
import '../footer/Landing.css';
import '../footer/footer.css';
import styles from './package_plan.module.css';
import SavedCards from '../../Components/paymentCard/savedCard';

const PackagePlan = (props) => {
  let userCount = props?.location?.state?.userCount;
  return (
    <>
      <Header />
      <div className={styles.responsiveDiv}>
        <img
          className={`go-back-plan ${styles.goBackIcons}`}
          src={process.env.PUBLIC_URL + '/go-back-1.png'}
          onClick={() => props.history.push('/company/billing')}
        />
        <div className={`subs-plan ${styles.subPlan}`}>Subscription Plans</div>
        <div className={`pick-plan ${styles.picPlan}`}>
          Pick your Growth Plan
        </div>
        <PlanCards userCount={userCount} />
        {/* <SavedCards /> */}
      </div>
      <Footer />
    </>
  );
};

export default PackagePlan;
