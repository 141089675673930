import React, { Component } from 'react';
import { Row, Col, Input, Select, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import NewsCard from './news_card';
import './serviceslist.css';
import styles from './news.module.css';
import CompanyMenuDropDown from '../CompanyMenuDropDown';

const { Option } = Select;

export default class NewsListWrapper extends Component {
  state = {
    searchQuery: '',
    addForm: true,
    sortValue: '',
  };

  handleChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };
  handleSort = (value) => {
    this.setState({
      sortValue: value,
    });
  };
  checkPremiumAccount = () => {
    this.renderAddNews();
  };
  renderAddNews = (e, obj, type) => {
    if (type === 'edit') {
      this.props.history.push({
        pathname: '/company/edit-news',
        state: { news_obj: obj },
      });
    } else {
      this.props.history.push({
        pathname: '/company/add-news',
        state: { addForm: this.state.addForm },
      });
    }
  };
  render() {
    const NewsCount = this.props.data ? this.props.data.data.length : '';
    return (
      <>
        <Row>
          <Col xs={24} md={0} style={{ marginBottom: '10px' }}>
            <CompanyMenuDropDown name={'News'} />
          </Col>
        </Row>
        <div>
          <Row className="services-header" justify="space-between">
            <Col xs={0} sm={12}>
              <span className="header-text">News</span>
            </Col>
            <Col xs={0} sm={12}>
              <Button
                className="add-service-btn"
                onClick={this.checkPremiumAccount}
              >
                Add new News
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col xs={24} sm={8} className={styles.searchCol}>
              <Input
                size="large"
                className={`resume-data-1 company-joblist-input service-input ${styles.search}`}
                placeholder="Search for a news"
                prefix={<SearchOutlined />}
                onChange={this.handleChange}
                value={this.state.searchQuery}
              />
            </Col>
            <Col xs={17} sm={0}></Col>
            <Col xs={6} sm={4} className={styles.sort}>
              <Select
                name="permissions"
                className="sort-select"
                style={{ float: 'right' }}
                placeholder="Sort By"
                onChange={this.handleSort}
              >
                <Option value="-createdAt">Latest</Option>
                <Option value="reset">Reset</Option>
              </Select>
            </Col>
            <Col xs={1} sm={0}></Col>
          </Row>
          <NewsCard
            searchQuery={this.state.searchQuery}
            editNews={(e, obj) => this.renderAddNews(e, obj, 'edit')}
            sortValue={this.state.sortValue}
            addNews={this.checkPremiumAccount}
          />
        </div>
      </>
    );
  }
}
