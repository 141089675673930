import React, { Component } from 'react';
import { Row, Col, Input, Select, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import EventCards from './event_card';
import styles from './events.module.css';
import './serviceslist.css';
import CompanyMenuDropDown from '../CompanyMenuDropDown';

const { Option } = Select;

export default class EventsListWrapper extends Component {
  state = {
    searchQuery: '',
    addForm: true,
    sortValue: '',
  };
  handleChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };
  handleSort = (value) => {
    this.setState({
      sortValue: value,
    });
  };
  checkPremiumAccount = () => {
    this.renderAddEvent();
  };
  renderAddEvent = (e, obj, type) => {
    if (type === 'edit') {
      this.props.history.push({
        pathname: '/company/edit-event',
        state: { event_obj: obj },
      });
    } else {
      this.props.history.push({
        pathname: '/company/add-event',
        state: { addForm: this.state.addForm },
      });
    }
  };
  render() {
    return (
      <>
        <Row>
          <Col xs={24} md={0} style={{ marginBottom: '10px' }}>
            <CompanyMenuDropDown name={'Events'} />
          </Col>
        </Row>
        <div className="applied-job-paddingright">
          <Row className="services-header" justify="space-between">
            <Col xs={0} sm={12}>
              <span className="header-text">Events</span>
            </Col>
            <Col xs={0} sm={12}>
              <Button
                className="add-service-btn"
                onClick={this.checkPremiumAccount}
              >
                Add new Event
              </Button>
            </Col>
          </Row>
          <Row className={styles.searchRow}>
            <Col xs={24} sm={8} className={styles.searchCol}>
              <Input
                size="large"
                className={`resume-data-1 company-joblist-input service-input ${styles.search}`}
                placeholder="Search for a Event"
                prefix={<SearchOutlined />}
                onChange={this.handleChange}
                value={this.state.searchQuery}
              />
            </Col>
            <Col xs={16} sm={0} />
            <Col xs={7} sm={4} className={styles.sortCol}>
              <Select
                name="permissions"
                className="sort-select"
                style={{ float: 'right' }}
                placeholder="Sort By"
                onChange={this.handleSort}
              >
                <Option value="-likes&-comments">Popular</Option>
                <Option value="-from">Latest</Option>
                <Option value="name">A-Z</Option>
                <Option value="-name">Z-A</Option>
                <Option value="reset">Reset</Option>
              </Select>
            </Col>
            <Col xs={1} sm={0} />
          </Row>
          <EventCards
            searchQuery={this.state.searchQuery}
            editEvent={(e, obj) => this.renderAddEvent(e, obj, 'edit')}
            sortValue={this.state.sortValue}
            addNewEvent={this.checkPremiumAccount}
          />
        </div>
      </>
    );
  }
}
