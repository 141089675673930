import React, { Component } from 'react';
import { Row, Col, Switch, Spin, message, Card, Tag, Tooltip } from 'antd';
import {
  DeleteFilled,
  NotificationFilled,
  EditFilled,
  CalendarFilled,
  StopOutlined,
} from '@ant-design/icons';

import NoData from '../../PageNotFound/NoData';
import styles from './companyJobs.module.css';
import moment from 'moment';
import checkPremiumDetails from './checkPremiumDetail';
import { connect } from 'react-redux';
import APIManager from '../../APIManager/index.js';
import { fileUrl } from '../../Shared/imageUrlPath';
import { withRouter } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import './jobCards.css';
import cardDefaultPic from '../../Components/asset/card.svg';
import PromoteJob from '../../Components/company/promoteEntity/index';
import ConfirmPopUp from '../constants/ConfirmPopup';

var user_type;

class JobCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobSwitchId: '',
      jobswitchflag: true,
      editJob: true,
      users: [],
      checked: [],
      // resultsPerPage: 10,
      deleteJobId: '',
      stopSponsorJobId: '',
      flag: false,
      isSpin: false,
      resultsPerPage: '',
      totalData: '',
      totalPages: '',
      currentPage: '',
      promoteJob: false,
      promoteJobId: '',
      isLoading: false,
      publishToDate: '',
      activeStatus: true,
      confirmDeletePopup: false,
      confirmStopSponsorPopup: false,
    };
  }
  componentDidMount() {
    user_type = JSON.parse(localStorage.getItem('user_type') || false);
    const user_type2 = JSON.parse(localStorage.getItem('user_type2') || false);
    user_type = user_type / (user_type2 * 99);
    this.makeHttpRequestWithPage();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchingValue !== prevProps.searchingValue) {
      this.makeHttpRequestWithPage();
    }
    if (this.props.sortingValue !== prevProps.sortingValue) {
      this.makeHttpRequestWithPage();
    }
  }
  deleteJob = () => {
    const id = this.state.deleteJobId;
    //const { id } = this.props.match.params;
    APIManager.deleteJob(id).then((resp) => {
      if (resp.data.isSuccess) {
        message.success('Job is deleted.');
        this.makeHttpRequestWithPage();
        APIManager.myJobs();
      }
    });
  };
  stopSponsor = () => {
    const id = this.state.stopSponsorJobId;
    //const { id } = this.props.match.params;
    APIManager.stopSponsor(id, 'job').then((resp) => {
      if (resp.data.isSuccess) {
        message.success('Job Sponsor is stopped');
        this.makeHttpRequestWithPage();
        //  window.location.reload();
        APIManager.myJobs();
      }
    });
  };
  confirmDelete = () => {
    this.deleteJob();
    this.setState({ confirmDeletePopup: false });
  };
  confirmStopSponsor = () => {
    this.stopSponsor();
    this.setState({ confirmStopSponsorPopup: false });
  };
  dontDelete = () => {
    //Dont Delete Because it is sponsored job which has remaining Budget
    message.info("You can't delete a sponsored job");
  };

  // makeHttpRequestWithPage = async (pageNumber) => {
  //   var ls = require('local-storage');
  //   var tok = ls.get('token');
  //   const response = await fetch(
  //     `https://reqres.in/api/users?page=${pageNumber}}`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${tok}`,
  //       },
  //     }
  //   );

  //   const data = await response.json();
  //   this.setState({
  //     users: data.data,
  //     total: data.total,
  //     per_page: data.per_page,
  //     current_page: data.page,
  //   });
  // };
  makeHttpRequestWithPage = async (pageNumber = 1) => {
    const searchQuery = this.props.searchingValue;
    const sort = this.props.sortingValue;
    this.setState({ isLoading: true });
    APIManager.myJobsSortAndSearch(
      pageNumber,
      searchQuery.toLowerCase(),
      sort
    ).then((resp) => {
      this.setState({
        users: resp?.data?.data,
        resultsPerPage: resp?.data?.results,
        totalData: resp?.data?.totalCount,
        totalPages: resp?.data?.totalPages,
        currentPage: resp?.data?.currentPage,
        isLoading: false,
        checked: resp?.data?.data?.map((item) => item?.isActivated) ?? [],
      });
    });
  };
  handleActiveJobStatus = async (currState, index, checked) => {
    // console.log('-------------------');
    // console.log('-------------------');
    if (!currState) {
      const flag = await checkPremiumDetails('jobs');
      if (!flag) {
        return;
      }
    }
    const id = this.state.jobSwitchId;
    this.setState({
      isSpin: true,
      checked: [
        ...this.state.checked?.map((_, i) => (i === index ? checked : _)),
      ],
    });
    // if (moment(this.state.publishToDate).isAfter(date)) {
    APIManager.changeJobActiveStatus(id)
      .then((resp) => {
        this.setState({ jobswitchflag: resp.data.data.isActivated });
        message.info('updated.');
        this.props.setzero();
        this.setState({
          isSpin: false,
        });
      })
      .catch((err) => {
        message.error(err);
        this.setState({ isSpin: false });
      });
    // }
    // else {
    //   message.info("Please edit publish from date to change the status.");
    // }
  };
  render() {
    // console.log('State', this.state);
    let renderPageNumbers;
    const pageNumbers = [];
    if (this.state.total !== null) {
      for (
        let i = 1;
        i <= Math.ceil(this.state.total / this.state.resultsPerPage);
        i++
      ) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers?.map((number) => {
        // let classes = this.state.currentPage === number ? .active : '';
        return (
          <span
            key={number}
            onClick={() => this.makeHttpRequestWithPage(number)}
            className={
              this.state.current_page === number
                ? 'page-number-btn'
                : 'pagination-container'
            }
          >
            {number}
          </span>
        );
      });
    }

    const reloadJobs = async () => {
      await APIManager.myJobs('1')
        .then((resp) => {
          this.setState({
            users: resp.data.data,
            resultsPerPage: resp.data.results,
            totalData: resp.data.totalCount,
            totalPages: resp.data.totalPages,
            currentPage: resp.data.currentPage,
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({ isloading: false });
          message.error('Something went wrong!');
        });
    };

    return (
      <>
        {this.state.isLoading && (
          <div className={'d-flex justify-content-center'}>
            <Spin />
          </div>
        )}
        {this.state.confirmDeletePopup && (
          <ConfirmPopUp
            message="Are you sure you want to delete job"
            handleOk={this.confirmDelete}
            closePopUp={() => this.setState({ confirmDeletePopup: false })}
          />
        )}
        {this.state.confirmStopSponsorPopup && (
          <ConfirmPopUp
            message="Are you sure you want to Stop Job Sponsorisation"
            handleOk={this.confirmStopSponsor}
            closePopUp={() => this.setState({ confirmDeletePopup: false })}
          />
        )}
        {this.state.promoteJob && (
          <PromoteJob
            show={this.state.promoteJob}
            onHide={() => this.setState({ promoteJob: false })}
            name={'job'}
            sponser={true}
            entity={this.state.promoteJobId}
            reloadProducts={reloadJobs}
          />
        )}
        <div className={'d-flex justify-content-center pt-3'}>
          {this.state.users === null ? <Spin /> : ''}
        </div>
        {this.state?.users?.length === 0 && this.state.isLoading === false ? (
          <NoData />
        ) : (
          this.state.users?.map((data, i) => (
            <Card className={styles.card} key={data?._id}>
              <Row gutter={15}>
                <Col xs={5} sm={4} className={styles.imageCol}>
                  <img
                    alt="example"
                    src={
                      data.logoPath
                        ? data.logoPath
                        : data.defaultLogo.length
                        ? fileUrl.fileUrlPath + data.defaultLogo
                        : cardDefaultPic
                    }
                    style={{
                      width: '90%',
                      height: '90%',
                      maxHeight: '90px',
                      borderRadius: '10px',
                    }}
                  />
                </Col>
                <Col
                  xs={19}
                  sm={20}
                  className="service-detail-row"
                >
                  <Row justify="space-between">
                    <Col xs={20} sm={12} className={styles.titleCol}>
                      <span
                        className="service-name"
                        onClick={() =>
                          this.props.history.push({
                            pathname: `/apply-for/${data._id}`,
                            state: { jobdata: data },
                          })
                        }
                      >
                        {data.title.length > 20 ? (
                          <Tooltip title={data.title}>
                            {data.title.substr(0, 19) + '...'}
                          </Tooltip>
                        ) : (
                          data.title
                        )}
                      </span>
                      <span className={styles.appliedTag}>
                        <Tag
                          color="volcano"
                          className="s-price-tag custom-tag-hover"
                          style={{
                            marginLeft: '0px',
                            verticalAlign: 'bottom',
                            maxWidth: '100%',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.history.push({
                              pathname: '/AppliedCandidates/details',
                              state: {
                                title: data.title,
                                job_type: data.job_type,
                                count: data.noOf_applied,
                                added_by: data.createdBy,
                                address:
                                  data?.companyData?.contact_info?.sub_address,
                                date: data.create,
                                image: data.job_logo,
                                id: data._id,
                                companyLocation:
                                  data?.companyData?.contact_info?.sub_address,
                              },
                            });
                          }}
                        >
                          {data.noOf_applied > 0
                            ? data.noOf_applied + ' APPLIED'
                            : 'NONE APPLIED'}
                        </Tag>
                      </span>
                    </Col>
                    <Col xs={0} sm={5}>
                      <CalendarFilled className="service-icons" />
                      <div className="service-location">
                        {moment(data.create).format('YYYY/MM/DD')}
                      </div>
                    </Col>

                    <Col xs={4} sm={5} className="icon-col">
                      <Tooltip title="Edit Job">
                        <EditFilled
                          className="service-hidden-icons service-icons"
                          // onClick={(e) => this.props.editService(e, data)}
                          onClick={() =>
                            this.props.history.push({
                              state: { data, editJob: true },
                              pathname: '/edit-job',
                            })
                          }
                        />
                      </Tooltip>
                      {user_type === 2 && !data?.isSponsored && (
                        <Tooltip title="Sponsor Job">
                          
                          <NotificationFilled
                            onClick={() =>
                              this.setState({
                                promoteJob: true,
                                promoteJobId: data._id,
                              })
                            }
                            className="service-hidden-icons service-icons"
                          />
                        </Tooltip>
                      )}
                      {user_type === 2 && data.isSponsored && (
                        <Tooltip title="Stop Sponsorisation">
                          <StopOutlined
                            onClick={() => {
                              this.setState({
                                stopSponsorJobId: data._id,
                                confirmStopSponsorPopup: true,
                              });
                            }}
                            className="service-hidden-icons service-icons"
                          />
                        </Tooltip>
                      )}
                      <Tooltip title="Delete Job">
                        <DeleteFilled
                          className="service-hidden-icons service-icons"
                          onClick={() => {
                            this.setState({
                              deleteJobId: data._id,
                              confirmDeletePopup: true,
                            });
                          }}
                        />
                      </Tooltip>
                      {/* </Popconfirm> */}
                      <Switch
                        defaultChecked={data.isActivated}
                        checked={this.state.checked[i]}
                        onChange={(checked) => {
                          this.setState(
                            {
                              jobSwitchId: data._id,
                              publishToDate: data.publish_to,
                            },
                            () =>
                              this.handleActiveJobStatus(
                                this.state.checked[i],
                                i,
                                checked
                              )
                          );
                        }}
                        // disabled={data.isActive ? false : true}
                      />
                    </Col>
                    <Col xs={19} sm={0} className={styles.dateInnerRow}>
                      <CalendarFilled className="service-icons" />
                      <div className="service-location">
                        {moment(data.create).format('YYYY/MM/DD')}
                      </div>
                    </Col>
                    <Col
                      xs={5}
                      sm={0}
                      style={{ textAlign: 'right' }}
                      className="sponsered"
                    >
                      <span className="sponsered">
                        {data.isSponsored ? 'SPONSERED' : <span>&nbsp;</span>}
                      </span>
                    </Col>
                    <Col xs={24} sm={0}>
                      <Tag
                        color="volcano"
                        className="s-price-tag custom-tag-hover"
                        style={{
                          verticalAlign: 'bottom',
                          maxWidth: '100%',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.history.push({
                            pathname: '/AppliedCandidates/details',
                            state: {
                              title: data.title,
                              job_type: data.job_type,
                              count: data.noOf_applied,
                              added_by: data.createdBy,
                              address: data.location,
                              date: data.create,
                              image: data.job_logo,
                              id: data._id,
                              companyLocation: data.location,
                            },
                          });
                        }}
                      >
                        {data.noOf_applied > 0
                          ? data.noOf_applied + ' APPLIED'
                          : 'NONE APPLIED'}
                      </Tag>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={0} sm={19}>
                      <Row>
                      <Col xs={24} sm={5}>
                        <span>
                          <img
                            src={process.env.PUBLIC_URL + '/briefcase-red.png'}
                            style={{ width: 14 }}
                            alt="img"
                          />
                        </span>
                        <span
                          className="service-s-desc"
                          style={{
                            paddingLeft: '8px',
                            verticalAlign: 'middle',
                          }}
                        >
                          {data.job_type}
                        </span>
                      </Col>
                      <Col xs={24} sm={10} style={{display: 'flex', alignItems:'center' }}>
                        <span>
                          <img
                            src="./user-circle.png"
                            style={{ width: 15, marginTop: '-4px' }}
                            alt="img"
                          />
                        </span>
                        &nbsp;&nbsp;
                        <span
                          style={{
                            color: '#000000',
                            opacity: '50%',
                            fontWeight: 'bold',
                          }}
                        >
                          Added By: &nbsp;
                        </span>
                        <span
                          style={{
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                          }}
                        >
                          {data ? data?.createdBy : ''}
                        </span>
                      </Col>
                      </Row>
                      
                    </Col>
                    <Col
                      xs={0}
                      sm={5}
                      style={{ textAlign: 'right' }}
                      className="sponsered"
                    >
                      <span className="sponsered">
                        {data.isSponsored ? 'SPONSERED' : <span>&nbsp;</span>}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={24}
                      style={{
                        display: 'flex',
                        alignSelf: 'self-start',
                        marginTop: '4px',
                      }}
                    >
                      <span>
                        <img
                          src={process.env.PUBLIC_URL + '/location-red.png'}
                          className="service-icons"
                          style={{
                            height: '16px',
                            marginTop: '3px',
                          }}
                          alt="img"
                        />
                      </span>
                      <span className="service-location">
                        {data?.companyData?.contact_info?.sub_address}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          ))
        )}
        {this.state.users.length > 0 ? (
          <div className={'paginate-container'}>
            <Pagination
              activePage={this.state.currentPage}
              itemsCountPerPage={10}
              firstPageText={false}
              lastPageText={false}
              hideDisabled={true}
              totalItemsCount={this.state.totalData}
              pageRangeDisplayed={5}
              onChange={(pageNumber) => {
                this.makeHttpRequestWithPage(pageNumber);
              }}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    myjobData: state.jobManagementReducer.myjobData,
    isloading: state.jobManagementReducer.isloading,
  };
};
export default withRouter(connect(mapStateToProps)(JobCards));
