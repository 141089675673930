import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Tabs, Spin, Row, Col } from 'antd';
import { Image } from 'antd';
import { Upload, Modal, message, Popconfirm } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import APIManager from '../../APIManager/index';
import { fileUrl } from '../../Shared/imageUrlPath';
import './edit_company_profile.css';
import styles from './edit_company_profile.module.css';
import VideoThumbnail from 'react-video-thumbnail';
import playButton from '../asset/play-button.svg';

const { TabPane } = Tabs;
export const getBlob = async (fileUri) => {
  const resp = await fetch(fileUri);
  const imageBody = await resp.blob();
  return imageBody;
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
}
let imageUploadCounter = 0;
// Later on in your styles..

class CompanyMedia extends Component {
  constructor(props) {
    super(props);
    this.modalElement = React.createRef();
    this.state = {
      fileList: [],
      uploadUrl: '',
      fileType: [],
      fileName: [],
      contentType: [],
      uploadImage: '',
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      prevMedia: [],
      deleteImageArr: '',
      mediaLoading: false,
    };
  }
  componentDidMount() {
    this.GetCompanyMedia();
  }
  GetCompanyMedia = () => {
    APIManager.companyInfo().then((response) => {
      if (response.data.isSuccess) {
        this.setState({
          prevMedia: response.data.data.comp_media,
        });
      }
    });
  };
  setProgress = (data) => {
    this.setState({
      progress: data,
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  AddMediaProfile = () => {
    if (this.state.fileList.length !== 0) {
      let media = [];
      this.state.fileName.forEach((data, i) => {
        media.push({
          fileName: data.fileName,
          fileType: data.fileType,
        });
      });
      let EditImageArray = [];
      EditImageArray = [...this.props.initialdata, ...media];
      const param = {
        media: EditImageArray,
      };
      APIManager.uploadMediaProfile(param).then((response) => {
        this.setState({ fileList: [], fileName: [] });
        this.GetCompanyMedia();
      });
    } else {
      message.info('Please upload files first.');
    }
  };
  deleteMedia = () => {
    let media = [];
    this.state.fileName.forEach((data, i) => {
      media.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    const deletedArr = this.state.deleteImageArr;
    let EditImageArray = [];
    if (deletedArr.length !== 0) {
      EditImageArray = [...this.props.initialdata, ...media];
      const id = EditImageArray.findIndex((x) => x.fileName === deletedArr);
      if (id >= 0) {
        EditImageArray.splice(id, 1);
      }
    } else {
      EditImageArray = [...this.props.initialdata, ...media];
    }
    const param = {
      media: EditImageArray,
    };
    APIManager.uploadMediaProfile(param).then((response) => {
      this.GetCompanyMedia();
    });
  };
  setCounter = () => (imageUploadCounter = 0);
  handleUploadFiles = ({ fileList }) => {
    imageUploadCounter += 1;
    if (imageUploadCounter < 2) {
      this.setState({ fileList });
      fileList?.map((data) => {
        return this.setState({
          contentType: data.type,
          uploadImage: data.originFileObj,
        });
      });
      const uploadParams = {
        files: [
          {
            requestType: 'CompanyServiceMedia',
            contentType: this.state.contentType,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            this.state.fileName.push({
              fileName: data.fileName,
              fileType: fileList[fileList.length - 1].type,
            });
            this.setState({ uploadUrl: data.url });
          });

          this.uploadFile();
        }
      });
    } else {
      console.log('process will not run');
    }
  };
  uploadFile = () => {
    this.setState({ mediaLoading: true });
    fetch(
      this.state.uploadUrl,
      {
        method: 'PUT',
        mode: 'cors',
        body: this.state.uploadImage,
      },
      (onprogress = (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        this.setProgress(percent);
        if (percent === 100) {
          setTimeout(() => this.setProgress(0), 1000);
        }
      })
    )
      .then((resp) => {
        this.setState({ mediaLoading: false });
      })
      .catch((err) => {
        this.setState({ mediaLoading: true });
        console.log(err);
      });
  };
  handleClick = () => {
    this.setState({ callAddMedia: true });
  };
  closeModal = () => {
    this.setState({ callAddMedia: false });
  };
  removeFiles = (e) => {
    const id = e.uid;
    const indexId = this.state.fileList.findIndex((x) => x.uid === id);
    this.state.fileList.splice(indexId, 1);
    this.setState({ fileList: this.state.fileList });
  };
  render() {
    const { previewVisible, previewImage, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        <div className={styles.mediaTabs}>
          {this.state.mediaLoading && (
            <Spin className="d-flex justify-content-center pt-3" />
          )}
          <Tabs
            className="company_media_tabs"
            defaultActiveKey="1"
            type="card"
            style={{ backgroundColor: 'white' }}
          >
            <TabPane
              tab={
                this.props.initialdata &&
                this.props.initialdata.length + ' File(s)'
              }
              key="1"
            >
              <p className="company_media_image_para">
                In order to have the most beautiful card do not hesitate to call
                upon professionals of the photos. Click here if there are some
                who are registered in Sendbiz near you.
              </p>
              <div className={'d-flex justify-content-end pt-3'}>
                <button
                  className={`btn btn-dark bold-family btn-save-font cursor ${styles.mediaTabsBtnOld}`}
                  disabled={this.state.mediaLoading}
                  onClick={this.AddMediaProfile}
                >
                  Save
                </button>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    className={'mediaUpload'}
                    fileList={this.state.fileList}
                    onClick={this.setCounter}
                    onPreview={this.handlePreview}
                    onChange={this.handleUploadFiles}
                    customRequest={this.handleUploadFiles}
                    accept="image/png,image/gif,image/jpeg,video/mp4,video/x-msvideo,image/jpg"
                    disabled={this.state.mediaLoading}
                    onRemove={(e) => this.removeFiles(e)}
                    beforeUpload={() => {
                      return false;
                    }}
                  >
                    {uploadButton}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                  >
                    <img
                      alt="example"
                      style={{
                        width: '100%',
                        objectFit: 'contain',
                        height: '200px',
                      }}
                      src={previewImage}
                    />
                  </Modal>
                </div>
              </div>
              <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
                {this.props.initialdata &&
                  this.props.initialdata?.map((data) => (
                    // <Col
                    //   style={{ display: 'flex',flexWrap: 'wrap', margin: '5px' }}
                    //   // className="image-container"
                    // >
                    //   <div className={'delete-btn-info-media'} style={{ display: 'flex',flexWrap: 'wrap' }}>
                    //     <Popconfirm
                    //       title="Are you sure to delete this file?"
                    //       onConfirm={this.deleteMedia}
                    //       okText="Yes"
                    //       cancelText="No"
                    //     >
                    //       <DeleteOutlined
                    //         onClick={() =>
                    //           this.setState({ deleteImageArr: data.fileName })
                    //         }
                    //       />
                    //     </Popconfirm>
                    //   </div>
                    //   {data.fileType === 'video/mp4' ||
                    //   data.fileType === 'video/x-msvideo' ? (
                    //     <a
                    //       href={fileUrl.fileUrlPath + data.fileName}
                    //       target="_blank"
                    //     >
                    //       <p>
                    //         {data.fileType === 'video/x-msvideo' ? (
                    //           <div
                    //             style={{
                    //               width: '250px',
                    //               height: '250px',
                    //               backgroundColor: '#d1d3d4',
                    //             }}
                    //           >
                    //             <img
                    //               src={downloadButton}
                    //               style={{
                    //                 width: '250px',
                    //                 height: '250px',
                    //                 position: 'absolute',
                    //                 padding: '2px',
                    //               }}
                    //             />
                    //           </div>
                    //         ) : (
                    //           <div className="container profile-media-thumbnail">
                    //             <div className="media-middle">
                    //               <div className="media-text">
                    //                 <img
                    //                   src={playButton}
                    //                   alt=""
                    //                   style={{
                    //                     width: '50px',
                    //                   }}
                    //                 />
                    //               </div>
                    //             </div>
                    //             <VideoThumbnail
                    //               videoUrl={
                    //                 data.fileName
                    //                   ? fileUrl.fileUrlPath + data.fileName
                    //                   : ''
                    //               }
                    //               thumbnailHandler={(thumbnail) =>
                    //                 console.log('thumbnail')
                    //               }
                    //               style={{ width: '250px', height: '250px' }}
                    //             />
                    //           </div>
                    //         )}
                    //       </p>
                    //     </a>
                    //   ) : (
                    //     <Image
                    //       src={
                    //         data.fileName
                    //           ? fileUrl.fileUrlPath + data.fileName
                    //           : ''
                    //       }
                    //     />
                    //   )
                    //   }
                    // </Col>
                    <div className={styles.imageContainer}>
                      <Col>
                        <div key={data._id} className={styles.deleteIcon}>
                          <Popconfirm
                            title="Are you sure to delete this file?"
                            onConfirm={this.deleteMedia}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined
                              style={{ color: 'red' }}
                              onClick={() =>
                                this.setState({ deleteImageArr: data.fileName })
                              }
                            />
                          </Popconfirm>
                        </div>

                        {/* <div
                        className={'delete-btn-info-media'}
                        // style={{ display: 'flex', flexWrap: 'wrap' }}
                      >
                        <Popconfirm
                          title="Are you sure to delete this file?"
                          onConfirm={this.deleteMedia}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined
                            onClick={() =>
                              this.setState({ deleteImageArr: data.fileName })
                            }
                          />
                        </Popconfirm>
                      </div> */}
                        {data?.fileType?.includes('image') ? (
                          <Image
                            src={
                              data.fileName
                                ? fileUrl.fileUrlPath + data.fileName
                                : ''
                            }
                            style={{ height: '100px', width: '100px' }}
                          />
                        ) : (
                          <a
                            href={fileUrl.fileUrlPath + data.fileName}
                            target="_blank"
                            rel="noreferrer"
                            style={{ padding: '0px' }}
                          >
                            <span>
                              {
                                <div
                                  className="container profile-media-thumbnail"
                                  style={{ padding: '0px' }}
                                >
                                  <div className="media-middle">
                                    <div>
                                      <img
                                        src={playButton}
                                        alt=""
                                        style={{
                                          width: '10px',
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <VideoThumbnail
                                    videoUrl={
                                      data.fileName
                                        ? fileUrl.fileUrlPath + data.fileName
                                        : ''
                                    }
                                    thumbnailHandler={(thumbnail) =>
                                      console.log('thumbnail')
                                    }
                                    style={{ width: '100px', height: '100px' }}
                                  />
                                </div>
                              }
                            </span>
                          </a>
                        )}
                      </Col>
                    </div>
                  ))}
              </Row>
              <Row>
                <Col xs={24} sm={0}>
                  <button
                    className={`btn btn-dark bold-family btn-save-font cursor ${styles.mediaTabsBtn}`}
                    disabled={this.state.mediaLoading}
                    onClick={this.AddMediaProfile}
                  >
                    Save
                  </button>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </div>
        <div className={styles.hideDiv}></div>
      </>
    );
  }
}
export default CompanyMedia;
