import React, { Component } from 'react';
import { Row, Col, Spin, Card } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import APIManager from '../../APIManager/index';
import { ImageUrl } from '../../Shared/imageUrlPath';
import userDefaultPic from '../asset/user.svg';
import AppliedJobStatus from '../layout/applied-job-status'

class AppliedJobDetail extends Component {
  componentDidMount() {
    const jobId = this.props.location.state.jobId.job;
    const companyId = this.props.location.state.jobId.company;

    APIManager.appliedJobCompany(jobId, companyId);
  }
  render() {
    const companyName = this.props.appliedJobData && this.props.appliedJobData.data[0].appliedByCompany
      ? this.props.appliedJobData.data[0].appliedByCompany.comp_info.comp_name
      : '';
    const jobTitle = this.props.appliedJobData
      ? this.props.appliedJobData.data[0].applied_for_job.title
      : '';
    const location = this.props.appliedJobData
      ? this.props.appliedJobData.data[0].appliedByCompany ? `${this.props.appliedJobData.data[0].appliedByCompany.contact_info ? (this.props.appliedJobData.data[0].appliedByCompany.contact_info.city).charAt(0).toUpperCase() + (this.props.appliedJobData.data[0].appliedByCompany.contact_info.city).slice(1) : ""}, ${this.props.appliedJobData.data[0].appliedByCompany.contact_info ? (this.props.appliedJobData.data[0].appliedByCompany.contact_info.country).charAt(0).toUpperCase() + (this.props.appliedJobData.data[0].appliedByCompany.contact_info.country).slice(1) : ""}` : ''
      : 'No location found';
    const date = this.props.appliedJobData
      ? this.props.appliedJobData.data[0].applied_at
      : '';
    const loading = this.props.isloading;
    // const apply_for_job_id = this.props.appliedJobData ?
    const appliedJobId = this.props.appliedJobData
      ? this.props.appliedJobData.data.applied_for_job
      : '';
    // var ls = require('local-storage');
    // ls.set('applied_jobId',appliedJobId);
    return (
      <>
        <div className={'d-flex justify-content-center'}>
          {loading && <Spin />}
        </div>
        <div className="">
          <Row>
            <p className="resume-1">{`Applied Job : ${jobTitle}`}</p>
          </Row>
          {/*<Row className={'jobListingclscard-1 resume-card-2 mt-3 p-0'}>
            <div className="col-sm-2 resume-custom-col-2 text-center"
              style={{ padding: '20px 30px' }}
            >
              <Image
                className="job-card-image-1"
                src={process.env.PUBLIC_URL + '/Rectangle@2x.png'}
              />
            </div>
            <div className="col-sm-10 resume-custom-col-10 resume-col-10">
              <Row>
                <div className="col-sm-9 col-xs-9 p-0">
                  <p className="resume-card-4">
                    {' '}
                    {companyName.charAt(0).toUpperCase() + companyName.slice(1)}
                    &nbsp;&nbsp;
                    <div className="resume-card-3">
                      <img src={process.env.PUBLIC_URL + '/user.png'} />
                      <span>03</span>
                    </div>
                  </p>
                  <p className="job-card-4">
                    {jobTitle.charAt(0).toUpperCase() +
                      jobTitle.slice(1)}
                  </p>
                  <p className="job-card-5">
                    <img src={process.env.PUBLIC_URL + '/location-red.png'} />
                    &nbsp;&nbsp;{location}
                  </p>
                </div>
              </Row>
              <ul className="job-card-6">
                <li>
                  <p>
                    <img src={process.env.PUBLIC_URL + '/Group 363-red.png'} />
                    &nbsp;&nbsp;Less than 3 months
                  </p>
                </li>
                <li>
                  <p>
                    <img src={process.env.PUBLIC_URL + '/clock-red.png'} />
                    &nbsp;&nbsp;
                    {moment(date).format('DD MMM YY')}
                  </p>
                </li>
              </ul>
            </div>
          </Row>*/}
          <Card className="services-card">
            <Row>
              <Col span={3}>
                <img
                  alt="example"
                  src={this.props.appliedJobData && this.props.appliedJobData.data[0].applied_for_job.job_logo
                    ? ImageUrl.imageUrlPath + this.props.appliedJobData.data[0].applied_for_job.job_logo
                    : userDefaultPic}
                  style={{ width: '100%', height: '100%', objectFit : 'cover', borderRadius: '10px' }}
                />
              </Col>
              <Col
                span={21}
                style={{ paddingLeft: '20px' }}
                className="service-detail-row"
              >
                <Row justify="space-between">
                  <Col span={17}>
                    <div className="service-name" style={{ paddingBottom: "3px" }}>
                      {' '}
                      {companyName.charAt(0).toUpperCase() +
                        companyName.slice(1)}
                      &nbsp;&nbsp;
                      <div className="resume-card-3">
                        <img src={process.env.PUBLIC_URL + '/user.png'} />
                        &nbsp;
                        <span>{this.props.appliedJobData && this.props.appliedJobData.totalCount}</span>
                      </div>
                    </div>
                    <p className="job-card-4">
                      {jobTitle.charAt(0).toUpperCase() +
                        jobTitle.slice(1)}
                    </p>
                  </Col>
                  <Col span={7} className="icon-col"></Col>
                </Row>
                <Row>
                  <Col span={18}>
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + '/location-red.png'}
                        style={{ width: 14 }}
                      />
                    </span>
                    <span
                      className="service-s-desc"
                      style={{
                        paddingLeft: '8px',
                        verticalAlign: 'middle',
                      }}
                    >
                      {location}
                    </span>
                  </Col>
                </Row>
                <Row align="bottom" style={{ marginTop: '10px' }}>
                  <Col span={12}>
                    <img
                      src={process.env.PUBLIC_URL + '/clock-red.png'}
                      className="service-icons"
                      style={{ height: '16px', verticalAlign: 'super' }}
                    />
                    <div className="service-location">
                      {moment(date).format('DD MMM YY')}
                    </div>
                  </Col>
                  <Col span={2}></Col>
                </Row>
              </Col>
            </Row>
          </Card>
          <Row>
            <Col span={24}>
              <p
                style={{
                  marginTop: 30,
                  font: 'normal normal 400 24px/43px Gilroy Bold',
                }}
              >
                {this.props.appliedJobData && this.props.appliedJobData.totalCount} Candidates
              </p>
            </Col>
          </Row>
          {this.props.appliedJobData && this.props.appliedJobData.data && this.props.appliedJobData.data?.map((val, index) => (
            <Row className={"resume-card-2 mt-3 p-0"} key={index}>
              <div className="col-sm-2 all-applied-resume-card-1">
                {val.candidate.image === "" ? (
                  <img className="w-100 rounded-circle" src={userDefaultPic} style={{objectFit : 'cover'}}/>
                ) : (
                  <img className="w-100 h-90 rounded-circle" style={{ height: "136px", objectFit : 'cover' }} src={ImageUrl.imageUrlPath + val.candidate.image} />
                )}


              </div>
              <div className="col-sm-10 all-applied-resume-card-7">
                <div className="row">
                  <div className="col-sm-9 all-applied-resume-card-10">
                    <p className="all-applied-resume-card-2"> {val.candidate.name}</p>
                    <p className="all-applied-resume-card-3">{val.candidate.email}</p>
                    <ul className="all-applied-resume-card-8">
                      <li>
                        <a target="_blank" style={{ textDecoration: 'none' }} className="p-0" href={ImageUrl.imageUrlPath + val.candidate.resume}>
                          <p className="all-applied-resume-card-4">
                            <img src={process.env.PUBLIC_URL + "/link.svg"} />
                                        &nbsp;&nbsp;View Resume PDF
                                    </p>
                        </a>
                      </li>
                      <li>
                        <p className="all-applied-resume-card-5">
                          <span className="all-applied-resume-card-6">Applied on:</span>
                                        &nbsp;&nbsp;{moment(val.applied_at).format('DD MMM YYYY')}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-3">
                    <AppliedJobStatus name={val.name} status={val.status} job_id={val._id} />
                  </div>
                </div>
              </div>
            </Row>
          ))}
          {/* {loading ? (
            ''
          ) : (
            <AppliedJobCards
              appliedJobData={this.props.appliedJobData}
            />
          )} */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appliedJobData: state.jobManagementReducer.appliedJobData,
    isloading: state.jobManagementReducer.isloading,
  };
};
export default connect(mapStateToProps)(AppliedJobDetail);
