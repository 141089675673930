import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import styles from './visitProfile.module.css';
import { Row, Col, Collapse } from 'antd';
import { connect } from 'react-redux';
import CompanyIcon from '../../asset/Company-icon.png';
import moment from 'moment';
import { fileUrl } from '../../../Shared/imageUrlPath';
import { CURRENCY } from '../../constants/currency';
const Panel = Collapse.Panel;
class CompanyMedia extends Component {
  state = {
    isOpen: false,
    isActive: [],
  };

  changeOpen = (index) => {
    var isActive = this.state?.isActive;
    if (isActive?.includes(index)) {
      const inn = isActive?.indexOf(index);
      isActive?.splice(inn, 1);
    } else {
      isActive?.push(index);
    }
    this.setState({
      isActive,
    });
  };

  render() {
    let data = this?.props?.data?.data?.data;
    let candidateData = data?.can_experience;
    const lastSalary = data?.can_salary?.lastSalary;
    const minSalary = data?.can_salary?.minSalary;
    const maxSalary = data?.can_salary?.maxSalary;
    const recieveFormat = data?.can_salary?.recieveFormat;

    return (
      <div className={styles.personalInfoParentCOntainer}>
        {candidateData?.length > 0 && (
          <Row style={{ paddingTop: 35 }}>
            <Col span={24}>
              <p className="visit_cand_personal_1">Current Position</p>
            </Col>
          </Row>
        )}
        {candidateData &&
          candidateData
            ?.sort((a, b) => (a.from > b.from ? -1 : 1))
            ?.map((data, index) => (
              <>
                {data.isCurrentlyWorking ? (
                  <>
                    <Row
                      className={`visit_cand_personal_7 ${styles.currentPositionCards}`}
                    >
                      <Col sm={4} xs={8} className={styles.cardsImage}>
                        <img
                          className={styles.currentPositionCardsImage}
                          src={
                            data.experience_image
                              ? fileUrl.fileUrlPath + data.experience_image
                              : CompanyIcon
                          }
                          alt='companyIcon'
                        />
                      </Col>
                      <Col sm={20} xs={16}>
                        <Row>
                          <Col span={24}>
                            <p
                              className={`visit_cand_personal_3 ${styles.visitProfileHeadings}`}
                            >
                              {data.title}
                            </p>
                          </Col>
                          <Col span={24}>
                            <p
                              className={`visit_cand_personal_4 ${styles.visitProfileSubHeadings}`}
                            >
                              {`from ${moment(data.from).format(
                                'MMM YYYY'
                              )} to Present`}
                            </p>
                          </Col>
                          <Col span={24}>
                            <p
                              className={`visit_cand_personal_4 ${styles.visitProfileSubHeadings}`}
                            >
                              {data.company}
                            </p>
                          </Col>
                          <Col span={24}>
                            <p
                              className={`visit_cand_personal_5 ${styles.visitProfileSubHeadings}`}
                            >
                              {data.location}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className={`colapsable ${styles.closableDivs}`}>
                      <Collapse
                        defaultActiveKey={this.state.isOpen}
                        onChange={() => {
                          this.changeOpen(index);
                        }}
                      >
                        <Panel
                          className={`${styles.closableSubDivs} ${styles.closableSubDivsParents}`}
                          header={
                            this.state.isActive.includes(index)
                              ? 'Hide'
                              : 'View More'
                          }
                          key="1"
                        >
                          <Row
                            className={`visit_cand_personal_9 ${styles.closableSubDivs}`}
                          >
                            <Col span={12}>
                              <p className="visit_cand_personal_10">Title</p>
                            </Col>
                            <Col span={12}>
                              <p className="visit_cand_personal_10">
                                Employment type
                              </p>
                            </Col>
                          </Row>
                          <Row className={styles.closableSubDivs}>
                            <Col span={12}>
                              <p
                                className={`visit_cand_personal_11 ${styles.closableDivsTexts}`}
                              >
                                {data.title}
                              </p>
                            </Col>
                            <Col span={12}>
                              <p
                                className={`visit_cand_personal_11 ${styles.closableDivsTexts}`}
                              >
                                {/* {employeeType} */}
                                {data.employmentType}
                              </p>
                            </Col>
                          </Row>
                          <Row
                            className={`visit_cand_personal_9 ${styles.closableSubDivs}`}
                          >
                            <Col span={24}>
                              <p className="visit_cand_personal_10">
                                Description
                              </p>
                            </Col>
                          </Row>
                          <Row className={styles.closableSubDivs}>
                            <Col span={24}>
                              <p
                                className={`visit_cand_personal_11 ${styles.closableDivsTexts}`}
                              >
                                {/* {currentPosition} */}
                                {data.description}
                              </p>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>
            ))}

        {/*********************Professional Experience ********************/}
        {candidateData?.length > 0 && (
          <Row>
            <Col span={24} style={{ marginTop: '30px' }}>
              <p className="visit_cand_personal_6">Professional Experience</p>
            </Col>
          </Row>
        )}

        {candidateData &&
          candidateData
            ?.sort((a, b) => (a.from > b.from ? -1 : 1))
            ?.map((data, index) => (
              <>
                {!data?.isCurrentlyWorking ? (
                  <div style={{ marginBottom: '20px' }}>
                    <Row
                      className={`visit_cand_personal_7 ${styles.currentPositionCards}`}
                    >
                      <Col sm={4} xs={8} className={styles.cardsImage}>
                        <img
                          className={styles.currentPositionCardsImage}
                          src={
                            data?.experience_image
                              ? fileUrl.fileUrlPath + data?.experience_image
                              : CompanyIcon
                          }
                          alt='companyIcon'
                        />
                      </Col>
                      <Col sm={20} xs={16}>
                        <Row>
                          <Col span={24}>
                            <p
                              className={`visit_cand_personal_3 ${styles.visitProfileHeadings}`}
                            >
                              {data?.title} {data?.company}
                            </p>
                          </Col>
                          <Col span={24}>
                            <p
                              className={`visit_cand_personal_4 ${styles.visitProfileSubHeadings}`}
                            >
                              {`from ${moment(data?.from).format(
                                'MMM YYYY'
                              )} to ${moment(data?.to).format('MMM YYYY')}`}
                            </p>
                          </Col>
                          <Col span={24}>
                            <p
                              className={`visit_cand_personal_5 ${styles.visitProfileSubHeadings}`}
                            >
                              {data?.location}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className={`colapsable ${styles.closableDivs}`}>
                      <Collapse
                        defaultActiveKey={this.state.isOpen}
                        onChange={() => {
                          this.changeOpen(index);
                        }}
                      >
                        <Panel
                          className={`${styles.closableSubDivs} ${styles.closableSubDivsParents}`}
                          header={
                            this.state.isActive.includes(index)
                              ? 'Hide'
                              : 'View More'
                          }
                          key="1"
                        >
                          <Row
                            className={`visit_cand_personal_9 ${styles.closableSubDivs}`}
                          >
                            <Col span={12}>
                              <p className="visit_cand_personal_10">Title</p>
                            </Col>
                            <Col span={12}>
                              <p className="visit_cand_personal_10">
                                Employment type
                              </p>
                            </Col>
                          </Row>
                          <Row className={styles.closableSubDivs}>
                            <Col span={12}>
                              <p
                                className={`visit_cand_personal_11 ${styles.closableDivsTexts}`}
                              >
                                {data?.title}
                              </p>
                            </Col>
                            <Col span={12}>
                              <p
                                className={`visit_cand_personal_11 ${styles.closableDivsTexts}`}
                              >
                                {data?.employmentType}
                              </p>
                            </Col>
                          </Row>
                          <Row
                            className={`visit_cand_personal_9 ${styles.closableSubDivs}`}
                          >
                            <Col span={24}>
                              <p className="visit_cand_personal_10">
                                Description
                              </p>
                            </Col>
                          </Row>
                          <Row className={styles.closableSubDivs}>
                            <Col span={24}>
                              <p
                                className={`visit_cand_personal_11 ${styles.closableDivsTexts}`}
                              >
                                {/* {currentPosition} */}
                                {data?.description}
                              </p>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </>
            ))}
        <div>
          <Row>
            <Col span={24}>
              <p className="visit_cand_personal_13">Salary</p>
            </Col>
          </Row>
          <Row>
            <Col sm={8} xs={12}>
              <p className="visit_cand_personal_15">Minimum Salary</p>
            </Col>

            <Col sm={5} xs={12}>
              <p className="visit_cand_personal_15">Maximum Salary</p>
            </Col>
          </Row>
          <Row>
            <Col sm={8} xs={12}>
              <p className="visit_cand_personal_14">
                {CURRENCY.filter(
                  (values) => values.name === minSalary?.currency
                ).length > 0 &&
                  CURRENCY.filter(
                    (values) => values.name === minSalary?.currency
                  )[0].symbol}
                {`${minSalary?.amount} ${recieveFormat}`}
              </p>
            </Col>
            <Col sm={5} xs={12}>
              <p className="visit_cand_personal_14">
                {CURRENCY.filter(
                  (values) => values.name === maxSalary?.currency
                ).length > 0 &&
                  CURRENCY.filter(
                    (values) => values.name === maxSalary?.currency
                  )[0].symbol}

                {`${maxSalary?.amount} ${recieveFormat}`}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p className="visit_cand_personal_15">Last Salary</p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p className="visit_cand_personal_14">
                {CURRENCY.filter(
                  (values) => values.name === lastSalary?.currency
                ).length > 0 &&
                  CURRENCY.filter(
                    (values) => values.name === lastSalary?.currency
                  )[0].symbol}
                {`${lastSalary?.amount} ${recieveFormat}`}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.companyInfoReducer.data,
    isloading: state.companyInfoReducer.isloading,
  };
};
export default connect(mapStateToProps)(CompanyMedia);
