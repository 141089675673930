import React, { Component } from 'react';
import { Row, Col, Card, message, Spin, Tooltip } from 'antd';

import Pagination from 'react-js-pagination';
import APIManager from '../../../APIManager';
import '../Services/ServiceListing.css';
import './event_details.css';
import { fileUrl } from '../../../Shared/imageUrlPath';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import cardDefaultPic from '../../../Components/asset/card.svg';
import NoData from '../../../PageNotFound/NoData';

class NewsListingCard extends Component {
  state = {
    data: null,
    resultsPerPage: 10,
    totalData: null,
    totalPages: null,
    currentPage: null,
    id: '',
    loading: false,
  };
  componentDidMount() {
    if (
      !(
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.news_name
      )
    ) {
      this.makeHttpRequestWithPage();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchingValue !== prevProps.searchingValue) {
      this.makeHttpRequestWithPage();
    }
    if (this.props.company_name !== prevProps.company_name) {
      this.makeHttpRequestWithPage();
    }
    if (this.props.current_location?.length > 0 && this.props.current_location !== prevProps.current_location) {
      this.makeHttpRequestWithPage();
    }
  }
  makeHttpRequestWithPage = async (pageNumber = 1) => {
    const searchingValue = this.props.searchingValue;
    const company = this.props.company_name;
    let searchComp = '';
    if (company.length > 0) {
      company?.map((val) => {
        searchComp = searchComp + `&companyId=${val}`;
      });
    }
    let country = '';
    let state = '';
    let city = '';
    if (this.props.current_location.length > 0) {
      const locationArray = this.props.current_location.split(',');
      if (locationArray.length > 0) {
        country = locationArray[locationArray.length - 1];
        state =
          locationArray.length > 1
            ? locationArray[locationArray.length - 2]
            : '';
        city = locationArray.length > 2 ? locationArray[0] : '';
      }
    }
    this.setState({ loading: true });
    APIManager.allNewsListSortAndSearch(
      pageNumber,
      searchingValue,
      searchComp,
      city,
      state,
      country,
      this.props.homeSearch,
      this.props.searchLocation
    )
      .then((resp) => {
        this.setState({
          data: resp.data.data,
          total: resp.data.totalCount,
          totalData: resp.data.totalCount,
          currentPage: resp.data.currentPage,
          loading: false,
        });
      })
      .catch((err) => {
        message.error(err);
      });
  };

  render() {
    let renderPageNumbers;
    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / 12); i++) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers?.map((number) => {
        // let classes = this.state.currentPage === number ? .active : '';
        return (
          <span
            key={number}
            onClick={() => this.makeHttpRequestWithPage(number)}
            className={
              this.state.current_page === number
                ? 'page-number-btn'
                : 'pagination-container'
            }
          >
            {number}
          </span>
        );
      });
    }
    const { searchQuery } = this.props;
    return (
      <>
        <Row justify="center" style={{ paddingLeft: '7%', paddingRight: '7%' }}>
          {this.state.loading && (
            <div style={{ margin: 'auto' }}>
              <Spin />
            </div>
          )}
          {!this.state.loading &&
            this.state.data !== null &&
            this.state.data.length === 0 ? (
            <div className={'d-flex justify-content-center w-100'}>
              <NoData text={"Please try using Different Location, Filters or keywords for your search."} />
            </div>
          ) : (
            !this.state.loading &&
            this.state.data &&
            this.state.data?.map((data) => (
              <Row>
                <Col  
                  xs={10}
                  sm={8}
                  md={6} className={'p-2 m-3'}>
                  <Card
                    hoverable
                    onClick={() => {
                      var ls = require('local-storage');
                      ls.set('newsId', data._id);
                      {
                        const win = window.open(`/news/detail/${data._id}`, "_blank");
                        win.focus();
                      }
                    }}
                    style={{
                      width: 240,
                      height: 'auto',
                      marginBottom: 30,
                      background: '#FFFFFF 0% 0% no-repeat padding-box',
                      border: '2px solid #E6E8EE',
                      borderRadius: '7px',
                      opacity: 1,
                    }}
                    bodyStyle={{ paddingBottom: 0, paddingTop: 18 }}
                    cover={
                      <img
                        alt="example"
                        src={
                          (data.image?.filter((data) => data.fileType.includes('image'))?.length>0)
                            ? fileUrl.fileUrlPath + data.image.filter((data) => data.fileType.includes('image'))[0].fileName
                            : cardDefaultPic
                        }
                        style={{
                          height: '165px',
                          width: '100%',
                          borderTopLeftRadius: '7px',
                          borderTopRightRadius: '7px',
                          objectFit: 'cover',
                        }}
                      />
                    }
                  >
                    <p className="card-heading-job-detail-scroller-1 event-name" style={{paddingBottom:'2px'}}>
                      <Tooltip placement="topLeft" color="#000000" title={(data.title).toUpperCase()}>
                        {(data.title).toUpperCase()}
                      </Tooltip>
                    </p>
                    <span
                            className="partner-text-span-1"
                            style={{ fontSize: '14px' ,paddingTop:'0px' }}
                          >
							                {data?.companyData?.comp_info?.bus_name
                              .charAt(0)
                              .toUpperCase() +
                              data?.companyData?.comp_info?.bus_name.slice(1)}
                </span>
                    <p
                      className=""
                      style={{
                        fontSize: 15,
                        fontFamily: 'Gilroy-SemiBold',
                        paddingBottom: 8,
                        color: '#6F727A',
                      }}
                    >
                      <img
                        alt="example"
                        src={process.env.PUBLIC_URL + '/clock_copy_3.svg'}
                      />
                      &nbsp;&nbsp;
                      <span>{data.avgReadTime} read</span>
                    </p>
                    <p
                      className=""
                      style={{
                        fontSize: 13,
                        fontFamily: 'Gilroy-SemiBold',
                        color: '#6F727A',
                      }}
                    >
                      {moment(data.updatedAt).format('DD MMM YYYY')}
                    </p>
                  </Card>
                </Col>
              </Row>
            ))
          )}
        </Row>
        {this.state.data !== null && this.state.data.length === 0 ? (
          <div></div>
        ) : (
          !this.state.loading &&
          this.state.data && (
            <Row justify="space-around">
              <div className={'news-paginate-container'}>
                <Pagination
                  activePage={this.state.currentPage}
                  itemsCountPerPage={12}
                  totalItemsCount={this.state.totalData}
                  firstPageText={false}
                  lastPageText={false}
                  pageRangeDisplayed={5}
                  hideDisabled={true}
                  onChange={(pageNumber) => {
                    this.makeHttpRequestWithPage(pageNumber);
                  }}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </Row>
          )
        )}
      </>
    );
  }
}
export default withRouter(NewsListingCard);