import React, { Component } from 'react';
import { message, Spin, Card, Row, Col , Tooltip } from 'antd';
import { ImageUrl } from '../../Shared/imageUrlPath';
import APIManager from '../../APIManager';
import { connect } from 'react-redux';
import { DeleteFilled } from '@ant-design/icons';
import moment from 'moment';
import ApplicationStatus from '../app_status/application_status';
import jobCard from '../asset/card.svg';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import NoData from '../../PageNotFound/NoData';
import ConfirmPopUp from '../constants/ConfirmPopup.js';
import styles from './appliedjobs.module.css';
import MenuDropDown from './MenuDropDown.jsx';

class AppliedJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isloading: false,
      total: '',
      total_pages: '',
      current_page: '',
      deleteJobId: '',
      confirmDeletePopup: false,
    };
  }

  componentDidMount() {
    this.setState({ isloading: true });
    APIManager.appliedCandidateJobs(1).then((response) => {
      if (response.status === 200) {
        this.setState({
          data: response.data.data,
          isloading: false,
          total: response.data.totalCount,
          total_pages: response.data.totalPages,
          current_page: parseInt(response.data.currentPage),
        });
      }
    });
  }
  hideJob(id) {
    this.setState({ confirmDeletePopup: false });
    APIManager.hideAppliedJob(id)
      .then((response) => {
        if (response.data.status === 'success') {
          message.info('Job deleted successfully');
          window.location.reload();
        }
      })
      .catch((err) => message.error('Something went wrong.'));
  }
  // downloadResume = (resume) => {
  //   const resumeLink = "https://sendbizbucket.s3.eu-west-3.amazonaws.com/candidate/profile/CandidateProfile-606ff33cc1b7ce4e7a100bb7-1621437141412";
  //   // Create blob link to download
  //   const url = window.URL.createObjectURL(
  //     new Blob([resumeLink]),
  //   );
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute(
  //     'download',
  //     `${resume}.pdf`,
  //   );

  //   // Append to html link element page
  //   document.body.appendChild(link);

  //   // Start download
  //   link.click();

  //   // Clean up and remove the link
  //   link.parentNode.removeChild(link);
  // }
  makeHttpRequestWithPage = async (pageNumber) => {
    this.setState({
      isloading: true,
    });
    APIManager.appliedCandidateJobs(pageNumber)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            data: response.data.data,
            isloading: false,
            total: response.data.totalCount,
            total_pages: response.data.totalPages,
            current_page: parseInt(response.data.currentPage),
          });
        }
      })
      .catch((err) => {
        this.setState({ isloading: false });
        message.error('Something went wrong!');
      });
  };

  render() {
    let loader = this.state.isloading;
    let applied_job = [];
    if (this.state.data !== undefined && this.state.data !== null) {
      applied_job = this.state.data;
    }

    let renderPageNumbers;

    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / 10); i++) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers?.map((number) => {
        // let classes = this.state.currentPage === number ? .active : '';
        return (
          <span
            key={number}
            onClick={() => this.makeHttpRequestWithPage(number)}
            className={
              this.state.current_page == number
                ? 'page-number-btn'
                : 'pagination-container'
            }
          >
            {number}
          </span>
        );
      });
    }

    return (
      <>
        <Row>
          <Col xs={24} sm={0} style={{ marginBottom: '20px' }}>
            <MenuDropDown name={'Applied Jobs'} />
          </Col>
        </Row>
        <Row className={styles.mainContainer}>
          <h5 className={`candidate_heading ${styles.candidateHeading}`}>
            Applied Jobs
          </h5>
          {/*=========== popup for delete alert ===============*/}
          {this.state.confirmDeletePopup && (
            <ConfirmPopUp
              message="Are you sure you want to delete job"
              handleOk={() => this.hideJob(this.state.deleteJobId)}
              closePopUp={() => this.setState({ confirmDeletePopup: false })}
            />
          )}
          {loader ? (
            <Spin className={'d-flex justify-content-center pt-3'} />
          ) : (
            <>
              {applied_job.length === 0 ? (
                <NoData />
              ) : (
                <>
                  {applied_job?.map((val, index) => (
                    <>
                      <Card
                        className={`services-card w-100 ${styles.serviceCardsParents}`}
                        key={index}
                      >
                        <Row
                          className={`service-detail-row ${styles.serviceCardsParentslayer2}`}
                        >
                          <Col span={4}>
                            <img
                              className={styles.cardsImage}
                              alt="example"
                              src={
                                val.applied_for_job.job_logo
                                  ? ImageUrl.imageUrlPath +
                                    val.applied_for_job.job_logo
                                  : val.companyData.logo.name
                                  ? ImageUrl.imageUrlPath +
                                    val.companyData.logo.name
                                  : jobCard
                              }
                            />
                          </Col>
                          <Col
                            span={15}
                            style={{
                              paddingLeft: '10px',
                            }}
                          >
                            <Row justify="space-between">
                              <Col sm={17} xs={24}>
                                <Tooltip title={val.applied_for_job.title}>
                                  <div
                                    className="service-name"
                                    style={{
                                      fontSize: '1.5rem',
                                      marginBottom: '3px',
                                    }}
                                    onClick={() =>
                                      this.props.history.push(
                                        `/apply-for/${val.applied_for_job._id}`
                                      )
                                    }
                                  >
                                    {val.applied_for_job.title.length > 40
                                      ? val.applied_for_job.title.substr(
                                          0,
                                          39
                                        ) + '...'
                                      : val.applied_for_job.title}
                                  </div>
                                </Tooltip>
                                <p
                                  className="favourite-para-1 m-0"
                                  style={{
                                    textTransform: 'uppercase',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {val.companyData.comp_info.bus_name}
                                </p>
                              </Col>
                              <Col sm={7} xs={0} className="icon-col"></Col>
                            </Row>
                            <Row>
                              <Col
                                span={18}
                                xs={24}
                                style={{
                                  fontSize: '1.2rem',
                                  marginTop: '1px',
                                }}
                              >
                                <Row className={styles.locationSection}>
                                  <Col sm={1} xs={2}>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        '/location-red.png'
                                      }
                                      style={{ width: 14, marginTop: '15px' }}
                                    />
                                  </Col>
                                  <Col
                                    sm={22}
                                    xs={22}
                                    className="service-s-desc"
                                    style={{
                                      verticalAlign: 'middle',
                                      fontSize: '1rem',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {val.companyData.contact_info.city},{' '}
                                    {val.companyData.contact_info.country}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row align="bottom" className={styles.thirdRowCard}>
                              {val.location === null || val.location === ' ' ? (
                                ''
                              ) : (
                                <Col
                                  sm={8}
                                  xs={24}
                                  style={{ cursor: 'pointer' }}
                                  className={styles.cardsResume}
                                >
                                  <a
                                    href={`${ImageUrl.imageUrlPath}${val.candidate.resume}`}
                                    target="_blank"
                                    download
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        '/page-check-red.png'
                                      }
                                      className={`service-icons ${styles.serviceIcon}`}
                                    />
                                    <div
                                      className="service-location"
                                      style={{ fontSize: '1rem', color: 'red' }}
                                    >
                                      Resume
                                    </div>
                                  </a>
                                </Col>
                              )}
                              {/* <Col sm={8} xs={24}>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + '/countdown.png'
                                  }
                                  className={`service-icons ${styles.serviceIcon}`}
                                />
                                <div className="service-location">
                                  {moment(
                                    val.applied_for_job ? val.updatedAt : ''
                                  ).fromNow('DD MMM YY')}
                                </div>
                              </Col> */}
                              <Col sm={8} xs={24}>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + '/clock-red.png'
                                  }
                                  className={`service-icons ${styles.serviceIcon}`}
                                />
                                <div className="service-location">
                                  {moment(val.applied_at).format('YYYY/MM/DD')}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={4}>
                            <ApplicationStatus status={val.status} />
                          </Col>
                          <Col
                            span={1}
                            style={{
                              textAlign: 'right',
                            }}
                          >
                            <DeleteFilled
                              style={{ fontSize: '1.2rem', color: 'red' }}
                              onClick={() =>
                                this.setState({
                                  deleteJobId: val._id,
                                  confirmDeletePopup: true,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </Card>
                    </>
                  ))}
                </>
              )}
              {/*================ pagination section==============*/}
              {applied_job.length > 0 ? (
                <Row style={{ width: '100%' }}>
                  <Col
                    span={24}
                    className={'d-flex justify-content-center pt-3'}
                  >
                    <span
                      className={
                        this.state.current_page == 1
                          ? 'pagination-side-btn'
                          : ''
                      }
                      onClick={() =>
                        this.state.current_page != 1
                          ? this.makeHttpRequestWithPage(
                              this.state.current_page - 1
                            )
                          : ''
                      }
                    >
                      {' '}
                      <LeftOutlined />{' '}
                    </span>
                    <div>{renderPageNumbers}</div>
                    <span
                      className={
                        this.state.current_page == this.state.total_pages
                          ? 'pagination-side-btn'
                          : ''
                      }
                      onClick={() =>
                        this.state.current_page != this.state.total_pages
                          ? this.makeHttpRequestWithPage(
                              this.state.current_page + 1
                            )
                          : ''
                      }
                    >
                      <RightOutlined />
                    </span>
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.candidateInfoSubmitReducer.job_data,
  isloading: state.candidateInfoSubmitReducer.isLoading,
});

export default connect(mapStateToProps)(AppliedJobs);
