import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Button, message } from 'antd';
import { Row, Col, Input, Form } from 'antd';
import styles from './edit_company_profile.module.css';
import CustomMap from '../candidate/map';
import GoogleSearch from '../candidate/googlesearch';
import CurrentMapLocation from '../../Routes/test';
import APIManager from '../../APIManager';
import { dispatchAction } from '../../Redux/Store/index';
import { updateUser } from '../../Redux/Actions/AuthAction';

class ContactInfo extends Component {
  state = {
    current_location: '',
    lat: null,
    long: null,
    checkToggle: false,
  };
  componentDidMount() {
    if (this.props.initialdata !== null) {
      this.setState({
        current_location: this.props.initialdata.sub_address,
        lat: this.props?.initialdata?.latitude,
        long: this.props?.initialdata?.longitude,
      });
    }
  }
  // onSubmit = (e) => {
  //   if (this.props.initialdata.sub_address !== this.state.current_location) {
  //     if (
  //       this.props.initialdata.latitude !== this.state.lat &&
  //       this.props.initialdata.longitude !== this.state.long
  //     ) {
  //       e['latitude'] = this.state.lat;
  //       e['longitude'] = this.state.long;
  //     } else {
  //       e['latitude'] = '';
  //       e['longitude'] = '';
  //     }
  //   } else {
  //     e['latitude'] = this.state.lat;
  //     e['longitude'] = this.state.long;
  //   }
  //   e['sub_address'] = this.state.current_location;
  //   if (this.state.current_location.length > 0) {
  //     const locationArray = this.state.current_location.split(',');
  //     if (locationArray.length > 0) {
  //       e['country'] = locationArray[locationArray.length - 1].trim();
  //       e['state'] =
  //         locationArray.length > 1
  //           ? locationArray[locationArray.length - 2].trim()
  //           : '';
  //       e['city'] = locationArray.length > 2 ? locationArray[0].trim() : '';
  //     }
  //   }
  //   this.props.setdata(e);
  // };
  // contactInfoSubmit = () => {
  //   formButton.click();
  // };
  takecurrentLocation = (value) => {
    this.setState({ current_location: value });
  };
  takeLatLong = (lat, long) => {
    this.setState({
      lat: lat,
      long: long,
    });
  };
  onKeyPress = (event) => {
    return (
      (event.charCode >= 65 && event.charCode <= 90) ||
      (event.charCode >= 97 && event.charCode <= 122)
    );
  };

  checkToggleFunc = (checkToggle) => {
    this.setState({
      checkToggle: checkToggle,
    });
  };
  saveUserData = async (values) => {
    try {
      let data1 = {};
      values.sub_address = this.state.current_location;
      let locationArray = this.state.current_location?.split(',')?.reverse();
      values.latitude = this.state.lat;
      values.longitude = this.state.long;
      if (locationArray.length > 0) {
        values.country = locationArray[0]?.trim();
        if (locationArray.length > 1) values.state = locationArray[1]?.trim();
        if (locationArray.length > 2) values.city = locationArray[2]?.trim();
      }
      data1['contact_info'] = values;
      const response = await APIManager.companyInfoSubmit(data1);
      var ls = require('local-storage');
      let old_data = ls.get('persist:root');
      if (old_data !== undefined) {
        old_data = JSON.parse(old_data.AuthReducer);
        old_data.user.data.data = response.data.data;
        let new_data = {};
        new_data = old_data.user;
        dispatchAction(updateUser(new_data));
      }
      message.success('Contact info updated successfully.');
    } catch {
      message.error('Error updating user profile.');
    }
  };
  render() {
    return (
      <>
        <Form
          onFinish={this.saveUserData}
          className={styles.contactInfoTab}
          initialValues={this.props.initialdata}
          layout="vertical"
        >
          <Row gutter={24}>
            <Col sm={12} xs={24}>
              <Form.Item
                name="phone_no"
                label={
                  <label className="input_label_profile">Phone Number</label>
                }
                // rules={[
                //   {
                //     required: true,
                //     message: 'Required Field!',
                //     whitespace: true,
                //   },
                // ]}
                type="text"
              >
                <Input
                  className="input-field-custom-type-1-left"
                  type="text"
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item
                name="land_no"
                label={
                  <label className="input_label_profile">Landline Number</label>
                }
                // rules={[
                //   {
                //     required: true,
                //     message: 'Required Field!',
                //     whitespace: true,
                //   },
                // ]}
                type="text"
              >
                <Input
                  className="input-field-custom-type-1-left"
                  type="text"
                  maxLength={10}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={12} xs={24}>
              <Form.Item
                name="email"
                label={<label className="input_label_profile"> Email ID</label>}
                rules={[
                  {
                    required: true,
                    message: 'Required Field!',
                    whitespace: true,
                  },
                ]}
                type="email"
              >
                <Input
                  className="input-field-custom-type-1-left"
                  type="email"
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item
                name="website"
                label={<label className="input_label_profile">Website</label>}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Required Field!',
                //     whitespace: true,
                //   },
                // ]}
                type="text"
              >
                <Input className="input-field-custom-type-1-left" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col sm={12} xs={24}>
              <Form.Item
                name="sub_address"
                label={<label className="input_label_profile">Address</label>}
                type="text"
              >
                <GoogleSearch
                  className={`input-field-custom-type-1-left ${styles.inputBoxW100}`}
                  takecurrentLocation={this.takecurrentLocation}
                  takeLatLong={this.takeLatLong}
                  name="city"
                  checkToggleFunc={this.checkToggleFunc}
                  checkToggle={this.state?.checkToggle}
                />
              </Form.Item>
              {this.state?.lat &&
              this.state?.long &&
              this.state?.checkToggle ? (
                <div className={`form-group mb-signup-1 ${styles.locationBox}`}>
                  <CurrentMapLocation
                    lat={this.state.lat}
                    long={this.state.long}
                    takeLatLong={this.takeLatLong}
                  />
                </div>
              ) : (
                ''
              )}
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item
                name="zip_code"
                label={<label className="input_label_profile">Zip Code</label>}
                rules={[
                  {
                    required: true,
                    message: 'Required Field!',
                    whitespace: true,
                  },
                ]}
                type="text"
              >
                <Input
                  className="input-field-custom-type-1-left"
                  maxLength={6}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {!this.state.checkToggle && (
              <Col xs={24} style={{ marginTop: 15, minHeight: '270px' }}>
                <CustomMap lat={this.state.lat} long={this.state.long} />
              </Col>
            )}
            <Col xs={24} className={styles.buttonCol}>
              <Button className={styles.button} htmlType="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

export default ContactInfo;
