import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Spin, Card } from 'antd';
import userDefaultPic from '../../asset/card.svg';
import APIManager from '../../../APIManager';
import NoDataFound from '../../../PageNotFound/NoData';
import { fileUrl } from '../../../Shared/imageUrlPath';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import styles from './offers_sent.module.css';
import CompanyMenuDropDown from '../CompanyMenuDropDown';

const Offers_sent = (props) => {
  useEffect(() => {
    loadData('', 1);
  }, []);
  const loadData = (searchKeyword = '', pageNumber) => {
    APIManager.offersSentList(searchKeyword.toLowerCase(), pageNumber)
      .then((res) => {
        setLoading(false);
        setOffers(res.data.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);

  const handleChange = (e) => {
    setLoading(true);
    loadData(e.target.value);
  };
  return (
    <>
      <Row>
        <Col xs={24} md={0} style={{ marginBottom: '10px' }}>
          <CompanyMenuDropDown name={'Offers Sent'} />
        </Col>
      </Row>
      <Row>
        <Col xs={0} sm={24} className="resume-1">
          Offers Sent
        </Col>
      </Row>
      <Row className="s-search-bar-updated" justify="space-between">
        <Col xs={24} sm={10} className={styles.searchCol}>
          <Input
            className={`company-joblist-input jobListingcls-1 ${styles.search}`}
            prefix={<SearchOutlined />}
            type="text"
            placeholder="Search sent offers"
            onChange={handleChange}
          />
        </Col>
        <Col xs={0} sm={14}></Col>
      </Row>
      {loading ? (
        <Spin />
      ) : (
        <>
          {offers.length === 0 ? (
            <NoDataFound />
          ) : (
            <Row className={styles.mainCol}>
              {offers?.map((offer) => (
                <Card className={styles.card}>
                  <Row
                    gutter={15}
                    key={offer._id.company}
                    onClick={() =>
                      props.history.push({
                        pathname: '/offer-details',
                        state: {
                          jobId: offer._id.job,
                          companyId: offer._id.company,
                          offer: offer,
                        },
                      })
                    }
                  >
                    <Col xs={5} sm={4}>
                      <img
                        src={
                          offer.job &&
                          offer.job.addedByCompany[0]?.logo?.name !== ''
                            ? fileUrl.fileUrlPath +
                              offer.job.addedByCompany[0]?.logo?.name
                            : userDefaultPic
                        }
                        className={styles.image}
                      />
                    </Col>
                    <Col xs={19} sm={20} style={{ cursor: 'pointer' }}>
                      <Col xs={24} sm={24}>
                        <Col xs={24} sm={24} className={styles.title}>
                          <span>
                            {offer.job.title.charAt(0).toUpperCase() +
                              offer.job.title.slice(1)}
                          </span>
                          <span className={styles.appliedCount}>
                            <img
                              src={process.env.PUBLIC_URL + '/user.png'}
                              alt=""
                            />{' '}
                            {offer.count}
                          </span>
                        </Col>
                        <Col xs={24} sm={24}>
                          <NavLink
                            to={`comp-id${offer.job.addedByCompany[0]._id}`}
                            target="_blank"
                            className={`job-card-4`}
                            style={{ marginLeft: '0px', padding: '0px', color: '#000' }}
                          >
                            {offer.job.addedByCompany.length
                              ? offer.job.addedByCompany[0].comp_info.bus_name ?
                              offer.job.addedByCompany[0].comp_info.bus_name
                                  .charAt(0)
                                  .toUpperCase() +
                                offer.job.addedByCompany[0].comp_info.comp_name.slice(
                                  1
                                ) :
                                offer.job.addedByCompany[0].comp_info.comp_name
                                .charAt(0)
                                .toUpperCase() +
                              offer.job.addedByCompany[0].comp_info.comp_name.slice(
                                1
                              ) 
                              : ''}
                          </NavLink>
                        </Col>
                        <Col xs={24} sm={24}>
                          <span>
                            <img
                              src={process.env.PUBLIC_URL + '/location-red.png'}
                            />
                          </span>
                          <span className={styles.location}>
                            {`${
                              offer.job.addedByCompany[0].contact_info.city !==
                              ''
                                ? offer.job.addedByCompany[0].contact_info
                                    .city + ', '
                                : ''
                            }`}
                            {`${
                              offer.job.addedByCompany[0].contact_info.state !==
                              ''
                                ? offer.job.addedByCompany[0].contact_info
                                    .state + ', '
                                : ''
                            }`}
                            {`${
                              offer.job.addedByCompany[0].contact_info
                                .country !== ''
                                ? offer.job.addedByCompany[0].contact_info
                                    .country
                                : ''
                            }`}
                          </span>
                        </Col>
                        <Col xs={24} sm={24}>
                          <span>
                            <img
                              src={process.env.PUBLIC_URL + '/clock-red.png'}
                            />
                          </span>
                          <span className={styles.location}>
                            {moment(
                              offer.appliedAt ? offer.appliedAt : ''
                            ).format('YYYY/MM/DD')}
                          </span>
                        </Col>
                      </Col>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default Offers_sent;
