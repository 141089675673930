/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import './Subcard.css';
import { Row, Col, Switch, Spin, Card, Tag, message, Tooltip } from 'antd';
import '../jobCards.css';
import cardDefaultPic from '../../../Components/asset/card.svg';
import { useHistory, withRouter } from 'react-router-dom';
import APIManager from '../../../APIManager';
import PromoteJob from '../../../Components/company/promoteEntity/index';
import NoDataFound from '../../../PageNotFound/NoData';
import checkPremiumDetails from '../checkPremiumDetail';
import { fileUrl } from '../../../Shared/imageUrlPath';
import styles from './subcontractorList.module.css';
import {
  DeleteFilled,
  EditFilled,
  NotificationFilled,
  StopOutlined,
} from '@ant-design/icons';

const Subcard = (props) => {
  const history = useHistory();
  const { data, reload } = props;
  const [loading, setLoading] = useState(false);
  const [stopSponsorJobId, setStopSponsorJobId] = useState('');
  const [confirmStopSponsorPopup, setConfirmStopSponsorPopup] = useState(false);
  const [checked, isChecked] = useState([]);
  const [actionIcon, setActionIcon] = useState(null);
  const [confirmDeletePopUp, setConfirmDeleteProp] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [promote, setPromote] = useState({ jobId: '', job: false });

  const deleteJob = (id) => {
    APIManager.deleteSubcontractorjob(deleteId).then((response) => {
      reload();
    });
    setConfirmDeleteProp(false);
  };
  useEffect(() => {
    isChecked(data?.map((item) => item?.isActivated));
  }, [data?.length]);

  const confirmStopSponsor = () => {
    APIManager.stopSponsor(stopSponsorJobId, 'job').then((response) => {
      reload();
    });
    setConfirmStopSponsorPopup(false);
  };
  const showPopUp = (id) => {
    setDeleteId(id);
    setConfirmDeleteProp(true);
  };

  const handleActiveJobStatus = async (jobSwitchId, current, key) => {
    if (!checked[key]) {
      const flag = await checkPremiumDetails('jobs', history);
      if (!flag) {
        return;
      }
    }
    isChecked([...checked?.map((_, i) => (i === key ? current : _))]);
    const id = jobSwitchId;
    APIManager.changeJobActiveStatus(id)
      .then((resp) => {
        message.info('updated.');
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const ConfirmPopUp = (props) => {
    const { message, closePopUp, handleOk } = props;

    return (
      <>
        <div
          style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: '#24262B66',
            backdropFilter: 'blur(6px)',
            position: 'fixed',
            left: '0',
            top: '0',
            zIndex: '100',
          }}
        >
          <div
            style={{
              width: '30%',
              height: '15vw',
              backgroundColor: 'white',
              borderRadius: '10px',
              opacity: '1',
              margin: '10vw 0 0 35vw',
            }}
          >
            <div
              style={{ height: '2vw', paddingLeft: '27vw', paddingTop: '1vw' }}
            >
              <img src="../../cancel-circle.png" onClick={closePopUp}></img>
            </div>
            <div style={{ margin: '2vw 0 0 3vw', position: 'relative' }}>
              <img src="./delete-icon.png" />
              <span
                style={{
                  fontSize: '1.5vw',
                  fontWeight: '900',
                  marginLeft: '10px',
                }}
              >
                {message}
              </span>
            </div>
            <div>
              <button
                style={{
                  margin: '10% 0 0 10%',
                  width: '20%',
                  backgroundColor: '#EE5050',
                  color: '#ffffff',
                  borderRadius: '5px',
                  border: 'none',
                }}
                onClick={closePopUp}
              >
                No
              </button>
              <button
                style={{
                  margin: '10% 0 0 10%',
                  width: '20%',
                  backgroundColor: '#ffffff',
                  color: '#EE5050',
                  borderRadius: '5px',
                  border: '2px solid #EE5050',
                }}
                onClick={handleOk}
              >
                yes
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {confirmStopSponsorPopup && (
        <ConfirmPopUp
          message="Are you sure you want to Stop subcontractor Sponsorisation"
          handleOk={confirmStopSponsor}
          closePopUp={() => setConfirmStopSponsorPopup(false)}
        />
      )}
      {confirmDeletePopUp && (
        <ConfirmPopUp
          message="Are you sure you want to delete job"
          handleOk={deleteJob}
          closePopUp={() => setConfirmDeleteProp(false)}
        />
      )}
      {promote.job && (
        <PromoteJob
          show={promote.job}
          sponser={true}
          onHide={() => setPromote({ job: false })}
          name={'subcontractor'}
          entity={promote.jobId}
          reloadProducts={() => {
            reload();
          }}
        />
      )}
      {loading ? (
        <div className={'d-flex justify-content-center'}>
          <Spin />
        </div>
      ) : !data.length ? (
        <div style={{ width: '100%' }}>
          <NoDataFound />
        </div>
      ) : (
        data?.map((data, key) => (
          <Card className={styles.card} key={data._id}>
            <Row gutter={15}>
              <Col xs={5} sm={4}>
                <img
                  alt="example"
                  src={
                    data.job_logo
                      ? `https://sendbizbucket.s3.eu-west-3.amazonaws.com/${data.job_logo}`
                      : data.defaultLogo.length
                      ? fileUrl.fileUrlPath + data.defaultLogo
                      : cardDefaultPic
                  }
                  className={styles.image}
                />
              </Col>
              <Col xs={18} sm={20}>
                <Row className="service-detail-row">
                  <Col xs={20} sm={18}>
                    <div
                      className="service-name"
                      onClick={() => history.push(`/apply-for/${data._id}`)}
                    >
                      {data.title.length > 20 ? (
                        <Tooltip title={data.title}>
                          {data.title.substr(0, 19) + '...'}
                        </Tooltip>
                      ) : (
                        data.title
                      )}
                      <Tag
                        color="volcano"
                        className={`s-price-tag custom-tag-hover ${styles.tag}`}
                        style={{
                          marginLeft: '25px',
                          verticalAlign: 'bottom',
                          maxWidth: '100%',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push({
                            pathname: '/subcontractor-received-details',
                            search: `?jobid=${data.id}&companyid=${data.companyApplied[0]}`,
                          });
                        }}
                      >
                        {data.noOf_applied > 0
                          ? data.noOf_applied + ' APPLIED'
                          : 'NONE APPLIED'}
                      </Tag>
                    </div>
                  </Col>
                  <Col xs={4} sm={6}>
                    <Row>
                      <Col xs={0} sm={6}>
                        <EditFilled
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            history?.push({
                              state: { data, editJob: true },
                              pathname: '/update-subcontractor-job',
                            })
                          }
                          className="service-hidden-icons service-icons"
                        />
                      </Col>
                      <Col xs={0} sm={6}>
                        {!data.isSponsored ? (
                          <NotificationFilled
                            onClick={() => {
                              setPromote({ jobId: data._id, job: true });
                            }}
                            className="service-hidden-icons service-icons"
                          />
                        ) : (
                          <Tooltip title="Stop Sponsorisation">
                            <StopOutlined
                              onClick={() => {
                                setStopSponsorJobId(data._id);
                                setConfirmStopSponsorPopup(true);
                              }}
                              className="service-hidden-icons service-icons"
                              style={{ color: 'red', fontSize: '1.2rem' }}
                            />
                          </Tooltip>
                        )}
                      </Col>
                      <Col xs={0} sm={6}>
                        <DeleteFilled
                          onClick={() => {
                            showPopUp(data.id);
                          }}
                          className="service-hidden-icons service-icons"
                        />
                      </Col>
                      <Col xs={6} sm={6}>
                        <Switch
                          checked={checked[key]}
                          defaultChecked={data.isActivated}
                          onChange={(current) => {
                            handleActiveJobStatus(data._id, current, key);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={0}>
                    <Tag
                      color="volcano"
                      className={`s-price-tag custom-tag-hover`}
                      style={{
                        verticalAlign: 'bottom',
                        maxWidth: '100%',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push({
                          pathname: '/subcontractor-received-details',
                          search: `?jobid=${data.id}&companyid=${data.companyApplied[0]}`,
                        });
                      }}
                    >
                      {data.noOf_applied > 0
                        ? data.noOf_applied + ' APPLIED'
                        : 'NONE APPLIED'}
                    </Tag>
                  </Col>
                  <Col
                    xs={12}
                    sm={0}
                    style={{ textAlign: 'right' }}
                    className="sponsered"
                  >
                    <span className="sponsered">
                      {data.isSponsored ? 'SPONSERED' : ''}
                    </span>
                  </Col>

                  <Col xs={24} sm={6}>
                    <span>
                      <img
                        src={process.env.PUBLIC_URL + '/briefcase-red.png'}
                        style={{ width: 14 }}
                      />
                    </span>
                    <span
                      className="service-s-desc"
                      style={{
                        paddingLeft: '8px',
                        verticalAlign: 'middle',
                      }}
                    >
                      {data.job_type}
                    </span>
                  </Col>
                  <Col
                    xs={24}
                    sm={14}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span>
                      <img src="./user-circle.png" style={{ width: 14 }} />
                    </span>
                    <span
                      style={{
                        color: '#000000',
                        opacity: '0.8rem',
                        fontWeight: 'bold',
                        marginLeft: '10px',
                      }}
                    >
                      Added By:
                    </span>
                    <span
                      style={{
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                        marginLeft: '10px',
                      }}
                    >
                      {data?.addedBy?.first_name +
                        ' ' +
                        data?.addedBy?.last_name}
                    </span>
                  </Col>
                  <Col
                    xs={0}
                    sm={4}
                    style={{ textAlign: 'right' }}
                    className="sponsered"
                  >
                    <span className="sponsered">
                      {data.isSponsored ? 'SPONSERED' : ''}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24}>
                    <span>
                      <img src="./location-red.png" style={{ width: 14 }} />
                    </span>
                    <span
                      className={styles.location}
                      style={{
                        paddingLeft: '8px',
                        verticalAlign: 'middle',
                      }}
                    >
                      {data?.companyDetail?.[0]?.contact_info?.sub_address}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        ))
      )}
    </>
  );
};
Subcard.defaultProps = {
  data: [],
};

export default withRouter(Subcard);
