import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Switch,
  Spin,
  Card,
  message,
  Tooltip,
} from 'antd';
import { withRouter, useHistory } from 'react-router-dom';
import checkPremiumDetails from '../checkPremiumDetail';
import './servicescard.css';
import {
  DeleteFilled,
  NotificationFilled,
  DollarCircleFilled,
  EditFilled,
  ClockCircleOutlined,
  StopOutlined,
} from '@ant-design/icons';
import APIManager from '../../../APIManager';
import Pagination from 'react-js-pagination';
import cardDefaultPic from '../../../Components/asset/card.svg';
import PromoteEntity from '../../../Components/company/promoteEntity/index';
import NoData from '../../../PageNotFound/NoData';
import { CURRENCY } from '../../../Components/constants/currency';
import ConfirmPopUp from '../../constants/ConfirmPopup';
import styles from './product.module.css';
import { ImageUrl } from '../../../Shared/imageUrlPath';

var user_type;

const ProductCard = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [productsData, setProductsData] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setId] = useState('');
  const [checked, isChecked] = useState([]);
  const [confirmDeletePopUp, setConfirmDeleteProp] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [promoteEntity, setPromoteEntity] = useState(false);
  const [promoteProductId, setPromoteProductId] = useState('');
  const [stopSponsorJobId, setStopSponsorJobId] = useState('');
  const [confirmStopSponsorPopup, setConfirmStopSponsorPopup] = useState(false);

  useEffect(() => {
    isChecked(productsData?.map((item) => item?.isActivated));
  }, [productsData?.length]);
  React.useEffect(() => {
    loadData(props.sortValue || props.searchQuery);
  }, [props.sortValue, props.searchQuery]);
  useEffect(() => {
    if (id) {
      changeActiveStatus();
    }
  });

  const loadData = (page) => {
    const sort = props.sortValue;
    const searchQuery = props.searchQuery;
    user_type = JSON.parse(localStorage.getItem('user_type') || false);
    const user_type2 = JSON.parse(localStorage.getItem('user_type2') || false);
    user_type = user_type / (user_type2 * 99);

    if ((sort && sort !== 'reset') || (searchQuery && searchQuery !== '')) {
      APIManager.sortProducts(sort, searchQuery, page)
        .then((resp) => {
          setProductsData(resp.data.data);
          setLoading(false);
          setTotalProducts(resp.data.totalCount);
          setCurrentPage(resp.data.currenctPage);
        })
        .catch((err) => {
          this.setState({ isloading: false });
          message.error('Something went wrong!');
        });
    } else {
      APIManager.getAllCompanyProducts(page).then((resp) => {
        setProductsData(resp.data.data);
        setLoading(false);
        setTotalProducts(resp.data.totalCount);
        setCurrentPage(resp.data.currenctPage);
      });
    }
  };
  const confirmStopSponsor = () => {
    APIManager.stopSponsor(stopSponsorJobId, 'product').then((response) => {
      setConfirmStopSponsorPopup(false);
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    });
  };
  const deleteProduct = (id) => {
    APIManager.deleteCompanyProduct(id).then((resp) => {
      console.log('delete Api response:', resp);
    });
    setConfirmDeleteProp(false);
    loadData(currentPage);
  };
  const showPopUp = (id) => {
    setDeleteId(id);
    setConfirmDeleteProp(true);
  };

  const dontDelete = () => {
    //Dont Delete Because it is sponsored product which has remaining Budget
    message.info("You can't delete a sponsored product");
  };

  const changeProductStatus = (id, isActivated) => {
    const data = {
      isActivated: !isActivated,
    };
    // console.log('data for product status change', data);
    APIManager.patchCompanyProduct(id, data).then((resp) => {
      console.log('status Api response:', resp);
      setLoading(false);
    });
  };

  const handleChange = (value) => {
    window.scrollTo(0, 0);
    // setLoading(true);
    setCurrentPage(value);
    loadData(value);
  };

  const renderb = (e, data) => {
    console.log(e, data);
  };
  const changeActiveStatus = async (jobSwitchId, current, key) => {
    if (!checked[key]) {
      const flag = await checkPremiumDetails('products', history);
      if (!flag) {
        return;
      }
    }
    isChecked([...checked?.map((_, i) => (i === key ? current : _))]);
    const id = jobSwitchId;
    APIManager.productActiveStatus(id);
  };
  const { searchQuery } = props;
  const searchedData = productsData.filter((data) =>
    data.name.toLowerCase().startsWith(searchQuery.toLowerCase())
  );
  const reloadProducts = () => {
    APIManager.getAllCompanyProducts('1').then((resp) => {
      setProductsData(resp.data.data);
      setLoading(false);
      setTotalProducts(resp.data.totalCount);
      setCurrentPage(resp.data.currenctPage);
    });
  };
  // console.log(productsData);
  return (
    <>
      {promoteEntity && (
        <PromoteEntity
          show={promoteEntity}
          sponser={true}
          onHide={() => setPromoteEntity(false)}
          name={'product'}
          entity={promoteProductId}
          reloadProducts={reloadProducts}
        />
      )}
      {confirmStopSponsorPopup && (
        <ConfirmPopUp
          message="Are you sure you want to Stop subcontractor Sponsorisation"
          handleOk={confirmStopSponsor}
          closePopUp={() => setConfirmStopSponsorPopup(false)}
        />
      )}
      {confirmDeletePopUp && (
        <ConfirmPopUp
          message="Are you sure you want to delete product"
          handleOk={() => deleteProduct(deleteId)}
          closePopUp={() => setConfirmDeleteProp(false)}
        />
      )}

      {loading ? (
        <Spin style={{ padding: '100px 50%' }} />
      ) : (
        <div>
          {productsData.length === 0 ? (
            <NoData />
          ) : (
            productsData &&
            productsData?.map((data, key) => (
              <Row className={styles.cardRow}>
                <Card className={styles.card} key={data.id}>
                  <Row gutter={15}>
                    <Col xs={5} sm={4}>
                      <img
                        alt="example"
                        src={
                          data.media.filter((data) =>
                            data.fileType?.includes('image')
                          ).length !== 0
                            ? ImageUrl.imageUrlPath +
                              data.media.filter((data) =>
                                data.fileType.includes('image')
                              )[0].fileName
                            : data?.companyData?.logo ? ImageUrl.imageUrlPath + data?.companyData?.logo?.name : cardDefaultPic
                        }
                        className={styles.image}
                      />
                    </Col>
                    <Col
                      xs={19}
                      sm={20}
                      className={`service-detail-row ${styles.topRow}`}
                    >
                      <Row justify="space-between">
                        <Col xs={17} sm={17}>
                          <div
                            className="service-name"
                            onClick={() =>
                              props.history.push({
                                pathname: `/products/detail/${data._id}`,
                              })
                            }
                          >
                            {data.name}
                          </div>
                        </Col>
                        <Col xs={7} sm={7} className="icon-col">
                          <Tooltip title="Edit Product">
                            <EditFilled
                              className="service-hidden-icons service-icons"
                              onClick={(e) => props.editProduct(e, data)}
                            />
                          </Tooltip>
                          {user_type === 2 && !data?.isSponsored && (
                            <Tooltip title="Sponsor Product">
                              <NotificationFilled
                                className="service-hidden-icons service-icons"
                                onClick={() => {
                                  setPromoteEntity(true);
                                  setPromoteProductId(data._id);
                                }}
                              />
                            </Tooltip>
                          )}
                          {user_type === 2 && data.isSponsored && (
                            <Tooltip title="Stop Sponsorisation">
                              <StopOutlined
                                onClick={() => {
                                  setStopSponsorJobId(data._id);
                                  setConfirmStopSponsorPopup(true);
                                }}
                                className="service-hidden-icons service-icons"
                              />
                            </Tooltip>
                          )}
                          <Tooltip title="Delete Product">
                            <DeleteFilled
                              className="service-hidden-icons service-icons"
                              onClick={() => {
                                data.remaningBudget === 0
                                  ? showPopUp(data._id)
                                  : dontDelete();
                              }}
                            />
                          </Tooltip>
                          <Switch
                            checked={checked[key]}
                            defaultChecked={data.isActivated}
                            onChange={(current) => {
                              changeActiveStatus(data._id, current, key);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={18} sm={18} className="service-s-desc">
                          {data.shortDescription}
                        </Col>
                        <Col xs={6} sm={6}>
                          <div
                            className="sponsered"
                            style={{ textAlign: 'right' }}
                          >
                            {data.isSponsored ? 'SPONSORED' : ''}
                          </div>
                        </Col>
                      </Row>
                      <Row align="bottom" className={styles.deliveryTime}>
                        <Col xs={24} sm={9} className={styles.deliveryTime1}>
                          <ClockCircleOutlined className="service-icons" />
                          <div
                            className="service-location"
                            style={{ display: 'block' }}
                          >
                            {data.deliveryTime}
                          </div>
                        </Col>
                        <Col xs={24} sm={6} className={styles.price}>
                          <DollarCircleFilled className="service-icons" />
                          <div className="service-location">
                            {CURRENCY.filter(
                              (values) => values.name === data.price.currency
                            ).length > 0 &&
                              CURRENCY.filter(
                                (values) => values.name === data.price.currency
                              )[0].symbol}
                            &nbsp;&nbsp;{data.price.amount}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Row>
            ))
          )}
          {!searchedData && <div className="no-data-div">No Data Found</div>}
          <div className={'paginate-container'}>
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={10}
              firstPageText={false}
              onChange={handleChange}
              lastPageText={false}
              totalItemsCount={totalProducts}
              pageRangeDisplayed={5}
              hideDisabled={true}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(ProductCard);
