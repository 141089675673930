import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import styles from './candidature.module.css';
import { Select, Row, Col, Input, Form } from 'antd';
import { message, Checkbox, DatePicker, Button } from 'antd';
import APIManager from '../../APIManager/index';
import moment from 'moment';
import GoogleSearch from '../candidate/googlesearch';
import { fileUrl } from '../../Shared/imageUrlPath';
import CompanyIcon from '../asset/Company-icon.png';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;
class CandidatureExperience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salary_active: 'candidature-active',
      social_active: '',
      qualification_active: '',
      exp_active: '',
      contact_active: '',
      tab_name: 'Profile',
      image_preview: undefined,
      file: '',
      fileName: '',
      fileNameLocal: '',
      // ===========================================================Experience=============================
      allExperiences: [],
      allExperiencesLength: 0,
      title: '',
      company: '',
      employmentType: 'Select Employment Type',
      location: '',
      experience_image: '',
      experience_from: '',
      experience_to: '',
      isCurrentlyWorking: false,
      show_currently_working: true,
      edit_experience: false,
      description: '',
      disabled: true,
      edit_data_index: '',
      bio: '',
    };
  }
  componentDidMount() {
    this.expFormRef = React.createRef();
    let data = this.props.data;
    let cd = [];
    data.forEach((n) => {
      let fso = {};
      fso['company'] = n.company;
      fso['from'] = n.from;
      fso['isCurrentlyWorking'] = n.isCurrentlyWorking;
      fso['to'] = n.to;
      fso['location'] = n.location;
      fso['title'] = n.title;
      fso['employmentType'] = n.employmentType;
      fso['description'] = n.description;
      fso['experience_image'] = n.experience_image;
      cd.push(fso);
      // if (n.isCurrentlyWorking) {
      //   this.setState({ show_currently_working: false });
      // }
    });
    this.setState({ allExperiences: cd });
    this.props.handleSave(false);
  }
  handleClick = () => {
    this.addAnotherExp();
    this.props.setData(this.state.allExperiences);
  };
  handleFromDate = (date, dateString) => {
    this.setState({ experience_from: dateString, disabled: false });
  };
  handleToDate = (date, dateString) => {
    this.setState({ experience_to: dateString });
  };
  disabledDate = (current) => {
    let customDate = this.state.experience_from;
    return current < moment(customDate, 'DD MMMM YYYY');
  };
  isEmpty = (str) => {
    if (!str || !str.trim()) {
      return true;
    }
    return false;
  };
  handleCurrentlyWorking = (checkedValue) => {
    if (checkedValue.target.checked) {
      this.setState({
        isCurrentlyWorking: checkedValue.target.checked,
        disabled: true,
      });
    } else {
      this.setState({
        isCurrentlyWorking: checkedValue.target.checked,
        disabled: false,
        experience_to: '',
      });
    }
  };
  handleEditExperience = (index) => {
    let allExp = this.state.allExperiences;
    this.setState({ fileName: allExp[index].experience_image });
    let edit_data_index = index;
    let title = this.state.title;
    title = allExp[index].title;
    let company = this.state.company;
    company = allExp[index].company;
    let employmentType = this.state.employmentType;
    employmentType = allExp[index].employmentType;
    let location = this.state.location;
    location = allExp[index].location;
    let experience_from = this.state.experience_from;
    experience_from = allExp[index].from;
    let experience_to = this.state.experience_to;
    experience_to = allExp[index].to;
    let isCurrentlyWorking = this.state.isCurrentlyWorking;
    isCurrentlyWorking = allExp[index].isCurrentlyWorking;
    let bio = this.state.bio;
    bio = allExp[index].description;
    let experience_image = this.state.experience_image;
    experience_image = allExp[index].experience_image;
    if (isCurrentlyWorking && this.state.show_currently_working) {
      this.setState({ show_currently_working: true });
    } else {
      this.setState({ show_currently_working: false });
    }
    this.setState({
      title,
      company,
      employmentType,
      location,
      experience_from,
      experience_to,
      isCurrentlyWorking,
      bio,
      edit_experience: true,
      edit_data_index: edit_data_index,
      experience_image,
      fileNameLocal: 'Experience Logo.png',
    });
    let formRef = this.expFormRef.current;
    formRef.setFieldsValue({
      title,
      company,
      employmentType,
      location,
      experience_from,
      experience_to,
      bio,
      // experience_image,
    });
    this.props.handleSave(false);
  };
  handleDeleteExperience = (index) => {
    const existingExp = [...this.state.allExperiences];
    const newExp = existingExp.filter((exp, i) => i !== index);
    this.setState({ allExperiences: newExp });
    const can_experience = { can_experience: newExp };
    APIManager.candidatureSubmit(can_experience, 'can_experience')
      .then((res) => {
        message.success('Data saved successfully.');
        // this.setState({ file: '', fileNameLocal: '',fileUrlPath:'' });
      })
      .catch((error) => {
        message.error('Something went wrong');
      });
  };
  addEditExperience = (index) => {
    if (
      this.isEmpty(this.state.title) ||
      this.isEmpty(this.state.company) ||
      this.isEmpty(this.state.location) ||
      this.isEmpty(this.state.employmentType)
    ) {
      return false;
    }
    let allExp = this.state.allExperiences;
    allExp[index].title = this.state.title;
    allExp[index].company = this.state.company;
    allExp[index].employmentType = this.state.employmentType;
    allExp[index].location = this.state.location;
    allExp[index].from = this.state.experience_from;
    allExp[index].to = this.state.experience_to;
    allExp[index].isCurrentlyWorking = this.state.isCurrentlyWorking;
    allExp[index].description = this.state.bio;
    allExp[index].experience_image = this.state.fileName;
    this.setState({
      allExperiences: allExp,
      title: '',
      company: '',
      employmentType: '',
      location: '',
      experience_from: '',
      experience_to: '',
      isCurrentlyWorking: false,
      bio: '',
      edit_experience: false,
      fileName: '',
      experience_image: '',
      fileNameLocal: '',
    });
    let formRef = this.expFormRef.current;
    formRef.resetFields();
    this.props.handleSave(true);
  };
  addAnotherExp = () => {
    let formRef = this.expFormRef.current;
    formRef
      .validateFields()
      .then((values) => {
        let allExp = this.state.allExperiences;
        allExp.push({
          title: this.state.title.trim(),
          company: this.state.company.trim(),
          employmentType: this.state.employmentType,
          location: this.state.location.trim(),
          from: this.state.experience_from,
          to: this.state.experience_to,
          isCurrentlyWorking: this.state.isCurrentlyWorking,
          description: this.state.bio.trim(),
          experience_image: this.state.fileName,
        });
        if (
          allExp[allExp.length - 1].title === '' ||
          allExp[allExp.length - 1].company === '' ||
          allExp[allExp.length - 1].employmentType === '' ||
          allExp[allExp.length - 1].location === '' ||
          allExp[allExp.length - 1].experience_from === '' ||
          allExp[allExp.length - 1].experience_to === '' ||
          allExp[allExp.length - 1].bio === ''
        ) {
          allExp.splice(allExp.length - 1, 1);
        } else {
          this.setState({
            allExperiences: allExp,
            allExperiencesLength: allExp.length,
            title: '',
            company: '',
            employmentType: '',
            location: '',
            experience_from: '',
            experience_to: '',
            isCurrentlyWorking: false,
            bio: '',
            experience_image: '',
            fileNameLocal: '',
          });
        }
        formRef.resetFields();
        this.props.handleSave(true);
      })
      .catch((err) => {
        console.log('fail', err);
      });
  };

  setLocation = (location) => {
    this.expFormRef.current.setFieldsValue({ location: location });
    this.setState({ location: location });
  };

  takecurrentLocation = (value) => {
    this.setState({ current_location: value });
  };

  takeLatLong = (lat, long) => {
    this.setState({
      lat: lat,
      long: long,
    });
  };

  addImage = (image) => {
    const file = image;
    this.setState({
      file: file,
      fileNameLocal: image.name,
    });
    let img_type = file.type;
    this.setState({
      img_type: img_type,
    });
    const uploadParams = {
      files: [
        {
          requestType: 'CandidateProfile',
          contentType: img_type,
        },
      ],
    };
    APIManager.preSignedUrl(uploadParams).then((resp) => {
      if (resp.status === 200) {
        this.setState({
          uploadUrl: resp.data[0].url,
          fileName: resp.data[0].fileName,
          experience_image: resp.data[0].fileName,
        });
        this.uploadOnS3(file);
      }
    });
  };

  uploadOnS3 = (file) => {
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: file,
    })
      .then((resp) => {
        this.setState({ experience_image: this.state.fileName });
      })
      .catch((err) => console.log(err));
  };

  onSubmit = (values) => {
    const can_experience = { can_experience: this.state.allExperiences };
    if (this.state.allExperiencesLength !== 0) {
      APIManager.candidatureSubmit(can_experience, 'can_experience')
        .then((res) => {
          message.success('Data saved successfully.');
          this.setState({ file: '', fileNameLocal: '', fileUrlPath: '' });
        })
        .catch((error) => {
          message.error('Something went wrong');
        });
    } else {
      message.error('Please fill all fields');
    }
  };
  Submit = () => {
    if (this.state.edit_experience) {
      this.addEditExperience(this.state.edit_data_index);
    } else {
      this.addAnotherExp();
    }
    setTimeout(() => {
      this.onSubmit();
    }, 2000);
  };
  render() {
    return (
      <div className={styles.expTab}>
        <Form ref={this.expFormRef}>
          {/* save button for big screen */}
          <Row
            style={{ marginBottom: '1.5vw' }}
            className={styles.dipNoneOnSmall}
          >
            <Col span={24}>
              <Button
                onClick={this.Submit}
                style={{ float: 'right', height: '100%' }}
                type="button"
                htmlType="submit"
                className="btn btn-dark bold-family btn-save-font cursor"
              >
                Save/New
              </Button>
            </Col>
          </Row>
          {/* all experience cards */}
          {this.state.allExperiences.length !== 0 ? (
            <Row>
              {this.state.allExperiences?.map((val, index) => {
                if (index % 2 === 0) {
                  return (
                    <Col
                      sm={12}
                      xs={24}
                      key={index}
                      className={`card-bottom-padding-qualification form-padding-right ${styles.exptabPaddingRight}`}
                    >
                      <Row className="bg-white qualification-card">
                        <Col span={5}>
                          <img
                            src={
                              val.experience_image
                                ? fileUrl.fileUrlPath + val.experience_image
                                : CompanyIcon
                            }
                            alt='companyIcon'
                            className="qualification-card-icon"
                          />
                        </Col>
                        <Col span={18} className="qualification-card-icon-data">
                          <p className="degree_name_cls_1">{val.title} </p>
                          <p className="degree_name_cls_2">
                            {moment(val.from).format('YYYY MMMM DD')} to{' '}
                            {!val.isCurrentlyWorking
                              ? moment(val.to).format('YYYY MMMM DD')
                              : 'Present'}
                          </p>
                        </Col>
                        <Col span={1} style={{ cursor: 'pointer' }}>
                          <img
                            onClick={() => {
                              this.setState(
                                {
                                  file: '',
                                  fileNameLocal: '',
                                },
                                () => {
                                  this.handleEditExperience(index);
                                }
                              );
                            }}
                            src={process.env.PUBLIC_URL + '/edit.png'}
                            alt=""
                          />
                          <img
                            onClick={() => this.handleDeleteExperience(index)}
                            src={process.env.PUBLIC_URL + '/delete-icon.png'}
                            alt=""
                          />
                        </Col>
                      </Row>
                    </Col>
                  );
                } else {
                  return (
                    <Col
                      sm={12}
                      xs={24}
                      key={index}
                      className="card-bottom-padding-qualification form-padding-right pr-0"
                    >
                      <Row className="bg-white qualification-card">
                        <Col span={5}>
                          <img
                            src={
                              val.experience_image
                                ? fileUrl.fileUrlPath + val.experience_image
                                : CompanyIcon
                            }
                            alt="companyIcon"
                            className="qualification-card-icon"
                          />
                        </Col>
                        <Col span={18} className="qualification-card-icon-data">
                          <p className="degree_name_cls_1">{val.title} </p>
                          <p className="degree_name_cls_2">
                            {moment(val.from).format('YYYY MMMM DD')} to{' '}
                            {!val.isCurrentlyWorking
                              ? moment(val.to).format('YYYY MMMM DD')
                              : 'Present'}
                          </p>
                        </Col>
                        <Col span={1}>
                          <img
                            onClick={() => this.handleEditExperience(index)}
                            src={process.env.PUBLIC_URL + '/edit.png'}
                            alt="edit"
                          />
                          <img
                            onClick={() => this.handleDeleteExperience(index)}
                            src={process.env.PUBLIC_URL + '/delete-icon.png'}
                            alt="delete"
                          />
                        </Col>
                      </Row>
                    </Col>
                  );
                }
              })}
            </Row>
          ) : null}
          <Row>
            <Col sm={5} xs={10}>
              <label className="input_label_profile">Image</label>
              <br />
              <label
                className={`add-user-upload-image add-user-upload-image-2 ${styles.uploadImageSection}`}
              >
                <Form.Item
                  name="experience_image"
                  style={{ height: '0px', visibility: 'hidden' }}
                >
                  <Input
                    accept="image/*"
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={(event) => {
                      this.addImage(event.target.files[0]);
                    }}
                    style={{ height: '0px' }}
                  />
                </Form.Item>
                <PlusOutlined />
                <br />
                <br />
                <br />
                <span style={{ cursor: 'pointer', marginTop: '5vw' }}>
                  &nbsp;&nbsp;UPLOAD IMAGE
                </span>
              </label>
            </Col>
            <Col sm={10} xs={10} style={{ color: 'red' }}>
              {this.state.file ? (
                <img
                  src={URL.createObjectURL(this.state.file)}
                  alt=""
                  class="qual-logo1"
                />
              ) : (
                ''
              )}
            </Col>
          </Row>
          {/* all input boxes */}
          <Row>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-right ${styles.exptabPaddingRight}`}
            >
              <label className="input_label_profile required-asteric">
                Title
              </label>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  type="text"
                  className={`input-field-custom-type-1-left ${styles.expTabW100}`}
                  value={this.state.title}
                  onChange={(e) => this.setState({ title: e.target.value })}
                  placeholder=""
                />
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-left ${styles.exptabPaddingLeft}`}
            >
              <label className="input_label_profile required-asteric">
                Employment Type
              </label>
              <Form.Item
                name="employmentType"
                rules={[{ required: true, message: 'Required field' }]}
              >
                <Select
                  className="input-selector-custom-type-1 input-field-custom-type-1-left"
                  value={this.state.employmentType}
                  onChange={(value) => {
                    this.setState({ employmentType: value });
                  }}
                  style={{ width: '100%' }}
                  placeholder={'Select any'}
                >
                  <Option value="" disabled>
                    Select
                  </Option>
                  <Option value="Permanent">Permanent</Option>
                  <Option value="Full Time">Full Time</Option>
                  <Option value="Part Time">Part Time</Option>
                  <Option value="Temporary">Temporary</Option>
                  <Option value="Commission">Commission</Option>
                  <Option value="Apprenticeship">Apprenticeship</Option>
                  <Option value="Internship">Internship</Option>
                  <Option value="Volunteer">Volunteer</Option>
                  <Option value="Freelance">Freelance</Option>
                  <Option value="Student">Student</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-right ${styles.exptabPaddingRight} ${styles.exptabPaddingRight}`}
            >
              <label className="input_label_profile required-asteric">
                Company
              </label>
              <Form.Item
                name="company"
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  type="text"
                  className={`input-field-custom-type-1-left ${styles.expTabW100}`}
                  value={this.state.company}
                  onChange={(e) => this.setState({ company: e.target.value })}
                  placeholder=""
                />
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-left ${styles.exptabPaddingLeft}`}
            >
              <label className="input_label_profile required-asteric">
                Location
              </label>
              <Form.Item
                name="location"
                // rules={[{ required: true, message: 'Required Field!',whitespace:true }]}
                // style={{display:"none"}}
              >
                <Input
                  size="large"
                  className="s-input-fields"
                  placeholder="Location"
                  name="location"
                  defaultValue="Asias"
                  style={{ display: 'none' }}
                />
                <GoogleSearch
                  className={`input-field-custom-type-1-left ${styles.expTabW100}`}
                  takecurrentLocation={this.takecurrentLocation}
                  takeLatLong={this.takeLatLong}
                  // handleSave={this.handleSave}
                  setLocation={this.setLocation}
                  location={this.state.location}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* date selection section */}
          <Row>
            <Col
              sm={8}
              xs={24}
              className={`form-padding-right ${styles.exptabPaddingRight}`}
            >
              <label className="input_label_profile">From</label>
              {/* <Form.Item label="From" name="experience_from" rules={[{ required: true, message: 'Required field' }]} >               */}
              <DatePicker
                onChange={this.handleFromDate}
                size="large"
                suffixIcon={
                  <img src={process.env.PUBLIC_URL + '/calendar-date.svg'} alt="date" />
                }
                className="w-100 input-field-custom-type-1-left"
                value={
                  this.state.experience_from
                    ? moment(this.state.experience_from)
                    : undefined
                }
                format={'YYYY MMMM DD'}
                picker="date"
              />
            </Col>
            <Col
              sm={8}
              xs={24}
              className={`form-padding-left ${styles.exptabPaddingLeft}`}
            >
              <label className="input_label_profile">To</label>
              <DatePicker
                className="w-100 input-field-custom-type-1-left disabled-bg"
                value={
                  this.state.experience_to
                    ? moment(this.state.experience_to)
                    : undefined
                }
                onChange={this.handleToDate}
                disabledDate={this.disabledDate}
                disabled={this.state.disabled}
                suffixIcon={
                  <img src={process.env.PUBLIC_URL + '/calendar-date.svg'} alt="date" />
                }
                picker="date"
                format={'YYYY MMMM DD'}
              />
            </Col>
            <Col
              sm={8}
              xs={24}
              className={`form-padding-left checkbox-padding-candidate ${styles.currentlyWorking}`}
            >
              {this.state.show_currently_working ? (
                <Checkbox
                  className="input_label_profile"
                  checked={this.state.isCurrentlyWorking}
                  onChange={this.handleCurrentlyWorking}
                >
                  Currently working here
                </Checkbox>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="text-area-padding">
              <label className="input_label_profile">Description</label>
              <Form.Item name="bio">
                <TextArea
                  name="bio"
                  className="input-font form-control profile-form-control"
                  value={this.state.bio}
                  onChange={(e) => this.setState({ bio: e.target.value })}
                  rows="5"
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: '-20px' }} className={styles.dipNoneOnBig}>
            <Col span={24}>
              <Button
                onClick={this.Submit}
                style={{ float: 'right', height: '100%', width: '100%' }}
                type="button"
                htmlType="submit"
                className="btn btn-dark bold-family btn-save-font cursor"
              >
                Save/New
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default CandidatureExperience;
