import axios from "axios";
import { MapKey } from '../Shared/imageUrlPath';
import { dispatchAction } from '../Redux/Store/index';
import {
  uploadMediaFirstStatus,
  uploadMediaSecondStatus,
  uploadMediaThirdStatus,
  uploadMediaFourthStatus,
  uploadMediaFifthStatus
} from '../Redux/Actions/uploadMediaAction'

axios.defaults.baseURL =`${process.env.REACT_APP_URL}/`

axios.defaults.headers.post["Content-Type"] = "application/json";

const showPosition = (position) => {
  var ls = require('local-storage');
  ls.set('lat', position.coords.latitude);
  ls.set('long', position.coords.longitude)
};

let HELPERS = {
  getLatLong: () => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(showPosition);

    } else {
      console.log("not found")
    }

  },
  isNumber: (value) => {
    return (value === Number(value)) ? "number" : "string"
  },

  getLocation: (lat, long) => {
    return axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + lat + ',' + long + '&key=' + MapKey.key)//AIzaSyCq_buWKq5SczlpLmaxxpgQD7zZTNGGXL4') 
  },
  changesSaved: (func) => {
    try {
      if (HELPERS.savedHandle !== null) {
        HELPERS.log("saved: Clearing old interval...");
        clearInterval(HELPERS.savedHandle);
      }

      HELPERS.log("saved state....");
      HELPERS.savedHandle = setInterval(() => {
        HELPERS.log("PING: Pinging");
        func();
      }, 60 * 1000);
    } catch (e) {
      HELPERS.log(e);
    }
  },
  log: (...args) => {
    if (process.env.NODE_ENV !== "production") {
    }
  },
  error: (...args) => {
    if (process.env.NODE_ENV !== "production") {
      console.error("$$woken$$", ...args);
    }
  },
  getCookie: (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  },
  request: (config) => {
    config.headers = config.headers ? config.headers : {};
    const csrfTokenEl = document.querySelector("[name=csrfmiddlewaretoken]");
    const csrfToken =
      HELPERS.getCookie("csrftoken") || (csrfTokenEl && csrfTokenEl.value);
    if (csrfToken) {
      config.headers["X-CSRFToken"] = csrfToken;
    }

    return axios.request(config).then((response) => {
      return response;
    })
      .catch(
        err => {
          // if (err.response.status===403){
          //   localStorage.setItem('token', null)
          //   window.location.href = "/signin";
          // }
          // else{
          //   throw new Error("Error");
          // }
        }
      )
  },
  // ========================upload company media on company profile===========
  uploadFirstFile: (file, uploadUrl) => {
    let uploadImage = ""
    fetch(uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: file,
    })
      .then((resp) => {
        let upload_status = { upload_first: true }
        dispatchAction(uploadMediaFirstStatus(upload_status))
      })
      .catch((err) => console.log(err));
  },
  uploadSecondFile: (file, uploadUrl) => {
    let uploadImage = ""
    fetch(uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: file,
    })
      .then((resp) => {
        let upload_status = { upload_second: true }
        dispatchAction(uploadMediaSecondStatus(upload_status))
      })
      .catch((err) => console.log(err));
  },
  uploadThirdFile: (file, uploadUrl) => {
    let uploadImage = ""
    fetch(uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: file,
    })
      .then((resp) => {
        let upload_status = { upload_third: true }
        dispatchAction(uploadMediaThirdStatus(upload_status))
      })
      .catch((err) => console.log(err));
  },
  uploadFourthFile: (file, uploadUrl) => {
    let uploadImage = ""
    fetch(uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: file,
    })
      .then((resp) => {
        let upload_status = { upload_fourth: true }
        dispatchAction(uploadMediaFourthStatus(upload_status))
      })
      .catch((err) => console.log(err));
  },
  uploadFifthFile: (file, uploadUrl) => {
    let uploadImage = ""
    fetch(uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: file,
    })
      .then((resp) => {
        let upload_status = { upload_fifth: true }
        dispatchAction(uploadMediaFifthStatus(upload_status))
      })
      .catch((err) => console.log(err));
  },
  secureRequest: (config) => {
    config.headers = config.headers ? config.headers : {};
    const token = localStorage.getItem("token");
    if (token) {
      const authToken = JSON.parse(localStorage.getItem('token') || false);
      config.headers["Authorization"] = 'Bearer ' + authToken;
      config.headers['Access-Control-Allow-Credentials'] = true;
    }
    return HELPERS.request(config);
  },
  debounce: (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  },
  debounceFormChanges: (func) => {
    return HELPERS.debounce(func, 1000);
  },
  getUserId: () => localStorage.getItem("user_id"),
  converToFormData: (obj, rootName, ignoreList) => {
    var formData = new FormData();
    function appendFormData(data, root) {
      if (!ignore(root)) {
        root = root || "";
        if (data instanceof File) {
          formData.append(root, data);
        } else if (Array.isArray(data)) {
          for (var i = 0; i < data.length; i++) {
            appendFormData(data[i], root + "[" + i + "]");
          }
        } else if (typeof data === "object" && data) {
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              if (root === "") {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], root + "." + key);
              }
            }
          }
        } else {
          if (data !== null && typeof data !== "undefined") {
            formData.append(root, data);
          }
        }
      }
    }
    function ignore(root) {
      return (
        Array.isArray(ignoreList) &&
        ignoreList.some(function (x) {
          return x === root;
        })
      );
    }
    appendFormData(obj, rootName);
    return formData;
  },
};

export default HELPERS;
