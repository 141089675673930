import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Row, Col, Input, Button } from 'antd';
import { message } from 'antd';
import APIManager from '../../APIManager/index';
import { Formik } from 'formik';
import GoogleSearch from '../candidate/googlesearch';
import CustomMap from './map.js';
import CurrentMapLocation from '../../Routes/test';
import styles from './candidature.module.css';
let formButton = null;

class CandidatureContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_location: '',
      lat: '',
      long: '',
    };
  }
  onSubmit = (e) => {
    var location = {
      latitude: this.state.lat,
      longitude: this.state.long,
    };
    e['location'] = location;
    e['addressTwo'] = this.state.current_location;
    if (this.state.current_location?.length > 0) {
      const locationArray = this.state.current_location.split(',');
      if (locationArray.length > 0) {
        e['country'] = locationArray[locationArray.length - 1].trim();
        e['state'] =
          locationArray.length > 1
            ? locationArray[locationArray.length - 2].trim()
            : '';
        e['city'] = locationArray.length > 2 ? locationArray[0].trim() : '';
      }
    }

    this.props.setData(e);
  };

  componentDidMount() {
    if (this.props.data !== null && this.props.data.location !== null) {
      this.setState({
        current_location: this.props.data.addressTwo,
        lat: this.props.data.location.latitude,
        long: this.props.data.location.longitude,
      });
    }
  }
  handleClick = () => {
    formButton.click();
  };
  takecurrentLocation = (value) => {
    this.setState({ current_location: value });
  };

  takeLatLong = (lat, long) => {
    this.setState({
      lat: lat,
      long: long,
    });
  };
  checkToggleFunc = (checkToggle) => {
    this.setState({
      checkToggle: checkToggle,
    });
  };
  render() {
    const numArr = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '0',
    ];
    const data = this.props.data;
    return (
      <div className={styles.salaryTabsParent}>
        <Formik
          initialValues={{
            phoneNumber: data.phoneNumber ? data.phoneNumber : '',
            landlineNumber: data.landlineNumber ? data.landlineNumber : '',
            addressTwo: data.addressTwo ? data.addressTwo : '',
            zipCode: data.zipCode ? data.zipCode : '',
            location: data.location ? data.location : {},
            checkToggle: false,
          }}
          onSubmit={(values, { setSubmitting }) => {
            delete values.addressTwo;
            values.addressTwo = this.state.current_location;
            values.location.latitude = this.state.lat;
            values.location.longitude = this.state.long;
            const can_contact = { can_contact: values };
            APIManager.candidatureSubmit(can_contact, 'can_contact')
              .then((res) => {
                console.log('response', res);
                message.success('Data saved successfully');
              })
              .catch((error) => {
                message.error('Something went wrong');
                console.log('error', error);
              });
          }}
        >
          {(formikProps) => {
            const {
              values,
              handleChange,
            } = formikProps;
            return (
              <form onSubmit={formikProps.handleSubmit}>
                {/*============ save button for big scrrens ==================*/}
                <Row className={styles.dipNoneOnSmall}>
                  <Col span={24}>
                    <Button
                      style={{ float: 'right', height: '100%' }}
                      type="button"
                      htmlType="submit"
                      className="btn btn-dark bold-family btn-save-font cursor"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm={12}
                    xs={24}
                    className={`form-padding-right ${styles.formPaddingLeftContact}`}
                  >
                    <label className="input_label_profile">Phone Number</label>
                    <Input
                      type="text"
                      className={`input-field-custom-type-1-left ${styles.w100}`}
                      value={values.phoneNumber}
                      name="phoneNumber"
                      maxLength={13}
                      onChange={handleChange}
                      placeholder=""
                      onKeyDown={(e) =>
                        !numArr.includes(e.key) && e.preventDefault()
                      }
                    />
                  </Col>
                  <Col
                    sm={12}
                    xs={24}
                    className={`form-padding-left  pr-0 ${styles.formPLContact}`}
                  >
                    <label className="input_label_profile">
                      Landline Number
                    </label>
                    <Input
                      type="text"
                      className="input-field-custom-type-1-left"
                      value={values.landlineNumber}
                      name="landlineNumber"
                      maxLength={13}
                      style={{ width: '100%' }}
                      onChange={handleChange}
                      placeholder=""
                      onKeyDown={(e) =>
                        !numArr.includes(e.key) && e.preventDefault()
                      }
                    />
                  </Col>

                  <Col
                    sm={12}
                    xs={24}
                    className={`form-padding-right ${styles.formPaddingLeftContact}`}
                  >
                    <label className="input_label_profile">Address</label>
                    <GoogleSearch
                      className={`input-field-custom-type-1-left  ${styles.w100}`}
                      location={values.addressTwo}
                      takecurrentLocation={this.takecurrentLocation}
                      // takecurrentLocation={(val) => {
                      // 	setFieldValue('addressTwo',val)
                      // }}
                      takeLatLong={this.takeLatLong}
                      checkToggleFunc={this.checkToggleFunc}
                      handleSave={this.props.handleSave}
                    />
                    {this.state.lat &&
                      this.state.long &&
                      this.state.checkToggle && (
                        <div
                          className="form-group mb-signup-1"
                          style={{
                            position: 'relative',
                            width: '100%',
                            height: '300px',
                          }}
                        >
                          <CurrentMapLocation
                            lat={this.state.lat}
                            long={this.state.long}
                            takeLatLong={this.takeLatLong}
                          />
                        </div>
                      )}
                  </Col>
                  <Col
                    sm={12}
                    xs={24}
                    className={`form-padding-left  pr-0 ${styles.formPLContact}`}
                  >
                    <label className="input_label_profile">Zip Code</label>
                    <Input
                      type="text"
                      className="input-field-custom-type-1-left"
                      name="zipCode"
                      value={values.zipCode}
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      placeholder=""
                      maxLength={6}
                      onKeyDown={(e) =>
                        !numArr.includes(e.key) && e.preventDefault()
                      }
                    />
                  </Col>
                  <Col
                    xs={24}
                    style={{ marginBottom: '60px', height: '200px' }}
                  >
                    <div className={styles.mapSection}>
                      {!this.state.checkToggle && (
                        <CustomMap
                          lat={this.state.lat}
                          long={this.state.long}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                {/*============ save button for small scrrens ==================*/}
                <Row className={styles.dipNoneOnBig}>
                  <Col span={24} style={{ marginTop: '20px' }}>
                    <Button
                      style={{
                        float: 'right',
                        height: '100%',
                        width: '100%',
                      }}
                      type="button"
                      htmlType="submit"
                      className="btn btn-dark bold-family btn-save-font cursor"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}
export default CandidatureContact;
