import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import APIManager from '../../../APIManager/index';
import { connect } from 'react-redux';
import { Menu, Dropdown, Row, Col } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './stats.css';
import styles from './statistic.module.css';
import moment from 'moment';
import CompanyMenuDropDown from '../CompanyMenuDropDown';
const { SubMenu } = Menu;
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testmonth: [],
      type: 'yearly',
      value: '',
    };
  }

  getStatsData = (val) => {
    if (this.state.type == 'yearly') {
      APIManager.getStaticsData({ year: val });
    } else {
      APIManager.getStaticsData({ month: val });
    }
  };

  handleSelect = (e) => {
    this.setState({ value: e });
    this.getStatsData(e);
  };

  handletimePeriod = (e) => {
    this.setState({
      timePeriod: e.target.value,
    });
  };

  componentDidMount() {
    // this.getStatsData('monthly')
  }

  getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  setType = (val) => {
    this.setState({ type: val });
  };

  menu = () => (
    <Menu
      onClick={({ key }) => {
        this.handleSelect(key);
      }}
      onOpenChange={(openKeys) => {
        openKeys.length > 0 && this.setType(openKeys[0]);
      }}
    >
      <SubMenu key="yearly" title="Yearly">
        <Menu.Item key={new Date().getFullYear() - 2}>
          {new Date().getFullYear() - 2}
        </Menu.Item>
        <Menu.Item key={new Date().getFullYear() - 1}>
          {new Date().getFullYear() - 1}
        </Menu.Item>
        <Menu.Item key={new Date().getFullYear()}>
          {new Date().getFullYear()}
        </Menu.Item>
      </SubMenu>
      <SubMenu key="monthly" title="Monthly">
        <Menu.Item key="January">January</Menu.Item>
        <Menu.Item key="February">February</Menu.Item>
        <Menu.Item key="March">March</Menu.Item>
        <Menu.Item key="April">April</Menu.Item>
        <Menu.Item key="May">May</Menu.Item>
        <Menu.Item key="June">June</Menu.Item>
        <Menu.Item key="July">July</Menu.Item>
        <Menu.Item key="August">August</Menu.Item>
        <Menu.Item key="September">September</Menu.Item>
        <Menu.Item key="October">October</Menu.Item>
        <Menu.Item key="November">November</Menu.Item>
        <Menu.Item key="December">December</Menu.Item>
      </SubMenu>
    </Menu>
  );

  render() {
    let currentMonth = new Date().getMonth() + 1;
    let selectedMonth =
      (this.state.value &&
        this.state.type &&
        this.state.type == 'monthly' &&
        moment().month(this.state.value).format('M')) ||
      15;
    let currentYear = new Date().getFullYear();
    let stats = this.props.stats_data && JSON.parse(this.props.stats_data.data);
    let month, test, data, months;
    if (
      this.state.value == new Date().getFullYear() - 2 ||
      this.state.value == new Date().getFullYear() - 1 ||
      this.state.value == new Date().getFullYear()
    ) {
      data =
        this.props.stats_data &&
        JSON.parse(this.props.stats_data.data)?.map((data) => data.count);
      months =
        this.props.stats_data &&
        JSON.parse(this.props.stats_data.data)?.map((data) => data.monthName);
    } else {
      if (this.props.stats_data && stats.length > 0) {
        let d = JSON.parse(this.props.stats_data.data)?.map((data) => {
          return {
            date: moment(data.date).format('DD-MM-YYYY'),
            count: data.count,
          };
        });
        let dailyData = this.getDaysInMonth(
          moment(d[0].date).format('DD') - 1,
          moment(d[0].date).format('YYYY')
        )?.map((date) => moment(date).format('DD-MM-YYYY'));
        const finalData =
          dailyData &&
          dailyData?.map((daily) => {
            let t = d.filter((data) => {
              if (data.date == daily) {
                return data.count;
              }
            });
            if (t.length) {
              return t[0];
            } else {
              return {
                date: daily,
                count: 0,
              };
            }
          });
        months = finalData && finalData?.map((data) => data.date);
        data = finalData && finalData?.map((data) => data.count);
      } else {
        if (selectedMonth < currentMonth && stats && stats.length == 0) {
          let dateData = this.getDaysInMonth(
            moment().month(this.state.value).format('M') - 1,
            currentYear
          )?.map((data) => {
            return {
              date: moment(data).format('DD-MM-YYYY'),
              count: 0,
            };
          });
          months = dateData && dateData?.map((data) => data.date);
          data = dateData && dateData?.map((data) => data.count);
        }
      }
    }

    if (data && months) {
      month = ['', ...months];
      test = [0, ...data];
    }

    const state = {
      labels: month,
      datasets: [
        {
          label: '',
          fill: false,
          lineTension: 0,
          backgroundColor: '#ffffff',
          borderColor: 'rgba(280,80,80)',
          data: test,
        },
      ],
    };

    return (
      <>
        <Row>
          <Col sm={0} xs={24} className={styles.dropDownMenu}>
            <CompanyMenuDropDown name={'Statistics'} />
          </Col>
        </Row>
        <div className={`stats-container ${styles.mainContainer}`}>
          <p>
            <span className={'stats-label'}> PROFILE VIEWS</span>
          </p>
          <div className={'time-period-container w-100'}>
            <div></div>
            <div>
              <Dropdown overlay={this.menu} trigger={['click']}>
                <div
                  className={`time-period-select ${styles.timePeriodSelect}`}
                >
                  {this.state.value ? this.state.value : 'Choose'}{' '}
                  <DownOutlined />
                </div>
              </Dropdown>
            </div>
          </div>

          <Line
            data={state}
            options={{
              title: {
                display: true,
                fontSize: 20,
                backgroundColor: '#EE5050',
              },
              legend: { display: false, position: 'right' },
              tooltips: {
                display: true,
                backgroundColor: '#EE5050',
              },
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stats_data: state.statsReducer.stats_data,
  };
};
export default connect(mapStateToProps)(index);
