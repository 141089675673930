import React, { Component } from 'react';
import { Row, Col, Select, Spin } from 'antd';

import Card from './resumeFileCard';
import './resume.css';
import { connect } from 'react-redux';
import APIManager from '../../APIManager/index';

const { Option } = Select;
class Resume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchingValue: '',
      sortingValue: '',
    };
  }
  componentDidMount() {
    APIManager.resumeJobsAppliedbyCompany(
      this.state.searchingValue,
      this.state.sortingValue
    );
  }
  handleSort = (e) => {
    if (e === 'appliedAt') {
      this.setState({
        sortingValue: 'appliedAt',
      });
      APIManager.resumeJobsAppliedbyCompany(
        this.state.searchingValue,
        'appliedAt'
      );
    } else if (e === 'candidate') {
      this.setState({
        sortingValue: 'candidate.name',
      });
      APIManager.resumeJobsAppliedbyCompany(
        this.state.searchingValue,
        'candidate.name'
      );
    }
  };

  //handle searching value
  // handleChange = (e) => {
  //   this.setState({ searchingValue: e.target.value });
  //   APIManager.resumeJobsAppliedbyCompany(e.target.value, this.state.sortingValue);
  // };
  render() {
    // const data = this.props.appliedCandidateData
    //   ? this.props.data.data
    //   : [];
    const loading = this.props.isloading ? this.props.isloading : '';
    const count = this.props.appliedCandidateData
      ? this.props.appliedCandidateData.results
      : '';

    return (
      <>
        {loading ? (
          <div className={'d-flex justify-content-center'}>
            <Spin />
          </div>
        ) : (
          <>
            <Row>
              <p className="resume-1">{`${count > 1 ? count : ''} ${
                count > 1 ? 'Resumes' : 'Resume'
              }`}</p>
              {/* <p className="resume-1">{`${count} Resume`}</p> */}
              {/* <div className="col-sm-6 pl-0 pb-3">
                <Input
                  size="large"
                  className="billing-searchbox"
                  placeholder="Search for keywords"
                  prefix={<SearchOutlined />}
                  value={this.state.searchingValue}
                  onChange={this.handleChange}
                />
              </div> */}
              {/* <div className="col-sm-3 pb-3"> */}
              <Col span={12} className="billing_overview_input_and_sort">
                <Select
                  name="permissions"
                  defaultValue=""
                  className="billing-sort"
                  onChange={this.handleSort}
                >
                  <Option value="" disabled>
                    Sort by
                  </Option>
                  <Option value="appliedAt" onChange={this.handleSort}>
                    Applied Date
                  </Option>
                </Select>
                {/* </div> */}
              </Col>
            </Row>
            <Row>
              <Card
                {...this.props}
                searchingValue={this.state.searchingValue}
                sortingValue={this.state.sortingValue}
              />
            </Row>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appliedCandidateData: state.jobManagementReducer.appliedCandidateData,
    isloading: state.jobManagementReducer.isloading,
  };
};
export default connect(mapStateToProps)(Resume);
