import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Row, Space } from 'antd';
import { Link } from 'react-router-dom';
import styles from './statistics.module.css';
const styleobj = {
  color: '#00000080',
  font: 'normal normal 400 13px/15px Gilroy semiBold',
};
const items = [
  {
    key: '1',
    label: (
      <span style={{ font: 'normal normal 400 14px/16px Gilroy Bold' }}>
        Basic
      </span>
    ),
  },

  {
    key: '2',
    label: (
      <Link style={styleobj} to="/profile">
        Profile
      </Link>
    ),
  },
  {
    key: '3',
    label: (
      <Link style={styleobj} to="/statistics">
        Statistics
      </Link>
    ),
  },
  {
    key: '4',
    label: (
      <span style={{ font: 'normal normal 400 14px/16px Gilroy Bold' }}>
        Recruitment
      </span>
    ),
  },
  {
    key: '5',
    label: (
      <Link style={styleobj} to="/candidature">
        Candidature
      </Link>
    ),
  },
  {
    key: '6',
    label: (
      <Link style={styleobj} to="/resume">
        Resume
      </Link>
    ),
  },
  {
    key: '7',
    label: (
      <Link style={styleobj} to="/favouritejobs">
        Favourite Jobs
      </Link>
    ),
  },
  {
    key: '8',
    label: (
      <Link style={styleobj} to="/appliedjobs">
        Applied Jobs
      </Link>
    ),
  },
  {
    key: '9',
    label: (
      <span style={{ font: 'normal normal 400 14px/16px Gilroy Bold' }}>
        Contact
      </span>
    ),
  },
  {
    key: '10',
    label: (
      <Link style={styleobj} to="/company-mailbox">
        Mailbox
      </Link>
    ),
  },
];
const MenuDropDown = ({ name }) => (
  <Row className={styles.dropDownMenu}>
    <Col span={24} className={styles.dropDownMenuContainer}>
      <Dropdown
        className={styles.menuItems}
        menu={{
          items,
        }}
        trigger={['click']}
      >
        <a
          onClick={(e) => e.preventDefault()}
          style={{
            color: 'black',
            font: 'normal normal 400 20px/25px Gilroy Bold',
          }}
        >
          <Space>
            {name}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </Col>
  </Row>
);

export default MenuDropDown;
