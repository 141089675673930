import { LeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Spin, Tooltip, Modal, Typography } from 'antd';
import { fileUrl, ImageUrl } from '../../Shared/imageUrlPath';
import moment from 'moment';
import userDefaultPic from '../asset/user.svg';
import APIManager from '../../APIManager';
import AppliedCandidatesNewCard from './applied_candidates_new_card';
import AppliedJobStatus from '../layout/applied-job-status';
import NoDataFound from '../../PageNotFound/NoData';
import styles from './applied_candidates.module.css';

const { Paragraph } = Typography;

const Applied_candidates_new_details = (props) => {
  const [jobDetails, setJobDetails] = useState({});
  const [appliedCandidates, setAppliedCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [selectedCandidate, setSelectedCandidate] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    setJobDetails(props.location.state);
    APIManager.appliedCandidateJobsDetailsList(props.location.state.id).then(
      (res) => {
        setAppliedCandidates(res.data.data);
        setLoading(false);
      }
    );
  }, []);

  const checkChannelExist = (id) => {
    APIManager.checkChannel(id).then((resp) => console.log(''));
  };
  return (
    <>
      <div
        onClick={() => {
          props.history.goBack();
        }}
      >
        <LeftOutlined className="back-icon" />
        <span className="back-btn">Go back</span>
      </div>
      <p className={styles.applicantHeading}>
        Applicants : &nbsp;
        <Tooltip title={jobDetails?.title}>
          {jobDetails?.title?.length > 50
            ? jobDetails.title.substr(0, 49) + '...'
            : jobDetails.title}{' '}
        </Tooltip>
      </p>

      {loading ? (
        <div className={'d-flex justify-content-center'}>
          <Spin />
        </div>
      ) : !appliedCandidates.length ? (
        <NoDataFound />
      ) : (
        <>
          <AppliedCandidatesNewCard
            title={jobDetails.title}
            job_type={jobDetails.job_type}
            count={jobDetails.count}
            added_by={jobDetails.added_by}
            address={jobDetails.address}
            date={jobDetails.date}
            image={jobDetails.image}
            companyLocation={jobDetails.companyLocation}
          />
          <p className={styles.applicantHeading}>
            {appliedCandidates.length}{' '}
            {appliedCandidates.length > 1 ? 'Candidates' : 'Candidate'}
          </p>
          {appliedCandidates &&
            appliedCandidates?.map((data) => (
              <div className={styles.cardCol}>
                <Card className={styles.card}>
                  <Row gutter={15}>
                    <Col xs={4} >
                      <img
                        alt="example"
                        src={
                          data.candidateData.can_detail.profile
                            ? ImageUrl.imageUrlPath +
                              data.candidateData.can_detail.profile
                            : userDefaultPic
                        }
                        style={{
                          width: '90%',
                          height: '100px',
                          borderRadius: '10px',
                          // objectFit: 'cover',
                        }}
                      />
                    </Col>
                    <Col xs={16}>
                      <Row justify="space-between">
                        <Col span={17}>
                          <div
                            className="service-name"
                            onClick={() =>
                              props.history.push({
                                pathname: `/Applied-candidate-info/${data?._id}`,
                                state: {
                                  candidateId: data?._id,
                                  status: data?.status,
                                },
                              })
                            }
                            style={{ fontSize: '22px' }}
                          >
                            {' '}
                            {data?.candidate?.name}
                          </div>
                          <p
                            style={{ fontSize: '13px', paddingTop: '5px' }}
                            className="ac-position m-0"
                          >
                            {data?.candidate?.current_position}
                          </p>
                          {/* {moment(data.applied_at).format("DD MMM'YYYY")} */}
                        </Col>
                        <Col span={7} className="icon-col"></Col>
                      </Row>
                      <Row>
                        <Col xs={0} sm={24}>
                            <img
                              src={process.env.PUBLIC_URL + '/location-red.png'}
                              style={{ width: 14 }}
                              alt=""
                            />
                            <span
                              style={{
                                paddingLeft: '8px',
                                verticalAlign: 'middle',
                                font: 'normal normal 600 16px/32px Gilroy Bold',
                                opacity: '0.5',
                                color: '#000000',
                              }}
                            >
                              {data?.candidateData?.can_contact?.addressTwo
                                ? `${data?.candidateData?.can_contact?.addressTwo}`
                                : `${data?.candidateData?.can_detail?.currentLocation}`}
                            </span>
                        </Col>
                        <Col xs={24}>
                            <span>
                              <img
                                src="../clock-red.png"
                                style={{ width: 14 }}
                              />
                            </span>
                            <span
                              style={{
                                paddingLeft: '8px',
                                verticalAlign: 'middle',
                                font: 'normal normal 600 16px/32px Gilroy Bold',
                                opacity: '0.5',
                                color: '#000000',
                              }}
                            >
                              {' '}
                              {moment(data?.applied_at).format("DD MMM'YYYY")}
                            </span>
                        </Col>
                        <Col xs={24} sm={0}>
                            <img
                              src={process.env.PUBLIC_URL + '/location-red.png'}
                              style={{ width: 14 }}
                              alt=""
                            />
                            <span
                              style={{
                                paddingLeft: '8px',
                                verticalAlign: 'middle',
                                font: 'normal normal 600 16px/32px Gilroy Bold',
                                opacity: '0.5',
                                color: '#000000',
                              }}
                            >
                              {data?.candidateData?.can_contact?.addressTwo
                                ? `${data?.candidateData?.can_contact?.addressTwo}`
                                : `${data?.candidateData?.can_detail?.currentLocation}`}
                            </span>
                        </Col>
                      </Row>
                      <Row align="bottom">
                        <Col xs={0} sm={4}>
                          <div
                            className="ac-card-list-item w-100 ac-card-list-border"
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Row>
                              <p className="ac-position w-100 m-0 appliedAvaibility">
                                {data?.candidate?.availability}
                              </p>
                            </Row>
                            <Row>
                              <p
                                className={
                                  'w-100 input-label-field-custom-type-1 m-0'
                                }
                              >
                                Availibility
                              </p>
                            </Row>
                          </div>
                        </Col>
                        <Col xs={0} sm={4} align="middle">
                          <div className="ac-card-list-item w-100 ac-card-list-border">
                            <Row>
                              <p className="ac-position w-100 m-0">
                                {`$${data.candidate.price}`}
                              </p>
                            </Row>
                            <Row>
                              <p
                                className={
                                  'w-100 input-label-field-custom-type-1 m-0'
                                }
                              >
                                Salary
                              </p>
                            </Row>
                          </div>
                        </Col>
                        <Col
                          xs={0}
                          sm={8}
                          align="middle"
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <div className="pl-2 ac-card-list-item w-100">
                            <Row>
                              <a
                                target="_blank"
                                className="ac-position w-100 m-0"
                                href={`${fileUrl.fileUrlPath}${data.candidate.resume}`}
                                style={{
                                  textDecoration: 'none',
                                  paddingTop: '0',
                                  paddingBottom: '0',
                                  textAlign: 'center',
                                  verticalAlign: 'bottom',
                                }}
                                rel="noreferrer"
                              >
                                <span>
                                  {/* <LinkOutlined style={{color: 'red', height: '14px', width: '14px'}}/> */}
                                  <img
                                    src="../link.svg"
                                    style={{ width: 14 }}
                                    alt=""
                                  />
                                </span>
                                <span
                                  className="service-s-desc"
                                  style={{
                                    paddingLeft: '8px',
                                    verticalAlign: 'middle',
                                    fontSize: '0.9vw',
                                    color: 'red',
                                  }}
                                >
                                  View Resume PDF
                                </span>
                              </a>
                         
                            </Row>
                            <Row>
                              <p
                                className={
                                  'w-100 input-label-field-custom-type-1 m-0'
                                }
                              >
                                Resume
                              </p>
                            </Row>
                          </div>
                        </Col>
                        <Col
                          xs={0}
                          sm={8}
                          align="middle"
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <div className="pl-2 ac-card-list-item w-100">
                            <Row>
                              <p
                                target="_blank"
                                className="ac-position w-100 m-0"
                                onClick={() => {
                                  handleOpen();
                                  setSelectedCandidate(data?.candidate);
                                }}
                                // href={`${fileUrl.fileUrlPath}${data.candidate.resume}`}
                                style={{
                                  textDecoration: 'none',
                                  paddingTop: '0',
                                  paddingBottom: '0',
                                  textAlign: 'center',
                                  verticalAlign: 'bottom',
                                  cursor: 'pointer',
                                }}
                                rel="noreferrer"
                              >
                                <span>
                                  {/* <LinkOutlined style={{color: 'red', height: '14px', width: '14px'}}/> */}
                                  <img
                                    src="../link.svg"
                                    style={{ width: 14 }}
                                    alt=""
                                  />
                                </span>
                                <span
                                  className="service-s-desc"
                                  style={{
                                    paddingLeft: '8px',
                                    verticalAlign: 'middle',
                                    fontSize: '0.9vw',
                                    color: 'red',
                                  }}
                                >
                                  More Info
                                </span>
                              </p>
                            </Row>
                            <Row>
                              <p
                                className={
                                  'w-100 input-label-field-custom-type-1 m-0'
                                }
                              >
                                Information
                              </p>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={4}>
                      <AppliedJobStatus
                        status={data.status}
                        job_id={data._id}
                      />
                      </Col>
                      <Row style={{width: '100%'}} >
                        <Col xs={6} sm={0}>
                          <div
                            className="ac-card-list-item w-100 ac-card-list-border"
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Row>
                              <p className="ac-position w-100 m-0 appliedAvaibility">
                                {data?.candidate?.availability}
                              </p>
                            </Row>
                            <Row>
                              <p
                                className={
                                  'w-100 input-label-field-custom-type-1 m-0'
                                }
                              >
                                Availibility
                              </p>
                            </Row>
                          </div>
                        </Col>
                        <Col xs={6} sm={0} align="middle">
                          <div className="ac-card-list-item w-100 ac-card-list-border">
                            <Row>
                              <p className="ac-position w-100 m-0">
                                {`$${data.candidate.price}`}
                              </p>
                            </Row>
                            <Row>
                              <p
                                className={
                                  'w-100 input-label-field-custom-type-1 m-0'
                                }
                              >
                                Salary
                              </p>
                            </Row>
                          </div>
                        </Col>
                        <Col
                          xs={6}
                          sm={0}
                          align="middle"
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <div className="pl-2 ac-card-list-item w-100">
                            <Row>
                              <a
                                target="_blank"
                                className="ac-position w-100 m-0"
                                href={`${fileUrl.fileUrlPath}${data.candidate.resume}`}
                                style={{
                                  textDecoration: 'none',
                                  paddingTop: '0',
                                  paddingBottom: '0',
                                  textAlign: 'center',
                                  verticalAlign: 'bottom',
                                }}
                                rel="noreferrer"
                              >
                                <span>
                                  {/* <LinkOutlined style={{color: 'red', height: '14px', width: '14px'}}/> */}
                                  <img
                                    src="../link.svg"
                                    style={{ width: 14 }}
                                    alt=""
                                  />
                                </span>
                                <span
                                  className="service-s-desc"
                                  style={{
                                    paddingLeft: '8px',
                                    verticalAlign: 'middle',
                                    fontSize: '0.9vw',
                                    color: 'red',
                                  }}
                                >
                                  View Resume PDF
                                </span>
                              </a>
                         
                            </Row>
                            <Row>
                              <p
                                className={
                                  'w-100 input-label-field-custom-type-1 m-0'
                                }
                              >
                                Resume
                              </p>
                            </Row>
                          </div>
                        </Col>
                        <Col
                          xs={6}
                          sm={0}
                          align="middle"
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <div className="pl-2 ac-card-list-item w-100">
                            <Row>
                              <p
                                target="_blank"
                                className="ac-position w-100 m-0"
                                onClick={() => {
                                  handleOpen();
                                  setSelectedCandidate(data?.candidate);
                                }}
                                // href={`${fileUrl.fileUrlPath}${data.candidate.resume}`}
                                style={{
                                  textDecoration: 'none',
                                  paddingTop: '0',
                                  paddingBottom: '0',
                                  textAlign: 'center',
                                  verticalAlign: 'bottom',
                                  cursor: 'pointer',
                                }}
                                rel="noreferrer"
                              >
                                <span>
                                  {/* <LinkOutlined style={{color: 'red', height: '14px', width: '14px'}}/> */}
                                  <img
                                    src="../link.svg"
                                    style={{ width: 14 }}
                                    alt=""
                                  />
                                </span>
                                <span
                                  className="service-s-desc"
                                  style={{
                                    paddingLeft: '8px',
                                    verticalAlign: 'middle',
                                    fontSize: '0.9vw',
                                    color: 'red',
                                  }}
                                >
                                  More Info
                                </span>
                              </p>
                            </Row>
                            <Row>
                              <p
                                className={
                                  'w-100 input-label-field-custom-type-1 m-0'
                                }
                              >
                                Information
                              </p>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                  </Row>
                </Card>
              </div>
            ))}
        </>
      )}
      <Modal
        title="Information"
        className="service-name"
        visible={open}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
        maskStyle={{
          backgroundColor: '#00000073',
        }}
      >
        <Paragraph
          className={'input-label-field-custom-type-1 m-0'}
          style={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {selectedCandidate?.information}
        </Paragraph>
      </Modal>
    </>
  );
};

export default Applied_candidates_new_details;
