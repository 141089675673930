import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  Select,
} from 'antd';
import APIManager from '../../APIManager/index';
import userDefaultPic from '../asset/user.svg';
import { ImageUrl } from '../../Shared/imageUrlPath';
import { CURRENCY } from '../constants/currency';

import { message } from 'antd';
const { TextArea } = Input;
const { Option } = Select;

class SubcontractForm extends Component {
  formRef = React.createRef();
  state = {
    price: '',
    uploadedImage: userDefaultPic,
    profile_url: '',
    photo_name: 'UPLOAD IMAGE',
    allCandidate: [],
    resume: '',
    documents: '',
    change_index: '',
    editcandidate: false,
    comp_name: '',
    uploadResume: '',
    uploadDocument: '',
    activeForm: true,
    singleCandidate: true,
    initialValues: {
      name: '',
      email: '',
      experience: '',
      current_position: '',
      current_company: '',
      availability: '',
      price: '',
      information: '',
      currency: '',
      jobPayment: '',
    },
  };
  componentDidMount() {
    this.initialData();
    this.props.alterSaveButton(false);
  }
  initialData = () => {
    var ls = require('local-storage');
    let data = ls.get('persist:root');
    if (data !== undefined) {
      data = JSON.parse(data.AuthReducer);
      let actual_data = data?.user?.data?.data;
      const comp_name = actual_data?.comp_info?.comp_name;
      let initialValues = this.state.initialValues;
      initialValues.current_company = comp_name;
      this.setState({
        initialValues: initialValues,
      });
      this.formRef.current.setFieldsValue(initialValues);
    }
  };
  clickSubmitClick = () => {
    const fieldData = this.formRef.current.getFieldsValue().name;
    if (this.state.allCandidate.length > 0 && fieldData.length === 0) {
      this.props.clickSubmit(this.state.allCandidate);
    } else {
      message.error('Please Add Candidate');
    }
  };

  onChangeResume = (file) => {
    if (file) {
      this.setState({
        contentType: file.type,
        uploadResume: file,
      });
      const uploadParams = {
        files: [
          {
            requestType: 'CandidateProfile',
            contentType: file.type,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            this.setState({ uploadUrl: data.url, resume: data.fileName });
          });
          this.uploadResumeFile();
        }
      });
    }
  };
  onChangeDocument = (file) => {
    if (file) {
      this.setState({
        contentType: file.type,
        uploadDocument: file,
      });
      const uploadParams = {
        files: [
          {
            requestType: 'CandidateProfile',
            contentType: file.type,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            this.setState({ uploadUrl: data.url, documents: data.fileName });
          });
          this.uploadDocumentFile();
        }
      });
    }
  };
  uploadResumeFile = () => {
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.uploadResume,
    })
      .then((resp) => {})
      .catch((err) => console.log(err));
  };
  uploadDocumentFile = () => {
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.uploadDocument,
    })
      .then((resp) => {})
      .catch((err) => console.log(err));
  };

  editAnotherCandidate = (index) => {
    let all = this.state.allCandidate;
    let initialValues = { ...all[index] };
    this.setState({
      change_index: index,
      editcandidate: true,
      initialValues: initialValues,
      resume: all[index].resume,
      activeForm: false,
    });
    this.formRef.current.setFieldsValue(initialValues);
    if (all[index].profileData) {
      this.setState({
        uploadedImage: ImageUrl.imageUrlPath + all[index].profileData,
        profile_url: all[index].profileData,
        photo_name: all[index].profile_name,
      });
    }
  };
  addEditAnotherCandi = (values) => {
    let all = this.state.allCandidate;
    const index = this.state.change_index;
    all[index] = values;
  };

  addAnotherCandidate = (values) => {
    let all = this.state.allCandidate;
    const index = this.state.change_index;
    if (index !== '') {
      values['resume'] = this.state.resume;
      values['documents'] = this.state.documents;
      if (this.state.profile_url !== '') {
        values['profileData'] = this.state.profile_url;
        values['profile_name'] = this.state.photo_name;
      }
      values['applied_for_job'] = this.props.id;

      all[index] = values;
      this.setState({
        allCandidate: all,
        resume: '',
        documents: '',
        profile_url: '',
        photo_name: 'UPLOAD IMAGE',
        change_index: '',
        editcandidate: false,
        uploadedImage: userDefaultPic,
        activeForm: false,
      });

      const resetValues = {
        name: '',
        email: '',
        experience: '',
        current_position: '',
        availability: '',
        price: '',
        information: '',
        currency: '',
        jobPayment: '',
      };
      this.formRef.current.setFieldsValue(resetValues);
    } else {
      if (this.state.resume !== '') {
        if (values.name) {
          values['resume'] = this.state.resume;
          values['documents'] = this.state.documents;
          if (this.state.profile_url !== '') {
            values['profileData'] = this.state.profile_url;
            values['profile_name'] = this.state.photo_name;
          }
          values['applied_for_job'] = this.props.id;
          let data = values;
          all.push(data);
          this.setState({
            allCandidate: all,
            resume: '',
            documents: '',
            profile_url: '',
            photo_name: 'UPLOAD IMAGE',
            uploadedImage: userDefaultPic,
            activeForm: false,
            uploadDocument: '',
            uploadResume: '',
            price: '',
          });
          const resetValues = {
            name: '',
            email: '',
            experience: '',
            position: '',
            location: '',
            availability: '',
            price: '',
            information: '',
            resume: '',
            documents: '',
            jobPayment: '',
            currency: '',
          };
          this.formRef.current.setFieldsValue(resetValues);
        }
      } else {
        message.error('Please Upload Resume');
        // this.formRef.current.resetFields();
      }
    }
    if (this.state.singleCandidate) this.props.handleFormSubmit();
  };

  removeCandidate = (index) => {
    // let newCandidates = this.state.allCandidate
    // newCandidates = newCandidates.splice(index, 1);
    this.state.allCandidate.splice(index, 1);
    this.setState({ allCandidate: this.state.allCandidate });
  };

  priceChange = (e) => {
    this.setState({ price: e.target.value });
  };

  render() {
    let initialValues = this.state.initialValues;
    // initialValues.current_company = this.state.comp_name
    return (
      <>
        {this.state.allCandidate.length !== 0 ? (
          <>
            <ul className="company_form_1">
              {this.state.allCandidate?.map((val, index) => (
                <li
                  // onClick={() => this.editAnotherCandidate(index)}
                  key={index}
                >
                  <span
                    onClick={() => this.editAnotherCandidate(index)}
                    className="m-0 applied-candidate-name"
                  >
                    {val.name}
                  </span>
                  <span
                    onClick={() => this.removeCandidate(index)}
                    className="apply-job-candidate-remove"
                  >
                    <img
                      src={process.env.PUBLIC_URL + '/remove-candidate.png'}
                      alt='remove_candidate'
                    />
                  </span>
                </li>
              ))}
            </ul>
          </>
        ) : null}
        <Row>
          <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
            {this.props.title}
          </p>
          <p style={{ color: '#EE5050', fontSize: '2vw' }}>&nbsp;&bull;</p>
          <p style={{ color: '#EE5050', marginTop: '8px' }}>
            &nbsp;&nbsp;{this.props.comp_name}
          </p>
        </Row>
        <Row>
          <p className="job-card-7" style={{ textTransform: 'capitalize' }}>
            {this.props.jobtype}
          </p>
        </Row>
        <div className="row mb-signup-1 youare">
          <p className="w-100 you_are_cls">Apply with</p>
          <div className="col-6 pl-0">
            <div className="form-check form-check-inline">
              {this.state.singleCandidate ? (
                <img
                  style={{ width: '100%' }}
                  src={process.env.PUBLIC_URL + '/checkbox-filled.png'}
                  alt=""
                />
              ) : (
                <img
                  onClick={() => {
                    this.setState({ singleCandidate: true });
                    this.props.alterSaveButton(false);
                  }}
                  style={{ width: '100%' }}
                  src={process.env.PUBLIC_URL + '/checkbox-empty.png'}
                  alt=""
                />
              )}
            </div>
            <span style={{ position: 'absolute' }}>Single Candidate</span>
          </div>
          <div className="col-6 ">
            <div className="form-check form-check-inline">
              {this.state.singleCandidate ? (
                <img
                  onClick={() => {
                    this.setState({ singleCandidate: false });
                    this.props.alterSaveButton(true);
                  }}
                  style={{ width: '100%' }}
                  src={process.env.PUBLIC_URL + '/checkbox-empty.png'}
                  alt=""
                />
              ) : (
                <img
                  style={{ width: '100%' }}
                  src={process.env.PUBLIC_URL + '/checkbox-filled.png'}
                  alt=""
                />
              )}
            </div>
            <span style={{ position: 'absolute' }}>Multiple Candidate</span>
          </div>
        </div>

        <Form
          initialValues={initialValues}
          ref={this.formRef}
          onFinish={this.addAnotherCandidate}
        >
          <Row style={{ marginTop: 40 }}>
            <label className="input_label_profile">Name</label>
            <Form.Item
              style={{ width: '100%', margin: 0 }}
              rules={[{ required: true, message: 'Required field' }]}
              name="name"
            >
              <Input
                name="name"
                // value={values.name}
                // onChange={e => this.setState({name:e.target.value})}
                className="input-field-custom-type-1-left input-border"
                placeholder="Enter name"
              />
            </Form.Item>
          </Row>
          <Row>
            <label className="input_label_profile">Experience (in years)</label>
            <Form.Item
              style={{ width: '100%', margin: 0 }}
              rules={[{ required: true, message: 'Required field' }]}
              name="experience"
            >
              <Input
                type="number"
                // value={values.experience}
                //  value={this.state.experience}
                // onChange={e => this.setState({experience:e.target.value})}
                // onKeyDown={onlyNumber}
                className="input-field-custom-type-1-left input-border"
                placeholder="Enter experience"
              />
            </Form.Item>
          </Row>
          <Row>
            <label className="input_label_profile">Email</label>
            <Form.Item
              style={{ width: '100%', margin: 0 }}
              rules={[{ required: true, message: 'Required field' }]}
              name="email"
            >
              <Input
                name="email"
                className="input-field-custom-type-1-left input-border"
                placeholder="Email to get in touch with you"
              />
            </Form.Item>
          </Row>
          <Row>
            <Col span={24} className="form-padding-right">
              <label className="input_label_profile">Current position</label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[{ required: true, message: 'Required field' }]}
                name="position"
              >
                <Input
                  className="input-field-custom-type-1-left input-border"
                  placeholder="Enter position"
                />
              </Form.Item>
            </Col>
            <Col span={12} className="form-padding-right">
              <label className="input_label_profile">Location</label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[{ required: true, message: 'Required field' }]}
                name="location"
              >
                <Input
                  // name="availability"
                  // value={this.state.availability}
                  // onChange={e => this.setState({availability:e.target.value})}
                  className="input-field-custom-type-1-left input-border"
                  placeholder=""
                />
              </Form.Item>
            </Col>
            <Col span={12} className="form-padding-right">
              <label className="input_label_profile">Availability</label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[{ required: true, message: 'Required field' }]}
                name="availability"
              >
                <Input
                  // name="availability"
                  // value={this.state.availability}
                  // onChange={e => this.setState({availability:e.target.value})}
                  className="input-field-custom-type-1-left input-border"
                  placeholder="Enter availability"
                />
              </Form.Item>
            </Col>
            <Col span={12} className="form-padding-right">
              <label className="input_label_profile">Salary</label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[{ required: true, message: 'Required field' }]}
                name="price"
              >
                <Row>
                  <Col span={6}>
                    <Form.Item name="currency">
                      <Select
                        className="currency-select"
                        style={{ width: '100%' }}
                      >
                        {CURRENCY?.map((curr) => (
                          <Option value={curr.name}>{curr.symbol}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={18}>
                    <Input
                      className="input-field-custom-type-1-left input-border"
                      placeholder="Enter salary"
                      value={this.state.price}
                      type="number"
                      // defaultValue={this.state.initialValues.price}
                      onChange={this.priceChange}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span={12} className="form-padding-right">
              <label className="input_label_profile">Job Payment</label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[{ required: true, message: 'Required field' }]}
                name="jobPayment"
              >
                <Select className="input-field-custom-type-1-left input border">
                  <Option value="Hourly">Hourly</Option>
                  <Option value="Daily">Daily</Option>
                  <Option value="Fixed">Fixed</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} className="form-padding-right">
              <label className="input_label_profile">Upload Resume</label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[{ required: true, message: 'Required field' }]}
                name="resume"
              >
                <Input
                  accept="pdf/*"
                  id="raised-button-file"
                  type="file"
                  className="input-field-custom-type-1-left input-border"
                  onChange={(event) => {
                    this.onChangeResume(event.target.files[0]);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} className="form-padding-right">
              <label className="input_label_profile">Upload Documents</label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[{ required: false, message: 'Required field' }]}
                name="documents"
              >
                <Input
                  accept="pdf/*"
                  id="raised-button-file"
                  type="file"
                  className="input-field-custom-type-1-left input-border"
                  onChange={(event) => {
                    this.onChangeDocument(event.target.files[0]);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <label className="input_label_profile">Information</label>
            <Form.Item
              style={{ width: '100%', margin: 0 }}
              rules={[{ required: false, message: 'Required field' }]}
              name="information"
            >
              <TextArea
                className="input-field-custom-type-1-left input-border"
                placeholder="Enter Information"
              ></TextArea>
            </Form.Item>
          </Row>
          {!this.state.singleCandidate &&
            (this.state.editcandidate ? (
              <Button
                className="add_candidate_data_button"
                htmlType={this.addEditAnotherCandi}
              >
                Save Candidate
              </Button>
            ) : (
              <Button
                className="add_candidate_data_button"
                htmlType={this.addAnotherCandidate}
              >
                Add Candidate
              </Button>
            ))}
          {this.state.singleCandidate && (
            <Button
              className="add_candidate_data_button"
              htmlType={this.addAnotherCandidate}
            >
              Submit
            </Button>
          )}
        </Form>
      </>
    );
  }
}
export default SubcontractForm;
