import React from 'react';
import { Row, Col, Card } from 'antd';
import cardDefaultPic from '../../Components/asset/card.svg';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styles from './sponsoredProducts.module.css';

const SponsoredListCard = ({
  name,
  sponsoredBy,
  sponsoredOn,
  sponsorExpiresAt,
  totalViewsCount,
  initialBudget,
  remaningBudget,
  imageUrl,
  serviceStatus,
}) => {
  const history = useHistory();

  return (
    <div>
      <Card className={`services-card ${styles.serviceCard}`} key="kkk">
        <Row gutter={15}>
          <Col md={4} xs={5}>
            <img
              alt="example"
              className={styles.profileImg}
              src={
                imageUrl
                  ? `https://sendbizbucket.s3.eu-west-3.amazonaws.com/${imageUrl}`
                  : cardDefaultPic
              }
            />
          </Col>
          <Col md={20} xs={18}>
            <Row>
              <h4 style={{ fontWeight: '700', textTransform: 'capitalize' }}>
                {name}
              </h4>
            </Row>
            <Row>
              <Col
                md={16}
                xs={24}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <span>
                  <img
                    src="../../../user-circle.png"
                    alt="img"
                    style={{ width: 20, marginRight: '6px' }}
                  />
                </span>
                <span
                  style={{
                    fontSize: '20px',
                    color: '#000000',
                    fontWeight: '800',
                  }}
                >
                  Sponsored by:
                </span>{' '}
                <span
                  style={{
                    fontSize: '20px',

                    color: 'red',
                    fontWeight: '800',
                  }}
                >
                  &nbsp;{sponsoredBy}
                </span>
              </Col>
              {/* for small screen only */}
              <Col md={0} xs={24}>
                <Col md={0} xs={24}>
                  <Row>
                    <Col span={10}>
                      <span
                        style={{
                          fontSize: '20px',
                          color: '#000000',
                          fontWeight: '700',
                        }}
                      >
                        Amount:
                      </span>
                    </Col>
                    <Col span={6} style={{ color: 'red' }}>
                      <span
                        style={{
                          fontSize: '20px',
                          fontWeight: '700',
                          color: 'red',
                        }}
                      >
                        ${' ' + (totalViewsCount * 0.2).toFixed(2)}
                      </span>
                    </Col>

                    <Col span={8}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '5px',
                          paddingLeft: '20%',
                        }}
                      >
                        <img
                          src="../../../eye@2x.png"
                          alt="img"
                          style={{ width: 22, marginRight: '6px' }}
                        />
                        <span
                          style={{
                            font: 'normal normal 400 18px/20px Gilroy semiBold',
                          }}
                        >
                          {totalViewsCount}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col md={4} xs={10}>
                <span
                  style={{
                    fontSize: '20px',
                    color: '#000000',
                    fontWeight: '700',
                  }}
                >
                  Start Date
                </span>
              </Col>
              <Col md={4} xs={12} style={{ color: 'red' }}>
                <span
                  style={{ fontSize: '20px', fontWeight: '700', color: 'red' }}
                >
                  {moment(sponsoredOn).format('DD MMM YY')}
                </span>
              </Col>

              {/* for small screen only */}
              <Col md={0} xs={10}>
                <span
                  style={{
                    fontSize: '20px',
                    color: '#000000',
                    fontWeight: '700',
                  }}
                >
                  End Date
                </span>
              </Col>
              <Col md={0} xs={12} style={{ color: 'red' }}>
                <span
                  style={{ fontSize: '20px', fontWeight: '700', color: 'red' }}
                >
                  {moment(sponsorExpiresAt).format('DD MMM YY')}
                </span>
              </Col>
            </Row>
            <Row style={{ marginTop: '25px' }}>
              <Col md={3} xs={0}>
                <span
                  style={{
                    fontSize: '18px',
                    color: '#000000',
                    fontWeight: '700',
                    marginLeft: '29px',
                  }}
                >
                  Amount:
                </span>
              </Col>
              <Col md={7} xs={0} style={{ color: 'red' }}>
                <span
                  style={{
                    fontSize: '20px',
                    fontWeight: '700',
                    color: 'red',
                    marginLeft: '29px',
                  }}
                >
                  ${' ' + (totalViewsCount * 0.2).toFixed(2)}
                </span>
              </Col>

              <Col md={6} xs={0}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>
                    <img
                      src="../../../eye@2x.png"
                      alt="img"
                      style={{ width: 16, marginRight: '6px' }}
                    />
                  </span>
                  {totalViewsCount}
                </div>{' '}
              </Col>
              <Col md={4} xs={0}>
                <span
                  style={{
                    fontSize: '20px',
                    color: '#000000',
                    fontWeight: '700',
                  }}
                >
                  End Date
                </span>
              </Col>
              <Col md={4} xs={0} style={{ color: 'red' }}>
                <span
                  style={{ fontSize: '20px', fontWeight: '700', color: 'red' }}
                >
                  {moment(sponsorExpiresAt).format('DD MMM YY')}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default SponsoredListCard;
