import React, { Component } from 'react';
import {
  Row,
  Col,
  Input,
  Card,
  Carousel,
  Comment,
  List,
  Button,
  Form,
  message,
  Image,
} from 'antd';
import {
  SendOutlined,
  LeftOutlined,
  CalendarFilled,
  ClockCircleOutlined,
} from '@ant-design/icons';
import '../topNav.css';
import styles from './newsDetails.module.css';
import APIManager from '../../../APIManager';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import NewsComp from './News_comp';
import './event_details.css';
import moment from 'moment';
import { fileUrl } from '../../../Shared/imageUrlPath';
import cardDefaultPic from '../../asset/card.svg';
import { withRouter, NavLink } from 'react-router-dom';
import VideoSection from '../../videoSection/VideoSection';
import { Helmet } from 'react-helmet';
const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    className="event-comment-list"
    dataSource={comments}
    header={`${comments.length > 1 ? 'Comments' : 'Comment'} (${
      comments.length
    }) `}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);

const Editor = ({ submitting, onSubmit, formRef }) => (
  <>
    <Form
      onFinish={onSubmit}
      ref={formRef}
      style={{ display: 'flex', marginTop: '40px' }}
    >
      <Form.Item
        name="comment"
        style={{ display: 'inline-block', width: '100%' }}
      >
        <TextArea
          rows={1}
          className="comment-textarea"
          placeholder="comment here"
          autoSize
        />
      </Form.Item>
      <div className="comment-btn-div">
        <Form.Item style={{ display: 'inline-block' }}>
          <Button
            htmlType="submit"
            className="comment-btn"
            loading={submitting}
            icon={
              <SendOutlined
                style={{
                  color: '#ffffff',
                  fontSize: '24px',
                  top: '-4px',
                  position: 'relative',
                }}
                rotate={-45}
              />
            }
          ></Button>
        </Form.Item>
      </div>
    </Form>
  </>
);

class NewsDetail extends Component {
  formRef = React.createRef();
  state = {
    data: null,
    resultsPerPage: 10,
    totalData: null,
    totalPages: null,
    current_page: null,
    serviceid: '',
    loading: true,
    submitting: false,
    allProductsData: null,
    isLoading: false,
    comments: [
      {
        author: 'Priyanshi Tater',
        avatar:
          'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        content: <span>I love the Sendbiz platform</span>,
        datetime: moment().fromNow(),
      },
    ],
  };

  componentDidMount() {
    var ls = require('local-storage');
    var newsId = ls.get('newsId');
    this.setState({
      isLoading: true,
    });
    const { id } = this.props.match.params;
    APIManager.getOneNews(id).then((response) => {
      if (response.data.isSuccess) {
        console.log(response.data.data);
        this.setState({
          isLoading: false,
          data: response.data.data,
        });
      }
    });
    APIManager.getAllServices();
    APIManager.jobList();
    APIManager.getAllProducts()
      .then((resp) => {
        if (resp.data.isSuccess) {
          this.setState({
            allProductsData: resp.data.data,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        message.error('Something went wrong');
        this.setState({
          isLoading: false,
        });
      });
  }
  handleChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };
  EventListingRender = () => {
    this.props.history.goBack();
  };
  renderJobs = () => {
    this.props.history.push({
      pathname: '/all_jobs',
    });
  };
  renderProducts = () => {
    this.props.history.push({
      pathname: '/products',
    });
  };
  renderServices = () => {
    this.props.history.push({
      pathname: '/services',
    });
  };
  changed = (a, b, c) => {};
  handleSubmit = (values) => {
    if (!values.comment) {
      return;
    }

    this.setState({
      submitting: true,
    });
    this.formRef.current.resetFields();
    setTimeout(() => {
      this.setState({
        submitting: false,
        comments: [
          ...this.state.comments,
          {
            author: 'Priyanshi Tater',
            avatar:
              'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
            content: <span>{values.comment}</span>,
            datetime: moment().fromNow(),
          },
        ],
      });
    }, 1000);
  };

  render() {
    console.log(this.state.data?.imagePath);
    const socialMedia =
      this.state.data && this.state.data.companyData
        ? this.state.data.companyData.social_link
        : null;

    const data = this.props.location.event_obj
      ? this.props.location.event_obj
      : '';
    const { comments, submitting } = this.state;
    const title = this.state.data ? this.state.data.title : '';
    const companyName =
      this.state?.data?.companyData?.comp_info?.bus_name || '';
    const readingTime = this.state.data ? this.state.data.avgReadTime : '';
    const createdAt = this.state.data ? this.state.data.createdAt : '';
    const comment = this.state.data ? this.state.data.comments : '';
    const description = this.state.data ? this.state.data.description : '';
    const imagePath = this.state.data ? this.state.data.image : [];
    const allServiceData = this.props.servicedata
      ? this.props.servicedata.data
      : '';
    const allJobs = this.props.jobdata ? this.props.jobdata.data : '';
    const RecentServices = allServiceData.slice(0, 3);
    const recentJobs = allJobs.slice(0, 3);
    const recentProducts = this.state.allProductsData
      ? this.state.allProductsData.slice(0, 3)
      : '';
    const loading = this.props.isloading;

    console.log('Data', this.state);
    return (
      <>
        <Helmet>
          <title>{`${title}-${companyName}-Sendbiz`}</title>
          <meta
            property="og:title"
            content={`${title}-${companyName}-Sendbiz`}
          />
          <meta
            name="twitter:card"
            content={`${title}-${companyName}-Sendbiz`}
          />

          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </Helmet>
        {loading || this.state.isLoading ? (
          <div
            className={'d-flex justify-content-center'}
            style={{ marginTop: '5%' }}
          >
            <Spin />
          </div>
        ) : (
          <>
            <div className={`detail-outer-div ${styles.mainContainer}`}>
              <div
                style={{ paddingBottom: '16px' }}
                className={styles.gobackIcon}
              >
                <LeftOutlined
                  className="back-icon"
                  onClick={this.EventListingRender}
                />
                <span className="back-btn" onClick={this.EventListingRender}>
                  Go back
                </span>
              </div>
              <Row gutter={32} className={styles.parentContainer}>
                <Col md={8} xs={24} className={styles.allCards}>
                  <div className="bg-white p-3 s-detail-left-card">
                    <Row className="row custom_row">
                      <Col
                        span={12}
                        className=" side-navbar-heading-company-1 pl-0"
                      >
                        Recent Jobs
                      </Col>
                      <Col
                        span={12}
                        className="p-0 side-navbar-heading-company-2 custom-view-all"
                        onClick={this.renderJobs}
                      >
                        View All&nbsp;&nbsp;
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      </Col>
                    </Row>
                    <ul className="icons-listing p-0">
                      {recentJobs &&
                        recentJobs?.map((data) => (
                          <li>
                            <Card
                              bordered={false}
                              className="w-100 p-0 sidebar-card"
                            >
                              <Row>
                                <Col span={7}>
                                  <img
                                    src={
                                      data.companyDetail
                                        ? data?.companyDetail?.map(
                                            (data) =>
                                              fileUrl.fileUrlPath +
                                              data.logo.name
                                          )
                                        : cardDefaultPic
                                    }
                                    style={{
                                      height: '100px',
                                      width: '100px',
                                      borderRadius: '10px',
                                      objectFit: 'cover',
                                    }}
                                    className="feature_jobs_company"
                                    alt="feature jobs"
                                  />
                                </Col>
                                <Col span={1}></Col>
                                <Col span={16}>
                                  <p
                                    style={{ cursor: 'pointer' }}
                                    className="partner-text mb-0"
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: `/apply-for/${data._id}`,
                                        state: { jobId: data._id },
                                      });
                                    }}
                                  >
                                    <span
                                      className="partner-text-span-1"
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      {data &&
                                        data.title &&
                                        data.title.charAt(0).toUpperCase() +
                                          data.title.slice(1)}
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                      paddingLeft: '15px',
                                    }}
                                  >
                                    <span>
                                      <NavLink
                                        to={`/comp-id${data?.companyDetail[0]?._id}`}
                                        onClick={(e) => e.stopPropagation()}
                                        target="_blank"
                                        className="job-card-4 comp-hover visit_cand_personal_11"
                                        style={{
                                          textDecoration: 'underline',
                                          fontSize: 'initial',
                                          cursor: 'pointer',
                                          marginLeft: '0px',
                                        }}
                                      >
                                        {data.companyDetail[0].comp_info.bus_name
                                          .charAt(0)
                                          .toUpperCase() +
                                          data.companyDetail[0].comp_info.bus_name.slice(
                                            1
                                          )}
                                      </NavLink>
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                      paddingLeft: '15px',
                                    }}
                                  >
                                    <span className="partner-text-span-2">
                                      {moment(data.createdAt).format(
                                        'DD MMM YYYY'
                                      )}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                            </Card>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="bg-white p-3 mt-4 s-detail-left-card">
                    <Row className="w-100 custom_row">
                      <Col
                        span={12}
                        className="side-navbar-heading-company-1 pl-0"
                      >
                        Recent Services
                      </Col>
                      <Col
                        span={12}
                        className="side-navbar-heading-company-2 custom-view-all"
                        onClick={this.renderServices}
                      >
                        View All&nbsp;&nbsp;
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      </Col>
                    </Row>
                    <div className="column">
                      {RecentServices &&
                        RecentServices?.map((data) => (
                          <ul className="icons-listing p-0">
                            <li>
                              <Card
                                bordered={false}
                                className="w-100 p-0 sidebar-card"
                              >
                                <Row>
                                  <Col span={7}>
                                    <img
                                      src={
                                        data.media.length !== 0
                                          ? fileUrl.fileUrlPath +
                                            data.media[0].fileName
                                          : cardDefaultPic
                                      }
                                      style={{
                                        height: '100px',
                                        width: '100px',
                                        borderRadius: '10px',
                                        objectFit: 'cover',
                                      }}
                                      className="feature_jobs_company"
                                      alt="feature jobs"
                                    />
                                  </Col>
                                  <Col span={1}></Col>
                                  <Col span={16}>
                                    <p
                                      className="partner-text mb-0"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        var ls = require('local-storage');
                                        ls.set('serviceId', data._id);
                                        this.props.history.push({
                                          pathname: `/services/detail/${data._id}`,
                                          id: data._id,
                                        });
                                      }}
                                    >
                                      <span
                                        className="partner-text-span-1"
                                        style={{
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                        }}
                                      >
                                        {data.name &&
                                          data.name.charAt &&
                                          data.name.charAt(0).toUpperCase() +
                                            data.name.slice(1)}
                                      </span>
                                    </p>
                                    <p
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        paddingLeft: '15px',
                                      }}
                                    >
                                      <span>
                                        <NavLink
                                          to={`/comp-id${data?.companyData?._id}`}
                                          onClick={(e) => e.stopPropagation()}
                                          target="_blank"
                                          className="job-card-4 comp-hover visit_cand_personal_11"
                                          style={{
                                            textDecoration: 'underline',
                                            fontSize: 'initial',
                                            cursor: 'pointer',
                                            marginLeft: '0px',
                                          }}
                                        >
                                          {data.companyData.comp_info.bus_name
                                            .charAt(0)
                                            .toUpperCase() +
                                            data.companyData.comp_info.bus_name.slice(
                                              1
                                            )}
                                        </NavLink>
                                      </span>
                                    </p>
                                    <p
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        paddingLeft: '15px',
                                      }}
                                    >
                                      <span className="partner-text-span-2">
                                        {moment(data.createdAt).format(
                                          'DD MMM YYYY'
                                        )}
                                      </span>
                                    </p>
                                  </Col>
                                </Row>
                              </Card>
                            </li>
                          </ul>
                        ))}
                    </div>
                  </div>

                  <div className="bg-white p-3 mt-4 s-detail-left-card">
                    <Row className="row custom_row">
                      <Col
                        span={12}
                        className="side-navbar-heading-company-1 pl-0"
                      >
                        Recent Products
                      </Col>
                      <Col
                        span={12}
                        className="side-navbar-heading-company-2 custom-view-all"
                        onClick={this.renderProducts}
                      >
                        View All&nbsp;&nbsp;
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      </Col>
                    </Row>
                    <ul className="icons-listing p-0">
                      {recentProducts &&
                        recentProducts?.map((data) => (
                          <li>
                            <Card
                              bordered={false}
                              className="w-100 p-0 sidebar-card"
                            >
                              <Row>
                                <Col span={7}>
                                  <img
                                    src={
                                      data.media.length !== 0
                                        ? fileUrl.fileUrlPath +
                                          data.media[0].fileName
                                        : cardDefaultPic
                                    }
                                    style={{
                                      height: '100px',
                                      width: '100px',
                                      borderRadius: '10px',
                                      objectFit: 'cover',
                                    }}
                                    className="feature_jobs_company"
                                    alt="feature jobs"
                                  />
                                </Col>
                                <Col span={1}></Col>
                                <Col span={16}>
                                  <p
                                    className="partner-text mb-0"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      this.props.history.push({
                                        pathname: `/products/detail/${data._id}`,
                                        product_obj: data,
                                      })
                                    }
                                  >
                                    <span
                                      className="partner-text-span-1"
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      {data.name &&
                                        data.name.charAt &&
                                        data.name.charAt(0).toUpperCase() +
                                          data.name.slice(1)}
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                      paddingLeft: '15px',
                                    }}
                                  >
                                    <span>
                                      <NavLink
                                        to={`/comp-id${data?.companyData?._id}`}
                                        onClick={(e) => e.stopPropagation()}
                                        target="_blank"
                                        className="job-card-4 comp-hover visit_cand_personal_11"
                                        style={{
                                          textDecoration: 'underline',
                                          fontSize: 'initial',
                                          cursor: 'pointer',
                                          marginLeft: '0px',
                                        }}
                                      >
                                        {data?.companyData?.comp_info?.bus_name
                                          .charAt(0)
                                          .toUpperCase() +
                                          data?.companyData?.comp_info?.bus_name.slice(
                                            1
                                          )}
                                      </NavLink>
                                    </span>
                                  </p>
                                  <p
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                      paddingLeft: '15px',
                                    }}
                                  >
                                    <span className="partner-text-span-2">
                                      {moment(data.createdAt).format(
                                        'DD MMM YYYY'
                                      )}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                            </Card>
                          </li>
                        ))}
                    </ul>
                  </div>
                  {/* </Card> */}
                </Col>

                <Col md={16} xs={24}>
                  <Card className="s-detail-r-card" bordered={false}>
                    <Carousel
                      afterChange={this.onChange}
                      className="event-carousel"
                      //  autoplay
                      arrows
                    >
                      {imagePath &&
                        imagePath
                          ?.filter((data) => data.fileType.includes('image'))
                          ?.map((data) => (
                            <div className={'d-flex justify-content-center'}>
                              <Image
                                src={fileUrl.fileUrlPath + data.fileName}
                                alt="event"
                                className="event-carousel-img"
                                width={'100%'}
                                height={350}
                                style={{ objectFit: 'cover' }}
                              ></Image>
                            </div>
                          ))}
                    </Carousel>
                    <br />
                    <Row style={{ margin: '30px 0 20px' }}>
                      <Col md={18} xs={24} style={{ paddingLeft: '10px' }}>
                        <h4
                          className="event-detail-name"
                          style={{ fontSize: '28px', marginBottom: '15px' }}
                        >
                          {title &&
                            title.charAt &&
                            title.charAt(0).toUpperCase() + title.slice(1)}
                        </h4>
                        <p>
                        <NavLink
                          to={`/comp-id${data?.companyData?._id}`}
                          onClick={(e) => e.stopPropagation()}
                          target="_blank"
                          className="job-card-4 comp-hover visit_cand_personal_11"
                          style={{
                            // textDecoration: 'underline',
                            fontSize: 'initial',
                            cursor: 'pointer',
                            paddingBottom: '20px',
                          }}
                        >
                          {companyName &&
                            companyName.charAt &&
                            companyName.charAt(0).toUpperCase() +
                              companyName.slice(1)}
                        </NavLink>
                        </p>
                        <Row>
                          <Col md={12} xs={24}>
                            <ClockCircleOutlined className="service-icons" />
                            <span
                              className="event-detail-date"
                              style={{ fontSize: '18px', marginRight: '35px' }}
                            >
                              {readingTime}
                            </span>
                          </Col>
                          <Col md={12} xs={24} className={styles.calender}>
                            <CalendarFilled className="service-icons" />
                            <span
                              className="event-detail-date"
                              style={{ fontSize: '18px', marginRight: '35px' }}
                            >
                              {moment(createdAt).format('DD MMM YYYY')}
                            </span>
                          </Col>
                        </Row>

                        {imagePath.filter((data) =>
                          data.fileType.includes('video')
                        ).length > 0 && (
                          <VideoSection
                            media={imagePath.filter((data) =>
                              data.fileType.includes('video')
                            )}
                          />
                        )}
                      </Col>
                      <Col md={6} xs={24}>
                        <ul
                          className={`company-main-ul-1 company-visit-profile ${styles.socialMediaParent}`}
                        >
                          {socialMedia &&
                            socialMedia?.map((val, index) => (
                              <>
                                {(val.tag === 'Facebook' ||
                                  val.tag === 'facebook') &&
                                val.link ? (
                                  <li
                                    style={{ paddingRight: 15 }}
                                    onClick={() =>
                                      window.open(val.link, '_blank')
                                    }
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL + '/facebook.png'
                                      }
                                      style={{
                                        fontSize: '30px',
                                        marginRight: '17px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </li>
                                ) : null}
                                {(val.tag === 'Linkedin' ||
                                  val.tag === 'linkedin' ||
                                  val.tag === 'LinkedIn' ||
                                  val.tag === 'linkedIn') &&
                                val.link ? (
                                  <li
                                    style={{ paddingRight: 15 }}
                                    onClick={() =>
                                      window.open(val.link, '_blank')
                                    }
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL + '/linkedin.png'
                                      }
                                      style={{
                                        fontSize: '30px',
                                        marginRight: '17px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </li>
                                ) : null}
                                {(val.tag === 'Twitter' ||
                                  val.tag === 'twitter') &&
                                val.link ? (
                                  <li
                                    style={{ paddingRight: 15 }}
                                    onClick={() =>
                                      window.open(val.link, '_blank')
                                    }
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL + '/twitter.png'
                                      }
                                      style={{
                                        fontSize: '30px',
                                        marginRight: '17px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </li>
                                ) : null}
                              </>
                            ))}
                        </ul>
                      </Col>
                    </Row>
                    <p
                      className="s-detail-short-desc"
                      style={{
                        fontSize: '16px',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {description}
                    </p>
                    <Row></Row>
                  </Card>
                </Col>
              </Row>
            </div>

            <NewsComp />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newsonedata: state.newsReducer.newsonedata,
    jobdata: state.jobManagementReducer.jobdata,
    servicedata: state.ServiceReducer.servicedata,
    isloading: state.newsReducer.isloading,
  };
};
export default connect(mapStateToProps)(NewsDetail);
