import React , { Component } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import { message, Row, Input } from "antd";
import APIManager from "../../APIManager/index";

import { Modal, Button } from 'react-bootstrap';
let formButton=null;
const { TextArea } = Input;
class ResumeModal extends Component{
	constructor(){
		super();
		this.state = {
			formIsValid:false,
			fields: {},
			resume:'',
			resume_file:'',
			initialValues:{title:"",description:""}
		}
	}
	onChange = ( newFileList) =>{
		if(newFileList!== undefined){
			this.setState({resume_file:newFileList,resume:newFileList.name})
		}
  	}
	CandidateResumeUpload = (data) =>{
		let resume = this.state.resume_file;
		if(resume !== ""){
			let formdata = new FormData();
			formdata.append("candidateResume",resume,resume.name)
			formdata.append("title", data.title.trim())
			formdata.append("description", data.description.trim())
			APIManager.CandidateResume(formdata)
			.then((response) => {
				if (response.data.status === "success") {
					this.props.onHide()
					this.props.reloadPage()
					message.info(response.data.message);
					// window.location.reload();
				}
			})
			.catch((err) =>{
				// this.props.onHide()
				message.error("Something went wrong.")
			}
			);
		}
		else{
			// this.props.onHide()
			message.error("Please Upload Resume file");
		}

	}
	CandidateResumeUpdate = (data) =>{
		let resume = this.state.resume_file;
			let formdata = new FormData();
			if(resume){
				formdata.append("candidateResume",resume,resume.name)
			}else{
				formdata.append("name",this.props.resumeName)
			}
			formdata.append("title", data.title.trim())
			formdata.append("description", data.description.trim())
			APIManager.CandidateUpdateResume(formdata, this.props.resumeId)
			.then((response) => {
				if (response.data.status === "success") {
					this.props.onHide()
					this.props.reloadPage()
					message.info(response.data.message);
				}
			})
			.catch((err) =>{
				message.error("Something went wrong.")
			}
			);
	}
	onSubmit = (e, { setSubmitting }) =>{
		if(this.props.resumeId){
			setTimeout(() => {
				setSubmitting(false);
				this.CandidateResumeUpdate(e);
			}, 500);
		}else{
			setTimeout(() => {
				setSubmitting(false);
				this.CandidateResumeUpload(e);
			}, 500);
		}
  		
  	}
  	componentWillMount(){
  		let initialValues = {
			title: this.props.name,
			description: this.props.description,
		}
		this.setState({initialValues:initialValues,})
		
  	}
	clickSubmitClick = () => {
  		formButton.click()
  	}

	deleteClick = ()=>{
		this.setState({resume:'',
		resume_file:''})
	}

	render(){
		return(
	      <Formik
	        initialValues={this.state.initialValues}
	        onSubmit={this.onSubmit}
	        validationSchema={Yup.object().shape({
	          title: Yup.string().trim().required("title is required."),
	          description: Yup.string().trim().required("description is required."),
	          
	        })}
	      >
	        {(formikProps) => {
	          const {
	            values,
	            errors,
	            isSubmitting,
	            handleChange,
	            handleSubmit,
	          } = formikProps;
		return(
				<Modal {...this.props} animation={false} className="password-modal" aria-labelledby="contained-modal-title-vcenter">
			      	<div className="modal-header">
				      	<div className="modal-title h4" id="contained-modal-title-vcenter">
				      		Resume
				      	</div>
				      	<button type="button" onClick={this.props.onHide} className="close">
				      		<span aria-hidden="true"><img src={"./cancel-circle.png"} alt="cancel" /></span>

				      		<span className="sr-only">Close</span>
				      	</button>
			    	</div>
			      <Modal.Body className="show-grid">
			        <form onSubmit={formikProps.handleSubmit}>
	        			<Row>
							<label className="input_label_profile w-100">File</label>
								<label className="custom-file-label-1 resume-upload-card-1 m-0">
							    <input 
								    accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
							    	type="file" 
							    	name="attachment"
							    	className="custom-file-input resume-upload-card-3"
							    	onChange={(event)=>{
												  this.onChange(event.target.files[0])
												}
	                  						} />
							    
							    
							    <i className="fa fa-upload" aria-hidden="true"></i>&nbsp;&nbsp;
							    { this.state.resume.length > 1 ? (
							    	<p className="name-of-resume-file">{this.state.resume}</p>
									):
									this.props.resumeName ? 
									(<p className="name-of-resume-file">{this.props.resumeName}</p>
									) :
									(<p className="name-of-resume-file">UPLOAD RESUME</p>)
								}
								
							    </label>
								
							    
							{errors.attachment && (
		                        <div style={{ color: "red", fontSize: "12px" }}>
		                          {errors.attachment}
		                        </div>
		                      )}
							  {(this.state.resume.length > 1 || this.props.resumeName) ?
							  (<div className="delete_icon" style={{padding: "10%"}}>
							  <img src='./delete-icon.png' onClick={this.deleteClick}/>
							  </div>): ""}
							  
						</Row>
	        			<Row style={{display : 'grid'}}>
							<label className="input_label_profile">Title</label>
							<Input 
								type="text" 
								name="title"
								value={values.title}
								className="input-field-custom-type-1-left" 
								id="inputPassword" 
								maxLength={50}
								onChange={handleChange}
								style={{border : '1px solid #CED4EE'}}
								placeholder="Job title" required />
							{errors.title && (
		                        <div style={{ color: "red", fontSize: "12px" }}>
		                          {errors.title}
		                        </div>
		                    )}
						</Row>
						<Row>
							<label className="input_label_profile">Description</label>
							<TextArea 
								type="text" 
								name="description"
								value={values.description}
								className="input-font-with-border form-control profile-form-control" 
								id="inputPassword" 
								onChange={handleChange} 
								// maxLength={300}
								placeholder="Tell us about yourself" required >
							</TextArea>
							{errors.description && (
		                        <div style={{ color: "red", fontSize: "12px" }}>
		                          {errors.description}
		                        </div>
		                    )}
						</Row>
						<button style={{opacity:0}} type={"submit"} ref={(e) => { formButton = e }}>ADD </button>
			        </form>
			      </Modal.Body>
			      <Modal.Footer>
			      	<button type="button" onClick={this.clickSubmitClick} className="btn btn-dark bold-family btn-save-font cursor">Save Resume</button>
			      </Modal.Footer>
			    </Modal>
			)
		 }}
      </Formik>
		)
	}
}
export default ResumeModal;			