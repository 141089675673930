import React, { Component } from 'react';
import { Row, Col, Card, message, Tooltip } from 'antd';
import Pagination from 'react-js-pagination';
import {
  EnvironmentFilled,
  MessageFilled,
  CalendarFilled,
  LikeFilled,
} from '@ant-design/icons';
import styles from './eventListing.module.css';
import APIManager from '../../../APIManager';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import cardDefaultPic from '../../../Components/asset/card.svg';
import NoData from '../../../PageNotFound/NoData';
import { fileUrl } from '../../../Shared/imageUrlPath';
import { NavLink } from 'react-router-dom';
import { SendOutlined, UserOutlined } from '@ant-design/icons';

const numEachPage = 2;

class EventListingCard extends Component {
  state = {
    minValue: 0,
    maxValue: 2,
    data: [],
    resultsPerPage: 10,
    totalData: null,
    totalPages: null,
    current_page: null,
    loading: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.date !== prevProps.date) {
      this.makeHttpRequestWithPage();
    }
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.makeHttpRequestWithPage();
    }
    if (this.props.dateRange !== prevProps.dateRange) {
      this.makeHttpRequestWithPage();
    }
    if (this.props.company !== prevProps.company) {
      this.makeHttpRequestWithPage();
    }
    if (this.props.current_location?.length > 0 && this.props.current_location !== prevProps.current_location) {
      this.makeHttpRequestWithPage();
    }
  }
  makeHttpRequestWithPage = async (pageNumber = 1) => {
    const date = this.props.date;
    const search = this.props.searchQuery ? this.props.searchQuery : '';
    const dcompany = this.props.company;
    const dateRange = this.props.dateRange.join(',');
    const location = this.props.current_location;
    let country = '';
    let state = '';
    let city = '';
    this.setState({ loading: true });
    if (this.props.current_location.length > 0) {
      const locationArray = this.props.current_location.split(',');
      if (locationArray.length > 0) {
        country = locationArray[locationArray.length - 1];
        state =
          locationArray.length > 1
            ? locationArray[locationArray.length - 2]
            : '';
        city = locationArray.length > 2 ? locationArray[0] : '';
      }
      APIManager.allEventsSortAndSearch(
        pageNumber,
        date,
        search?.toLowerCase(),
        dateRange,
        dcompany,
        city,
        state,
        country,
        this.props.homeSearch,
        this.props.searchLocation,
      )
        .then((resp) => {
          this.props.setPropsEvents(resp.data.data);
          this.setState({
            data: resp.data.data,
            total: resp.data.totalCount,
            totalData: resp.data.totalCount,
            current_page: resp.data.currentPage,
            loading: false,
          });
          if (this.props.searchQuery) {
            this.props.changeMapLocation(resp?.data?.data[0]);
          }
        })
        .catch((err) => message.error(err));
    }
  };
  handleChange = (value) => {
    this.setState({
      minValue: (value - 1) * numEachPage,
      maxValue: value * numEachPage,
    });
  };
  handleLocationChange = (e, value) => {
    e.stopPropagation();
    this.props.changeMapLocation(value);
  };
  render() {
    let renderPageNumbers;
    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / 10); i++) {
        pageNumbers.push(i);
      }
      renderPageNumbers = pageNumbers?.map((number) => {
        return (
          <span
            key={number}
            onClick={() => this.makeHttpRequestWithPage(number)}
            className={
              this.state.current_page === number
                ? 'page-number-btn'
                : 'pagination-container'
            }
          >
            {number}
          </span>
        );
      });
    }

    const loading = this.props.isloading;
    // console.log(this.state.data);
    return (
      <>
        {this.state.loading && (
          <div className={'d-flex justify-content-center mt-5'}>
            <Spin />
          </div>
        )}
        {!this.state.loading && (
          <h3 className={`card-div-head ${styles.eventPageCardsNumberOfData}`}>
            {this.state.data.length > 0
              ? this.state.data.length +
                `${
                  this.state.data.length === 1
                    ? ' Event found'
                    : ' Events found'
                }`
              : ''}
          </h3>
        )}
        <div className="custom-container" style={{ padding: 0 }}>
          {!this.state.loading && this.state.data.length === 0 ? (
            <div>
              <NoData
                text={
                  'Please try using Different Location, Filters or keywords for your search.'
                }
              />{' '}
            </div>
          ) : (
            !this.state.loading &&
            this.state.data
              ?.map((data) => (
                <Card
                  className={`services-card eventpage-maincontainer ${styles.mainContainerOfServiceCards}`}
                  onClick={() => {
                    var ls = require('local-storage');
                    ls.set('eventId', data._id);
                    
                    const win = window.open(
                      `/events/detail/${data._id}`,
                      "_self"
                    );
                    win.focus();
                  }}
                  key={data.comp_id}
                >
                  {/*********************event page cards main container **********************/}
                  <Row>
                    <Col sm={5} xs={6} className={styles.eventImageParent}>
                      <img
                        className={styles.eventPageCardsImage}
                        alt="example"
                        src={
                          data.image.filter((data) =>
                            data.fileType?.includes('image')
                          ).length > 0
                            ? fileUrl.fileUrlPath +
                              data.image
                                .filter((data) =>
                                  data.fileType?.includes('image')
                                )
                                ?.at(0)?.fileName
                            : cardDefaultPic
                        }
                      />
                    </Col>
                    <Col
                      sm={19}
                      xs={18}
                      className={`service-detail-row ${styles.eventCardsCol2}`}
                    >
                      <Row justify="space-between">
                        <Col span={20}>
                          <div style={{ display: 'flex' }}>
                            <div className="job-card-3 event-name">
                              {data.name}
                            </div>
                            {data.companyData.isFreelancer ? (
                              <div style={{ margin: '1px 0px 0px 10px' }}>
                                <Tooltip placement="top" title={'Freelancer'}>
                                  <UserOutlined
                                    style={{
                                      fontSize: '20px',
                                      color: '#ed5050',
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </Col>
                        <Col span={4} style={{display: 'flex',justifyContent: 'flex-end'}}>
                          <div
                            span={1}
                            className={`col-sm-1 col-xs-1 text-right p-0`}
                            onClick={(e) => this.handleLocationChange(e, data)}
                          >
                            <SendOutlined className="mapIcon" />
                          </div>
                        </Col>
                      </Row>
                      <Row justify="space-between">
                        <Col span={24}>
                          <NavLink
                            to={`comp-id${data.companyData._id}`}
                            onClick={(e) => e.stopPropagation()}
                            className="job-card-4 comp-hover"
                          >
                            {data.companyData.comp_info.bus_name}
                          </NavLink>
                        </Col>
                      </Row>
                      <Row align="bottom" style={{ marginTop: '10px' }}>
                        <Col
                          span={20}
                          style={{ display: 'flex', alignSelf: 'self-start' }}
                        >
                          <EnvironmentFilled className="service-icons" />
                          <div
                            className="service-location"
                            style={{
                              display: 'block',
                              textTransform: 'capitalize',
                            }}
                          >
                            {data?.eventLocation?.city
                              ? data?.eventLocation?.city
                              : ''}
                            {data?.eventLocation?.state
                              ? ', ' + data?.eventLocation?.state
                              : ''}
                            {data?.eventLocation?.country?.length > 0
                              ? ', ' + data?.eventLocation?.country
                              : ''}
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '10px' }}>
                        <Col
                          span={5}
                          style={{
                            display: 'flex',
                            alignSelf: 'self-start',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <LikeFilled
                            className="service-icons"
                            style={{ fontSize: '1rem', padding: '0 5px 0 0' }}
                          />
                          <div
                            className="service-location"
                            style={{
                              display: 'block',
                              fontSize: '1rem',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {data.likes.length} Likes
                          </div>
                        </Col>
                        <Col
                          span={8}
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <MessageFilled
                            className="service-icons"
                            style={{ fontSize: '1rem', padding: '0 5px 0 0' }}
                          />
                          <div
                            className="service-location"
                            style={{ fontSize: '1rem' }}
                          >
                            {data.comments.length} Comments{' '}
                          </div>
                        </Col>
                        <Col
                          sm={11}
                          xs={24}
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <CalendarFilled
                            className="service-icons"
                            style={{ fontSize: '1rem', padding: '0 5px 0 0' }}
                          />
                          <div
                            className="service-location"
                            style={{ fontSize: '1rem' }}
                          >
                            {`${moment(data.from).format(
                              'YYYY/MM/DD'
                            )} to ${moment(data.to).format('YYYY/MM/DD')}`}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              ))
          )}
          {/********************* pagination container **********************/}
          {!this.state.loading && (
            <div className={'paginate-container'}>
              <Pagination
                activePage={this.state.current_page}
                firstPageText={false}
                lastPageText={false}
                itemsCountPerPage={10}
                totalItemsCount={this.state.totalData}
                pageRangeDisplayed={5}
                hideDisabled={true}
                onChange={(pageNumber) => {
                  this.makeHttpRequestWithPage(pageNumber);
                }}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    eventdata: state.eventReducer.eventdata,
    isloading: state.eventReducer.isloading,
  };
};
export default withRouter(connect(mapStateToProps)(EventListingCard));
