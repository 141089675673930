import React, { Component, useEffect } from 'react';
import { Row, Col, Input, Select, Button, Badge, Switch, Form } from 'antd';
import Header from '../header/header.js';
import PlanCards from './plan_cards.js';
import Footer from './footer';
import { Link, NavLink } from 'react-router-dom';
import './Landing.css';
import './footer.css';

const PrivacyPolicy = (props) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="responsive-div" style={{ backgroundColor: 'white' }}>
      <Header />
      <div className="home-outer-div">
        <h3 className="tnc-head" style={{ opacity: 0.8 }}>
          <b>SENDBIZ INC - PRIVACY POLICY</b>
        </h3>
        <Row>
          <Col span={3}></Col>
          <Col span={18} className="tnc-para">
            SENDBIZ and its partners companies (“SENDBIZ”) respects your
            integrity and commits to process personal information in a lawful
            and accountable manner in accordance with prevailing information
            practices as well as relevant data protection and privacy laws.
            SENDBIZ has therefore created this policy to provide you with
            information on how we collect store, use and/or share information as
            well as information concerning individual rights as a data subject
            and how you can exercise them. This policy applies only to
            information we process from subcontractors and general contractors
            in connection with SENDBIZ platform
            <Link to={'/'} style={{ padding: 0 }}>
              https://sendbiz.com
            </Link>
            (the “site”). It does not apply to information processing done by
            other websites linked from this site. Please note that by using the
            SENDBIZ website and platform, you are deemed to have read and
            accepted this Policy and agree to be bound by it. If you do not
            agree with our information management practices, please do not use
            the SENDBIZ platform and website. SENDBIZ s is an online market
            place operated by SENDBIZ inc, a company based in San Francisco, USA
            and having its registered office at 26 O'Farrell #500 - 94108 San
            Francisco CA. SENDBIZ acts as a data controller with respect to all
            information collected in connection with access and use of the
            SENDBIZ website.
            <br />
            <br />
            <br />
            <b>Information we collect:</b>
            SENDBIZ collects information which is necessary for the delivery of
            SENDBIZ services and compliance with its contractual and legal
            obligations. Some of the information we collected is provided
            directly by you (personal and company information) while other
            information is automatically generated when you use or access this
            website. Personal information is information that relates to an
            identified or identifiable physical person: information which can
            reasonably identify, directly or indirectly, person trough
            identifiers such as name, contact in formation, location data, or
            online identifiers. We collect the following information:
            <br />
            <b> Personal information:</b>(name, address, salaries, CV,
            professional information, diplomas, formations, phone, availability,
            photos, and email)
            <br />
            <b> Company information:</b> such as company name, logo, corporate
            email, telephone number, website, physical address, contact person
            or registered users, Corporate ID, , country of registration, sector
            of activity, specialization , as well as information relating to
            company products, services, jobs, subcontractor job, event, news. At
            discretion, users may also attach company portfolio or presentation
            including photographs, videos or documents.
            <br />
            <b> Web usage information:</b> We collect information relating to
            your use of SENDBIZ website, products, services, jobs, subcontractor
            job, event, news, including your internet protocol address,
            approximate geographical location, information about the device used
            to view the website (device type and operating platform) as well as
            the type of internet browser user to view the website. Some
            information of this information is also collected using cookies and
            web tracking technologies.
            <br />
            <b>Cookies:</b> This website uses cookies to tailor content and to
            provide measure internet traffic to our website. We may also share
            information about your use of the site with our advertising and web
            analytics partners who may combine such information with other
            information you have provide to them or which they have collected
            from other sources.
            <br />
            <br />
            <br />
            <b>What are cookies? </b> A cookie is a tiny file containing a
            string of random data. This file is saved on your browser's cookie
            file when you visit our website. Cookies are useful in a number ways
            and are of different types.
            <br />
            <br />
            At SENDBIZ for the moment we don't collect any cookies, in the
            future we use two types of cookies: session cookies (temporary
            cookies) as well as persistent cookies (“permanent cookies”).
            Session cookies are cookies that manage the user's browsing session.
            They are only active while users navigate the site and are erased
            when a user leaves the site or closes the browser. Session cookies
            help to make the site usable by enabling basic functions like log in
            and navigation. Session cookies remember log in information and
            enable registered users to access the secure area of the site.
            Persistent cookies, or so called permanent cookies, are cookies that
            are stored in a user's device for longer period until their defined
            expiration time or until the user deletes them manually. These types
            of cookie help to remember user preferences including for example
            preferred language or the location from which you access the
            website. We use both types of cookies for statistics purposes to
            understand how users use the SENDBIZ platform and to collect and
            compile anonymous information such as number of visitors , popular
            pages as well as the origins of web traffic to the website.
            <br />
            To control cookies being saved on your device, please review the
            privacy and security settings of your internet browser.
            <br />
            <br />
            <br />
            <b>Lawful Grounds for collecting information: </b> We collect and
            use personal information on the following lawful reasons: For
            performance of contract between SENDBIZ and you and/or to facilitate
            any negotiations between you and us before entering into contract.
            In SENDBIZ's legitimate interest to operate its business
            effectively. To comply with any legal requirement to which SENDBIZ
            is subject In accordance with your consent, where consent is
            required by law for the processing of certain information. To
            protect your vital interests as a user of the SENDBIZ platform.
            <br />
            <br />
            <b>How we use your information:</b>
            <br />
            <b>Provide access to the SENDBIZ platform and its services.</b> We
            will use your information as necessary to provide you with access to
            our database and services. For example we use your name and email to
            register you and create your user account and grant you access to
            those services which require registration. We use company
            information to create your company profile and grant you access to
            our digital audience, to initiate business connections, marketing
            services, outsourcing services, pricing data, research data and
            reports, as well as provide access to a database of potential
            suppliers, candidates, job seeker, buyers or subcontractors. We may
            also use your information in the performance of our administrative
            and customer support services.
            <br />
            <b>Improve the SENDBIZ website and its services: </b> SENDBIZ
            strives to be a safe, reliable and an effective platform where
            SENDBIZ users and visitors can utilize the leverage of updated
            information in the subcontracting industry. SENDBIZ will use certain
            information to analyse and understand how users, candidates and
            visitors use the SENDBIZ services and website with the sole aim of
            improving its efficiency.
            <br />
            <b>Communication: </b> We will use your contact information to
            respond to your requests and provide you with information regarding
            Sendbiz services, for example, Such company is interested in this
            product that you are marketing. By registering you agree to receive
            commercial, promotional or information from Sendbiz and Sendbiz
            customers, we will use your email address to send you information
            about news, releases, tips and other industry information that we
            think is useful for your business.
            <br />
            Your contact information is provided to companies interested in a
            product, service or subcontracting request.
            <br />
            The contact information of the candidates is also presented to the
            companies registered in Sendbiz that have only applied for a job
            offer from the company.
            <br />
            <br />
            <b> Safety and Legal compliance: </b> SENDBIZ strives to be a safe
            and secure online market place and we always work to ensure the
            integrity of this website. We may collect, store and process
            personal and professional information as necessary to enforce our
            rights under our Terms ad Conditions and applicable laws. SENDBIZ
            will also collect nd store some information protect our customers
            and to detect and prevent fraud, financial crimes as well as misuse
            of the SENDBIZ platform and services. Where we are required by
            applicable law to collect, store and disclose specific data
            (including for example sales tax and accounting purposes), we will
            handle such data as required by the law.
            <br />
            <br />
            <b> Marketing: </b> SENDBIZ works to ensure that Companies and
            candidates get the best market exposure possible. We will use your
            information in our marketing and advertising campaigns. We may also
            use your contact information to send information about potential
            companies that could be relevant to your business. If you subscribed
            to our newsletter, we will user your email to send information about
            special offers, discounts, events and or releases happening at the
            SENDBIZ.
            <br />
            <br />
            <b>
              How we share information: SENDBIZ may have to share certain
              personal and non-information under the following circumstances:
            </b>
            <br />
            <br />
            <b>Service providers and business partners: </b> Certain portions of
            company information will be publicly accessible. We may share
            certain personal and company information to our partner companies,
            service providers, marketers or other providers who deliver services
            and products to you on our behalf. <br />
            <b>Business connections: </b> Portions of your business information
            (name, title, email, phone) will be accessible to companies that
            have an interest in or wish to purchase one of your products and
            services or events. We may share certain personal and business
            information with our partner companies, service providers,
            marketers, or other providers who provide services and products to
            you on our behalf. <br />
            <b>Business transfer or sale: </b> Where SENDBIZ is sold to,
            acquired by or merged with any company or organization, we may share
            information to the newly formed company as necessary for completion
            of such merger, sale or acquisition. This information may include
            personal information such as customer lists. <br />
            <b>To comply with the law: </b> We may share information to
            governmental regulatory or enforcement agencies when required to so
            by law or court order.
            <br />
            <b>Marketing: </b> we will share your company information, with your
            consent, to our marketers and advertisers.
            <br />
            <br />
            <br />
            <b>International transfers: </b> Please note that SENDBIZ makes
            every effort to process all information received in connection with
            the SENDBIZ Platform. Please note, however, that our partner
            companies and service providers may be located outside of Italy or
            elsewhere in the world and that in this regard, personal and
            non-personal information may be transferred to other jurisdictions
            that have different privacy protection standards. Examples of such
            jurisdictions include providers who supply us with web hosting and
            email delivery services. In these cases, we will take steps to
            ensure that your personal information is adequately protected,
            including entering into data processing agreements that set out
            sufficient protection requirements for the processing of personal
            data. Where applicable and required by law, we may seek your consent
            for such transfers.
            <br />
            <br />
            Your Information and files including their backups are stored on our
            servers and the servers of companies we hire to provide services to
            Us. Your Personal Information may be transferred across national
            borders because We have servers located in the Country_Name/s, and
            the companies we hire to help Us run our business may be located in
            different countries around the world.
            <br />
            <br />
            <b>Data retention: </b> SENDBIZ will keep personal and company
            information for as long as it is required for the purposes for which
            it was collected. Information collected for contractual performance
            will normally be kept for the entire duration of contract and for an
            additional period as may be required or permitted by applicable law.
            <br />
            <br />
            <b>Protecting your information: </b> SENDBIZ take precautionary
            measures to safeguard personal information from loss, misuse or
            unauthorised access, sharing and destruction. We have implemented
            suitable technical and organisational measures aimed to protect our
            database where your personal information is stored. This includes
            encryption, malware scanning and use of personal passwords. We also
            require our affiliated parties and service providers to protect your
            personal information in accordance with this policy and our data
            processing agreements. In addition, SENDBIZ has implemented
            role-based access policy and buyer's registration is only visible to
            website administrators.
            <br />
            <br />
            <b>Links: </b>SENDBIZ allows companies and candidates to include
            links to their websites on its platform. SENDBIZ does not screen,
            review, manage or control the linked websites and is not responsible
            for information collection activities done by such contractors. Our
            inclusion of the links is for convenience only and not an assurance
            that those websites are secure or reliable. If you use the links,
            you agree that you do so at your own risk.
            <br />
            <br />
            <b>Your Rights: </b>We provide many choices about the collection,
            use and sharing of your data, from deleting or correcting data you
            include in your profile and controlling the visibility of your posts
            to advertising opt-outs and communication controls. We offer you
            settings to control and manage the personal data we have about you.:{' '}
            <br />
            <b>Information and access: </b> You can request to have access to
            your information and obtain a copy of your information. This right
            has some limitations. Access to information may be denied if it is
            repetitive or if it could violate another person's privacy. <br />
            <b>Data rectification: </b> You have the right to request the
            correction or updating of erroneous or outdated information. <br />
            <b>Data erasure: </b> You may have a right to erase your
            information. This right may have some limitations. <br />
            <b>Object to processing of personal information: </b> You have the
            right, under certain circumstances, to object to specific types of
            processing of personal data including for example processing for
            direct marketing, research nd statistical purposes or processing
            based on our legitimate interests
            <br />
            <b>Restrict the processing of personal information: </b>You have the
            right to restrict access to your personal information including for
            example the restriction that your personal information should only
            be handled by SENDBIZ, and not be processed by other entities, as
            long as the restriction is in place or while your complaints is
            being processed. <br />
            <b>Data Portability: </b> You have the right to request portability
            of your information. This means the right to obtain your personal
            information in a structured, commonly used and machine-readable
            format so that is can easily be transferred by you or us to another
            company. <br />
            <b>California Privacy Rights </b> If you are a resident of
            California, this section provides additional details about the
            personal information we collect about you, and your rights under the
            California Consumer Privacy Act, or “CCPA.” <br />
            <b>
              Subject to certain limitations, the CCPA provides you the right to
              request:{' '}
            </b>{' '}
            <br />
            That we provide you access to details on the categories or specific
            pieces of personal information we collect and/or sell (including how
            we use and disclose this information, to whom we may sell it);
            <br />
            That we delete any of your personal information;
            <br />
            To opt out of any “sale” of your personal information that may
            occur; and
            <br />
            To not be discriminated against for exercising any of the above
            rights.
            <br />
            If you would like to request that we exercise your California
            privacy rights under the CCPA, please send us an email. We will
            verify your request using information associated with your account,
            including your email address. Further identification may be
            required. You may also designate an authorized representative to act
            on your behalf.
            <br />
            Sendbiz will not sell your personal information without your prior
            consent. If you want to opt out of the sale of your personal
            information, please send us an email.
            <br />
            <br />
            <br />
            <b>Consent and withdrawal of consent: </b> Where consent is the
            lawful basis for the collection and use of your personal information
            or special categories of personal information, you have the right,
            at any time, to withdraw consent previously given for a certain
            purpose, without charge, by contacting our Data Protection Officer.
            <br />
            <br />
            <br />
            <b>Children's Privacy</b>
            We recognize the importance of children's safety and privacy. We do
            not request, or knowingly collect, any personally identifiable
            information from children under the age of 16. If a parent or
            guardian becomes aware that his or her child has provided Us with
            Personal Information, he or she should write to Us at the email
            address set forth in this Policy.
            <br />
            <br />
            <br />
            <b>Data Protection Officer: </b>
            <br />
            For enquiries about privacy protection and data subject access
            rights, please send your enquiries to our human resource department
            our data protection officer <a>privacy@sendbiz.io</a>.<br />
            <br />
            <br />
            <b>Changes in our Privacy Policy: </b>We may update this policy
            where necessary. This policy may also be revised so as to comply
            with legal requirements of your jurisdiction. In case of changes to
            the policy, SENDBIZ will post the revised policy on the website and
            notify you via the email supplied to us upon registration.
            <br />
            <br />
            <br />
            <b>Your Acceptance of These Terms: </b>
            <br />
            Acceptance of Terms Through the use of this website, you are hereby
            accepting the terms and conditions stipulated within the
            aforementioned Privacy Policy Agreement. If you are not in agreement
            with our terms and conditions, then you should refrain from further
            use of our sites. In addition, your continued use of our website
            following the posting of any updates or changes to our terms and
            conditions shall mean that you are in agreement and acceptance of
            such changes.
            <br />
            <br />
            <br />
            <br />
          </Col>
          <Col span={3}></Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;