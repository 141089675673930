import React, { Component } from 'react';
import './login.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import APIManager from '../../APIManager/index';
import { Col, Row, message } from 'antd';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import sendbiz_logo from '../asset/sendbiz_logo.png';
import { fileUrl } from '../../Shared/imageUrlPath';
var ls = require('local-storage');

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: true,
    };
  }

  goSignup() {
    this.props.history.push('/signup');
  }

  componentDidMount() {
    const token = ls.get('token');
    if (token !== null) {
      this.props.history.push('/');
    }
  }

  //handle login
  loginInApp = (data) => {
    APIManager.Login(data)
      .then((response) => {
        if (response.data.isSuccess) {
          //this.props.history.push("/profile");
          const authToken = response.data.token;
          const userType = response.data.data.user.user_type;
          const rand = Math.floor(1 + Math.random() * (100 - 1));
          ls.set('user_type2', rand);
          ls.set('token', authToken);
          ls.set('user_type', userType * rand * 99);
          if (userType === 1) {
            const candidateEmail = response.data.data.user.email;
            const firstName = response.data.data.user.can_detail.first_name;
            const lastName = response.data.data.user.can_detail.last_name;
            const fullName = `${firstName} ${lastName}`;
            this.props.history.push('/profile');
            ls.set('email', candidateEmail);
            ls.set('name', fullName);
            message.info(
              `Welcome ${
                firstName.charAt(0).toUpperCase() + firstName.slice(1)
              }`
            );
          } else if (userType === 2 || userType === 3) {
            const companyEmail = response.data.data.user.email;
            const companyName =
              response.data.data.user.comp_detail.company_name;
            ls.set('company_email', companyEmail);
            ls.set('company_name', companyName);
            this.props.history.push('/edit/company/profile');
            message.info(
              `Welcome ${
                companyName.charAt(0).toUpperCase() + companyName.slice(1)
              }`
            );
          }
        }
      })

      .catch((err) =>
        message.error('Wrong Credentials or something went wrong.')
      );
  };
  showPass = () => {
    this.setState((prev) => ({ password: !prev.password }));
  };

  render() {
    return (
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            this.loginInApp(values);
          }, 500);
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required('email is required.'),
          password: Yup.string().required('password is required.'),
        })}
      >
        {(props) => {
          const {
            errors,
            handleChange,
            handleSubmit,
            touched,
            handleBlur,
          } = props;
          return (
            <>
              <Row>
                <Col xs={24} sm={12} className="login-left text-left px-left-login px-top-login">
                  <div className="go-back ">
                    <img
                      className="cursor"
                      onClick={() => this.props.history.push('/')}
                      src={process.env.PUBLIC_URL + '/go-back-1.png'}
                      alt="img"
                    />
                  </div>
                  <div>
                    <img
                      src={process.env.PUBLIC_URL + sendbiz_logo}
                      alt="logo"
                      style={{
                        width: '120px',
                        height: '50px',
                      }}
                    />
                  </div>
                  <h6
                    style={{
                      fontSize: 26,
                      fontWeight: 'bolder',
                      marginTop: 30,
                      fontFamily: 'Gilroy Bold',
                    }}
                    className="welcome_msg"
                  >
                    Welcome back !
                  </h6>
                  <p style={{ marginTop: 30, fontFamily: 'Gilroy medium' }}>
                    Log in to your SENDBIZ Account
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group login-form-group">
                      <label htmlFor="exampleInputEmail1">User name</label>
                      <input
                        type="email"
                        name="email"
                        className="input-font form-control login-form-control"
                        onChange={handleChange}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter username / Email ID"
                        onBlur={handleBlur}
                        // className={errors.email && touched.email ?
                        //   "input-error" : null}
                      />
                      {errors.email && touched.email && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {errors.email}
                        </div>
                      )}
                    </div>
                    <div className="form-group login-form-group">
                      <label htmlFor="exampleInputEmail1">Password</label>

                      <div style={{ position: 'relative' }}>
                        {this.state.password ? (
                          <EyeFilled
                            className="password-icon"
                            onClick={this.showPass}
                          />
                        ) : (
                          <EyeInvisibleFilled
                            className="password-icon"
                            onClick={this.showPass}
                          />
                        )}
                        <input
                          type={this.state.password ? 'password' : 'text'}
                          onBlur={handleBlur}
                          name="password"
                          className="input-font form-control login-form-control"
                          onChange={handleChange}
                          id="exampleInputPassword1"
                          placeholder="Enter password"
                        />
                      </div>

                      {errors.password && touched.password && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                          {errors.password}
                        </div>
                      )}
                    </div>
                    <div
                      className="form-group"
                      style={{ marginBottom: '40px' }}
                    >
                      <label
                        onClick={() => {
                          this.props.history.push('/forget-password');
                        }}
                        className="form-check-label form-check-label-3 cursor"
                      >
                        Forgot Password.
                      </label>
                    </div>
                    <div className="cent_button">
                      <button
                        type="submit"
                        className="btn btn-dark login-btn login-button-font"
                      >
                        Login
                      </button>
                    </div>
                  </form>
                  <h6 className="login-tag">
                    Don't have an account? Create your account,{' '}
                    <small
                      className="cursor red-link"
                      onClick={this.goSignup.bind(this)}
                      style={{ color: '#B02318' }}
                    >
                      Signup
                    </small>{' '}
                    now.
                  </h6>
                </Col>
                <Col xs={0} sm={12} className={'login-container'}>
                <img
                    className="login_div_img_1"
                    src={fileUrl.fileUrlPath + "login_image_cover.gif"}
                    alt="img"
                  />
                </Col>
              </Row>
            </>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.user,
    isloading: state.AuthReducer.isloading,
  };
};
export default connect(mapStateToProps)(Login);
