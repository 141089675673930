import React, { useEffect, useState } from 'react';
import Carousel from 'react-elastic-carousel';
import userDefaultpic from '../../../Components/asset/card.svg';
import './ServiceDetail.css';
import APIManager from '../../../APIManager';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Row, Tooltip } from 'antd';
import { fileUrl } from '../../../Shared/imageUrlPath';

const NewsCaursel = (props) => {
  const [newsData, setNewsData] = useState([]);
  useEffect(() => {
    getNews('1', '');
  }, []);

  const getNews = () => {
    APIManager.allNewsList('1').then((response) => {
      if (response.status === 200) {
        setNewsData(response.data.data);
      }
    });
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];
  console.log(newsData);
  return (
    <div className={'news-caursel-container'}>
      <Row className="apply-job-news-bottom" style={{ paddingTop: 70 }}>
        <p className="apply-job-news-bottom-para-1">News</p>
        <p className="apply-job-news-bottom-para-2">Inside SendBiz</p>
      </Row>
      <Row justify="center"></Row>
      <Carousel breakPoints={breakPoints} pagination={false}>
        {newsData &&
          newsData?.map((data) => {
            return (
              <div
                className={'news-caursel-card p-2'}
                onClick={() =>
                  props.history.push({
                    pathname: `/news/detail/${data._id}`,
                    id: data._id,
                  })
                }
              >
                <div>
                  <img
                    className={'news-caursel-img'}
                    src={
                      data.image.filter((data) =>
                        data.fileType.includes('image')
                      ).length > 0
                        ? fileUrl.fileUrlPath +
                          data.image.filter((data) =>
                            data.fileType.includes('image')
                          )[0].fileName
                        : userDefaultpic
                    }
                    style={{ objectFit: 'cover' }}
                    alt="img"
                  />
                </div>
                <div className={'news-title-carousel'}>
                  <Tooltip
                    placement="topLeft"
                    color="#000000"
                    title={data.title.toUpperCase()}
                  >
                    {data.title.toUpperCase()}
                  </Tooltip>
                  {/* {(data.title).toUpperCase()} */}
                </div>
                <div className={'time-container-news-carousel'}>
                  {moment(data.updatedAt).fromNow()}
                </div>
                <div className={'date-news-carousel'}>
                  {moment(data.createdAt).format('DD MMM YYYY')}
                </div>
              </div>
            );
          })}
      </Carousel>
    </div>
  );
};
export default withRouter(NewsCaursel);
