import React from 'react';
import {
  Row,
  Col,
  Card,
  Tag,
  Tooltip,
} from 'antd';

import { useHistory } from 'react-router-dom';
import cardDefaultPic from '../../../Components/asset/card.svg';
import moment from 'moment';
import { fileUrl } from '../../../Shared/imageUrlPath';
import styles from './offersReceived.module.css';

const ReceiveCard = ({ id, count, appliedAt, job, company }) => {
  const history = useHistory();
  return (
    <Card
      className={`${styles.card}`}
      style={{ cursor: 'pointer' }}
      onClick={() =>
        history.push({
          pathname: '/subcontractor-received-details',
          search: `?jobid=${id}&companyid=${id}`,
        })
      }
    >
      <Row gutter={15}>
        <Col xs={5} sm={4}>
          <img
            alt="example"
            src={
              job.job_logo
                ? fileUrl.fileUrlPath + job.job_logo
                : job.defaultLogo
                ? fileUrl.fileUrlPath + job.defaultLogo
                : cardDefaultPic
            }
            className={styles.image}
          />
        </Col>
        <Col xs={19} sm={20}>
          <Row>
            <Col xs={24} sm={18}>
              <div style={{display: 'flex',  alignItems: 'center' }} className="service-name">
                {job.title.length > 30 ? (
                  <Tooltip title={job.title}>
                    {job.title.substr(0, 29) + '...'}
                  </Tooltip>
                ) : (
                  job.title
                )}
                <Tag
                  color="volcano"
                  className={`s-price-tag ${styles.tag}`}
                >
                  {`${count} applied`}
                </Tag>
              </div>
            </Col>
            <Col xs={24} sm={6}>
                      <div className={styles.location}>
                        {moment(appliedAt).format('DD MMM YYYY')}
                      </div>
            </Col>
            </Row>
            <Row>
            <Col xs={12} sm={8}>
              <span>
                <img
                  src={process.env.PUBLIC_URL + '/briefcase-red.png'}
                  style={{ width: 14 }}
                />
              </span>
              <span
                className={styles.text}
                style={{
                  paddingLeft: '8px',
                  verticalAlign: 'middle',
                }}
              >
                {job.job_type}
              </span>
              </Col>
            <Col xs={12} sm={0}>
                <Tag
                  color="volcano"
                  className={`s-price-tag`}
                  style={{
                    margin: '0px',
                    maxWidth:'100%'
                  }}
                >
                  {`${count} Applied`}
                </Tag>
            </Col>
              </Row>
            <Row>
              <Col xs={24} sm={16}>
              <span
                style={{
                  color: '#000000',
                  opacity: '50%',
                  fontWeight: 'bold',
                  verticalAlign: 'middle',
                }}
              >
                Added By: 
              </span>
            <span
                className={styles.text}
                style={{
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  verticalAlign: 'middle',
                  marginTop:"-2px",
                  marginLeft: '10px'
                }}
              >
                {job.addedBy.first_name}&nbsp;{job.addedBy.last_name}
              </span>
              </Col>
              </Row>
              <Row>
            <Col xs={24} sm={24}>
              <span>
                <img src="./location-red.png" style={{ width: 14 }} />
              </span>
              <span
                className={styles.location}
                style={{
                  paddingLeft: '8px',
                  verticalAlign: 'middle',
                }}
              >
                {company?.contact_info?.sub_address}
              </span>
            </Col>
            </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default ReceiveCard;
