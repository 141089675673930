import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import styles from './edit_company_profile.module.css';
import { Row, Col, Input, Form, Button, message, Modal } from 'antd';
// import RightModal from '../job/rightModal.js';
// import CreateLink from '../createprofile/create-link.js';
import APIManager from '../../APIManager';
import { dispatchAction } from '../../Redux/Store/index';
import { updateUser } from '../../Redux/Actions/AuthAction';

let initialValues = [
  { tag: 'LinkedIn', link: '' },
  { tag: 'Facebook', link: '' },
  { tag: 'Instagram', link: '' },
  { tag: 'Twitter', link: '' },
];
class CompanySocial extends Component {
  constructor(props) {
    super(props);
    this.linkElement = React.createRef();
  }
  state = {
    socialLinkData: [],
    initialdata: {},
    modalShow: false,
  };
  addSocialLink = () => {
    this.setState({ modalShow: true });
  };
  closeModal = () => {
    this.setState({ modalShow: false });
  };
  saveUserData = async (values) => {
    try {
      let data1 = {};
      let arr = [];
      this.props.initialdata.forEach((val) => {
        arr.push({ _id: val._id, tag: val.tag, link: val.link });
      });
      arr.push({ tag: values.tag, link: values.link });
      data1['social_link'] = arr;
      const response = await APIManager.companyInfoSubmit(data1);
      var ls = require('local-storage');
      let old_data = ls.get('persist:root');
      if (old_data !== undefined) {
        old_data = JSON.parse(old_data.AuthReducer);
        old_data.user.data.data = response.data.data;
        let new_data = {};
        new_data = old_data.user;
        dispatchAction(updateUser(new_data));
      }
      message.success('Social link added successfully.');
      setTimeout(()=>{window.location.reload();},600)
    } catch {
      message.error('Error updating user profile.');
    }
  };
  editUserData = async (values) => {
    try {
      let data1 = {};
      let arr = [];
      if( this.props.initialdata.length>0){
        this.props.initialdata.map((val) => {
          Object.keys(values).forEach((e) => {
            if (e === val.tag) {
              val.link = values[e];
            }
          });
          arr.push(val);
        });
      } else {
        initialValues.map((val)=>{
          Object.keys(values).forEach((e) => {
            if (e === val.tag) {
              val.link = values[e];
            }
          });
          arr.push(val);
        })
      }
      data1['social_link'] = arr;
      const response = await APIManager.companyInfoSubmit(data1);
      var ls = require('local-storage');
      let old_data = ls.get('persist:root');
      if (old_data !== undefined) {
        old_data = JSON.parse(old_data.AuthReducer);
        old_data.user.data.data = response.data.data;
        let new_data = {};
        new_data = old_data.user;
        dispatchAction(updateUser(new_data));
      }
      message.success('Social link updated successfully.');
      setTimeout(()=>{window.location.reload();},600);
    } catch {
      message.error('Error updating user profile.');
    }
  };
  render() {
    return (
      <>
        <Modal
          open={this.state.modalShow}
          footer={null}
          onCancel={this.closeModal}
        >
          <Form onFinish={this.saveUserData}>
            <Row style={{ marginTop: 40 }}>
              <div style={{ width: '100%', height: 85 }}>
                <p className="input-label-field-custom-type-1">Title</p>
                <Form.Item name="tag">
                  <input
                    name="tag"
                    className="input-field-custom-type-1-left input-border"
                    placeholder="Enter Title"
                  ></input>
                </Form.Item>
              </div>
            </Row>
            <Row style={{ marginTop: 40 }}>
              <div style={{ width: '100%', height: 85 }}>
                <p className="input-label-field-custom-type-1">Link</p>
                <Form.Item name={'link'}>
                  <input
                    name="link"
                    className="input-field-custom-type-1-left input-border"
                    placeholder="Enter Link"
                  ></input>
                </Form.Item>
              </div>
            </Row>
            <Form.Item>
              <Row>
                <Col xs={24} className={styles.buttonCol}>
                  <Button className={styles.button} htmlType="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
        <Form
          className={styles.socialTab}
          layout="vertical"
          onFinish={this.editUserData}
        >
          <Row>
            {this.props.initialdata.length>0 ? this.props.initialdata.map((val, index) => {
              if (index % 2 === 0) {
                return (
                  <Col
                    sm={12}
                    xs={24}
                    key={index}
                    className={`form-padding-right ${styles.fromPaddingRight}`}
                  >
                    <Form.Item
                      name={val.tag}
                      label={
                        <label
                          className="input_label_profile"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {val.tag}
                        </label>
                      }
                      initialValue={val.link}
                    >
                      <Input
                        className={`input-field-custom-type-1-left ${styles.inputBoxW100}`}
                      />
                    </Form.Item>
                  </Col>
                );
              } else if (index % 2 === 1) {
                return (
                  <Col
                    sm={12}
                    xs={24}
                    key={index}
                    className={`form-padding-left ${styles.fromPaddingLeft}`}
                  >
                    <Form.Item
                      name={val.tag}
                      label={
                        <label
                          className="input_label_profile"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {val.tag}
                        </label>
                      }
                      initialValue={val.link}
                    >
                      <Input
                        name="link"
                        className={`input-field-custom-type-1-left ${styles.inputBoxW100}`}
                      />
                    </Form.Item>
                  </Col>
                );
              }
            }): initialValues.map((val, index) => {
              if (index % 2 === 0) {
                return (
                  <Col
                    sm={12}
                    xs={24}
                    key={index}
                    className={`form-padding-right ${styles.fromPaddingRight}`}
                  >
                    <Form.Item
                      name={val.tag}
                      label={
                        <label
                          className="input_label_profile"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {val.tag}
                        </label>
                      }
                      initialValue={val.link}
                    >
                      <Input
                        className={`input-field-custom-type-1-left ${styles.inputBoxW100}`}
                      />
                    </Form.Item>
                  </Col>
                );
              } else if (index % 2 === 1) {
                return (
                  <Col
                    sm={12}
                    xs={24}
                    key={index}
                    className={`form-padding-left ${styles.fromPaddingLeft}`}
                  >
                    <Form.Item
                      name={val.tag}
                      label={
                        <label
                          className="input_label_profile"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {val.tag}
                        </label>
                      }
                      initialValue={val.link}
                    >
                      <Input
                        name="link"
                        className={`input-field-custom-type-1-left ${styles.inputBoxW100}`}
                      />
                    </Form.Item>
                  </Col>
                );
              }
            })}
          </Row>
          <div className="custom-file">
            <label
              className="custom-file-label-3"
              onClick={this.addSocialLink}
              style={{ paddingTop: 20 }}
              htmlFor="inputGroupFile01"
            >
              <img src={process.env.PUBLIC_URL + '/link.svg'} alt="img" />
              &nbsp;&nbsp; Add another link
            </label>
          </div>
          <Row>
            <Col xs={24} className={styles.buttonCol}>
              <Button className={styles.button} htmlType="submit">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

export default CompanySocial;
